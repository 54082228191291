import { Modal as AntDModal } from 'antd';
import lodash from 'lodash';
import React, { Component } from 'react';

const defaultAntDModalProps = {
	width: '100%',
	style: { maxWidth: '1000px', padding: '10px' },
	bodyStyle: { maxHeight: '90vh', height: '90vh', overflow: 'auto' },
	centered: true,
	footer: null,
	maskClosable: true, // Fire OnCancel Prop on Overlay Area Click.
	keyboard: true, // Fire OnCancel Prop on Keyboard "Esc" Key.
	destroyOnClose: true,
};

class CustomModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modalVisibility: false, // Strictly handled whether modal is open or not. Depending on '"modalProps.visible" OR "toggle" prop this will changed accordingly.
		};
	}

	static getDerivedStateFromProps(props) {
		const { modalProps = {} } = props;

		// NOTE : If "modalProps.visible" prop is provided it normally determine modal's open/close state. (This is useful when open/close is need to handle remotely. Ex. through redux. )
		// 				But also note that, If "trigger" prop is passed it can use to toggle between open/close. (This is useful when we just need to handle open/close simply. As necessary toggle is handled internally.)

		if (modalProps.visible) {
			return { modalVisibility: modalProps.visible };
		} else {
			return null;
		}
	}

	// NOTE : This helper function will normally passed to modal's passed child/function. This is useful when 'trigger' prop is passed and we can need to manually open/close modal.
	modalToggler() {
		this.setState({
			modalVisibility: !this.state.modalVisibility,
		});
	}

	generateAntdModalProps() {
		const { modalVisibility } = this.state;
		const { modalProps = {} } = this.props;
		const { title } = modalProps;

		// Dynamic AntD Modal props, depending on certain other prop values.
		const dynamicAntdModalProps = {
			bodyStyle: {
				paddingTop: !title ? '50px' : undefined, // Making when title is not provided, body content is spaced nicely with close icon.
			},
			visible: modalVisibility,
			onCancel: modalProps.onCancel || (() => this.modalToggler()),
		};

		return lodash.merge(
			{},
			defaultAntDModalProps,
			dynamicAntdModalProps,
			modalProps, // User passed props will take over any other prop precedence.
		);
	}

	// NOTE : This is used to pass necessary prop to passed "Component OR Function" as Modals' rendering Child.
	getPassedChildComponentWithNewProps() {
		const rawChildComp = this.props.children; // Passed modal children can be Component or Function. In either case they will get some props, which can be used handle modal later, if necessary.
		let newChildComp = <div />; // This will updated accordingly on below.

		// Props that will be passed to passed Component/Function.
		const defaultPassingProps = {
			toggleModal: () => this.modalToggler(),
		};

		// Handling when Function is passed.
		if (lodash.isFunction(rawChildComp)) {
			newChildComp = rawChildComp(defaultPassingProps);
		}

		// Handling when Component is passed.
		if (React.isValidElement(rawChildComp)) {
			newChildComp = React.cloneElement(rawChildComp, defaultPassingProps);
		} else {
			// Handling when Function is passed and Its returned component.
			newChildComp = React.cloneElement(newChildComp, defaultPassingProps);
		}

		return newChildComp;
	}

	render() {
		const {
			toggler, // If toggler prop is passed, It can be used to open/close modal.
			togglerWrapperStyles = {},
		} = this.props;
		const antdModalProps = this.generateAntdModalProps();

		const passedChildCompWithNewProps =
			this.getPassedChildComponentWithNewProps();

		return (
			<div className='CustomModal'>
				{toggler && (
					<div style={togglerWrapperStyles}>
						<div
							onClick={() => {
								this.modalToggler();
							}}>
							{toggler}
						</div>
					</div>
				)}

				<AntDModal {...antdModalProps}>{passedChildCompWithNewProps}</AntDModal>
			</div>
		);
	}
}

export default CustomModal;
