import React from 'react';

import ProductOverviewItem from '../../helperComponents/ProductOverviewItem/ProductOverviewItem';
import ProductOverviewSection from '../../helperComponents/ProductOverviewSection/ProductOverviewSection';

import './ProOveGeneralSection.css';

const ProOveGeneralSection = (props) => {
	const { overviewData = {} } = props;

	return (
		<ProductOverviewSection className='ProOveGeneralSection' title='GENERAL'>
			<ProductOverviewItem title='CATEGORY'>
				{overviewData.productMainCategoryName || '-'}
			</ProductOverviewItem>

			<ProductOverviewItem title='SUB CATEGORY'>
				{overviewData.productSubCategoryName || '-'}
			</ProductOverviewItem>

			<ProductOverviewItem title='CURRENT STOCK'>
				<div
					title={
						overviewData.inventoryUnitType === 'BOX'
							? `Total Items : ${overviewData.stockQuantityAsEA}`
							: ''
					}>
					{overviewData.stockQuantityWithUnitTypeSuffixed}

					{overviewData.inventoryUnitType === 'BOX' && (
						<span className='ProductOverview--UTL--subtitleText'>{` (${overviewData.numOfItemsInABox} EA PER BOX)`}</span>
					)}
				</div>
			</ProductOverviewItem>

			<ProductOverviewItem title='ACTIVE OFFERS'>
				{overviewData.activeOffers.length === 0 && '-'}
				{overviewData.activeOffers.map((offer) => {
					return <div>{offer.PromotionName}</div>;
				})}
			</ProductOverviewItem>
		</ProductOverviewSection>
	);
};

export default ProOveGeneralSection;
