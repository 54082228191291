import { Button, Divider, Empty, Space, message } from 'antd';
import { LoaderView } from 'ecologital-ui-library';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './AppsViewStyles.css'

import {
	getNisaProductCountsByOrgId,
	getNisaProductsByOrgId,
	getNisaPromotionCountByOrdId,
	getNisaPromotionsByOrgId,
	getNisaProdoctCountByModifiedDate,
	getNisaProdoctsByModifiedDate,
	getNisaPromotionCountByModifiedDate,
	getNisaPromotionByModifiedDate,
	getNisaProdoctsByBarCode,
	getNisaPromotionByBarCode,
	getNisaProdoctCountByProductName,
	getNisaProdoctsByProductName,
	getNisaPromotionCountByProductName,
	getNisaPromotionByProductName,
	addNisaProductsUpdateRequest,
	addNisaPromotionsUpdateRequest,
	getNisaProdoctCountPriceModified,
	getNisaProdoctsPriceModified,
	getNisaPromotionCountByPeriod,
	getNisaPromotionByPeriod,

} from '../../../utilities/apiRequests/witmegNisaServerRequests';

import ProductSearch from './subSections/ProductSearch';
import SearchResults from './subSections/SearchResults';
// import { Alert, Button, Tabs } from 'antd';
// import { LoaderView } from 'ecologital-ui-library';
// import { Formik } from 'formik';

const dashboardSelector = (state) => () => state.dashboard;

const NisaApp = () => {
	const dashboardState = useSelector(dashboardSelector)();
	const [viewType, setViewType] = useState('products');
	const [productList, setProductList] = useState([]);
	const [columnOrder, setColumnOrder] = useState([]);
	const [loading, setLoading] = useState(false);
	const [fetchingStatus, setFetchingStatus] = useState('default');
	const [totalResults, setTotalResults] = useState(0);
	const [currentPaginationIndex, setCurrentPaginationIndex] = useState(1);
	const [searchOption, setSearchOption] = useState('');
	const [searchKey, setSearchKey] = useState('');
	const [searchKeysRange, setSearchKeysRange] = useState([moment().subtract(7, 'd'), moment()]);
	const [forceFetch, setForceFetch] = useState(false);
	const [selectedItems, setSelectedItems] = useState([]);

	useEffect(() => {
		async function fetchCount() {
			setFetchingStatus('pending');
			setProductList([]);
			setSelectedItems([])
			if (viewType === 'products') {
				setSearchOption('')
				const productCountData = getNisaProductCountsByOrgId({
					OrgID: dashboardState.dashboard.currentlySelectedOrgId,
				});

				await productCountData.then(async (productCount) => {
					if (_.isInteger(Number(productCount))) {
						setTotalResults(productCount);
					} else {
						setFetchingStatus('error');
					}
					setForceFetch(true)
					return true;
				});
			} else if (viewType === 'promotions') {
				setSearchOption('')
				const promoCountData = getNisaPromotionCountByOrdId({
					OrgID: dashboardState.dashboard.currentlySelectedOrgId,
				});

				await promoCountData.then(async (promoCount) => {
					if (_.isInteger(Number(promoCount))) {
						setTotalResults(promoCount);
					} else {
						setFetchingStatus('error');
					}
					setForceFetch(true)
					return true;
				});
			}
		}
		fetchCount();
	}, [viewType, dashboardState]);

	useEffect(() => {
		async function fetchResult() {
			setLoading(true);
			if (viewType === 'products') {
				if (searchOption === '' && forceFetch) {
					const prodData = getNisaProductsByOrgId({
						OrgID: dashboardState.dashboard.currentlySelectedOrgId,
						Skip: Number(currentPaginationIndex - 1),
					});
					await prodData.then((data) => {
						setProductList(data.Result);
						setColumnOrder(data.ColumnOrder)
						console.log('product Data ===>>', data);
						setFetchingStatus('success');
						setLoading(false);
						return true;
					});
				} else if (searchOption === "modifiedDate" && searchKey !== '') {
					const prodData = getNisaProdoctsByModifiedDate({
						OrgID: dashboardState.dashboard.currentlySelectedOrgId,
						Skip: Number(currentPaginationIndex - 1),
						ModifiedDate: searchKey
					});
					await prodData.then((data) => {
						setProductList(data);
						console.log('product Data ===>>', data);
						setFetchingStatus('success');
						setLoading(false);
						return true;
					});
				} else if (searchOption === "productName" && searchKey !== '') {
					const prodData = getNisaProdoctsByProductName({
						OrgID: dashboardState.dashboard.currentlySelectedOrgId,
						Skip: Number(currentPaginationIndex - 1),
						RequestValue: searchKey
					});
					await prodData.then((data) => {
						setProductList(data);
						console.log('product Data ===>>', data);
						setFetchingStatus('success');
						setLoading(false);
						return true;
					});
				} else {
					setLoading(false);
				}
			} else if (viewType === 'promotions') {
				setForceFetch(false)
				if (searchOption === '') {
					const promoData = getNisaPromotionsByOrgId({
						OrgID: dashboardState.dashboard.currentlySelectedOrgId,
						Skip: Number(currentPaginationIndex - 1),
					});
					await promoData.then((data) => {
						setProductList(data.Result);
						setColumnOrder(data.ColumnOrder)
						console.log('pomotion Data ===>>', data);
						setFetchingStatus('success');
						setLoading(false);
						return true;
					});
				} else if (searchOption === "modifiedDate" && searchKey !== '') {
					const prodData = getNisaPromotionByModifiedDate({
						OrgID: dashboardState.dashboard.currentlySelectedOrgId,
						Skip: Number(currentPaginationIndex - 1),
						ModifiedDate: searchKey
					});
					await prodData.then((data) => {
						setProductList(data);
						console.log('product Data ===>>', data);
						setFetchingStatus('success');
						setLoading(false);
						return true;
					});
				} else if (searchOption === "period") {
					const prodData = getNisaPromotionByPeriod({
						OrgID: dashboardState.dashboard.currentlySelectedOrgId,
						Skip: Number(currentPaginationIndex - 1),
						StartDate: searchKeysRange[0],
						EndDate: searchKeysRange[1]
					});
					await prodData.then((data) => {
						setProductList(data);
						console.log('product Data ===>>', data);
						setFetchingStatus('success');
						setLoading(false);
						return true;
					});
				} else {
					setLoading(false);
				}
			} else {
				setLoading(false);
			}
		}
		fetchResult();
	}, [viewType, dashboardState, currentPaginationIndex, searchOption, searchKey, forceFetch]);

	const handleChangeProductType = (val) => {
		setViewType(val);
		// setForceFetch(true)
	};

	const handleChangeMainFilterOption = async (val) => {
		if (viewType === "products") {
			if (val === 'modified') {
				setLoading(true);
				const productCountData = getNisaProdoctCountPriceModified({
					OrgID: dashboardState.dashboard.currentlySelectedOrgId,
				});

				await productCountData.then(async (productCount) => {
					if (_.isInteger(Number(productCount))) {
						setTotalResults(productCount);
					} else {
						setFetchingStatus('error');
					}
					return true;
				});

				const prodData = getNisaProdoctsPriceModified({
					OrgID: dashboardState.dashboard.currentlySelectedOrgId,
					Skip: 0,
				});
				await prodData.then((data) => {
					setProductList(data.Result);
					console.log('product Data ===>>', data);
					setFetchingStatus('success');
					setLoading(false);
					return true;
				});
			} else {
				const productCountData = getNisaProductCountsByOrgId({
					OrgID: dashboardState.dashboard.currentlySelectedOrgId,
				});
				await productCountData.then(async (productCount) => {
					if (_.isInteger(Number(productCount))) {
						setTotalResults(productCount);
					} else {
						setFetchingStatus('error');
					}
					setForceFetch(true)
					return true;
				});
			}
		}
	}

	const handleSearchByKey = async (searchOptionVal, searchKeyVal) => {
		setCurrentPaginationIndex(1)
		setSearchOption(searchOptionVal);
		setSearchKey(searchKeyVal)

		if (viewType === "products") {
			if (searchOptionVal === "modifiedDate") {
				const productCountData = getNisaProdoctCountByModifiedDate({
					OrgID: dashboardState.dashboard.currentlySelectedOrgId,
					ModifiedDate: searchKeyVal
				});

				await productCountData.then(async (productCount) => {
					if (_.isInteger(Number(productCount))) {
						setTotalResults(productCount);
					} else {
						setFetchingStatus('error');
					}
					return true;
				});

				const prodData = getNisaProdoctsByModifiedDate({
					OrgID: dashboardState.dashboard.currentlySelectedOrgId,
					Skip: 0,
					ModifiedDate: searchKeyVal
				});
				await prodData.then((data) => {
					setProductList(data);
					console.log('product Data ===>>', data);
					setFetchingStatus('success');
					setLoading(false);
					return true;
				});


			} else if (searchOptionVal === "productName") {
				if (searchKeyVal && searchKeyVal !== '' > 0) {
					const productCountData = getNisaProdoctCountByProductName({
						OrgID: dashboardState.dashboard.currentlySelectedOrgId,
						RequestValue: searchKeyVal
					});

					await productCountData.then(async (productCount) => {
						if (_.isInteger(Number(productCount))) {
							setTotalResults(productCount);
						} else {
							setTotalResults(0);
							setFetchingStatus('error');
						}
						setLoading(false);
						return true;
					});

					const prodData = getNisaProdoctsByProductName({
						OrgID: dashboardState.dashboard.currentlySelectedOrgId,
						Skip: 0,
						RequestValue: searchKeyVal
					});
					await prodData.then((data) => {
						setProductList(data);
						console.log('product Data ===>>', data);
						setFetchingStatus('success');
						setLoading(false);
						return true;
					});
				}

			} else if (searchOptionVal === "barcode") {
				const prodData = getNisaProdoctsByBarCode({
					OrgID: dashboardState.dashboard.currentlySelectedOrgId,
					RequestValue: searchKeyVal
				});
				await prodData.then((data) => {
					setProductList(data);
					console.log('product Data ===>>', data);
					setFetchingStatus('success');
					setLoading(false);
					return true;
				});
			}
		} else {
			if (searchOptionVal === "modifiedDate") {
				const productCountData = getNisaPromotionCountByModifiedDate({
					OrgID: dashboardState.dashboard.currentlySelectedOrgId,
					ModifiedDate: searchKeyVal
				});

				await productCountData.then(async (productCount) => {
					if (_.isInteger(Number(productCount))) {
						setTotalResults(productCount);
					} else {
						setTotalResults(0);
						setFetchingStatus('error');
					}
					setLoading(false);
					return true;
				});

				const prodData = getNisaPromotionByModifiedDate({
					OrgID: dashboardState.dashboard.currentlySelectedOrgId,
					Skip: 0,
					ModifiedDate: searchKeyVal
				});
				await prodData.then((data) => {
					setProductList(data);
					console.log('product Data ===>>', data);
					setFetchingStatus('success');
					setLoading(false);
					return true;
				});
			} else if (searchOptionVal === "productName") {
				if (searchKeyVal && searchKeyVal !== '' > 0) {
					const productCountData = getNisaPromotionCountByProductName({
						OrgID: dashboardState.dashboard.currentlySelectedOrgId,
						RequestValue: searchKeyVal
					});

					await productCountData.then(async (productCount) => {
						if (_.isInteger(Number(productCount))) {
							setTotalResults(productCount);
						} else {
							setTotalResults(0);
							setFetchingStatus('error');
						}
						setLoading(false);
						return true;;
					});

					const prodData = getNisaPromotionByProductName({
						OrgID: dashboardState.dashboard.currentlySelectedOrgId,
						Skip: 0,
						RequestValue: searchKeyVal
					});
					await prodData.then((data) => {
						if (data && data.length > 0) {
							setProductList(data);
							console.log('product Data ===>>', data);
							setFetchingStatus('success');

						} else {
							setFetchingStatus('error');
						}
						setLoading(false);
						return true;
					});
				} else {
					setLoading(false);
				}


			} else if (searchOptionVal === "barcode") {
				const prodData = getNisaPromotionByBarCode({
					OrgID: dashboardState.dashboard.currentlySelectedOrgId,
					RequestValue: searchKeyVal
				});
				await prodData.then((data) => {
					setTotalResults(1)
					setProductList(data.Result);
					console.log('product Data ===>>', data);
					setFetchingStatus('success');
					setLoading(false);
					return true;
				});

			} else {
				setLoading(false);
				return true;
			}

		}
	}

	const handleSearchByPeriod = async (type, data) => {
		if (viewType === "promotions") {
			setSearchKeysRange(data)
			const promoCount = getNisaPromotionCountByPeriod({
				OrgID: dashboardState.dashboard.currentlySelectedOrgId,
				StartDate: data[0],
				EndDate: data[1]
			})
			await promoCount.then(async (productCount) => {
				if (_.isInteger(Number(productCount))) {
					setTotalResults(productCount);
				} else {
					setTotalResults(0);
					setFetchingStatus('error');
				}
				setLoading(false);
				return true;
			});

			const prodData = getNisaPromotionByPeriod({
				OrgID: dashboardState.dashboard.currentlySelectedOrgId,
				Skip: 0,
				StartDate: data[0],
				EndDate: data[1]
			});
			await prodData.then((data) => {
				setProductList(data);
				console.log('product Data ===>>', data);
				setFetchingStatus('success');
				setLoading(false);
				return true;
			});
		}
	}

	const fetchMoreData = async (pageNo) => {
		setCurrentPaginationIndex(pageNo);
	};

	const hanndleChangeSearchOption = (val) => {
		setSearchOption(val)
		setSearchKey('')
	}

	const handleSetSelectedItems = (itemList) => {
		setSelectedItems(itemList)
	}

	const sendUpdateProductsRequest = async () => {

		if (viewType === "products") {
			const requetDataArray = selectedItems.map(item => {
				return {
					ProductCode: item.ProductCode,
					InnerEAN: item.InnerEAN,
					Nsp: item.Nsp,
					NumberUnitsPack: parseInt(item.NumberUnitsPack),
					Rsp: item.customerPrice && item.customerPrice !== "" ? item.customerPrice : item.Rsp,
					ShortDescription: item.ShortDescription,
					Weight: item.Weight,
					VATRate: item.VatRate,
					Currency: item.Currency
				}
			})
			console.log("requetArray===>>>", requetDataArray)
			const productsUpdateData = addNisaProductsUpdateRequest({
				OrgID: dashboardState.dashboard.currentlySelectedOrgId,
				CloudLocationID: dashboardState.dashboard.currentlySelectedLocationId,
				Request: requetDataArray
			});
			await productsUpdateData.then((data) => {
				console.log('Product Update Data ===>>', data);
				message.success('Product Update Request Submitted');
				setSelectedItems([])
			});
		} else {
			const requetArray = selectedItems.map(item => {
				return {
					ProductCode: item.ProductCode,
					InnerEAN: item.InnerEAN,
					EffectiveDate: item.EffectiveDate,
					EndDate: item.EndDate,
					Nsp: item.Nsp,
					NumberUnitsPack: parseInt(item.NumberUnitsPack),
					Rsp: item.Rsp,
					ShortDescription: item.ShortDescription,
					Currency: item.Currency
				}
			})
			const productsUpdateData = addNisaPromotionsUpdateRequest({
				OrgID: dashboardState.dashboard.currentlySelectedOrgId,
				CloudLocationID: dashboardState.dashboard.currentlySelectedLocationId,
				Request: requetArray
			});
			await productsUpdateData.then((data) => {
				console.log('Product Update Data ===>>', data);
				message.success('Product Update Request Submitted');
				setSelectedItems([])
			});
		}
	}

	return (
		<div>
			<ProductSearch
				handleChangeProductType={handleChangeProductType}
				handleChangeMainFilterOption={handleChangeMainFilterOption}
				handleSearchByKey={handleSearchByKey}
				handleSearchByPeriod={handleSearchByPeriod}
				hanndleChangeSearchOption={hanndleChangeSearchOption}
				viewType={viewType}
				columnOrder={columnOrder}
			/>

			<Divider />
			<>
				{selectedItems.length > 0 ?
					<div style={{ textAlign: 'right' }}>
						<Space align='end'>
							<Button type="primary" onClick={sendUpdateProductsRequest}>Download</Button>
						</Space>
					</div>
					: null}
			</>


			{(() => {
				switch (fetchingStatus) {
					case 'pending':
						return <LoaderView className='ProductMasterView' isLoading />;
					case 'error':
						return <Empty />;
					default:
						return (
							<>
								{productList && productList.length > 0 ? (
									<SearchResults
										productList={productList}
										selectedItems={selectedItems}
										tableCompProps={{
											pagination: {
												current: currentPaginationIndex,
												total: totalResults,
												pageSize: 10,
												onChange: (pageNo) => {
													// NOTE: This 'pageNo' is start by '1' not '0'
													fetchMoreData(pageNo);
												},
											},
										}}
										handleSetSelectedItems={handleSetSelectedItems}
										viewType={viewType}
										loading={loading}
										columnOrder={columnOrder}
									/>
								) : null}
							</>
						);
				}
			})()}
		</div>
	);
};

export default NisaApp;
