// import { reqStatusTypes } from '../../../helpers/constants';
import { sectionName } from '../dashboardConstants';

const sliceName = 'suppliersPage';
const currentStatePath = `${sectionName}.${sliceName}`;

const sliceConstants = {
	name: sliceName,
	parentSectionName: sectionName,
	currentStatePath,
	initialState: {
		// ******************************* invokeSuppliersPageResetter() *******************************
		shouldInvokeSupplierPageResetter: false,

		// ******************************* setSupplierCrudRelatedData() *******************************
		supplierCrudRelatedData: {
			currentCrudType: 'ADD',
			selectedItemData: {}, // Currently, Only applicable on EDIT mode. For example to pass editing Supplier's ID.
			additionalInfo: {},
		},
	},
};

export default sliceConstants;
