import {
    combineReducers,
    configureStore,
    getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";

import globalValues from "../configs/globalValues";

import customersPageSliceReducer, {
    name as customersPageSliceName,
} from "./slices/dashboard/customersPageSlice/customersPageSlice";
import { sectionName as dashboardSectionName } from "./slices/dashboard/dashboardConstants";
import dashboardSliceReducer, {
    name as dashboardSliceReducerName,
} from "./slices/dashboard/dashboardSlice/dashboardSlice";
import inventoryProductsPageSliceReducer, {
    name as inventoryProductsPageSliceName,
} from "./slices/dashboard/inventoryProductsPageSlice/inventoryProductsPageSlice";
import proformasPageSliceReducer, {
    name as proformasPageSliceName,
} from "./slices/dashboard/proformasPageSlice/proformasPageSlice";
import purchaseOrderPageSliceReducer, {
    name as purchaseOrderPageSliceName,
} from "./slices/dashboard/purchaseOrderPageSlice/purchaseOrderPageSlice";
import settingsSliceReducer, {
    name as settingsSliceReducerName,
} from "./slices/settingsSlice/settingsSlice";
import reportsSliceReducer, {
    name as reportsSliceReducerrName,
} from "./slices/reportsSlice/reportsSlice";
import userSliceReducer, {
    name as userSliceReducerName,
} from "./slices/userSlice/userSlice";
import supplierPageSliceReducer, {
    name as suppliersPageSliceName,
} from "./slices/dashboard/suppliersPageSlice/suppliersPageSlice";

/**
 * NOTE : We are using "redux-toolkit" helpers to setup and use Redux in our React App.
 */

const isDevelopment = globalValues.environment.IS_DEVELOPMENT;

const reduxLogger = createLogger({
    collapsed: true,
});

const middleware = [...getDefaultMiddleware()];

if (isDevelopment) {
    middleware.push(reduxLogger); // This must be the last middleware in chain.
}

//  NOTE : This store is pre configured with ReduxDevTools.
export const reduxStore = configureStore({
    reducer: combineReducers({
        [dashboardSectionName]: combineReducers({
            [dashboardSliceReducerName]: dashboardSliceReducer,
            [inventoryProductsPageSliceName]: inventoryProductsPageSliceReducer,
            [customersPageSliceName]: customersPageSliceReducer,
            [proformasPageSliceName]: proformasPageSliceReducer,
            [purchaseOrderPageSliceName]: purchaseOrderPageSliceReducer,
            [suppliersPageSliceName]: supplierPageSliceReducer,
        }),
        [userSliceReducerName]: userSliceReducer,
        [settingsSliceReducerName]: settingsSliceReducer,
        [reportsSliceReducerrName]: reportsSliceReducer,
    }),
    middleware: [...middleware],
    devTools: !!isDevelopment,
});

export default reduxStore;
