import { Input, Button, Select } from 'antd';
// import { Formik } from 'formik';
import React from 'react';

const PurchaseOrderSearchForm = (props) => {
	const isSubmitting = false;

	const handleChangeProductType = (val) => {
		props.handleChangeProductType(val);
	};

	const handleSelectProductType = (val) => {
		console.log("val", val)
		props.handleChangeProductType(val);
	}
	return (
		<div>
			<Input.Group compact>
				<Select
					style={{ width: '20%' }}
					defaultValue='0'
					onChange={handleChangeProductType}
					onSelect={handleSelectProductType}>
					<Select.Option key='0' value='0'>Draft</Select.Option>
					<Select.Option key='1' value='1'>Completed</Select.Option>
					<Select.Option key='2' value='2'>Partially GRN Created</Select.Option>
					<Select.Option key='3' value='3'>Confirmed</Select.Option>
					<Select.Option key='4' value='4'>Cancel</Select.Option>
				</Select>
				<Input style={{ width: '80%' }} />

			</Input.Group>
		</div>
	);
};

export default PurchaseOrderSearchForm;
