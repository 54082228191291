export const imageUploaderTypes = {
	primary: {
		label: 'PRIMARY IMAGES',
		type: 'PRIMARY_IMAGES',
	},
	unitChart: {
		label: 'UNITCHART IMAGES',
		type: 'UNITCHART_IMAGES',
	},
	special: {
		label: 'SPECIAL IMAGES',
		type: 'SPECIAL_IMAGES',
	},
};

// NOTE : These data are supposed to be received from API Endpoint. But until its available we define response here.
//        This determine what kind of "Custom Types" available to user which they can choose/enter values. (Relevant in "Custom Tab" and "Images Tab" )
//
// 				"customArrayKeyName"  --> This is the key which user added/selected data will be added in "CustomArray" key in Backend Product Data Obj.
export const customPropertyTypesList = [
	{
		customArrayKeyName: 'size',
		label: 'Size',
		inputType: 'input',
	},
	{
		customArrayKeyName: 'origin',
		inputType: 'input',
		label: 'Origin',
	},
	{
		customArrayKeyName: 'color',
		inputType: 'select',
		label: 'Color',
		options: [
			'',
			'Red',
			'Yellow',
			'Green',
			'Blue',
			'Purple',
			'Orange Black',
			'White',
		],
	},
	{
		customArrayKeyName: 'gender',
		inputType: 'select',
		label: 'Gender',
		options: ['', 'Male', 'Female'],
	},
	{
		customArrayKeyName: 'expiryDate',
		inputType: 'date',
		label: 'Expiry Date',
	},
	{
		customArrayKeyName: 'season',
		inputType: 'input',
		label: 'Season',
	},
];
