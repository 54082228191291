import { SettingOutlined as SettingsIcon } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import { HeaderBar } from 'ecologital-ui-library';
import { Field } from 'formik';
import {
	Checkbox,
	DatePicker,
	Form,
	Input,
	InputNumber,
	Select,
} from 'formik-antd';
import React, { Component } from 'react';
import { v4 as genUUID } from 'uuid';

import { getFormSectionsPartialObjPaths } from '../../helpers/parserHelpers';

import './PmvMasterTab.css';

const { TextArea } = Input;

// Default Form.Items Label Size.
const defaultLabelColProps = {
	span: 4,
	style: {
		whiteSpace: 'pre-wrap',
	},
};

// Form.Items Label Size used when Form is divided to two sections.
const dividedSectionLabelColProps = {
	...defaultLabelColProps,
	span: 6,
};

const {
	itemInfoObjPath,
	unitConfigurationObjPath,
	sellingInfoObjPath,
	purchaseInfoObjPath,
	stockInfoObjPath,
} = getFormSectionsPartialObjPaths().masterTab;

// Helper Component that enable setting up "Base Unit Type" and "Additional Unit Type" Settings
const UnitTypeConfigurator = (props = {}) => {
	const { unitTypeList = [], helperData = {}, helperFns = {} } = props;
	const { isAdditionalUnitTypeSelected } = helperData;

	return (
		<div>
			<Form layout='vertical'>
				<Form.Item
					name={`${unitConfigurationObjPath}baseUnitType`}
					label='Base Unit Type'>
					<Select
						name={`${unitConfigurationObjPath}baseUnitType`}
						options={unitTypeList
							.map((item, index) => {
								return {
									label: item.UnitTypeName,
									value: index,
									fullData: item,
								};
							})
							.filter((item) => item.fullData.IsBaseUnit)}
					/>
				</Form.Item>

				<Form.Item
					name={`${unitConfigurationObjPath}additionalUnitType`}
					label='Additional Unit Type'>
					<Select
						name={`${unitConfigurationObjPath}additionalUnitType`}
						options={unitTypeList
							.map((item, index) => {
								return {
									label: item.UnitTypeName,
									value: index,
									fullData: item,
								};
							})
							.filter((item) => !item.fullData.IsBaseUnit)}
						onSelect={(selectedTypeIndex) => {
							helperFns.onAdditionalUnitTypeChange(selectedTypeIndex);
						}}
					/>
				</Form.Item>

				<Form.Item
					name={`${unitConfigurationObjPath}additionalUnitTypeQuantity`}
					label="Additional Unit Type's Quantity">
					<InputNumber
						name={`${unitConfigurationObjPath}additionalUnitTypeQuantity`}
						precision={0}
						min={1}
						disabled={!isAdditionalUnitTypeSelected}
					/>
				</Form.Item>
			</Form>
		</div>
	);
};

class PmvMasterTab extends Component {
	generateHelperFunctionsAndDataToGetNecessaryInfo() {
		const { parserFunctions } = this.props;

		const { getFormValueByPath, setFormValueByPath, masterTabParserFns } =
			parserFunctions;

		const baseUnitTypeData = masterTabParserFns.getSelectedBaseUnitTypeData();
		const isBaseUnitTypeSelected = baseUnitTypeData.isSelected;
		const selectedBaseUnitTypeIndex = baseUnitTypeData.selectedIndex;
		const baseUnitType = baseUnitTypeData.selectedBaseUnitTypeObj;

		const additionalUnitTypeData =
			masterTabParserFns.getSelectedAdditionalUnitTypeData();
		const isAdditionalUnitTypeSelected = additionalUnitTypeData.isSelected;
		const selectedAdditionalUnitTypeIndex =
			additionalUnitTypeData.selectedIndex;
		const additionalUnitType =
			additionalUnitTypeData.selectedAdditionalUnitTypeObj;
		const additionalUnitTypeQuantity =
			additionalUnitTypeData.selectedAdditionalUnitTypeQuantity;

		const sellingUnitTypeData =
			masterTabParserFns.getSelectedSellingUnitTypeData();
		const isSellingUnitTypeSelected = sellingUnitTypeData.isSelected;
		const sellingUnitType = sellingUnitTypeData.selectedSellingUnitTypeObj;
		const isSellingUnitTypeABaseUnit = sellingUnitType.IsBaseUnit;
		const sellingPrice = getFormValueByPath(
			`${sellingInfoObjPath}sellingPrice`,
		);

		const purchaseUnitTypeData =
			masterTabParserFns.getSelectedPurchaseUnitTypeData();
		const isPurchaseUnitTypeSelected = purchaseUnitTypeData.isSelected;
		const purchaseUnitType = purchaseUnitTypeData.selectedPurchaseUnitTypeObj;
		const isPurchaseUnitTypeABaseUnit = purchaseUnitType.IsBaseUnit;
		const purchasePrice = getFormValueByPath(
			`${purchaseInfoObjPath}purchaseCost`,
		);

		const initialStockUnitTypeData =
			masterTabParserFns.getSelectedInitialStockUnitTypeData();
		const initialStockUnitType =
			initialStockUnitTypeData.selectedInitialStockUnitTypeObj;

		const minOrderLevelUnitTypeData =
			masterTabParserFns.getSelectedMinOrderLevelUnitTypeData();
		const minOrderLevelUnitType =
			minOrderLevelUnitTypeData.selectedMinOrderLevelUnitTypeObj;

		const maxOrderLevelUnitTypeData =
			masterTabParserFns.getSelectedMaxOrderLevelUnitTypeData();
		const maxOrderLevelUnitType =
			maxOrderLevelUnitTypeData.selectedMaxOrderLevelUnitTypeObj;

		function calculateAndUpdateSellingUnitPrice() {
			/**
			 * NOTE : This is a function to calculate and update values for Auto Calculated Fields. (By the way there is multiple function like this below for each Auto Calculated Fields)
			 *	- Main thing to notice here is, We directly mutate "formikProps.values". We can't use "formikProps.setFieldValue(xxx)" Formik inbuilt function to update values, because it doesn't work appropriately in this case.
						- Currently, There is multiple reasons for this.
								- To avoid multiple re-renders which ultimately cause very noticeable Input Lag in FormItems.
							- Since we get necessary values for calculation from "formikProps.values" and Depending on where this function is called, we receive un-updated old data.
			 *
			 */

			if (
				!isSellingUnitTypeSelected ||
				!sellingPrice ||
				!additionalUnitTypeQuantity
			) {
				setFormValueByPath(`${sellingInfoObjPath}unitPrice`, '');
				return;
			}

			if (isSellingUnitTypeABaseUnit) {
				setFormValueByPath(`${sellingInfoObjPath}unitPrice`, sellingPrice);
			} else {
				setFormValueByPath(
					`${sellingInfoObjPath}unitPrice`,
					sellingPrice / additionalUnitTypeQuantity,
				);
			}
		}

		function calculateAndUpdatePurchaseUnitPrice() {
			if (
				!isPurchaseUnitTypeSelected ||
				!purchasePrice ||
				!additionalUnitTypeQuantity
			) {
				setFormValueByPath(`${purchaseInfoObjPath}unitCost`, '');

				return;
			}

			if (isPurchaseUnitTypeABaseUnit) {
				setFormValueByPath(`${purchaseInfoObjPath}unitCost`, purchasePrice);
			} else {
				setFormValueByPath(
					`${purchaseInfoObjPath}unitCost`,
					purchasePrice / additionalUnitTypeQuantity,
				);
			}
		}

		function calculateAndUpdateSellingMargin() {
			const unitSellingPrice = getFormValueByPath(
				`${sellingInfoObjPath}unitPrice`,
			);
			const unitCostPrice = getFormValueByPath(
				`${purchaseInfoObjPath}unitCost`,
			);

			const profit = unitSellingPrice - unitCostPrice;

			const profitMarginAsPercentage = (profit / unitCostPrice) * 100;

			if (!unitSellingPrice || !unitCostPrice) {
				setFormValueByPath(`${sellingInfoObjPath}margin`, '');
				return;
			}

			setFormValueByPath(
				`${sellingInfoObjPath}margin`,
				profitMarginAsPercentage.toFixed(0),
			);
		}

		function calculateAndUpdateInitialStockValue() {
			const isInitialStockUnitTypeSelected =
				initialStockUnitTypeData.isSelected;

			const initialStock = getFormValueByPath(
				`${stockInfoObjPath}initialStock`,
			);

			const initialStockAsBaseUnit = initialStockUnitType.IsBaseUnit
				? initialStock
				: initialStock * additionalUnitTypeQuantity;

			const purchaseUnitCost = getFormValueByPath(
				`${purchaseInfoObjPath}unitCost`,
			);

			const totalStockValue = initialStockAsBaseUnit * purchaseUnitCost;

			if (
				!purchaseUnitCost ||
				!isInitialStockUnitTypeSelected ||
				!initialStock
			) {
				setFormValueByPath(`${stockInfoObjPath}initialStockValue`, '');
				return;
			}

			setFormValueByPath(
				`${stockInfoObjPath}initialStockValue`,
				totalStockValue,
			);
		}

		// Update "Unit Type" related Fields, When Additional Unity Type Changed.
		function onAdditionalUnitTypeChange(unitTypeIndex) {
			if (!sellingUnitType.IsBaseUnit) {
				setFormValueByPath(`${sellingInfoObjPath}unitType`, unitTypeIndex);
			}

			if (!purchaseUnitType.IsBaseUnit) {
				setFormValueByPath(`${purchaseInfoObjPath}unitType`, unitTypeIndex);
			}

			if (!initialStockUnitType.IsBaseUnit) {
				setFormValueByPath(
					`${stockInfoObjPath}initialStockUnitType`,
					unitTypeIndex,
				);
			}

			if (!minOrderLevelUnitType.IsBaseUnit) {
				setFormValueByPath(
					`${stockInfoObjPath}minOrderLevelUnitType`,
					unitTypeIndex,
				);
			}

			if (!maxOrderLevelUnitType.IsBaseUnit) {
				setFormValueByPath(
					`${stockInfoObjPath}maxOrderLevelUnitType`,
					unitTypeIndex,
				);
			}
		}

		function getSelectableUnitTypeList() {
			const selectableUnitList = [];

			if (isBaseUnitTypeSelected) {
				selectableUnitList.push({
					rawData: baseUnitType,
					parentArrayIndex: selectedBaseUnitTypeIndex,
				});
			}

			if (isAdditionalUnitTypeSelected) {
				selectableUnitList.push({
					rawData: additionalUnitType,
					parentArrayIndex: selectedAdditionalUnitTypeIndex,
				});
			}

			return selectableUnitList;
		}

		function getSubCategoryList() {
			const selectedCategoryData = masterTabParserFns.getSelectedCategoryData();

			return selectedCategoryData.isSelected
				? selectedCategoryData.selectedCategorySubCategoryObjList
				: [];
		}

		return {
			helperFns: {
				calculateAndUpdateSellingUnitPrice,
				calculateAndUpdatePurchaseUnitPrice,
				calculateAndUpdateSellingMargin,
				calculateAndUpdateInitialStockValue,
				onAdditionalUnitTypeChange,
				getSelectableUnitTypeList,
				getSubCategoryList,
			},
			helperData: {
				isAdditionalUnitTypeSelected,
			},
		};
	}

	render() {
		const {
			formikProps,

			categoryTypeList,
			brandTypeList,
			unitTypeList,

			parserFunctions,
			isEditMode,
			defaultCurrencyDetails,
		} = this.props;

		const { getFormValueByPath } = parserFunctions;

		const { helperFns, helperData } =
			this.generateHelperFunctionsAndDataToGetNecessaryInfo();

		// Updating Auto Calculating Fields without Re-Renderings.
		helperFns.calculateAndUpdateSellingUnitPrice();
		helperFns.calculateAndUpdatePurchaseUnitPrice();
		helperFns.calculateAndUpdateSellingMargin();
		helperFns.calculateAndUpdateInitialStockValue();

		const selectableUnitTypesOptions = helperFns
			.getSelectableUnitTypeList()
			.map((item) => {
				return {
					label: item.rawData.UnitTypeName,
					value: item.parentArrayIndex,
					fullData: item.rawData,
				};
			});

		return (
			<div className='PmvMasterTab'>
				<Form
					labelCol={{ ...defaultLabelColProps }}
					colon={false}
					labelAlign='left'>
					<div className='PmvMasterTab__itemInfoSection'>
						<HeaderBar subTitle={<b>ITEM INFORMATION</b>} />

						<Field name={`${itemInfoObjPath}itemType`}>
							{(itemTypeFieldProps) => {
								const { name: fieldName, value: fieldValue } =
									itemTypeFieldProps.field;

								return (
									<Form.Item name={fieldName} label='Item Type'>
										<Button
											type={fieldValue === 'PRODUCT' ? 'primary' : ''}
											onClick={() =>
												itemTypeFieldProps.form.setFieldValue(
													fieldName,
													'PRODUCT',
												)
											}>
											Product
										</Button>

										<Button
											disabled // NOTE : Temporally disabled, Because currently platform don't support services.
											type={fieldValue === 'PRODUCT' ? 'SERVICE' : ''}
											onClick={() =>
												itemTypeFieldProps.form.setFieldValue(
													fieldName,
													'SERVICE',
												)
											}>
											Service
										</Button>
									</Form.Item>
								);
							}}
						</Field>

						<Form.Item
							name={`${itemInfoObjPath}productName`}
							label='Product Name'>
							<Input
								name={`${itemInfoObjPath}productName`}
								onChange={(e) => {
									formikProps.setFieldValue(
										`${itemInfoObjPath}productName`,
										`${e.target.value.toUpperCase()}`,
									);
								}}
							/>
						</Form.Item>

						<Form.Item name={`${itemInfoObjPath}itemCode`} label='Item Code'>
							<div style={{ display: 'flex' }}>
								<Input name={`${itemInfoObjPath}itemCode`} />
								<Button
									onClick={() => {
										formikProps.setFieldValue(
											`${itemInfoObjPath}itemCode`,
											`${genUUID().toUpperCase()}`,
										);
									}}>
									Generate
								</Button>
							</div>
						</Form.Item>

						<Form.Item name={`${itemInfoObjPath}barcode`} label='Barcode'>
							<Input name={`${itemInfoObjPath}barcode`} />
						</Form.Item>

						<Form.Item
							name={`${itemInfoObjPath}productDescription`}
							label='Product Description'>
							<TextArea
								name={`${itemInfoObjPath}productDescription`}
								autoSize={{ minRows: 2, maxRows: 2 }}
							/>
						</Form.Item>

						<Form.Item name={`${itemInfoObjPath}category`} label='Category'>
							<Select
								name={`${itemInfoObjPath}category`}
								options={categoryTypeList.map((item, index) => {
									return {
										label: item.ProductCategory.Category,
										value: index,
										fullData: item,
									};
								})}
								onSelect={(selectedValue, selectedOption) => {
									formikProps.setFieldValue(
										`${itemInfoObjPath}vat`,
										selectedOption.fullData.ProductCategory.VatRate,
									);

									formikProps.setFieldValue(
										`${itemInfoObjPath}subCategory`,
										'',
									);
								}}
							/>
						</Form.Item>

						<Form.Item
							name={`${itemInfoObjPath}subCategory`}
							label='Sub Category'>
							<Select
								name={`${itemInfoObjPath}subCategory`}
								disabled={helperFns.getSubCategoryList().length === 0}
								options={helperFns.getSubCategoryList().map((item, index) => {
									return {
										label: item.Name,
										value: index,
										fullData: item,
									};
								})}
							/>
						</Form.Item>

						<Form.Item name={`${itemInfoObjPath}vat`} label='VAT'>
							<InputNumber
								name={`${itemInfoObjPath}vat`}
								precision={2}
								min={0}
								max={100}
								style={{ width: '100%' }}
							/>
						</Form.Item>

						<Form.Item name={`${itemInfoObjPath}brand`} label='Brand'>
							<Select
								name={`${itemInfoObjPath}brand`}
								options={brandTypeList.map((item, index) => {
									return { label: item.BrandName, value: index };
								})}
							/>
						</Form.Item>

						<Form.Item name={`${itemInfoObjPath}weight`} label='Weightable'>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Checkbox
									name={`${itemInfoObjPath}weightable`}
									onChange={() => {
										formikProps.setFieldValue(`${itemInfoObjPath}weight`, '');
										formikProps.setFieldTouched(
											`${itemInfoObjPath}weight`,
											false,
										);

										formikProps.setFieldValue(
											`${itemInfoObjPath}weightType`,
											'G',
										);
									}}
								/>

								<div style={{ display: 'flex', marginLeft: '10px' }}>
									<InputNumber
										name={`${itemInfoObjPath}weight`}
										min={0}
										disabled={
											getFormValueByPath(`${itemInfoObjPath}weightable`) ===
											false
										}
									/>

									<Select
										name={`${itemInfoObjPath}weightType`}
										disabled={
											getFormValueByPath(`${itemInfoObjPath}weightable`) ===
											false
										}
										options={['G', 'KG'].map((item) => {
											return { label: item, value: item };
										})}
									/>
								</div>
							</div>
						</Form.Item>

						<Form.Item
							name={`${itemInfoObjPath}sellOnline`}
							label='Sell Online'>
							<Checkbox name={`${itemInfoObjPath}sellOnline`} />
						</Form.Item>

						<Form.Item name={`${itemInfoObjPath}active`} label='Active'>
							<Checkbox name={`${itemInfoObjPath}active`} />
						</Form.Item>
					</div>

					<div className='PmvMasterTab__unitTypeConfigSection'>
						<Popover
							content={
								<UnitTypeConfigurator
									unitTypeList={unitTypeList}
									helperFns={helperFns}
									helperData={helperData}
								/>
							}
							trigger='click'>
							<Button icon={<SettingsIcon />}>Unit Chart Configuration</Button>
							<div
								style={{
									color: 'red',
									fontSize: 'smaller',
									textAlign: 'right',
									minHeight: '18px', // To avoid content shifts.
								}}>
								{formikProps.errors.masterSection &&
								formikProps.errors.masterSection.unitConfiguration
									? 'ERRORS INSIDE*'
									: ''}
							</div>
						</Popover>
					</div>

					<div className='PmvMasterTab__sellingInfoSection'>
						<HeaderBar subTitle={<b>SELLING INFORMATION</b>} />

						<div className='ProductMasterView-UTL--flexContainer'>
							<div>
								<Form.Item
									name={`${sellingInfoObjPath}sellingPrice`}
									label='Selling Price'
									labelCol={{ ...dividedSectionLabelColProps }}>
									<div className='PmvMasterTab____priceInputGrouper'>
										<span className='PmvMasterTab____priceInputGrouper__prefix'>
											{defaultCurrencyDetails
												? defaultCurrencyDetails.CurrencySymbol
												: ''}
										</span>
										<span className='PmvMasterTab____priceInputGrouper__input'>
											<InputNumber
												name={`${sellingInfoObjPath}sellingPrice`}
												precision={2}
												min={0}
											/>
										</span>
										<span className='PmvMasterTab____priceInputGrouper__suffix'>
											INC. VAT
										</span>
									</div>
								</Form.Item>

								<Form.Item
									className='ProductMasterView____autoCalcField'
									name={`${sellingInfoObjPath}margin`}
									label='Margin'
									labelCol={{ ...dividedSectionLabelColProps }}>
									{getFormValueByPath(`${sellingInfoObjPath}margin`) && (
										<div className='PmvMasterTab____priceInputGrouper'>
											<span className='PmvMasterTab____priceInputGrouper__prefix'>
												{defaultCurrencyDetails
													? defaultCurrencyDetails.CurrencySymbol
													: ''}
											</span>
											<span className='PmvMasterTab____priceInputGrouper__input'>
												{Number(
													getFormValueByPath(`${sellingInfoObjPath}margin`),
												).toFixed(0)}

												{/* <Input
												name={`${sellingInfoObjPath}margin`}
												disabled
												bordered={false}
											/> */}
											</span>
											<span className='PmvMasterTab____priceInputGrouper__suffix'>
												%
											</span>
										</div>
									)}
								</Form.Item>

								<Form.Item
									name={`${sellingInfoObjPath}salesAccountCode`}
									label='Sales Account Code'
									labelCol={{ ...dividedSectionLabelColProps }}>
									<Select name={`${sellingInfoObjPath}salesAccountCode`} />
								</Form.Item>
							</div>

							<div>
								<Form.Item
									name={`${sellingInfoObjPath}unitType`}
									label='Unit Type'
									labelCol={{ ...dividedSectionLabelColProps }}>
									<div style={{ display: 'flex' }}>
										<Select
											name={`${sellingInfoObjPath}unitType`}
											options={selectableUnitTypesOptions}
										/>
									</div>
								</Form.Item>

								<Form.Item
									className='ProductMasterView____autoCalcField'
									name={`${sellingInfoObjPath}unitPrice`}
									label='Unit Price'
									labelCol={{ ...dividedSectionLabelColProps }}>
									{getFormValueByPath(`${sellingInfoObjPath}unitPrice`) && (
										<div className='PmvMasterTab____priceInputGrouper'>
											<span className='PmvMasterTab____priceInputGrouper__prefix'>
												{defaultCurrencyDetails
													? defaultCurrencyDetails.CurrencySymbol
													: ''}
											</span>
											<span className='PmvMasterTab____priceInputGrouper__input'>
												{Number(
													getFormValueByPath(`${sellingInfoObjPath}unitPrice`),
												).toFixed(2)}

												{/* <InputNumber
													name={`${sellingInfoObjPath}unitPrice`}
													precision={2}
													min={0}
													disabled
													bordered={false}
												/> */}
											</span>
											<span className='PmvMasterTab____priceInputGrouper__suffix'>
												INC. VAT
											</span>
										</div>
									)}
								</Form.Item>
							</div>
						</div>
					</div>

					<div className='PmvMasterTab__purchaseInfoSection'>
						<HeaderBar subTitle={<b>PURCHASE INFORMATION</b>} />

						<div className='ProductMasterView-UTL--flexContainer'>
							<div>
								<Form.Item
									name={`${purchaseInfoObjPath}purchaseCost`}
									label='Purchase Cost'
									labelCol={{ ...dividedSectionLabelColProps }}>
									<div className='PmvMasterTab____priceInputGrouper'>
										<span className='PmvMasterTab____priceInputGrouper__prefix'>
											{defaultCurrencyDetails
												? defaultCurrencyDetails.CurrencySymbol
												: ''}
										</span>
										<span className='PmvMasterTab____priceInputGrouper__input'>
											<InputNumber
												name={`${purchaseInfoObjPath}purchaseCost`}
												precision={2}
												min={0}
											/>
										</span>
										<span className='PmvMasterTab____priceInputGrouper__suffix'>
											EXC. VAT
										</span>
									</div>
								</Form.Item>

								<Form.Item
									name={`${purchaseInfoObjPath}preferredSupplier`}
									label='Preferred Supplier'
									labelCol={{ ...dividedSectionLabelColProps }}>
									<Select name={`${purchaseInfoObjPath}preferredSupplier`} />
								</Form.Item>

								<Form.Item
									name={`${purchaseInfoObjPath}purchaseAccountCode`}
									label='Purchase Account Code'
									labelCol={{ ...dividedSectionLabelColProps }}>
									<Select name={`${purchaseInfoObjPath}purchaseAccountCode`} />
								</Form.Item>
							</div>

							<div>
								<Form.Item
									name={`${purchaseInfoObjPath}unitType`}
									label='Unit Type'
									labelCol={{ ...dividedSectionLabelColProps }}>
									<Select
										name={`${purchaseInfoObjPath}unitType`}
										options={selectableUnitTypesOptions}
									/>
								</Form.Item>

								<Form.Item
									className='ProductMasterView____autoCalcField'
									name={`${purchaseInfoObjPath}unitCost`}
									label='Unit Cost'
									labelCol={{ ...dividedSectionLabelColProps }}>
									{getFormValueByPath(`${purchaseInfoObjPath}unitCost`) && (
										<div className='PmvMasterTab____priceInputGrouper'>
											<span className='PmvMasterTab____priceInputGrouper__prefix'>
												{defaultCurrencyDetails
													? defaultCurrencyDetails.CurrencySymbol
													: ''}
											</span>
											<span className='PmvMasterTab____priceInputGrouper__input'>
												{Number(
													getFormValueByPath(`${purchaseInfoObjPath}unitCost`),
												).toFixed(2)}

												{/* <InputNumber
												name={`${purchaseInfoObjPath}unitCost`}
												precision={2}
												min={0}
												disabled
												bordered={false}
											/> */}
											</span>
											<span className='PmvMasterTab____priceInputGrouper__suffix'>
												INC. VAT
											</span>
										</div>
									)}
								</Form.Item>
							</div>
						</div>
					</div>

					<div className='PmvMasterTab__stockInfoSection'>
						<HeaderBar subTitle={<b>STOCK INFORMATION</b>} />

						<div className='ProductMasterView-UTL--flexContainer'>
							<div>
								<Form.Item
									name={`${stockInfoObjPath}initialStock`}
									label='Openning Stock' // AKA "Initial Stock"
									labelCol={{ ...dividedSectionLabelColProps }}>
									<div style={{ display: 'flex' }}>
										<InputNumber
											name={`${stockInfoObjPath}initialStock`}
											precision={0}
											min={0}
											disabled={isEditMode}
										/>
										<Select
											name={`${stockInfoObjPath}initialStockUnitType`}
											options={selectableUnitTypesOptions}
											disabled={isEditMode}
										/>
									</div>
								</Form.Item>

								<Form.Item
									name={`${stockInfoObjPath}initialStockDate`}
									label='As of Date'
									labelCol={{ ...dividedSectionLabelColProps }}>
									<DatePicker
										name={`${stockInfoObjPath}initialStockDate`}
										disabled
										allowClear={false}
									/>
								</Form.Item>

								<Form.Item
									className='ProductMasterView____autoCalcField'
									name={`${stockInfoObjPath}initialStockValue`}
									label='Openning Stock Total'
									labelCol={{ ...dividedSectionLabelColProps }}>
									{getFormValueByPath(
										`${stockInfoObjPath}initialStockValue`,
									) && (
										<div className='PmvMasterTab____priceInputGrouper'>
											<span className='PmvMasterTab____priceInputGrouper__prefix'>
												{defaultCurrencyDetails
													? defaultCurrencyDetails.CurrencySymbol
													: ''}
											</span>
											<span className='PmvMasterTab____priceInputGrouper__input'>
												{Number(
													getFormValueByPath(
														`${stockInfoObjPath}initialStockValue`,
													),
												).toFixed(2)}

												{/* <InputNumber
												name={`${stockInfoObjPath}initialStockValue`}
												disabled
												precision={2}
												bordered={false}
											/> */}
											</span>
											<span className='PmvMasterTab____priceInputGrouper__suffix'>
												EXC. VAT
											</span>
										</div>
									)}
								</Form.Item>
							</div>

							<div>
								<Form.Item
									name={`${stockInfoObjPath}minOrderLevel`}
									label='Min Order Level'
									labelCol={{ ...dividedSectionLabelColProps }}>
									<div style={{ display: 'flex' }}>
										<InputNumber
											name={`${stockInfoObjPath}minOrderLevel`}
											min={0}
											precision={0}
										/>

										<Select
											name={`${stockInfoObjPath}minOrderLevelUnitType`}
											options={selectableUnitTypesOptions}
										/>
									</div>
								</Form.Item>

								<Form.Item
									name={`${stockInfoObjPath}maxOrderLevel`}
									label='Max Order Level'
									labelCol={{ ...dividedSectionLabelColProps }}>
									<div style={{ display: 'flex' }}>
										<InputNumber
											name={`${stockInfoObjPath}maxOrderLevel`}
											min={0}
											precision={0}
										/>
										<Select
											name={`${stockInfoObjPath}maxOrderLevelUnitType`}
											options={selectableUnitTypesOptions}
										/>
									</div>
								</Form.Item>
							</div>
						</div>
					</div>
				</Form>
			</div>
		);
	}
}

export default PmvMasterTab;
