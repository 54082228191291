import { reqStatusTypes } from '../../../helpers/constants';
import { sectionName } from '../dashboardConstants';

const sliceName = 'inventoryProductsPage';
const currentStatePath = `${sectionName}.${sliceName}`;

const sliceConstants = {
	name: sliceName,
	parentSectionName: sectionName,
	currentStatePath,
	initialState: {
		// ******************************* invokeInventoryProductsPageResetter() *******************************
		shouldInvokeInventoryProductsPageResetter: false,

		// ******************************* toggleProductAddEditModal() *******************************
		isProductAddEditModalVisible: false,
		productAddEditModalType: 'ADD',
		productAddEditModalAdditionalInfo: {}, // Keep data such as opened Product's ID.
	},
};

export default sliceConstants;
