/* eslint-disable no-param-reassign */

import { reqStatusTypes } from '../../../helpers/constants';

import {
	getFullUserOrgDetailsByUserId,
	setLocationSelectorData,
} from './dashboardSliceExtraActions';

const extraReducers = {
	// ******************************* getFullUserOrgDetailsByUserId() *******************************
	[getFullUserOrgDetailsByUserId.pending]: (state, action) => {
		state.getFullUserOrgDetailsByUserIdReqStatus = reqStatusTypes.pending;
		state.getFullUserOrgDetailsByUserIdReqError = '';
	},

	[getFullUserOrgDetailsByUserId.fulfilled]: (state, action) => {
		state.getFullUserOrgDetailsByUserIdReqStatus = reqStatusTypes.succeeded;
		state.fullUserOrgDetailsList = action.payload;
	},

	[getFullUserOrgDetailsByUserId.rejected]: (state, action) => {
		state.getFullUserOrgDetailsByUserIdReqStatus = reqStatusTypes.failed;
		state.getFullUserOrgDetailsByUserIdReqError = action.error.message;
		state.fullUserOrgDetailsList = [];
	},

	// ******************************* setLocationSelectorData() *******************************
	[setLocationSelectorData]: (state, action) => {
		const { orgIndex, orgId, comIndex, comId, locIndex, locId, locName, storesList } =
			action.payload || {};

		state.currentlySelectedOrgIndex = orgIndex; // This track which index of above 'state.fullUserOrgDetailsList' array is currently selected.
		state.currentlySelectedOrgId = orgId;

		state.currentlySelectedCompanyIndex = comIndex;
		state.currentlySelectedCompanyId = comId;

		state.currentlySelectedLocationIndex = locIndex;
		state.currentlySelectedLocationId = locId;
		state.currentlySelectedLocationName = locName;
		state.storesList = storesList;
	},
};

export default extraReducers;
