/* eslint-disable no-shadow */
import { PlusCircleOutlined as AddIcon } from '@ant-design/icons';
import { bindActionCreators } from '@reduxjs/toolkit';
import { Button } from 'antd';
import { HeaderBar } from 'ecologital-ui-library';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ProductMasterView from '../../../../components/dashboardRelatedComps/ProductMasterView/ProductMasterView';
import ProductSearcher from '../../../../components/dashboardRelatedComps/ProductSearcher/ProductSearcher';
import CustomModal from '../../../../components/widgets/CustomModal/CustomModal';
import { sectionName as dashboardSectionName } from '../../../../redux/slices/dashboard/dashboardConstants';
import { actions as inventoryProductsPageSliceActions } from '../../../../redux/slices/dashboard/inventoryProductsPageSlice/inventoryProductsPageSlice';

import './InventoryProductsPage.css';

class InventoryProductsPage extends Component {
	render() {
		const {
			inventoryProductsPageSliceState,
			inventoryProductsPageSliceActions,
		} = this.props;

		const { isProductAddEditModalVisible, productAddEditModalType } =
			inventoryProductsPageSliceState;
		const { toggleProductAddEditModal } = inventoryProductsPageSliceActions;

		return (
			<div className='InventoryProductsPage'>
				<CustomModal
					modalProps={{
						title:
							productAddEditModalType === 'ADD'
								? 'ADD PRODUCT'
								: 'EDIT PRODUCT',
						visible: isProductAddEditModalVisible,
						maskClosable: false,
						keyboard: false,
						onCancel: () => {
							toggleProductAddEditModal();
						},
					}}>
					<ProductMasterView />
				</CustomModal>

				<HeaderBar title='Inventory Products' />

				<div className='InventoryProductsPage__toolbar'>
					{/* <Button
						category="text"
						title="Add Pro"
						variant='primary'
						size='small'
						// type="button"
						onClick={() => {
							toggleProductAddEditModal({
								type: 'ADD',
							});
						}} 
					/> */}
					<Button
						variant='primary'
						size='small'
						onClick={() => {
							toggleProductAddEditModal({
								type: 'ADD',
							});
						}}>
						Add Product
					</Button>
	
				</div>

				<ProductSearcher productSearcherType='genericType' />
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	inventoryProductsPageSliceState:
		state[dashboardSectionName].inventoryProductsPage,
});

const mapDispatchToProps = (dispatch) => {
	const boundInventoryProductsPageSliceActions = bindActionCreators(
		inventoryProductsPageSliceActions,
		dispatch,
	);

	return {
		inventoryProductsPageSliceActions: boundInventoryProductsPageSliceActions,
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(InventoryProductsPage);
