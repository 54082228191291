import React, { useState, useEffect } from 'react';
import {
	Col,
	Icon,
	Row,
	Stack,
	StackItem,
	Typography,
	Alert,
} from 'witmeg-ui-system';

const OfferTypeForm = ({
	handleChangeOfferInfoInputs,
	basicInformationValues,
	basicInfoValidationErrors,
	missingBasicFieldNames,
	enableEditMode,
	validationMessage,
	selectedCard,
	setTheCard,
}) => {
	const [promotionTypes, setPromotionTypes] = useState([
		{
			promotionID: '1',
			promotionName: 'Single/Bundle Promotion',
			promotionDescription:
				'small description about single or bundle promotion',
			icon: 'blue-tag',
		},
	]);

	const setSelectedPromotionType = (id, fieldName) => {
		// console.log("id", id);
		handleChangeInput(id, fieldName);
		setTheCard(id);
	};

	useEffect(() => {
		let isMounted = false;
		if (basicInformationValues?.promotionType) {
			!isMounted && setTheCard('1');
		} else {
			setTheCard(null);
		}
		return () => (isMounted = true);
	}, [basicInformationValues?.promotionType]);

	// const handleChangeInput = (e, fieldName) => {
	//     console.log('event handler result',e ,'and', fieldName)
	//     let updatedCategoryInformationIValues = {
	//       ...categoryInformationValues,

	//       [fieldName]: e.target.type === 'checkbox' ? e.target.checked : e.target.type === 'number' ? Number(e.target.value): e
	//     }
	//     console.log('updatedCategoryInformationIValues new ',updatedCategoryInformationIValues)
	//     // handleChangeCategoryInfoInputs(updatedCategoryInformationIValues, fieldName)
	// }

	const handleChangeInput = (e, fieldName) => {
		let updatedBasicInformationValues = {
			...basicInformationValues,
			[fieldName]: e,
		};
		// let obj = {
		//     updatedBasicInformationValues: updatedBasicInformationValues
		// }
		handleChangeOfferInfoInputs(
			updatedBasicInformationValues,
			fieldName
		);
	};

	return (
		<Row justify="center" gutter={[24, 24]}>
			{validationMessage && (
				<Alert
					message={validationMessage}
					showIcon
					type="error"
				/>
			)}
			<Col span={24} className="eco-mb-24">
				<Typography
					type="title"
					level={4}
					pattern="bold"
					className="eco-mt-24"
					alignment="center"
				>
					Select the offer type
				</Typography>
			</Col>
			{promotionTypes.map((promotion) => {
				return (
					<Row
						span={10}
						gutter={[38, 38]}
						justify="center"
						className="offer-type-card-row"
					>
						<Col>
							<Stack
								id={promotion.promotionID}
								direction="column"
								className={
									selectedCard ==
									promotion.promotionID
										? 'selected-offer-card offer-type-card'
										: 'offer-type-card'
								}
								onClick={() => {
									setSelectedPromotionType(
										promotion.promotionID,
										'promotionType'
									);
								}}
							>
								<StackItem className="eco-mb-16">
									<Icon
										name={promotion.icon}
										size="32"
									/>
								</StackItem>
								<StackItem className="eco-mb-16">
									<Typography
										type="title"
										level={4}
										pattern="bold"
									>
										{promotion.promotionName}
									</Typography>
								</StackItem>
								<StackItem className="eco-mb-16">
									<Typography
										type="text"
										color="gray_base"
									>
										{
											promotion.promotionDescription
										}
									</Typography>
								</StackItem>
								<StackItem alignment="center">
									<div>
										{selectedCard ==
										promotion.promotionID ? (
											<Icon
												name="blue-check"
												size="44"
											/>
										) : (
											<Icon
												name="not-complete"
												size="44"
											/>
										)}
									</div>
								</StackItem>
							</Stack>
						</Col>
					</Row>
				);
			})}
		</Row>
	);
};

export default OfferTypeForm;
