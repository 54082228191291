// import { Col, Divider, Row, Space } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { Button, ButtonGroup, Stack, Typography, Col, Row, } from 'witmeg-ui-system';
import DefaultImage from "../../../../../../assets/img/default.jpg";
import CategoryOverviewImageSlider from './CategoryOverviewImageSlider';
// import SalesChart from './SalesChart';

export default function ProductOverview({ category }) {
    const settingsState = useSelector(state => state.settings.currencySettings)
    console.log("settingsState ==>>>", settingsState);
    console.log("Category==== ==>>>", category);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div>
            {category ?
                <Row gutter={[16, 16]}>
                    <Col span={19}>
                        <Typography type="title" level={6} textTransform="capitalize" className="eco-mb-8">{category.ProductCategory.Category}</Typography>
                        <Typography type="text" color="gray_base" size="small">{category.ProductCategory.ProductDescription}</Typography>
                    </Col>
                    <Col span={5}>
                        <Typography type="title" level={6} pattern="bold" className="eco-mb-8">{category.ProductCategory.VatRate * 100}%</Typography>
                        <Typography type="text" color="gray_base" size="small">VAT RATE</Typography>
                    </Col>
                </Row>
                : null
            }


            {
                category && category.ProductSubCategory.length ? <div style={{ position: 'relative', width: '100%', marginBottom: 30, marginTop: 30 }}>
                    <CategoryOverviewImageSlider subCategories={category.ProductSubCategory} />
                </div> : ""
            }

            {/*}
            <div className="eco-mt-16 eco-ph-16 eco-divider-t">
                {/* TODO: @Dhanuja remove the alert component, component already exists *
                <Stack horizontalAlign="space_between" className="eco-mb-24">
                    <ButtonGroup toggle={true} variant="left">
                        <Button
                            category="icon"
                            size="small"
                            children="chart"
                            iconSize={20}
                        />
                        <Button
                            category="icon"
                            size="small"
                            children="table"
                            iconSize={20}
                        />
                    </ButtonGroup>
                    <div>
                    {/* <Select
                        style={{
                            width: '100%',
                        }}
                        value={{
                            label: "Abc",
                            value: "Abc",
                        }}
                        options={[
                            {
                                label: "Abc",
                                value: "Abc",
                            }
                        ]}
                    /> *
                    </div>
                </Stack>
                <Stack horizontalAlign="space_between" className="eco-mb-24">
                    
                </Stack>
                <Stack className="eco-mb-24">
                    {/* TODO @Dhanuja - Chart Integration *
                    <div>
                        {/* <Chart
                            labels={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']}
                            datasets={[
                                {
                                    label: 'Dataset 1',
                                    data: [100, 200, 300, 400, 450, 850, 600, 600, 300, 100, 50, 1000],
                                    borderColor: 'rgb(255, 99, 132)',
                                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                },
                                {
                                    label: 'Dataset 2',
                                    data: [600, 600, 300, 100, 50, 1000, 100, 200, 300, 400, 450, -850],
                                    borderColor: 'rgb(53, 162, 235)',
                                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                }
                            ]}
                        /> *
                    </div>
                </Stack>
            </div>
            */}
        </div>
    )
}
