import lodash from 'lodash';
import * as Yup from 'yup';

import { generateGenericRawProductImagesKeyDataParserFunctions } from './otherHelpers';
import { imageUploaderTypes } from './placeholderData';

// Common "Error Texts" to be used in Yup Validations.
export const errorTexts = {
	required: 'Required.',
	tooShort: 'Too Short.',
	tooLong: 'Too Long.',
	positiveNumber: 'Must be a Positive Number',
	numberRequired: 'Required.',
};

// Generate Full "Base Initial State" for all the forms in different tabs.
// NOTE : This is declared as function because,
// 					- For some mode (Especially "EDIT" mode) we have to pass and set already existing values as form initial values.
// 					- For some keys we have to calculate dynamic initial state on run. So for those keys we can pass dynamic function that return necessary value.
export const generateFullBaseInitialState = (data = {}) => {
	const {
		preFilledInitialValues = {}, // Values passed in here will be merged with Default Initial State values.
		dynamicInitialValueGetters = {},
	} = data;

	// Simple helper function to be used as default parameter to avoid runtime errors.
	const defaultFunction = () => '';

	const { getDefaultBaseUnitType = defaultFunction } =
		dynamicInitialValueGetters;

	const defaultInitialState = {
		// Form in Master Tab.
		masterSection: {
			itemInformation: {
				itemType: 'PRODUCT', // Can be "PRODUCT" OR "SERVICE"
				productName: '',
				itemCode: '',
				barcode: '',
				productDescription: '',
				category: '',
				subCategory: '',
				vat: '',
				brand: '',
				weightable: false,
				weight: '',
				weightType: 'G', // Can be "G" OR "KG"
				sellOnline: false,
				active: true,
			},

			unitConfiguration: {
				baseUnitType: getDefaultBaseUnitType(),
				additionalUnitType: '',
				additionalUnitTypeQuantity: 1,
			},

			sellingInformation: {
				sellingPrice: '',
				unitType: getDefaultBaseUnitType(),

				// Below are Auto Calculated based on Other Inputs.
				unitPrice: '',
				margin: '',

				// Below are Not Available yet.
				salesAccountCode: '',
			},

			purchaseInformation: {
				purchaseCost: '',
				unitType: getDefaultBaseUnitType(),

				// Below are Auto Calculated based on Other Inputs.
				unitCost: '',

				// Below are Not Available yet.
				preferredSupplier: '',
				purchaseAccountCode: '',
			},

			stockInformation: {
				initialStock: '',
				initialStockUnitType: getDefaultBaseUnitType(),
				initialStockDate: new Date(),
				initialStockValue: '',
				minOrderLevel: '',
				minOrderLevelUnitType: getDefaultBaseUnitType(),
				maxOrderLevel: '',
				maxOrderLevelUnitType: getDefaultBaseUnitType(),
			},
		},

		// Form in Custom Tab.
		customSection: {
			customInformation: {
				// Depending on CustomType received from API, user entered/selected fields data will be added here. (Ex. Color: 'Red')
			},
		},

		// Form in Images Tab.
		imageSection: {
			currentImageInformation: {
				primaryImageList: [],
				unitChartImageList: [],
				specialImageList: [],
			},

			imageUploaderInformation: {
				uploaderOpened: false,

				imageMainType: '',
				imageFile: '',
				imageUnitType: '', // Only applicable when "imageMainType" is 'UnitType Images".
				imageCustomType: '', // Only applicable when "imageMainType" is 'Special Images".
				imageCustomTypeValue: '',

				customSizeWidth: 500,
				customSizeHeight: 500,
				customSizeUnit: 'PX',
				imageQuality: 100,
				alphaValue: 100,
				waterMark: '',
				fontSize: 12,
			},
		},
	};

	return lodash.merge(defaultInitialState, preFilledInitialValues);
};

// Generate Full "Validation Scheme" for all the forms in different tabs.
// This structure is/should similar to above "fullBaseInitialState" Object.
export const fullValidationScheme = Yup.object().shape({
	// Form in Master Tab.
	masterSection: Yup.object().shape({
		itemInformation: Yup.object().shape({
			itemType: Yup.string().required(errorTexts.required),
			productName: Yup.string()
				.min(2, errorTexts.tooShort)
				.max(50, errorTexts.tooLong)
				.required(errorTexts.required),
			itemCode: Yup.string()
				.min(2, errorTexts.tooShort)
				.max(40, errorTexts.tooLong)
				.required(errorTexts.required),
			barcode: Yup.string()
				.min(2, errorTexts.tooShort)
				.max(20, errorTexts.tooLong)
				.required(errorTexts.required),
			productDescription: Yup.string().min(2, errorTexts.tooShort),
			category: Yup.string().required(errorTexts.required),
			subCategory: Yup.string().notRequired(),
			vat: Yup.number()
				.typeError(errorTexts.numberRequired)
				.min(0, 'Should be 0-100 %')
				.max(100, 'Should be 0-100 %')
				.required(errorTexts.required),
			brand: Yup.string().notRequired(),
			weightable: Yup.bool().notRequired(),
			weight: Yup.number()
				.typeError('You Must Specify a Weight.')
				.when('weightable', {
					is: true,
					then: Yup.number()
						.typeError(errorTexts.numberRequired)
						.positive(errorTexts.positiveNumber)
						.required(errorTexts.required),
					otherwise: Yup.number().notRequired(),
				}),
			weightType: Yup.string().when('weightable', {
				is: true,
				then: Yup.string().required(errorTexts.required),
				otherwise: Yup.string().notRequired(),
			}),
			sellOnline: Yup.bool().notRequired(),
			active: Yup.bool().notRequired(),
		}),

		unitConfiguration: Yup.object().shape({
			baseUnitType: Yup.string().required(errorTexts.required),
			additionalUnitType: Yup.string().notRequired(),
			additionalUnitTypeQuantity: Yup.number().when('additionalUnitType', {
				is: (val) => {
					return Number.isInteger(Number(val));
				},
				then: Yup.number()
					.typeError(errorTexts.numberRequired)
					.min(1, 'Minimum Quantity is One')
					.positive(errorTexts.positiveNumber)
					.required(errorTexts.required),
				otherwise: Yup.number().notRequired(),
			}),
		}),

		sellingInformation: Yup.object().shape({
			sellingPrice: Yup.number()
				.typeError(errorTexts.numberRequired)
				.positive('Price Should Be Larger Than 0')
				.required(errorTexts.required),
			unitType: Yup.string().required(errorTexts.required),

			// Below are Auto Calculated based on Other Inputs OR Not Available Yet.
			unitPrice: Yup.number().positive(errorTexts.positiveNumber).notRequired(),
			margin: Yup.number().notRequired(),
			salesAccountCode: Yup.string().notRequired(),
		}),

		purchaseInformation: Yup.object().shape({
			purchaseCost: Yup.number()
				.typeError(errorTexts.numberRequired)
				.positive('Price Should Be Larger Than 0')
				.required(errorTexts.required),
			unitType: Yup.string().required(errorTexts.required),

			// Below are Auto Calculated based on Other Inputs OR Not Available Yet.
			unitCost: Yup.number().positive(errorTexts.positiveNumber).notRequired(),
			preferredSupplier: Yup.string().notRequired(),
			purchaseAccountCode: Yup.string().notRequired(),
		}),

		stockInformation: Yup.object().shape({
			initialStock: Yup.number()
				.typeError('Must have a Number.')
				.min(0, 'Cannot Be Negative')
				.notRequired(),
			initialStockUnitType: Yup.string().when('initialStock', {
				is: (val) => {
					return Number.isInteger(Number(val));
				},
				then: Yup.string().required(errorTexts.required),
				otherwise: Yup.string().notRequired(),
			}),
			initialStockDate: Yup.date().notRequired(),

			// Below are Auto Calculated based on Other Inputs OR Not Available Yet.
			initialStockValue: Yup.number()
				.min(0, 'Cannot Be Negative')
				.notRequired(),

			minOrderLevel: Yup.number()
				.typeError('Must Have a Number.')
				.min(0, 'Cannot Be Negative')
				.notRequired(),
			minOrderLevelUnitType: Yup.string().when('minOrderLevel', {
				is: (val) => {
					return Number.isInteger(Number(val));
				},
				then: Yup.string().required(errorTexts.required),
				otherwise: Yup.string().notRequired(),
			}),
			maxOrderLevel: Yup.number()
				.typeError('Must Have a Number.')
				.min(0, 'Cannot Be Negative'),
			maxOrderLevelUnitType: Yup.string().when('maxOrderLevel', {
				is: (val) => {
					return Number.isInteger(Number(val));
				},
				then: Yup.string().required(errorTexts.required),
				otherwise: Yup.string().notRequired(),
			}),
		}),
	}),

	customSection: Yup.object().shape({
		customInformation: Yup.object().shape({
			// Depending on CustomType received from API, Fields Available Wary. So no specific validations for these yet.
		}),
	}),

	imageSection: Yup.object().shape({
		currentImageInformation: Yup.object().shape({
			primaryImageList: Yup.array().notRequired(),
			unitChartImageList: Yup.array().notRequired(),
			specialImageList: Yup.array().notRequired(),
		}),

		imageUploaderInformation: Yup.object().shape({
			uploaderOpened: Yup.bool().required(errorTexts.required),

			imageMainType: Yup.string().when('uploaderOpened', {
				is: true,
				then: Yup.string().required(errorTexts.required),
				otherwise: Yup.string().notRequired(),
			}),
			imageFile: Yup.string().when('uploaderOpened', {
				is: true,
				then: Yup.string().required(errorTexts.required),
				otherwise: Yup.string().notRequired(),
			}),
			imageUnitType: Yup.string().when(['uploaderOpened', 'imageMainType'], {
				// Validating When "uploaderOpened" is true and "imageMainType" is UnitChartImage type.
				is: (uploaderOpenedVal, imageMainTypeVal) => {
					if (uploaderOpenedVal === false) {
						return false;
					} else if (imageMainTypeVal === imageUploaderTypes.unitChart.type) {
						return true;
					} else {
						return false;
					}
				},
				then: Yup.string().required(errorTexts.required),
				otherwise: Yup.string().notRequired(),
			}),
			imageCustomType: Yup.string().when(['uploaderOpened', 'imageMainType'], {
				// Validating When "uploaderOpened" is true and "imageMainType" is SpecialImage type.
				is: (uploaderOpenedVal, imageMainTypeVal) => {
					if (uploaderOpenedVal === false) {
						return false;
					} else if (imageMainTypeVal === imageUploaderTypes.special.type) {
						return true;
					} else {
						return false;
					}
				},
				then: Yup.string().required(errorTexts.required),
				otherwise: Yup.string().notRequired(),
			}),
			imageCustomTypeValue: Yup.string().notRequired(),

			customSizeWidth: Yup.number()
				.typeError('Must Have a Number.')
				.min(100, 'Minimum is 100')
				.max(500, 'Maximum is 100')
				.required(errorTexts.required),
			customSizeHeight: Yup.number()
				.typeError('Must Have a Number.')
				.min(100, 'Minimum is 100')
				.max(500, 'Maximum is 100')
				.required(errorTexts.required),
			customSizeUnit: Yup.string().notRequired(),
			imageQuality: Yup.number()
				.typeError('Must Have a Number.')
				.min(1, 'Minimum is 1')
				.max(100, 'Maximum is 100')
				.required(errorTexts.required),
			alphaValue: Yup.number()
				.typeError('Must Have a Number.')
				.min(1, 'Minimum is 1')
				.max(100, 'Maximum is 100')
				.required(errorTexts.required),
			waterMark: Yup.string().notRequired(),
			fontSize: Yup.number()
				.typeError('Must Have a Number.')
				.min(8, 'Minimum is 8')
				.max(72, 'Maximum is 72')
				.required(errorTexts.required),
		}),
	}),
});

// Map "Raw API Product Data" into "Formik Initial State" Structure. (Especially used in "EDIT" mode)
export function mapApiProductDataToFormikFormStructure(data = {}) {
	const {
		productData = {},

		unitTypeList = [],
		categoryTypeList = [],
		brandTypeList = [],
		currentlySelectedLocationId = '',
	} = data;

	// ******** Below is multiple helper functions to extract necessary data which will be used in bottom of this main function.

	function getMasterTabRelatedMapperHelperFunctions() {
		// This is used to get "Array Index" of any Base Unit from "unitTypeList".
		// In simple sense this is used as fallback Unit Type.
		function getIndexIdOfABaseUnit() {
			const index = unitTypeList.findIndex((item) => {
				return item.UnitTypeName === 'EA' || item.IsBaseUnit === true;
			});

			if (index >= 0) {
				return index;
			} else {
				return '';
			}
		}

		function getProductCategoryIndex() {
			const index = categoryTypeList.findIndex((item) => {
				return item.ID === productData.CloudProductCategoryID;
			});

			if (index >= 0) {
				return index;
			} else {
				return '';
			}
		}

		function getProductSubCategoryIndex() {
			const categoryIndex = getProductCategoryIndex();

			if (Number.isInteger(categoryIndex)) {
				const subCategoryIndex = categoryTypeList[
					categoryIndex
				].ProductSubCategory.findIndex((item) => {
					return item.ID === productData.CloudProductSubCategoryID;
				});

				if (subCategoryIndex >= 0) {
					return subCategoryIndex;
				} else {
					return '';
				}
			} else {
				return '';
			}
		}

		function getProductBrandIndex() {
			const index = brandTypeList.findIndex((item) => {
				return item.ID === productData.CloudProductBrandID;
			});

			if (index >= 0) {
				return index;
			} else {
				return '';
			}
		}

		function getWeightData() {
			const fullWeightString = productData.Weight || '';
			const weightNumberPart = Number.parseFloat(fullWeightString) || '';
			const weightNumberPartLength = String(weightNumberPart).length;
			const weightStringPart =
				fullWeightString.slice(Math.max(0, weightNumberPartLength)).trim() ||
				'G';

			return {
				hasWeight: productData.HasWeight,
				weight: weightNumberPart,
				weightType: weightStringPart,
			};
		}

		function getBaseUnitType() {
			const index = unitTypeList.findIndex((item) => {
				return item.ID === productData.UnitChart.CloudBaseUnitTypeID;
			});

			if (index >= 0) {
				return index;
			} else {
				return getIndexIdOfABaseUnit();
			}
		}

		function getAdditionalUnitTypeData() {
			const index = unitTypeList.findIndex((item) => {
				return item.ID === productData.UnitChart.CloudUnitTypeID;
			});

			const quantity = productData.UnitChart.Quantity || 1;

			if (index >= 0) {
				return {
					index,
					quantity,
				};
			} else {
				return {
					index: '',
					quantity,
				};
			}
		}

		function getCloudSellingPriceData() {
			let sellingPriceObj = {};

			if (!productData.CloudSellingPricesArray) {
				// Handling Null Error Case
				sellingPriceObj = {};
			} else {
				sellingPriceObj =
					productData.CloudSellingPricesArray.find((item) => {
						return item.CloudLocationID === currentlySelectedLocationId;
					}) || {};
			}

			const unitTypeIndex = unitTypeList.findIndex((item) => {
				return item.ID === sellingPriceObj.CloudSalesUnitTypeID;
			});

			return {
				unitTypeIndex: unitTypeIndex < 0 ? '' : unitTypeIndex,
				price: sellingPriceObj.CloudSellingprice,
			};
		}

		function getCloudCostPriceData() {
			let costPriceObj = {};

			if (!productData.CloudCostPricesArray) {
				// Handling Null Error Case
				costPriceObj = {};
			} else {
				costPriceObj =
					productData.CloudCostPricesArray.find((item) => {
						return item.CloudLocationID === currentlySelectedLocationId;
					}) || {};
			}

			const unitTypeIndex = unitTypeList.findIndex((item) => {
				return item.ID === costPriceObj.CloudUnitTypeID;
			});

			return {
				unitTypeIndex: unitTypeIndex < 0 ? '' : unitTypeIndex,
				price: costPriceObj.CloudCostprice,
			};
		}

		return {
			getIndexIdOfABaseUnit,
			getProductCategoryIndex,
			getProductSubCategoryIndex,
			getProductBrandIndex,
			getWeightData,
			getBaseUnitType,
			getAdditionalUnitTypeData,
			getCloudSellingPriceData,
			getCloudCostPriceData,
		};
	}

	function getCustomTabRelatedMapperHelperFunctions() {
		function getCustomArrayListValues() {
			return productData.CustomArray || {};
		}

		return { getCustomArrayListValues };
	}

	function getImagesTabRelatedMapperHelperFunctions() {
		const {
			getPrimaryImageListAsArray,
			getUnitChartImageListAsArray,
			getSpecialImageListAsArray,
		} = generateGenericRawProductImagesKeyDataParserFunctions();

		return {
			getPrimaryImageListAsArray: () =>
				getPrimaryImageListAsArray(productData.OnLineImages.PrimaryImages),
			getUnitChartImageListAsArray: () =>
				getUnitChartImageListAsArray(productData.OnLineImages.UnitChartImages),
			getSpecialImageListAsArray: () =>
				getSpecialImageListAsArray(productData.OnLineImages.SpecialImages),
		};
	}

	const mapperHelperFns = {
		masterTab: getMasterTabRelatedMapperHelperFunctions(),
		customTab: getCustomTabRelatedMapperHelperFunctions(),
		imagesTab: getImagesTabRelatedMapperHelperFunctions(),
	};

	// *** Mapping Data

	return {
		// Form in Master Tab.
		masterSection: {
			itemInformation: {
				itemType: 'PRODUCT', // Can be "PRODUCT" OR "SERVICE"
				productName: productData.Name,
				itemCode: productData.ProductRefNo,
				barcode: productData.BarCode,
				productDescription: '',
				category: mapperHelperFns.masterTab.getProductCategoryIndex(),
				subCategory: mapperHelperFns.masterTab.getProductSubCategoryIndex(),
				vat: productData.CloudProductCategory.ProductCategory.VatRate,
				brand: mapperHelperFns.masterTab.getProductBrandIndex(),
				weightable: productData.HasWeight,
				weight: mapperHelperFns.masterTab.getWeightData().weight,
				weightType: mapperHelperFns.masterTab.getWeightData().weightType,
				sellOnline: productData.IsOnline,
				active: productData.IsActive,
			},

			unitConfiguration: {
				baseUnitType: mapperHelperFns.masterTab.getBaseUnitType(),
				additionalUnitType:
					mapperHelperFns.masterTab.getAdditionalUnitTypeData().index,
				additionalUnitTypeQuantity:
					mapperHelperFns.masterTab.getAdditionalUnitTypeData().quantity,
			},

			sellingInformation: {
				sellingPrice:
					mapperHelperFns.masterTab.getCloudSellingPriceData().price,
				unitType:
					mapperHelperFns.masterTab.getCloudSellingPriceData().unitTypeIndex,

				// Below are Auto Calculated based on Other Inputs.
				unitPrice: '',
				margin: '',

				// Below are Not Available yet.
				salesAccountCode: '',
			},

			purchaseInformation: {
				purchaseCost: mapperHelperFns.masterTab.getCloudCostPriceData().price,
				unitType:
					mapperHelperFns.masterTab.getCloudCostPriceData().unitTypeIndex,

				// Below are Auto Calculated based on Other Inputs.
				unitCost: '',

				// Below are Not Available yet.
				preferredSupplier: '',
				purchaseAccountCode: '',
			},

			stockInformation: {
				initialStock: productData.OpeningStock,
				initialStockUnitType: mapperHelperFns.masterTab.getIndexIdOfABaseUnit(),
				initialStockDate: productData.CreatedDate,

				// Below are Auto Calculated based on Other Inputs.
				initialStockValue: '',

				minOrderLevel: productData.ReorderLevel,
				minOrderLevelUnitType:
					mapperHelperFns.masterTab.getIndexIdOfABaseUnit(),
				maxOrderLevel: productData.MaxOnlineOrderQty,
				maxOrderLevelUnitType:
					mapperHelperFns.masterTab.getIndexIdOfABaseUnit(),
			},
		},

		customSection: {
			customInformation: {
				...mapperHelperFns.customTab.getCustomArrayListValues(),
			},
		},

		imageSection: {
			currentImageInformation: {
				primaryImageList:
					mapperHelperFns.imagesTab.getPrimaryImageListAsArray(),
				unitChartImageList:
					mapperHelperFns.imagesTab.getUnitChartImageListAsArray(),
				specialImageList:
					mapperHelperFns.imagesTab.getSpecialImageListAsArray(),
			},

			// Values from below sections are NOT received from Backend. So not relevant here.
			// 		imageUploaderInformation: {
			//			...
			// 		},
		},
	};
}
