import React, {useState} from 'react'
import { Input, Button, Select, DatePicker } from 'antd';
import moment from 'moment';

const PromotionSearchByBarCode = (props) => {
    const [searchText, setSearchText] = useState('');

    const handleChangeSearchKey = (e) => {
        console.log("handleChangeSearchKey EVENT===>> ", e)
        setSearchText(e.target.value)

        if(e.key && e.key==="Enter") {
            props.handleSearchByKey('barcode', e.target.value)
            setSearchText('')
        }
    }

    const handleSearch = () => {

			props.handleSearchByKey('barcode', searchText)
		

	}
    return (
        <div>
            <Input.Group compact>

                <Input style={{ width: '80%' }} onChange={handleChangeSearchKey} onKeyDown={handleChangeSearchKey} value={searchText} placeholder='Scan barcode or type here' />

                <Button style={{ width: '20%' }} onClick={handleSearch}>
                    Search
                </Button>
            </Input.Group>
        </div>
    )
}

export default PromotionSearchByBarCode
