import { reqStatusTypes } from '../../helpers/constants';

const sliceConstants = {
	name: 'reports',
	initialState: {
		// ******************************* Report State *******************************
		reportsStatus: reqStatusTypes.idle,
		reportsError: {},
		reports: null,
	},
};

export default sliceConstants;
