import { SearchOutlined as SearchOutlinedIcon } from '@ant-design/icons';
import { Popover, Input } from 'antd';
import { LoaderView } from 'ecologital-ui-library';
import lodash from 'lodash';
import React, { Component } from 'react';

import './OmniBar.css';

/**
 * NOTE : In simpler sense, This component provide a way to do some searching when clicked on input and show search result in the immediate popup. (Kind of Google Search Box)
 *
 * 	- In our component there is three main parts.
 * 			- Initial Input Component - This is what user see in idle state. In most of the time this will be a normal <Input/> showing selected value. Developer can pass any component for this. ("displayComponent" prop)
 * 			-	Internal Input Component - As soon as user clicked on "Initial Input Component" we hide it and show this. So for searching user will be typing here. Developer should pass how searching should happen on this onChange. ("onSearchInputChange()" prop - It will receive searchTerm)
 * 			- Internal Popup Component - As soon as user clicked on "Initial Input Component", this will be also shown. Developer can pass how to show received search result from searching. Also loading, error, etc.. state will be shown accordingly. ("popupContentGenerator()" prop - It will receive search result from above)
 */

const initialState = {
	searchTerm: '',
	isPopupOpen: false,

	isLoading: false,
	isError: '',
	rawSearchResult: {}, // Track the result of "onSearchInputChange()" prop.
};

class OmniBar extends Component {
	constructor(props) {
		super(props);

		this.state = { ...initialState };

		// Function Bindings.
		this.handleInputChange = lodash.debounce(this.handleInputChange, 300);
		this.onClickEventListenerHandler =
			this.onClickEventListenerHandler.bind(this);

		// Generating "Unique CSS ID" for specific parts, which we will be used to directly manipulate through DOM Findings. (Ex. document.querySelector())
		const uniqueKey = Number.parseInt(Math.random() * 10000, 10); // Using unique key because, there will be multiple instance usage of this component. So we need to have unique id for to use DOM Manipulation functions.
		const baseId = `OmniBar__${uniqueKey}`;
		this.mainComponentWrapperId = baseId; // Main Div that contain all parts.
		this.internalSearchInputId = `${baseId}__input`; // This is where user will type and searching will invoked.
		this.internalPopupRenderingTargetId = `${baseId}__popupTarget`; // This is where popup content will be rendered.
	}

	async handleInputChange(searchTerm) {
		// NOTE : This function is made "debounced" on constructor().
		// 				This will be fired when user start typing.

		const {
			onSearchInputChange = async () => { }, // This function should some work and return search result that will be expecting in "popupContentGenerator()" function. This function will get passed "searchTerm"
		} = this.props;

		try {
			const rawSearchResult = await onSearchInputChange({
				searchTerm,
			});

			this.setState({
				isLoading: false,
				isError: '',
				rawSearchResult,
			});
		} catch (error) {
			this.setState({
				isLoading: false,
				isError: error,
				rawSearchResult: '',
			});
		}
	}

	// This will determine what will be shown in Popup Area.
	getPopupContent() {
		const {
			// Developer should pass this prop which should return content that should shown in Popup Area when search is success. This function will get passed "searchResult" received from "onSearchInputChange()" and more.
			popupContentGenerator = () => {
				return null;
			},
			isEmptyResultChecker, // Optional prop. If passed this will used to determine whether searchResult is empty. And if that is the case we will show Empty Items Notice.
		} = this.props;

		const { isLoading, isError, searchTerm, rawSearchResult } = this.state;

		// Determine whether searchResult is empty or not.
		let isSearchResultEmpty = false;
		if (searchTerm && lodash.isFunction(isEmptyResultChecker)) {
			// When "isEmptyResultChecker()" passed.
			isSearchResultEmpty = isEmptyResultChecker({ rawSearchResult });
		} else if (
			// When "isEmptyResultChecker()" is NOT passed.
			searchTerm &&
			(!rawSearchResult || lodash.isEmpty(rawSearchResult))
		) {
			isSearchResultEmpty = true;
		}

		if (!searchTerm || isLoading || isError || isSearchResultEmpty) {
			return (
				<LoaderView
					className='OmniBar__Loader'
					isInfo={!searchTerm}
					isLoading={isLoading}
					isError={isError}
					isEmpty={isSearchResultEmpty}
					typeConfigs={{
						info: {
							subTitle: 'Start Typing To Search.',
							icon: <SearchOutlinedIcon />,
						},

						error: {
							subTitle: 'Sorry. Error Occurred.',
						},

						empty: {
							subTitle: 'No Items Found.',
						},
					}}
				/>
			);
		}

		return popupContentGenerator({
			popupContentRenderTargetId: this.internalPopupRenderingTargetId,

			rawSearchResult, // Caller can use this to determine what to display.

			// Caller can use this close Popup on their end once something selected.
			closePopup: () => {
				this.setState({
					isPopupOpen: false,
				});

				setTimeout(() => {
					// NOTE : This is fired inside setTimeOut, To override "focus()" fired on "<Popover content='XXX'/>"'s outer onClick handler.
					document.querySelector(`#${this.internalSearchInputId}`).blur();
				}, 1);
			},
		});
	}

	// NOTE : Normally, This is added as event listener when Popover is opened and then removed when Popover is Close.
	// 				Purpose of this is to catch clicks occur outside of Popover area and Close the popup if it is the case.
	onClickEventListenerHandler(e) {
		// Where OminiBar comp. related items are rendered. (Ex. Popup Trigger Input, Search Input)
		// Its important to note that currently "Popup Container" itself is not rendered inside this.
		const currentComponentElement = document.querySelector(
			`#${this.mainComponentWrapperId}`,
		);

		// Where "Popup Container" is rendered. (Currently its rendered in outside of this component's div to resolve overflow hidden issue.)
		const internalPopupRenderingTargetElem = document.querySelector(
			`#${this.internalPopupRenderingTargetId}`,
		);

		// Ignoring clicks on OminiBar related elements.
		// if (currentComponentElement.contains(e.target)) {
		// 	return;
		// }

		// // Checking "clicking" happened on inside the Popover area or Not.
		// if (!internalPopupRenderingTargetElem.contains(e.target)) {
		// 	this.setState({ ...initialState }); // Closing PopUp and Resetting other data along with it.
		// 	window.removeEventListener('click', this.onClickEventListenerHandler);
		// }
	}

	// NOTE : Calculate how much width "Popup Container" should be.
	// 				Currently we make sure "Popup Container" width is same as "Search Input Box"
	getWidthForPopupContainer() {
		const mainComponentWrapperElem = document.querySelector(
			`#${this.mainComponentWrapperId}`,
		);

		return mainComponentWrapperElem
			? `${mainComponentWrapperElem.getBoundingClientRect().width}px`
			: '100%';
	}

	componentDidMount() {
		// NOTE : This is where <Popover/> content will be rendered later.
		// 				We are appending it to "body" to resolve overflow hidden issues.
		const popupRenderingTargetNode = document.createElement('div');
		popupRenderingTargetNode.id = this.internalPopupRenderingTargetId;
		document.querySelector('body').append(popupRenderingTargetNode);
	}

	componentWillUnmount() {
		const popupRenderingTargetNode = document.querySelector(
			`#${this.internalPopupRenderingTargetId}`,
		);

		popupRenderingTargetNode.remove();
	}

	render() {
		const {
			displayComponent = <Input />, // Initial Component that will displayed to user in Idle State.
		} = this.props;
		const { isPopupOpen, searchTerm } = this.state;

		return (
			<div className='OmniBar' id={this.mainComponentWrapperId}>
				{/* Idle State (When Not Searching) */}
				{/* 		- When clicked on here this will be hidden. Then we invoke things to show Popover and start searching.  */}
				<div
					style={{
						/* stylelint-disable-line */
						display: isPopupOpen ? 'none' : 'initial',
					}}
					onClick={() => {
						window.addEventListener('click', this.onClickEventListenerHandler); // To track clicks outside of Popover.

						// Focussing to internal Input.
						// NOTE : This is fired inside setTimeOut, To override "focus()" fired on "<Popover content='XXX'/>"'s outer onClick handler.
						setTimeout(() => {
							document.querySelector(`#${this.internalSearchInputId}`).focus();
						}, 1);

						// Showing Popup & Initiate searching phase.
						this.setState({
							isPopupOpen: true,
						});
					}}>
					{displayComponent}
				</div>

				{/* ****** Searching State (When user clicked on Idle Input) ******* */}
				{/* Searching State - Input */}
				<div
					style={{
						/* stylelint-disable-line */
						display: isPopupOpen ? 'initial' : 'none',
					}}>
					<Input
						id={this.internalSearchInputId}
						name={this.internalSearchInputId}
						value={searchTerm}
						onChange={async (e) => {
							// Invoking Searching & Showing updated Popup Content for Each Change.

							const newSearchTerm = e.target.value;

							this.setState(
								{ isLoading: true, searchTerm: newSearchTerm },
								async () => {
									await this.handleInputChange(newSearchTerm);
								},
							);
						}}
					/>
				</div>

				{/* Searching State - Popover */}
				<Popover
					getPopupContainer={() => {
						// NOTE : In here we set where popup should be rendered. (Currently, We are rendering on a specific div in "body" to resolve overflow hidden issues.)

						return document.querySelector(
							`#${this.internalPopupRenderingTargetId}`,
						);
					}}
					overlayStyle={{
						// These styles will be applied to Popup's wrapper nested child div, Which is rendered inside of the element that we return from "getPopupContainer()".

						width: this.getWidthForPopupContainer(),
						maxHeight: '250px',
						overflow: 'auto',
						border: '1px solid #00000033',
						paddingTop: '0px', // To hide Arrow.
					}}
					content={() => {
						return (
							<div
							// NOTE : Temporally Disabled This feature. Because if this is enabled there isn't way to have any inputs inside popup itself, As whenever clicked it will refocus.
							// Refocusing on Internal <Input> when clicked anywhere on Popup Section. This is to avoid user searching cumbersome disability due to unfocussed when moved/clicked on PopupArea.
							// onClick={() => {
							// 	document
							// 		.querySelector(`#${this.internalSearchInputId}`)
							// 		.focus();
							// }}
							>
								{this.getPopupContent()}
							</div>
						);
					}}
					visible={isPopupOpen}
					autoAdjustOverflow={false} // This is used to avoid uncertain/random "placement" changes due to, suitable height being not available.
					placement='bottomLeft'
					destroyTooltipOnHide={{ keepParent: false }}>
					{/* This is just placeholder. Actual trigger is "displayComponent" on the Top, and trigerness is handled manually by us using our internal state. */}
					<div />
				</Popover>
			</div>
		);
	}
}

export default OmniBar;
