import { EyeOutlined as EyeIcon } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { Table, withViewportData } from 'ecologital-ui-library';
import lodash from 'lodash';
import React, { Component } from 'react';

import CustomModal from '../../widgets/CustomModal/CustomModal';
import { rawColumnListParser } from '../helpers/parsers/filterDataParsers';
import ProductOverview from '../ProductOverview/ProductOverview';

import './ProductList.css';

// SIDE NOTE : This component originally created to show products list in only Products Page.
// 							But later there was other places (Ex. On Add Proforma Page) we need to show product list with some modifications.
// 							So we have done some changes, given ways to achieve that. So while exploring this code below, keep in mind that.

/**
 * ************************* SOME PROP EXPLANATIONS *************************
 *
 * 1. customizationProps - {}
 * 		- NOTE : This can have various below optional props to customize Customer List if necessary.
 *
 * 		- customRawColumnListGenerator
 *				- Should be a function. It will be passed existing column list, etc...
 *				 - This can be used to pass custom Column List Config to be shown.
 *
 * 		- customSpecialRootKeyListGenerator
 * 				- Should be a function. It will be passed parsed column list, etc...
 * 				- Using these we can further customize what columns will be shown, what data will be rendered, etc...
 * 				- This will specially useful when backend received Filter data is not available or we need to further customize it.
 *
 * 		- customActionColumnButtonGenerator
 * 				- Should be a function. It will be passed each rows data, etc...
 * 				-	 Using this we can customize what to shown in each row's Action Column, and what to do with each button.
 *
 * 		- customColumnSortOrderKeysGenerator
 * 				- Should be a function which return array of "Column Keys". It will be passed parsed default SortList, etc...
 * 				- If given these will take priority in column order.
 *
 * 		- hideColumnHeaderWhenZeroItem
 * 				- Should be a boolean.
 * 				- This can be used to hide column headers, when there's no items in the table.
 */

class ProductList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isOverviewModalVisible: false,
			selectedRowData: {}, // When clicked on specific raw action (Ex. Overview Modal Eye Icon) this will filled with that specific row data.
		};
	}

	toggleOverviewModal(options = {}) {
		const { isOverviewModalVisible } = this.state;
		const { showModal = !isOverviewModalVisible, selectedRowData = {} } =
			options;

		this.setState({
			isOverviewModalVisible: showModal,
			selectedRowData,
		});
	}

	parseRawColumnList(rawColumnList = {}, otherData = {}) {
		const { customizationProps = {} } = otherData;

		const { selectedOrgId = '', selectedLocationId = '' } = otherData;

		const defaultSpecialColumnHandlers = [
			{
				key: 'CustomArray',
				customConfigGenerator: (options = {}) => {
					const { rootValue } = options;

					return Object.entries(rootValue || {}).map((entry2) => {
						// eslint-disable-next-line no-shadow
						const [rootKey, rootValue] = entry2;

						return {
							customData: {
								columnRootKey: rootKey, // To avoid rootKey being 'CustomArray' on these nested columns.
							},
							...rootValue,
						};
					});
				},
			},
			{
				key: 'Inventories',
				customRenderValueGetter: (valueOfDataIndex, fullRowValues) => {
					const InventoriesArray = fullRowValues.Inventories || [];

					const relevantOrgEntry = InventoriesArray.find((item) => {
						return item.OrgID === selectedOrgId;
					});

					// Check entry for currentlySelectedOrg is available if that the case dig more to get nested value.
					if (relevantOrgEntry && relevantOrgEntry.QuantityList) {
						// Some API EndPoint seems to send "QuantityList" not as an list of array.
						// 		- So until it it resolved on backend, adding temporary checks to avoid errors.

						// When received as Array
						if (Array.isArray(relevantOrgEntry.QuantityList)) {
							const relevantLocationEntry =
								relevantOrgEntry.QuantityList.find((item) => {
									return item.CloudLocationID === selectedLocationId;
								}) || {};

							return relevantLocationEntry.Stock;
						}

						// When received as Object
						if (
							lodash.isObject(relevantOrgEntry.QuantityList) &&
							relevantOrgEntry.QuantityList.CloudLocationID ===
							selectedLocationId
						) {
							return relevantOrgEntry.QuantityList.Stock;
						}

						return '';
					}

					return '';
				},
			},
			{
				key: 'CostPrice',
				customRenderValueGetter: (valueOfDataIndex, fullRowValues) => {
					const CloudCostPricesArray = fullRowValues.CloudCostPricesArray || [];

					const relevantLocationValueSet =
						CloudCostPricesArray.find((item) => {
							return item.CloudLocationID === selectedLocationId;
						}) || {};

					return relevantLocationValueSet.CloudCostprice;
				},
			},
			{
				key: 'SellingPrice',
				customRenderValueGetter: (valueOfDataIndex, fullRowValues) => {
					const CloudSellingPricesArray =
						fullRowValues.CloudSellingPricesArray || [];
					const relevantLocationValueSet =
						CloudSellingPricesArray.find((item) => {
							return item.CloudLocationID === selectedLocationId;
						}) || {};

					return relevantLocationValueSet.CloudSellingprice;
				},
			},
		];

		const defaultColumnSortOrderKeys = [
			'Name',
			'Weight',
			'Inventories',
			'SellingPrice',
		];

		// These will be update depending on passed props & some other conditions.
		let allRawColumnList = {};
		let allSpecialColumnHandlersList = [];
		let allColumnSortOrderKeys = [];

		// Getting Raw Column List to Process.
		if (lodash.isFunction(customizationProps.customRawColumnListGenerator)) {
			// When Custom RawColumnList Generator is passed.
			allRawColumnList = customizationProps.customRawColumnListGenerator({
				existingRawColumnList: rawColumnList,
			});
		} else {
			allRawColumnList = rawColumnList;
		}

		// Getting special handlers to handle special Columns.
		if (
			lodash.isFunction(customizationProps.customSpecialRootKeyListGenerator)
		) {
			// When customSpecialRootKeyHandler is Passed.
			allSpecialColumnHandlersList =
				customizationProps.customSpecialRootKeyListGenerator({
					existingRawColumnList: defaultSpecialColumnHandlers,
					selectedOrgId,
					selectedLocationId,
				});
		} else {
			allSpecialColumnHandlersList = defaultSpecialColumnHandlers;
		}

		// Getting Sort Column Key List.
		if (
			lodash.isFunction(customizationProps.customColumnSortOrderKeysGenerator)
		) {
			// When Custom columnSortOrderKeyList Generator is passed.
			allColumnSortOrderKeys =
				customizationProps.customColumnSortOrderKeysGenerator({
					existingColumnSortOrderKeys: defaultColumnSortOrderKeys,
				});
		} else {
			allColumnSortOrderKeys = defaultColumnSortOrderKeys;
		}

		return rawColumnListParser(allRawColumnList, {
			columnSortOrderKeys: allColumnSortOrderKeys,
			specialRootKeyList: allSpecialColumnHandlersList,
		});
	}

	generateTableColumnListConfig(parsedColumnList = [], otherData = {}) {
		const {
			rawProductList = [],
			currentViewportData = {},

			customizationProps = {
				/**
				 * NOTE : This can have various optional props to customize Customer List if necessary.
				 *
				 * 	- customActionColumnButtonGenerator
				 * 			- Should be a function. It will be passed each rows data, etc...
				 * 			- Using this we can customize what to shown in each row's Action Column, and what to do with each button.
				 */
			},
		} = otherData;

		const { hideColumnHeaderWhenZeroItem } = customizationProps;

		const { isMobileViewport } = currentViewportData;

		// If there's no items in the table we just return empty row config. (When "hideColumnHeaderWhenZeroItem" prop provided.)
		if (hideColumnHeaderWhenZeroItem === true) {
			if (rawProductList.length === 0) {
				return [];
			}
		}

		// Any common prop that applicable to all columns goes here.
		const defaultColumnProps = {};

		// Generating Column Config for ParsedColumnList.
		const tableColumnListConfig = parsedColumnList.map((column) => {
			const { customData = {} } = column;
			const { columnRootKey } = customData;

			// Some props will be added here when some logics are fulfilled below. Anyway this props will override all other column props as this is defined last in each columns props list.
			let dynamicColumnProps = {};

			// Making some dynamic props decisions based on API returned column data's object Root Key values.
			switch (columnRootKey) {
				case 'Name': {
					if (isMobileViewport) {
						// Handle 'Name' column in mobile resolutions to provide better UI/UX. (Ex. Fixed Width, Values Shrunken, Etc...)
						dynamicColumnProps = {
							...dynamicColumnProps,
							fixed: false,
							width: '160px',
							ellipsis: false,
							render: (valueOfDataIndex, fullRowValues, index) => {
								const name = valueOfDataIndex || ''; // Value getting for 'Name' column.
								const maxNameLength = 22;
								const currentNameLength = name.length;
								const shouldShrink = currentNameLength > maxNameLength;

								if (shouldShrink) {
									const shrunkenName = `${name.slice(0, maxNameLength)}*`;

									return (
										<div>
											<Tooltip placement='topLeft' title={name}>
												{shrunkenName}
											</Tooltip>
										</div>
									);
								} else {
									return name;
								}
							},
						};
					} else {
						// Making Name Column fixed on larger resolutions while horizontal scrolling.
						// 		SIDE NOTE : But on mobile resolutions (<768px), Fixed "Name" column may would just hide all other columns as it take much width. So in mobiles res. we just disable column being fixed and do more improvements for mobile UI/UX.
						dynamicColumnProps.fixed = true;
					}

					break;
				}

				default: {
					break;
				}
			}

			return {
				...defaultColumnProps,
				title: () => (
					<div
						style={
							{
								// width: 'min-content', // This "min-content" makes each header text break on space and not expand column just for header name sake. (Useful when column name long but its content is very small.)
							}
						}>
						{column.ColumnName}
					</div>
				),
				onHeaderCell: () => {
					// This can used to add html props to each <th> element.
					return {
						style: { verticalAlign: 'bottom' },
					};
				},
				dataIndex: column.ValuePath.split('.'),
				onCell: () => { },
				render: (valueOfDataIndex, fullRowValues, index) => {
					// Custom-Default Cell Rendered Function.

					const displayValue = customData.customRenderValueGetter
						? customData.customRenderValueGetter(
							valueOfDataIndex,
							fullRowValues,
						)
						: valueOfDataIndex;

					// Below is needed because, original render function don't render boolean values. So in here we show custom string for show them.
					const isBooleanValue = typeof displayValue === 'boolean';
					if (isBooleanValue) {
						return displayValue ? 'True' : 'False';
					}

					return displayValue;
				},

				...column.customColumnProps, // props mentioned in "parseRawColumnList()" function.
				...dynamicColumnProps,
			};
		});

		// ******* Handling Actions Column *******

		// Normally these will be shown in Action Column, If not customized.
		const getDefaultActionColumnButtons = (valueOfDataIndex, fullRowValues) => [
			<EyeIcon
				title='Full Product Overview'
				onClick={() => {
					this.toggleOverviewModal({
						selectedRowData: fullRowValues,
					});
				}}
			/>,
		];

		// Helper function to Handle customization if "customActionColumnButtonGenerator" prop passed OR Not.
		const getActionColumnButtons = (valueOfDataIndex, fullRowValues, index) => {
			const isCustomActionColumnButtonGeneratorAvailable = lodash.isFunction(
				customizationProps.customActionColumnButtonGenerator,
			);

			let actionColumnButtons = []; // Track what to shown in 'Action Column' Finally.

			if (isCustomActionColumnButtonGeneratorAvailable) {
				actionColumnButtons =
					customizationProps.customActionColumnButtonGenerator({
						// Passing below data to passed customization prop, so on there, they can handle what to show.
						selectedRowData: fullRowValues,
						rowIndex: index,
						getDefaultActionColumnButtons: () =>
							getDefaultActionColumnButtons(
								valueOfDataIndex,
								fullRowValues,
								index,
							),
					});
			} else {
				actionColumnButtons = getDefaultActionColumnButtons(
					valueOfDataIndex,
					fullRowValues,
					index,
				);
			}

			return actionColumnButtons;
		};

		// Adding Special Action Column
		tableColumnListConfig.push({
			// title: 'ACTIONS',
			fixed: 'right',
			align: 'center',
			render: (valueOfDataIndex, fullRowValues, index) => {
				return (
					<div style={{ display: 'flex', gap: '10px' }}>
						{getActionColumnButtons(valueOfDataIndex, fullRowValues, index)}
					</div>
				);
			},
		});

		return tableColumnListConfig;
	}

	render() {
		const { isOverviewModalVisible, selectedRowData } = this.state;
		const {
			rawProductList = [],
			rawFilterData = {},
			selectedOrgId,
			selectedLocationId,

			currentViewportData, // From 'withViewportData' HOC.

			tableCompProps = {},

			customizationProps = {},
			rowClassName,
		} = this.props;

		const rawColumnList = rawFilterData.Columns || [];

		const parsedColumnList = this.parseRawColumnList(rawColumnList, {
			rawProductList,
			selectedOrgId,
			selectedLocationId,
			customizationProps,
		});
		const tableColumnsConfig = this.generateTableColumnListConfig(
			parsedColumnList,
			{
				rawProductList,
				selectedOrgId,
				selectedLocationId,

				currentViewportData,

				customizationProps,
			},
		);

		const tableData = rawProductList;

		return (
			<>
				<CustomModal
					modalProps={{
						title: 'PRODUCT OVERVIEW',
						visible: isOverviewModalVisible,
						onCancel: () => {
							this.toggleOverviewModal();
						},
					}}>
					<ProductOverview
						productData={selectedRowData}
						selectedOrgId={selectedOrgId}
						selectedLocationId={selectedLocationId}
						toggleOverviewModal={() => {
							this.toggleOverviewModal();
						}}
					/>
				</CustomModal>

				<div className='ProductList'>
					<Table
						columns={tableColumnsConfig}
						// NOTE :  This 'rowKey' prop is the key need as per React "Each child in a list should have a unique key" prop' warning".
						// 				"ID" value we used in here is unique value per row, Which available in dataSet we are passing in "dataSource" prop.
						rowKey='ID'
						dataSource={tableData}
						pagination={{
							pageSize: 10,
						}}
						customProps={{
							showCardViewInMobileRes: false,
						}}
						rowClassName={rowClassName}
						{...tableCompProps}
					/>
				</div>
			</>
		);
	}
}

export default withViewportData(ProductList);
