/* eslint-disable no-param-reassign */
// import { current as getStateAsPlainObj } from '@reduxjs/toolkit';

import sliceConstants from './purchaseOrderPageSliceConstants';

const standardReducers = {
    // NOTE : This function is used as Temporary way to reset "purchaseOrder Page" Internal React State.
    //        Depending on this actions redux value, that component itself handle resetting.
    invokepurchaseOrderPageResetter: (state, action) => {
        state.shouldInvokepurchaseOrderPageResetter = action.payload;
    },

    setPurchaseOrderCrudRelatedData: (state, action) => {
        // This is used to define what kind of operations will be doing for specific PurchaseOrder and relevant data needed for it.
        // Example Scenario - On one page user click on 'Edit' Button and then we update relevant detail from there and redirect to specific Edit page. On there we can get this updated details and do appropriate things.

        const payload = action.payload || {};
        const {
            currentCrudType = 'ADD',
            selectedItemData = {},
            additionalInfo = {},
        } = payload;

        state.purchaseOrderCrudRelatedData = {
            ...sliceConstants.initialState.purchaseOrderCrudRelatedData,
            ...payload,
            currentCrudType,
            selectedItemData,
            additionalInfo,
        };
    },

    // Resetter function for "setPurchaseOrderCrudRelatedData()"
    resetPurchaseOrderCrudRelatedData: (state) => {
        state.customerCrudRelatedData = {
            ...sliceConstants.initialState.purchaseOrderCrudRelatedData,
        };
    },
};

export default standardReducers;
