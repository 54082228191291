/* eslint-disable no-param-reassign */

import { reqStatusTypes } from '../../helpers/constants';

import {
	dummyFn,
	getCurrencySettingsByOrgIdAndCloudLocationID,
} from './settingsSliceExtraActions';

const extraReducers = {
	// ******************************* dummyFn() *******************************
	[dummyFn.pending]: (state) => {
		state.dummyFnReqStatus = reqStatusTypes.pending;
		state.dummyFnReqError = '';
	},

	[dummyFn.fulfilled]: (state, action) => {
		state.dummyFnReqStatus = reqStatusTypes.succeeded;
		state.dummyValue = action.payload;
	},

	[dummyFn.rejected]: (state, action) => {
		state.dummyFnReqStatus = reqStatusTypes.failed;
		state.dummyFnReqError = action.error.message;
		state.dummyValue = '';
	},

	[getCurrencySettingsByOrgIdAndCloudLocationID.pending]: (state) => {
		state.currencySettingsStatus = reqStatusTypes.pending;
		state.currencySettingsError = {};
	},

	[getCurrencySettingsByOrgIdAndCloudLocationID.fulfilled]: (state, action) => {
		state.currencySettingsStatus = reqStatusTypes.succeeded;
		state.currencySettings = action.payload;
		state.currencySettingsError = {};
	},

	[getCurrencySettingsByOrgIdAndCloudLocationID.rejected]: (state, action) => {
		state.currencySettingsStatus = reqStatusTypes.failed;
		state.currencySettingsError = action.payload;
		state.currencySettings = null;
	},
};

export default extraReducers;
