import {
	MinusCircleOutlined as MinusCircleOutlinedIcon,
	PlusCircleOutlined as PlusCircleOutlinedIcon,
} from '@ant-design/icons';
import { Button } from 'antd';
import { HeaderBar } from 'ecologital-ui-library';
import { FieldArray } from 'formik';
import { Checkbox, Form, Input, InputNumber, Select } from 'formik-antd';
import React, { Component } from 'react';

import { getFormSectionsPartialObjPaths } from '../../helpers/parserHelpers';
import {
	customerTypesList,
	customerStatusTypesList,
	addressTypesList,
	countryTypesList,
	paymentMethodTypesList,
	tierTypesList,
} from '../../helpers/placeholderData';

import './CmvMasterTab.css';

// Default Form.Items Label Size.
const defaultLabelColProps = {
	span: 4,
	style: {
		whiteSpace: 'pre-wrap',
	},
};

// Form.Items Label Size used when Form is divided to two sections.
const dividedSectionLabelColProps = {
	...defaultLabelColProps,
	span: 6,
};

const { baseInfoObjPath, settingsInfoObjPath } =
	getFormSectionsPartialObjPaths().masterTab;

class CmvMasterTab extends Component {
	render() {
		const { salesRepsList, parserFunctions } = this.props;
		const { getFormValueByPath } = parserFunctions;

		return (
			<div className='CmvMasterTab'>
				<Form
					labelCol={{ ...defaultLabelColProps }}
					colon={false}
					labelAlign='left'>
					<div className='CmvMasterTab__baseInfoSection'>
						<HeaderBar subTitle={<b>BASE INFORMATION</b>} />

						<Form.Item
							name={`${baseInfoObjPath}customerType`}
							label='Customer Type'>
							<Select
								name={`${baseInfoObjPath}customerType`}
								options={customerTypesList.map((item) => {
									return {
										label: item.label,
										value: item.customerTypeID,
									};
								})}
							/>
						</Form.Item>

						<Form.Item
							name={`${baseInfoObjPath}companyName`}
							label='Company Name'>
							<Input name={`${baseInfoObjPath}companyName`} />
						</Form.Item>

						<Form.Item
							name={`${baseInfoObjPath}firstName`}
							label='First Name'
							labelCol={{ ...defaultLabelColProps }}>
							<Input name={`${baseInfoObjPath}firstName`} />
						</Form.Item>

						<Form.Item name={`${baseInfoObjPath}lastName`} label='Last Name'>
							<Input name={`${baseInfoObjPath}lastName`} />
						</Form.Item>

						<Form.Item
							name={`${baseInfoObjPath}customerStatus`}
							label='Customer Status'>
							<Select
								name={`${baseInfoObjPath}customerStatus`}
								options={customerStatusTypesList.map((item) => {
									return {
										label: item.label,
										value: item.value,
									};
								})}
							/>
						</Form.Item>
					</div>

					<div className='CmvMasterTab__addressInfoSection'>
						<HeaderBar subTitle={<b>ADDRESS INFORMATION</b>} />

						<FieldArray name='masterSection.addressInformation'>
							{(fieldArrayProps) => {
								const addressList =
									getFormValueByPath('masterSection.addressInformation') || [];

								return (
									<div>
										<div>
											{addressList.map((addressSet, index) => {
												const addressArrayPath = `masterSection.addressInformation[${index}].`;

												return (
													<div key={index}>
														<div>
															{index !== 0 && (
																<div className='CmvMasterTab__addressInfoSection____addressSetHeader'>
																	<span className='CmvMasterTab__addressInfoSection____addressSetHeader__title'>
																		Address {index + 1}
																	</span>

																	<Button
																		icon={
																			<MinusCircleOutlinedIcon
																				style={{ color: 'red' }}
																			/>
																		}
																		shape='circle'
																		title='Remove Address Set'
																		disabled={
																			// Making first address-set is non-removable.
																			addressList.length <= 1 || index === 0
																		}
																		onClick={() =>
																			fieldArrayProps.remove(index)
																		}
																	/>
																</div>
															)}
														</div>

														<div>
															<Form.Item
																name={`${addressArrayPath}addressType`}
																label='Address Type'>
																<Select
																	name={`${addressArrayPath}addressType`}
																	options={addressTypesList.map((item) => {
																		return {
																			label: item.label,
																			value: item.value,
																		};
																	})}
																/>
															</Form.Item>

															<div className='CustomerMasterView-UTL--flexContainer'>
																<div>
																	<Form.Item
																		name={`${addressArrayPath}addressLine1`}
																		label='Address Line&nbsp;1'
																		labelCol={{
																			...dividedSectionLabelColProps,
																		}}>
																		<Input
																			name={`${addressArrayPath}addressLine1`}
																		/>
																	</Form.Item>

																	<Form.Item
																		name={`${addressArrayPath}addressLine2`}
																		label='Address Line&nbsp;2'
																		labelCol={{
																			...dividedSectionLabelColProps,
																		}}>
																		<Input
																			name={`${addressArrayPath}addressLine2`}
																		/>
																	</Form.Item>

																	<Form.Item
																		name={`${addressArrayPath}city`}
																		label='City'
																		labelCol={{
																			...dividedSectionLabelColProps,
																		}}>
																		<Input name={`${addressArrayPath}city`} />
																	</Form.Item>

																	<Form.Item
																		name={`${addressArrayPath}stateProvinceRegion`}
																		label='State/Region'
																		labelCol={{
																			...dividedSectionLabelColProps,
																		}}>
																		<Input
																			name={`${addressArrayPath}stateProvinceRegion`}
																		/>
																	</Form.Item>

																	<Form.Item
																		name={`${addressArrayPath}postCode`}
																		label='PostCode'
																		labelCol={{
																			...dividedSectionLabelColProps,
																		}}>
																		<Input
																			name={`${addressArrayPath}postCode`}
																		/>
																	</Form.Item>

																	<Form.Item
																		name={`${addressArrayPath}country`}
																		label='Country'
																		labelCol={{
																			...dividedSectionLabelColProps,
																		}}>
																		<Select
																			name={`${addressArrayPath}country`}
																			options={countryTypesList.map((item) => {
																				return {
																					label: item.label,
																					value: item.value,
																				};
																			})}
																		/>
																	</Form.Item>
																</div>

																<div>
																	<Form.Item
																		name={`${addressArrayPath}contactNumber`}
																		label='Contact Number'
																		labelCol={{
																			...dividedSectionLabelColProps,
																		}}>
																		<Input
																			name={`${addressArrayPath}contactNumber`}
																		/>
																	</Form.Item>

																	<Form.Item
																		name={`${addressArrayPath}faxNumber`}
																		label='Fax Number'
																		labelCol={{
																			...dividedSectionLabelColProps,
																		}}>
																		<Input
																			name={`${addressArrayPath}faxNumber`}
																		/>
																	</Form.Item>

																	<Form.Item
																		name={`${addressArrayPath}email`}
																		label='Email'
																		labelCol={{
																			...dividedSectionLabelColProps,
																		}}>
																		<Input name={`${addressArrayPath}email`} />
																	</Form.Item>
																</div>
															</div>
														</div>
													</div>
												);
											})}
										</div>

										<div className='CmvMasterTab__addressInfoSection____addressAdder'>
											<Button
												icon={<PlusCircleOutlinedIcon />}
												onClick={() =>
													fieldArrayProps.push({
														addressType: '',
														addressLine1: '',
														addressLine2: '',
														city: '',
														postCode: '',
														country: '',
														contactNumber: '',
														faxNumber: '',
														email: '',
													})
												}>
												Add More Address
											</Button>
										</div>
									</div>
								);
							}}
						</FieldArray>
					</div>

					<div className='CmvMasterTab__settingsInfoSection'>
						<HeaderBar subTitle={<b>SETTINGS</b>} />

						<div className='CustomerMasterView-UTL--flexContainer'>
							<div>
								<Form.Item
									name={`${settingsInfoObjPath}creditLimit`}
									label='Credit Limit'
									labelCol={{
										...dividedSectionLabelColProps,
									}}>
									<InputNumber
										name={`${settingsInfoObjPath}creditLimit`}
										min={0}
										precision={0}
										style={{ width: '100%' }}
									/>
								</Form.Item>

								<Form.Item
									name={`${settingsInfoObjPath}creditPeriodAsDays`}
									label='Credit Period (Days)'
									labelCol={{
										...dividedSectionLabelColProps,
									}}>
									<InputNumber
										name={`${settingsInfoObjPath}creditPeriodAsDays`}
										min={0}
										precision={0}
										style={{ width: '100%' }}
									/>
								</Form.Item>

								<Form.Item
									name={`${settingsInfoObjPath}memberNumber`}
									label='Member No'
									labelCol={{
										...dividedSectionLabelColProps,
									}}>
									<Input name={`${settingsInfoObjPath}memberNumber`} disabled />
								</Form.Item>

								<Form.Item
									name={`${settingsInfoObjPath}paymentMethod`}
									label='Payment Method'
									labelCol={{
										...dividedSectionLabelColProps,
									}}>
									<Select
										name={`${settingsInfoObjPath}paymentMethod`}
										options={paymentMethodTypesList.map((item) => {
											return {
												label: item.label,
												value: item.value,
											};
										})}
									/>
								</Form.Item>

								<Form.Item
									name={`${settingsInfoObjPath}customerVatNumber`}
									label='Customer VAT Number'
									labelCol={{
										...dividedSectionLabelColProps,
									}}>
									<Input name={`${settingsInfoObjPath}customerVatNumber`} />
								</Form.Item>

								<Form.Item
									name={`${settingsInfoObjPath}openingBalance`}
									label='Opening Balance'
									labelCol={{
										...dividedSectionLabelColProps,
									}}>
									<InputNumber
										name={`${settingsInfoObjPath}openingBalance`}
										min={0}
										precision={2}
										style={{ width: '100%' }}
									/>
								</Form.Item>

								<Form.Item
									name={`${settingsInfoObjPath}bulkDiscount`}
									label='Bulk Discount'
									labelCol={{
										...dividedSectionLabelColProps,
									}}>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: '10px',
										}}>
										<InputNumber
											name={`${settingsInfoObjPath}bulkDiscount`}
											min={0}
											max={100}
											precision={0}
											style={{ width: '100%' }}
										/>
										<span> %</span>
									</div>
								</Form.Item>
							</div>

							<div>
								<Form.Item
									name={`${settingsInfoObjPath}tier`}
									label='Tier'
									labelCol={{
										...dividedSectionLabelColProps,
									}}>
									<Select
										name={`${settingsInfoObjPath}tier`}
										options={tierTypesList.map((item) => {
											return {
												label: item.label,
												value: item.value,
											};
										})}
									/>
								</Form.Item>

								<Form.Item
									name={`${settingsInfoObjPath}accountNumber`}
									label='Account Number'
									labelCol={{
										...dividedSectionLabelColProps,
									}}>
									<Input name={`${settingsInfoObjPath}accountNumber`} />
								</Form.Item>

								<Form.Item
									name={`${settingsInfoObjPath}salesRep`}
									label='Sales Rep'
									labelCol={{
										...dividedSectionLabelColProps,
									}}>
									<Select
										name={`${settingsInfoObjPath}salesRep`}
										options={salesRepsList.map((item, index) => {
											return {
												label: item.StaffName,
												value: index,
												fullData: item,
											};
										})}
									/>
								</Form.Item>

								<Form.Item
									name={`${settingsInfoObjPath}isVatExemption`}
									label='VAT Exemption'
									labelCol={{
										...dividedSectionLabelColProps,
									}}>
									<Checkbox name={`${settingsInfoObjPath}isVatExemption`} />
								</Form.Item>

								<Form.Item
									name={`${settingsInfoObjPath}isEmailProforma`}
									label='Email Proforma'
									labelCol={{
										...dividedSectionLabelColProps,
									}}>
									<Checkbox name={`${settingsInfoObjPath}isEmailProforma`} />
								</Form.Item>

								<Form.Item
									name={`${settingsInfoObjPath}isEmailInvoice`}
									label='Email Invoice'
									labelCol={{
										...dividedSectionLabelColProps,
									}}>
									<Checkbox name={`${settingsInfoObjPath}isEmailInvoice`} />
								</Form.Item>
							</div>
						</div>
					</div>
				</Form>
			</div>
		);
	}
}

export default CmvMasterTab;
