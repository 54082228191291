import { PlusCircleOutlined as PlusCircleOutlinedIcon } from '@ant-design/icons';
import { Button, Popover, InputNumber } from 'antd';
import React, { Component, useRef, useState } from 'react';

import ProductSearcher from '../../../../../ProductSearcher/ProductSearcher';
import { productSearchColumnConfig } from '../../helpers/typeConfigs';

class ProductFullSearcher extends Component {
	render() {
		const { helperFns } = this.props;

		return (
			<ProductSearcher
				productSearcherType='proformaType'
				productListCustomizationProps={{
					customRawColumnListGenerator: (data = {}) => {
						// Specifying what columns to show on ProductList.
						return productSearchColumnConfig;
					},

					customSpecialRootKeyListGenerator: (data = {}) => {
						return [];
					},

					customColumnSortOrderKeysGenerator: (data = {}) => {
						return [];
					},

					customActionColumnButtonGenerator: (data = {}) => {
						// Adding custom action/button to "Actions Column" of ProductList.

						const { selectedRowData } = data;
						const {
							AvailableStock: availableStock,
							EligibleForOrder: eligibleForOrder,
						} = selectedRowData;

						const inputRef = useRef(null);
						const [popupVisible, setPopupVisible] = useState(false);

						return (
							<Popover
								content={() => {
									return (
										<div>
											<span>
												<span>QTY : </span>
												<InputNumber
													min={1}
													max={availableStock}
													ref={inputRef}
												/>
											</span>

											<Button
												onClick={() => {
													const purchaseQty = inputRef.current.value;

													if (purchaseQty && purchaseQty >= 0) {
														helperFns.onProductAdding({
															selectedRowData,
															additionalData: {
																purchaseQty,
															},
														});
													}

													setPopupVisible(false);
												}}>
												ADD
											</Button>

											<Button
												onClick={() => {
													setPopupVisible(false);
												}}>
												CANCEL
											</Button>
										</div>
									);
								}}
								title=''
								trigger='click'
								onVisibleChange={(visibleStateAccordingToTriggerProp) => {
									// NOTE : In "onVisibleChange" prop, what we receive is Popup state, that should be according "trigger" prop. (This doesn't care that we are using our own state to handle popup visibility by passing "visible" prop.)
									// 				Anyway, We are using this to our advantage by using this to close popup when clicked on outside of Popover.

									if (visibleStateAccordingToTriggerProp === false) {
										setPopupVisible(false);
									}
								}}
								visible={popupVisible}
								overlayInnerStyle={{
									border: '1px solid #0c0c0c21',
								}}
								destroyTooltipOnHide>
								<PlusCircleOutlinedIcon
									title={eligibleForOrder ? 'Add Product' : 'Not Available'}
									style={{
										/* stylelint-disable */
										color: eligibleForOrder ? '' : 'red',
									}}
									onClick={() =>
										eligibleForOrder ? setPopupVisible(true) : ''
									}
								/>
							</Popover>
						);
					},
				}}
			/>
		);
	}
}

export default ProductFullSearcher;
