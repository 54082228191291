import React, { Fragment, useEffect, useState } from "react";
import {
    InputGroup,
    StackItem,
    Stack,
    Button,
    ActionList,
    Popover,
    Icon,
    Table,
    Checkbox,
    Space,
    RightPanel,
    RightPanelHeader,
    Accordion,
    Alert,
    Message,
    ConfirmationPopup,
    SearchableMenu,
    Label,
} from "witmeg-ui-system";

import BasicInformationForm from "./Forms/BasicInformationForm";
import SupplierOverview from "./Forms/SupplierOverview";

import {
    getEposSuppliersAllByOrgId,
    addEposSupplier,
    deleteEposSupplier,
    overviweEposSupplier,
    editEposSupplier,
    searchEposSupplier,
    searchCountEposSupplier,
} from "../../../../../utilities/apiRequests/witmegWebNeurolageServerRequests";
import { useSelector, useDispatch } from "react-redux";
import { sectionName as dashboardSectionName } from "../../../../../redux/slices/dashboard/dashboardConstants";
import moment from "moment";

const SupplierPage = (props) => {
    const userState = useSelector((state) => state.user.userDetails);
    const dashboardState = useSelector(
        (state) => state[dashboardSectionName].dashboard
    );
    const settingsState = useSelector((state) => state["settings"]);

    const [selectedLeftValue, setSelectedLeftValue] = useState([]);
    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
    const leftOptions = [
        { value: "All", label: "All" },
        // { value: "supplierCode", label: "Supplier Code" },
        { value: "Name", label: "Supplier Name" },
        { value: "Email", label: "Supplier E-Mail" },
    ];
    const [showAddMenu, setShowAddMenu] = useState(false);
    const [showActionMenu, setShowActionMenu] = useState(false);
    const [addMenuPopoverTriggerEvent, setAddMenuPopoverTriggerEvent] =
        useState(null);
    const [actionMenuPopoverTriggerEvent, setActionMenuPopoverTriggerEvent] =
        useState(null);

    const [showAddSupplierPanel, setShowAddSupplierPanel] = useState(false);
    const [showSupplierOverviewPanel, setShowSupplierOverviewPanel] =
        useState(false);
    const [loadingSuppliers, setLoadingSuppliers] = useState(false);

    const [basicInformationIValues, setBasicInformationIValues] = useState({});
    const [basicInfoValidationErrors, setbasicInfoValidationErrors] = useState({
        supplierName: false,
        supplierEmail: false,
    });
    // const [enableProductEditMode, setEnableProductEditMode] = useState(false);
    const [enableSupplierEditMode, setEnableSupplierEditMode] = useState(false);
    const [missingBasicFieldNames, setMissingBasicFieldNames] = useState([]);
    // const [basicInfo, setBasicInfo] = useState(null);
    const [missingBasicFields, setMissingBasicFields] = useState([]);

    //Record that selected to perform actions
    const [selectedRecord, setSelectedRecord] = useState({});

    //Contact Person Data
    const [contactPerson, setContactPerson] = useState({});
    // const [subCategoryInformationValues, setSubCategoryInformationValues] = useState([]);
    const [contactPersonValues, setContactPersonValues] = useState([]);

    //delete confirmation popup
    const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState(false);
    const [
        showDeleteConfirmPopupForMultipleSuppliers,
        setShowDeleteConfirmPopupForMultipleSuppliers,
    ] = useState(false);

    //overview
    const [overviewOfSupplier, setOverviewOfSupplier] = useState({});

    //search value
    const [searchKey, setSearchKey] = useState("");

    const [selectedOption, setSelectedOption] = useState("");

    const [emailValidationIssue, setEmailValidationIssue] = useState(false);
    const [selectedRowKey, setSelectedRowKey] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const handleChangeBasicInfoInputs = (values, updatedField) => {
        setBasicInformationIValues(values);
        setbasicInfoValidationErrors({
            ...basicInfoValidationErrors,
            [updatedField]: values[updatedField] === "",
        });
    };

    const [columnData, setColumnData] = useState([
        {
            title: () => <div></div>,
            key: "rowSelector",
            render: (record) => (
                <Checkbox onChange={(e) => handleCheck(e, record)} />
            ),
        },
        // {
        //   title: "Supplier Code",
        //   dataIndex: "supplierCode",
        //   key: "supplierCode",
        // },
        {
            title: "Supplier Name",
            dataIndex: "supplierName",
            key: "supplierName",
        },
        {
            title: "Landline",
            dataIndex: "supplierLandline",
            key: "supplierLandline",
        },
        {
            title: "Email",
            dataIndex: "supplierEmail",
            key: "supplierEmail",
        },
        {
            title: "Balance Due",
            dataIndex: "balanceDue",
            key: "balanceDue",
        },
        {
            title: "Actions",
            key: "actions",
            render: (record) => (
                <Space size="middle">
                    <Button
                        category="icon"
                        variant="link"
                        children="eye"
                        size="small"
                        iconSize={16}
                        onClick={() => {
                            viewSupplierOverviewPanel(record);
                            setSelectedRecord(record);
                        }}
                    />
                    <Button
                        category="icon"
                        variant="link"
                        children="ellipsis-vertical"
                        size="small"
                        iconSize={16}
                        onClick={(e) => {
                            handleShowCategoryActions(e);
                            setSelectedRecord(record);
                        }}
                        onMouseLeave={() => toggleActionMenu(false)}
                    />
                </Space>
            ),
        },
    ]);

    //handleCheck
    const handleCheck = async (e, record, idSetList) => {
        setSelectedRowKey({
            id: record.id,
            checked: e.target.checked,
        });
    };

    useEffect(() => {
        if (selectedRowKey && selectedRowKey.checked) {
            setSelectedRowKeys([...selectedRowKeys, selectedRowKey.id]);
        } else {
            setSelectedRowKeys(
                selectedRowKeys.filter((s) => s !== selectedRowKey.id)
            );
        }
    }, [selectedRowKey]);

    //Set data to feilds in the edit mode
    useEffect(async () => {
        if (enableSupplierEditMode && selectedRecord) {
            setBasicInformationIValues({
                key: selectedRecord.key,
                supplierCode: selectedRecord.supplierCode,
                supplierName: selectedRecord.supplierName,
                supplierLandline: selectedRecord.supplierLandline,
                supplierEmail: selectedRecord.supplierEmail,
                balanceDue: selectedRecord.balanceDue,
                supplierAddress: selectedRecord.supplierAddress,
                isExternalIntegration: selectedRecord.isExternalIntegration,
                contactPerson: selectedRecord.contactPerson,
                supplierMobile: selectedRecord.supplierMobile,
                id: selectedRecord.id,
            });
            setShowAddSupplierPanel(true);
        } else {
            setBasicInformationIValues({
                supplierName: (basicInformationIValues.supplierName = ""),
                supplierAddress: (basicInformationIValues.supplierAddress = ""),
                supplierEmail: (basicInformationIValues.supplierEmail = ""),
                supplierLandline: (basicInformationIValues.supplierLandline =
                    ""),
                supplierMobile: (basicInformationIValues.supplierMobile = ""),
            });
            setShowAddSupplierPanel(false);
        }
    }, [enableSupplierEditMode, selectedRecord]);

    //UseState to Set The fetched data
    const [tableData, setTableData] = useState([]);

    //Fetch Data Method Calling Point
    useEffect(() => {
        let isMounted = false;
        !isMounted && fetchData();
        return () => (isMounted = true);
    }, [props.selectedLocation]);

    //Fetch Data Method
    const fetchData = async () => {
        setSelectedRowKeys([]);
        setLoadingSuppliers(true);
        const data = await getEposSuppliersAllByOrgId({
            OrgID: dashboardState.currentlySelectedOrgId,
        });
        const tableDataFromResult = await getTableDataFromResult(data);
        setTableData(tableDataFromResult);
        setLoadingSuppliers(false);
    };

    //Map the fetched data
    const getTableDataFromResult = async (result) => {
        result = sortResultsFromCreatedDate(result);
        return await result.map((supplier, index) => {
            return {
                key: index,
                supplierCode: supplier.SupplierID,
                supplierName: supplier.Name,
                supplierLandline: supplier.Telephone,
                supplierEmail: supplier.Email,
                balanceDue: supplier.PendingPayment,
                supplierAddress: supplier.Address,
                isExternalIntegration: supplier.IsExternalIntegration,
                id: supplier.ID,
                supplierMobile: supplier.MobileNo,
                contactPerson: supplier.ContactPerson,
            };
        });
    };

    //sort the fetched data by the created date
    const sortResultsFromCreatedDate = (result) => {
        return result.sort((result1, result2) => {
            return (
                new Date(result2.CreatedDate) - new Date(result1.CreatedDate)
            );
        });
    };

    const [apiValidationError, setApiValidationError] = useState(null);

    const [selectedCategory, setSelectedCategory] = useState(null);

    const toggleAddMenu = (showAddMenu) => {
        setShowAddMenu(showAddMenu);
    };

    const handleShowCategoryActions = (event) => {
        setActionMenuPopoverTriggerEvent(event);
        setShowActionMenu(true);
    };

    const toggleActionMenu = (showActionMenu) => {
        setShowActionMenu(showActionMenu);
        setShowAddMenu(false);
    };

    const addSupplierPanelHeader = (
        <RightPanelHeader
            title={enableSupplierEditMode ? "Update Supplier" : "Add Supplier"}
            rightContent={
                enableSupplierEditMode ? (
                    <Button
                        onClick={() => handleSubmitUpdateSupplier()}
                        variant="primary"
                    >
                        Update
                    </Button>
                ) : (
                    <Button
                        onClick={() => handleSubmitAddSupplier()}
                        variant="primary"
                    >
                        Save
                    </Button>
                )
            }
        />
    );

    //saving the edited supplier
    const handleSubmitUpdateSupplier = async () => {
        const validationErrors = await validateBasicInfo();
        if (validationErrors.missingBasicFieldNames.length > 0) {
            console.log("Validation Errors Occured");
        } else {
            if (validationErrors.emailValidationIssue.supplierEmail === true) {
                console.log("Wrong E-mail format");
            } else {
                const res = await updateSupplierInTheDB();
                if (res.isAdded === true) {
                    Message.success(`Supplier is added successfully.`);
                    setBasicInformationIValues({
                        supplierName: "",
                        supplierAddress: "",
                        supplierEmail: "",
                        supplierLandline: "",
                        supplierMobile: "",
                        contactPerson: "",
                    });
                    setShowAddSupplierPanel(false);
                    toggleActionMenu(false);
                    setEmailValidationIssue(false);
                    setApiValidationError(null);
                    setEnableSupplierEditMode(false);
                    fetchData();
                } else {
                    console.log(res);
                    setApiValidationError(res.Message);
                }
            }
        }
    };

    //save supplier
    const handleSubmitAddSupplier = async () => {
        await validateBasicInfo();
        if (
            basicInfoValidationErrors.supplierName === false &&
            basicInfoValidationErrors.supplierAddress === false
        ) {
            const res = await saveSupplierToTheDB();
            if (res.isAdded === true) {
                Message.success(`Supplier is added successfully.`);
                fetchData();
            }
        }
        // const res = await saveSupplierToTheDB();
        // if (res.isAdded === true) {
        //   Message.success(`Supplier is added successfully.`);
        //   fetchData();
        // }
        setBasicInformationIValues({
            supplierName: (basicInformationIValues.supplierName = ""),
            supplierAddress: (basicInformationIValues.supplierAddress = ""),
            supplierEmail: (basicInformationIValues.supplierEmail = ""),
            supplierLandline: (basicInformationIValues.supplierLandline = ""),
            supplierMobile: (basicInformationIValues.supplierMobile = ""),
        });
        setShowAddSupplierPanel(false);
        setContactPersonValues([]);
    };

    // validation
    const validateBasicInfo = () => {
        const { supplierAddress, supplierName, supplierEmail } =
            basicInformationIValues;
        var regex = new RegExp(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        const newErrors = {};
        const missingBasicFieldNames = [];
        const emailValidationIssue = {};

        if (!supplierAddress || supplierAddress.trim() === "") {
            newErrors.supplierAddress = true;
            missingBasicFieldNames.push("Supplier Address");
        }
        if (!supplierName || supplierName.trim() === "") {
            newErrors.supplierName = true;
            missingBasicFieldNames.push("Supplier Name");
        }
        if (supplierEmail) {
            if (regex.test(supplierEmail) === false) {
                setEmailValidationIssue(true);
                emailValidationIssue.supplierEmail = true;
            }
        }
        setbasicInfoValidationErrors(newErrors);
        setMissingBasicFieldNames(missingBasicFieldNames);

        return {
            missingBasicFieldNames: missingBasicFieldNames,
            emailValidationIssue: emailValidationIssue,
        };
    };

    //save supplier
    const saveSupplierToTheDB = async () => {
        const apiReqBody = {
            CloudSupplierID: "",
            OrgID: dashboardState?.currentlySelectedOrgId,
            Name: basicInformationIValues.supplierName,
            Address: basicInformationIValues.supplierAddress,
            Email: basicInformationIValues.supplierEmail,
            Landline: basicInformationIValues.supplierLandline,
            MobileNo: basicInformationIValues.supplierMobile,
            CreatedBy: 1,
            ModifiedBy: 0,
            CloudCreatedBy: userState?.UserID,
            CloudModifiedBy: "",
            ContactPerson: basicInformationIValues.contactPerson,
        };

        const data = await addEposSupplier({
            apiReqBody: apiReqBody,
        });

        return data;
    };

    //update supplier
    const updateSupplierInTheDB = async () => {
        var proposedDate = moment().toISOString();

        const apiReqBody = {
            ID: selectedRecord.id,
            SupplierID: 0,
            CloudSupplierID: selectedRecord.id,
            Name: basicInformationIValues.supplierName,
            ModifiedDate: proposedDate,
            CloudModifiedBy: userState?.UserID,
            Address: basicInformationIValues.supplierAddress,
            Email: basicInformationIValues.supplierEmail,
            Telephone: basicInformationIValues.supplierLandline,
            OrgID: dashboardState.currentlySelectedOrgId,
            MobileNo: basicInformationIValues.supplierMobile,
            ContactPerson: basicInformationIValues.contactPerson,
        };

        // console.log("update data", apiReqBody);

        const data = await editEposSupplier({
            apiReqBody: apiReqBody,
        });

        return data;
    };

    const fieldsWithValidation = [
        { name: "supplierName", label: "Supplier Name" },
        { name: "supplierAddress", label: "Supplier Category" },
    ];

    useEffect(() => {
        let missingBasicFieldNames = fieldsWithValidation
            .filter((field) => missingBasicFields.includes(field.name))
            .map((field) => field.label);
        // setMissingBasicFieldNames(missingBasicFieldNames);
    }, [missingBasicFields]);

    const supplierOverviewPanelHeader = (
        <RightPanelHeader
            title="Supplier Overview"
            rightContent={
                <Space size="middle">
                    <Button
                        onClick={() => {
                            setShowDeleteConfirmPopup(true);
                            toggleActionMenu(false);
                        }}
                    >
                        Delete
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            viewEditCategoryOverviewPanel();
                            hideSupplierOverviewPanel();
                        }}
                    >
                        Edit
                    </Button>
                </Space>
            }
        />
    );

    //Action Menu
    const hideActionMenu = () => {
        setShowActionMenu(false);
    };

    //Right Panel Close
    const hideAddSupplierPanel = () => {
        setShowAddSupplierPanel(false);
        setEnableSupplierEditMode(false);
        toggleActionMenu(false);
        setContactPersonValues([]);
        setBasicInformationIValues({
            // supplierName: basicInformationIValues.supplierName = "",
            // supplierAddress: basicInformationIValues.supplierAddress = "",
            // supplierEmail: basicInformationIValues.supplierEmail = "",
            // supplierLandline: basicInformationIValues.supplierLandline = "",
            // supplierMobile:basicInformationIValues.supplierMobile = "",
        });
    };

    //Right Panel Open
    const viewAddSupplierPanel = () => {
        setShowAddSupplierPanel(true);
        toggleAddMenu(false);
        setShowActionMenu(false);
    };

    //Supplier overview pannel open
    const viewSupplierOverviewPanel = async (record) => {
        await fetchSupplierOverviewData(record);
        setShowSupplierOverviewPanel(true);
        setShowActionMenu(false);
    };

    //supplier overview pannel close
    const hideSupplierOverviewPanel = () => {
        setShowSupplierOverviewPanel(false);
    };

    const fetchSupplierOverviewData = async (record) => {
        const reqBody = {
            OrgID: dashboardState.currentlySelectedOrgId,
            CloudSupplierID: record.id,
        };
        console.log("overview data", reqBody);
        const res = await overviweEposSupplier(reqBody);
        setOverviewOfSupplier(res.Message);
    };

    const missingBasicInfoMessage =
        missingBasicFieldNames.length > 0
            ? `${missingBasicFieldNames.join(
                  ", "
              )} fields are missing in Basic Information.`
            : "";

    // const viewEditSupplierOverviewPanel = (record) => {
    //   console.log("record", record);
    //   // setSelectedRecord(record)
    //   // setShowCategoryOverviewPanel(false);
    //   // setSelectedCategoryId(selectedCategoryId);
    //   // setShowAddOrEditPanel(true);
    //   // setEnableCategoryEditMode(true);
    //   // toggleActiomMenu(false);
    // };

    //edit a supplier
    const viewEditCategoryOverviewPanel = () => {
        setEnableSupplierEditMode(true);
        // setShowAddSupplierPanel(true);
    };

    //delete a supplier
    const deleteSelectedSupplier = async () => {
        const apiReqBody = {
            CloudSupplierIDs: [`${selectedRecord.id}`],
            OrgID: dashboardState.currentlySelectedOrgId,
            CloudUserID: userState?.UserID,
        };
        const res = await deleteEposSupplier(apiReqBody);
        if (res.isDeleted === true) {
            Message.success(
                `Supplier (${selectedRecord.supplierName}) is deleted successfully.`
            );
            fetchData();
        }
        setShowSupplierOverviewPanel(false);
        setShowDeleteConfirmPopup(false);
    };

    //contact person data
    const handleChangeInputContactPerson = (e, fieldName) => {
        let contactPersonDetails = {
            ...contactPerson,
            [fieldName]: e.target.value,
        };
        setContactPerson(contactPersonDetails);
    };

    //contact person data
    const handleContactPersonDesignation = (e, fieldName) => {
        let contactPersonDetails = {
            ...contactPerson,
            [fieldName]: e.value,
        };
        setContactPerson(contactPersonDetails);
    };

    const onCancelDeleteConfirmPopup = () => {
        setShowDeleteConfirmPopup(false);
    };

    const onCancelDeleteConfirmPopupForMultipleSuppliers = () => {
        setShowDeleteConfirmPopupForMultipleSuppliers(false);
    };

    const handleSetSearchKey = async (value) => {
        setSearchKey(value);
        setLoadingSuppliers(true);
    };

    const handleSearchCategory = async (value) => {
        console.log("value", value);
    };

    useEffect(async () => {
        let isMounted = false;
        !isMounted && searchSuppliers();
        return (isMounted = true);
    }, [searchKey]);

    const searchSuppliers = async () => {
        const search = await searchCount();

        const reqBody = {
            OrgID: dashboardState.currentlySelectedOrgId,
            IsGeneralSearchApplicable: true,
            SearchBy: selectedLeftValue.value,
            SearchKey: searchKey,
            IsAdvanceSearchApplicable: false,
            AdvanceSearchBy: {
                BalanceDue: {
                    IsLessThan: false,
                    IsGreaterThan: false,
                    Amount: 0.0,
                    IsInBetween: false,
                    InBetween: {
                        MinAmount: 0.0,
                        MaxAmount: 0.0,
                    },
                },
            },
            Pagination: {
                Start: 0,
                Limit: search,
            },
        };

        setLoadingSuppliers(true);
        const searchResult = await searchEposSupplier(reqBody);
        const tableDataFromResult = await getTableDataFromResult(
            searchResult.Message
        );

        setTableData(tableDataFromResult);
        setLoadingSuppliers(false);
    };

    const searchCount = async () => {
        const reqBody = {
            OrgID: dashboardState.currentlySelectedOrgId,
            IsGeneralSearchApplicable: true,
            SearchBy: selectedLeftValue.value,
            SearchKey: searchKey,
            IsAdvanceSearchApplicable: false,
            AdvanceSearchBy: {
                BalanceDue: {
                    IsLessThan: false,
                    IsGreaterThan: false,
                    Amount: 0.0,
                    IsInBetween: false,
                    InBetween: {
                        MinAmount: 0.0,
                        MaxAmount: 0.0,
                    },
                },
            },
            Pagination: {
                Start: 0,
                Limit: 0,
            },
        };

        const searchResultCount = await searchCountEposSupplier(reqBody);
        return searchResultCount.Message.Count;
    };

    const [advancedSearchComponents, setAdvancedSearchComponents] = useState([
        // {
        //   key: 1,
        //   title: "Created Date",
        //   type: "date-range-select",
        //   isVisible: false,
        // },
        {
            key: 1,
            title: "Balance Due",
            type: "range-select",
            units: [
                settingsState.currencySettings.DefaultCurrency.CurrencySymbol,
            ],
            isVisible: false,
        },
    ]);

    const handleClearAll = () => {
        setSelectedLeftValue("");
        handleSetSearchKey("");

        const selectedComponents = advancedSearchComponents.map(
            (searchComponent) => {
                return {
                    ...searchComponent,
                    isVisible: false,
                };
            }
        );
        setAdvancedSearchComponents(selectedComponents);
        fetchData();
    };

    const defaultAdvancedSearchFilters = {
        IsSearchByCreatedDate: false,
        Date: {
            Start: "2018-01-01T00:00:00.000+00:00",
            EndDate: "2022-04-01T00:00:00.000+00:00",
        },
        IsSearchByVatRate: false,
        VatRate: {
            IsDeliveryVatRate: false,
            Delivery: {
                IsLessThan: false,
                IsGreaterThan: false,
                Rate: 0.0,
                IsInBetween: false,
                InBetween: {
                    MinRate: 0.0,
                    MaxRate: 0.0,
                },
            },
            IsTakeAwayVatRate: false,
            TakeAway: {
                IsLessThan: false,
                IsGreaterThan: false,
                Rate: 0.0,
                IsInBetween: false,
                InBetween: {
                    MinRate: 0.0,
                    MaxRate: 0.0,
                },
            },
            IsTableVatRate: false,
            Table: {
                IsLessThan: false,
                IsGreaterThan: false,
                Rate: 0.0,
                IsInBetween: false,
                InBetween: {
                    MinRate: 0.0,
                    MaxRate: 0.0,
                },
            },
            IsOnlineVatRate: false,
            Online: {
                IsLessThan: false,
                IsGreaterThan: false,
                Rate: 0.0,
                IsInBetween: false,
                InBetween: {
                    MinRate: 0.0,
                    MaxRate: 0.0,
                },
            },
        },
    };

    const [advancedSearchFilters, setAdvancedSearchFilters] = useState(
        defaultAdvancedSearchFilters
    );

    const [isSearchApplicable, setIsSearchApplicable] = useState(false);

    const handleApplySearchFilters = (
        searchComponent,
        selectedOption,
        value
    ) => {
        setIsSearchApplicable(true);

        if (searchComponent === "Balance Due") {
            setAdvancedSearchFilters({
                ...advancedSearchFilters,
                // IsSearchByVatRate: true,
                // VatRate: {
                //     ...advancedSearchFilters.VatRate,
                //     IsDeliveryVatRate: true,
                //     Delivery: {
                //         IsLessThan: selectedOption === "Less than",
                //         IsGreaterThan: selectedOption === "More than",
                //         Rate: Number(
                //             selectedOption !== "Between" ? value.value : 0
                //         ),
                //         IsInBetween: selectedOption === "Between",
                //         InBetween: {
                //             MinRate: Number(
                //                 selectedOption === "Between"
                //                     ? value[0].value
                //                     : 0
                //             ),
                //             MaxRate: Number(
                //                 selectedOption === "Between"
                //                     ? value[1].value
                //                     : 0
                //             ),
                //         },
                //     },
                // },
                BalanceDue: {
                    IsLessThan: selectedOption === "Less than",
                    IsGreaterThan: selectedOption === "More than",
                    Amount: selectedOption !== "Between" ? +value.value : 0,
                    IsInBetween: selectedOption === "Between",
                    InBetween: {
                        MinAmount:
                            selectedOption === "Between" ? +value[0].value : 0,
                        MaxAmount:
                            selectedOption === "Between" ? +value[1].value : 0,
                    },
                },
            });
        }

        const advancedSearchComponent = advancedSearchComponents.map(
            (advancedSearchComponent) => {
                if (advancedSearchComponent.title === searchComponent) {
                    return {
                        ...advancedSearchComponent,
                        selectedOption: selectedOption,
                    };
                }
                return advancedSearchComponent;
            }
        );
    };

    useEffect(async () => {
        if (isSearchApplicable) {
            const balanceDue = {
                IsLessThan: false,
                IsGreaterThan: false,
                Amount: 0.0,
                IsInBetween: false,
                InBetween: {
                    MinAmount: 0.0,
                    MaxAmount: 0.0,
                },
            };

            if (advancedSearchFilters.BalanceDue.IsLessThan) {
                balanceDue.IsLessThan = true;
                balanceDue.Amount = advancedSearchFilters.BalanceDue.Amount;
            } else if (advancedSearchFilters.BalanceDue.IsGreaterThan) {
                balanceDue.IsGreaterThan = true;
                balanceDue.Amount = advancedSearchFilters.BalanceDue.Amount;
            } else {
                balanceDue.IsInBetween = true;
                balanceDue.InBetween.MinAmount =
                    advancedSearchFilters.BalanceDue.InBetween.MinAmount;
                balanceDue.InBetween.MaxAmount =
                    advancedSearchFilters.BalanceDue.InBetween.MaxAmount;
            }

            const totalCount = await advanceSearchCount(balanceDue);

            const reqBody = {
                OrgID: dashboardState.currentlySelectedOrgId,
                IsGeneralSearchApplicable: false,
                SearchBy: !selectedLeftValue.value
                    ? "All"
                    : selectedLeftValue.value,
                SearchKey: searchKey,
                IsAdvanceSearchApplicable: true,
                AdvanceSearchBy: {
                    BalanceDue: balanceDue,
                },
                Pagination: {
                    Start: 0,
                    Limit: totalCount == 0 ? 1 : totalCount,
                },
            };

            setLoadingSuppliers(true);
            const searchResult = await searchEposSupplier(reqBody);
            if (searchResult) {
                const mappedData = await getTableDataFromResult(
                    searchResult.Message
                );
                setTableData(mappedData);
                setLoadingSuppliers(false);
            }
        }
    }, [advancedSearchFilters, isSearchApplicable]);

    const advanceSearchCount = async (balanceDue) => {
        const reqBody = {
            OrgID: dashboardState.currentlySelectedOrgId,
            IsGeneralSearchApplicable: false,
            SearchBy: !selectedLeftValue.value
                ? "All"
                : selectedLeftValue.value,
            SearchKey: searchKey,
            IsAdvanceSearchApplicable: true,
            AdvanceSearchBy: {
                BalanceDue: balanceDue,
            },
            Pagination: {
                Start: 0,
                Limit: 0,
            },
        };

        const searchResultCount = await searchCountEposSupplier(reqBody);
        return searchResultCount.Message.Count;
    };

    useEffect(() => {
        reRenderColumnData();
    }, [selectedRowKeys]);

    // Multiple Supplier Delete
    const multipleDeleteSupplier = async () => {
        const apiReqBody = {
            CloudSupplierIDs: selectedRowKeys,
            OrgID: dashboardState.currentlySelectedOrgId,
            CloudUserID: userState?.UserID,
        };
        const res = await deleteEposSupplier(apiReqBody);
        if (res.isDeleted === true) {
            Message.success(`Suppliers are deleted successfully.`);
            setSelectedRowKeys([]);
            setSelectedRowKey({});
            reRenderColumnData();
            setShowDeleteConfirmPopupForMultipleSuppliers(false);
            fetchData();
        }
        // setSelectedRowKeys(selectedRowKeys.filter((s) => console.log("s", s)))
    };

    const reRenderColumnData = () => {
        setColumnData([
            {
                title: () => (
                    <div>
                        <Checkbox />
                    </div>
                ),
                key: "rowSelector",
                render: (record) => (
                    <Checkbox
                        onChange={(e) => handleCheck(e, record)}
                        checked={
                            selectedRowKeys.findIndex((x) => x === record.id) >
                            -1
                        }
                    />
                ),
            },
            ...columnData.filter((cd) => cd.key !== "rowSelector"),
        ]);
    };

    return (
        <div className="supplier-page-content" style={{ padding: "20px" }}>
            <div
                className="supplier-page-header"
                style={{ paddingBottom: "20px" }}
            >
                <Stack horizontalAlign="space_between">
                    <Stack>
                        <StackItem style={{ width: "280px", marginRight: 20 }}>
                            <InputGroup
                                leftComponent="select"
                                leftOptions={leftOptions}
                                leftValue={selectedLeftValue}
                                leftWidth="50%"
                                onChangeLeftValue={(e) =>
                                    setSelectedLeftValue(e)
                                }
                                rightComponent="search"
                                rightWidth="50%"
                                rightValue={searchKey}
                                onChangeRightValue={(value) => {
                                    handleSetSearchKey(value);
                                    handleSearchCategory(value);
                                }}
                            ></InputGroup>
                        </StackItem>
                        {advancedSearchComponents.map((searchComponent) => {
                            if (searchComponent.isVisible) {
                                if (searchComponent.type === "range-select") {
                                    return (
                                        <StackItem style={{ margin: 10 }}>
                                            <SearchableMenu
                                                groupedItems={[
                                                    {
                                                        selectedOption:
                                                            searchComponent.selectedOption,
                                                        items: [
                                                            {
                                                                text: "Less than",
                                                                inputs: [
                                                                    <InputGroup
                                                                        leftComponent="select"
                                                                        leftOptions={searchComponent.units.map(
                                                                            (
                                                                                unit
                                                                            ) => {
                                                                                return {
                                                                                    label: unit,
                                                                                    value: unit,
                                                                                };
                                                                            }
                                                                        )}
                                                                        leftValue={{
                                                                            label: searchComponent
                                                                                .units[0],
                                                                            value: searchComponent
                                                                                .units[0],
                                                                        }}
                                                                        onChangeLeftValue={
                                                                            ""
                                                                        }
                                                                        leftWidth="30%"
                                                                        rightComponent="number"
                                                                        rightWidth="70%"
                                                                        style={{
                                                                            width: "160px",
                                                                        }}
                                                                    />,
                                                                ],
                                                            },
                                                            {
                                                                text: "More than",
                                                                inputs: [
                                                                    <InputGroup
                                                                        leftComponent="select"
                                                                        leftOptions={searchComponent.units.map(
                                                                            (
                                                                                unit
                                                                            ) => {
                                                                                return {
                                                                                    label: unit,
                                                                                    value: unit,
                                                                                };
                                                                            }
                                                                        )}
                                                                        leftValue={{
                                                                            label: searchComponent
                                                                                .units[0],
                                                                            value: searchComponent
                                                                                .units[0],
                                                                        }}
                                                                        onChangeLeftValue={
                                                                            ""
                                                                        }
                                                                        leftWidth="30%"
                                                                        rightComponent="number"
                                                                        rightWidth="70%"
                                                                        style={{
                                                                            width: "160px",
                                                                        }}
                                                                    />,
                                                                ],
                                                            },
                                                            {
                                                                text: "Between",
                                                                inputs: [
                                                                    <InputGroup
                                                                        leftComponent="select"
                                                                        leftOptions={searchComponent.units.map(
                                                                            (
                                                                                unit
                                                                            ) => {
                                                                                return {
                                                                                    label: unit,
                                                                                    value: unit,
                                                                                };
                                                                            }
                                                                        )}
                                                                        leftValue={{
                                                                            label: searchComponent
                                                                                .units[0],
                                                                            value: searchComponent
                                                                                .units[0],
                                                                        }}
                                                                        onChangeLeftValue={
                                                                            ""
                                                                        }
                                                                        leftWidth="30%"
                                                                        rightComponent="number"
                                                                        rightWidth="70%"
                                                                        style={{
                                                                            width: "160px",
                                                                        }}
                                                                    />,
                                                                    <br />,
                                                                    <Label>
                                                                        And
                                                                    </Label>,
                                                                    <br />,
                                                                    <InputGroup
                                                                        leftComponent="select"
                                                                        leftOptions={searchComponent.units.map(
                                                                            (
                                                                                unit
                                                                            ) => {
                                                                                return {
                                                                                    label: unit,
                                                                                    value: unit,
                                                                                };
                                                                            }
                                                                        )}
                                                                        leftValue={{
                                                                            label: searchComponent
                                                                                .units[0],
                                                                            value: searchComponent
                                                                                .units[0],
                                                                        }}
                                                                        onChangeLeftValue={
                                                                            ""
                                                                        }
                                                                        leftWidth="30%"
                                                                        rightComponent="number"
                                                                        rightWidth="70%"
                                                                        style={{
                                                                            width: "160px",
                                                                        }}
                                                                    />,
                                                                ],
                                                            },
                                                        ],
                                                    },
                                                ]}
                                                placement="topLeft"
                                                action={(text, values) =>
                                                    // console.log(
                                                    //     "balance due",
                                                    //     text,
                                                    //     values
                                                    // )
                                                    handleApplySearchFilters(
                                                        searchComponent.title,
                                                        text,
                                                        values
                                                    )
                                                }
                                            >
                                                <Button
                                                    category="text-icon"
                                                    variant="default"
                                                    text={`Select ${searchComponent.title}`}
                                                    icon="chevron-down"
                                                    size="medium"
                                                    iconSize={20}
                                                    customStyles={{
                                                        borderColor: "#ccc",
                                                    }}
                                                />
                                            </SearchableMenu>
                                        </StackItem>
                                    );
                                }
                            }
                        })}
                        <StackItem>
                            <SearchableMenu
                                groupedItems={[
                                    {
                                        items: advancedSearchComponents.map(
                                            (searchComponent) => ({
                                                checkBox: true,
                                                text: searchComponent.title,
                                                checked:
                                                    searchComponent.isVisible,
                                            })
                                        ),
                                    },
                                ]}
                                placement="topLeft"
                                isSearchable={true}
                                action={(selectedItems) => {
                                    const selectedComponents =
                                        advancedSearchComponents.map(
                                            (searchComponent) => {
                                                return {
                                                    ...searchComponent,
                                                    isVisible:
                                                        selectedItems.includes(
                                                            searchComponent.title
                                                        ),
                                                };
                                            }
                                        );
                                    setAdvancedSearchComponents(
                                        selectedComponents
                                    );
                                }}
                            >
                                <Button
                                    category="icon"
                                    variant="default"
                                    children="filter"
                                    size="medium"
                                    iconSize={20}
                                    className="eco-button--special"
                                    color="white"
                                />
                            </SearchableMenu>
                        </StackItem>
                        <StackItem
                            className="eco-divider-l"
                            style={{ margin: 10 }}
                        >
                            <Button
                                category="text"
                                children="Clear All"
                                variant="subtle"
                                onClick={handleClearAll}
                            />
                        </StackItem>
                    </Stack>
                    <Stack
                        horizontalAlign="space_between"
                        style={{ alignItems: "flex-start" }}
                    >
                        <StackItem className="eco-mr-12" style={{ margin: 10 }}>
                            <Button
                                category="icon"
                                variant="primary"
                                children="plus"
                                size="small"
                                iconSize={20}
                                color="white"
                                onClick={(e) => {
                                    setAddMenuPopoverTriggerEvent(e);
                                    toggleAddMenu(true);
                                }}
                            />
                        </StackItem>

                        {selectedRowKeys?.length > 0 && (
                            <StackItem
                                className="eco-mr-12"
                                style={{ margin: 10 }}
                            >
                                <Button
                                    category="icon"
                                    variant="default"
                                    children="trashcan"
                                    size="small"
                                    iconSize={20}
                                    onClick={(e) => {
                                        setShowDeleteConfirmPopupForMultipleSuppliers(
                                            true
                                        );
                                    }}
                                />
                            </StackItem>
                        )}

                        <StackItem className="eco-mr-12" style={{ margin: 10 }}>
                            <Button
                                category="icon"
                                variant="default"
                                children="settings"
                                size="small"
                                iconSize={20}
                            />
                        </StackItem>
                        <StackItem style={{ margin: 10 }}>
                            <Button
                                category="icon"
                                variant="default"
                                children="ellipsis"
                                size="small"
                                iconSize={20}
                            />
                        </StackItem>
                    </Stack>
                </Stack>
            </div>
            <Popover
                position="bottom-right"
                popoverTriggerEvent={addMenuPopoverTriggerEvent}
                active={showAddMenu}
                showHidePopover={toggleAddMenu}
                type="basic"
                customStyles={{ marginTop: 8 }}
            >
                <ActionList
                    width={160}
                    items={[
                        {
                            key: 1,
                            prefix: <Icon name="tag" size={16} />,
                            content: "Supplier",
                            onClick: () => {
                                viewAddSupplierPanel();
                            },
                        },
                        {
                            key: 2,
                            prefix: <Icon name="tag" size={16} />,
                            content: "Purchase Order",
                            onClick: () => {},
                        },
                        {
                            key: 3,
                            prefix: <Icon name="tag" size={16} />,
                            content: "Purchase Return",
                            onClick: {},
                        },
                        {
                            key: 4,
                            prefix: <Icon name="tag" size={16} />,
                            content: "Direct GRN",
                            onClick: {},
                        },
                        {
                            key: 5,
                            prefix: <Icon name="tag" size={16} />,
                            content: "Transfers",
                            onClick: {},
                        },
                    ]}
                    type="action"
                />
            </Popover>

            <Popover
                position="bottom-right"
                popoverTriggerEvent={actionMenuPopoverTriggerEvent}
                active={showActionMenu}
                showHidePopover={toggleActionMenu}
                type="basic"
                customStyles={{ width: "128px" }}
            >
                <ActionList
                    width={160}
                    items={[
                        {
                            key: 1,
                            prefix: <Icon name="pencil" size={16} />,
                            content: "Edit",
                            onClick: () => {
                                setShowAddSupplierPanel(true);
                                setEnableSupplierEditMode(true);
                                toggleActionMenu(false);
                            },
                        },
                        {
                            key: 2,
                            prefix: <Icon name="trashcan" size={16} />,
                            content: "Delete",
                            onClick: () => {
                                setShowDeleteConfirmPopup(true);
                                toggleActionMenu(false);
                            },
                        },
                        {
                            key: 3,
                            prefix: <Icon name="trashcan" size={16} />,
                            content: "Audit Trail",
                            onClick: () => alert("true"),
                        },
                    ]}
                    type="action"
                />
            </Popover>
            <ConfirmationPopup
                modalVisible={showDeleteConfirmPopup}
                onSuccess={() => deleteSelectedSupplier(selectedCategory)}
                onCancel={onCancelDeleteConfirmPopup}
                confirmationMessage={`Are you sure you want to delete this supplier ?`}
                okText="Yes"
                cancelText="No"
            />
            <ConfirmationPopup
                modalVisible={showDeleteConfirmPopupForMultipleSuppliers}
                onSuccess={() => multipleDeleteSupplier()}
                onCancel={onCancelDeleteConfirmPopupForMultipleSuppliers}
                confirmationMessage={`Are you sure you want to delete this suppliers ?`}
                okText="Yes"
                cancelText="No"
            />
            <RightPanel
                active={showAddSupplierPanel}
                size="medium"
                outsideClose={true}
                headerContent={addSupplierPanelHeader}
                handleOnClose={hideAddSupplierPanel}
            >
                <Space direction="vertical" style={{ width: "100%" }}>
                    {missingBasicInfoMessage && (
                        <Alert
                            message="Required Fields Missing!"
                            showIcon
                            description={`
            ${missingBasicInfoMessage}
          `}
                            type="error"
                            closable
                        />
                    )}

                    {apiValidationError ? (
                        <Alert
                            message={apiValidationError}
                            showIcon
                            description={`
            ${apiValidationError}
          `}
                            type="error"
                            closable
                        />
                    ) : null}

                    {emailValidationIssue && (
                        <Alert
                            message="E-Mail is not valid !"
                            showIcon
                            description="Enter a valid e-mail"
                            type="error"
                            closable
                        />
                    )}

                    <Accordion
                        theme="default"
                        type="content_only"
                        title="Basic Information"
                        collapsed={false}
                    >
                        <div style={{ width: "100%" }}>
                            <BasicInformationForm
                                validations={basicInfoValidationErrors}
                                basicInformationIValues={
                                    basicInformationIValues
                                }
                                handleChangeBasicInfoInputs={
                                    handleChangeBasicInfoInputs
                                }
                                handleChangeInputContactPerson={
                                    handleChangeInputContactPerson
                                }
                                handleContactPersonDesignation={
                                    handleContactPersonDesignation
                                }
                                contactPersonValues={contactPersonValues}
                                enableSupplierEditMode={enableSupplierEditMode}
                            />
                        </div>
                    </Accordion>
                </Space>
            </RightPanel>
            <RightPanel
                active={showSupplierOverviewPanel}
                size="medium"
                outsideClose={true}
                headerContent={supplierOverviewPanelHeader}
                handleOnClose={hideSupplierOverviewPanel}
            >
                <Space direction="vertical" style={{ width: "100%" }}>
                    <SupplierOverview
                        selectedRecord={selectedRecord}
                        overviewOfSupplier={overviewOfSupplier}
                    />
                </Space>
            </RightPanel>
            <div>
                <Table
                    columns={columnData}
                    dataSource={tableData}
                    loading={loadingSuppliers}
                />
            </div>
        </div>
    );
};

export default SupplierPage;
