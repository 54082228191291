import React, { useEffect, useState } from 'react'
import { getStockAdjustmentSummary } from '../../../../../../utilities/apiRequests/witmegWebNeurolageServerRequests'
import { sectionName as dashboardSectionName } from "../../../../../../redux/slices/dashboard/dashboardConstants";
import { useSelector, useDispatch } from "react-redux";
import {
    Table,
    Stack,
    Typography
} from "witmeg-ui-system";

function AdjustmentTable(props) {
    const {
        startDate,
        endDate,
        passedProductId,
        displayDetail,
        selectedMsaObj,
        rowRecord

    } = props
    const dashboardState = useSelector((state) => state[dashboardSectionName].dashboard);
    const [displayTableDetail, setDisplayTableDetail] = useState(null)
    const [openingStock, setOpeningStock] = useState(null)
    const [columnData, setColumnData] = useState([
        {
            title: "Adjusted Items",
            dataIndex: "DateNTime",
            key: "DateNTime",
            render: (text) => <div>
                <Stack>
                    <Typography style={{ height: '40px', width: '50%' }} color="gray_base" >Date Time</Typography>
                    <Typography style={{ height: '40px', width: '5%' }} color="gray_base">:</Typography>
                    <Typography style={{ height: '40px', width: '50%', display: 'flex', justifyContent: 'flex-start' }} color="gray_darkest" pattern="bold" className="eco-ml-4">
                        {text.DateTime}
                    </Typography>
                </Stack>
                <Stack>
                    <Typography style={{ height: '40px', width: '50%' }} color="gray_base" >Opening Stock</Typography>
                    <Typography style={{ height: '40px', width: '5%' }} color="gray_base">:</Typography>
                    <Typography style={{ height: '40px', width: '50%', display: 'flex', justifyContent: 'flex-start' }} color="gray_darkest" pattern="bold" className="eco-ml-4">
                        {text.OpeningStock}
                    </Typography>
                </Stack>
                <Stack>
                    <Typography style={{ height: '40px', width: '50%' }} color="gray_base" >Unit Type</Typography>
                    <Typography style={{ height: '40px', width: '5%' }} color="gray_base">:</Typography>
                    <Typography style={{ height: '40px', width: '50%', display: 'flex', justifyContent: 'flex-start' }} color="gray_darkest" pattern="bold" className="eco-ml-4">
                        {text.UnitType}
                    </Typography>
                </Stack>
                <Stack>
                    <Typography style={{ height: '40px', width: '50%' }} color="gray_base" >Updated User</Typography>
                    <Typography style={{ height: '40px', width: '5%' }} color="gray_base">:</Typography>
                    <Typography style={{ height: '40px', width: '50%', display: 'flex', justifyContent: 'flex-start' }} color="gray_darkest" pattern="bold" className="eco-ml-4">
                        {text.UpdatedUser}
                    </Typography>
                </Stack>
            </div>,
        },
        {
            title: "In Hand",
            dataIndex: "InHand",
            key: "InHand",
            render: (text) => <a>{text}</a>,
        },
        {
            title: "Adjustment",
            dataIndex: "Adjustment",
            key: "Adjustment",
            render: (text) =>
                <div>
                    <a>{text}</a>

                </div>,
        },


    ])

    //mapping the data 
    const getDataFromTable = async (result) => {
        console.log('result', result)
        return await result.Adjustments.map((adjustments, index) => {
            return {
                key: index,
                DateNTime: {
                    DateTime: selectedMsaObj?.stockDatenTime,
                    OpeningStock: adjustments.NetQty + ' ' + rowRecord?.itemName.unitType,
                    UnitType: rowRecord?.itemName?.unitType,
                    UpdatedUser: selectedMsaObj?.stockAdjustedBy
                },
                InHand: adjustments.NetQty,
                Adjustment: adjustments.Adjustment + ' ' + rowRecord?.itemName.unitType,

            }
        })
    }

    //getting the table data from api
    useEffect(async () => {
        setDisplayTableDetail(null)
        setOpeningStock(null)
        let searchTableData = await getStockAdjustmentSummary({

            ProductID: passedProductId,
            OrgID: dashboardState.currentlySelectedOrgId,
            CloudLocID: dashboardState.currentlySelectedLocationId,
            StartDate: startDate,
            EndDate: endDate

        })
        let dataTable = await getDataFromTable(searchTableData)
        console.log('hello summary', searchTableData);
        setDisplayTableDetail(dataTable)
        setOpeningStock(searchTableData?.StockInHand)
        console.log('OpeningStock', openingStock)
    }, [passedProductId])


    return (
        <div>
            <Table
                type="striped"
                columns={columnData}
                dataSource={displayTableDetail}
            // loading={loadingStocks}
            ></Table>
        </div>
    )
}

export default AdjustmentTable