import React, { useState, useEffect } from 'react'
import { getStockAdjustmentDetail } from '../../../../../../utilities/apiRequests/witmegWebNeurolageServerRequests'
import {
    Table,
    Button,
    RightPanel,
    Space,
    RightPanelHeader,
    Card,
    Typography,
    Stack
} from "witmeg-ui-system";
import MsaDetail from './MsaDetail'
import AdjustmentTable from './AdjustmentTable';
import './TableInfo.css'

function TableInfo(props) {
    const {
        selectedGroupId,
        startDate,
        endDate,
        selectedMsaObj,
        currentLocation,
        noOfItems,
        getTableDataFromDetail,
        displayDetail

    } = props
    const [arrayData, setArrayData] = useState(null);
    const [displayData, setDisplayData] = useState(null);
    const [showDetailPanel, setShowDetailPanel] = useState(false);
    const [passedProductId, setPassedProductId] = useState(null);
    const [selectedRows, setSelectedRows] = useState(null)
    const [rowRecord, setRowRecord] = useState(null)
    const [columnData, setColumnData] = useState([
        {
            title: "Adjusted Items",
            dataIndex: "itemName",
            key: "itemName",
            render: (item) => <div>
                <div>{item.name} </div>
                <br />
                <Stack>
                    <Typography style={{ height: '40px', width: '50%' }} color="gray_base" >Barcode </Typography>
                    <Typography style={{ height: '40px', width: '5%' }} color="gray_base">:</Typography>
                    <Typography style={{ height: '40px', width: '50%', display: 'flex', justifyContent: 'flex-start' }} color="gray_darkest" pattern="bold" className="eco-ml-4">
                        {item.itemCode}
                    </Typography>
                </Stack>
                <Stack>
                    <Typography style={{ height: '40px', width: '50%' }} color="gray_base" >Unit Type </Typography>
                    <Typography style={{ height: '40px', width: '5%' }} color="gray_base">:</Typography>
                    <Typography style={{ height: '40px', width: '50%', display: 'flex', justifyContent: 'flex-start' }} color="gray_darkest" pattern="bold" className="eco-ml-4">
                        {item.unitType}
                    </Typography>
                </Stack>
            </div>,
        },
        {
            title: "In Hand",
            dataIndex: "closingStock",
            key: "closingStock",
            render: (text) => <a>{text}</a>,
        },
        {
            title: "Adjustment",
            dataIndex: "adjustment",
            key: "adjustment",
            render: (record) =>
                <div>
                    <a>{record}</a>

                </div>,
        },
        // {
        //     dataIndex: "productID",
        //     key: 'productID',
        //     render: (record) => (
        //         <Space size="middle">
        //             <Button
        //                 category='icon'
        //                 variant='link'
        //                 children='eye'
        //                 size='small'
        //                 iconSize={16}
        //                 onClick={() => {
        //                     //handleDetailViewPanel(record);
        //                     console.log('handleDetailViewPanel', record);
        //                     setShowDetailPanel(true)
        //                     setPassedProductId(record)
        //                     console.log('PassedProductId', record);
        //                 }}
        //             />


        //         </Space>
        //     ),
        // },

    ])

    //closing detail panel
    const hideDetailPanel = () => {
        setShowDetailPanel(false)
    }

    //detail header 
    const detailHeader = (
        <RightPanelHeader
            title={'Manual Stock Adjustment Overview'}
        ></RightPanelHeader>
    );

    //onClick row select
    const selectRow = (record) => {
        setSelectedRows(record.key);
        console.log('record====>', record)
        setShowDetailPanel(true)
        setPassedProductId(record.productID)
        setRowRecord(record)

    };

    return (
        <div>

            <Table
                type="default"
                columns={columnData}
                dataSource={displayDetail}

                // loading={loadingStocks}
                rowHighlightClass={(record, key) => {
                    return key == selectedRows ? 'selected-row' : ''
                }
                }
                onRow={(record) => {
                    return {
                        onClick: () => { selectRow(record) }
                    }
                }}
            ></Table>
            <RightPanel
                active={showDetailPanel}
                size='big'
                headerContent={detailHeader}
                handleOnClose={hideDetailPanel}>

                <Card
                    height='320px'
                    width='100%'
                    rounded="small"
                    shadow="no_shadow"
                    className='menuItemCard'
                    content={
                        <div>
                            <MsaDetail
                                displayDetail={displayDetail}
                                startDate={startDate}
                                endDate={endDate}
                                passedProductId={passedProductId}
                                selectedMsaObj={selectedMsaObj}
                                currentLocation={currentLocation}
                                noOfItems={noOfItems}
                            />
                        </div>
                    }
                >
                </Card>
                <Card
                    height='320px'
                    width='100%'
                    rounded="small"
                    shadow="no_shadow"
                    className='menuItemCard'
                    content={
                        <div>
                            <AdjustmentTable
                                startDate={startDate}
                                endDate={endDate}
                                passedProductId={passedProductId}
                                displayDetail={displayDetail}
                                selectedMsaObj={selectedMsaObj}
                                rowRecord={rowRecord}
                            />
                        </div>
                    }
                >
                </Card>
            </RightPanel>

        </div>
    )
}

export default TableInfo