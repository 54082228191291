import {
	SearchOutlined as SearchOutlinedIcon,
	PlusCircleOutlined as PlusCircleOutlinedIcon,
} from '@ant-design/icons';
import { Button, Popover, InputNumber, Input as AntdBaseInput } from 'antd';
import React, { Component, useRef, useState } from 'react';

import { getProductListForPurchaseOrderCreation } from '../../../../../../../utilities/apiRequests/witmegWebNeurolageServerRequests';
import CustomInputSearcher from '../../../../../../widgets/formRelated/OmniBar/OmniBar';
import ProductList from '../../../../../ProductList/ProductList';
import { productSearchColumnConfig } from '../../helpers/typeConfigs';

// NOTE : Helper component to give user inline Customer Searching Ability.

export default class ProductInlineSearcher extends Component {
	render() {
		const { selectedOrgId, selectedLocationId, helperFns } = this.props;

		return (
			<div className='ProductInlineSearcher'>
				<CustomInputSearcher
					//
					// This is what initially displayed to user. Clicking on this invoke popup area and searching.
					displayComponent={
						<div>
							<AntdBaseInput
								prefix={<SearchOutlinedIcon />}
								placeholder='Start Typing to Inline Search Products'
							/>
						</div>
					}
					//
					// Will be invoked when Search Input is changing. Its result will passed to bottom "popupContentGenerator()"
					onSearchInputChange={async (data = {}) => {
						const { searchTerm } = data;

						return await getProductListForPurchaseOrderCreation({
							orgId: selectedOrgId,
							locationId: selectedLocationId,
							searchKeyType: 'Name',
							searchKey: searchTerm,
						});
					}}
					//
					// Helper function to determine no items for its internal purposes.
					isEmptyResultChecker={(data = {}) => {
						const { rawSearchResult = {} } = data;
						return (
							rawSearchResult.Result && rawSearchResult.Result.length === 0
						);
					}}
					//
					// Determine what to show in popup area.
					popupContentGenerator={(data = {}) => {
						const {
							rawSearchResult,
							popupContentRenderTargetId, // Where parent popup (<CustomInputSearcher/> Popup) is rendered.
							closePopup,
						} = data;

						return (
							<ProductList
								selectedOrgId={selectedOrgId}
								selectedLocationId={selectedLocationId}
								rawProductList={rawSearchResult.Result}
								customizationProps={{
									customRawColumnListGenerator: (data = {}) => {
										// Specifying what columns to show on ProductList.
										return productSearchColumnConfig;
									},
									customSpecialRootKeyListGenerator: (data = {}) => {
										return [];
									},
									customColumnSortOrderKeysGenerator: (data = {}) => {
										return [];
									},
									customActionColumnButtonGenerator: (data = {}) => {
										// Adding custom action/button to "Actions Column" of ProductList.

										const { selectedRowData } = data;
										const {
											AvailableStock: availableStock,
											EligibleForOrder: eligibleForOrder,
										} = selectedRowData;

										const inputRef = useRef(null);
										const [popupVisible, setPopupVisible] = useState(false);

										return (
											<Popover
												getPopupContainer={() => {
													// NOTE : This is to make sure this Popover is rendered inside Parent Popover (<CustomInputSearcher/> Popup)
													// 				Otherwise some custom popup closing mechanism wont work correctly.

													return document.querySelector(
														`#${popupContentRenderTargetId}`,
													);
												}}
												content={() => {
													return (
														<div>
															<span>
																<span>QTY : </span>
																<InputNumber
																	min={1}
																	max={availableStock}
																	ref={inputRef}
																/>
															</span>

															<Button
																onClick={() => {
																	const purchaseQty = inputRef.current.value;

																	if (purchaseQty && purchaseQty >= 0) {
																		helperFns.onProductAdding({
																			selectedRowData,
																			additionalData: {
																				purchaseQty,
																			},
																		});
																	}

																	setPopupVisible(false);
																	closePopup();
																}}>
																ADD
															</Button>

															<Button
																onClick={() => {
																	setPopupVisible(false);
																}}>
																CANCEL
															</Button>
														</div>
													);
												}}
												title=''
												trigger='click'
												onVisibleChange={(
													visibleStateAccordingToTriggerProp,
												) => {
													// NOTE : In "onVisibleChange" prop, what we receive is Popup state, that should be according "trigger" prop. (This doesn't care that we are using our own state to handle popup visibility by passing "visible" prop.)
													// 				Anyway, We are using this to our advantage by using this to close popup when clicked on outside of Popover.

													if (visibleStateAccordingToTriggerProp === false) {
														setPopupVisible(false);
													}
												}}
												visible={popupVisible}
												overlayInnerStyle={{
													border: '1px solid #0c0c0c21',
												}}
												destroyTooltipOnHide>
												<PlusCircleOutlinedIcon
													title={
														eligibleForOrder ? 'Add Product' : 'Not Available'
													}
													style={{
														/* stylelint-disable */
														color: eligibleForOrder ? '' : 'red',
													}}
													onClick={() =>
														eligibleForOrder ? setPopupVisible(true) : ''
													}
												/>
											</Popover>
										);
									},
								}}
							/>
						);
					}}
				/>
			</div>
		);
	}
}
