import React, { useEffect, useState } from "react";
import { Table, Input, InputNumber, Popconfirm, Form, Select } from "antd";
import { Button, InputBox } from "witmeg-ui-system";
const { Option } = Select;

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    unitTypes,
    ...restProps
}) => {
    const [selectedUnit, setSelectedUnit] = useState("Box");
    const [selectedUnitObj, setSelectedUnitObj] = useState("");
    function handleChange(e) {
        console.log("Change unit Type===>>", e);
        setSelectedUnit(e.target.value);
        setSelectedUnitObj(
            unitTypes.find((u) => u.UnitTypeName === e.target.value)
        );
    }

    const validationMap = {
        Name: [
            {
                required: true,
                message: `Please Enter Name`,
            },
        ],
        Email: [
            {
                type: "email",
                message: `Invalid Email`,
            },
        ],
        Mobile: [
            {
                required: true,
                message: `Please Enter Mobile no.`,
            },
        ],
    };

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={validationMap[dataIndex]}
                >
                    {dataIndex == "Mobile" ? (
                        <InputNumber style={{ width: "100%" }} />
                    ) : (
                        <Input />
                    )}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

function MultipleContactPeronTable({
    handleContactPerson,
    basicInformationIValues,
}) {
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [editingKey, setEditingKey] = useState("");
    const [isAddMode, setIsAddMode] = useState(false);

    useEffect(() => {
        if (basicInformationIValues?.contactPerson) {
            setData(
                basicInformationIValues?.contactPerson.map((cp, i) => {
                    return {
                        key: `cp_${i}`,
                        ...cp,
                    };
                })
            );
        } else {
            setData([]);
        }
    }, [basicInformationIValues.contactPerson]);

    const isEditing = (record) => record.key === editingKey;

    const addNewContactPerson = () => {
        let item = {
            key: data.length.toString(),
            Name: "",
            Staff: "",
            Mobile: "",
            Email: "",
        };
        setData([...data, item]);
        edit(item);
    };

    const edit = (record) => {
        form.setFieldsValue({
            Name: "",
            Staff: "",
            Mobile: "",
            Email: "",
            ...record,
        });
        setEditingKey(record.key);
    };

    const cancel = (key) => {
        // console.log("Key", key);
        let newData = data.filter((item) => item.key !== key);
        setData(newData);
        handleContactPerson(newData);
        setEditingKey("");
    };

    const deleteItem = async (key) => {
        let newData = data.filter((item) => item.key !== key);
        setData(newData);
        handleContactPerson(newData);
    };

    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setData(newData);
                setEditingKey("");
                handleContactPerson(newData);
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey("");
            }
        } catch (errInfo) {
            console.log("Validate Failed:", errInfo);
        }
    };

    const columns = [
        {
            // title:<div> Name <span style={{color: "red"}}>*</span></div>,
            title: "Name",
            dataIndex: "Name",
            key: "Name",
            editable: true,
        },
        {
            title: "Designation",
            dataIndex: "Staff",
            key: "Staff",
            editable: true,
        },
        {
            title: "Mobile",
            dataIndex: "Mobile",
            key: "Mobile",
            editable: true,
            width: "20%",
        },
        {
            title: "E-Mail",
            dataIndex: "Email",
            key: "Email",
            editable: true,
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Button
                            category="icon"
                            variant="subtle"
                            onClick={() => save(record.key)}
                            children="check"
                            size="small"
                            iconSize={16}
                        />
                        <Button
                            category="icon"
                            variant="subtle"
                            children="cancel"
                            size="small"
                            iconSize={16}
                            onClick={() => cancel(record.key)}
                        />
                        <Button
                            category="icon"
                            variant="subtle"
                            children="trashcan"
                            size="small"
                            iconSize={16}
                            onClick={() => deleteItem(record.key)}
                            disabled={record.key === "0"}
                        />
                    </span>
                ) : (
                    <span>
                        <Button
                            category="icon"
                            variant="subtle"
                            children="create"
                            size="small"
                            iconSize={16}
                            disabled={editingKey !== ""}
                            onClick={() => edit(record)}
                        />
                        <Button
                            category="icon"
                            variant="subtle"
                            children="trashcan"
                            size="small"
                            iconSize={16}
                            onClick={() => deleteItem(record.key)}
                        />
                    </span>
                );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: "number",
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <div>
            <Form form={form} component={false}>
                <Table
                    components={{
                        body: {
                            cell: (props) => <EditableCell {...props} />,
                        },
                    }}
                    bordered
                    dataSource={data}
                    columns={mergedColumns}
                    rowClassName="editable-row"
                />
            </Form>
            <Button
                variant="link"
                onClick={() => {
                    addNewContactPerson();
                    setIsAddMode(true);
                }}
            >
                Add New
            </Button>
        </div>
    );
}

export default MultipleContactPeronTable;
