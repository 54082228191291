/* eslint-disable react/sort-comp */
/* eslint-disable no-shadow */

import { bindActionCreators } from '@reduxjs/toolkit';
import { Alert, Button, Tabs, Popconfirm, Space, Empty } from 'antd';
import { LoaderView } from 'ecologital-ui-library';
import { Formik } from 'formik';
import lodash from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import { reqStatusTypes } from '../../../redux/helpers/constants';
import { sectionName as dashboardSectionName } from '../../../redux/slices/dashboard/dashboardConstants';
import { actions as dashboardSliceActions } from '../../../redux/slices/dashboard/dashboardSlice/dashboardSlice';
import { actions as purchaseOrdersPageSliceActions } from '../../../redux/slices/dashboard/purchaseOrderPageSlice/purchaseOrderPageSlice';
import { routePaths } from '../../../routes/routeConstants';
import { errorLogger } from '../../../utilities/errorHandlers';
import routerHistory from '../../../utilities/routerHistory';
import { getLoggedUserId } from '../../../utilities/userAuthentication';
import ConditionalTabRenderer from '../ProductMasterView/helperComponents/ConditionalTabRenderer';

import { createMasterTabFormRelatedApiData } from './helpers/apiDataCreators';
import {
	generateFullBaseInitialState,
	generateDynamicInitialValueGetterFunctions,
	fullValidationScheme,
	mapApiPurchaseOrderDataToFormikFormStructure,
} from './helpers/formikHelperData';
import { generateFomikFormValuesObjParserFunctions, getFormSectionsPartialObjPaths } from './helpers/parserHelpers';
import PromvItemsTab from './subSections/PromvItemsTab/PromvItemsTab';
import PromvMasterTab from './subSections/PromvMasterTab/PromvMasterTab';

import './PurchaseOrderMasterView.css';
import './PurchaseOrderMasterViewUtility.css';
import { savePurchaseOrder, getPurchaseOrderDetailsById, updatePuchaseOrder, updatePuchaseOrderStatus } from '../../../utilities/apiRequests/witmegWebNeurolageServerRequests';

const { TabPane } = Tabs;

const { addedItemsInfoObjPath } = getFormSectionsPartialObjPaths().itemsTab;

class PurchaseOrderMasterView extends Component {
	constructor(props) {
		super(props);

		const { purchaseOrdersPageSliceState } = this.props;
		const { purchaseOrderCrudRelatedData } = purchaseOrdersPageSliceState;

		const { purchaseOrderId: editingPurchaseOrderId } =
			purchaseOrderCrudRelatedData.selectedItemData;
		const isEditMode = purchaseOrderCrudRelatedData.currentCrudType === 'EDIT';

		this.state = {
			activeTabKey: '1', // Currently Showing Tab.
			isEditMode,
			editingPurchaseOrderId,

			formikInitialState: {},

			// "Fetching Initial Page Data", related state.
			pageInitialDataReqStatus: reqStatusTypes.idle,
			pageInitialDataReqError: '',

			rawPurchaseOrderData: {}, // PurchaseOrder's Raw API data. (Especially used when opened in "EDIT" mode)
			purchaseOrderDetails: {},
			purchaseOrderStatus: "pending",
			confirmedOrderData: {},
			selectedPurchasOderId: null
		};
	}

	// componentDidMount() {
	// 	// const id = this.props.match.params.id;
	// 	alert("ID:")
	// }

	async fetchNecessaryInitialPageData() {
		try {
			const { isEditMode, editingPurchaseOrderId } = this.state;
			const { dashboardSliceState } = this.props;

			const { currentlySelectedOrgId, currentlySelectedLocationId } =
				dashboardSliceState;

			// These will update below in certain cases. (Especially in "EDIT" mode)
			let rawPurchaseOrderData = {};
			let preFilledFormikInitialValues = {};
			let formikInitialValueGetterFunctions = {};

			this.setState({
				pageInitialDataReqStatus: reqStatusTypes.pending,
				pageInitialDataReqError: '',
			});

			// *** Below are pre-fetching data that needed for some specific parts in forms.
			// Ex.
			// const salesRepsList = await getSalesRepsListByOrgId({
			// 	orgId: currentlySelectedOrgId,
			// });

			// *** When in "EDIT" Mode.
			if (isEditMode) {
				// Getting Editing PurchaseOrder's Initial Raw Data.
				// Ex:
				// const purchaseOrderDataResponse = await getEposCustomersByCloudCustomerId({
				// 	orgId: currentlySelectedOrgId,
				// 	locationId: currentlySelectedLocationId,
				// 	searchKey: editingPurchaseOrderId,
				// });

				// rawPurchaseOrderData = purchaseOrderDataResponse.Result[0];
				rawPurchaseOrderData = {};

				// Mapping "Raw PurchaseOrder Values" to "Formik Value Structure", So we can pass them as formik initial values.
				preFilledFormikInitialValues = mapApiPurchaseOrderDataToFormikFormStructure({
					purchaseOrderData: rawPurchaseOrderData,
				});
			}

			// Generating functions set, to be used as dynamic/customized initial value getters for needed keys.
			formikInitialValueGetterFunctions =
				generateDynamicInitialValueGetterFunctions({});

			this.setState(
				{
					pageInitialDataReqStatus: reqStatusTypes.succeeded,
					pageInitialDataReqError: '',

					rawPurchaseOrderData,
				},
				() => {
					// NOTE : Re-Setting React State here because, "generateFullBaseInitialState()" may depend on some values from state we define in above.
					this.setState({
						formikInitialState: generateFullBaseInitialState({
							preFilledInitialValues: preFilledFormikInitialValues,
							dynamicInitialValueGetters: formikInitialValueGetterFunctions,
						}),
					});
				},
			);
		} catch (error) {
			errorLogger(error);
			this.setState({
				pageInitialDataReqStatus: reqStatusTypes.failed,
				pageInitialDataReqError: 'Error occurred while fetching initial data.',
			});
		}
	}

	// This get the all the form values in every form of every tab.
	// So in here we parse all those values and send necessary data to Add/Edit a purchaseOrder.
	async handlePurchaseOrderAddEditFormSubmitting(
		allFormValuesInEveryTab,
		formikProps,
	) {
		// try {
		const { isEditMode, editingPurchaseOrderId, rawPurchaseOrderData } = this.state;

		const { dashboardSliceState, purchaseOrdersPageSliceActions } = this.props;

		const { currentlySelectedOrgId } = dashboardSliceState;
		const { resetPurchaseOrderCrudRelatedData } = purchaseOrdersPageSliceActions;

		const parserFunctions = generateFomikFormValuesObjParserFunctions(
			allFormValuesInEveryTab,
			{},
		);

		const userId = getLoggedUserId();

		const commonArgumentsForApiDataCreatorFunctions = {
			userId,
			isEditMode,
			parserFunctions,
		};

		const masterTabFormRelatedApiData = createMasterTabFormRelatedApiData(
			commonArgumentsForApiDataCreatorFunctions,
		);

		const fullAddEposPurchaseOrderApiReqBody = {
			...masterTabFormRelatedApiData,
		};

		const { getFormValueByPath } = parserFunctions;

		const currentItemsList = getFormValueByPath(
			`${addedItemsInfoObjPath}addedItemsList`,
		).slice();



		if (isEditMode) {
			const fullUpdateEposPurchaseOrderApiReqBody = lodash.mergeWith(
				rawPurchaseOrderData, // Existing Customer Data.
				fullAddEposPurchaseOrderApiReqBody,
				// eslint-disable-next-line consistent-return
				(objValue, srcValue, key) => {
					// Making below mentioned keys are not merged with existing values.
					const ignoreMergeKeys = [];
					if (ignoreMergeKeys.includes(key)) {
						return srcValue;
					}
				},
			);

			console.log('Response ERR', fullUpdateEposPurchaseOrderApiReqBody);


			// await savePurchaseOrder(purchaseOrderDetails)

			// await updateEposCustomer({
			// 	ID: editingPurchaseOrderId,
			// 	...fullUpdateEposPurchaseOrderApiReqBody,
			// });
		}
		toast.success(`PurchaseOrder ${isEditMode ? 'Updated' : 'Added'}.`);
		// formikProps.setStatus({
		// 	error: '',
		// });
		// formikProps.setSubmitting(false);

		// // Resetting related redux's existing data and redirecting.
		// resetPurchaseOrderCrudRelatedData();
		// routerHistory.push(routePaths.dashboard__sales__purchaseOrders__search);
		// } catch (error) {
		// 	const errMsg =
		// 		error.customErrMsg ||
		// 		'Error occurred while Creating/Editing the purchaseOrder.';
		// 	errorLogger(error);
		// 	toast.error(errMsg);
		// 	formikProps.setStatus({
		// 		error: errMsg,
		// 	});
		// 	formikProps.setSubmitting(false);
		// }
	}

	getEachTabsMetaData(options = {}) {
		const { formikProps } = options;

		const tabMetaData = {
			masterTab: {},
		};

		const tabsList = [
			{
				tabMetaKey: 'masterTab',
				formikKey: 'masterSection',
			},
		];

		tabsList.forEach((tab) => {
			const tabErrors = lodash.get(formikProps.errors, tab.formikKey);
			const tabTouched = lodash.get(formikProps.touched, tab.formikKey);

			if (lodash.isEmpty(tabErrors)) {
				tabMetaData[tab.tabMetaKey].isErrors = false;
			} else {
				tabMetaData[tab.tabMetaKey].isErrors = true;
			}

			if (lodash.isEmpty(tabTouched)) {
				tabMetaData[tab.tabMetaKey].isTouched = false;
			} else {
				tabMetaData[tab.tabMetaKey].isTouched = true;
			}

			if (
				tabMetaData[tab.tabMetaKey].isErrors &&
				tabMetaData[tab.tabMetaKey].isTouched
			) {
				tabMetaData[tab.tabMetaKey].showErrors = true;
			} else {
				tabMetaData[tab.tabMetaKey].showErrors = false;
			}
		});

		return tabMetaData;
	}

	fetchSelectedPurchaseOrderData = async (id) => {
		const { dashboardSliceState } = this.props;
		const { currentlySelectedOrgId, currentlySelectedLocationId } =
			dashboardSliceState;
		let selectedPurchaseOrderDetails = await getPurchaseOrderDetailsById({
			OrgID: currentlySelectedOrgId,
			CloudLocationID: currentlySelectedLocationId,
			poId: id
		});

		console.log("selectedPurchaseOrderDetails===>>", selectedPurchaseOrderDetails)
		if (selectedPurchaseOrderDetails.Status) {

			const withDefaultFilters = [selectedPurchaseOrderDetails.PurchaseOrders].map(po => {
				return {
					...po,
					PurchaseOrderDetail: po.PurchaseOrderDetail.map(pod => {
						return {
							...pod,
							availableUnitTypes: [pod.UnitTypeID],
							vatRate: (pod.LineVat) / (pod.OrderQuantity)
						}
					})
				}

			})

			this.setState({
				purchaseOrderDetails: withDefaultFilters[0],
				purchaseOrderStatus: "exists",
			})
		} else {
			this.setState({
				purchaseOrderStatus: "notExists",
			})
		}
	}

	// componentDidUpdate(prevProps) {
	// 	const { dashboardSliceState } = this.props;
	// 	const { currentlySelectedOrgId, currentlySelectedLocationId } =
	// 		dashboardSliceState;
	// 	if (prevProps.dashboardSliceState.currentlySelectedOrgId !== currentlySelectedOrgId || prevProps.dashboardSliceState.currentlySelectedLocationId !== currentlySelectedLocationId) {
	// 		alert(JSON.stringify(prevProps))
	// 		const id = this.props.match.params.id;
	// 		if (id) {
	// 			this.fetchSelectedPurchaseOrderData(id)
	// 		}
	// 	}
	// }

	componentDidMount() {
		this.fetchNecessaryInitialPageData();
		const id = this.props.match.params.id;
		if (id) {
			this.setState({
				selectedPurchasOderId: id
			})
			this.fetchSelectedPurchaseOrderData(id)
		}
	}

	componentWillUnmount() {
		const { purchaseOrdersPageSliceActions } = this.props;
		const { resetPurchaseOrderCrudRelatedData } = purchaseOrdersPageSliceActions;

		// Making related redux data is resetted when un-mounting. This is done to make sure when this component mounted again it doesn't show previous data.
		resetPurchaseOrderCrudRelatedData();
	}

	handleChangeSupplierInfo = (supplierInfo) => {
		const poData = { ...this.state.purchaseOrderDetails }
		poData.SupplierID = supplierInfo.SupplierID;
		poData.SupplierName = supplierInfo.SupplierName;
		poData.SupplierReferenceNo = supplierInfo.SupplierReferenceNo;
		poData.SupplierScheme = supplierInfo.SupplierScheme;
		poData.SupplierSchemeType = supplierInfo.SupplierSchemeType;
		this.setState({
			purchaseOrderDetails: poData
		})
	}

	handleSetQty = data => {
		const poData = { ...this.state.purchaseOrderDetails }
		poData.PurchaseOrderDetail = data
		this.setState({
			purchaseOrderDetails: poData
		})
	}

	handleSupplierSchemeChange = data => {
		const poData = { ...this.state.purchaseOrderDetails }
		poData.SupplierScheme = data
		this.setState({
			purchaseOrderDetails: poData
		})
	}

	handleBaseInfoChange = async (data, fieldName) => {
		const poData = { ...this.state.purchaseOrderDetails }
		poData[fieldName] = data
		this.setState({
			purchaseOrderDetails: poData
		})
		if (fieldName === 'SupplierScheme') {
			if (!this.state.purchaseOrderDetails.IsExternalIntegration || (this.state.purchaseOrderDetails.IsExternalIntegration && this.state.purchaseOrderDetails.SupplierScheme && this.state.purchaseOrderDetails.SupplierScheme !== "")) {
				const resp = await updatePuchaseOrder(this.state.purchaseOrderDetails)
				console.log("RES", resp)
				if (resp) {
					toast.success(`PurchaseOrder Updated`);
				} else {
					toast.error(`PurchaseOrder Update failed`);
				}
			} else {
				toast.error(`Please Select Supplier Scheme Type`);
			}
		}
	}

	handleSaveDratf = async () => {
		// const { dashboardSliceState, userSliceState } = this.props;
		// const { currentlySelectedOrgId, currentlySelectedLocationId } =
		// 	dashboardSliceState;
		// const { userDetails } = userSliceState
		// if (!this.state.purchaseOrderDetails.IsExternalIntegration) {
		// 	const resp = await updatePuchaseOrder(this.state.purchaseOrderDetails)
		// 	console.log("RES", resp)
		// 	if (resp) {
		// 		toast.success(`PurchaseOrder Updated`);
		// 	} else {
		// 		toast.error(`PurchaseOrder Update failed`);
		// 	}
		// }

		if (!this.state.purchaseOrderDetails.IsExternalIntegration || (this.state.purchaseOrderDetails.IsExternalIntegration && this.state.purchaseOrderDetails.SupplierScheme && this.state.purchaseOrderDetails.SupplierScheme !== "")) {
			const resp = await updatePuchaseOrder(this.state.purchaseOrderDetails)
			console.log("RES", resp)
			if (resp) {
				toast.success(`Purchase Order Updated`);
				this.fetchNecessaryInitialPageData();
				const id = this.props.match.params.id;
				if (id) {
					this.fetchSelectedPurchaseOrderData(id)
				}
			} else {
				toast.error(`PurchaseOrder Update failed`);
			}
		} else {
			toast.error(`Please Select Supplier Scheme Type`);
		}



	}


	handleConfirmPO = async () => {
		const { dashboardSliceState, userSliceState } = this.props;
		const { currentlySelectedOrgId, currentlySelectedLocationId } =
			dashboardSliceState;
		const { userDetails } = userSliceState
		const resp = await updatePuchaseOrderStatus({
			Status: 3,
			OrgID: currentlySelectedOrgId,
			CloudLocationID: currentlySelectedLocationId,
			CloudPurchaseOrderID: this.state.purchaseOrderDetails.CloudPurchaseOrderID,
		})
		// console.log("RES", resp)
		if (resp && resp.Status) {
			toast.success(`PurchaseOrder Confirmed`);
			if (resp.Action.Action === "Open new tab") {
				let poData = { ...this.state.purchaseOrderDetails }
				poData.Status = 3
				this.setState({
					purchaseOrderDetails: poData,
					confirmedOrderData: resp.Action
				})

				window.open(resp.Action.URL, "_blank");

			}

		} else {
			toast.error(`PurchaseOrder Confirmation failed`);

			// this.setState({
			// 	confirmedOrderData: {
			// 		Action: 'Open new tab',
			// 		URL: 'https://google.lk'
			// 	}
			// })

		}
	}

	handleCancelPO = async () => {
		const { dashboardSliceState, userSliceState } = this.props;
		const { currentlySelectedOrgId, currentlySelectedLocationId } =
			dashboardSliceState;
		const { userDetails } = userSliceState
		let resp = await updatePuchaseOrderStatus({
			Status: 4,
			OrgID: currentlySelectedOrgId,
			CloudLocationID: currentlySelectedLocationId,
			CloudPurchaseOrderID: this.state.purchaseOrderDetails.CloudPurchaseOrderID,
		})
		console.log("RES", resp)
		if (resp && resp.Status) {
			let poData = { ...this.state.purchaseOrderDetails }
			poData.Status = 4
			this.setState({
				purchaseOrderDetails: poData
			})
			toast.success(`PurchaseOrder Cancelled`);
		} else {
			toast.error(`PurchaseOrder Cancellation failed`);
		}
	}

	handleDeletePOItem = async (item) => {
		let poData = { ...this.state.purchaseOrderDetails }
		const productList = poData.PurchaseOrderDetail.filter(pod => pod.CloudProductID !== item.CloudProductID)
		poData.PurchaseOrderDetail = productList
		this.setState({
			purchaseOrderDetails: poData
		})
	}

	setPOItemList = async (itemList) => {
		let poData = { ...this.state.purchaseOrderDetails }
		poData.PurchaseOrderDetail = itemList;
		this.setState({
			purchaseOrderDetails: poData
		})
	}

	render() {
		const {
			activeTabKey,
			formikInitialState,
			pageInitialDataReqStatus,
			pageInitialDataReqError,
			purchaseOrderDetails,
			purchaseOrderStatus,
			confirmedOrderData,
			selectedPurchasOderId
		} = this.state;

		const handleSetQty = this.handleSetQty

		const handleSupplierSchemeChange = this.handleSupplierSchemeChange;

		const handleBaseInfoChange = this.handleBaseInfoChange;

		const handleDeletePOItem = this.handleDeletePOItem;

		const setPOItemList = this.setPOItemList;

		const { dashboardSliceState, purchaseOrdersPageSliceState } = this.props;

		const { currentlySelectedOrgId, currentlySelectedLocationId, storesList } =
			dashboardSliceState;

		const { purchaseOrderCrudRelatedData } = purchaseOrdersPageSliceState;

		const isMainPageActionsRunning =
			pageInitialDataReqStatus === reqStatusTypes.pending;
		const isMainPageActionsError =
			pageInitialDataReqStatus !== reqStatusTypes.pending &&
			pageInitialDataReqError;

		// NOTE : This component handle both ADD  & EDIT. So in here we check in "EDIT" mode existing item data is available. If not we are redirecting.
		// 				Example Scenario - User Directly going to edit URL "/sales/purchaseOrders/edit"
		if (
			purchaseOrderCrudRelatedData.currentCrudType === 'EDIT' &&
			lodash.isEmpty(purchaseOrderCrudRelatedData.selectedItemData)
		) {
			return <Redirect to={routePaths.dashboard__sales__purchaseOrders__search} />;
		}

		if (isMainPageActionsRunning || isMainPageActionsError) {
			return (
				<LoaderView
					className='PurchaseOrderMasterView'
					isLoading={isMainPageActionsRunning}
					isError={isMainPageActionsError}
					typeConfigs={{
						error: {
							extra: (
								<Button
									type='primary'
									onClick={() => {
										this.fetchNecessaryInitialPageData();
									}}>
									TRY AGAIN
								</Button>
							),
						},
					}}
				/>
			);
		}

		if (purchaseOrderStatus === "notExists") {
			return (
				<div>
					<Empty description={<span>Purchase Order <b>{selectedPurchasOderId}</b> not  exists</span>} />
				</div>
			)
		}
		if (purchaseOrderStatus === "pending") {
			return (
				<LoaderView />
			)
		}

		return (
			<div className='PurchaseOrderMasterView'>
				<Formik
					initialStatus={{}}
					initialValues={formikInitialState}
					enableReinitialize
					validationSchema={fullValidationScheme}
					onSubmit={(allFormValuesInEveryTab, formikProps) => {
						this.handlePurchaseOrderAddEditFormSubmitting(
							allFormValuesInEveryTab,
							formikProps,
						);
					}}
					validateOnChange={false} // As a Performance Improvement (But Note that there could be some "Validation Error Showing" caveats due to this.)
				>
					{(formikProps) => {
						const parserFunctions = generateFomikFormValuesObjParserFunctions(
							formikProps.values,
							{},
						);

						const commonPropsForTabCompParts = {
							selectedOrgId: currentlySelectedOrgId,
							selectedLocationId: currentlySelectedLocationId,
							storesList: storesList,
							formikProps,
							parserFunctions,
							purchaseOrderDetails,
							confirmedOrderData,
							selectedPurchasOderId,
							handleSetQty,
							handleSupplierSchemeChange,
							handleBaseInfoChange,
							handleDeletePOItem,
							setPOItemList
						};

						const tabsMetaData = this.getEachTabsMetaData({ formikProps });

						return (
							<div>
								<Tabs
									defaultActiveKey='1'
									type='card'
									size='small'
									tabBarGutter={2}
									animated={{ inkBar: false, tabPane: false }}
									onChange={(activeTabKey) => {
										this.setState({
											activeTabKey,
										});
									}}
									renderTabBar={() => null} // Hiding Tab Selection.
								>
									<TabPane
										tab={
											<div>
												Master
												{tabsMetaData.masterTab.showErrors ? ' *' : ''}
											</div>
										}
										key='1'>
										<ConditionalTabRenderer
											activeTabKey={activeTabKey}
											thisTabKey='1'>
											<PromvMasterTab {...commonPropsForTabCompParts} />

											{/* NOTE : Previously ItemsTab suppose to be on its own tab.
																 But due to UI/UX decision made later this is moved to here to shown along with Main/Master Tab Data.
											*/}
											<PromvItemsTab {...commonPropsForTabCompParts} />
										</ConditionalTabRenderer>
									</TabPane>
								</Tabs>

								<div className='PurchaseOrderMasterView__footer'>
									{formikProps.status.error && (
										<div className='PurchaseOrderMasterView__footer__errorViewer'>
											<Alert
												message={formikProps.status.error}
												type='error'
												showIcon
											/>
										</div>
									)}

									<div className='PurchaseOrderMasterView__footer__buttonList'>
										<Space>
											{/* <Button
											type='primary'
											loading={formikProps.isSubmitting}
											onClick={() => {
												formikProps.handleSubmit();
											}}
											disabled
										// onClick={this.updatePuchaseOrder}
										>
											SAVE DRAFT
										</Button> */}
											{purchaseOrderDetails.Status === 0 ?
												<Popconfirm
													title="Are you sure to update this Purchase Order?"
													onConfirm={this.handleSaveDratf}
													okText="Yes"
													cancelText="No"
													placement="topRight"
												>
													<Button
														type='primary'
													// loading={formikProps.isSubmitting}
													// onClick={this.handleConfirmPO}
													// onClick={this.updatePuchaseOrder}
													>
														SAVE DRAFT
													</Button>
												</Popconfirm>
												: <Button
													type='primary'
													disabled
												>
													Save Draft
												</Button>}

											{purchaseOrderDetails.Status === 0 ?

												<Popconfirm
													title="Are you sure to confirm this Purchase Order?"
													onConfirm={this.handleConfirmPO}
													okText="Yes"
													cancelText="No"
													placement="topRight"
												>
													<Button
														type='primary'
														loading={formikProps.isSubmitting}
													// onClick={this.handleConfirmPO}
													// onClick={this.updatePuchaseOrder}
													>
														CONFIRM
													</Button>
												</Popconfirm>

												: null}

											{purchaseOrderDetails.Status === 0 ?

												<Popconfirm
													title="Are you sure to cancel this Purchase Order?"
													onConfirm={this.handleCancelPO}
													okText="Yes"
													cancelText="No"
													placement="topRight"
												>
													<Button
														loading={formikProps.isSubmitting}
													// onClick={this.handleConfirmPO}
													// onClick={this.updatePuchaseOrder}
													>
														CANCEL
													</Button>
												</Popconfirm>

												: null}

											{/* <Button
											disabled={formikProps.isSubmitting}
											onClick={() => {
												routerHistory.push(
													routePaths.dashboard__sales__purchaseOrders__search,
												);
											}}>
											CANCEL
										</Button> */}
										</Space>
									</div>
								</div>
							</div>
						);
					}}
				</Formik>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	dashboardSliceState: state[dashboardSectionName].dashboard,
	purchaseOrdersPageSliceState: state[dashboardSectionName].purchaseOrderPage,
	userSliceState: state.user
});

const mapDispatchToProps = (dispatch) => {
	const boundDashboardSliceActions = bindActionCreators(
		dashboardSliceActions,
		dispatch,
	);

	const boundPurchaseOrdersPageSliceActions = bindActionCreators(
		purchaseOrdersPageSliceActions,
		dispatch,
	);

	return {
		dashboardSliceActions: boundDashboardSliceActions,
		purchaseOrdersPageSliceActions: boundPurchaseOrdersPageSliceActions,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PurchaseOrderMasterView));
