import React, { useState, useEffect } from 'react';
import {
	Col,
	FileUploader,
	Icon,
	InputBox,
	Label,
	Row,
	Stack,
	Switch,
	Typography,
	Popup,
	Space,
	Button,
	Alert,
	Card,
	StackItem,
	Select,
	ProgressBar,
} from 'witmeg-ui-system';
import { DatePicker } from 'antd';
import Resizer from 'react-image-file-resizer';
import { toBlob } from 'html-to-image';
import globalValues from '../../../../../../configs/globalValues';
// import OfferImagesForm from "./OfferImagesForm";
import { useSelector, useDispatch } from 'react-redux';
// import { sectionName as dashboardSectionName } from '../../../../../redux/slices/dashboard/dashboardConstants';
import { sectionName as dashboardSectionName } from '../../../../../../redux/slices/dashboard/dashboardConstants';
import moment from 'moment';
import { generateToken__WitmegEcoServicesServer } from '../../../../../../utilities/tokenGenerators';
import axios from 'axios';

const { RangePicker } = DatePicker;

// unitTypes={unitTypes}
// handleOnlineImageList={handleOnlineImageList}
// currentOrgName={dashboardState.currentlySelectedLocationName}
// basicInfo={basicInformationIValues ? basicInformationIValues : {}}
// customAttributes={customAttributesObj ? customAttributesObj : {}}
// onlineImages={onlineImagesObj ? onlineImagesObj : {}}
// locationDetails={props?.selectedLocation || null}
// forceResetImages={forceResetImages}

const OfferInfoForm = ({
	handleChangeOfferInfoInputs,
	basicInformationValues,
	basicInfoValidationErrors,
	missingBasicFieldNames,
	enableEditMode,
	LocationID,
	validationMessage,
}) => {
	const [selectedImage, setSelectedImage] = useState(null);
	const [showImageCropPopup, setShowImageCropPopup] =
		useState(false);
	const [processedImage, setProcessedImage] = useState(null);
	const [imageQuality, setImageQuality] = useState(1);
	const [uploadedImageUrl, setUploadedImageUrl] = useState();
	const [progress, setProgress] = useState(0);
	const [dateRange, setDateRange] = useState([]);

	// console.log("dateRange", dateRange);

	const dashboardState = useSelector(
		(state) => state[dashboardSectionName].dashboard
	);
	const dateFormat = 'YYYY/MM/DD';
	// const [defaultFileList, setDefaultFileList] = useState([]);

	// console.log("uploadedImageUrl", uploadedImageUrl);
	// console.log("basicInformationValues", basicInformationValues);

	const handleChangeInput = (e, fieldName) => {
		let updatedBasicInformationValues = {
			...basicInformationValues,
			[fieldName]: e.target.value,
		};

		e.target.value == '' &&
			delete updatedBasicInformationValues[fieldName];

		handleChangeOfferInfoInputs(
			updatedBasicInformationValues,
			fieldName
		);
	};

	const handleChangeSwitch = (e, fieldName) => {
		// console.log('fieldname', e, fieldName);

		let updatedBasicInformationValues = {
			...basicInformationValues,
			[fieldName]: e,
		};

		// !e && delete updatedBasicInformationValues[fieldName]

		handleChangeOfferInfoInputs(
			updatedBasicInformationValues,
			fieldName
		);
	};

	const onDateChange = (val) => {
		val = val ? val : [];
		setDateRange(val);
	};

	useEffect(() => {
		// let imgUrl = uploadedImageUrl? uploadedImageUrl[0] : '';
		let isMounted = false;
		let updatedBasicInformationValues = {
			...basicInformationValues,
			offerImage: uploadedImageUrl,
		};

		!isMounted &&
			handleChangeOfferInfoInputs(
				updatedBasicInformationValues,
				'OfferImage'
			);
		return () => (isMounted = true);
	}, [uploadedImageUrl]);

	useEffect(() => {
		let isMounted = false;
		let updatedBasicInformationValues = {
			...basicInformationValues,
			dateRange: dateRange,
		};

		// dateRange.length == 0 && delete updatedBasicInformationValues.dateRange;

		!isMounted &&
			handleChangeOfferInfoInputs(
				updatedBasicInformationValues,
				'dateRange'
			);
		return () => (isMounted = true);
	}, [dateRange]);

	useEffect(() => {
		let isMounted = false;

		if (enableEditMode) {
			const date = [
				moment(
					basicInformationValues?.dateRange[0],
					dateFormat
				),
				moment(
					basicInformationValues?.dateRange[1],
					dateFormat
				),
			];

			if (!isMounted) {
				setUploadedImageUrl(
					basicInformationValues.offerImage
				);
				// console.log("date", date);
				setDateRange(date);
			}
			// setDateRange([moment(basicInformationValues?.dateRange[0], dateFormat), moment(basicInformationValues?.dateRange[1], dateFormat)])
		} else {
			setDateRange([]);
		}
		return () => (isMounted = true);
	}, []);

	const missingBasicInfoMessage =
		missingBasicFieldNames.length > 0
			? `${missingBasicFieldNames.join(
					', '
			  )} fields are missing in Basic Information.`
			: '';

	// const handleOnlineImageList = (images) => {
	//   let onlineImagesObj = {};
	//   let PrimaryImages = {};
	//   let UnitChartImages = {};
	//   let SpecialImages = {};

	//   images
	//     .filter((ig) => ig.imageType === "PrimaryImages")
	//     .forEach((img, index) => {
	//       PrimaryImages[index === 0 ? "Main" : `Image${index + 1}`] =
	//         img.imageUrl;
	//     });

	//   images
	//     .filter((ig) => ig.imageType === "UnitChartImages")
	//     .forEach((img, index) => {
	//       UnitChartImages[img.key] = img.imageUrl;
	//     });

	//   images
	//     .filter((ig) => ig.imageType === "SpecialImages")
	//     .forEach((img, index) => {
	//       SpecialImages[img.attrName] = [
	//         {
	//           Value: img.attrName,
	//           ImgURL: img.imageUrl,
	//         },
	//       ];
	//     });
	//   onlineImagesObj = {
	//     PrimaryImages: PrimaryImages,
	//     UnitChartImages: UnitChartImages,
	//     SpecialImages: SpecialImages,
	//   };
	//   setOnlineImagesObj(onlineImagesObj);
	//   console.log("onlineImagesObj===>>", onlineImagesObj)
	// };

	const handleProcessImage = async (file) => {
		// console.log("file=====>>>", file);
		setSelectedImage(file);
		setShowImageCropPopup(true);
		let uploadingImage = file.selectedFile;
		const image = await resizeImage(
			uploadingImage,
			500,
			500,
			'JPEG'
		);
		// console.log("image=====>>>", image);
		setProcessedImage(image);
	};

	const resizeImage = async (
		uploadingImage,
		width,
		height,
		fileFormat
	) => {
		return new Promise((resolve) => {
			Resizer.imageFileResizer(
				uploadingImage,
				width,
				height,
				fileFormat,
				100,
				0,
				(uri) => {
					resolve(uri);
				},
				'file'
			);
		});
	};

	const handleGenerareImage = async () => {
		const element = document.getElementById('capture');
		toBlob(element, {
				quality: imageQuality,
				backgroundColor: '#fff',
				width: 500,
				height: 500,
			})
			.then(async function (blob) {
				await uploadImage(blob);
				setShowImageCropPopup(false);
			})
			.catch(function (error) {
				console.error('oops, something went wrong!', error);
			});
	};

	const uploadImage = async (file) => {
		// console.log("file=====>>>", file);
		const fmData = new FormData();
		const timeStamp = Math.round(new Date().getTime() / 1000);
		const environmentFolderName =
			globalValues.environment.CURRENT_ENVIRONMENT ===
			'production'
				? 'production'
				: 'dev';
		const fileName = `P_${basicInformationValues.id}_${timeStamp}`;
		const filePath = `/${environmentFolderName}/${LocationID}/erp/offer/`; //

		fmData.append('file', file);
		fmData.append('fileName', fileName);
		fmData.append('filePath', filePath);
		try {
			const config = {
				headers: {
					'Access-Control-Allow-Origin': '*',
					Authorization: `Bearer ${generateToken__WitmegEcoServicesServer()}`,
				},
			};
			const res = await axios.post(
				// "http://35.228.251.106:14501/v1/image/uploadImage",
				`${globalValues.baseURLS.REACT_APP__WITMEG_IMAGEUPLOADSERVER___BASEURL}/v1/image/uploadImage`,
				fmData,
				config
			);

			// console.log("server res: ", res);
			setUploadedImageUrl(res.data.Result);
			// setUploadedImageUrl([
			//     ...uploadedImageUrl,
			//     {
			//         id: Math.floor(100000 + Math.random() * 900000),
			//         imageUrl: res.data.Result,
			//         imageType: 'PrimaryImages',
			//         key: 'EA',
			//         attrName: null
			//     }
			// ]);
			// props.handleOnlineImageList([
			//     ...uploadedImageUrl,
			//     {
			//         id: Math.floor(100000 + Math.random() * 900000),
			//         imageUrl: res.data.Result,
			//         imageType: 'PrimaryImages',
			//         key: 'EA',
			//         attrName: null
			//     }
			// ])
		} catch (err) {
			console.log('Eroor: ', err);
			const error = new Error('Some error');
			// onError({ err });
		}
	};

	const handleDeleteImage = () => {
		setUploadedImageUrl();
	};

	return (
		<div>
			{validationMessage && (
				<Alert
					message={validationMessage}
					showIcon
					type="error"
				/>
			)}
			<Row className="form-row">
				<Col span={24} className="eco-mb-24">
					<Typography
						type="title"
						level={4}
						pattern="bold"
						className="eco-mt-24"
						alignment="center"
					>
						Offer Information
					</Typography>
				</Col>
			</Row>

			<Row className="form-row">
				<Col span={4}>
					<div className="form-lbl-wrapper">
						<Label required={true}>Offer Name</Label>
					</div>
				</Col>
				<Col span={12}>
					<InputBox
						type="text"
						placeholder="Offer Name"
						onChange={(e) =>
							handleChangeInput(e, 'offerName')
						}
						error={basicInfoValidationErrors.offerName}
						value={basicInformationValues.offerName}
					/>
				</Col>
			</Row>

			<Row className="form-row">
				<Col span={4}>
					<div className="form-lbl-wrapper">
						<Label required={true}>Valid Duration</Label>
					</div>
				</Col>
				<Col span={8}>
					<RangePicker
						// defaultValue={[moment('2015/01/01', dateFormat), moment('2015/01/01', dateFormat)]}
						style={{ width: '100%' }}
						getPopupContainer={(triggerNode) => {
							return triggerNode.parentNode;
						}}
						value={dateRange}
						onChange={(val) => onDateChange(val)}
						// onChange={setDateRange}
						error={basicInfoValidationErrors.dateRange}
					/>
				</Col>
			</Row>

			<Row className="form-row">
				<Col span={4}>
					<div className="form-lbl-wrapper">
						<Label required={true}>Offer Price</Label>
					</div>
				</Col>
				<Col span={6}>
					<InputBox
						type="number"
						placeholder="Offer Price"
						onChange={(e) =>
							handleChangeInput(e, 'offerPrice')
						}
						error={basicInfoValidationErrors.offerPrice}
						value={basicInformationValues.offerPrice}
					/>
				</Col>
			</Row>

			<Row className="form-row">
				<Col span={4}>
					<div className="form-lbl-wrapper">
						<Label required={true}>Offer Quantity</Label>
					</div>
				</Col>
				<Col span={8}>
					<InputBox
						type="number"
						placeholder="Offer Quantity"
						onChange={(e) =>
							handleChangeInput(e, 'offerQuantity')
						}
						error={
							basicInfoValidationErrors.offerQuantity
						}
						value={basicInformationValues.offerQuantity}
					/>
				</Col>
			</Row>

			<Row className="form-row" align="middle">
				<Col span={4}>
					<div className="form-lbl-wrapper">
						<Label>Customer Display</Label>
					</div>
				</Col>
				<Col span={8}>
					<Switch
						ame="customerDisplay"
						onChange={(e) =>
							handleChangeSwitch(e, 'customerDisplay')
						}
						size="small"
					/>
				</Col>
			</Row>

			<Row className="form-row" align="middle">
				<Col span={4}>
					<div className="form-lbl-wrapper">
						<Label>Featured Offer</Label>
					</div>
				</Col>
				<Col span={12}>
					<Stack direction="row" verticalAlign="v_top">
						<StackItem className="eco-mr-12">
							<Switch
								ame="featuredOffer"
								onChange={(e) =>
									handleChangeSwitch(
										e,
										'featuredOffer'
									)
								}
								size="small"
							/>
						</StackItem>
						<StackItem>
							<Stack direction="row">
								<Icon
									name="information"
									color="blue"
									className="eco-mr-4"
								/>
								<Typography
									type="text"
									color="blue_base"
								>
									Message goes here !!!
								</Typography>
							</Stack>
						</StackItem>
					</Stack>
				</Col>
			</Row>

			<Row className="form-row">
				<Col span={8}>
					<div className="form-lbl-wrapper">
						<Label>Offer Image</Label>
					</div>
				</Col>
				<Col span={24}>
					{!uploadedImageUrl && (
						<FileUploader
							multiple={false}
							onUpload={handleProcessImage}
							acceptFiles="image/*"
							maxSize={25}
						/>
					)}

					<div>
						{/* {uploadedImageUrl &&
							<Typography type="title" level={4}>
								Manage Uploads
							</Typography>
						}
						<br /> */}
						{uploadedImageUrl && (
							<Card
								shadow="default"
								disabled={false}
								type={'complex'}
								content={
									<Stack
										className="eco-pa-4"
										direction="row"
										horizontalAlign="space_around"
										verticalAlign="v_center"
									>
										<StackItem
											style={{
												width: '100px',
												height: '100px',
												padding: '8px',
											}}
										>
											<img
												src={uploadedImageUrl}
												alt="Product Images"
												style={{
													width: '100%',
												}}
											/>
										</StackItem>
										<Stack
											width="300"
											horizontalAlign="space_evenly"
										>
											<StackItem
												style={{
													flexGrow: '5',
													padding: '8px',
													justifyContent:
														'center',
													display: 'flex',
													alignItems:
														'center',
													width: '100px',
												}}
											>
												{/* <ProgressBar
												type="circle"
												value={i === 2 - 1 ? progress : 100}
												style={{ marginRight: "10%", width: "10px" }}
												width={20}
											/> */}
											</StackItem>
											<StackItem
												style={{
													height: '100px',
													padding: '8px',
													justifyContent:
														'flex-end',
													display: 'flex',
													alignItems:
														'center',
												}}
											>
												<Button
													category="link"
													onClick={() =>
														handleDeleteImage()
													}
													customStyles={{
														border: 'none',
													}}
												>
													<Icon
														name="trashcan"
														color="red"
													/>
												</Button>
											</StackItem>
										</Stack>
									</Stack>
								}
								width="100%"
								height="fit-content"
							/>
						)}

						{/* <div style={{marginTop:'12px'}}>
                    <Row>
                        <Col span={3}>
                            <div style={{width:'100%', height:'80px', backgroundColor:'#bbb', padding:'8px'}}>
                                Preview
                            </div>
                        </Col>
                        <Col span={15}>
                        
                            <div style={{paddingLeft: '8px'}}>
                            <div>
                                <Checkbox checked={true}>Use As Primary Image</Checkbox>
                            </div>
                            <br />
                            <div>
                                <Select
                                    value={selectedOption}
                                    onChange={setSelectedOption}
                                    options={options}
                                />
                            </div>
                            </div>

                        </Col>
                        <Col span={6}>

                        </Col>
                    </Row>
                </div> */}
					</div>

					{/* <OfferImagesForm 
            unitTypes={unitTypes}
            handleOnlineImageList={handleOnlineImageList}
            currentOrgName={dashboardState.currentlySelectedLocationName}
            basicInfo={basicInformationIValues ? basicInformationIValues : {}}
            customAttributes={customAttributesObj ? customAttributesObj : {}}
            onlineImages={onlineImagesObj ? onlineImagesObj : {}}
            locationDetails = {props?.selectedLocation || null}
            forceResetImages = {forceResetImages}
          /> */}

					<Popup
						modalTitle={<h3>Image Upload</h3>}
						visible={showImageCropPopup}
						onCancel={() => setShowImageCropPopup(false)}
						footer={
							<Space>
								<Button
									onClick={handleGenerareImage}
									variant="primary"
									size="small"
								>
									Done
								</Button>
								<Button
									onClick={() =>
										setShowImageCropPopup(false)
									}
									variant="secondary"
									className="eco-mr-8"
									size="small"
								>
									Cancel
								</Button>
							</Space>
						}
						style={{ height: '800px', width: '800px' }}
						width={550}
					>
						<div>
							<div
								style={{
									width: '504px',
									height: '504px',
									border: '2px solid #666',
								}}
							>
								{processedImage ? (
									<div
										id="capture"
										style={{
											width: '500px',
											height: '500px',
											display: 'flex',
											background: '#fff',
										}}
									>
										<div
											style={{
												display: 'flex',
												verticalAlign:
													'middle',
												justifyContent:
													'center',
												alignItems: 'center',
												margin: 'auto',
											}}
										>
											<div>
												<img
													src={URL.createObjectURL(
														processedImage
													)}
												/>
											</div>
										</div>
									</div>
								) : null}
							</div>
							{/* <hr /> */}
							{/* <div>
								<Row className="form-row">
									<Col md={8}>
										<div className="form-lbl-wrapper">
											<Label>Image Quality</Label>
										</div>
									</Col>
									<Col md={8}>
										<InputBox
											type="number"
											placeholder="Ouality"
											minValue={0.1}
											maxValue={1}
											value={imageQuality}
											onChange={(e) => setImageQuality(e.target.value)}
										/>
									</Col>
								</Row>
							</div> */}
						</div>
					</Popup>
				</Col>
			</Row>
		</div>
	);
};

export default OfferInfoForm;
