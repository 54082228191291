import {
	SearchOutlined as SearchOutlinedIcon,
	SelectOutlined as SelectOutlinedIcon,
} from '@ant-design/icons';
import { Button, Descriptions, Select } from 'antd';
import { HeaderBar } from 'ecologital-ui-library';
import { Form, DatePicker, Input } from 'formik-antd';
import moment from 'moment';
import lodash from 'lodash';
import React, { Component } from 'react';

import CustomModal from '../../../../widgets/CustomModal/CustomModal';
import SupplierSearcher from '../../../SupplierSearcher/SupplierSearcher';
import commonProps from '../../helpers/commonProps';
import { getFormSectionsPartialObjPaths } from '../../helpers/parserHelpers';


import SupplierInputSearcher from './helperComponents/SupplierInputSearcher/SupplierInputSearcher';
import {
	getEposSuppliersAllByOrgId,
} from '../../../../../utilities/apiRequests/witmegWebNeurolageServerRequests';
import './PromvMasterTab.css';

// Default Form.Items Label Size.
const defaultLabelColProps = {
	span: 4,
	style: {
		whiteSpace: 'pre-wrap',
	},
};

// Form.Items Label Size used when Form is divided to two sections.
const dividedSectionLabelColProps = {
	...defaultLabelColProps,
	span: 8,
};

const { basePurchaseOrdernfoObjPath } =
	getFormSectionsPartialObjPaths().masterTab;

class PromvMasterTab extends Component {
	constructor(props) {
		super(props)
		this.state = {
			supplierInfo: {
				Name: '',
				Telephone: '',
				Address: ''
			}
		}
	}

	onChangeText = (event, fieldName) => {
		const suppInfo = { ...this.state.supplierInfo }
		suppInfo[fieldName] = event.target.value
		console.log("suppInfo", suppInfo)
		this.setState({ supplierInfo: suppInfo })
	}



	handleSelectedSupplier = (data, modalProps) => {
		console.log("handleSelectedSupplier", data)
		const { formikProps } = this.props;

		// const {
		// 	selectedRowData, // From CustomList OR CustomInputSearcher Component.
		// 	// modalProps = {}, // From Modal Component.
		// } = data;

		const mergedFormikState = lodash.merge({}, formikProps.values, {
			masterSection: {
				supplierInformation: {
					isSupplierSelected: true,
					supplierId: data.ID,
					supplierName: data.Name,
					rawSupplierData: data,
					address: data.Address
				},
			},
		});

		this.setState({
			supplierInfo: data
		})

		formikProps.setValues(mergedFormikState, true);
		modalProps.toggleModal();

	}

	handleInlineSelectedSupplier = (data) => {
		console.log("handleInlineSelectedSupplier", data)
		const { formikProps, parserFunctions } = this.props;


		const mergedFormikState = lodash.merge({}, formikProps.values, {
			masterSection: {
				supplierInformation: {
					isSupplierSelected: true,
					supplierId: data.ID,
					supplierName: data.Name,
					rawSupplierData: data,
					address: data.Address
				},
			},
		});

		this.setState({
			supplierInfo: data
		})

		formikProps.setValues(mergedFormikState, true);

	}

	componentDidMount = async () => {
		// const { purchaseOrderDetails } = this.props
		this.fetchSupplierInfo()

	}

	componentDidUpdate = async (prevProps, prevState) => {
		// const { purchaseOrderDetails, selectedPurchasOderId } = this.props

		if (this.props.selectedPurchasOderId !== prevProps.selectedPurchasOderId) {
			// console.log("purchaseOrderDetails=====>>>", purchaseOrderDetails)
			this.fetchSupplierInfo()
		}

	}

	fetchSupplierInfo = async () => {
		const commonReqParams = {
			orgId: this.props.selectedOrgId,
			locationId: this.props.selectedLocationId,
			skip: 0,
			limit: 100,
			searchKey: this.props.purchaseOrderDetails.SupplierID,
		};
		let rawSearchResult = await getEposSuppliersAllByOrgId({
			...commonReqParams,
		});
		if (rawSearchResult && rawSearchResult.length > 0) {

			this.setState({
				supplierInfo: rawSearchResult.find(si => si.SupplierID === this.props.purchaseOrderDetails.SupplierID),
			});
		}
	}

	generateHelperFunctionsAndDataToGetNecessaryInfo() {
		const { formikProps, parserFunctions } = this.props;

		const { getFormValueByPath } = parserFunctions;

		// Function to handle supplier selecting from SupplierList.
		function onSupplierSelected(data = {}) {
			const {
				selectedRowData, // From CustomList OR CustomInputSearcher Component.
				modalProps = {}, // From Modal Component.
			} = data;

			const mergedFormikState = lodash.merge({}, formikProps.values, {
				masterSection: {
					supplierInformation: {
						isSupplierSelected: true,
						supplierId: selectedRowData.ID,
						supplierName: selectedRowData.CompanyName,
						rawSupplierData: selectedRowData,
					},
				},
			});

			formikProps.setValues(mergedFormikState, true);

			if (lodash.isFunction(modalProps.toggleModal)) {
				modalProps.toggleModal();
			}
		}

		// Extracting multiple data values, which need to shown in UI.
		// function getDisplayInfoOfSupplierAdditionalDataSet() {
		// 	const rawSupplierData =
		// 		getFormValueByPath(`${supplierInfoObjPath}rawSupplierData`) || {};

		// 	const contactKeysData = rawSupplierData.Contact || {};
		// 	const supplierKeysData = rawSupplierData.Supplier || {};

		// 	return {
		// 		supplierName: rawSupplierData.CompanyName,
		// 		accountNumber: supplierKeysData.AccountNumber,
		// 		postCode: contactKeysData.PostCode,

		// 		balanceOutstanding: supplierKeysData.BalanceOutstanding,
		// 		creditLimit: supplierKeysData.CreditLimit,
		// 		creditPeriod: supplierKeysData.CreditPeriod
		// 			? `${supplierKeysData.CreditPeriod} Days`
		// 			: '',
		// 	};
		// }

		return {
			helperFns: {
				onSupplierSelected,
				// getDisplayInfoOfSupplierAdditionalDataSet,
			},
			helperData: {},
		};
	}

	render() {
		const { selectedOrgId, selectedLocationId, storesList, parserFunctions, purchaseOrderDetails, confirmedOrderData, handleBaseInfoChange, selectedPurchasOderId } =
			this.props;

		const { helperFns } =
			this.generateHelperFunctionsAndDataToGetNecessaryInfo();

		// const { supplierInfo } = this.state;

		// const companyList = [...new Map(storesList.flatMap(({ Companies }) => Companies).map(k => ([k.CompanyName, k]))).values()];

		const locationList = _(storesList)
			.flatMap('Companies')
			.flatMap('LocationIDs')
			.value();

		// console.log("locationList===>>", locationList)
		// const { Address, Name, Telephone } = supplierInfo;

		// const supplierAdditionalDisplayDataSet =
		// 	helperFns.getDisplayInfoOfSupplierAdditionalDataSet();

		return (
			<div className='PromvMasterTab'>
				<Form
					labelCol={{ ...defaultLabelColProps }}
					colon={false}
					labelAlign='left'>
					<div>
						<div className='ProformaMasterView-UTL--gridWrapper'>
							<div className='ProformaMasterView-UTL--gridWrapper___leftColumn'>

								<div className='PromvMasterTab__supplierInfoSection ProformaMasterView-UTL--formSubSection'>
									<HeaderBar subTitle={<b>SUPPLIER SELECTION</b>} />

									{/* Supplier Select Through Modal */}
									<div className='Promvmastertab__po_id_container'>
										<div className="Promvmastertab__po_id_title">
											Purchase Order NO
										</div>
										: {purchaseOrderDetails.PurchaseOrderNo !== "" ? purchaseOrderDetails.PurchaseOrderNo : ''}
									</div>
									<div className='Promvmastertab__po_id_container'>
										<div className="Promvmastertab__po_id_title">
											Purchase Order ID
										</div> : {purchaseOrderDetails.CloudPurchaseOrderID !== "" ? purchaseOrderDetails.CloudPurchaseOrderID : '-'}
									</div>
									<CustomModal
										toggler={
											<Button icon={<SearchOutlinedIcon />} disabled={selectedPurchasOderId}>
												Select Supplier
											</Button>
										}
										togglerWrapperStyles={{
											display: 'flex',
											justifyContent: 'flex-end',
											marginBottom: '15px',
										}}
										modalProps={{
											title: 'SELECT SUPPLIER',
											style: { paddingLeft: '20px', paddingTop: '25px' }, // Making this modal look like nested on parent one.
										}}>
										{(modalProps) => {
											return (
												<SupplierSearcher
													// Adding custom action/button to "Actions Column" of SupplierList.
													// Simply this button is used to get clicked suppliers data, back to here.
													supplierListCustomizationProps={{
														customActionColumnButtonGenerator: (data = {}) => {
															const { selectedRowData } = data;

															return (
																<SelectOutlinedIcon
																	title='Select'
																	onClick={() =>
																		helperFns.onSupplierSelected({
																			selectedRowData,
																			modalProps,
																		})
																	}
																/>
															);
														},
													}}
													handleSelect={(data) => this.handleSelectedSupplier(data, modalProps)}
												/>
											);
										}}
									</CustomModal>

									{/* Supplier Select Through Inline Searching */}
									<div>
										<Form.Item
											name='TEMP'
											label='Supplier'
											labelCol={{
												...defaultLabelColProps,
												sm: { span: 4 },
												xl: { span: 2 },
											}}>
											<SupplierInputSearcher
												selectedOrgId={selectedOrgId}
												selectedLocationId={selectedLocationId}
												helperFns={helperFns}
												parserFunctions={parserFunctions}
												handleSelect={this.handleInlineSelectedSupplier}
												defaultValue={this.state.supplierInfo && this.state.supplierInfo.Name ? this.state.supplierInfo.Name : ''}
												selectedPurchasOderId={selectedPurchasOderId}
											/>

										</Form.Item>
									</div>
								</div>

								<div className='PromvMasterTab__baseProformaInfoSection ProformaMasterView-UTL--formSubSection'>
									<HeaderBar subTitle={<b>BASE INFORMATION</b>} />

									<div className='PromvMasterTab__baseProformaInfoSection__inputsWrapper'>


										<Form.Item
											name={`${basePurchaseOrdernfoObjPath}contactNo`}
											label='Contact No'
											labelCol={{ ...dividedSectionLabelColProps }}>
											<Input
												name={`${basePurchaseOrdernfoObjPath}contactNo`}
												style={{ width: '100%' }}
												type='tel'
												allowClear={false}
												// defaultValue={this.state.supplierInfo.Telephone}
												onChange={(e) => {
													this.onChangeText(e, 'Telephone')
													handleBaseInfoChange(e.target.value, 'Telephone')
												}}
												value={this.state.supplierInfo && this.state.supplierInfo.Telephone ? this.state.supplierInfo.Telephone : ''}
												disabled={selectedPurchasOderId}
											/>
										</Form.Item>

										<Form.Item
											name={`${basePurchaseOrdernfoObjPath}storeLocation`}
											label='Store Location'
											labelCol={{ ...dividedSectionLabelColProps }}>
											<Select
												name={`${basePurchaseOrdernfoObjPath}storeLocation`}
												options={
													locationList.map(loc => {
														return {
															label: loc.LocationName,
															value: loc.LocationID
														}
													})
												}
												defaultValue={purchaseOrderDetails.CloudLocationID}
												value={purchaseOrderDetails.CloudLocationID}
												onChange={(val) => handleBaseInfoChange(val, 'CloudLocationID')}
											/>
										</Form.Item>

										<Form.Item
											name={`${basePurchaseOrdernfoObjPath}address`}
											label='Address'
											labelCol={{ ...dividedSectionLabelColProps }}>
											<Input
												name={`${basePurchaseOrdernfoObjPath}address`}
												style={{ width: '100%' }}
												type='text'
												allowClear={false}
												// defaultValue={this.state.supplierInfo.Address}
												onChange={(e) => {
													this.onChangeText(e, 'Address');
													handleBaseInfoChange(e.target.value, 'Address')
												}}
												value={this.state.supplierInfo && this.state.supplierInfo.Address ? this.state.supplierInfo.Address : ''}
												disabled={selectedPurchasOderId}
											/>
										</Form.Item>

										<Form.Item
											name={`${basePurchaseOrdernfoObjPath}orderDate`}
											label='Order Date'
											labelCol={{ ...dividedSectionLabelColProps }}>

											<DatePicker
												name={`${basePurchaseOrdernfoObjPath}orderDate`}
												style={{ width: '100%' }}
												allowClear={false}
												defaultValue={moment(purchaseOrderDetails.OrderDate)}
												onChange={(val) => handleBaseInfoChange(val.format(), 'OrderDate')}
											/>
										</Form.Item>

										<Form.Item
											name={`${basePurchaseOrdernfoObjPath}salesPerson`}
											label='Sales Person'
											labelCol={{ ...dividedSectionLabelColProps }}>
											<Input
												name={`${basePurchaseOrdernfoObjPath}salesPerson`}
												style={{ width: '100%' }}
												type='text'
												allowClear={false}
												onChange={(e) => this.onChangeText(e, 'Name')}
												value={this.state.supplierInfo && this.state.supplierInfo.Name ? this.state.supplierInfo.Name : ''}
												disabled={selectedPurchasOderId}
											/>
										</Form.Item>

										<Form.Item
											name={`${basePurchaseOrdernfoObjPath}deliveryDate`}
											label='Delivery Date'
											labelCol={{ ...dividedSectionLabelColProps }}>
											<DatePicker
												name={`${basePurchaseOrdernfoObjPath}deliveryDate`}
												style={{ width: '100%' }}
												allowClear={false}
												defaultValue={moment(purchaseOrderDetails.DeliveryDate)}
												onChange={(val) => handleBaseInfoChange(val.format(), 'DeliveryDate')}
											/>
										</Form.Item>
										{purchaseOrderDetails.IsExternalIntegration ?
											<Form.Item
												name={`${basePurchaseOrdernfoObjPath}deliveryDate`}
												label='Supplier Scheme Type'
												labelCol={{ ...dividedSectionLabelColProps }}>
												{/* <Select>
												{purchaseOrderDetails.SupplierSchemeType && purchaseOrderDetails.SupplierSchemeType.map((ss, i) => {
													<Select.Option value={ss} key={`ss_key_${i}`}>{ss}</Select.Option>
												})}
											</Select> */}

												<Select
													name={`${basePurchaseOrdernfoObjPath}SupplierSchemeType`}
													options={
														purchaseOrderDetails.SupplierSchemeType && purchaseOrderDetails.SupplierSchemeType.map((ss) => {
															return {
																label: ss,
																value: ss
															}
														})}
													onChange={(val) => handleBaseInfoChange(val, 'SupplierScheme')}
													defaultValue={purchaseOrderDetails.SupplierScheme}
													value={purchaseOrderDetails.SupplierScheme}
												/>
											</Form.Item>
											: null}
									</div>
								</div>
							</div>

							<div className='ProformaMasterView-UTL--gridWrapper___rightColumn'>
								{/* Additional Data about Selected Supplier */}
								<div>
									<div className='PromvMasterTab__supplierAdditionalInfoSection'>
										<HeaderBar subTitle={<b>SUPPLIER INFO</b>} />

										<Descriptions
											bordered
											size='small'
											column={1}
											layout='horizontal'
											{...commonProps.additionalDetails__descriptionsCompCommonProps}>
											<Descriptions.Item label='Supplier No'>
												-
											</Descriptions.Item>
											<Descriptions.Item label='Drafted PO'>
												-
											</Descriptions.Item>
											<Descriptions.Item label='Confirmed PO'>
												-
											</Descriptions.Item>
											<div style={{ height: '22px' }} />
											<Descriptions.Item label='Confirmed PO Total'>
												-
											</Descriptions.Item>
											<Descriptions.Item label='Due Amount'>
												-
											</Descriptions.Item>
											<Descriptions.Item label='Currency'>
												-
											</Descriptions.Item>
											<Descriptions.Item label='Last Paid Date'>
												-
											</Descriptions.Item>

										</Descriptions>
									</div>
									<br />
									{confirmedOrderData.Action && confirmedOrderData.Action === "Open new tab" ?
										<div className="Promvmastertab__confirmed_status">
											<h3>Pleace click on the follwing link to view confirmed order status</h3>
											<a href={confirmedOrderData.URL} target="_blank">Click Here</a>
										</div>
										: null}
								</div>
							</div>
						</div>
					</div>
				</Form>
			</div>
		);
	}
}

export default PromvMasterTab;
