import React, { useState, useEffect } from 'react';
import { Table } from 'ecologital-ui-library';
import { Input, InputNumber, Form, Space, Button } from 'antd';

import MaterialDesignIcon from '../../../common/MaterialDesignIcon';
import _, { indexOf } from 'lodash';


const BarCodeScanResults = (props) => {

    const selectionType = 'checkbox';
    const [editingKey, setEditingKey] = useState('');
    const [products, setProducts] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [form] = Form.useForm();


    const isEditing = (record) => record.key === editingKey;
    const edit = (record) => {
        console.log(`record`, record)
        form.setFieldsValue({
            ProductCode: '',
            ...record,
        });
        setEditingKey(record.key);
    };

    useEffect(() => {
        let selectedList = products.map((p, i) => props.selectedItems.findIndex(si => si.ID===p.ID)>-1?i:null).filter(x => x!==null)

        setSelectedRowKeys(selectedList)
    }, [props.selectedItems])

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (rec) => {
        try {
            const row = await form.validateFields();
            let newData = [...products];
            const index = newData.findIndex((item) => rec.ID === item.ID);
            let selectedKeys = [...new Set([...selectedRowKeys, index])]
            setSelectedRowKeys(selectedKeys)

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setProducts(newData);
                props.handleSetSelectedItems(newData.filter((nd, k) => selectedKeys.find(l => l === k)))
            }
            setEditingKey('');
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    useEffect(() => {
        setProducts(props.productList)
    }, [props.productList]);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            props.handleSetSelectedItems(selectedRows)
            setSelectedRowKeys(selectedRowKeys)
        },
    };

    let reqCols = props.columnOrder.map((p, i) => {
        return {
            title: p.ColumnHeader,
            dataIndex: p.ColumnName,
            key: p.ColumnName,
        }
    });

    console.log("reqCols", reqCols);

    const displayCols = reqCols;

    const columns = [
        ...displayCols,
        {
            title: '',
            key: 'operation',
            fixed: 'right',
            width: 60,
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <Space direction='horizontal'>
                        <Button type="text" icon={<MaterialDesignIcon icon='visibility' />} />
                        <Button type="link" icon={<MaterialDesignIcon icon='done' />} onClick={() => save(record)} />
                        <Button type="link" danger icon={<MaterialDesignIcon icon='close' />} onClick={() => cancel()} />
                        {/* <MaterialDesignIcon icon='save' />
					<MaterialDesignIcon icon='cancel' /> */}
                    </Space>
                ) : (<Space direction='horizontal'>
                    <Button type="text" icon={<MaterialDesignIcon icon='visibility' />} />
                    <Button type="link" icon={<MaterialDesignIcon icon='edit' />} onClick={() => edit(record)} disabled={props.viewType !== "products"} />
                    {/* <MaterialDesignIcon icon='save' />
				<MaterialDesignIcon icon='cancel' /> */}
                </Space>)
            }
        }
    ]




    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <div>
            {products ? (
                <Form form={form} component={false}>
                    <Table
                        rowSelection={{
                            selectedRowKeys,
                            type: selectionType,
                            ...rowSelection,
                        }}
                        columns={mergedColumns}
                        dataSource={[
                            ...(products.map((p, i) => {
                                return { key: i, ...p };
                            })),
                        ]}
                        // rowClassName="editable-row"
                        loading={props.loading}
                        size="small"
                        {...props.tableCompProps}
                    />
                </Form>
            ) : null}
        </div>
    );
}

export default BarCodeScanResults;
