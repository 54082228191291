import React from 'react';
import { Input, Row, Col, Form } from 'antd'
const PurchaseOrderTotals = (props) => {
    const { currentItems } = props
    const noOfItems = currentItems && currentItems.length;
    const noOfQty = currentItems && currentItems.reduce((sum, cur) => sum + cur.OrderQuantity, 0);
    // const totalCost = currentItems && currentItems.reduce((sum, cur) => sum + cur.PurchasingPrice * cur.OrderQuantity, 0);
    const totalCost = currentItems && currentItems.reduce((sum, cur) => sum + Number(cur.lineTotal), 0);
    const totalVat = currentItems && currentItems.reduce((sum, cur) => sum + cur.LineVat, 0);
    // const netTotal = currentItems && currentItems.reduce((sum, cur) => sum + cur.SubTotal, 0);
    return (
        <div style={{ padding: '6px' }}>
            <Row>
                <Col span={12}>&nbsp;</Col>
                <Col span={12}>
                    <div>
                        <Form.Item
                            // name={`purchaseOrderTotals_noOfItems`}
                            label='No Of Items'
                            labelCol={{ span: '8' }}>
                            <Input
                                // name={`purchaseOrderTotals_noOfItems`}
                                style={{ width: '100%' }}
                                type='text'
                                value={noOfItems}
                            />
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item
                            // name={`purchaseOrderTotals_noOfItems`}
                            label='No Of Qty '
                            labelCol={{ span: '8' }}>
                            <Input
                                // name={`purchaseOrderTotals_noOfItems`}
                                style={{ width: '100%' }}
                                type='text'
                                value={noOfQty}
                            />
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item
                            // name={`purchaseOrderTotals_totalCost`}
                            label='Total Cost'
                            labelCol={{ span: '8' }}>
                            <Input
                                // name={`purchaseOrderTotals_totalCost`}
                                style={{ width: '100%' }}
                                type='text'
                                value={Number(totalCost).toFixed(2)}
                            />
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item
                            label='Total Vat'
                            labelCol={{ span: '8' }}>
                            <Input
                                style={{ width: '100%' }}
                                type='text'
                                value={Number(totalVat).toFixed(2)}
                            />
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item
                            label={<b>Net Total</b>}
                            labelCol={{ span: '8' }}>
                            <Input
                                style={{ width: '100%' }}
                                type='text'
                                allowClear={false}
                                value={Number(totalCost + totalVat).toFixed(2)}
                            />
                        </Form.Item>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default PurchaseOrderTotals;
