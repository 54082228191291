import {
	DeleteOutlined as DeleteOutlinedIcon,
	EditOutlined as EditOutlinedIcon,
} from '@ant-design/icons';
import { Empty, Popover, InputNumber, Button } from 'antd';
import React, { Component, useRef, useState } from 'react';

import FieldErrorMessage from '../../../../../../widgets/formRelated/FieldErrorMessage/FieldErrorMessage';
import ProductList from '../../../../../ProductList/ProductList';
import { getFormSectionsPartialObjPaths } from '../../../../helpers/parserHelpers';

import './ProductItemList.css';

const { addedItemsInfoObjPath } = getFormSectionsPartialObjPaths().itemsTab;

export default class ProductItemList extends Component {
	render() {
		const {
			selectedOrgId,
			selectedLocationId,
			formikProps,
			helperFns,
			parserFunctions,
		} = this.props;

		const { getFormValueByPath } = parserFunctions;

		return (
			<div className='ProductItemList'>
				<ProductList
					selectedOrgId={selectedOrgId}
					selectedLocationId={selectedLocationId}
					rawProductList={getFormValueByPath(
						`${addedItemsInfoObjPath}addedItemsList`,
					)}
					tableCompProps={{
						size: 'small',
						locale: {
							emptyText: (
								<Empty
									image={Empty.PRESENTED_IMAGE_SIMPLE}
									description='No Products Added Yet.'
								/>
							),
						},
						pagination: false,
						onRow: (selectedRowData, rowIndex) => {
							// On Each Row hover invoking handlers to show additional detail in "Items Details" Section.
							return {
								onMouseOver: () => {
									helperFns.onProductItemHoverIn({
										selectedRowData,
										rowIndex,
									});
								},
								onMouseOut: () => {
									helperFns.onProductItemHoverOut({
										selectedRowData,
										rowIndex,
									});
								},
							};
						},
					}}
					customizationProps={{
						customRawColumnListGenerator: (data = {}) => {
							return {
								ItemCode: {
									ColumnName: 'Item No',
									ValuePath: 'ItemCode',
									IsVisible: true,
									IsFilter: true,
								},
								ProductName: {
									ColumnName: 'Name',
									IsFilter: true,
									IsVisible: true,
									ValuePath: 'ProductName',
								},
								UnitType: {
									ColumnName: 'Unit Type',
									IsFilter: true,
									IsVisible: true,
									ValuePath: 'UnitType',
								},
								Qty: {
									ColumnName: 'Qty',
									IsFilter: true,
									IsVisible: true,
									ValuePath: '___manuallyAddedData.purchaseQty',
								},
								LineVAT: {
									ColumnName: 'Line VAT',
									IsFilter: true,
									IsVisible: true,
									ValuePath: 'ProductVatRate',
								},
								LineAmount: {
									ColumnName: 'Line Amount',
									IsFilter: true,
									IsVisible: true,
									ValuePath: 'SellingPrice',
								},
							};
						},
						customSpecialRootKeyListGenerator: (data = {}) => {
							return [];
						},
						customColumnSortOrderKeysGenerator: (data = {}) => {
							return [];
						},
						customActionColumnButtonGenerator: (data = {}) => {
							const { selectedRowData, rowIndex } = data;
							const { AvailableStock: availableStock } = selectedRowData;

							const inputRef = useRef(null);
							const [popupVisible, setPopupVisible] = useState(false);

							return (
								<div style={{ display: 'flex', gap: '5px' }}>
									<Popover
										content={() => {
											return (
												<div>
													<span>
														<span>QTY : </span>
														<InputNumber
															min={1}
															max={availableStock}
															ref={inputRef}
															defaultValue={
																selectedRowData.___manuallyAddedData
																	? selectedRowData.___manuallyAddedData
																			.purchaseQty
																	: 0
															}
														/>
													</span>

													<Button
														onClick={() => {
															const purchaseQty = inputRef.current.value;

															if (purchaseQty && purchaseQty >= 0) {
																helperFns.onProductEditing({
																	rowIndex,
																	selectedRowData: {
																		...selectedRowData,
																		___manuallyAddedData: {
																			purchaseQty,
																		},
																	},
																});
															}

															setPopupVisible(false);
														}}>
														UPDATE
													</Button>

													<Button
														onClick={() => {
															setPopupVisible(false);
														}}>
														CANCEL
													</Button>
												</div>
											);
										}}
										title=''
										trigger='click'
										onVisibleChange={(visibleStateAccordingToTriggerProp) => {
											// NOTE : In "onVisibleChange" prop, what we receive is Popup state, that should be according "trigger" prop. (This doesn't care that we are using our own state to handle popup visibility by passing "visible" prop.)
											// 				Anyway, We are using this to our advantage by using this to close popup when clicked on outside of Popover.

											if (visibleStateAccordingToTriggerProp === false) {
												setPopupVisible(false);
											}
										}}
										visible={popupVisible}
										overlayInnerStyle={{
											border: '1px solid #0c0c0c21',
										}}
										destroyTooltipOnHide>
										<EditOutlinedIcon
											title='Edit'
											onClick={() => setPopupVisible(true)}
										/>
									</Popover>

									<DeleteOutlinedIcon
										title='Remove'
										onClick={() => {
											helperFns.onProductRemoving({
												selectedRowData,
												rowIndex,
											});
										}}
									/>
								</div>
							);
						},
					}}
				/>

				<FieldErrorMessage
					name={`${addedItemsInfoObjPath}addedItemsList`}
					style={{
						textAlign: 'right',
					}}
				/>
			</div>
		);
	}
}
