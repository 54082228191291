/* eslint-disable no-shadow */

import { EditOutlined as EditIcon } from '@ant-design/icons';
import { bindActionCreators } from '@reduxjs/toolkit';
import { Button } from 'witmeg-ui-system';
import lodash from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { sectionName as dashboardSectionName } from '../../../redux/slices/dashboard/dashboardConstants';
import { actions as inventoryProductsPageSliceActions } from '../../../redux/slices/dashboard/inventoryProductsPageSlice/inventoryProductsPageSlice';

import { generateProductDataObjParserFunctions } from './helpers/parserHelpers';
import ProOveGeneralSection from './subComponents/ProOveGeneralSection/ProOveGeneralSection';
import ProOveImagesSection from './subComponents/ProOveImagesSection/ProOveImagesSection';
import ProOvePricingSection from './subComponents/ProOvePricingSection/ProOvePricingSection';
import ProOveSalesAndStockSection from './subComponents/ProOveSalesAndStockSection/ProOveSalesAndStockSection';
import ProOveSuppliersSection from './subComponents/ProOveSuppliersSection/ProOveSuppliersSection';
import ProOveTimelineSection from './subComponents/ProOveTimelineSection/ProOveTimelineSection';

import './ProductOverview.css';

class ProductOverview extends Component {
	// Parsing/Formatting/Organizing data that needed to shown in ProductOverview.
	getNecessaryDataToDisplay() {
		const {
			productData = {}, // NOTE : This function assume, This is a specific "Individual" product data portion received from relevant API Endpoint. (Ex. "/v1/epos/product/barcode" Endpoint.)
			selectedOrgId = '',
			selectedLocationId = '',
		} = this.props;

		// TEMPORARY : Temp check to avoid errors when "productData" is not provided. (Ex. Errors occurred when accessing unavailable nested object path.)
		if (lodash.isEmpty(productData)) {
			return {};
		}

		const {
			ID,
			Name,
			UnitChart = {},
			CloudProductCategory = {},
			Offers = [],
			// OnLineImages = {},
		} = productData;

		// Getting set of helper functions to parse data.
		const productDetailsParserFns = generateProductDataObjParserFunctions({
			productDataObj: productData,
			selectedOrgId,
			selectedLocationId,
		});

		const {
			getSellingPriceDataSet,
			getCostPriceDataSet,
			getInventoryDataSet,

			getSellingUnitType,
			getCostUnitType,
			getInventoryUnitType,

			generateImagesRelatedHelperFunctions,
		} = productDetailsParserFns;

		const inventoryData = getInventoryDataSet();

		// *********************** Specific helper functions to get relevant data. ***********************

		const getProductMainCategoryName = () => {
			return CloudProductCategory.ProductCategory.Category;
		};

		const getProductSubCategoryName = () => {
			return CloudProductCategory.ProductSubCategory.Name;
		};

		const getSellingPrice = () => {
			return getSellingPriceDataSet().CloudSellingprice;
		};

		const getCostPrice = () => {
			return getCostPriceDataSet().CloudCostprice;
		};

		const getProfitMargin = () => {
			const sellingPriceDataSet = getSellingPriceDataSet();
			const costPriceDataSet = getCostPriceDataSet();

			const sellingPrice = getSellingPrice();
			const costPrice = getCostPrice();

			const isSellingPriceByBaseUnit = !sellingPriceDataSet.CloudUnitChartID;
			const isCostPriceByBaseUnit = !costPriceDataSet.CloudUnitChartID;

			const sellingPriceAsUnit = isSellingPriceByBaseUnit
				? sellingPrice
				: sellingPrice / UnitChart.Quantity;

			const costPriceAsUnit = isCostPriceByBaseUnit
				? costPrice
				: costPrice / UnitChart.Quantity;

			const profit = sellingPriceAsUnit - costPriceAsUnit;

			const profitMarginAsPercentage = (profit / costPriceAsUnit) * 100;

			return `${profitMarginAsPercentage.toFixed(0)}%`;
		};

		const generateTitle = () => {
			return Name;
		};

		const getActiveOffers = () => {
			const offerData = Offers;

			return offerData.map((offer) => {
				return offer.Offer;
			});
		};

		const getProductImagesInEveryCategory = () => {
			const {
				getPrimaryImageListAsArray,
				getSpecialImageListAsArray,
				getUnitChartImageListAsArray,
			} = generateImagesRelatedHelperFunctions();

			const primaryImageList = getPrimaryImageListAsArray() || [];
			const unitChartImageList = getUnitChartImageListAsArray() || [];
			const specialImageList = getSpecialImageListAsArray() || [];

			// SIDE NOTE : These formatting should be SOMEWHAT match with formatting's and vice versa in "ProductMasterView/helpers/parserHelpers.js ----> getAllImagesInEveryCategory()"

			const formattedPrimaryImageList = primaryImageList
				.map((img) => {
					return {
						...img,
						imgCategory: 'Primary Images',
						isMainImage: img.imgKeyTitle === 'Main',
					};
				})
				.sort((img) => {
					return img.imgKeyTitle === 'Main' ? -1 : 0;
				});

			const formattedUnitChartImageList = unitChartImageList.map((img) => {
				return {
					...img,
					imgCategory: 'UnitChart Images',
					isMainImage: false,
				};
			});

			const formattedSpecialImageList = specialImageList.map((img) => {
				return {
					...img,
					imgCategory: 'Special Images',
					isMainImage: false,
				};
			});

			return [].concat(
				formattedPrimaryImageList,
				formattedUnitChartImageList,
				formattedSpecialImageList,
			);
		};

		// eslint-disable-next-line consistent-return
		return {
			productId: ID,

			title: generateTitle(),
			productMainCategoryName: getProductMainCategoryName(),
			productSubCategoryName: getProductSubCategoryName(),

			costPrice: getCostPrice(),
			sellingPrice: getSellingPrice(),
			sellingMargin: getProfitMargin(),

			stockQuantityWithUnitTypeSuffixed: inventoryData.Stock,
			stockQuantityAsEA: inventoryData.Quantity,
			numOfItemsInABox: UnitChart.Quantity,

			sellingUnitType: getSellingUnitType(),
			costUnitType: getCostUnitType(),
			inventoryUnitType: getInventoryUnitType(),

			activeOffers: getActiveOffers(),
			productImageList: getProductImagesInEveryCategory(),
		};
	}

	render() {
		const {
			selectedLocationId = '',
			toggleOverviewModal,
			defaultCurrencyDetails,
		} = this.props;

		const { inventoryProductsPageSliceActions } = this.props;
		const { toggleProductAddEditModal } = inventoryProductsPageSliceActions;

		const overviewData = this.getNecessaryDataToDisplay() || {};

		// TEMPORARY : Temp check to avoid errors when "overviewData" is not provided. (Ex. Errors occurred when accessing unavailable nested object path.)
		if (lodash.isEmpty(overviewData)) {
			return null;
		}

		return (
			<div className='ProductOverview'>
				<div className='ProductOverview____headerWrapper'>
					<div className='ProductOverview__title'>
						<h2>{overviewData.title}</h2>
					</div>

					<div>
						<Button
							title='Edit Product'
							icon={<EditIcon />}
							size='medium'
							onClick={() => {
								toggleOverviewModal(); // Closing Overview Modal.

								// Opening ProductAddEdit Modal
								toggleProductAddEditModal({
									type: 'EDIT',
									additionalInfo: {
										productId: overviewData.productId,
									},
								});
							}}
						/>
					</div>
				</div>

				<div className='ProductOverview____bodyWrapper'>
					<ProOveGeneralSection overviewData={overviewData} />
					<div style={{ display: 'flex', flexWrap: 'wrap' }}>
						<div style={{ marginRight: '15px', flex: 1 }}>
							<ProOvePricingSection overviewData={overviewData} />
						</div>
						<div style={{ flex: 1 }}>
							<ProOveSalesAndStockSection overviewData={overviewData} />
						</div>
					</div>
					<ProOveSuppliersSection
						overviewData={overviewData}
						defaultCurrencyDetails={defaultCurrencyDetails}
					/>
					<ProOveImagesSection overviewData={overviewData} />
					<ProOveTimelineSection
						overviewData={overviewData}
						selectedLocationId={selectedLocationId}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	inventoryProductsPageSliceState:
		state[dashboardSectionName].inventoryProductsPage,
	defaultCurrencyDetails:
		state.settings.currencySettings &&
		state.settings.currencySettings.DefaultCurrency,
});

const mapDispatchToProps = (dispatch) => {
	const boundInventoryProductsPageSliceActions = bindActionCreators(
		inventoryProductsPageSliceActions,
		dispatch,
	);

	return {
		inventoryProductsPageSliceActions: boundInventoryProductsPageSliceActions,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductOverview);
