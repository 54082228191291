import React, { useState, useEffect } from 'react';
import {
	Button,
	InputBox,
	Table,
	AutoSuggest,
	Alert,
	Row,
	async,
} from 'witmeg-ui-system';

import { searchProducts } from '../../../../../../utilities/apiRequests/witmegWebNeurolageServerRequests';
import { useSelector } from 'react-redux';
import { imagePlaceholder } from '../../../../../../configs/constants';

const AddItemsForm = (props) => {
	const {
		basicInformationValues,
		handleChangeOfferInfoInputs,
		enableProductEditMode,
		setEnableProductEditMode,
		enableEditMode,
		offerItemsValues,
		handleOfferItemsInputs,
		missingOfferFieldNames,
		validations,
		apiValidationError,
		validationMessage,
	} = props;
	const [rowNumber, setRowNumber] = useState(0);
	const [count, setCount] = useState(0);
	const [updatedData, setUpdatedData] = useState();
	const [inputBarcode, setInputBarcode] = useState();
	const [dataSet, setDataSet] = useState([]);
	const suggestionTableColumns = [
		{ label: 'Barcode', key: 'BarCode' },
		{ label: 'Item Name', key: 'ProductName' },
		{ label: 'Item Code', key: 'Itemcode' },
	];
	const dashboardState = useSelector((state) => state.dashboard);
	const [dashboardSliceState, setDashboardSliceState] = useState();
	const [tableData, setTableData] = useState([]);
	const defaultAdvancedSearchFilters = {
		IsSearchByCreatedDate: true,
		CreatedDate: {
			Start: '2021-01-01T00:00:00.000+00:00',
			End: '2022-11-01T00:00:00.000+00:00',
		},
		IsSearchBySellingPrice: false,
		SellingPrice: {
			IsLowerThan: false,
			IsGreaterThan: false,
			Amount: 0,
			IsInBetween: true,
			InBetween: {
				MinPrice: 3,
				MaxPrice: 15,
			},
		},
		IsSearchByPurchasePrice: false,
		PurchasePrice: {
			IsLowerThan: false,
			IsGreaterThan: false,
			Amount: 0,
			IsInBetween: true,
			InBetween: {
				MinPrice: 4,
				MaxPrice: 10,
			},
		},
		IsSearchByStock: false,
		Stock: {
			IsLowerThan: false,
			IsGreaterThan: false,
			Amount: 10,
			IsInBetween: true,
			InBetween: {
				MinStock: 10,
				MaxStock: 1000,
			},
		},
	};

	const [advancedSearchFilters, setAdvancedSearchFilters] =
		useState(defaultAdvancedSearchFilters);

	console.log('basicInformationValues', basicInformationValues);

	useEffect(() => {
		let isMounted = false;

		const handleOfferValues = async () => {
			if (offerItemsValues.length > 0) {
				await setTableData(offerItemsValues);
			} else {
				setTableData([]);
			}
		};
		!isMounted && handleOfferValues();

		const loadExsitingData = async () => {
			const data = await getFormattedDataFromResult(
				basicInformationValues.OfferProducts
			);
			// console.log('existing data', data);
			setDataSet(data);
		};

		if (
			enableEditMode &&
			basicInformationValues.OfferProducts.length > 0
		) {
			!isMounted && loadExsitingData();
		}

		return () => (isMounted = true);
	}, []);

	useEffect(() => {
		let isMounted = false;
		setDashboardSliceState(dashboardState);
		// console.log("dashboardSliceState",dashboardSliceState.dashboard.currentlySelectedOrgId);
		const getSearchProducts = async () => {
			// debugger
			const result = await searchProducts(
				{
					orgId: dashboardSliceState.dashboard
						.currentlySelectedOrgId,
					locationId:
						dashboardSliceState.dashboard
							.currentlySelectedLocationId,
					// searchBy: "Barcode",
					searchKey: inputBarcode,
					// isAdvancedSearch: false,
					// advacedSearchParams: { ...advancedSearchFilters }
				},
				{
					'Access-Control-Allow-Origin': '*',
				}
			);
			const data = await getFormattedDataFromResult(result);
			setDataSet(data);
			// console.log('search data', data);
		};
		!isMounted && getSearchProducts();
		// console.log('dashboardSliceState', dashboardSliceState);
		return () => (isMounted = true);
	}, [inputBarcode, dashboardState]);

	const getFormattedDataFromResult = async (searchResult) => {
		// console.log('SEARCH RESULTS', searchResult);
		return await searchResult.map((product, index) => {
			return {
				// key: `product_${index}`,
				BarCode: product.BarCode,
				ProductName: product.Name,
				Itemcode: product.ProductRefNo,
				// ProductID: product.ID,
				// NoOfChoices: 0,
				// IsDeleted: false,
				// OrderIndex: 0,
				// ImageURL: product.OnLineImages?.PrimaryImages?.Main ? product.OnLineImages.PrimaryImages.Main : '',
				// UnitPrice: product.UnitPrice,
				// IsDeleted: 0,
				OrgID: dashboardSliceState.dashboard
					.currentlySelectedOrgId,
				CloudProductID: product.CloudProductID,
				Quantity: 1,
				ImageURL: product.OnLineImages.PrimaryImages?.Main
					? product.OnLineImages.PrimaryImages.Main
					: imagePlaceholder,
				Price: product.SellingPricesArray?.[0]['Sellingprice']
					? product.SellingPricesArray[0]['Sellingprice']
					: 0,
			};
		});
	};

	const addItems = () => {
		const newData = {
			key: tableData.length - 1,
			id: tableData.length - 1,
			BarCode: '',
			ProductName: '',
			Itemcode: '',
		};
		setTableData([...tableData, newData]);
		setCount(tableData.length - 1);
		// setCount(count + 1);
	};

	const getData = (data) => {
		setTableData(data);
	};

	useEffect(() => {
		let isMounted = false;

		if (updatedData) {
			if (!isMounted) {
				const dataTemp = [...tableData];
				dataTemp[tableData.length - 1] = updatedData;
				getData(dataTemp);
			}
		}
		return () => (isMounted = true);
	}, [updatedData]);

	const setUpdatedTableData = async (row, data) => {
		setUpdatedData(data);
	};

	const inputChangeInEditable = (value) => {
		setInputBarcode(value);
	};

	const deleteItem = (index, item, id) => {
		let copyOfTD = [...tableData];
		copyOfTD.splice(id, 1);
		setTableData(copyOfTD);
	};

	useEffect(() => {
		// console.log('handleOfferItemsInputs', tableData);
		let isMounted = false;

		!isMounted &&
			handleOfferItemsInputs(
				{ ...basicInformationValues, itemsAdd: tableData },
				'itemsAdd'
			);
		return () => (isMounted = true);
	}, [tableData]);

	const addItemsColumns = [
		{
			id: 'Barcode - 1',
			title: 'Barcode',
			dataIndex: 'BarCode',
			key: 'BarCode',
			render: (text, record, index) => (
				<div style={{ width: '150px' }}>
					<AutoSuggest
						id="Barcode-1"
						type="text"
						rowNumber={index}
						setTableData={setUpdatedTableData}
						inputValue={record?.BarCode}
						columnsData={suggestionTableColumns}
						dataSet={dataSet}
						searchKey="BarCode"
						inputChangeInEditable={inputChangeInEditable}
					/>
				</div>
			),
		},
		{
			title: 'Item Name',
			dataIndex: 'ProductName',
			key: 'ProductName',
		},
		{
			title: 'Item Code',
			dataIndex: 'Itemcode',
			key: 'Itemcode',
		},
		{
			title: 'Action',
			dataIndex: 'action',
			key: 'action',
			render: (_, record, index) => {
				return (
					<Button
						category="icon"
						variant="link"
						children="delete"
						onClick={() => deleteItem(_, record, index)}
						size="small"
						iconSize={16}
					/>
				);
			},
		},
	];

	const missingBasicInfoMessage =
		missingOfferFieldNames.length > 0
			? `${missingOfferFieldNames.join(
					', '
			  )} fields are missing in Basic Information.`
			: '';

	return (
		<div>
			{validationMessage && (
				<Alert
					message={validationMessage}
					showIcon
					type="error"
				/>
			)}
			<Row className="eco-mb-24"></Row>
			<Table
				columns={addItemsColumns}
				dataSource={tableData}
				type="striped"
				className="eco-mb-24"
			/>
			<Button variant="link" onClick={() => addItems()}>
				Add Item
			</Button>
		</div>
	);
};

export default AddItemsForm;
