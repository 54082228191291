import { createAsyncThunk } from '@reduxjs/toolkit';

import { getUserDetailsByUserId as getUserDetailsByUserIdAPI } from '../../../utilities/apiRequests/witmegOrgServerRequests';
import { getLoggedUserId } from '../../../utilities/userAuthentication';

import sliceConstants from './userSliceConstants';

export const dummyFn = createAsyncThunk(
	`${sliceConstants.name}/dummyFn`,
	async (options = {}, thunkAPI) => {
		try {
			return await Promise.resolve(options.dummyValue);
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;
			return Promise.reject(errMsg);
		}
	},
);

export const getCurrentlyLoggedUserDetails = createAsyncThunk(
	`${sliceConstants.name}/getCurrentlyLoggedUserDetails`,
	async (options = {}, thunkAPI) => {
		try {
			return await getUserDetailsByUserIdAPI({
				UserID: getLoggedUserId(),
			});
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;
			return Promise.reject(errMsg);
		}
	},
);

const extraActions = {
	dummyFn,
	getCurrentlyLoggedUserDetails,
};

export default extraActions;
