// import { Divider,  Space } from 'antd'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, ButtonGroup, Card, Icon, Stack, Tag, Typography, Thumbnail, Select, Row, Col } from 'witmeg-ui-system';
import SummaryChart from './SummaryChart';
import DefaultImage from "../../../../../../assets/img/default.jpg";
import { getEposProductOverviewByProductId } from '../../../../../../utilities/apiRequests/witmegWebNeurolageServerRequests';
import ProductOverviewImageSlider from './ProductOverviewImageSlider';


export default function ProductOverview({ OrgID, locationId, product }) {
    const [summeryView, setSummeryView] = useState('cards')
    const [summaryData, setSummaryData] = useState(null)
    const [selectedPeriod, setSelectedPeriod] = useState({ value: 30, label: 'Last 30 days' })
    const settingsState = useSelector(state => state.settings.currencySettings)
    const options2 = [
        {
            options: [
                { value: 30, label: 'Last 30 days' },
                { value: 365, label: 'Last Year' },
                { value: 90, label: 'Last 90 days' },
                { value: 14, label: 'Last 2 weeks' },
                { value: 7, label: 'Last week' },
            ]
        },
    ];

    // console.log("product111",product?.Inventories?.find(i => i?.QuantityList.find(ql => ql?.CloudLocationID === locationId))?.QuantityList.find(qls => qls?.CloudLocationID === locationId).Stock || '-');
    
    useEffect(async () => {
        let oveviewData = await getEposProductOverviewByProductId({
            locationId: locationId,
            productId: product.ID,
            orgId: OrgID,
            noOfDays: selectedPeriod.value
        })

        //Hardcoded result for development purpose
        let results = {
            "SalesActivityData": {
                "Activity": "Sales",
                "TotalValue": 12342.33,
                "TotalQuantity": 3319,
                "DailyData": [
                    {
                        "PackSize": 1,
                        "TotalValue": 1550.35,
                        "TotalQuantity": 502,
                        "DateOfActivity": "2022-05-03T00:00:00Z",
                        "Activity": "Sales"
                    },
                    {
                        "PackSize": 1,
                        "TotalValue": 936.73,
                        "TotalQuantity": 323,
                        "DateOfActivity": "2022-05-04T00:00:00Z",
                        "Activity": "Sales"
                    },
                    {
                        "PackSize": 1,
                        "TotalValue": 2075.54,
                        "TotalQuantity": 374,
                        "DateOfActivity": "2022-05-05T00:00:00Z",
                        "Activity": "Sales"
                    },
                    {
                        "PackSize": 1,
                        "TotalValue": 1287.53,
                        "TotalQuantity": 458,
                        "DateOfActivity": "2022-05-06T00:00:00Z",
                        "Activity": "Sales"
                    },
                    {
                        "PackSize": 1,
                        "TotalValue": 1819.25,
                        "TotalQuantity": 564,
                        "DateOfActivity": "2022-05-07T00:00:00Z",
                        "Activity": "Sales"
                    },
                    {
                        "PackSize": 1,
                        "TotalValue": 1509.08,
                        "TotalQuantity": 382,
                        "DateOfActivity": "2022-05-08T00:00:00Z",
                        "Activity": "Sales"
                    },
                    {
                        "PackSize": 1,
                        "TotalValue": 1229,
                        "TotalQuantity": 306,
                        "DateOfActivity": "2022-05-09T00:00:00Z",
                        "Activity": "Sales"
                    },
                    {
                        "PackSize": 1,
                        "TotalValue": 1934.85,
                        "TotalQuantity": 410,
                        "DateOfActivity": "2022-05-10T00:00:00Z",
                        "Activity": "Sales"
                    }
                ]
            },
            "PurchaseActivityData": {
                "Activity": "Purchased",
                "TotalValue": 10,
                "TotalQuantity": 5,
                "DailyData": null
            },
            "ExpiredProductData": {
                "Activity": "Expired",
                "TotalValue": 6,
                "TotalQuantity": 2,
                "DailyData": null
            },
            "DamagedProductData": {
                "Activity": "Damaged",
                "TotalValue": 12,
                "TotalQuantity": 4,
                "DailyData": null
            },
            "ManualStockUpdateData": {
                "Activity": "ManualStockUpdate",
                "TotalValue": 4580,
                "TotalQuantity": 45,
                "DailyData": [
                    {
                        "PackSize": 1,
                        "TotalValue": 0,
                        "TotalQuantity": -1,
                        "DateOfActivity": "2022-05-01T00:00:00Z",
                        "Activity": "ManualStockUpdate"
                    }
                ]
            },
            "GoodsInTransit": [
                {
                    "ID": {
                        "OrgID": "6106b9d3802140e655ad0deb",
                        "CloudProductID": "615619e3627bd2d9fc4d0f3f",
                        "CloudUnitTypeID": "615596eb627bd2d9fc4c442a",
                        "PackSize": 10
                    },
                    "TotalQty": 4,
                    "TotalValue": 41.4
                },
                {
                    "ID": {
                        "OrgID": "6106b9d3802140e655ad0deb",
                        "CloudProductID": "615619e3627bd2d9fc4d0f3f",
                        "CloudUnitTypeID": "615596eb627bd2d9fc4c442a",
                        "PackSize": 10
                    },
                    "TotalQty": 4,
                    "TotalValue": 41.4
                }
            ],
            "CurrentStockInfo": {
                "AvailableStock": 456,
                "AvailableStockValue": 574357,
                "UnitTypeName": "EA"
            }
        };

        // setSummaryData(results)

        if (oveviewData.Status) {
            setSummaryData(oveviewData.Result)
        } else {
            setSummaryData(null)
        }

        console.log("oveviewData====>>", oveviewData);

    }, [selectedPeriod])

    // switch between tabs automatically at inital view
    useEffect(() => {
        let isMounted = true;
        let tabTimer = setTimeout(function() {
            isMounted && setSummeryView('chart');
        }, 4000);

        return () => {
            isMounted = false;
            clearTimeout(tabTimer);
        }
    }, []);

    // const handleChangePeriod = (val) => {
    //     setSelectedPeriod: val
    // }


    return (
        <div>
            {product ?
                <>
                    <Row gutter={[16, 16]}>
                        <Col span={10}>
                            <Stack direction="column">
                                <div style={{ width: '100%', position: 'relative' }}>
                                    <ProductOverviewImageSlider data={product?.OnLineImages} />
                                </div>
                            </Stack>
                        </Col>
                        <Col span={14}>
                            <Stack horizontalAlign="space_between" direction="row" className="eco-mb-4">
                                <Typography
                                    type="title"
                                    level={4}
                                    pattern="bold"
                                >
                                    {product ? product.Name : ''}
                                </Typography>
                                <div>
                                    {product?.IsDeleted ? <Tag label="Inactive" variant="gray" /> : <Tag label="Active" variant="green" />}
                                    {product?.IsOutOfStock ? <Tag label="Out Of Stock" variant="red" /> : null}
                                </div>
                            </Stack>
                            <Stack direction="row" className="eco-mb-8">
                                <Typography color="gray_base" size="small">CATEGORY NAME |{' '}</Typography>
                                <Typography pattern="bold" className="eco-mr-8">
                                    {product?.CloudProductCategory?.ProductCategory?.Category || 'Unknown Category'}
                                </Typography>
                                {
                                    product?.CloudProductCategory?.ProductSubCategory?.Name !== '' ?
                                        <Typography size="small" className="eco-pl-8 eco-divider-l">
                                            {product?.CloudProductCategory?.ProductSubCategory?.Name || null}
                                        </Typography> : null
                                }
                            </Stack>
                            <Stack>
                                <Typography color="gray_base" size="small">BARCODE:</Typography>
                                <Typography color="gray_darkest" pattern="bold" className="eco-ml-4">
                                    {product?.BarCode}
                                </Typography>
                            </Stack>
                            <Stack>
                                <Typography color="gray_base" size="small">ITEMCODE:</Typography>
                                <Typography color="gray_darkest" pattern="bold" className="eco-ml-4">
                                    {product?.ProductRefNo}
                                </Typography>
                            </Stack>
                            <Stack>
                                <Typography color="gray_base" size="small">Stock:</Typography>
                                <Typography color="gray_darkest" pattern="bold" className="eco-ml-4">
                                    {product?.Inventories?.find(i => i?.QuantityList.find(ql => ql?.CloudLocationID === locationId))?.QuantityList.find(qls => qls?.CloudLocationID === locationId).Stock || '-'}
                                </Typography>
                            </Stack>
                            {/* ToDo: Move to another component - @Dhanuja */}
                            <Stack direction="row" className="eco-mt-24">
                                <Stack className="eco-pr-24" direction="column" verticalAlign="v_top">
                                    <Typography
                                        color="gray_base"
                                        pattern="bold"
                                        textTransform="capitalize"
                                        small
                                        className="eco-mb-8"
                                    >
                                        {product?.UnitChart?.UnitTypeName || ''}
                                    </Typography>
                                    <Typography
                                        type="title"
                                        level={2}
                                        pattern="bold"
                                        color="gray_darkest"
                                    >
                                        {settingsState?.DefaultCurrency?.CurrencySymbol || '£'}
                                        {product?.CloudSellingPricesArray?.[0]?.CloudSellingprice.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || '-'}
                                    </Typography>
                                    <Typography
                                        color="gray_lighter"
                                        pattern="bold"
                                        textTransform="uppercase"
                                        style={{ fontSize: '10px' }}
                                    >
                                        Selling Price
                                    </Typography>
                                </Stack>
                                <Stack className="eco-pl-24 eco-divider-l" direction="column" verticalAlign="v_top">
                                    <Typography
                                        color="gray_base"
                                        pattern="bold"
                                        textTransform="capitalize"
                                        small
                                        className="eco-mb-8"
                                    >
                                        {product?.UnitChart?.UnitTypeName || ''}
                                    </Typography>
                                    <Typography
                                        type="title"
                                        level={2}
                                        pattern="bold"
                                        color="gray_darkest"
                                    >
                                        {settingsState?.DefaultCurrency?.CurrencySymbol || '£'}
                                        {product?.CloudCostPricesArray?.[0]?.CloudCostprice.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || '-'}
                                    </Typography>
                                    <Typography
                                        color="gray_lighter"
                                        pattern="bold"
                                        textTransform="uppercase"
                                        style={{ fontSize: '10px' }}
                                    >
                                        Cost Price
                                    </Typography>
                                </Stack>
                            </Stack>
                            {product.IsOnline || product.IsOfferProduct || product.HasWeight || product.IsPriceNotification ?
                                <Row className="eco-mt-24">
                                    {product.IsOnline ? <Col span={12}>
                                        <Icon name="globe" type="filled" size={20} color="red" /><Typography size="small">Online</Typography>
                                    </Col> : null}
                                    {product.IsOfferProduct ? <Col span={12}>
                                        <Icon name="contact-badge" type="filled" size={20} color="green" /><Typography size="small">Offer Product</Typography>
                                    </Col> : null}
                                    {product.HasWeight ? <Col span={12}>
                                        <Icon name="stopwatch" type="filled" size={20} color="orange" /><Typography size="small">Weighable</Typography>
                                    </Col> : null}
                                    {product.IsPriceNotification ? <Col span={12}>
                                        <Icon name="bell" type="filled" size={20} color="yelow" /><Typography size="small">Price Notification</Typography>
                                    </Col> : null}
                                </Row>
                                : null}
                        </Col>
                    </Row>
                </>
                : null}
            {/* Product Overview */}

            <div className="eco-mt-16 eco-ph-16 eco-divider-t">
                {/* TODO: @Dhanuja remove the alert component, component already exists */}
                <Stack horizontalAlign="space_between" className="eco-mb-24">
                    <ButtonGroup toggle={true} variant="left">
                        <Button
                            category="icon"
                            size="small"
                            children="chart"
                            iconSize={20}
                            variant={summeryView === "chart" ? "primary" : "default"}
                            onClick={() => setSummeryView('chart')}
                        />
                        <Button
                            category="icon"
                            size="small"
                            children="table"
                            iconSize={20}
                            variant={summeryView === "cards" ? "primary" : "default"}
                            onClick={() => setSummeryView('cards')}
                        />
                    </ButtonGroup>
                    <div>
                        <Select
                            className="duration-select"
                            defaultValue='last-30-days'
                            placeholder="Last 30 days"
                            options={options2}
                            value={selectedPeriod}
                            onChange={setSelectedPeriod}
                        >
                            {/*<Select.Option value='last-30-days'>Last 30 days</Select.Option>*/}
                            {/*<Select.Option value='last-week'>Last week</Select.Option>*/}
                        </Select>
                    </div>
                </Stack>

                {summeryView === "chart" ?
                    <Row>
                        <Col span={24}>
                            <div >
                                <SummaryChart summaryData={summaryData} selectedPeriod={selectedPeriod} />
                                {/* <Chart
                                    labels={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']}
                                    datasets={[
                                        {
                                            label: 'Dataset 1',
                                            data: [100, 200, 300, 400, 450, 850, 600, 600, 300, 100, 50, 1000],
                                            borderColor: 'rgb(255, 99, 132)',
                                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                        },
                                        {
                                            label: 'Dataset 2',
                                            data: [600, 600, 300, 100, 50, 2000, 100, 200, 300, 400, 450, -850],
                                            borderColor: 'rgb(53, 162, 235)',
                                            backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                        }
                                    ]}
                                /> */}
                            </div>
                        </Col>
                    </Row>
                    : <Stack horizontalAlign="space_between" className="eco-mb-24">

                        <Card
                            title="test"
                            className="stats-card-block"
                            content={
                                <div>
                                    <div style={{ margin: 'auto', width: '80px' }}>
                                        <Stack horizontalAlign="h-center" className="eco-mb-12">
                                            <Icon name="layers" type="filled" size={24} color="orange" />
                                            <span>&nbsp;</span>
                                            <Tag label="Stock" variant="red" />
                                        </Stack>
                                    </div>
                                    <div className='eco-pa-12'>
                                        <Stack horizontalAlign="space_between" className="eco-mb-12">
                                            <Typography color="gray_base">Available Qty</Typography>
                                            <Typography type="title" level={3}>{summaryData?.CurrentStockInfo?.AvailableStock || 0}</Typography>
                                        </Stack>
                                        <Stack horizontalAlign="space_between" className="eco-mb-12">
                                            <Typography color="gray_base">In Transit</Typography>
                                            <Typography type="title" level={3}>
                                                {summaryData?.GoodsInTransit?.reduce((t, val) => t + val.TotalQty, 0) || 0}
                                            </Typography>
                                        </Stack>
                                        <Stack horizontalAlign="space_between" className="eco-mb-12">
                                            <Typography color="gray_base">Available Stock Value</Typography>
                                            <Typography type="title" level={3}>{summaryData?.CurrentStockInfo?.AvailableStockValue.toFixed(2) || 0}</Typography>
                                        </Stack>
                                    </div>
                                </div>
                            }
                        />
                        <Card
                            title="test"
                            className="stats-card-block"
                            content={
                                <div>
                                    <div style={{ margin: 'auto', width: '80px' }}>
                                        <Stack horizontalAlign="h-center" className="eco-mb-12">
                                            <Icon name="cash" type="filled" size={24} color="orange" />
                                            <span>&nbsp;</span>
                                            <Tag label="Sales" variant="red" />
                                        </Stack>
                                    </div>
                                    <div className='eco-pa-12'>
                                        <Stack horizontalAlign="space_between" className="eco-mb-12">
                                            <div>
                                                <Typography type="title" level={3}>{summaryData?.SalesActivityData?.TotalQuantity || 0}</Typography>
                                                <Typography color="gray_base">Sold</Typography>
                                            </div>
                                            <div style={{'min-width': '100px'}}>
                                                <Typography type="title" level={3}>{summaryData?.DamagedProductData?.TotalQuantity || 0}</Typography>
                                                <Typography color="gray_base">Damaged</Typography>
                                            </div>

                                        </Stack>
                                        <Stack horizontalAlign="space_between" className="eco-mb-12">
                                            <div>
                                                <Typography type="title" level={3}>{summaryData?.PurchaseActivityData?.TotalQuantity || 0}</Typography>
                                                <Typography color="gray_base">Purchased</Typography>
                                            </div>
                                            <div style={{'min-width': '100px'}}>
                                                <Typography type="title" level={3}>{summaryData?.ExpiredProductData?.TotalQuantity || 0}</Typography>
                                                <Typography color="gray_base">Expired</Typography>
                                            </div>
                                        </Stack>

                                    </div>
                                </div>
                            }
                        />

                    </Stack>
                }

            </div>
        </div>
    )
}
