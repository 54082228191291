import lodash from 'lodash';
import { toast } from 'react-toastify';

import { routePaths } from '../../../routes/routeConstants';
import routerHistory from '../../routerHistory';
import { logoutUser } from '../../userAuthentication';

// Handling when API says "Provided UserToken is Invalid/Expired".
export function handleTokenExpirationApiProblem(response) {
	const { Status = '', Result = '' } = lodash.isObject(response.data)
		? response.data
		: {};

	if (Status === false && Result.includes('Token Expired')) {
		// Checking where currently user at. According to that redirect to appropriate login page.
		let redirectTo = routePaths.loginRoot;
		const currentLocationPath = routerHistory.location.pathname;

		// If already on one of Login Page ignore redirecting.
		if (currentLocationPath === routePaths.loginRoot) {
			return;
		}

		if (currentLocationPath.startsWith(routePaths.dashboardRoot)) {
			redirectTo = routePaths.loginRoot;
		}

		logoutUser({ redirectTo });
		toast.warning('Login Expired. Please Login Again.');
	}
}
