import React, { useState, useEffect } from "react";
import { Button, Form, Space, Select, InputBox, Label, Stack, Search, InputGroup, Typography, Row, Col, Checkbox, InputNumber } from 'witmeg-ui-system';
import UnitChartTable from "./UnitChartTable";



const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

export default function PriceInformationForm(props) {
    const [showUnitChartTable, setShowUnitChartTable] = useState(false)
    const {
        priceInformationValues,
        priceInformationForm,
        unitTypes,
        handleChangePriceInfoInputs,
        validations,
        unitChartInfo,
        currencySettings,
    } = props;

    const [marginValue, setMarginValue] = useState(0)
    const [purchasePriceCurrency, setPurchasePriceCurrency] = useState(null)
    const [purchasePrice, setPurchasePrice] = useState('');
    const [sellingPriceCurrency, setSellingPriceCurrency] = useState(null)
    const [sellingPrice, setSellingPrice] = useState('');
    const [discount, setDiscount] = useState('');
    const [availableUnitTypes, setAvailableUnitTypes] = useState([])
    const [discountType, setDiscountType] = useState({ value: 'Amount', label: 'Amount' })

    useEffect(() => {
        calculateMarginValue();
    }, [priceInformationValues])

    useEffect(() => {
        if (currencySettings && currencySettings.DefaultCurrency) {
            setPurchasePriceCurrency({ value: currencySettings?.DefaultCurrency?.CurrencyCode || 'GBP', label: currencySettings?.DefaultCurrency?.CurrencySymbol || '£' })
            setSellingPriceCurrency({ value: currencySettings?.DefaultCurrency?.CurrencyCode || 'GBP', label: currencySettings?.DefaultCurrency?.CurrencySymbol || '£' })
        }
    }, [currencySettings])


    useEffect(() => {
        if (priceInformationValues.sellingPrice > 0 && discount > 0) {
            if (discountType.value === "Amount") {
                let updatedPriceInformationValues = {
                    ...priceInformationValues,
                    maxDiscount: discount
                }
                handleChangePriceInfoInputs(updatedPriceInformationValues, 'maxDiscount')
            } else {
                let updatedPriceInformationValues = {
                    ...priceInformationValues,
                    maxDiscount: Number((discount / priceInformationValues.sellingPrice) * 100).toFixed(2)
                }
                handleChangePriceInfoInputs(updatedPriceInformationValues, 'maxDiscount')
            }
        }
    }, [discountType, discount, priceInformationValues.sellingPrice])

    useEffect(() => {
        if (unitChartInfo) {
            if (unitChartInfo.CloudUnitTypeID === unitChartInfo.CloudBaseUnitTypeID) {
                setAvailableUnitTypes(unitTypes.filter(ut => ut.IsBaseUnit).map((utm) => {
                    return {
                        value: utm.ID,
                        label: utm.UnitTypeName,
                    }
                }))
            } else {
                setAvailableUnitTypes([
                    ...unitTypes.filter(ut => ut.IsBaseUnit).map((utm) => {
                        return {
                            value: utm.ID,
                            label: utm.UnitTypeName,
                        }
                    }),
                    ...unitTypes.filter(ut => ut.ID === unitChartInfo.CloudUnitTypeID).map((utm) => {
                        return {
                            value: utm.ID,
                            label: utm.UnitTypeName,
                        }
                    })
                ])
            }
        }
    }, [unitChartInfo, unitTypes])



    const calculateMarginValue = () => {
        let purchasePrice = priceInformationValues.purchasePrice
        let sellingPrice = priceInformationValues.sellingPrice

        purchasePrice = purchasePrice ? purchasePrice : 0
        sellingPrice = sellingPrice ? sellingPrice : 0

        const margin = (sellingPrice - purchasePrice) / purchasePrice * 100;
        if (Number(purchasePrice) === 0 && sellingPrice > 0) {
            setMarginValue("Not Applicable")
            return;
        }
        setMarginValue(margin);
    }

    const clearMaxDiscountValue = () => {
        priceInformationForm.setFieldsValue({ maxDiscount: 0 });

    }

    const unitOptions = unitTypes.filter(ut => ut.IsBaseUnit).map((utm) => {
        return {
            value: utm.ID,
            label: utm.UnitTypeName,
        }
    })

    const unitConversionColumns = [
        {
            title: 'Base',
            dataIndex: 'base',
            key: 'base',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Qty',
            dataIndex: 'qty',
            key: 'qty',
        },
        {
            title: 'Unit Type',
            dataIndex: 'unitType',
            key: 'unitType',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button category='icon' variant='subtle' children='create' size='small' iconSize={16} />
                    <Button category='icon' variant='subtle' children='trashcan' size='small' iconSize={16} />
                </Space>
            ),
        },
    ];


    //   const unitConversionData = [
    //     {
    //       key: '1',
    //       base: 'EA',
    //       qty: 10,
    //       unitType: 'Box'
    //     },
    //     {
    //       key: '1',
    //       base: 'EA',
    //       qty: 10,
    //       unitType: 'Box'
    //     },
    //     {
    //       key: '1',
    //       base: 'EA',
    //       qty: 10,
    //       unitType: 'Box'
    //     },
    //   ];

    const handleSToogleUnitChartsTable = () => {

        setShowUnitChartTable(!showUnitChartTable)
    }

    const handleSetUnitChart = (data) => {
        let currentUnitChart = unitTypes.find(ut => ut.UnitTypeName === data[data.length - 1].UnitTypeName)
        setAvailableUnitTypes([
            ...unitTypes.filter(ut => ut.IsBaseUnit).map((utm) => {
                return {
                    value: utm.ID,
                    label: utm.UnitTypeName,
                }
            }),
            {
                value: currentUnitChart.ID,
                label: currentUnitChart.UnitTypeName,
            }
        ])
        props.handleSetUnitChart(data)
    }

    const handleChangeInput = (e, fieldName) => {

        let updatedPriceInformationValues = {
            ...priceInformationValues,
            [fieldName]: fieldName === 'purchasePrice' || fieldName === 'sellingPrice' ? Number(e) % 1 == 0 ? e : Number(e).toFixed(2).toLocaleString() : Number(e).toLocaleString(),
            updatedPrices: true
        }

        updatedPriceInformationValues = fieldName === 'recommendedPrice' ? {...updatedPriceInformationValues, 'recommendedPrice': Number(e.target.value)} : updatedPriceInformationValues;

        handleChangePriceInfoInputs(updatedPriceInformationValues, fieldName);
        // Number(e) % 1 == 0?e:Number(e).toFixed(2)
        // calculateMarginValue()
    }

    const handleChangeSelect = (e, fieldName) => {
        let updatedPriceInformationValues = Object.assign(
            priceInformationValues,
            {[fieldName]: e}
        );
        // let updatedPriceInformationValues = {
        //     ...priceInformationValues,
        //     [fieldName]: e
        // }
        handleChangePriceInfoInputs(updatedPriceInformationValues, fieldName)
    }

    const handleChangeDiscountType = (val) => {
        setDiscountType(val)
    }

    const setDiscountValue = (e) => {
        setDiscount(Number(e) % 1 == 0 ? e : Number(e).toFixed(2))
        let updatedPriceInformationValues = Object.assign(priceInformationValues, {maxDiscount: Number(e) % 1 == 0 ? e : Number(e).toFixed(2)})
        // let updatedPriceInformationValues = {
        //     ...priceInformationValues,
        //     maxDiscount: Number(e) % 1 == 0 ? e : Number(e).toFixed(2)
        // }
        handleChangePriceInfoInputs(updatedPriceInformationValues, 'maxDiscount');
    }

    return (
        <div>
            <Row className="form-row" align="top">
                <Col md={8}><div className="form-lbl-wrapper"><Label required={true}>Purchase</Label></div></Col>
                <Col span={16}>
                    <Row gutter={8}>
                        <Col md={12}>
                            <InputGroup
                                leftComponent="select"
                                leftOptions={[{ value: currencySettings?.DefaultCurrency?.CurrencyCode || 'GBP', label: currencySettings?.DefaultCurrency?.CurrencySymbol || '£' }]}
                                leftValue={{ value: currencySettings?.DefaultCurrency?.CurrencyCode || 'GBP', label: currencySettings?.DefaultCurrency?.CurrencySymbol || '£' }}
                                onChangeLeftValue={(e) => setPurchasePriceCurrency(e)}
                                leftWidth="60%"
                                rightComponent="number"
                                rightValue={priceInformationValues.purchasePrice}
                                rightMinValue={0}
                                onChangeRightValue={(e) => handleChangeInput(e, 'purchasePrice')}
                                rightWidth="40%"
                                errorRight={validations.purchasePrice}

                            />

                        </Col>
                        <Col md={7}>
                            <Select options={availableUnitTypes}
                                value={priceInformationValues.purchaseUnits}
                                onChange={(e) => handleChangeSelect(e, 'purchaseUnits')}
                                placeholder="Unit"

                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="form-row">
                <Col md={8}><div className="form-lbl-wrapper"><Label required={true}>Selling</Label></div></Col>
                <Col span={16}>
                    <Row gutter={8}>
                        <Col md={12}>
                            <InputGroup
                                leftComponent="select"
                                leftOptions={[{ value: currencySettings?.DefaultCurrency?.CurrencyCode || 'GBP', label: currencySettings?.DefaultCurrency?.CurrencySymbol || '£' }]}
                                leftValue={{ value: currencySettings?.DefaultCurrency?.CurrencyCode || 'GBP', label: currencySettings?.DefaultCurrency?.CurrencySymbol || '£' }}
                                onChangeLeftValue={(e) => setSellingPriceCurrency(e)}
                                leftWidth="60%"
                                rightComponent="number"
                                rightValue={priceInformationValues.sellingPrice}
                                onChangeRightValue={(e) => handleChangeInput(e, 'sellingPrice')}
                                rightWidth="40%"
                                rightMinValue={0}
                                errorRight={validations.sellingPrice}
                            />
                        </Col>
                        <Col md={7}>
                            <Select options={availableUnitTypes}
                                value={priceInformationValues.sellingPriceUnits}
                                onChange={(e) => handleChangeSelect(e, 'sellingPriceUnits')}
                                placeholder="Unit"
                                name="sellingPriceUnits"
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="form-row">
                <Col md={8}>
                    <div className="form-lbl-wrapper">
                        <div>
                            <Label>Margin</Label>

                            <Typography size="small" disabled={true}>System Generated</Typography>
                        </div>
                    </div></Col>
                <Col span={16}>
                    <div style={{ color: "#039BE5", fontSize: '16px' }}>{marginValue && !isNaN(marginValue) || isFinite(marginValue) ? marginValue.toFixed(2) + '%' : '-'}</div>
                </Col>
            </Row>

            <Row className="form-row">
                <Col md={8}>
                    <div className="form-lbl-wrapper">
                        <div>
                            <Label>Recommended Price</Label>

                            {/* <Typography size="small" disabled={true}>System Generated</Typography> */}
                        </div>
                    </div></Col>
                <Col span={11}>
                <InputBox
                    type="number"
                    placeholder="Recommended Price"
                    value={priceInformationValues.recommendedPrice}
                    onChange={(e) => handleChangeInput(e, 'recommendedPrice')}
                    error={validations.recommendedPrice}
                />
                </Col>
            </Row>            

            <Row className="form-row">
                <Col md={8}>
                    <div className="form-lbl-wrapper">
                        <div>
                            <Label>Max. Discount</Label>
                        </div>
                    </div></Col>
                <Col span={8}>
                    <InputGroup
                        leftComponent="select"
                        leftOptions={[
                            { value: 'Amount', label: 'Amount' },
                            { value: 'Percentage', label: 'Percentage' }
                        ]}
                        leftValue={discountType}
                        onChangeLeftValue={(e) => handleChangeDiscountType(e)}
                        leftWidth="70%"
                        rightComponent="number"
                        rightValue={priceInformationValues.maxDiscount}
                        onChangeRightValue={(e) => setDiscountValue(e)}
                        rightWidth="70%"
                        rightMinValue={0}
                        rightMinErrorMessage={discountType.value === "Percentage" ? 'The value cannot be negative' : 'The value cannot be negative'}
                        rightMaxValue={discountType.value === "Amount" ? priceInformationValues.sellingPrice ? Number(priceInformationValues.sellingPrice) : 0 : 100}
                        rightMaxErrorMessage={discountType.value === "Amount" ? 'Maximum discount should be less than the selling price' : 'The value cannot be more than 100 '}

                    />
                    {/* <InputBox type="number" /> */}
                </Col>
            </Row>

            <Row className="form-row">
                <Col md={8}>
                    <div className="form-lbl-wrapper">
                        <div>
                            <Label>Show Unit Chart</Label>
                        </div>
                    </div></Col>
                <Col span={8}>
                    <div className="form-lbl-wrapper">
                        <Checkbox checked={showUnitChartTable} onChange={handleSToogleUnitChartsTable}></Checkbox>
                    </div>
                </Col>
            </Row>

            {/* <Form initialValues={priceInfoInitialValues} {...layout} form={priceInformationForm} name="price-messages" onFinish={onFinish} onFinishFailed={onFinishFailed} style={{ width: '100%' }}>
                <Form.Item label="Purchase Price" labelAlign="left">
                    <Form.Item
                        name="purchasePrice"
                        rules={[{ required: true }]}
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginBottom: 0 }}
                        labelAlign="left"
                        help=""
                    >
                        <AntInput placeholder="Purchase Price" onChange={calculateMarginValue} />
                    </Form.Item>
                    <Form.Item
                        name="purchaseUnits"
                        rules={[{ required: true }]}
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px', marginBottom: 0 }}
                        help=""
                    >
                        <Select options={
                            unitTypes.filter(ut => ut.IsBaseUnit).map((utm) => {
                                return {
                                    value: utm.ID,
                                    label: utm.UnitTypeName,
                                }
                            })
                        } 
                        defaultValue={unitTypes.find(ut => ut.IsBaseUnit).ID}/>
                    </Form.Item>
                </Form.Item>
                <Form.Item label="Selling Price" rules={[{ required: true }]} labelAlign="left">
                    <Form.Item
                        name="sellingPrice"
                        rules={[{ required: true }]}
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginBottom: 0 }}
                        help=""
                    >
                        <AntInput placeholder="Selling Price" type="number" onChange={calculateMarginValue} />
                    </Form.Item>
                    <Form.Item
                        name="sellingPriceUnits"
                        rules={[{ required: true }]}
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px', marginBottom: 0 }}
                        help=""
                    >
                        <Select options={
                            unitTypes.filter(ut => ut.IsBaseUnit).map((utm) => {
                                return {
                                    value: utm.ID,
                                    label: utm.UnitTypeName,
                                }
                            })
                        } />
                    </Form.Item>
                </Form.Item>
                <Form.Item label="Margin (%)" labelAlign="left">
                    <Form.Item
                        name="margin"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginBottom: 0 }}
                    >
                        {marginValue && !isNaN(marginValue) || isFinite(marginValue) ? marginValue.toFixed(2) + '%': '-'}
                    </Form.Item>
                </Form.Item>
                {/* <Form.Item label="Recommended Price" labelAlign="left">
                    <Form.Item
                        name="year"
                        rules={[{ required: true }]}
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginBottom: 0 }}
                    >
                        <AntInput placeholder="Recommended Price" />
                    </Form.Item>
                </Form.Item> *
                <Form.Item label="Max. Discount" labelAlign="left">
                    <AntInput.Group compact>
                        <Form.Item
                            name="discountBy"
                            style={{ marginBottom: 0 }}
                        >
                            <Select
                                defaultValue="Percentage"
                                options={[
                                    { value: 'Amount', label: 'Amount' },
                                    { value: 'Percentage', label: 'Percentage' }
                                ]}
                                onChange={clearMaxDiscountValue}
                            />
                        </Form.Item>
                        <Form.Item
                            name="maxDiscount"
                            style={{ marginBottom: 0 }}
                        >
                            <AntInput style={{ width: '70%' }} size="large" type="number" placeholder="" />
                        </Form.Item>
                    </AntInput.Group>
                </Form.Item>


                <Form.Item label="Show Unit Chart" labelAlign="left">
                    <Form.Item
                        name="showUnitChart"
                        rules={[{ required: false }]}
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginBottom: 0 }}
                    >
                        <Checkbox checked={showUnitChartTable} onChange={handleSToogleUnitChartsTable}></Checkbox>
                    </Form.Item>
                </Form.Item>

            </Form> */}
            {showUnitChartTable ?
                <>
                    <h5>Unit Conversation</h5>
                    <UnitChartTable unitTypes={unitTypes} unitChartInfo={unitChartInfo} handleSetUnitChart={handleSetUnitChart} />
                    {/* <Table columns={unitConversionColumns} dataSource={unitConversionData} style={{ width: '100%', marginTop: 25 }} /> */}
                </>
                : null}
        </div>
    )
}
