import React, { useState, useEffect } from "react";
import { Form, InlineMessage, InputBox, Label, Row, Col } from 'witmeg-ui-system';


const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const CustomAttributesForm = (props) => {

  const {
    customAttributesInitialValues,
    customAttributesForm,
    onFinish,
    onFinishFailed,
    customAttributes,
    handleChangeCustomAttributesInputs
  } = props;

  const [attributes, setAttributes] = useState([])

  // useEffect(() => {
  //   if(customAttributes) {
  //     customAttributes.map(ca => {key:'ca', value:})
  //   }
  // }, [customAttributes])

  const handleChangeInput = (e, fieldName) => {
    let updatedValues = {
      ...customAttributes,
      [fieldName]: e.target.value
    }
    handleChangeCustomAttributesInputs(updatedValues, fieldName)
    // console.log("updatedBasicInformationIValues===>>", updatedBasicInformationIValues)
  }


  return (
    <div>
      {customAttributes && Object.keys(customAttributes).length > 0 ? Object.keys(customAttributes).map((ca, i) => {
        return (
          <Row className="form-row" key={`custom_attribute_${i}`}>
            <Col md={8}><div className="form-lbl-wrapper"><Label>{ca}</Label></div></Col>
            <Col md={16}>
              <InputBox
                type="text"
                placeholder="Product Name"
                value={customAttributes[ca]}
                onChange={(e) => handleChangeInput(e, ca)}
              // error={validations.productName}
              />
            </Col>
          </Row>
        )
      })
        : <InlineMessage message="Custom Attributes Not Available" type="alert" variant="warning" />
      }
    </div>

    // <Form initialValues={customAttributesInitialValues} form={customAttributesForm} onFinish={onFinish} onFinishFailed={onFinishFailed} {...layout} name="nest-messages" style={{width: '100%'}}>
    //   {customAttributes && customAttributes.Attributes && customAttributes.Attributes.map(ca => {
    //     return (
    //       <Form.Item
    //         name={ca}
    //         label={ca}
    //         labelAlign="left"
    //       >
    //         <AntInput placeholder="" />
    //       </Form.Item>
    //     )
    //   })}
    // </Form>
  )
}

export default CustomAttributesForm;