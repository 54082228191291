import React from 'react';
import { Card, Carousel, Icon, Label, Stack, StackItem, Tag, Typography } from 'witmeg-ui-system';

export default function CategoryOverviewImageSlider(props) {
    const { subCategories } = props;
    
  return (
    <div>
        <Carousel slidesToShow={subCategories.length < 3 ? subCategories.length : 3} slidesToScroll={1}>
            {
                subCategories.map((subCategory) => {
                    return <Card
                            width={subCategories.length === 1 ? "35%" : subCategories.length === 2 ? "45" : "90%"}
                            height="150px"
                            className={`centered-card-content ${subCategories.length === 1 ? 'centered-card' : ''}`}
                            content={            
                                <Stack direction="column">
                                    <StackItem style={{marginBottom: "10px"}}>
                                        <Typography className="eco-typography--center" type="title" level={5} textTransform="capitalize" pattern="bold">{subCategory.Name}</Typography>
                                    </StackItem>
                                    <StackItem>
                                        <Typography className="eco-typography--center" size="small" color="gray_base">{subCategory.SubcategoryDescription}</Typography>
                                    </StackItem>
                                </Stack>
                            }
                        />
                })
            }
        </Carousel>
      </div>
  )
}

