import React, { useState, useEffect } from 'react';
import { Input, Button, Select, DatePicker } from 'antd';
import moment from 'moment';
// import { Formik } from 'formik';
const { RangePicker } = DatePicker;

const ProductSearch = (props) => {
	const [visibleAdvancedSearch, setVisibleAdvancedSearch] = useState(false);
	const [searchOption, setSearchOption] = useState('');
	const [searchText, setSearchText] = useState('');
	const [modifiedDate, setModifiedDate] = useState(moment());
	const [dateRange, setDateRange] = useState([moment().subtract(7, 'd'), moment()]);
	const isSubmitting = false;

	const handleChangeProductType = (val) => {
		props.handleChangeProductType(val);
	};

	const handleChangeMainFilterOption = (val) => {
		props.handleChangeMainFilterOption(val);
	};

	const toogleAdvancedSearch = () => {
		setVisibleAdvancedSearch(!visibleAdvancedSearch)
	}
	useEffect(() => {
		setSearchOption('');
		setSearchText('')
	}, [props.viewType]);

	const hanndleChangeSearchOption = (val) => {
		setSearchText('')
		setSearchOption(val)
		props.hanndleChangeSearchOption(val)
	}

	const handleChangeSearchKey = (e) => {
		setSearchText(e.target.value)
	}

	// useEffect(() => {
	// 	const timeoutId = setTimeout(() => {
	// 		props.handleSearchByKey(searchOption, searchText)
	// 	}, 1000);
	// 	return () => clearTimeout(timeoutId);
	// }, [searchText]);

	const hanndleChangeModifiedDate = (val) => {
		setModifiedDate(val)
		// props.handleSearchByKey(searchOption, val.format())
	}

	const handleSearch = () => {
		if (searchOption === 'modifiedDate') {
			props.handleSearchByKey(searchOption, modifiedDate.format())
		} else if (searchOption === 'period') {
			console.log("DATERANGE", dateRange)
			props.handleSearchByPeriod(searchOption, [dateRange[0].format(), dateRange[1].format()])
		} else {
			props.handleSearchByKey(searchOption, searchText)
		}

	}
	return (
		<div>
			<Input.Group compact>
				<Select
					style={{ width: '20%' }}
					defaultValue='products'
					onChange={handleChangeProductType}>
					<Select.Option value='products'>Products</Select.Option>
					<Select.Option value='promotions'>Promotions</Select.Option>
				</Select>
				<Select style={{ width: '60%' }} defaultValue='all' onChange={handleChangeMainFilterOption}>
					<Select.Option value='all'>All</Select.Option>
					{/* <Select.Option value='new'>New</Select.Option> */}
					<Select.Option value='modified'>Modified</Select.Option>
				</Select>
				<Button type='default' onClick={toogleAdvancedSearch} style={{ width: '20%' }}>
					Advanced Search
				</Button>
			</Input.Group>
			<br />
			{visibleAdvancedSearch ?
				<>
					<Input.Group compact>
						{props.viewType === "products" ?
							<Select style={{ width: '20%' }} value={searchOption} onChange={hanndleChangeSearchOption}>
								<Select.Option value=''>All</Select.Option>
								<Select.Option value='productName'>Product Name</Select.Option>
								<Select.Option value='barcode'>Barcode</Select.Option>
								<Select.Option value='modifiedDate'>Modified Date</Select.Option>
							</Select>
							: <Select style={{ width: '20%' }} value={searchOption} onChange={hanndleChangeSearchOption}>
								<Select.Option value=''>All</Select.Option>
								{/* <Select.Option value='promoType'>Promotion Type</Select.Option> */}
								<Select.Option value='productName'>Product Name</Select.Option>
								<Select.Option value='barcode'>Barcode</Select.Option>
								<Select.Option value='modifiedDate'>Modified Date</Select.Option>
								<Select.Option value='period'>Promotion Period</Select.Option>

							</Select>
						}
						{searchOption === 'modifiedDate' ?
							<DatePicker style={{ width: '60%' }} onChange={hanndleChangeModifiedDate} value={modifiedDate} />
							: searchOption === 'period' ?
								<RangePicker
									style={{ width: '60%' }}
									value={dateRange}
									onChange={setDateRange}
								/>
								: <Input style={{ width: '60%' }} onChange={handleChangeSearchKey} value={searchText} disabled={searchOption === ''} />
						}

						<Button disabled={isSubmitting} style={{ width: '20%' }} onClick={handleSearch}>
							Search
						</Button>
					</Input.Group>
				</>
				: null
			}
		</div>
	);
};

export default ProductSearch;
