import React, { useState, useEffect } from 'react';
import {
	Form,
	Switch,
	Select,
	InputBox,
	Label,
	Checkbox,
	Row,
	Col,
} from 'witmeg-ui-system';
// import { Row, Col, Input as AntInput, Radio } from 'antd';
import { generateLocationListOfAllOrganizations } from '../../../../../../components/dashboardRelatedComps/LocationSelector/helpers/helperFns';
import './formStyles.css';
const layout = {
	labelCol: {
		span: 8,
	},
	wrapperCol: {
		span: 16,
	},
};

const CategoryInformationForm = (props) => {
	const {
		categoryInformationValues,
		basicInformationForm,
		onFinish,
		onFinishFailed,
		brands,
		orgDetails,
		handleChangeCategoryInfoInputs,
		validations,
	} = props;

	const [subCategories, setSubCategories] = useState([]);
	const [locations, setLocations] = useState([]);
	const [productName, setProductName] = useState('');

	useEffect(() => {
		let isMounted = false;

		if (
			orgDetails &&
			orgDetails.fullUserOrgDetailsList.length > 0
		) {
			if (!isMounted) {
				let locationsList =
					generateLocationListOfAllOrganizations({
						dashboardSliceState: orgDetails,
					});
				setLocations(locationsList);
			}
		}
		return () => (isMounted = true);
	}, [orgDetails]);

	useEffect(() => {
		console.log(
			'categoryInformationValues-------new',
			categoryInformationValues
		);
		setProductName(categoryInformationValues.productName);
	}, [categoryInformationValues.productName]);

	const handleChangeInput = (e, fieldName) => {
		console.log(
			'event handler result',
			e.target.type,
			'and',
			fieldName
		);
		let updatedCategoryInformationIValues = {
			...categoryInformationValues,

			[fieldName]:
				e.target.type === 'checkbox'
					? e.target.checked
					: e.target.type === 'number'
					? Number(e.target.value)
					: e.target.value,
		};
		console.log(
			'updatedCategoryInformationIValues new ',
			updatedCategoryInformationIValues
		);
		handleChangeCategoryInfoInputs(
			updatedCategoryInformationIValues,
			fieldName
		);
	};

	return (
		<div>
			<Row className="form-row">
				<Col md={8}>
					<div className="form-lbl-wrapper">
						<Label required={true}>Category Name</Label>
					</div>
				</Col>
				<Col md={16}>
					<InputBox
						type="text"
						placeholder="Category Name"
						value={categoryInformationValues.categoryName}
						onChange={(e) =>
							handleChangeInput(e, 'categoryName')
						}
						error={validations.categoryName}
					/>
				</Col>
			</Row>
			<Row className="form-row">
				<Col md={8}>
					<div className="form-lbl-wrapper">
						<Label>Description</Label>
					</div>
				</Col>
				<Col md={16}>
					<InputBox
						type="text"
						placeholder="Description"
						value={categoryInformationValues.description}
						onChange={(e) =>
							handleChangeInput(e, 'description')
						}
						error={validations.description}
					/>
				</Col>
			</Row>
			<Row className="form-row">
				<Col md={8}>
					<div className="form-lbl-wrapper">
						<Label>VAT</Label>
					</div>
				</Col>
				<Col md={8}>
					<InputBox
						type="number"
						placeholder="VAT %"
						value={categoryInformationValues.vat}
						error={validations.vat}
						onChange={(e) => handleChangeInput(e, 'vat')}
						minValue={0}
						maxValue={100}
						minErrorMessage={
							'The minimum value cannot be negative'
						}
						maxErrorMessage={
							'The maximum value cannot be more than 100'
						}
					/>
				</Col>
			</Row>
			<Row className="form-row">
				<Col md={8}>
					<div className="form-lbl-wrapper">
						<Label>% Discount</Label>
					</div>
				</Col>
				<Col md={16}>
					<div className="form-lbl-wrapper">
						<Checkbox
							checked={
								categoryInformationValues.discount
							}
							onChange={(e) =>
								handleChangeInput(e, 'discount')
							}
						></Checkbox>
					</div>
				</Col>
			</Row>
			<Row className="form-row">
				<Col md={8}>
					<div className="form-lbl-wrapper">
						<Label>Show Under Shop Sales</Label>
					</div>
				</Col>
				<Col md={16}>
					<div className="form-lbl-wrapper">
						<Checkbox
							checked={
								categoryInformationValues.showUnderShopSale
							}
							onChange={(e) =>
								handleChangeInput(
									e,
									'showUnderShopSale'
								)
							}
						></Checkbox>
					</div>
				</Col>
			</Row>
			<Row className="form-row">
				<Col md={8}>
					<div className="form-lbl-wrapper">
						<Label>Point Based Discount</Label>
					</div>
				</Col>
				<Col md={16}>
					<div className="form-lbl-wrapper">
						<Checkbox
							checked={
								categoryInformationValues.pointBasedDiscount
							}
							onChange={(e) =>
								handleChangeInput(
									e,
									'pointBasedDiscount'
								)
							}
						></Checkbox>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default CategoryInformationForm;
