import React, { Component } from 'react';
import { Table } from 'ecologital-ui-library';
import { Space, Button } from 'antd';
import moment from 'moment';
import { withRouter } from "react-router-dom";
import MaterialDesignIcon from '../../../common/MaterialDesignIcon';

class SearchResults extends Component {
	constructor() {
		super();
		this.state = {
			selectedPurchaseOrder: {}, // Check here to configure the default column
		};
	}

	// componentWillUnmount() {
	// 	this.setState({
	// 		selectedRowKeys: [], // Check here to configure the default column
	// 	});
	// }

	// onSelectChange = (selectedRowKeys) => {
	// 	console.log('selectedRowKeys changed:', selectedRowKeys);
	// 	this.setState({ selectedRowKeys });
	// };

	editPurchaseOrder = (e, item) => {
		this.setState({
			selectedPurchaseOrder: item
		})
		this.props.history.push("/dashboard/purchase/purchase-orders/edit/" + item.ID);
	}

	render() {
		const { purchaseOrdersList, LocationName } = this.props;


		return (
			<div>
				{purchaseOrdersList ? (
					<Table
						columns={[
							// ...Object.keys(purchaseOrdersList[0]).map((p, i) => {
							// 	return { title: p, dataIndex: p, key: i };
							// }),
							{ title: 'PO ID', dataIndex: 'CloudPurchaseOrderID', key: 'CloudPurchaseOrderID' },
							{ title: 'Supplier Name', dataIndex: 'SupplierName', key: 'SupplierName' },
							{ title: 'Ordered Date', dataIndex: 'OrderDate', key: 'OrderDate', render: (text) => <span>{moment(text).format('DD/MM/YYYY')}</span>, },
							{ title: 'Vat Value', dataIndex: 'VatAmount', key: 'VatAmount' },
							{ title: 'Location', dataIndex: 'LocationID', key: 'LocationID', render: () => <span>{LocationName}</span> },
							{
								title: 'Status',
								dataIndex: 'Status',
								key: 'Status',
								render: (text) => <>{(() => {
									switch (text) {
										case 0: return ('Draft');
										case 1: return ('Completed');
										case 2: return ('Partially GRN Created');
										case 3: return ('Confirmed');
										case 4: return ('Cancel');
										case 5: return ('Copied PO');
										default: return ('')
									}
								})()}</>,
							},
							{ title: 'Delivery Date', dataIndex: 'DeliveryDate', key: 'DeliveryDate', render: (text) => <span>{moment(text).format('DD/MM/YYYY')}</span>, },
							{
								title: '',
								key: 'operation',
								fixed: 'right',
								width: 60,
								render: (item) => <Space>

									<Button type="text" icon={<MaterialDesignIcon icon='visibility' />} />
									<Button type="text" icon={<MaterialDesignIcon icon='edit' />} onClick={(e) => this.editPurchaseOrder(e, item)} />
									<Button type="text" icon={<MaterialDesignIcon icon='highlight_off' />} />
								</Space>
							},
						]}
						dataSource={[
							...(purchaseOrdersList &&
								purchaseOrdersList.map((p, i) => {
									return { key: i, ...p };
								})),
						]}
						pagination={true}
					/>
				) : null}
			</div>
		);
	}
}

export default withRouter(SearchResults);
