import React, { useState } from 'react';
import { InputNumber } from 'antd';
import { Button, Table, Form, Select, InputBox } from 'witmeg-ui-system';
const { Option } = Select;

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    unitTypes,
    unitChartInfo,
    ...restProps
}) => {
    const [selectedUnit, setSelectedUnit] = useState('Box')
    const [selectedUnitObj, setSelectedUnitObj] = useState('')
    function handleChange(e) {
        setSelectedUnit(e.target.value)
        setSelectedUnitObj(unitTypes.find(u => u.UnitTypeName === e.target.value))
    }

    const inputNode = inputType === 'number' ?
        <InputBox type='number'></InputBox>
        : <select onChange={handleChange} style={{ width: '90px', padding: '4px' }} value={selectedUnit}>
            {unitTypes.map(ut => <option value={ut.UnitTypeName}>{ut.UnitTypeName}</option>)}
            {/* <option value='EA'>EA</option>
            <option value='Box'>Box</option>
            <option value='Pack'>Pack</option> */}
        </select>
    // <Select onChange={handleChange} value="Box">
    //     <Option value="EA">EA</Option>
    //     <Option value="Box">Box</Option>
    //     <Option value="Pack">Pack</Option>
    // </Select>
    // : <Select
    //      defaultValue='EA'
    //      name="unitType"
    //         options={[
    //             { value: 'EA', label: 'EA' },
    //             { value: 'Box', label: 'Box' },
    //             { value: 'Pack', label: 'Pack' }
    //         ]}
    // />;

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const UnitChartTable = (props) => {
    const baseUnitObj = props.unitTypes.find(ut => ut.IsBaseUnit === true)
    const unitConversionData = [
        // ...props.unitTypes.filter(ut => ut.IsBaseUnit).map((utm) => {
        //     return {
        //         key: '0',
        //         base: 'EA',
        //         qty: 1,
        //         UnitTypeName: utm.UnitTypeName,
        //     }
        // }),
        {
            key: 0,
            base: props.unitChartInfo.BaseunitTypeName,
            qty: props.unitChartInfo.Quantity,
            UnitTypeName: props.unitChartInfo.UnitTypeName,
        }
        //...props.unitChartInfo

    ];

    const [form] = Form.useForm();
    const [data, setData] = useState(unitConversionData);
    const [editingKey, setEditingKey] = useState('');

    const isEditing = (record) => record.key === editingKey;

    const edit = (record) => {
        form.setFieldsValue({
            base: '',
            qty: '',
            UnitTypeName: '',
            ...record,
        });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setData(newData);
                setEditingKey('');
                props.handleSetUnitChart(newData)
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const deleteItem = async (key) => {
        let newData = data.filter(item => item.key !== key);
        setData(newData)
    };

    const addNewUnit = () => {
        let item = {
            key: data.length.toString(),
            base: `EA`,
            qty: 1,
            UnitTypeName: `EA`,
        }
        setData([...data, item])
    }

    const columns = [
        {
            title: 'Base',
            dataIndex: 'base',
            key: 'base',
            editable: false,
        },
        {
            title: 'Qty',
            dataIndex: 'qty',
            key: 'qty',
            editable: true,
        },
        {
            title: 'Unit Type',
            dataIndex: 'UnitTypeName',
            key: 'UnitTypeName',
            editable: true,
        },

        // {
        //   title: 'name',
        //   dataIndex: 'name',
        //   width: '25%',
        //   editable: true,
        // },
        // {
        //   title: 'age',
        //   dataIndex: 'age',
        //   width: '15%',
        //   editable: true,
        // },
        // {
        //   title: 'address',
        //   dataIndex: 'address',
        //   width: '40%',
        //   editable: true,
        // },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Button category='icon' variant='subtle' onClick={() => save(record.key)} children='check' size='small' iconSize={16} />
                        <Button category='icon' variant='subtle' children='cancel' size='small' iconSize={16} onClick={cancel} />
                        <Button category='icon' variant='subtle' children='trashcan' size='small' iconSize={16} onClick={() => deleteItem(record.key)} disabled={record.key === '0'} />
                    </span>
                ) : (
                    <>
                        <Button category='icon' variant='subtle' children='create' size='small' iconSize={16} disabled={editingKey !== '' || record.key === '0'} onClick={() => edit(record)} />
                        <Button category='icon' variant='subtle' children='trashcan' size='small' iconSize={16} onClick={() => deleteItem(record.key)} disabled={record.key === '0'} />
                    </>
                );
            },
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'qty' ? 'number' : 'select',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });
    let unitTypeArray = props.unitTypes
    return (
        <div>
            {props.unitTypes && props.unitTypes.length > 0 ?
                <Form form={form} component={false}>
                    <Table
                        components={{
                            body: {
                                cell: (props) => <EditableCell unitTypes={unitTypeArray} {...props} />,
                            },
                        }}
                        bordered
                        dataSource={data}
                        columns={mergedColumns}
                        rowClassName="editable-row"
                    />
                </Form>
                : null}
            {data.length < 2 ?
                <Button variant="link" onClick={addNewUnit}>Add New</Button>
                : null}
        </div>
    );
};


export default UnitChartTable;