import axios from "axios";
import globalValues from "../configs/globalValues";

import {
    checkIsUserLoggedIn,
    getLoggedUserDetails,
} from "./userAuthentication";

// checks whether token is exists in the localStorage
const tokenExists = (tokenName) => {
    if (localStorage.getItem("tokens") !== null) {
        const tokens = JSON.parse(localStorage.getItem("tokens"));
        return tokens.hasOwnProperty(tokenName) !== null
            ? tokens[tokenName]
            : false;
    }

    return false;
};

// generate token
export const generateToken = async (
    isLoginServerRequest = false,
    serviceName = "orgregistry"
) => {
    const data = {
        isLoginServerRequest: isLoginServerRequest,
        serviceName: serviceName,
    };

    if (serviceName == "orgregistry") {
		return await tokenAPI(data, serviceName);
    } else if (!tokenExists(serviceName)) {
        return await tokenAPI(data, serviceName);
    } else {
        return tokenExists(serviceName);
    }
};

const tokenAPI = async (data, serviceName) => {
	const { REACT_APP_TOKEN_SERVICE_BASE_URL } = globalValues.baseURLS;

    checkIsUserLoggedIn() &&
        (data.usertoken = getLoggedUserDetails().AccessToken);

    const response = await axios.post(REACT_APP_TOKEN_SERVICE_BASE_URL, data);

    if (response.status == 200) {
        let tokens =
            localStorage.getItem("tokens") !== null
                ? JSON.parse(localStorage.getItem("tokens"))
                : {};
        let newToken = { [serviceName]: response.data.token };
        localStorage.setItem(
            "tokens",
            JSON.stringify({ ...tokens, ...newToken })
        );
        return response.data.token;
    }
};

// returns `org registry` token
export const generateToken__WitmegOrgServer = async () =>
    await generateToken(false, "orgregistry");

// returns `app registry` token
export const generateToken__WitmegAppServer = async () =>
    await generateToken(false, "appRegistry");

// returns `email registry` token
export const generateToken__WitmegEmailServer = async () =>
    await generateToken(false, "emailRegistry");

// returns `webneurolage` token
export const generateToken__WitmegWebNeurolageServer = async () =>
    await generateToken(false, "webneurolage");

// returns `image upload` token
export const generateToken__WitmegEcoServicesServer = async () =>
    await generateToken(false, "ImageUpload");

// returns the decoded JWT token
export const decodeToken = (token) => {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(jsonPayload);
};
