import { Table } from 'ecologital-ui-library';
import React from 'react';

import ProductOverviewSection from '../../helperComponents/ProductOverviewSection/ProductOverviewSection';

import './ProOveSuppliersSection.css';

const ProOveSuppliersSection = (props) => {
	// const { overviewData = { } } = props;
	const { defaultCurrencyDetails } = props;
	const currencySymbol =
		defaultCurrencyDetails && defaultCurrencyDetails.CurrencySymbol
			? defaultCurrencyDetails.CurrencySymbol
			: '';

	return (
		<ProductOverviewSection
			title='SUPPLIERS & PURCHASES'
			className='ProOveSuppliersSection'>
			<div className='ProOveSuppliersSection__table'>
				<Table
					columns={[
						{
							title: 'Supplier Name',
							dataIndex: 'supplierName',
							fixed: 'left',
						},
						{
							title: 'Preferred',
							dataIndex: 'isPreferred',
						},
						{
							title: 'Last Purchase',
							dataIndex: 'lastPurchaseDate',
						},
						{
							title: 'Ordered Quantity',
							dataIndex: 'orderedQuantity',
						},
						{
							title: 'Unit Price',
							dataIndex: 'unitPrice',
						},
						{
							title: 'Last GRN',
							dataIndex: 'lastGrn',
						},
					].map((columnConfig) => {
						return {
							...columnConfig,
							// Default Configs For All Columns
							onHeaderCell: () => {
								return {
									style: {
										background: 'white',
									},
								};
							},
						};
					})}
					dataSource={[
						{
							supplierName: 'Bookers',
							isPreferred: true,
							lastPurchaseDate: '01/03/2021',
							orderedQuantity: '10 BOX',
							unitPrice: `${currencySymbol} 0.65`,
							lastGrn: 'G12002',
						},
						{
							supplierName: 'Damecha',
							isPreferred: false,
							lastPurchaseDate: '01/05/2020',
							orderedQuantity: '15 BOX',
							unitPrice: `${currencySymbol} 0.85`,
							lastGrn: 'G11004',
						},
					]}
					rowKey='supplierName' // NOTE :  This 'rowKey' prop is the key need as per React "Each child in a list should have a unique key" prop' warning".
					pagination={{
						pageSize: 10,
					}}
					customProps={{
						showCardViewInMobileRes: false,
					}}
				/>
			</div>
		</ProductOverviewSection>
	);
};

export default ProOveSuppliersSection;
