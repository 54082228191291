import React, { useState, useEffect } from 'react';
import {
	async,
	Button,
	Checkbox,
	InputGroup,
	RightPanel,
	RightPanelHeader,
	Select,
	Space,
	Stack,
	StackItem,
	Steps,
	Table,
	Message,
	Popover,
	ActionList,
	Icon,
	ConfirmationPopup,
} from 'witmeg-ui-system';
import { DatePicker } from 'antd';
import './index.css';
import OfferTypeForm from './Forms/OfferTypeForm';
import OfferInfoForm from './Forms/OfferInfoForm';
import AddItemsForm from './Forms/AddItemsForm';
import OfferOverview from './Overview/OfferOverview';
import {
	getAllEposOffers,
	saveEposOffers,
	getAllEposOffersByLocation,
	deleteEposOffers,
	editEposOffers,
} from '../../../../../utilities/apiRequests/witmegWebNeurolageServerRequests';
import { useSelector, useDispatch } from 'react-redux';
import { sectionName as dashboardSectionName } from '../../../../../redux/slices/dashboard/dashboardConstants';
import moment from 'moment';
import { imagePlaceholder } from '../../../../../configs/constants';
// import { value } from "lodash/seq";

const { RangePicker } = DatePicker;

const OfferPage = (props) => {
	// console.log("props", props);
	const dashboardState = useSelector(
		(state) => state[dashboardSectionName].dashboard
	);
	const userState = useSelector((state) => state.user.userDetails);
	const [tableData, setTableData] = useState([]);
	const [loadingData, setLoadingData] = useState(false);
	const [basicInformationValues, setBasicInformationValues] =
		useState({});
	const [basicInfoValidationErrors, setbasicInfoValidationErrors] =
		useState({
			promotionType: false,
			offerName: false,
			offerPrice: false,
			offerQuantity: false,
			dateRange: false,
		});
	const [selectedRecord, setSelectedRecord] = useState({});
	const [missingBasicFieldNames, setMissingBasicFieldNames] =
		useState([]);
	const [showActionMenu, setShowActionMenu] = useState(false);
	const [
		actionMenuPopoverTriggerEvent,
		setActionMenuPopoverTriggerEvent,
	] = useState(null);
	const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] =
		useState(false);
	const [
		showDeleteConfirmPopupForMultipleOffers,
		setShowDeleteConfirmPopupForMultipleOffers,
	] = useState(false);
	const [enableEditMode, setEnableEditMode] = useState(false);
	const [selectedRowKey, setSelectedRowKey] = useState(null);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [currentStep, setCurrentStep] = useState(0);
	// const [goForward, setGoForward] = useState(false);
	const [showOfferOverviewPanel, setShowOfferOverviewPanel] =
		useState(false);
	const [selectedCard, setSelectedCard] = useState(null);
	const [validationMessage, setValidationMessage] = useState('');

	const validationRules = {
		firstStep: [
			{
				promotionType: true,
				message: 'Please select a offer type',
			},
		],
		secondStep: [
			'offerName',
			'offerPrice',
			'offerQuantity',
			'dateRange',
		],
		finalStep: [],
	};

	const handleShowCategoryActions = (event) => {
		setActionMenuPopoverTriggerEvent(event);
		setShowActionMenu(true);
	};

	const toggleActionMenu = (toggleActionMenu) => {
		setShowActionMenu(toggleActionMenu);
	};

	const [columnData, setColumnData] = useState([
		{
			title: '',
			dataIndex: '',
			key: 'rowSelector',
			render: (record) => (
				<Checkbox onChange={(e) => handleCheck(e, record)} />
			),
		},
		{
			title: 'Promotion Name',
			dataIndex: 'promotionName',
			key: 'promotionName',
		},
		{
			title: 'Promotion Type',
			dataIndex: 'promotionType',
			key: 'promotionType',
		},
		{
			title: 'Price',
			dataIndex: 'price',
			key: 'price',
		},
		{
			title: 'Start Date',
			dataIndex: 'startDate',
			key: 'startDate',
		},
		{
			title: 'End Date',
			dataIndex: 'endDate',
			key: 'endDate',
		},
		{
			title: 'Qty',
			dataIndex: 'quantity',
			key: 'quantity',
		},
		{
			title: 'Min Saving',
			dataIndex: 'minSaving',
			key: 'minSaving',
		},
		{
			title: 'Max Saving',
			dataIndex: 'maxSaving',
			key: 'maxSaving',
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (record) => (
				<Space size="middle">
					<Button
						category="icon"
						variant="link"
						children="eye"
						size="small"
						iconSize={16}
						onClick={() => {
							setShowOfferOverviewPanel(true);
							overviewOfTheOffer(record);
						}}
					></Button>
					<Button
						category="icon"
						variant="link"
						children="ellipsis-vertical"
						size="small"
						iconSize={16}
						onClick={(e) => {
							handleShowCategoryActions(e);
							setSelectedRecord(record);
						}}
						onMouseLeave={toggleActionMenu(false)}
					></Button>
				</Space>
			),
		},
	]);

	const [showCreateOfferPanel, setShowCreateOfferPanel] =
		useState(false);

	const hideCreateOfferPanel = () => {
		setShowCreateOfferPanel(false);
		setBasicInformationValues({
			customerDisplay: false,
			dateRange: [],
			offerPrice: '',
			offerName: '',
			offerQuantity: '',
			promotionType: '',
			itemsAdd: [],
		});
		setCurrentStep(0);
		setEnableEditMode(false);
	};

	const hideOfferOverviewPanel = () => {
		setShowOfferOverviewPanel(false);
	};

	const handleChangeOfferInfoInputs = (values, updatedField) => {
		// console.log("values, updatedField", values, updatedField, values[updatedField]);
		// console.log('validation', [updatedField], values[updatedField] === "" ? true : false);
		setBasicInformationValues(values);
		setbasicInfoValidationErrors({
			...basicInfoValidationErrors,
			[updatedField]:
				values[updatedField] === '' ? true : false,
		});
	};

	// const goNext = (value) => {
	// 	setGoForward(value);
	// }

	const setTheCard = (value) => {
		setSelectedCard(value);
	};

	const stepsData = [
		{
			title: 'Offer Type',
			content: (
				<OfferTypeForm
					handleChangeOfferInfoInputs={
						handleChangeOfferInfoInputs
					}
					basicInformationValues={basicInformationValues}
					basicInfoValidationErrors={
						basicInfoValidationErrors
					}
					// missingBasicFieldNames={missingBasicFieldNames}
					enableEditMode={enableEditMode}
					validationMessage={validationMessage}
					selectedCard={selectedCard}
					setTheCard={setTheCard}
				/>
			),
		},
		{
			title: 'Offer Info',
			content: (
				<OfferInfoForm
					handleChangeOfferInfoInputs={
						handleChangeOfferInfoInputs
					}
					basicInformationValues={basicInformationValues}
					basicInfoValidationErrors={
						basicInfoValidationErrors
					}
					missingBasicFieldNames={missingBasicFieldNames}
					enableEditMode={enableEditMode}
					LocationID={
						props.selectedLocation?.locationDetails
							.LocationID
					}
					validationMessage={validationMessage}
				/>
			),
		},
		{
			title: 'Offer Items',
			content: (
				// <AddItemsForm
				// 	handleChangeOfferInfoInputs={handleChangeOfferInfoInputs}
				// 	basicInformationValues={basicInformationValues}
				// 	basicInfoValidationErrors={basicInfoValidationErrors}
				// 	missingBasicFieldNames={missingBasicFieldNames}
				// 	dashboardState={dashboardState}
				// />

				<AddItemsForm
					basicInformationValues={basicInformationValues}
					offerItemsValues={[]}
					handleOfferItemsInputs={
						handleChangeOfferInfoInputs
					}
					missingOfferFieldNames={[]}
					enableEditMode={enableEditMode}
					validationMessage={validationMessage}
					// apiValidationError={[]}
				/>
			),
		},
	];

	const validate = (step) => {
		switch (step) {
			case 0:
				if (selectedCard) {
					setCurrentStep(step + 1);
					setValidationMessage('');
				} else {
					setCurrentStep(step);
					setValidationMessage(
						'Please select a offer type!'
					);
				}
				break;

			case 1:
				let message = [];
				!basicInformationValues.hasOwnProperty('offerName') &&
					!basicInformationValues.offerName &&
					message.push('offer name');
				!basicInformationValues.hasOwnProperty(
					'offerPrice'
				) &&
					!basicInformationValues.offerPrice &&
					message.push('offer price');
				!basicInformationValues.hasOwnProperty(
					'offerQuantity'
				) &&
					!basicInformationValues.offerQuantity &&
					message.push('offer quantity');
				basicInformationValues.dateRange.length == 0 &&
					message.push('and date range');

				if (message.length > 0) {
					setValidationMessage(
						`Please enter ${message.join(', ')}`
					);
					setCurrentStep(step);
				} else {
					setCurrentStep(step + 1);
					setValidationMessage('');
				}

				break;

			case 2:
				break;

			default:
				break;
		}
	};

	const onCompleteStep = () => {
		validate(currentStep);
	};

	useEffect(() => {
		selectedCard && setValidationMessage('');
	}, [selectedCard]);

	const onRedoStep = () => {
		setCurrentStep(currentStep - 1);
	};

	//Set data to feilds in the edit mode
	useEffect(() => {
		// console.log("edit selectedRecord", selectedRecord);
		if (enableEditMode && selectedRecord) {
			setBasicInformationValues({
				key: selectedRecord.key,
				id: selectedRecord.id,
				offerName: selectedRecord.promotionName,
				promotionType: selectedRecord.promotionType,
				offerPrice: selectedRecord.price,
				// startDate: offer.Offer.StartDate.slice(0, 10),
				// endDate: offer.Offer.EndDate.slice(0, 10),
				dateRange: [
					selectedRecord.startDate,
					selectedRecord.endDate,
				],
				offerQuantity: selectedRecord.quantity,
				// minSaving: offer.Offer.MinSavings,
				// maxSaving: offer.Offer.MaxSavings,
				offerImage: selectedRecord?.offerImage
					? selectedRecord.offerImage
					: '',
				OfferProducts: selectedRecord.OfferProducts,
			});
		}
	}, [enableEditMode, selectedRecord]);

	//Fetch Data Section
	useEffect(() => {
		// fetchData();
		let isMounted = false;

		!isMounted && fetchDataBasedOnLocation();
		return () => (isMounted = true);
	}, [props.selectedLocation]);

	const fetchData = async () => {
		setLoadingData(true);
		const data = await getAllEposOffers({
			OrgId: dashboardState.currentlySelectedOrgId,
		});

		if (data.isAdded && data.Message.length > 0) {
			const tableDataFromResult = await getTableDataFromResult(
				data.Message
			);
			setTableData(tableDataFromResult);
		}
		setLoadingData(false);
	};

	const fetchDataBasedOnLocation = async () => {
		setLoadingData(true);
		const data = await getAllEposOffersByLocation({
			SelectedLocation: props.selectedLocation.value,
		});
		console.log('Offers by Location', data);
		if (data.isAdded && data.Message.length > 0) {
			// console.log('data message', data);
			const tableDataFromResult = await getTableDataFromResult(
				data.Message
			);
			// console.log("tableDataFromResult", tableDataFromResult);
			setTableData(tableDataFromResult);
		} else {
			setTableData([]);
		}
		setLoadingData(false);
	};

	//map the fetched data
	const getTableDataFromResult = async (result) => {
		result = await sortResultsFromCreatedDate(result);
		console.log('result', result);
		return await result.map((offer, index) => {
			return {
				key: index,
				id: offer.ID,
				promotionName: offer.Offer.PromotionName,
				promotionType: offer.Offer.OfferTypeDesc,
				price: offer.Offer.Price,
				startDate: offer.Offer.StartDate.slice(0, 10),
				endDate: offer.Offer.EndDate.slice(0, 10),
				quantity: offer.Offer.PromotionQty,
				minSaving: offer.Offer.MinSavings,
				maxSaving: offer.Offer.MaxSavings,
				offerImage: offer.Offer.OfferImage
					? offer.Offer.OfferImage
					: imagePlaceholder,
				OfferProducts: offer.OfferProducts,
			};
		});
	};

	//sort the fetched data by the created date
	const sortResultsFromCreatedDate = (result) => {
		return result.sort((result1, result2) => {
			return (
				new Date(result2.CreatedDate) -
				new Date(result1.CreatedDate)
			);
		});
	};

	//delete an offer
	const deleteSelectedOffer = async () => {
		const apiReqBody = {
			CloudOfferIDs: [`${selectedRecord.id}`],
		};

		const res = await deleteEposOffers(apiReqBody);
		if (res.isDeleted === true) {
			Message.success(
				`Offer (${selectedRecord.promotionName}) is deleted successfully.`
			);
			fetchData();
		} else {
			Message.warning(`${res.Message}`);
		}
		// toggleActionMenu(true);
		hideOfferOverviewPanel();
		setShowDeleteConfirmPopup(false);
	};

	// Multiple offers Delete start
	useEffect(() => {
		if (selectedRowKey && selectedRowKey.checked) {
			setSelectedRowKeys([
				...selectedRowKeys,
				selectedRowKey.id,
			]);
		} else {
			setSelectedRowKeys(
				selectedRowKeys.filter((s) => s !== selectedRowKey.id)
			);
		}
	}, [selectedRowKey]);

	const handleCheck = async (e, record) => {
		setSelectedRowKey({
			id: record.id,
			checked: e.target.checked,
		});
	};

	const multipleDeleteOffers = async () => {
		const apiReqBody = {
			CloudOfferIDs: selectedRowKeys,
		};
		const res = await deleteEposOffers(apiReqBody);
		if (res.isDeleted === true) {
			Message.success(`Offers are deleted successfully.`);
			setSelectedRowKeys([]);
			setSelectedRowKey({});
			reRenderColumnData();
			setShowDeleteConfirmPopupForMultipleOffers(false);
			fetchDataBasedOnLocation();
		} else {
			Message.warning(`${res.Message}`);
		}
		// setSelectedRowKeys(selectedRowKeys.filter((s) => console.log("s", s)))
	};

	const reRenderColumnData = () => {
		setColumnData([
			{
				title: () => (
					<div>
						<Checkbox />
					</div>
				),
				key: 'rowSelector',
				render: (record) => (
					<Checkbox
						onChange={(e) => handleCheck(e, record)}
						checked={
							selectedRowKeys.findIndex(
								(x) => x === record.id
							) > -1
						}
					/>
				),
			},
			...columnData.filter((cd) => cd.key !== 'rowSelector'),
		]);
	};

	useEffect(() => {
		let isMounted = false;

		!isMounted && reRenderColumnData();
		return () => (isMounted = true);
	}, [selectedRowKeys]);

	const onCancelDeleteConfirmPopupForMultipleOffers = () => {
		setShowDeleteConfirmPopupForMultipleOffers(false);
	};
	// Multiple offers Delete end

	//overviewOfTheOffer
	const overviewOfTheOffer = (record) => {
		setSelectedRecord(record);
	};

	const viewEditOfferOverviewPanel = () => {
		hideOfferOverviewPanel();
		setShowCreateOfferPanel(true);
		toggleActionMenu(false);
		setEnableEditMode(true);
	};

	const saveOfferToTheDB = async () => {
		// console.log('before saving to DB: ', basicInformationValues);

		console.log('basicInformationValues', basicInformationValues);

		if (basicInformationValues.itemsAdd.length == 0) {
			setValidationMessage('Please add an item!');
			setCurrentStep(currentStep);
		} else {
			// setCurrentStep(0);
			setValidationMessage('');

			const reqBody = {
				Offer: {
					IsShowCustomerDisplay:
						basicInformationValues.customerDisplay
							? basicInformationValues.customerDisplay
							: false,
					OrgID: dashboardState?.currentlySelectedOrgId,
					OfferSummaryHtmlContent: '',
					StartDate: basicInformationValues.dateRange
						? moment(
								basicInformationValues.dateRange[0]
						  ).format('yyyy-MM-DD[T]HH:mm:ss.SSSZ')
						: '',
					EndDate: basicInformationValues.dateRange
						? moment(
								basicInformationValues.dateRange[1]
						  ).format('yyyy-MM-DD[T]HH:mm:ss.SSSZ')
						: '',
					Price: basicInformationValues.offerPrice
						? parseInt(basicInformationValues.offerPrice)
						: '',
					PromotionName: basicInformationValues.offerName
						? basicInformationValues.offerName
						: '',
					PromotionQty: basicInformationValues.offerQuantity
						? parseInt(
								basicInformationValues.offerQuantity
						  )
						: '',
					OfferType: basicInformationValues.promotionType
						? parseInt(
								basicInformationValues.promotionType
						  )
						: '',
					MinSavings: 0,
					MaxSavings: 0,
					CloudLocationID:
						dashboardState?.currentlySelectedLocationId,
					OfferImage: basicInformationValues.OfferImage
						? basicInformationValues.OfferImage[0]
						: '',
				},
				OfferProducts: basicInformationValues.itemsAdd
					? basicInformationValues.itemsAdd
					: [],
				// OfferProducts: basicInformationValues.itemsAdd
				//   ?  [{
				//     "Quantity": 1,
				//     "BarCode": "8906042151101",
				//     "IsDeleted": 0,
				//     "OrgID": dashboardState?.currentlySelectedOrgId,
				//     "CloudProductID": dashboardState?.currentlySelectedLocationId,
				//   }]
				//   : [],
			};

			const validationIssues = await validateBasicInfo();
			if (validationIssues.length > 0) {
				console.log('Validation Issues');
			} else {
				const data = await saveEposOffers(reqBody);
				// console.log('saved offer details', data);
				if (data.isAdded && data.Message.length > 0) {
					Message.success(`Offer created successfully.`);
					// console.log("basicInformationValues", basicInformationValues);
					setBasicInformationValues({
						customerDisplay: false,
						dateRange: [],
						offerPrice: '',
						offerName: '',
						offerQuantity: '',
						promotionType: '',
						itemsAdd: [],
					});
					setCurrentStep(0);
					setShowCreateOfferPanel(false);
					fetchDataBasedOnLocation();
				}
			}
		}

		// console.log("reqBody", reqBody);
	};

	const editOfferToTheDB = async () => {
		console.log('selectedRecord', selectedRecord);
		var localTime = moment().format('YYYY-MM-DD'); // store localTime
		var proposedDate = localTime + 'T00:00:00.000Z';
		const reqBody = {
			ID: selectedRecord.id,
			Offer: {
				ID: selectedRecord.id,
				IsShowCustomerDisplay: false,
				OrgID: dashboardState?.currentlySelectedOrgId,
				OfferSummaryHtmlContent: '',
				IsDeleted: 0,
				OfferPriority: 0,
				OfferID: 0,
				LocationId: 0,
				StartDate: basicInformationValues.dateRange
					? moment(
							basicInformationValues.dateRange[0]
					  ).format('yyyy-MM-DD[T]HH:mm:ss.SSSZ')
					: '',
				EndDate: basicInformationValues.dateRange
					? moment(
							basicInformationValues.dateRange[1]
					  ).format('yyyy-MM-DD[T]HH:mm:ss.SSSZ')
					: '',
				Price: basicInformationValues.offerPrice
					? parseInt(basicInformationValues.offerPrice)
					: '',
				CreatedDate: '2020-11-03T07:43:01.13Z',
				CreatedBy: 1,
				ModifiedDate: proposedDate,
				ModifiedBy: 1,
				IsExpired: false,
				OfferTypeDesc: 'Bundle Promotion',
				PromotionName: basicInformationValues.offerName
					? basicInformationValues.offerName
					: '',
				PromotionQty: basicInformationValues.offerQuantity
					? parseInt(basicInformationValues.offerQuantity)
					: '',
				OfferType: 1,
				MinSavings: 0,
				MaxSavings: 0,
				OfferImageID: 0,
				OfferSummary: '',
				MultiLanguageDescription: '',
				CloudLocationID:
					dashboardState?.currentlySelectedLocationId,
				CloudPromotionID: selectedRecord.id,
				OfferImage: basicInformationValues.offerImage,
			},
			OfferProducts: basicInformationValues.itemsAdd
				? basicInformationValues.itemsAdd
				: [],
			OrgID: dashboardState?.currentlySelectedOrgId,
		};

		console.log('reqBody', reqBody);
		const validationIssues = await validateBasicInfo();
		if (validationIssues.length > 0) {
			console.log('Validation Issues');
		} else {
			const data = await editEposOffers(reqBody);
			if (data.isAdded && data.Message.length > 0) {
				Message.success(`Offer edited successfully.`);
				setBasicInformationValues({
					customerDisplay: false,
					dateRange: [],
					offerPrice: '',
					offerName: '',
					offerQuantity: '',
					promotionType: '',
					itemsAdd: [],
				});
				setCurrentStep(0);
				setShowCreateOfferPanel(false);
				fetchDataBasedOnLocation();
			}
		}
	};

	// validation
	const validateBasicInfo = () => {
		const {
			promotionType,
			offerName,
			dateRange,
			offerPrice,
			offerQuantity,
		} = basicInformationValues;
		const newErrors = {};
		const missingBasicFieldNames = [];

		if (!promotionType || promotionType.trim() === '') {
			newErrors.promotionType = true;
			missingBasicFieldNames.push('Promotion Type');
		}
		if (!offerName || offerName.trim() === '') {
			newErrors.offerName = true;
			missingBasicFieldNames.push('Offer Name');
		}
		if (!dateRange || dateRange === '') {
			newErrors.dateRange = true;
			missingBasicFieldNames.push('Date Range');
		}
		if (
			!offerPrice
			// || offerPrice.trim() === ""
		) {
			newErrors.offerPrice = true;
			missingBasicFieldNames.push('Offer Price');
		}
		if (
			!offerQuantity
			// || offerQuantity.trim() === ""
		) {
			newErrors.offerQuantity = true;
			missingBasicFieldNames.push('Offer Quantity');
		}

		setbasicInfoValidationErrors(newErrors);
		setMissingBasicFieldNames(missingBasicFieldNames);

		return missingBasicFieldNames;
	};

	return (
		<div
			className="offer-page-content"
			style={{ padding: '20px' }}
		>
			<div className="offer-page-header">
				<Stack
					horizontalAlign="space_between"
					verticalAlign="v_top"
				>
					<Stack>
						<StackItem>
							<InputGroup
								leftComponent="select"
								leftWidth="50%"
								rightComponent="search"
							/>
						</StackItem>
						<StackItem style={{ marginRight: 20 }}>
							<Select placeholder="Price" />
						</StackItem>
						<StackItem style={{ marginRight: 20 }}>
							<RangePicker />
						</StackItem>
						<StackItem style={{ marginRight: 20 }}>
							<Button
								category="icon"
								variant="default"
								children="filter"
								size="medium"
								iconSize={20}
								className="eco-button--special"
								color="white"
							/>
						</StackItem>
						<StackItem
							className="eco-divider-l"
							style={{ marginRight: 20 }}
						>
							<Button
								category="text"
								children="Clear All"
							/>
						</StackItem>
					</Stack>

					{selectedRowKeys?.length > 0 && (
						<StackItem
							className="eco-mr-12"
							style={{ margin: 10 }}
						>
							<Button
								category="icon"
								variant="default"
								children="trashcan"
								size="small"
								iconSize={20}
								onClick={(e) => {
									setShowDeleteConfirmPopupForMultipleOffers(
										true
									);
								}}
							/>
						</StackItem>
					)}

					<Stack horizontalAlign="space_between">
						<StackItem>
							<Button
								category="icon"
								variant="primary"
								children="plus"
								size="small"
								iconSize={20}
								color="white"
								onClick={() =>
									setShowCreateOfferPanel(true)
								}
							/>
						</StackItem>
						<StackItem
							className="eco-mr-12 eco-ml-12"
							style={{ margin: 10 }}
						>
							<Button
								category="icon"
								variant="default"
								children="settings"
								size="small"
								iconSize={20}
							/>
						</StackItem>
					</Stack>
				</Stack>
			</div>
			<Popover
				position="bottom-right"
				popoverTriggerEvent={actionMenuPopoverTriggerEvent}
				active={showActionMenu}
				showHidePopover={toggleActionMenu}
				type="basic"
				customStyles={{ width: '128px' }}
			>
				<ActionList
					width={160}
					items={[
						{
							key: 1,
							prefix: <Icon name="pencil" size={16} />,
							content: 'Edit',
							onClick: () =>
								viewEditOfferOverviewPanel(),
						},
						{
							key: 2,
							prefix: (
								<Icon name="trashcan" size={16} />
							),
							content: 'Delete',
							onClick: () => {
								setShowDeleteConfirmPopup(true);
								toggleActionMenu(false);
							},
						},
						{
							key: 3,
							prefix: (
								<Icon name="trashcan" size={16} />
							),
							content: 'Audit Trail',
							onClick: () => alert('true'),
						},
					]}
					type="action"
				/>
			</Popover>
			<ConfirmationPopup
				modalVisible={showDeleteConfirmPopup}
				onSuccess={() => deleteSelectedOffer()}
				onCancel={() => setShowDeleteConfirmPopup(false)}
				confirmationMessage={`Are you sure you want to delete this Offer ?`}
				okText="Yes"
				cancelText="No"
			/>
			<ConfirmationPopup
				modalVisible={showDeleteConfirmPopupForMultipleOffers}
				onSuccess={() => multipleDeleteOffers()}
				onCancel={onCancelDeleteConfirmPopupForMultipleOffers}
				confirmationMessage={`Are you sure you want to delete this Offers ?`}
				okText="Yes"
				cancelText="No"
			/>
			<RightPanel
				active={showCreateOfferPanel}
				size="small"
				outsideClose={true}
				handleOnClose={hideCreateOfferPanel}
				headerContent={
					<RightPanelHeader
						// title= "Offer Create"
						title={
							enableEditMode
								? 'Offer Edit'
								: 'Offer Create'
						}
						rightContent={
							<Space>
								{currentStep !== 0 ? (
									<Button
										onClick={() => onRedoStep()}
									>
										Back
									</Button>
								) : (
									<></>
								)}
								{currentStep !==
								stepsData.length - 1 ? (
									<Button
										variant="primary"
										onClick={() =>
											onCompleteStep()
										}
									>
										Next
									</Button>
								) : enableEditMode ? (
									<Button
										variant="primary"
										onClick={() =>
											editOfferToTheDB()
										}
									>
										Update
									</Button>
								) : (
									<Button
										variant="primary"
										onClick={() =>
											saveOfferToTheDB()
										}
									>
										Save
									</Button>
								)}
							</Space>
						}
					/>
				}
			>
				<Space direction="vertical" style={{ width: '100%' }}>
					<div>
						{stepsData.length !== 0 ? (
							<Steps
								stepsData={stepsData}
								currentStep={currentStep}
								handleChangeOfferInfoInputs={
									handleChangeOfferInfoInputs
								}
								basicInformationValues={
									basicInformationValues
								}
							/>
						) : null}
					</div>
				</Space>
			</RightPanel>
			<RightPanel
				active={showOfferOverviewPanel}
				size="small"
				outsideClose={true}
				handleOnClose={hideOfferOverviewPanel}
				headerContent={
					<RightPanelHeader
						title="Offer Overview"
						rightContent={
							<Space>
								<Button
									onClick={() =>
										deleteSelectedOffer()
									}
								>
									Delete
								</Button>
								<Button
									variant="primary"
									onClick={() =>
										viewEditOfferOverviewPanel()
									}
								>
									Edit
								</Button>
							</Space>
						}
					/>
				}
			>
				<Space direction="vertical" style={{ width: '100%' }}>
					<OfferOverview selectedRecord={selectedRecord} />
				</Space>
			</RightPanel>

			<div className="offer-page-body">
				<Table
					columns={columnData}
					dataSource={tableData}
					loading={loadingData}
				/>
			</div>
		</div>
	);
};

export default OfferPage;
