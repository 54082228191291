import { EcouiProvider } from 'ecologital-ui-library';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import App from './App/App';
import { reduxStore } from './redux/reduxStore';
// import reportWebVitals from './reportWebVitals';
import { extendConsoleLog } from './utilities/mix';
import routerHistory from './utilities/routerHistory';

import 'normalize.css';
import 'ecologital-ui-library/dist/ecologitalUILibrary.css';
import '../node_modules/witmeg-ui-system/dist/styles.css';
import '../node_modules/witmeg-reporting-embed/dist/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/swiper-bundle.min.css';

import './styles/overrides/allOverrides.css';

import './index.css';

extendConsoleLog();

ReactDOM.render(
	<React.StrictMode>
		<ReduxProvider store={reduxStore}>
			<Router history={routerHistory}>
				<div>
					{/* <EcouiProvider appSettings={{ disableAppZoom: true }}>
					<ToastContainer
						position='top-right'
						autoClose={2000}
						hideProgressBar
						newestOnTop
						closeOnClick
						pauseOnFocusLoss
						draggable={false}
						closeButton={false}
						pauseOnHover
					/>

					<App />
				</EcouiProvider> */}
					<App />
					<ToastContainer
						position='top-right'
						autoClose={2000}
						hideProgressBar
						newestOnTop
						closeOnClick
						pauseOnFocusLoss
						draggable={false}
						closeButton={false}
						pauseOnHover
					/>
				</div>
			</Router>
		</ReduxProvider>
	</React.StrictMode>,
	document.querySelector('#root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
