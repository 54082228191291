import {
	MailOutlined as MailOutlinedIcon,
	MessageOutlined as MessageOutlinedIcon,
	WhatsAppOutlined as WhatsAppOutlinedIcon,
	PayCircleOutlined as PayCircleOutlinedIcon,
	CopyOutlined as CopyOutlinedIcon,
	DisconnectOutlined as DisconnectOutlinedIcon,
} from '@ant-design/icons';
import { Divider, Descriptions, Select } from 'antd';
import lodash from 'lodash';
import React, { Component } from 'react';

import {
	customerStatusTypesList,
	tierTypesList,
} from '../../../CustomerMasterView/helpers/placeholderData';
import ProductOverviewSection from '../../../ProductOverview/helperComponents/ProductOverviewSection/ProductOverviewSection';
import { openLink } from '../../helpers/helperFunctions';

import './CusOveGeneralSection.css';

// <Divider/>
const antdDividerDefaultProps = {
	orientation: 'left',
	style: {
		color: '#1e1f23b0',
		fontSize: '0.8rem',
		fontWeight: '500',
		marginTop: '30px',
	},
};

// <Descriptions/>
const antdDescriptionDefaultProps = {
	bordered: true,
	column: 1,
	size: 'small',
	layout: 'horizontal',
	labelStyle: {
		width: '200px',
		textTransform: 'uppercase',
		fontSize: 'small',
	},
};

export default class CusOveGeneralSection extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedAddressIndex: 0,
		};
	}

	// Parsing/Formatting/Organizing data that needed to shown in this component.
	generateHelperFunctionsAndDataToGetNecessaryInfo() {
		const { selectedAddressIndex, salesRepsList } = this.state;
		const { parserFunctions, rawCustomerData } = this.props;

		const { getCompanyName, getCreditPeriodWithDuration } = parserFunctions;

		const { ID } = rawCustomerData;

		// *********************** Helper Function for Helper Functions. 😊 ***********************
		// Generating default Subject, Body, Etc... values to be used with Email/Sms/Etc.. Senders.
		const generateDefaultExternalMsgParts = () => {
			return {
				subject: 'Web Neurolage',
				body: `Hello ${rawCustomerData.FirstName} ${rawCustomerData.LastName},`,
			};
		};

		// *********************** Specific helper functions to perform task with relevant data. ***********************
		const openEmailLink = () => {
			const defaultMsgData = generateDefaultExternalMsgParts();

			const toEmailAddress = rawCustomerData.Email;
			const emailSubject = defaultMsgData.subject;
			const emailBody = defaultMsgData.body;

			const mailtoLink = `mailto:${toEmailAddress}?subject=${encodeURIComponent(
				emailSubject,
			)}&body=${encodeURIComponent(emailBody)}`;

			openLink(mailtoLink);
		};

		const openSmsLink = () => {
			const defaultMsgData = generateDefaultExternalMsgParts();

			const toTelNumber = `+${rawCustomerData.Contact.Phone}`;
			const smsBody = defaultMsgData.body;

			const smsLink = `sms:${toTelNumber}?body=${encodeURIComponent(smsBody)}`;

			openLink(smsLink);
		};

		const openWhatsappLink = () => {
			const defaultMsgData = generateDefaultExternalMsgParts();

			const toTelNumber = rawCustomerData.Contact.Phone;
			const msgBody = defaultMsgData.body;

			const msgLink = `https://wa.me/${toTelNumber}?text=${encodeURIComponent(
				msgBody,
			)}`;

			openLink(msgLink);
		};

		// *********************** Specific helper functions to get/format relevant data. ***********************
		// Getting & Formatting Credit Limit with Thousands Separator.
		const getCreditLimit = () => {
			const rawCreditLimit = rawCustomerData.Customer.CreditLimit;
			const numericCreditLimit = Number(rawCreditLimit);

			if (!lodash.isNumber(numericCreditLimit)) {
				return '';
			} else {
				return numericCreditLimit
					.toString()
					.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
			}
		};

		// Defining what shown in TopMost Address section.
		const getHeaderAddress = () => {
			const city = rawCustomerData.Contact.City;
			const postCode = rawCustomerData.Contact.PostCode;

			return `${city}${postCode ? `, ${postCode}` : ''}`;
		};

		// Getting relevant address data obj, depending on selected address set.
		const getSelectedAddressData = () => {
			const addressList = rawCustomerData.Addresses || [];

			return addressList[selectedAddressIndex] || {};
		};

		const getCustomerStatusData = () => {
			const currentStatusTypeName = lodash.get(
				rawCustomerData,
				'Customer.CustomerStatus',
			);

			const currentStatusTypeData =
				customerStatusTypesList.find((type) => {
					return type.value === currentStatusTypeName;
				}) || {};

			return {
				...currentStatusTypeData,
				label: currentStatusTypeData.label,
				icon: currentStatusTypeData.icon,
			};
		};

		const getTierType = () => {
			const currentTierTypeValue = lodash.get(rawCustomerData, 'Customer.Tier');

			const currentTierTypeData =
				tierTypesList.find((type) => {
					return type.value === currentTierTypeValue;
				}) || {};

			return currentTierTypeData.label || '';
		};

		return {
			helperFns: {
				openEmailLink,
				openSmsLink,
				openWhatsappLink,
				getCustomerStatusData,
				getTierType,
			},
			helperData: {
				customerId: ID,

				companyName: getCompanyName(),
				headerAddress: getHeaderAddress(),
				creditLimit: getCreditLimit(),
				creditPeriod: getCreditPeriodWithDuration(),
				selectedAddressData: getSelectedAddressData(),

				raw: rawCustomerData, // This is addeed because, When raw data can be used directly without any special parsing/formatting wherever possible.
			},
		};
	}

	render() {
		const { selectedAddressIndex } = this.state;
		const { currentViewportData } = this.props;

		const { helperData, helperFns } =
			this.generateHelperFunctionsAndDataToGetNecessaryInfo();

		const { currentBreakpoint } = currentViewportData;

		const antdDescriptionDynamicProps = {
			...antdDescriptionDefaultProps,
			layout: currentBreakpoint === 'xs' ? 'vertical' : 'horizontal', // Changing Layout in smaller screens.
		};

		return (
			<ProductOverviewSection className='CusOveGeneralSection' title='GENERAL'>
				<div className='CusOveGeneralSection_____contentWrapper'>
					<div className='CusOveGeneralSection__mainInfo'>
						<div className='CusOveGeneralSection__mainInfo_____top'>
							<div className='CusOveGeneralSection__mainInfo__image'>
								<img src='/static/images/companyBuildingIcon.png' alt='' />
							</div>

							<div className='CusOveGeneralSection__mainInfo__genInfo'>
								<div className='CusOveGeneralSection__mainInfo__genInfo__title'>
									{helperData.companyName || 'N/A'}
								</div>
								<div>{helperData.headerAddress}</div>
								<div>{helperData.raw.Contact.CountryName}</div>
								<div className='CusOveGeneralSection__mainInfo__genInfo__accNum'>
									{helperData.raw.Customer.AccountNumber}
								</div>
							</div>

							<div className='CusOveGeneralSection__mainInfo__statusInfo'>
								<div className='CusOveGeneralSection__mainInfo__statusInfo__header'>
									STATUS
								</div>

								<div className='CusOveGeneralSection__mainInfo__statusInfo__icon'>
									{helperFns.getCustomerStatusData().icon || (
										<DisconnectOutlinedIcon />
									)}
								</div>

								<div className='CusOveGeneralSection__mainInfo__statusInfo__subTitle'>
									{helperFns.getCustomerStatusData().label || 'N/A'}
								</div>
							</div>
						</div>

						<div className='CusOveGeneralSection__mainInfo_____bottom'>
							<div className='CusOveGeneralSection__mainInfo__creditInfo'>
								<div>
									<span>CREDIT LIMIT: </span> {helperData.creditLimit || 'N/A'}
								</div>

								<div>
									<span>CREDIT PERIOD : </span>{' '}
									{helperData.creditPeriod || 'N/A'}
								</div>
							</div>
						</div>
					</div>

					<Divider />

					<div className='CusOveGeneralSection__contactActions'>
						<div
							className='CusOveGeneralSection__contactActions__action'
							onClick={() => {
								helperFns.openEmailLink();
							}}>
							<div className='CusOveGeneralSection__contactActions__action__icon'>
								<MailOutlinedIcon style={{ color: '#374866' }} />
							</div>

							<div className='CusOveGeneralSection__contactActions__action__desc'>
								EMAIL
							</div>
						</div>

						<div
							className='CusOveGeneralSection__contactActions__action'
							onClick={() => {
								helperFns.openSmsLink();
							}}>
							<div className='CusOveGeneralSection__contactActions__action__icon'>
								<MessageOutlinedIcon style={{ color: '#2EA90F' }} />
							</div>

							<div className='CusOveGeneralSection__contactActions__action__desc'>
								SMS
							</div>
						</div>

						<div
							className='CusOveGeneralSection__contactActions__action'
							onClick={() => {
								helperFns.openWhatsappLink();
							}}>
							<div className='CusOveGeneralSection__contactActions__action__icon'>
								<WhatsAppOutlinedIcon style={{ color: '#20D665' }} />
							</div>

							<div className='CusOveGeneralSection__contactActions__action__desc'>
								WHATSAPP
							</div>
						</div>

						<div className='CusOveGeneralSection__contactActions__action'>
							<div className='CusOveGeneralSection__contactActions__action__icon'>
								<PayCircleOutlinedIcon style={{ color: '#D71522' }} />
							</div>

							<div className='CusOveGeneralSection__contactActions__action__desc'>
								PAY
							</div>
						</div>

						<div className='CusOveGeneralSection__contactActions__action'>
							<div className='CusOveGeneralSection__contactActions__action__icon'>
								<CopyOutlinedIcon style={{ color: '#076D37' }} />
							</div>

							<div className='CusOveGeneralSection__contactActions__action__desc'>
								INVOICE
							</div>
						</div>
					</div>

					<Divider {...antdDividerDefaultProps}>ADDRESSES</Divider>

					<div className='CusOveGeneralSection__addressInfo'>
						<div className='CusOveGeneralSection__addressInfo__addressTypeSelector'>
							<Select
								style={{
									width: '100%',
								}}
								value={selectedAddressIndex}
								options={helperData.raw.Addresses.map((address, index) => {
									return {
										value: index,
										label: address.AddressType || `Address ${index}`,
									};
								})}
								onChange={(selectedValue) => {
									this.setState({
										selectedAddressIndex: selectedValue,
									});
								}}
							/>
						</div>

						<Descriptions {...antdDescriptionDynamicProps}>
							<Descriptions.Item label='Address Line 1'>
								{helperData.selectedAddressData.AddressLine1 || '-'}
							</Descriptions.Item>
							<Descriptions.Item label='Address Line 2'>
								{helperData.selectedAddressData.AddressLine2 || '-'}
							</Descriptions.Item>
							<Descriptions.Item label='City/Town'>
								{helperData.selectedAddressData.City || '-'}
							</Descriptions.Item>
							<Descriptions.Item label='Post Code'>
								{helperData.selectedAddressData.PostZipCode || '-'}
							</Descriptions.Item>
							<Descriptions.Item label='Country'>
								{helperData.selectedAddressData.Country || '-'}
							</Descriptions.Item>
						</Descriptions>
					</div>

					<Divider {...antdDividerDefaultProps}>CONTACTS</Divider>

					<div className='CusOveGeneralSection__contactInfo'>
						<div className='CusOveGeneralSection__contactInfo__contactTypeSelector'>
							<Select
								style={{
									width: '100%',
								}}
								// NOTE : Currently, Data received from backend don't have multiple "Contact". So for now we just imitate single select for UI experience.
								value='Main'
								options={['Main'].map((type, index) => {
									return {
										value: index,
										label: type,
									};
								})}
							/>
						</div>

						<Descriptions {...antdDescriptionDynamicProps}>
							<Descriptions.Item label='Contact Name'>
								{helperData.raw.Contact.ContactPerson.Name || '-'}
							</Descriptions.Item>
							<Descriptions.Item label='Contact Number'>
								{helperData.raw.Contact.ContactPerson.ContactNumber || '-'}
							</Descriptions.Item>
							<Descriptions.Item label='Email'>
								{helperData.raw.Contact.Email || '-'}
							</Descriptions.Item>
							<Descriptions.Item label='Mobile'>
								{helperData.raw.Contact.Phone || '-'}
							</Descriptions.Item>
						</Descriptions>
					</div>

					<Divider {...antdDividerDefaultProps}>OTHER INFO</Divider>

					<div className='CusOveGeneralSection__additionalInfo'>
						<Descriptions {...antdDescriptionDynamicProps}>
							<Descriptions.Item label='VAT Number'>
								{helperData.raw.CustomerVatNo || '-'}
							</Descriptions.Item>
							<Descriptions.Item label='Member No.'>
								{helperData.raw.Customer.MemberNumber || '-'}
							</Descriptions.Item>
							<Descriptions.Item label='Auto Email Proforma'>
								{helperData.raw.Customer.IsEmailProforma ? 'Yes' : 'No'}
							</Descriptions.Item>
							<Descriptions.Item label='Auto Email Invoice'>
								{helperData.raw.Customer.IsEmailInvoice ? 'Yes' : 'No'}
							</Descriptions.Item>
							<Descriptions.Item label='VAT Exempted'>
								{helperData.raw.Customer.Isvatexemption ? 'Yes' : 'No'}
							</Descriptions.Item>
							<Descriptions.Item label='Bulk Discount'>
								{`${helperData.raw.Customer.BulkDiscount} %` || '-'}
							</Descriptions.Item>
							<Descriptions.Item label='Payment Method'>
								{helperData.raw.DefaultPaymentType || '-'}
							</Descriptions.Item>
							<Descriptions.Item label='Tier'>
								{helperFns.getTierType() || '-'}
							</Descriptions.Item>
							<Descriptions.Item label='Sales Rep'>
								{helperData.raw.Customer.SalesRepName || '-'}
							</Descriptions.Item>
						</Descriptions>
					</div>
				</div>
			</ProductOverviewSection>
		);
	}
}
