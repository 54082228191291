import lodash from 'lodash';

import { generateGenericRawProductImagesKeyDataParserFunctions } from '../../ProductMasterView/helpers/otherHelpers';

// Function to generate "Set of Helper Functions" that can easily extract data from "Product List" related API response's object. (For Ex. Response from "/v1/epos/product/barcode" Endpoint.)
export const generateProductDataObjParserFunctions = (data = {}) => {
	const {
		productDataObj = {}, // NOTE : This function assume, This is a specific "Individual" product data portion received from relevant API Endpoint.

		// These are used to extract relevant data set where applicable. (For. Ex. "Inventories" key have for multiple locations. So we extract only current location data.)
		selectedOrgId = '',
		selectedLocationId = '',
	} = data;

	const {
		CloudCostPricesArray = [],
		CloudSellingPricesArray = [],
		UnitChart = {},
		Inventories = [],
		OnLineImages = {},
	} = productDataObj;

	// *********************** Functions that extract particular KEY's whole data set that matched for given Location, Org, Etc...  ***********************
	const getSellingPriceDataSet = () => {
		// Handling value being 'null' error case.
		if (!CloudSellingPricesArray) {
			return {};
		}

		return (
			CloudSellingPricesArray.find((item) => {
				return item.CloudLocationID === selectedLocationId;
			}) || {}
		);
	};

	const getCostPriceDataSet = () => {
		// Handling value being 'null' error case.
		if (!CloudCostPricesArray) {
			return {};
		}

		return (
			CloudCostPricesArray.find((item) => {
				return item.CloudLocationID === selectedLocationId;
			}) || {}
		);
	};

	const getInventoryDataSet = () => {
		const InventoriesArray = Inventories;

		const relevantOrgEntry =
			InventoriesArray.find((item) => {
				return item.OrgID === selectedOrgId;
			}) || {};

		// Check entry for currentlySelectedOrg is available if that the case dig more to get nested value.
		if (relevantOrgEntry && relevantOrgEntry.QuantityList) {
			// Some API EndPoint seems to send "QuantityList" not as an list of array.
			// 		- So until it it resolved on backend, adding temporary checks to avoid errors.

			// When received as Array
			if (Array.isArray(relevantOrgEntry.QuantityList)) {
				// Getting and returning Relevant LocationEntry.
				return (
					relevantOrgEntry.QuantityList.find((item) => {
						return item.CloudLocationID === selectedLocationId;
					}) || {}
				);
			}

			// When received as Object
			if (
				lodash.isObject(relevantOrgEntry.QuantityList) &&
				relevantOrgEntry.QuantityList.CloudLocationID === selectedLocationId
			) {
				return relevantOrgEntry.QuantityList;
			}

			return '';
		}

		return '';
	};

	// *********************** Function that extract/generate custom data for specific needs. ***********************
	const getSellingUnitType = () => {
		const sellingDataSet = getSellingPriceDataSet();

		if (!sellingDataSet.CloudUnitChartID) {
			return 'EA';
		} else {
			return UnitChart.UnitTypeName;
		}
	};

	const getCostUnitType = () => {
		const costDataSet = getCostPriceDataSet();

		if (!costDataSet.CloudUnitChartID) {
			return 'EA';
		} else {
			return UnitChart.UnitTypeName;
		}
	};

	const getInventoryUnitType = () => {
		if (!UnitChart.UnitTypeName) {
			return 'EA';
		} else {
			return UnitChart.UnitTypeName;
		}
	};

	// *********************** Function that extract/generate custom functions for specific needs. ***********************
	function generateImagesRelatedHelperFunctions() {
		// Generate multiple function to parse data in "OnLineImages" key.

		const {
			getPrimaryImageListAsArray,
			getSpecialImageListAsArray,
			getUnitChartImageListAsArray,
		} = generateGenericRawProductImagesKeyDataParserFunctions();

		return {
			getPrimaryImageListAsArray: () =>
				getPrimaryImageListAsArray(OnLineImages.PrimaryImages),
			getSpecialImageListAsArray: () =>
				getSpecialImageListAsArray(OnLineImages.SpecialImages),
			getUnitChartImageListAsArray: () =>
				getUnitChartImageListAsArray(OnLineImages.UnitChartImages),
		};
	}

	return {
		getSellingPriceDataSet,
		getCostPriceDataSet,
		getInventoryDataSet,

		getSellingUnitType,
		getCostUnitType,
		getInventoryUnitType,

		generateImagesRelatedHelperFunctions,
	};
};
