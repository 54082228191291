const {
	REACT_APP__WEBSITE__BASEURL,
	REACT_APP_CURRENT_ENVIRONMENT,

	REACT_APP__WITMEG_ORGSERVER___PRIVATE_KEY,
	REACT_APP__WITMEG_ORGSERVER___ISSUER,
	REACT_APP__WITMEG_ORGSERVER___AUDIENCE,
	REACT_APP__WITMEG_ORGSERVER___BASEURL,

	REACT_APP__WITMEG_APPSERVER___PRIVATE_KEY,
	REACT_APP__WITMEG_APPSERVER___ISSUER,
	REACT_APP__WITMEG_APPSERVER___AUDIENCE,
	REACT_APP__WITMEG_APPSERVER___BASEURL,

	REACT_APP__WITMEG_EMAILSERVER___PRIVATE_KEY,
	REACT_APP__WITMEG_EMAILSERVER___ISSUER,
	REACT_APP__WITMEG_EMAILSERVER___AUDIENCE,
	REACT_APP__WITMEG_EMAILSERVER___BASEURL,
	REACT_APP__WITMEG_EMAILSERVER___DEFAULT_EMAIL_FROM_NAME,
	REACT_APP__WITMEG_EMAILSERVER___DEFAULT_EMAIL_FROM_ADDRESS,

	REACT_APP__WITMEG_WEBNEUROLAGESERVER___PRIVATE_KEY,
	REACT_APP__WITMEG_WEBNEUROLAGESERVER___ISSUER,
	REACT_APP__WITMEG_WEBNEUROLAGESERVER___AUDIENCE,
	REACT_APP__WITMEG_WEBNEUROLAGESERVER___BASEURL,

	NODE_ENV,

	REACT_APP__WITMEG_ECOSERVICESSERVER___PRIVATE_KEY,
	REACT_APP__WITMEG_ECOSERVICESSERVER___ISSUER,
	REACT_APP__WITMEG_ECOSERVICESSERVER___AUDIENCE,
	REACT_APP__WITMEG_ECOSERVICESSERVER___BASEURL,

	REACT_APP__WITMEG_IMAGEUPLOADSERVER___BASEURL,
	REACT_APP__WITMEG__LOOKUP__BASEURL,

	REACT_APP_REPORTING_BASE_URL,
	REACT_APP_TOKEN_SERVICE_BASE_URL
} = process.env;

const globalValues = {
	credentials: {
		WITMEG_ORGSERVER___PRIVATE_KEY: REACT_APP__WITMEG_ORGSERVER___PRIVATE_KEY,
		WITMEG_ORGSERVER___ISSUER: REACT_APP__WITMEG_ORGSERVER___ISSUER,
		WITMEG_ORGSERVER___AUDIENCE: REACT_APP__WITMEG_ORGSERVER___AUDIENCE,

		WITMEG_APPSERVER___PRIVATE_KEY: REACT_APP__WITMEG_APPSERVER___PRIVATE_KEY,
		WITMEG_APPSERVER___ISSUER: REACT_APP__WITMEG_APPSERVER___ISSUER,
		WITMEG_APPSERVER___AUDIENCE: REACT_APP__WITMEG_APPSERVER___AUDIENCE,

		WITMEG_EMAILSERVER___PRIVATE_KEY:
			REACT_APP__WITMEG_EMAILSERVER___PRIVATE_KEY,
		WITMEG_EMAILSERVER___ISSUER: REACT_APP__WITMEG_EMAILSERVER___ISSUER,
		WITMEG_EMAILSERVER___AUDIENCE: REACT_APP__WITMEG_EMAILSERVER___AUDIENCE,
		WITMEG_EMAILSERVER___DEFAULT_EMAIL_FROM_NAME:
			REACT_APP__WITMEG_EMAILSERVER___DEFAULT_EMAIL_FROM_NAME,
		WITMEG_EMAILSERVER___DEFAULT_EMAIL_FROM_ADDRESS:
			REACT_APP__WITMEG_EMAILSERVER___DEFAULT_EMAIL_FROM_ADDRESS,

		WITMEG_WEBNEUROLAGESERVER___PRIVATE_KEY:
			REACT_APP__WITMEG_WEBNEUROLAGESERVER___PRIVATE_KEY,
		WITMEG_WEBNEUROLAGESERVER___ISSUER:
			REACT_APP__WITMEG_WEBNEUROLAGESERVER___ISSUER,
		WITMEG_WEBNEUROLAGESERVER___AUDIENCE:
			REACT_APP__WITMEG_WEBNEUROLAGESERVER___AUDIENCE,

		WITMEG_ECOSERVICESSERVER___PRIVATE_KEY:
			REACT_APP__WITMEG_ECOSERVICESSERVER___PRIVATE_KEY,
		WITMEG_ECOSERVICESSERVER___ISSUER:
			REACT_APP__WITMEG_ECOSERVICESSERVER___ISSUER,
		WITMEG_ECOSERVICESSERVER___AUDIENCE:
			REACT_APP__WITMEG_ECOSERVICESSERVER___AUDIENCE,
	},

	baseURLS: {
		WEBSITE__BASEURL: REACT_APP__WEBSITE__BASEURL || window.location.origin,
		WITMEG_ORGSERVER___BASEURL: REACT_APP__WITMEG_ORGSERVER___BASEURL,
		WITMEG_APPSERVER___BASEURL: REACT_APP__WITMEG_APPSERVER___BASEURL,
		WITMEG_EMAILSERVER___BASEURL: REACT_APP__WITMEG_EMAILSERVER___BASEURL,
		WITMEG_WEBNEUROLAGESERVER___BASEURL:
			REACT_APP__WITMEG_WEBNEUROLAGESERVER___BASEURL,
		WITMEG_ECOSERVICESSERVER___BASEURL:
			REACT_APP__WITMEG_ECOSERVICESSERVER___BASEURL,
		WITMEG_REPORTING_BASE_URL: REACT_APP_REPORTING_BASE_URL,
		REACT_APP__WITMEG_IMAGEUPLOADSERVER___BASEURL: REACT_APP__WITMEG_IMAGEUPLOADSERVER___BASEURL,
		REACT_APP__WITMEG__LOOKUP__BASEURL: REACT_APP__WITMEG__LOOKUP__BASEURL,
		REACT_APP_TOKEN_SERVICE_BASE_URL: REACT_APP_TOKEN_SERVICE_BASE_URL
	},

	environment: {
		CURRENT_ENVIRONMENT: REACT_APP_CURRENT_ENVIRONMENT,
		IS_DEVELOPMENT: NODE_ENV === 'development',
	},
};

export default globalValues;

// *****************************************************************
// Simple function to notify if critical env values are not passed.
function checkAllCriticalEnvValuesAvailable() {
	const criticalEnvValueList = [
		REACT_APP__WITMEG_ORGSERVER___PRIVATE_KEY,
		REACT_APP__WITMEG_ORGSERVER___ISSUER,
		REACT_APP__WITMEG_ORGSERVER___AUDIENCE,
		REACT_APP__WITMEG_ORGSERVER___BASEURL,

		REACT_APP__WITMEG_APPSERVER___PRIVATE_KEY,
		REACT_APP__WITMEG_APPSERVER___ISSUER,
		REACT_APP__WITMEG_APPSERVER___AUDIENCE,
		REACT_APP__WITMEG_APPSERVER___BASEURL,

		REACT_APP__WITMEG_EMAILSERVER___PRIVATE_KEY,
		REACT_APP__WITMEG_EMAILSERVER___ISSUER,
		REACT_APP__WITMEG_EMAILSERVER___AUDIENCE,
		REACT_APP__WITMEG_EMAILSERVER___BASEURL,

		REACT_APP__WITMEG_WEBNEUROLAGESERVER___PRIVATE_KEY,
		REACT_APP__WITMEG_WEBNEUROLAGESERVER___ISSUER,
		REACT_APP__WITMEG_WEBNEUROLAGESERVER___AUDIENCE,
		REACT_APP__WITMEG_WEBNEUROLAGESERVER___BASEURL,
	];

	const isAllCriticalEnvValuesAvailable = criticalEnvValueList.every(
		(envValue) => envValue,
	);

	if (!isAllCriticalEnvValuesAvailable) {
		console.error('NOTE : SOME CRITICAL ENV VALUES ARE MISSING');
	}
}
checkAllCriticalEnvValuesAvailable();
