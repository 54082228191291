import lodash from 'lodash';

import { getFormSectionsPartialObjPaths } from './parserHelpers';

// NOTE : In here we have master function for each Tab (Master, Custom, Images, ....) which generate API endpoint's relevant data keys that related to specific tabs form data.

export function createMasterTabFormRelatedApiData(data = {}) {
	const {
		currentlySelectedOrgId,
		currentlySelectedLocationId,
		userId,

		isEditMode = false,
		rawProductData = {}, // Existing Product Data Received From API.

		parserFunctions = {},
	} = data;
	const { getFormValueByPath, masterTabParserFns } = parserFunctions;

	const {
		itemInfoObjPath,
		sellingInfoObjPath,
		purchaseInfoObjPath,
		stockInfoObjPath,
	} = getFormSectionsPartialObjPaths().masterTab;

	function generateCategoryAndBrandRelatedApiData() {
		const selectedCategoryData = masterTabParserFns.getSelectedCategoryData();
		const selectedCategory = selectedCategoryData.selectedCategoryObj;
		const selectedSubCategory =
			masterTabParserFns.getSelectedSubCategoryData().selectedSubCategoryObj;
		const selectedBrand =
			masterTabParserFns.getSelectedBrandData().selectedBrandObj;

		return {
			CloudProductCategoryID: selectedCategory.ID,
			CloudProductSubCategoryID: selectedSubCategory.ID,
			CloudProductBrandID: selectedBrand.ID,

			ProductCategoryID: selectedCategory.ProductCategoryID,
			ProductSubCategoryID: selectedSubCategory.ProductSubcategoryID,
			BrandID: selectedBrand.BrandID,

			CloudProductCategory: {
				ProductCategory: {
					ID: selectedCategory.ID,
					ProductCategoryID: selectedCategory.ProductCategoryID,
					Category: selectedCategory.Category,
					ProductDescription: selectedCategory.ProductDescription,
					VatRate: Number(getFormValueByPath(`${itemInfoObjPath}vat`)),
				},
				ProductSubCategory: {
					ID: selectedSubCategory.ID,
					ProductSubcategoryID: selectedSubCategory.ProductSubcategoryID,
					CategoryID: selectedSubCategory.CategoryID,
					CloudProductCategoryID: selectedSubCategory.CloudProductCategoryID,
					SubcategoryDescription: selectedSubCategory.SubcategoryDescription,
					Name: selectedSubCategory.Name,
				},
			},

			CloudProductBrand: {
				ID: selectedBrand.ID,
				BrandID: selectedBrand.BrandID,
				BrandName: selectedBrand.BrandName,
				Description: selectedBrand.Description,
			},
		};
	}

	function generateSellingAndPurchaseRelatedApiData() {
		const baseUnitType =
			masterTabParserFns.getSelectedBaseUnitTypeData().selectedBaseUnitTypeObj;

		const additionalUnitTypeData =
			masterTabParserFns.getSelectedAdditionalUnitTypeData();
		const additionalUnitType =
			additionalUnitTypeData.selectedAdditionalUnitTypeObj;
		const additionalUnitTypeQuantity =
			additionalUnitTypeData.selectedAdditionalUnitTypeQuantity;

		const sellingUnitType =
			masterTabParserFns.getSelectedSellingUnitTypeData()
				.selectedSellingUnitTypeObj;

		const purchaseUnitType =
			masterTabParserFns.getSelectedPurchaseUnitTypeData()
				.selectedPurchaseUnitTypeObj;

		const getUnitChartId = (isBaseUnit) => {
			if (isEditMode) {
				if (isBaseUnit) {
					return '';
				} else {
					return rawProductData.UnitChart.ID;
				}
			} else {
				// NOTE: On 'ADD" mode, A UnitChart will be automatically created on backend, And it's ID (CloudUnitChartID) will be assigned in appropriate places by backend. (Ex. CloudCostPricesArray, CloudSellingPricesArray, UnitChart, ...)
				return undefined;
			}
		};

		return {
			CloudCostPricesArray: [
				{
					CloudUnitTypeID: purchaseUnitType.ID,
					CloudBaseUnitTypeID: baseUnitType.ID,
					CloudLocationID: currentlySelectedLocationId,

					CloudCostprice: getFormValueByPath(
						`${purchaseInfoObjPath}purchaseCost`,
					),

					CloudUnitChartID: getUnitChartId(purchaseUnitType.IsBaseUnit),
				},
			],

			CloudSellingPricesArray: [
				{
					CloudSalesUnitTypeID: sellingUnitType.ID,
					CloudBaseUnitTypeID: baseUnitType.ID,
					CloudLocationID: currentlySelectedLocationId,

					CloudSellingprice: getFormValueByPath(
						`${sellingInfoObjPath}sellingPrice`,
					),

					CloudUnitChartID: getUnitChartId(sellingUnitType.IsBaseUnit),
				},
			],

			UnitChart: {
				OrgID: currentlySelectedOrgId,

				UnitTypeID: additionalUnitType.UnitTypeId,
				BaseUnitTypeID: baseUnitType.UnitTypeId,
				CloudUnitTypeID: additionalUnitType.ID,
				CloudBaseUnitTypeID: baseUnitType.ID,

				UnitTypeName: additionalUnitType.UnitTypeName,
				BaseunitTypeName: baseUnitType.UnitTypeName,
				Quantity: additionalUnitTypeQuantity,

				CloudCreatedBy: userId,
				CloudModifiedBy: userId,

				IsDeleted: false,
				CreatedDate: new Date().toISOString(),
				ModifiedDate: new Date().toISOString(),

				// Important Note : Don't Provide these. Otherwise we get "Error in Deserializing EPOS Product" error from backend.
				// CreatedBy: userId,
				// ModifiedBy: userId,
			},
		};
	}

	function generateInitialStockRelatedApiData() {
		const additionalUnitTypeQuantity =
			masterTabParserFns.getSelectedAdditionalUnitTypeData()
				.selectedAdditionalUnitTypeQuantity;
		const initialStockUnitType =
			masterTabParserFns.getSelectedInitialStockUnitTypeData()
				.selectedInitialStockUnitTypeObj;
		const minOrderLevelUnitType =
			masterTabParserFns.getSelectedMinOrderLevelUnitTypeData()
				.selectedMinOrderLevelUnitTypeObj;
		const maxOrderLevelUnitType =
			masterTabParserFns.getSelectedMaxOrderLevelUnitTypeData()
				.selectedMaxOrderLevelUnitTypeObj;

		const initialStockEnteredValue =
			getFormValueByPath(`${stockInfoObjPath}initialStock`) || 0;
		const minOrderLevelEnteredValue =
			getFormValueByPath(`${stockInfoObjPath}minOrderLevel`) || 0;
		const maxOrderLevelEnteredValue =
			getFormValueByPath(`${stockInfoObjPath}maxOrderLevel`) || 0;

		return {
			OpeningStock: initialStockUnitType.IsBaseUnit
				? initialStockEnteredValue
				: initialStockEnteredValue * additionalUnitTypeQuantity,
			ReorderLevel: minOrderLevelUnitType.IsBaseUnit
				? minOrderLevelEnteredValue
				: minOrderLevelEnteredValue * additionalUnitTypeQuantity,
			MaxOnlineOrderQty: maxOrderLevelUnitType.IsBaseUnit
				? maxOrderLevelEnteredValue
				: maxOrderLevelEnteredValue * additionalUnitTypeQuantity || 0,
			IsOutOfStock: false,
		};
	}

	function generateOtherMiscellaneousApiData() {
		const isWeightableSelected = getFormValueByPath(
			`${itemInfoObjPath}weightable`,
		);

		const weightValue = getFormValueByPath(`${itemInfoObjPath}weight`);
		const weightTypeValue = getFormValueByPath(`${itemInfoObjPath}weightType`);

		return {
			HasWeight: isWeightableSelected,
			Weight:
				weightValue && weightTypeValue
					? `${weightValue}${weightTypeValue}`
					: '',
		};
	}

	// Heres the all the data (For Master Tab) we are sending to API Endpoint.
	return {
		OrgID: currentlySelectedOrgId,
		CloudSelectedLocation: currentlySelectedLocationId,

		CloudCreatedBy: userId,
		CloudModifiedBy: userId,

		Name: getFormValueByPath(`${itemInfoObjPath}productName`),
		BarCode: getFormValueByPath(`${itemInfoObjPath}barcode`),
		ProductRefNo: getFormValueByPath(`${itemInfoObjPath}itemCode`),

		IsActive: getFormValueByPath(`${itemInfoObjPath}active`),
		IsOnline: getFormValueByPath(`${itemInfoObjPath}sellOnline`),

		...generateCategoryAndBrandRelatedApiData(),
		...generateSellingAndPurchaseRelatedApiData(),
		...generateInitialStockRelatedApiData(),
		...generateOtherMiscellaneousApiData(),
	};
}

export function createCustomTabFormRelatedApiData(data = {}) {
	const { parserFunctions = {} } = data;
	const { getFormValueByPath } = parserFunctions;

	function generateCustomInfoRelatedApiData() {
		const allCustomTypesData = getFormValueByPath(
			'customSection.customInformation',
		);

		// Removing Keys that don't have value.
		const allCustomTypesData__nonEmpty = lodash.pickBy(
			allCustomTypesData,
			lodash.identity,
		);

		return {
			CustomArray: { ...allCustomTypesData__nonEmpty },
		};
	}

	// Heres the all the data (For Custom Tab) we are sending to API Endpoint.
	return {
		...generateCustomInfoRelatedApiData(),
	};
}

export function createImagesTabFormRelatedApiData(data = {}) {
	const { parserFunctions = {} } = data;
	const { getFormValueByPath } = parserFunctions;

	const { currentImagesInfoObjPath } =
		getFormSectionsPartialObjPaths().imagesTab;

	function generatePrimaryImageListRelatedApiData() {
		const imageListAsObj = {};

		const primaryImageList = getFormValueByPath(
			`${currentImagesInfoObjPath}primaryImageList`,
		);

		primaryImageList.forEach((img) => {
			imageListAsObj[img.imgKeyTitle] = img.imgUrl;
		});

		return imageListAsObj;
	}

	function generateUnitChartImageListRelatedApiData() {
		const imageListAsObj = {};

		const unitChartImageList = getFormValueByPath(
			`${currentImagesInfoObjPath}unitChartImageList`,
		);

		unitChartImageList.forEach((img) => {
			imageListAsObj[img.imgKeyTitle] = img.imgUrl;
		});

		return imageListAsObj;
	}

	function generateSpecialImageListRelatedApiData() {
		const imageListAsObj = {};

		const specialImageList = getFormValueByPath(
			`${currentImagesInfoObjPath}specialImageList`,
		);

		specialImageList.forEach((img) => {
			imageListAsObj[img.imgKeyTitle] = [
				{ Value: img.customValue, ImgURL: img.imgUrl },
			];
		});

		return imageListAsObj;
	}

	// Heres the all the data (For Custom Tab) we are sending to API Endpoint.
	return {
		OnLineImages: {
			PrimaryImages: generatePrimaryImageListRelatedApiData(),
			UnitChartImages: generateUnitChartImageListRelatedApiData(),
			SpecialImages: generateSpecialImageListRelatedApiData(),
		},
	};
}
