import { DatePicker, Form, Input, Select } from 'formik-antd';
import React from 'react';

// Helper function to Generate relevant input type component for given customType. (Types applicable in "CustomArray" key in Raw Product Data.)
// This method is directly relevant for "PmvCustomTab" and some parts of "PmvImagesTab".
export function generateRelevantCustomPropertyInput(
	customType = {},
	name = '',
	otherOptions = {},
) {
	const { formItemProps = {} } = otherOptions;

	return (
		<Form.Item
			name={name}
			label={customType.label}
			{...formItemProps}
			key={name}>
			{customType.inputType === 'input' && <Input name={name} />}

			{customType.inputType === 'select' && (
				<Select
					name={name}
					options={customType.options.map((item) => {
						return {
							label: item,
							value: item,
						};
					})}
				/>
			)}

			{customType.inputType === 'date' && (
				<DatePicker style={{ width: '100%' }} name={name} />
			)}
		</Form.Item>
	);
}
