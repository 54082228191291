/* eslint-disable no-param-reassign */

import { reqStatusTypes } from '../../helpers/constants';

import {
	setReportList,
} from './reportsSliceExtraActions';

const extraReducers = {

	// [setReportList.pending]: (state) => {
	// 	state.reportsStatus = reqStatusTypes.pending;
	// 	state.reportsError = {};
	// },

	// [setReportList.fulfilled]: (state, action) => {
	// 	state.reportsStatus = reqStatusTypes.succeeded;
	// 	state.reports = action.payload;
	// 	state.reportsError = {};
	// },

	// [setReportList.rejected]: (state, action) => {
	// 	state.reportsStatus = reqStatusTypes.failed;
	// 	state.reportsError = action.payload;
	// 	state.reports = null;
	// },
	[setReportList]: (state, action) => {
		state.reportsStatus = reqStatusTypes.succeeded;
		state.reports = action.payload;
		state.reportsError = {};
	},
};

export default extraReducers;
