/* eslint-disable react/sort-comp */
/* eslint-disable no-shadow */

import { bindActionCreators } from '@reduxjs/toolkit';
import { Alert, Button, Tabs } from 'antd';
import { LoaderView } from 'ecologital-ui-library';
import { Formik } from 'formik';
import lodash from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

import { reqStatusTypes } from '../../../redux/helpers/constants';
import { sectionName as dashboardSectionName } from '../../../redux/slices/dashboard/dashboardConstants';
import { actions as dashboardSliceActions } from '../../../redux/slices/dashboard/dashboardSlice/dashboardSlice';
import { actions as proformasPageSliceActions } from '../../../redux/slices/dashboard/proformasPageSlice/proformasPageSlice';
import { routePaths } from '../../../routes/routeConstants';
import { errorLogger } from '../../../utilities/errorHandlers';
import routerHistory from '../../../utilities/routerHistory';
import { getLoggedUserId } from '../../../utilities/userAuthentication';
import ConditionalTabRenderer from '../ProductMasterView/helperComponents/ConditionalTabRenderer';

import { createMasterTabFormRelatedApiData } from './helpers/apiDataCreators';
import {
	generateFullBaseInitialState,
	generateDynamicInitialValueGetterFunctions,
	fullValidationScheme,
	mapApiProformaDataToFormikFormStructure,
} from './helpers/formikHelperData';
import { generateFomikFormValuesObjParserFunctions } from './helpers/parserHelpers';
import PromvItemsTab from './subSections/PromvItemsTab/PromvItemsTab';
import PromvMasterTab from './subSections/PromvMasterTab/PromvMasterTab';

import './ProformaMasterView.css';
import './ProformaMasterViewUtility.css';

const { TabPane } = Tabs;

class ProformaMasterView extends Component {
	constructor(props) {
		super(props);

		const { proformasPageSliceState } = this.props;
		const { proformaCrudRelatedData } = proformasPageSliceState;

		const { proformaId: editingProformaId } =
			proformaCrudRelatedData.selectedItemData;
		const isEditMode = proformaCrudRelatedData.currentCrudType === 'EDIT';

		this.state = {
			activeTabKey: '1', // Currently Showing Tab.
			isEditMode,
			editingProformaId,

			formikInitialState: {},

			// "Fetching Initial Page Data", related state.
			pageInitialDataReqStatus: reqStatusTypes.idle,
			pageInitialDataReqError: '',

			rawProformaData: {}, // Proforma's Raw API data. (Especially used when opened in "EDIT" mode)
		};
	}

	async fetchNecessaryInitialPageData() {
		try {
			const { isEditMode, editingProformaId } = this.state;
			const { dashboardSliceState } = this.props;

			const { currentlySelectedOrgId, currentlySelectedLocationId } =
				dashboardSliceState;

			// These will update below in certain cases. (Especially in "EDIT" mode)
			let rawProformaData = {};
			let preFilledFormikInitialValues = {};
			let formikInitialValueGetterFunctions = {};

			this.setState({
				pageInitialDataReqStatus: reqStatusTypes.pending,
				pageInitialDataReqError: '',
			});

			// *** Below are pre-fetching data that needed for some specific parts in forms.
			// Ex.
			// const salesRepsList = await getSalesRepsListByOrgId({
			// 	orgId: currentlySelectedOrgId,
			// });

			// *** When in "EDIT" Mode.
			if (isEditMode) {
				// Getting Editing Proforma's Initial Raw Data.
				// Ex:
				// const proformaDataResponse = await getEposCustomersByCloudCustomerId({
				// 	orgId: currentlySelectedOrgId,
				// 	locationId: currentlySelectedLocationId,
				// 	searchKey: editingProformaId,
				// });

				// rawProformaData = proformaDataResponse.Result[0];
				rawProformaData = {};

				// Mapping "Raw Proforma Values" to "Formik Value Structure", So we can pass them as formik initial values.
				preFilledFormikInitialValues = mapApiProformaDataToFormikFormStructure({
					proformaData: rawProformaData,
				});
			}

			// Generating functions set, to be used as dynamic/customized initial value getters for needed keys.
			formikInitialValueGetterFunctions =
				generateDynamicInitialValueGetterFunctions({});

			this.setState(
				{
					pageInitialDataReqStatus: reqStatusTypes.succeeded,
					pageInitialDataReqError: '',

					rawProformaData,
				},
				() => {
					// NOTE : Re-Setting React State here because, "generateFullBaseInitialState()" may depend on some values from state we define in above.
					this.setState({
						formikInitialState: generateFullBaseInitialState({
							preFilledInitialValues: preFilledFormikInitialValues,
							dynamicInitialValueGetters: formikInitialValueGetterFunctions,
						}),
					});
				},
			);
		} catch (error) {
			errorLogger(error);
			this.setState({
				pageInitialDataReqStatus: reqStatusTypes.failed,
				pageInitialDataReqError: 'Error occurred while fetching initial data.',
			});
		}
	}

	// This get the all the form values in every form of every tab.
	// So in here we parse all those values and send necessary data to Add/Edit a proforma.
	async handleProformaAddEditFormSubmitting(
		allFormValuesInEveryTab,
		formikProps,
	) {
		try {
			const { isEditMode, editingProformaId, rawProformaData } = this.state;

			const { dashboardSliceState, proformasPageSliceActions } = this.props;

			const { currentlySelectedOrgId } = dashboardSliceState;
			const { resetProformaCrudRelatedData } = proformasPageSliceActions;

			const parserFunctions = generateFomikFormValuesObjParserFunctions(
				allFormValuesInEveryTab,
				{},
			);

			const userId = getLoggedUserId();

			const commonArgumentsForApiDataCreatorFunctions = {
				userId,
				isEditMode,
				parserFunctions,
			};

			const masterTabFormRelatedApiData = createMasterTabFormRelatedApiData(
				commonArgumentsForApiDataCreatorFunctions,
			);

			const fullAddEposProformaApiReqBody = {
				...masterTabFormRelatedApiData,
			};

			if (isEditMode) {
				const fullUpdateEposProformaApiReqBody = lodash.mergeWith(
					rawProformaData, // Existing Customer Data.
					fullAddEposProformaApiReqBody,
					// eslint-disable-next-line consistent-return
					(objValue, srcValue, key) => {
						// Making below mentioned keys are not merged with existing values.
						const ignoreMergeKeys = [];
						if (ignoreMergeKeys.includes(key)) {
							return srcValue;
						}
					},
				);

				console.log('Response', fullUpdateEposProformaApiReqBody);

				// await updateEposCustomer({
				// 	ID: editingProformaId,
				// 	...fullUpdateEposProformaApiReqBody,
				// });
			} else {
				console.log('Response', fullAddEposProformaApiReqBody);
				// await addEposCustomer(fullAddEposProformaApiReqBody);
			}

			toast.success(`Proforma ${isEditMode ? 'Updated' : 'Added'}.`);
			formikProps.setStatus({
				error: '',
			});
			formikProps.setSubmitting(false);

			// Resetting related redux's existing data and redirecting.
			resetProformaCrudRelatedData();
			routerHistory.push(routePaths.dashboard__sales__proformas__search);
		} catch (error) {
			const errMsg =
				error.customErrMsg ||
				'Error occurred while Creating/Editing the proforma.';
			errorLogger(error);
			toast.error(errMsg);
			formikProps.setStatus({
				error: errMsg,
			});
			formikProps.setSubmitting(false);
		}
	}

	getEachTabsMetaData(options = {}) {
		const { formikProps } = options;

		const tabMetaData = {
			masterTab: {},
		};

		const tabsList = [
			{
				tabMetaKey: 'masterTab',
				formikKey: 'masterSection',
			},
		];

		tabsList.forEach((tab) => {
			const tabErrors = lodash.get(formikProps.errors, tab.formikKey);
			const tabTouched = lodash.get(formikProps.touched, tab.formikKey);

			if (lodash.isEmpty(tabErrors)) {
				tabMetaData[tab.tabMetaKey].isErrors = false;
			} else {
				tabMetaData[tab.tabMetaKey].isErrors = true;
			}

			if (lodash.isEmpty(tabTouched)) {
				tabMetaData[tab.tabMetaKey].isTouched = false;
			} else {
				tabMetaData[tab.tabMetaKey].isTouched = true;
			}

			if (
				tabMetaData[tab.tabMetaKey].isErrors &&
				tabMetaData[tab.tabMetaKey].isTouched
			) {
				tabMetaData[tab.tabMetaKey].showErrors = true;
			} else {
				tabMetaData[tab.tabMetaKey].showErrors = false;
			}
		});

		return tabMetaData;
	}

	componentDidMount() {
		this.fetchNecessaryInitialPageData();
	}

	componentWillUnmount() {
		const { proformasPageSliceActions } = this.props;
		const { resetProformaCrudRelatedData } = proformasPageSliceActions;

		// Making related redux data is resetted when un-mounting. This is done to make sure when this component mounted again it doesn't show previous data.
		resetProformaCrudRelatedData();
	}

	render() {
		const {
			activeTabKey,

			formikInitialState,

			pageInitialDataReqStatus,
			pageInitialDataReqError,
		} = this.state;

		const { dashboardSliceState, proformasPageSliceState } = this.props;

		const { currentlySelectedOrgId, currentlySelectedLocationId } =
			dashboardSliceState;

		const { proformaCrudRelatedData } = proformasPageSliceState;

		const isMainPageActionsRunning =
			pageInitialDataReqStatus === reqStatusTypes.pending;
		const isMainPageActionsError =
			pageInitialDataReqStatus !== reqStatusTypes.pending &&
			pageInitialDataReqError;

		// NOTE : This component handle both ADD  & EDIT. So in here we check in "EDIT" mode existing item data is available. If not we are redirecting.
		// 				Example Scenario - User Directly going to edit URL "/sales/proformas/edit"
		if (
			proformaCrudRelatedData.currentCrudType === 'EDIT' &&
			lodash.isEmpty(proformaCrudRelatedData.selectedItemData)
		) {
			return <Redirect to={routePaths.dashboard__sales__proformas__search} />;
		}

		if (isMainPageActionsRunning || isMainPageActionsError) {
			return (
				<LoaderView
					className='ProformaMasterView'
					isLoading={isMainPageActionsRunning}
					isError={isMainPageActionsError}
					typeConfigs={{
						error: {
							extra: (
								<Button
									type='primary'
									onClick={() => {
										this.fetchNecessaryInitialPageData();
									}}>
									TRY AGAIN
								</Button>
							),
						},
					}}
				/>
			);
		}

		return (
			<div className='ProformaMasterView'>
				<Formik
					initialStatus={{}}
					initialValues={formikInitialState}
					enableReinitialize
					validationSchema={fullValidationScheme}
					onSubmit={(allFormValuesInEveryTab, formikProps) => {
						this.handleProformaAddEditFormSubmitting(
							allFormValuesInEveryTab,
							formikProps,
						);
					}}
					validateOnChange={false} // As a Performance Improvement (But Note that there could be some "Validation Error Showing" caveats due to this.)
				>
					{(formikProps) => {
						const parserFunctions = generateFomikFormValuesObjParserFunctions(
							formikProps.values,
							{},
						);

						const commonPropsForTabCompParts = {
							selectedOrgId: currentlySelectedOrgId,
							selectedLocationId: currentlySelectedLocationId,

							formikProps,
							parserFunctions,
						};

						const tabsMetaData = this.getEachTabsMetaData({ formikProps });

						return (
							<div>
								<Tabs
									defaultActiveKey='1'
									type='card'
									size='small'
									tabBarGutter={2}
									animated={{ inkBar: false, tabPane: false }}
									onChange={(activeTabKey) => {
										this.setState({
											activeTabKey,
										});
									}}
									renderTabBar={() => null} // Hiding Tab Selection.
								>
									<TabPane
										tab={
											<div>
												Master
												{tabsMetaData.masterTab.showErrors ? ' *' : ''}
											</div>
										}
										key='1'>
										<ConditionalTabRenderer
											activeTabKey={activeTabKey}
											thisTabKey='1'>
											<PromvMasterTab {...commonPropsForTabCompParts} />

											{/* NOTE : Previously ItemsTab suppose to be on its own tab.
																 But due to UI/UX decision made later this is moved to here to shown along with Main/Master Tab Data.
											*/}
											<PromvItemsTab {...commonPropsForTabCompParts} />
										</ConditionalTabRenderer>
									</TabPane>
								</Tabs>

								<div className='ProformaMasterView__footer'>
									{formikProps.status.error && (
										<div className='ProformaMasterView__footer__errorViewer'>
											<Alert
												message={formikProps.status.error}
												type='error'
												showIcon
											/>
										</div>
									)}

									<div className='ProformaMasterView__footer__buttonList'>
										<Button
											type='primary'
											loading={formikProps.isSubmitting}
											onClick={() => {
												formikProps.handleSubmit();
											}}>
											SAVE
										</Button>

										<Button
											disabled={formikProps.isSubmitting}
											onClick={() => {
												routerHistory.push(
													routePaths.dashboard__sales__proformas__search,
												);
											}}>
											CANCEL
										</Button>
									</div>
								</div>
							</div>
						);
					}}
				</Formik>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	dashboardSliceState: state[dashboardSectionName].dashboard,
	proformasPageSliceState: state[dashboardSectionName].proformasPage,
});

const mapDispatchToProps = (dispatch) => {
	const boundDashboardSliceActions = bindActionCreators(
		dashboardSliceActions,
		dispatch,
	);

	const boundProformasPageSliceActions = bindActionCreators(
		proformasPageSliceActions,
		dispatch,
	);

	return {
		dashboardSliceActions: boundDashboardSliceActions,
		proformasPageSliceActions: boundProformasPageSliceActions,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProformaMasterView);
