import lodash from 'lodash';

// This generate string obj paths, which can used to access values from Formik Value Obj.
export function getFormSectionsPartialObjPaths() {
	return {
		masterTab: {
			baseInfoObjPath: 'masterSection.baseInformation.',
			addressInfoObjPath: 'masterSection.addressInformation.',
			settingsInfoObjPath: 'masterSection.settingsInformation.',
		},
	};
}

// This function  generate multiple functions that can easily extract data from Formik Values Object.
export function generateFomikFormValuesObjParserFunctions(
	formikAllFormValuesInEveryTabObj = {},
	otherData = {},
) {
	const getFormValueByPath = (path) =>
		lodash.get(formikAllFormValuesInEveryTabObj, path);

	const setFormValueByPath = (path, value) => {
		/**
		 * NOTE :
		 * 	- This function should be used very carefully and only use in specific edge cases.
		 * 	- This is similar to "formikProps.setFieldValue". But there is very definitive difference. That this MUTATE "formikProps.values" directly and Re-Rendering don't occur with changes.
		 * 	- Also note that this function update only update provided path value if that path already exist. This condition is must to avoid some errors that very hard to debug.
		 */

		if (lodash.has(formikAllFormValuesInEveryTabObj, path)) {
			lodash.set(formikAllFormValuesInEveryTabObj, path, value);
		}
	};

	const { salesRepsList } = otherData;

	function masterTabRelatedFunctions() {
		// Object Paths for Sub Sections of Master Tab's Form.
		const { settingsInfoObjPath } = getFormSectionsPartialObjPaths().masterTab;

		const getSelectedSalesRepData = () => {
			const selectedSalesRepIndex = getFormValueByPath(
				`${settingsInfoObjPath}salesRep`,
			);
			const isSalesRepSelected = Number.isInteger(selectedSalesRepIndex);
			const selectedSalesRep = isSalesRepSelected
				? salesRepsList[selectedSalesRepIndex]
				: {};

			return {
				isSelected: isSalesRepSelected,
				selectedIndex: selectedSalesRepIndex,
				selectedSalesRepObj: selectedSalesRep,
			};
		};

		return { getSelectedSalesRepData };
	}

	return {
		getFormValueByPath,
		setFormValueByPath,
		masterTabParserFns: masterTabRelatedFunctions(),
	};
}
