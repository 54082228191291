import { reqStatusTypes } from '../../../helpers/constants';
import { sectionName } from '../dashboardConstants';

const sliceName = 'proformasPage';
const currentStatePath = `${sectionName}.${sliceName}`;

const sliceConstants = {
	name: sliceName,
	parentSectionName: sectionName,
	currentStatePath,
	initialState: {
		// ******************************* invokeProformasPageResetter() *******************************
		shouldInvokeProformasPageResetter: false,

		// ******************************* setProformaCrudRelatedData() *******************************
		proformaCrudRelatedData: {
			currentCrudType: 'ADD',
			selectedItemData: {}, // Currently, Only applicable on EDIT mode. For example to pass editing Customer's ID.
			additionalInfo: {},
		},
	},
};

export default sliceConstants;
