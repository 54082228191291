import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import LoginForm from './forms/LoginForm/LoginForm';

import './LoginPage.css';
import logo from "../../assets/img/logo.png"
import LogoWithText from "../../assets/img/logo-with-text.png"
import { Stack,StackItem,Typography }from 'witmeg-ui-system';

class LoginPage extends Component {
	render() {
		const { history, location } = this.props;
		const { state } = location;

		const reactRouterState = state || {};

		return (
			<Stack className='login-page' horizontalAlign='h_center'>
				<Stack className="login-page-container" direction="row">
					<StackItem className='login-page-image-container'>
						<Stack className='login-page-image-container-content'>
							<StackItem className='eco-mt-32'>
								<img src={LogoWithText} />
							</StackItem>
							<StackItem className='eco-mt-16'>
								<Typography type='title' level={3} pattern='bold' alignment='left' color='white_base'>The POS <br />Powerhouse</Typography>
							</StackItem>
							<StackItem className='eco-mt-16'>
								<Typography size='small' pattern='bold' alignment='left' color='white_base'>Get started Neurolage.</Typography>
							</StackItem>
						</Stack>
					</StackItem>
					<StackItem className='login-page-form-container'>
						<Stack className='login-page-form' direction='column' horizontalAlign='h_start'>
							<StackItem>
								<img src={logo} />
							</StackItem>
							<StackItem className='login-form-elements-mt'>
								<Typography type='title' level={3} pattern='bold' alignment='left'>Sign In</Typography>
							</StackItem>
							<StackItem className='login-form-elements-mt'>
								<Typography size='small' pattern='bold' alignment='left'>Just sign in if you have an account here.Enjoy our tool</Typography>
							</StackItem>
							<div className="login-form-elements-mt">
								<LoginForm history={history} reactRouterState={reactRouterState} />								
							</div>	
							<StackItem className='login-form-elements-mt'>
								<Typography size='small' alignment='left'>
									By creating your account you agree with our <a className='links'>Terms and Conditions</a> and <a className='links'>Privacy Policy</a>
								</Typography>
							</StackItem>
							<StackItem className='login-form-elements-mt'>
								<Typography size='small' alignment='left'>
									Don’t have an account? <Typography size='small' pattern='bold' alignment='left'>Sign up</Typography>
								</Typography>
							</StackItem>
						</Stack>
					</StackItem>
				</Stack>		

			</Stack>
			
		);
	}
}

export default withRouter(LoginPage);
