import {
	// For Generic Type
	getEposProductCountByProductName,
	getEposProductCountByCloudProductCategoryID,
	getEposProductCountByCloudProductBrandId,
	getEposProductCountThatMinOrderLevelReached,
	getEposProductCountThatMaxOrderLevelReached,
	getEposProductsByCloudProductName,
	getEposProductsByCloudProductCategoryId,
	getEposProductsByCloudProductBrandId,
	getEposProductByBarcode,
	getEposProductByProductRefNo,
	getEposProductThatMinOrderLevelReached,
	getEposProductThatMaxOrderLevelReached,
	// For Proforma Type
	getProductListCountForProformaCreation,
	getProductListForProformaCreation,
} from '../../../../utilities/apiRequests/witmegWebNeurolageServerRequests';

/**
 * NOTE : This contains multiple function to handle product fetching (With appropriate set of endpoints), depending on, for which component we are getting products data.
 *   Ex. :
 *      - Product List for ProductsPage (GenericType)   ---> "/v1/epos/products/pagination/productname", Etc...
 *      - Product List for ProformaPage (ProformaType)  ---> "/v1/epos/proforma/productlist", Etc...
 *      - ......
 */

export async function productFetchingHandlerForGenericType(data = {}) {
	const {
		searchType,
		searchTypeList,
		isSearchFromPagination,
		commonReqParams,
		totalProductItems,

		searchTerm,
		categoryTypeId,
		brandTypeId,
		stockViewTypeId,
	} = data;

	// Theses data will updated accordingly, depending on searchType.
	const returnResult = {
		rawSearchResult: [],
		countOfTotalProductItemsForSearch: 0,
	};

	switch (searchType) {
		case searchTypeList.byProductName.key: {
			let totalItemsForProductName = 0;

			if (isSearchFromPagination) {
				// This "if' logic is just to avoid unnecessary fetching.
				totalItemsForProductName = totalProductItems;
			} else {
				totalItemsForProductName = await getEposProductCountByProductName({
					...commonReqParams,
					productName: searchTerm,
				});
			}

			const byProductNameResult = await getEposProductsByCloudProductName({
				...commonReqParams,
				productName: searchTerm,
			});

			returnResult.rawSearchResult = byProductNameResult;
			returnResult.countOfTotalProductItemsForSearch = totalItemsForProductName;
			break;
		}

		case searchTypeList.byCategoryId.key: {
			let totalItemsInCategory = 0;

			if (isSearchFromPagination) {
				// This "if' logic is just to avoid unnecessary fetching.
				totalItemsInCategory = totalProductItems;
			} else {
				totalItemsInCategory =
					await getEposProductCountByCloudProductCategoryID({
						...commonReqParams,
						productCategoryId: categoryTypeId,
					});
			}

			const byCategoryIdResult = await getEposProductsByCloudProductCategoryId({
				...commonReqParams,
				productCategoryId: categoryTypeId,
			});

			returnResult.rawSearchResult = byCategoryIdResult;
			returnResult.countOfTotalProductItemsForSearch = totalItemsInCategory;
			break;
		}

		case searchTypeList.byBrandId.key: {
			let totalItemsInBrand = 0;

			if (isSearchFromPagination) {
				// This "if' logic is just to avoid unnecessary fetching.
				totalItemsInBrand = totalProductItems;
			} else {
				totalItemsInBrand = await getEposProductCountByCloudProductBrandId({
					...commonReqParams,
					productBrandId: brandTypeId,
				});
			}

			const byBrandIdResult = await getEposProductsByCloudProductBrandId({
				...commonReqParams,
				productBrandId: brandTypeId,
			});

			returnResult.rawSearchResult = byBrandIdResult;
			returnResult.countOfTotalProductItemsForSearch = totalItemsInBrand;
			break;
		}

		case searchTypeList.byStockView.key: {
			let relevantStockApiFn = () => Promise.resolve({ Result: [] });
			let relevantStockCountApiFn = () => Promise.resolve({ Result: 0 });

			// Checking which API Endpoint to used depend on what sub stock type selected by user.
			if (stockViewTypeId === 'minOrderReached') {
				relevantStockApiFn = getEposProductThatMinOrderLevelReached;
				relevantStockCountApiFn = getEposProductCountThatMinOrderLevelReached;
			} else if (stockViewTypeId === 'maxOrderReached') {
				relevantStockApiFn = getEposProductThatMaxOrderLevelReached;
				relevantStockCountApiFn = getEposProductCountThatMaxOrderLevelReached;
			}

			let totalItemsInStockType = 0;
			if (isSearchFromPagination) {
				// This "if' logic is just to avoid unnecessary fetching.
				totalItemsInStockType = totalProductItems;
			} else {
				totalItemsInStockType = await relevantStockCountApiFn({
					...commonReqParams,
				});
			}


			const byStockViewResult = await relevantStockApiFn({
				...commonReqParams,
			});

			returnResult.rawSearchResult = byStockViewResult;
			returnResult.countOfTotalProductItemsForSearch = totalItemsInStockType;
			break;
		}

		case searchTypeList.byBarcode.key: {
			const byBarcodeResult = await getEposProductByBarcode({
				...commonReqParams,
				barcode: searchTerm,
			});

			returnResult.rawSearchResult = byBarcodeResult;
			break;
		}

		case searchTypeList.byProductRefNo.key: {
			const byProductRefNoResult = await getEposProductByProductRefNo({
				...commonReqParams,
				productRefNo: searchTerm,
			});

			returnResult.rawSearchResult = byProductRefNoResult;
			break;
		}

		default: {
			break;
		}
	}

	return returnResult;
}

export async function productFetchingHandlerForProformaType(data = {}) {
	const {
		searchType,
		searchTypeList,
		isSearchFromPagination,
		commonReqParams,
		totalProductItems,

		searchTerm,
		categoryTypeId,
		brandTypeId,
		stockViewTypeId,
	} = data;

	const returnResult = {
		rawSearchResult: [],
		countOfTotalProductItemsForSearch: 0,
	};

	const searchTypeMapping = {
		[searchTypeList.byProductName.key]: 'Name',
		[searchTypeList.byCategoryId.key]: 'Category',
		[searchTypeList.byBrandId.key]: 'Brand',
		[searchTypeList.byBarcode.key]: 'BarCode',
		[searchTypeList.byProductRefNo.key]: 'ItemCode',
	};

	const searchKeyMapping = {
		[searchTypeList.byProductName.key]: searchTerm,
		[searchTypeList.byCategoryId.key]: categoryTypeId,
		[searchTypeList.byBrandId.key]: brandTypeId,
		[searchTypeList.byBarcode.key]: searchTerm,
		[searchTypeList.byProductRefNo.key]: searchTerm,
	};

	const searchKeyType = searchTypeMapping[searchType];
	const searchKey = searchKeyMapping[searchType];

	if (isSearchFromPagination) {
		// This "if' logic is just to avoid unnecessary fetching.
		returnResult.countOfTotalProductItemsForSearch = totalProductItems;
	} else {
		returnResult.countOfTotalProductItemsForSearch =
			await getProductListCountForProformaCreation({
				...commonReqParams,
				searchKeyType,
				searchKey,
			});
	}

	returnResult.rawSearchResult = await getProductListForProformaCreation({
		...commonReqParams,
		searchKeyType,
		searchKey,
	});

	return returnResult;
}
