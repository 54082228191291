import { getFormSectionsPartialObjPaths } from './parserHelpers';

export function createMasterTabFormRelatedApiData(data = {}) {
	const { userId, isEditMode = false, parserFunctions = {} } = data;

	const { getFormValueByPath, masterTabParserFns } = parserFunctions;

	const { baseInfoObjPath, settingsInfoObjPath } =
		getFormSectionsPartialObjPaths().masterTab;

	const addressArrayPath = 'masterSection.addressInformation';

	function generateSalesRepRelatedApiData() {
		const selectedSalesRepData = masterTabParserFns.getSelectedSalesRepData();

		const selectedSalesRep = selectedSalesRepData.selectedSalesRepObj;

		return {
			SalesRepID: selectedSalesRep.StaffID,
			CloudSalesRepID: selectedSalesRep.CloudStaffID,
			SalesRepName: selectedSalesRep.StaffName,
		};
	}

	function generateAddressRelatedApiData() {
		const allAddressList = getFormValueByPath(`${addressArrayPath}`);

		const formattedAllAddressList = allAddressList.map((addressSet, index) => {
			return {
				AddressType: addressSet.addressType,
				AddressLine1: addressSet.addressLine1,
				AddressLine2: addressSet.addressLine2,
				City: addressSet.city,
				StateProvinceRegion: addressSet.stateProvinceRegion,
				PostZipCode: addressSet.postCode,
				Country: addressSet.country,
				Phone: addressSet.contactNumber,
				Fax: addressSet.faxNumber,
				Email: addressSet.email,

				CreatedDate: isEditMode ? undefined : new Date().toISOString(),
				LastModifiedDate: new Date().toISOString(),
				IsDefault: index === 0,
				IsActive: true,
			};
		});

		return {
			Addresses: formattedAllAddressList,
		};
	}

	if (isEditMode) {
		const fullAddressList = generateAddressRelatedApiData();
		const contactAddressDataSet = fullAddressList.Addresses[0];

		// Heres the all the data (For Master Tab) we are sending to "Customer Editing" API Endpoint. (These data will be merged with existing customer data)
		return {
			CompanyName: getFormValueByPath(`${baseInfoObjPath}companyName`), // Also In Customer.Name
			FirstName: getFormValueByPath(`${baseInfoObjPath}firstName`), // Also In Contact.ContactPerson.Name
			LastName: getFormValueByPath(`${baseInfoObjPath}lastName`),
			LastModifiedDate: new Date().toISOString(),

			Email: getFormValueByPath(`${addressArrayPath}[0].email`), // Also In Contact.Email
			...fullAddressList, // Also In Multiple places of Contact.

			DefaultPaymentType: getFormValueByPath(
				`${settingsInfoObjPath}paymentMethod`,
			),
			// Also in Customer.BusinessTaxNumber
			CustomerVatNo: getFormValueByPath(
				`${settingsInfoObjPath}customerVatNumber`,
			),

			Contact: {
				Email: getFormValueByPath(`${addressArrayPath}[0].email`),

				Address: contactAddressDataSet.AddressLine1,
				AddressLine2: contactAddressDataSet.AddressLine2,
				City: contactAddressDataSet.City,
				// StateProvinceRegion: contactAddressDataSet.StateProvinceRegion, // Not Applicable
				PostCode: contactAddressDataSet.PostZipCode,
				CountryName: contactAddressDataSet.Country,
				Phone: contactAddressDataSet.Phone,
				Fax: contactAddressDataSet.Fax,

				ContactPerson: {
					Name: getFormValueByPath(`${baseInfoObjPath}firstName`),
					ContactNumber: contactAddressDataSet.Phone,
					ModifiedDate: new Date().toISOString(),
				},

				ModifiedDate: new Date().toISOString(),
			},

			Customer: {
				Name: getFormValueByPath(`${baseInfoObjPath}companyName`),
				CustomerTypeID: getFormValueByPath(`${baseInfoObjPath}customerType`),
				CustomerStatus: getFormValueByPath(`${baseInfoObjPath}customerStatus`),
				CreditLimit: Number(
					getFormValueByPath(`${settingsInfoObjPath}creditLimit`),
				),
				CreditPeriod: String(
					getFormValueByPath(`${settingsInfoObjPath}creditPeriodAsDays`),
				),
				MemberNumber: getFormValueByPath(`${settingsInfoObjPath}memberNumber`),
				BusinessTaxNumber: getFormValueByPath(
					`${settingsInfoObjPath}customerVatNumber`,
				),
				OpeningBalance: Number(
					getFormValueByPath(`${settingsInfoObjPath}openingBalance`),
				),
				BulkDiscount: Number(
					getFormValueByPath(`${settingsInfoObjPath}bulkDiscount`),
				),
				Tier: getFormValueByPath(`${settingsInfoObjPath}tier`),
				AccountNumber: getFormValueByPath(
					`${settingsInfoObjPath}accountNumber`,
				),

				...generateSalesRepRelatedApiData(),

				Isvatexemption: getFormValueByPath(
					`${settingsInfoObjPath}isVatExemption`,
				),
				IsEmailProforma: getFormValueByPath(
					`${settingsInfoObjPath}isEmailProforma`,
				),
				IsEmailInvoice: getFormValueByPath(
					`${settingsInfoObjPath}isEmailInvoice`,
				),
			},
		};
	} else {
		// Heres the all the data (For Master Tab) we are sending to "Customer Adding" API Endpoint.
		return {
			RegisteredDate: new Date().toISOString(),
			CloudCreatedBy: userId,

			CustomerTypeID: getFormValueByPath(`${baseInfoObjPath}customerType`),
			CustomerStatus: getFormValueByPath(`${baseInfoObjPath}customerStatus`),
			CompanyName: getFormValueByPath(`${baseInfoObjPath}companyName`),
			FirstName: getFormValueByPath(`${baseInfoObjPath}firstName`),
			LastName: getFormValueByPath(`${baseInfoObjPath}lastName`),

			Email: getFormValueByPath(`${addressArrayPath}[0].email`),
			...generateAddressRelatedApiData(),

			CreditLimit: Number(
				getFormValueByPath(`${settingsInfoObjPath}creditLimit`),
			),
			CreditPeriod: String(
				getFormValueByPath(`${settingsInfoObjPath}creditPeriodAsDays`),
			),
			MemberNumber: getFormValueByPath(`${settingsInfoObjPath}memberNumber`),
			DefaultPaymentType: getFormValueByPath(
				`${settingsInfoObjPath}paymentMethod`,
			),
			CustomerVatNo: getFormValueByPath(
				`${settingsInfoObjPath}customerVatNumber`,
			),
			OpeningBalance: Number(
				getFormValueByPath(`${settingsInfoObjPath}openingBalance`),
			),
			BulkDiscount: Number(
				getFormValueByPath(`${settingsInfoObjPath}bulkDiscount`),
			),
			Tier: getFormValueByPath(`${settingsInfoObjPath}tier`),
			AccountNumber: getFormValueByPath(`${settingsInfoObjPath}accountNumber`),

			...generateSalesRepRelatedApiData(),

			Isvatexemption: getFormValueByPath(
				`${settingsInfoObjPath}isVatExemption`,
			),
			IsEmailProforma: getFormValueByPath(
				`${settingsInfoObjPath}isEmailProforma`,
			),
			IsEmailInvoice: getFormValueByPath(
				`${settingsInfoObjPath}isEmailInvoice`,
			),
		};
	}
}
