import React from 'react';

import ProductOverviewItem from '../../helperComponents/ProductOverviewItem/ProductOverviewItem';
import ProductOverviewSection from '../../helperComponents/ProductOverviewSection/ProductOverviewSection';

import './ProOvePricingSection.css';

const ProOvePricingSection = (props) => {
	const { overviewData = {} } = props;

	return (
		<ProductOverviewSection title='PRICING' className='ProOvePricingSection'>
			<div>
				<div className='ProOvePricingSection__sellingPriceContainer'>
					<ProductOverviewItem title='SELLING PRICE'>
						<div>
							<div>
								{Number(overviewData.sellingPrice).toFixed(2)}/
								{overviewData.sellingUnitType}
								<span className='ProductOverview--UTL--subtitleText'>
									INC. VAT
								</span>
							</div>

							<div>
								{overviewData.sellingUnitType === 'BOX' && (
									<div>
										{(
											overviewData.sellingPrice / overviewData.numOfItemsInABox
										).toFixed(2)}
										/EA
										<span className='ProductOverview--UTL--subtitleText'>
											INC. VAT
										</span>
									</div>
								)}
							</div>
						</div>
					</ProductOverviewItem>

					<ProductOverviewItem title='MARGIN'>
						<div>{overviewData.sellingMargin}</div>

						{overviewData.sellingUnitType === 'BOX' && (
							<div>{overviewData.sellingMargin}</div>
						)}
					</ProductOverviewItem>
				</div>

				<ProductOverviewItem title='COST PRICE'>
					{overviewData.costPrice}/{overviewData.costUnitType}
					<span className='ProductOverview--UTL--subtitleText'>INC. VAT</span>
				</ProductOverviewItem>
			</div>
		</ProductOverviewSection>
	);
};

export default ProOvePricingSection;
