import { getFormSectionsPartialObjPaths } from './parserHelpers';

export function createMasterTabFormRelatedApiData(data = {}) {
	const { userId, isEditMode = false, parserFunctions = {} } = data;

	const { getFormValueByPath, masterTabParserFns } = parserFunctions;

	const { customerInfoObjPath } = getFormSectionsPartialObjPaths().masterTab;

	if (isEditMode) {
		// Heres the all the data (For Master Tab) we are sending to "Customer Editing" API Endpoint. (These data will be merged with existing customer data)
		return {};
	} else {
		// Heres the all the data (For Master Tab) we are sending to "Customer Adding" API Endpoint.
		return { a: 10 };
	}
}
