import axios from 'axios';

export const serverRequest = async (server, url, method, data, options) => {
    const tokenGenerators = await import('../../tokenGenerators');
    const token = await tokenGenerators[server]();

    let axioOptions = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            ...options
        }
    }

    return await axios({
        method: method,
        url: url,
        data: data,
        ...axioOptions
    });
}