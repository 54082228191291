import React, { Component } from 'react';
import {
	EyeOutlined as EyeIcon,
	FileAddOutlined as FileAddIcon,
	CreditCardOutlined as CreditCardIcon,
	SelectOutlined as SelectIcon
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import { Table, withViewportData } from 'ecologital-ui-library';
import lodash from 'lodash';


import { rawColumnListParser } from '../helpers/parsers/filterDataParsers';

import { defaultCompoundValuePathColumnRenderer } from './helpers/helperFunctions';

import './SupplierList.css';

class SupplierList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isOverviewModalVisible: false,
			selectedRowData: {}, // When clicked on specific raw action (Ex. Overview Modal Eye Icon) this will filled with that specific row data.
		};
	}

	toggleOverviewModal(options = {}) {
		const { isOverviewModalVisible } = this.state;
		const { showModal = !isOverviewModalVisible, selectedRowData = {} } =
			options;

		this.setState({
			isOverviewModalVisible: false,//showModal,
			selectedRowData,
		});
	}

	parseRawColumnList(rawColumnList = {}, otherData = {}) {
		const {
			customizationProps = {
				/**
				 * NOTE : This can have various optional props to customize Customer List if necessary.
				 *
				 * 	- customActionColumnButtonGenerator
				 * 			- Should be a function. It will be passed each rows data, etc...
				 * 			- Using this we can customize what to shown in each row's Action Column, and what to do with each button.
				 *
				 *
				 * 	- customRawColumnListGenerator
				 * 	- customSpecialRootKeyListGenerator
				 * 			- Should be a function. It will be passed parsed column list, etc...
				 * 			- Using these we can further customize what columns will be shown, what data will be rendered, etc...
				 * 			- This will specially useful when backend received Filter data is not available or we need to further customize it.
				 */
			},
		} = otherData;

		const defaultSpecialColumnHandlers = [
			{
				key: 'Address',
				customConfigGenerator: (options = {}) => {
					const { rootValue } = options;

					return {
						customColumnProps: {
							render: (valueOfDataIndex, fullRowValues) =>
								defaultCompoundValuePathColumnRenderer(
									rootValue.ValuePath,
									fullRowValues,
								),
						},
					};
				},
			},
			{
				key: 'LastInvoice',
				customConfigGenerator: (options = {}) => {
					const { rootValue } = options;

					return {
						customColumnProps: {
							render: (valueOfDataIndex, fullRowValues) =>
								defaultCompoundValuePathColumnRenderer(
									rootValue.ValuePath,
									fullRowValues,
								),
						},
					};
				},
			},
		];

		// These will be update depending on passed props & some other conditions.
		let allRawColumnList = {};
		let allSpecialColumnHandlersList = [];

		// Getting Raw Column List to Process.
		if (lodash.isFunction(customizationProps.customRawColumnListGenerator)) {
			// When Custom RawColumnList Generator is passed.
			allRawColumnList = customizationProps.customRawColumnListGenerator({
				existingRawColumnList: rawColumnList,
			});
		} else {
			allRawColumnList = rawColumnList;
		}

		// Getting special handlers to handle special Columns.
		if (
			lodash.isFunction(customizationProps.customSpecialRootKeyListGenerator)
		) {
			// When customSpecialRootKeyHandler is Passed.
			allSpecialColumnHandlersList =
				customizationProps.customSpecialRootKeyListGenerator({
					existingRawColumnList: defaultSpecialColumnHandlers,
				});
		} else {
			allSpecialColumnHandlersList = defaultSpecialColumnHandlers;
		}

		return rawColumnListParser(allRawColumnList, {
			columnSortOrderKeys: ['CustomerName'],
			specialRootKeyList: allSpecialColumnHandlersList,
		});
	}

	generateTableColumnListConfig(parsedColumnList = [], otherData = {}) {
		const {
			rawSupplierList = [],
			currentViewportData = {},

			customizationProps = {
				/**
				 * NOTE : This can have various optional props to customize Customer List if necessary.
				 *
				 * 	- customActionColumnButtonGenerator
				 * 			- Should be a function. It will be passed each rows data, etc...
				 * 			- Using this we can customize what to shown in each row's Action Column, and what to do with each button.
				 */
			},
		} = otherData;

		const { isMobileViewport } = currentViewportData;

		// If there's no items in the table we just return empty row config.
		if (rawSupplierList.length === 0) {
			return [];
		}

		// Any common prop that applicable to all columns goes here.
		const defaultColumnProps = {};

		// Generating Column Config for ParsedColumnList.
		const tableColumnListConfig = parsedColumnList.map((column) => {
			const { customData = {} } = column;
			const { columnRootKey } = customData;

			// Some props will be added here when some logics are fulfilled below. Anyway this props will override all other column props as this is defined last in each columns props list.
			let dynamicColumnProps = {};

			// Making some dynamic props decisions based on API returned column data's object Root Key values.
			switch (columnRootKey) {
				case 'Name': {
					if (isMobileViewport) {
						// Handle 'Name' column in mobile resolutions to provide better UI/UX. (Ex. Fixed Width, Values Shrunken, Etc...)
						dynamicColumnProps = {
							...dynamicColumnProps,
							fixed: false,
							width: '160px',
							ellipsis: false,
							render: (valueOfDataIndex) => {
								const name = valueOfDataIndex || ''; // Value getting for 'Name' column.
								const maxNameLength = 22;
								const currentNameLength = name.length;
								const shouldShrink = currentNameLength > maxNameLength;

								if (shouldShrink) {
									const shrunkenName = `${name.slice(0, maxNameLength)}*`;

									return (
										<div>
											<Tooltip placement='topLeft' title={name}>
												{shrunkenName}
											</Tooltip>
										</div>
									);
								} else {
									return name;
								}
							},
						};
					} else {
						// Making Name Column fixed on larger resolutions while horizontal scrolling.
						// 		SIDE NOTE : But on mobile resolutions (<768px), Fixed "Name" column may would just hide all other columns as it take much width. So in mobiles res. we just disable column being fixed and do more improvements for mobile UI/UX.
						dynamicColumnProps.fixed = true;
					}

					break;
				}

				default: {
					break;
				}
			}

			return {
				...defaultColumnProps,
				title: () => (
					<div
						style={
							{
								// width: 'min-content', // This "min-content" makes each header text break on space and not expand column just for header name sake. (Useful when column name long but its content is very small.)
							}
						}>
						{column.ColumnName}
					</div>
				),
				onHeaderCell: () => {
					// This can used to add html props to each <th> element.
					return {
						style: { verticalAlign: 'bottom' },
					};
				},
				dataIndex: column.ValuePath.split('.'),
				onCell: () => { },
				// Custom-DEFAULT Cell Rendered Function. (This can be overridden in some cases.)
				render: (valueOfDataIndex, fullRowValues, index) => {
					// Custom-Default Cell Rendered Function.

					const displayValue = customData.customRenderValueGetter
						? customData.customRenderValueGetter(
							valueOfDataIndex,
							fullRowValues,
						)
						: valueOfDataIndex;

					// Below is needed because, original render function don't render boolean values. So in here we show custom string for show them.
					const isBooleanValue = typeof displayValue === 'boolean';
					if (isBooleanValue) {
						return displayValue ? 'True' : 'False';
					}

					return displayValue;
				},

				...column.customColumnProps, // props mentioned in "parseRawColumnList()" function.
				...dynamicColumnProps,
			};
		});

		// ******* Handling Actions Column *******

		// Normally these will be shown in Action Column, If not customized.
		const getDefaultActionColumnButtons = (valueOfDataIndex, fullRowValues) => [
			<EyeIcon
				title='View Customer'
				onClick={() => {
					this.toggleOverviewModal({
						selectedRowData: fullRowValues,
					});
				}}
			/>,
			<FileAddIcon title='Create New Order' onClick={() => { }} />,
			<CreditCardIcon title='Payment Details' onClick={() => { }} />,
		];

		// Helper function to Handle customization if "customActionColumnButtonGenerator" prop passed OR Not.
		const getActionColumnButtons = (valueOfDataIndex, fullRowValues, index) => {
			const isCustomActionColumnButtonGeneratorAvailable = lodash.isFunction(
				customizationProps.customActionColumnButtonGenerator,
			);

			let actionColumnButtons = []; // Track what to shown in 'Action Column' Finally.

			if (isCustomActionColumnButtonGeneratorAvailable) {
				actionColumnButtons =
					customizationProps.customActionColumnButtonGenerator({
						// Passing below data to passed customization prop, so on there, they can handle what to show.
						selectedRowData: fullRowValues,
						rowIndex: index,
						getDefaultActionColumnButtons: () =>
							getDefaultActionColumnButtons(
								valueOfDataIndex,
								fullRowValues,
								index,
							),
					});
			} else {
				// When column customization prop is not passed.
				actionColumnButtons = getDefaultActionColumnButtons(
					valueOfDataIndex,
					fullRowValues,
					index,
				);
			}

			return actionColumnButtons;
		};

		// Adding Special Action Column
		tableColumnListConfig.push({
			// title: 'ACTIONS',
			fixed: 'right',
			align: 'center',
			render: (valueOfDataIndex, fullRowValues, index) => {
				return (
					<div style={{ display: 'flex', gap: '10px' }}>
						{getActionColumnButtons(valueOfDataIndex, fullRowValues, index)}
					</div>
				);
			},
		});

		return tableColumnListConfig;
	}

	render() {
		// const { isOverviewModalVisible, selectedRowData } = this.state;
		const {
			rawSupplierList = [],
			// rawFilterData = {},
			selectedOrgId,
			selectedLocationId,

			currentViewportData, // From 'withViewportData' HOC.

			tableCompProps = {},

			customizationProps = {},
		} = this.props;

		const rawColumnList = []//rawFilterData.Columns || [];

		const parsedColumnList = this.parseRawColumnList(rawColumnList, {
			rawSupplierList,
			selectedOrgId,
			selectedLocationId,

			customizationProps,
		});
		// const tableColumnsConfig = this.generateTableColumnListConfig(
		// 	parsedColumnList,
		// 	{
		// 		rawSupplierList,
		// 		selectedOrgId,
		// 		selectedLocationId,

		// 		currentViewportData,

		// 		customizationProps,
		// 	},
		// );

		const tableData = rawSupplierList;

		return (
			<>
				{/* <CustomModal
					modalProps={{
						title: 'SUPPLIER OVERVIEW',
						visible: isOverviewModalVisible,
						onCancel: () => {
							this.toggleOverviewModal();
						},
					}}>
					<CustomerOverview
						rawCustomerData={selectedRowData}
						selectedOrgId={selectedOrgId}
						selectedLocationId={selectedLocationId}
						toggleOverviewModal={() => {
							this.toggleOverviewModal();
						}}
					/>
				</CustomModal> */}

				<div className='CustomerList'>
					<Table
						// columns={tableColumnsConfig}
						columns={[
							{
								title: 'Name',
								dataIndex: 'Name',
								key: 'name',
							},
							{
								title: 'SupplierID',
								dataIndex: 'SupplierID',
								key: 'supplierID',
							},
							{
								title: 'Email',
								dataIndex: 'Email',
								key: 'email',
							},
							{
								title: 'Address',
								dataIndex: 'Address',
								key: 'address',
							},
							// customizationProps.customActionColumnButtonGenerator({
							// 	fixed: 'right',
							// 	// Passing below data to passed customization prop, so on there, they can handle what to show.
							// 	getDefaultActionColumnButtons: () =>
							// 		// This can be used by caller, if they need to show default actions as well.
							// 		// eslint-disable-next-line sonarjs/no-extra-arguments
							// 		getDefaultActionColumnButtons(
							// 			valueOfDataIndex,
							// 			fullRowValues,
							// 			index,
							// 		),
							// })

							{
								// title: 'ACTIONS',
								fixed: 'right',
								align: 'center',
								render: (item) => {
									return (
										<div style={{ display: 'flex', gap: '10px' }}>
											<SelectIcon
												title='View Customer'
												onClick={() => {
													console.log("item", item)
													this.props.handleSelect(item)
												}}
											/>
										</div>
									);
								},

							}
						]}
						// NOTE :  This 'rowKey' prop is the key need as per React "Each child in a list should have a unique key" prop' warning".
						// 				"ID" value we used in here is unique value per row, Which available in dataSet we are passing in "dataSource" prop.
						rowKey='ID'
						dataSource={tableData}
						pagination={{
							pageSize: 10,
						}}
						customProps={{
							showCardViewInMobileRes: false,
						}}
						{...tableCompProps}
					/>
				</div>
			</>
		);
	}
}

export default withViewportData(SupplierList);
