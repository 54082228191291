import React, { Component } from 'react';

import './DashboardHomePage.css';

export default class DashboardHomePage extends Component {
	render() {
		return (
			<div>
				<h1>DASHBOARD HOME</h1>
			</div>
		);
	}
}
