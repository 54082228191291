import base64 from "base-64";
import { DateTime } from "luxon";
import qs from "query-string";
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { decodeToken } from "./tokenGenerators";

import { routePaths } from "../routes/routeConstants";

import routerHistory from "./routerHistory";

/**
 * There are multiple place in whole app that UserAuthentication/Redirection/LogOut related logic happens. In some cases they are interrelated and complicated.
 * Anyway for somewhat better understanding here is some list of place where important logics happen. (Not a comprehensive List)
 *  	1. MainRoutes Component - Handle AccessToken Expiration Check and Federation Login on Each Route Change.
 * 		2. LoginPage Component - Handle Normal Login. (Also most of logoutUser() fn cases get redirected to here)
 * 		3. PrivateRoute Component - Handle "IsUserLogged / LogOut" logics and any other custom passed logic to decide requested private route path should render or not.
 * 		4. TokenGenerators Functions - Add 'AccessToken' value when generating specific ServerToken. (So Authentication needed API Endpoint can be accessed.)
 * 		5. AxiosInstances Functions - Handle logOut() case when called API Endpoint itself return provided token is Expired.
 *		6. ...
 *
 * 		1. HomePage - Redirect to Dashboard if already logged.
 * 		2. Dashboard - LogOut Menus Items.
 * 		3. ...
 */

export function getLoggedUserDetails() {
    if (
        localStorage.getItem("user") &&
        localStorage.getItem("user") !== "[object Object]"
    ) {
        return JSON.parse(localStorage.getItem("user")) || {};
    }

    return {};
}

export function getLoggedUserId() {
    const loggedUserDetails = getLoggedUserDetails();
    return loggedUserDetails.userid;
}

export function checkIsUserLoggedIn() {
    const existingUserValueInStorage = getLoggedUserDetails();

    if (existingUserValueInStorage.AccessToken) {
        return true;
    }
    return false;
}

export function checkIsFederatedLogin() {
    const existingUserValueInStorage = getLoggedUserDetails();

    if (existingUserValueInStorage.isFederatedLogin) {
        return true;
    }
    return false;
}

export function logoutUser(options = {}) {
    const {
        redirectTo = routePaths.loginRoot, // By default re-direct to here.
        history = routerHistory,
        preventRedirect = false,
    } = options;

    localStorage.clear();
    localStorage.setItem("user", JSON.stringify({}));

    if (!preventRedirect && history && history.push) {
        history.push(redirectTo);
    }

    // return !preventRedirect ? <Redirect to={redirectTo} /> : null;
    return !preventRedirect ? (location.href = redirectTo) : null;
}

export function LogoutUserButtonWrapper(props) {
    const { children, redirectTo = routePaths.loginRoot } = props;

    const [loggedOut, setLoggedOut] = useState(false);

    const logout = () => {
        setLoggedOut(true);
        logoutUser();
    };

    if (loggedOut) {
        return <Redirect to={redirectTo} push />;
    }

    return (
        <div
            onClick={logout}
            style={{
                width: "100%",
            }}
        >
            {children}
        </div>
    );
}

// This is what happen when User Successfully Logged. (Either through UserName/Password OR Federated Login)
export function saveLoggedUser(passedUserDetails = {}) {
    try {
        const { AccessToken } = passedUserDetails;

        if (!AccessToken) {
            return;
        }
        const existingUserValuesInStorage = getLoggedUserDetails();

        // Getting encoded values inside AccessToken.
        const decodedTokenInfo = decodeToken(AccessToken);

        localStorage.setItem(
            "user",
            JSON.stringify({
                ...existingUserValuesInStorage,
                ...passedUserDetails, // In most cases, this receive key values such as AccessToken, RefreshToken, isFederatedLogin.
                ...decodedTokenInfo, // Normally available data keys are userid, exp, authorized, access_uuid.)
            })
        );
    } catch {
        logoutUser();
    }
}

export function handleFederatedLogin() {
    try {
        /**
         * Little Explanation about Federated Login Feature :
         * 		- This feature enable a way to directly view into any of specific page in this product(Specially private pages that need user authentication) without going through LoginPage's Normal Username/Password Process.
         * 				-  Ecologital WitMeg has multiple products. (Web Neurolage, Loyalty, Etc...). So onces user logged into any of those project through Normal Username/Password Process, that product can use their access token to to directly login to other project's pages through this Federated Login feature.
         * 				-  By the way that is possible because all Ecological WitMeg products use same AccessToken to Authenticate Login/API calls Etc.
         *
         * 				- This can be mostly useful for MobileApps WebView.
         * 					-  For Ex. on the mobile app (Let's say Loyalty App), once user logged in through normal Username/Password way, they can seamlessly show this product's pages (Web Neurolage) just using this federate login feature, with out any additional logins.
         *
         * Usage Example :
         *    - Let's assume we want to do a federated login for '/dashboard/inventory/products'.
         *    - So just append "?federatedLoginToken=VALUE" as query parameter.
         *        - This query parameter value should be  a object that have following key/values. (Ex. { isFederatedLogin: true, AccessToken:xxxxx, RefreshToken:yyyyy } )
         *            - But Make sure above object is first "JSON.stringyfy()"" and then convert to "Base64" string. (Because object values can't be passed as query parameter and other security reasons)
         *    - EX: https://webneurolage.com/dashboard/inventory/products?federatedLoginToken=BASE64ENCODEDOBJECT
         */

        const { location: reactRouterLocation } = routerHistory;

        // Extracting QueryParams from current URL path.
        const urlQueryString = reactRouterLocation.search;
        const queryParams = qs.parse(urlQueryString);

        const { federatedLoginToken } = queryParams;

        // If "federatedLoginToken" query param not passed just ignore handling federated login.
        if (!federatedLoginToken) {
            return;
        }

        // Extracting encoded/stringified data from passed token value.
        const decodedTokenData = JSON.parse(
            base64.decode(federatedLoginToken) || "{}"
        );

        const { isFederatedLogin } = decodedTokenData;

        if (isFederatedLogin === true) {
            saveLoggedUser({
                isFederatedLogin: true,
                AccessToken: decodedTokenData.AccessToken,
                RefreshToken: decodedTokenData.RefreshToken,
            });
        }
    } catch {
        // Error
    }
}

// NOTE : This only handle expiration by checking token exp value in local storage.
export function checkIsUserExpired(options = {}) {
    if (!checkIsUserLoggedIn()) {
        return null;
    }

    const existingUserValueInStorage = getLoggedUserDetails();
    const { exp: tokenExpireTimeAsSeconds } = existingUserValueInStorage;

    const expTime = DateTime.fromSeconds(tokenExpireTimeAsSeconds);
    const currentTime = DateTime.local();

    return expTime < currentTime;
}

export function removeUserIfExpired() {
    if (checkIsUserLoggedIn() && checkIsUserExpired()) {
        logoutUser({
            preventRedirect: true, // No redirection happen here, Because public pages should be accessible even when user is not logged/valid/expired.
        });
    }
}
