import React, { useState } from "react";
import {
	Stack,
	StackItem,
	Tag,
	Typography,
	Row,
	Col,
	Button,
	Select,
	ButtonGroup,
	Card,
	Icon,
} from "witmeg-ui-system";
import { Chart as ChartJS } from 'chart.js';
import OfferOverviewImageSlider from "./OfferOverviewImageSlider";
// import SummaryChart from './SummaryChart';

const OfferOverview = ({ selectedRecord, deleteOffer }) => {
	const [summeryView, setSummeryView] = useState('cards')
	const [summaryData, setSummaryData] = useState(null)
	const [selectedPeriod, setSelectedPeriod] = useState({ value: 30, label: 'Last 30 days' })
	const period = [
		{
			options: [
				{ value: 30, label: 'Last 30 days' },
				{ value: 365, label: 'Last Year' },
				{ value: 90, label: 'Last 90 days' },
				{ value: 14, label: 'Last 2 weeks' },
				{ value: 7, label: 'Last week' },
			]
		},
	];

	console.log('selectedRecord', selectedRecord);

	return (
		<div>
			<Stack
				direction="row"
				horizontalAlign="space_between"
				verticalAlign="v_top"
			>
				<StackItem>
					<Stack direction="row">
						<StackItem>
							<div>
								<img src={selectedRecord.offerImage} style={{ width: "200px", height: "200px" }} />
							</div>
						</StackItem>
						<StackItem>
							<Stack direction="column" verticalAlign="v_top" style={{ marginLeft: "20px" }}>
								<StackItem>
									<Typography type="title" level={3} className="eco-mb-12">
										{selectedRecord?.promotionName}
									</Typography>
									<Typography
										type="text"
										color="gray_base"
										className="eco-mr-16 eco-mb-12"
									>
										Buy {selectedRecord?.quantity} for {selectedRecord?.price}
									</Typography>

									<div style={{ display: "flex" }}>
										<Typography
											type="text"
											color="gray_base"
											className="eco-mr-16 eco-mb-12"
										>
											START DATE :{" "}
										</Typography>
										<Typography type="text">{selectedRecord?.startDate}</Typography>
									</div>
									<div style={{ display: "flex" }}>
										<Typography
											type="text"
											color="gray_base"
											className="eco-mr-16 eco-mb-12"
										>
											END DATE :{" "}
										</Typography>
										<Typography type="text">{selectedRecord?.endDate}</Typography>
									</div>
								</StackItem>
								<StackItem>
									<Stack direction="row">
										<Stack direction="column">
											<Typography
												type="title"
												level={2}
												pattern="bold"
												color="gray_darkest"
											>
												{selectedRecord?.price}
											</Typography>
											<Typography
												color="gray_lighter"
												pattern="bold"
												textTransform="uppercase"
												style={{ fontSize: "10px" }}
											>
												OFFER PRICE
											</Typography>
										</Stack>
										<div className="eco-ml-24">
											<div className="offer-qty-button" style={{ display: "flex", alignItems: "center", padding: "6px 10px" }}>
												<Icon
													name="information"
													color="yellow"
													type="filled"
													className="eco-mr-8"
												/>
												<Typography type="text" pattern="bold" style={{ lineHeight: "1" }}>
													Offer Qty is {selectedRecord?.quantity}
												</Typography>
											</div>
										</div>
									</Stack>
								</StackItem>
							</Stack>
						</StackItem>
					</Stack>
				</StackItem>
				<StackItem>
					<Tag variant="green" label="Active" />
				</StackItem>
			</Stack>
			<div>
				<OfferOverviewImageSlider
					OfferProducts={selectedRecord?.OfferProducts}
				/>
			</div>
		</div>
	);
};

export default OfferOverview;