import { serverRequest } from './axiosInstances/serverRequest';
import { APIError, errorLogger } from '../errorHandlers';

import apiEndpoints from './apiEndpointPaths';

export async function getReportListByAppIDAndOrgID(
	reqBody = {},
	options = {},
) {
	try {
		const apiReqBody = {};

		const { OrgID, appId } = reqBody;

		const API_URL = `${apiEndpoints.witmegReportingServer.getReportListByAppIDAndOrgID}?orgId=${OrgID}&appId=${appId}`;
		const resObj = await serverRequest('generateToken__WitmegWebNeurolageServer', API_URL, 'GET', apiReqBody, options);		

		const { status, data } = resObj.data;

		if (!status) {
			throw new APIError(Result);
		}

		// return data.filter(rli => rli.resources.find(r => r.type!=="Combined Resource"));
		return data
	} catch (error) {
		// const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		// return Promise.reject(APIError(customErrMsg, error));
	}
}