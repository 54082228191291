import React from "react";
import { Dashboard } from "witmeg-reporting-embed";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function ReportsPage() {
    let params = useParams();
    let user = localStorage.getItem("user");
    let AccessToken = JSON.parse(user).AccessToken;
    let userid = JSON.parse(user).userid;
    const dashboardState = useSelector((state) => state["dashboard"].dashboard);
    const userState = useSelector((state) => state.user);

    // console.log("Reports Page", {
    //     boardId: params.boardId,
    //     organizationId: dashboardState.currentlySelectedOrgId,
    //     cloudLocationId: dashboardState.currentlySelectedLocationId,
    //     userId: userid,
    //     baseUrl: `${process.env.REACT_APP_REPORTING_BASE_URL}/dashboard-details`,
    //     token: AccessToken,
    // });

    return (
        <div style={{ height: "100vh", padding: "16px" }}>
            <Dashboard
                baseUrl={`${process.env.REACT_APP_REPORTING_BASE_URL}/dashboard-details`}
                token={AccessToken}
                dashboardId={params.boardId}
                organizationId={dashboardState.currentlySelectedOrgId}
                cloudLocationId={dashboardState.currentlySelectedLocationId}
                userId={userid}
                userName={userState?.userDetails?.UserName}
                organizationName={dashboardState?.fullUserOrgDetailsList[dashboardState?.currentlySelectedOrgIndex]?.OrganizationName}
                locationName={dashboardState?.fullUserOrgDetailsList[dashboardState?.currentlySelectedOrgIndex]?.Companies[dashboardState?.currentlySelectedCompanyIndex]?.LocationIDs[dashboardState?.currentlySelectedLocationIndex]?.LocationName}
            />
        </div>
    );
}
