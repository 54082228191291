import lodash from 'lodash';

/**
 * NOTE : This function is written to parse, raw column list sended by certain backend APIS to match our base component needs.
 * 						- Normally data relevant to this function is in relevant API Response's "ProductFilter.Columns" path.
 * 						- Ex. Endpoint Paths that return data relevant to this function - "/v1/epos/product/barcode", "/v1/epos/customers/search/email", etc...
 *
 * 				On this raw column list backend define what columns should be shown on frontend for its received data.
 * 				For better understanding of this code, Please refer example of intended structure mentioned in "./___ExplainerReferences1.txt" file. (REF NO 1, REF NO 2)
 *
 * 				On most cases this function will be used in pre-process kind of way. So some code decision made here taking next function in mind.
 */

export const rawColumnListParser = (rawColumnList = {}, options = {}) => {
	const {
		// There are some special key entries in raw column list that need custom parsing to extract it's nested columns, nested data, etc...
		// In simpler sense "specialRootKeyList" is manually defined key list. that we need to parse manually for various reasons.
		specialRootKeyList = [],
		columnSortOrderKeys = [], // Until backend API result somehow send columns sort order we are manually specifying some column sort order.
	} = options;

	// Helper function to handle individual "Special Root Keys" mentioned in "specialRootKeyList". This generate and return special config obj. for the given key, that consist details which we can use later steps to do things.
	const specialKeyHandler = (
		passedRootKeyData,
		specialKeyHandlerOptions = {},
	) => {
		// This Special Key's "rootKey" & "rootValue".
		const { rootKey, rootValue } = passedRootKeyData;

		const {
			customConfigGenerator, // Should be Fn. If this is passed, used to generate whole config. This is like master function as this is passed below prop is not needed.

			customValuePathSetter, // Should be Fn. If this is passed, used to set custom "ValuePath" to get necessary data. Mostly useful when backend provided ValuePath is not directly usable.
			customRenderValueGetter, // Should be Fn. If this is passed, it will used INSIDE AntD renderer for that column. This function will get "valueFromValuePath, fullRowValues" for particular row from AntD. Should return plain value in almost cases.
			customRender, // Should be Fn. If this is passed, Similar to above. But it will used as DIRECT AntD renderer for that column.

			customColumnPropsSetter, // Should be Fn. If this is passed, It will used as DIRECT AntD column props for that column.
		} = specialKeyHandlerOptions;

		// More data in here may be changed/added depending on certain conditions below.
		const defaultColumnConfig = {
			...rootValue, // In most cases, this will contain "ColumnName, ValuePath, IsVisible, IsFilter, Etc..." (From Backend.)
			customColumnProps: {
				// These would be normally used directly in AntD Table.
				// Ex. render
			},
			customData: {
				// These would be used in later stage for certain conditions/data.
			},
		};

		// When "customConfigGenerator" passed.
		if (lodash.isFunction(customConfigGenerator)) {
			return {
				...defaultColumnConfig,
				...customConfigGenerator(passedRootKeyData),
			};
		}

		// When "customValuePathSetter" passed.
		if (lodash.isFunction(customValuePathSetter)) {
			const isCustomRenderValueGetterPassed = !lodash.isFunction(
				customRenderValueGetter,
			);
			const isCustomRenderPassed = !lodash.isFunction(customRender);

			if (isCustomRenderValueGetterPassed || isCustomRenderPassed) {
				defaultColumnConfig.ValuePath = ''; // Just resetting this.
			} else {
				defaultColumnConfig.ValuePath =
					customValuePathSetter(passedRootKeyData);
			}
		}

		// When "customRenderValueGetter" passed.
		if (lodash.isFunction(customRenderValueGetter)) {
			defaultColumnConfig.customData.customRenderValueGetter = (...args) =>
				customRenderValueGetter(...args);
		}

		// When "customRender" passed.
		if (lodash.isFunction(customRender)) {
			defaultColumnConfig.customColumnProps.render = (...args) =>
				customRender(...args);
		}

		// When "customColumnPropsSetter" passed.
		if (lodash.isFunction(customColumnPropsSetter)) {
			defaultColumnConfig.customColumnProps = {
				...defaultColumnConfig.customColumnProps,
				...customColumnPropsSetter(),
			};
		}

		return defaultColumnConfig;
	};

	return Object.entries(rawColumnList).reduce(
		(acc, entry, index, sourceArray) => {
			// In most cases this is each iteration's "Column Key"(rootKey) and that "Columns Raw Config Data"(rootValue)
			const [rootKey, rootValue] = entry;

			const extractedRawColumns = []; // Track what columns to be processed in this iteration. (In most cases, this will only contain direct "rootValue". But some key may have multiple nested columns that we need to extract. Like "CustomArray"   )
			let parsedColumnList = acc; // Keep all parsed column list throughout whole reduce process.

			// ********************************* Extracting & Handling Special/Normal Columns *********************************

			// *************** Handling Special Root Keys **************
			const specialRootKeyData =
				specialRootKeyList.find((specialRootKey) => {
					return specialRootKey.key === rootKey;
				}) || {};

			const isSpecialRootKey = specialRootKeyData.key === rootKey;

			if (isSpecialRootKey) {
				extractedRawColumns.push(
					specialKeyHandler({ rootKey, rootValue }, specialRootKeyData),
				);
			}
			// *************** Handling Normal Root Keys **************
			else {
				const defaultColumnConfig = {
					// SIDE NOTE : Just to match with "specialKeyHandler()" config and avoid runtime errors due to unavailable object paths.
					customColumnProps: {},
					customData: {},
				};

				// This is what happen for most keys. Because most keys are just directly specifying single column.
				extractedRawColumns.push({
					...defaultColumnConfig,
					...rootValue,
				});
			}

			// ********************************* Parsing All Extracted Raw Columns from Keys *********************************
			// Parsing/Checking conditions for extracted columns in current iteration.
			extractedRawColumns.forEach((column) => {
				const shouldRenderColumn = column.IsVisible && column.IsFilter;

				if (shouldRenderColumn) {
					parsedColumnList.push({
						ColumnName: column.ColumnName,
						ValuePath: column.ValuePath, // In most cases, This will be used as 'dataKey' on AntD columnConfig to access data from dataset.

						// Custom column props to be used later in AntDTable column config.
						customColumnProps: {
							...column.customColumnProps,
						},

						// Adding CustomData to make decisions in later stages.
						customData: {
							...column.customData,
							columnRootKey: column.customData
								? column.customData.columnRootKey || rootKey
								: rootKey, // This is used further customize columns when this list used elsewhere.
							columnSortOrderIndex: !columnSortOrderKeys.includes(rootKey) // Used for column ordering tasks.
								? undefined
								: columnSortOrderKeys.indexOf(rootKey),
							customRenderValueGetter:
								column.customData.customRenderValueGetter, // If needed, This is used to get custom value, to be rendered in cells.
						},
					});
				}
			});

			// Doing some overall changes in last iteration.
			const isLastIteration = index === sourceArray.length - 1;
			if (isLastIteration) {
				// ************* Sorting Columns
				// NOTE :
				// 		- Notice '1000' assigned as default values. This is done to avoid 'NaN' in sort calculations which result in unsorted array as some columns may not have specified a Sort Value.
				// 		- We are doing ascending order sort. So column with lowest Sort Value will come first. If column have not specified a Sort Value they will appear after the specified columns.
				parsedColumnList = parsedColumnList.slice().sort((a, b) => {
					const { columnSortOrderIndex: columnSortOrderIndexA = 1000 } =
						a.customData;
					const { columnSortOrderIndex: columnSortOrderIndexB = 1000 } =
						b.customData;

					return columnSortOrderIndexA - columnSortOrderIndexB;
				});
			}

			return parsedColumnList;
		},
		[],
	);
};
