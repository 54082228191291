import { HeaderBar } from 'ecologital-ui-library';
import React, { Component } from 'react';

import './RouteContentWrapper.css';

class RouteContentWrapper extends Component {
	render() {
		const { title = '', children } = this.props;

		return (
			<div className='RouteContentWrapper'>
				{title && <HeaderBar title={title} />}

				<div className='RouteContentWrapper__content'>{children}</div>
			</div>
		);
	}
}

export default RouteContentWrapper;
