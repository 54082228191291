import React, { useState } from 'react';
import {
	Form,
	InlineMessage,
	InputBox,
	Label,
	Select,
	Row,
	Col,
} from 'witmeg-ui-system';
// import {  Input as AntInput, InputNumber } from 'antd';

const layout = {
	labelCol: {
		span: 8,
	},
	wrapperCol: {
		span: 16,
	},
};

const WarningMessagesForm = (props) => {
	const {
		warningMessagesInitialValues,
		customAttributesForm,
		onFinish,
		onFinishFailed,
		warningMessages,
		handleChangeWarningMessageInputs,
	} = props;

	const [attributes, setAttributes] = useState([]);

	const handleChangeInput = (e, fieldName) => {
		let updatedValues = {
			...warningMessages,
			[fieldName]: e.target.value,
		};
		handleChangeWarningMessageInputs(updatedValues, fieldName);
		// console.log("updatedBasicInformationIValues===>>", updatedBasicInformationIValues)
	};

	const handleChangeNumber = (e, fieldName) => {
		console.log('e', e);
		let updatedValues = {
			...warningMessages,
			[fieldName]: e.target.value,
		};
		handleChangeWarningMessageInputs(updatedValues, fieldName);
		// console.log("updatedBasicInformationIValues===>>", updatedBasicInformationIValues)
	};

	const handleSelectChange = (selectedOption, fieldName) => {
		let updatedValues = {
			...warningMessages,
			[fieldName]: selectedOption.value,
		};
		handleChangeWarningMessageInputs(updatedValues, fieldName);
	};

	const soundFiles = [
		{ label: 'Warning 1', value: 'Warning 1.mp3' },
		{ label: 'Warning 2', value: 'Warning 2.mp3' },
		{ label: 'Warning 3', value: 'Warning 3.mp3' },
		{ label: 'Warning 4', value: 'Warning 4.mp3' },
		{ label: 'Warning 5', value: 'Warning 5.mp3' },
		{ label: 'Warning 6', value: 'Warning 6.mp3' },
		{ label: 'Warning 7', value: 'Warning 7.mp3' },
		{ label: 'Warning 8', value: 'Warning 8.mp3' },
		{ label: 'Warning 9', value: 'Warning 9.mp3' },
		{ label: 'Warning 10', value: 'Warning 10.mp3' },
		{ label: 'Warning 11', value: 'Warning 11.mp3' },
		{ label: 'Warning 12', value: 'Warning 12.mp3' },
	];

	return (
		<div>
			<Row className="form-row">
				<Col md={8}>
					<div className="form-lbl-wrapper">
						<Label>Description</Label>
					</div>
				</Col>
				<Col md={16}>
					<InputBox
						type="text"
						placeholder="Add Description"
						value={warningMessages.warningMessage}
						onChange={(e) =>
							handleChangeInput(e, 'warningMessage')
						}
					/>
				</Col>
			</Row>
			<Row className="form-row">
				<Col md={8}>
					<div className="form-lbl-wrapper">
						<Label>Age</Label>
					</div>
				</Col>
				<Col md={16}>
					<InputBox
						type="number"
						minValue={0}
						placeholder="Age"
						value={warningMessages.age}
						onChange={(e) => handleChangeNumber(e, 'age')}
					/>
				</Col>
			</Row>
			<Row className="form-row">
				<Col md={8}>
					<div className="form-lbl-wrapper">
						<Label>Sound</Label>
					</div>
				</Col>
				<Col md={16}>
					<Select
						options={soundFiles}
						onChange={(selectedOption) => {
							handleSelectChange(
								selectedOption,
								'soundFile'
							);
						}}
						value={{
							label: warningMessages.soundFile,
							value: warningMessages.soundFile,
						}}
					/>
				</Col>
			</Row>
		</div>
	);
};

export default WarningMessagesForm;
