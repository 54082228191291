// Defining what columns to be shown in Product Searching result Table.
export const productSearchColumnConfig = {
	ItemCode: {
		ColumnName: 'Item No',
		ValuePath: 'ItemCode',
		IsVisible: true,
		IsFilter: true,
	},
	ProductName: {
		ColumnName: 'Name',
		IsFilter: true,
		IsVisible: true,
		ValuePath: 'ProductName',
	},
	UnitType: {
		ColumnName: 'Unit Type',
		IsFilter: true,
		IsVisible: true,
		ValuePath: 'UnitType',
	},
	SellingPrice: {
		ColumnName: 'Selling Price',
		IsFilter: true,
		IsVisible: true,
		ValuePath: 'SellingPrice',
	},
	AvailableStock: {
		ColumnName: 'Available Stock',
		IsFilter: true,
		IsVisible: true,
		ValuePath: 'AvailableStock',
	},
	Weight: {
		ColumnName: 'Weight',
		IsFilter: true,
		IsVisible: true,
		ValuePath: 'Weight',
	},
};
