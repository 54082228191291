/* eslint-disable no-param-reassign */

import { reqStatusTypes } from '../../helpers/constants';

import {
	dummyFn,
	getCurrentlyLoggedUserDetails,
} from './userSliceExtraActions';

const extraReducers = {
	// ******************************* dummyFn() *******************************
	[dummyFn.pending]: (state, action) => {
		state.dummyFnReqStatus = reqStatusTypes.pending;
		state.dummyFnReqError = '';
	},

	[dummyFn.fulfilled]: (state, action) => {
		state.dummyFnReqStatus = reqStatusTypes.succeeded;
		state.dummyValue = action.payload;
	},

	[dummyFn.rejected]: (state, action) => {
		state.dummyFnReqStatus = reqStatusTypes.failed;
		state.dummyFnReqError = action.error.message;
		state.dummyValue = '';
	},

	// ******************************* getCurrentlyLoggedUserDetails() *******************************
	[getCurrentlyLoggedUserDetails.pending]: (state, action) => {
		state.getCurrentlyLoggedUserDetailsReqStatus = reqStatusTypes.pending;
		state.getCurrentlyLoggedUserDetailsReqError = '';
	},

	[getCurrentlyLoggedUserDetails.fulfilled]: (state, action) => {
		state.getCurrentlyLoggedUserDetailsReqStatus = reqStatusTypes.succeeded;
		state.userDetails = action.payload;
	},

	[getCurrentlyLoggedUserDetails.rejected]: (state, action) => {
		state.getCurrentlyLoggedUserDetailsReqStatus = reqStatusTypes.failed;
		state.getCurrentlyLoggedUserDetailsReqError = action.error.message;
		state.userDetails = {};
	},
};

export default extraReducers;
