import {
	SlidersOutlined as SlidersOutlinedIcon,
	AppstoreOutlined as AppstoreOutlinedIcon,
} from '@ant-design/icons';
import { Button, Menu, Dropdown } from 'antd';
import lodash from 'lodash';
import React, { Component } from 'react';

import ImageViewer from '../../../../../../widgets/ImageViewer/ImageViewer';

import './PmvImagesTabImageViewer.css';

export default class PmvImagesTabImageViewer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			imageViewerMode: 'SWIPER',
		};
	}

	setImageViewerMode(mode) {
		this.setState({
			imageViewerMode: mode,
		});
	}

	render() {
		const { imageList = [], setImageAsMainImageFn = () => {} } = this.props;
		const { imageViewerMode } = this.state;

		return (
			<div className='PmvImagesTabImageViewer'>
				<div className='PmvImagesTabImageViewer__toolbar'>
					<Button
						icon={<SlidersOutlinedIcon />}
						shape='circle'
						title='Swiper View'
						type={imageViewerMode === 'SWIPER' ? 'primary' : ''}
						onClick={() => this.setImageViewerMode('SWIPER')}
					/>
					<Button
						icon={<AppstoreOutlinedIcon />}
						shape='circle'
						title='Grid View'
						type={imageViewerMode === 'GRID' ? 'primary' : ''}
						onClick={() => this.setImageViewerMode('GRID')}
					/>
				</div>

				<ImageViewer
					imageList={imageList.map((img) => {
						return {
							// Data Strictly Needed By <ImageViewer/>
							imgTitle: `${img.imgCategory} - ${lodash.startCase(
								img.imgKeyTitle,
							)}`,
							imgUrl: img.imgUrl,

							// Additional Data, For further decision making. (Ex. These get passed back in "ThumbnailImgWrapperComp" prop)
							additionalData: {
								...img,
								isMainImage: Boolean(img.isMainImage),
							},
						};
					})}
					viewerMode={imageViewerMode}
					// Wrapper component to wrapped around thumbnails.
					// 		- In here we add Dropdown menu to give user some additional functionality for eachimage. (Ex. Mark As Main)
					ThumbnailImgWrapperComp={(props) => {
						const { clickedImageDetails = {} } = props;

						const { additionalData = {} } = clickedImageDetails;
						const { isMainImage, formikFieldPath, formikFieldValueIndex } =
							additionalData;

						return (
							<Dropdown
								overlay={
									<Menu>
										<Menu.Item
											disabled={isMainImage}
											onClick={() => {
												if (isMainImage) {
													return;
												}

												setImageAsMainImageFn(
													formikFieldPath,
													formikFieldValueIndex,
												);
											}}>
											Mark As Main
										</Menu.Item>
									</Menu>
								}
								trigger='contextMenu'>
								<div className='PmvImagesTabImageViewer____thumbImgWrapper'>
									{isMainImage && (
										<div className='PmvImagesTabImageViewer____thumbImgWrapper__mainIndicator'>
											M
										</div>
									)}
									{props.children}
								</div>
							</Dropdown>
						);
					}}
				/>
			</div>
		);
	}
}
