/* eslint-disable no-param-reassign */
import { current as getStateAsPlainObj } from '@reduxjs/toolkit';

const standardReducers = {
	// NOTE : This function is used as Temporary way to reset "Inventory Products Page" Internal React State.
	//        Depending on this actions redux value, that component itself handle resetting.
	invokeInventoryProductsPageResetter: (state, action) => {
		state.shouldInvokeInventoryProductsPageResetter = action.payload;
	},

	toggleProductAddEditModal: (state, action) => {
		const parsedState = getStateAsPlainObj(state);
		const {
			showModal = !parsedState.isProductAddEditModalVisible,
			type = 'ADD',
			additionalInfo = {},
		} = action.payload || {};

		state.isProductAddEditModalVisible = showModal;
		state.productAddEditModalType = type;
		state.productAddEditModalAdditionalInfo = additionalInfo;
	},
};

export default standardReducers;
