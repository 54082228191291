import React, { useState } from "react";
import {
    Col,
    InputBox,
    Row,
    Switch,
    Label,
    Icon,
    Select,
    Typography,
} from "witmeg-ui-system";
import MultipleContactPeronTable from "./MultipleContactPeronTable";
import Energy from "../../../../../../assets/img/energy.png";

const BasicInformationForm = ({
    validations,
    basicInformationIValues,
    handleChangeBasicInfoInputs,
    selectedRecord,
    handleChangeInputContactPerson,
    handleContactPersonDesignation,
    contactPersonValues,
    enableSupplierEditMode,
}) => {
    const [contactPerson, setContactPerson] = useState({});
    const [noOfcopies, setNoOfCopies] = useState([
        {
            label: "1",
            value: "1",
        },
    ]);
    const noOfCopiesOptions = [
        {
            label: "test 1",
            value: "test 1",
        },
        {
            label: "test 2",
            value: "test 2",
        },
        {
            label: "test 3",
            value: "test 3",
        },
    ];

    const handleChangeInput = (e, fieldName) => {
        let updatedBasicInformationIValues = {
            ...basicInformationIValues,
            [fieldName]: e.target.value,
        };
        handleChangeBasicInfoInputs(updatedBasicInformationIValues, fieldName);
    };

    const handleContactPerson = (suplierContactPersonUpdated) => {
        //converting number to string - for mobile
        let modifiledsuplierContactPersonUpdated =
            suplierContactPersonUpdated.map((item) => ({
                ...item,
                Mobile: item.Mobile.toString(),
            }));

        let updatedBasicInformationIValues = {
            ...basicInformationIValues,
            contactPerson: modifiledsuplierContactPersonUpdated,
        };
        handleChangeBasicInfoInputs(
            updatedBasicInformationIValues,
            "contactPerson"
        );
    };

    const handleContactPDesignation = (e, fieldName) => {
        let updatedBasicInformationIValues = {
            ...basicInformationIValues,
            [fieldName]: e.value,
        };
        handleChangeBasicInfoInputs(updatedBasicInformationIValues, fieldName);
    };

    return (
        <div>
            {basicInformationIValues.isExternalIntegration && (
                <Row className="form-row">
                    <Col span={20}>
                        <Typography
                            type="text"
                            className="eco-mr-8"
                            color="gray_base"
                        >
                            This Supplier is Integrated With Nisa
                        </Typography>
                    </Col>
                    <Col span={4}>
                        <img
                            src={Energy}
                            alt="Profile Picture"
                            style={{ width: "20px", height: "20px" }}
                        />
                    </Col>
                </Row>
            )}
            <Row className="form-row">
                <Col span={8}>
                    <div className="form-lbl-wrapper">
                        <Label required={true}>Supplier Name</Label>
                    </div>
                </Col>
                <Col span={16}>
                    <InputBox
                        type="text"
                        placeholder="Supplier Name"
                        error={validations.supplierName}
                        onChange={(e) => handleChangeInput(e, "supplierName")}
                        value={basicInformationIValues?.supplierName || ""}
                    />
                </Col>
            </Row>
            <Row className="form-row">
                <Col span={8}>
                    <div className="form-lbl-wrapper">
                        <Label>Email</Label>
                    </div>
                </Col>
                <Col span={16}>
                    <InputBox
                        type="email"
                        placeholder="Email"
                        onChange={(e) => handleChangeInput(e, "supplierEmail")}
                        value={basicInformationIValues?.supplierEmail || ""}
                        // error={validations.supplierEmail}
                    />
                </Col>
            </Row>
            <Row className="form-row">
                <Col span={8}>
                    <div className="form-lbl-wrapper">
                        <Label>Landline</Label>
                    </div>
                </Col>
                <Col span={16}>
                    <InputBox
                        type="number"
                        // error={validations.supplierLandline}
                        placeholder="Landline"
                        onChange={(e) =>
                            handleChangeInput(e, "supplierLandline")
                        }
                        value={basicInformationIValues?.supplierLandline || ""}
                        minValue={0}
                    />
                </Col>
            </Row>
            <Row className="form-row">
                <Col span={8}>
                    <div className="form-lbl-wrapper">
                        <Label>Mobile</Label>
                    </div>
                </Col>
                <Col span={16}>
                    <InputBox
                        type="number"
                        // error={validations.supplierMobile}
                        placeholder="Mobile"
                        onChange={(e) => handleChangeInput(e, "supplierMobile")}
                        value={basicInformationIValues?.supplierMobile || ""}
                        minValue={0}
                    />
                </Col>
            </Row>

            <Row className="form-row">
                <Col span={8}>
                    <div className="form-lbl-wrapper">
                        <Label required={true}>Address</Label>
                    </div>
                </Col>
                <Col span={16}>
                    <InputBox
                        type="text"
                        placeholder="Address"
                        error={validations.supplierAddress}
                        value={basicInformationIValues?.supplierAddress || ""}
                        onChange={(e) =>
                            handleChangeInput(e, "supplierAddress")
                        }
                    />
                </Col>
            </Row>

            <Row className="form-row">
                <Col span={8}>
                    <div className="form-lbl-wrapper">
                        <Label>Contact Person</Label>
                    </div>
                </Col>
            </Row>
            <MultipleContactPeronTable
                contactPersonValues={contactPersonValues}
                handleContactPerson={handleContactPerson}
                basicInformationIValues={basicInformationIValues}
                handleChangeBasicInfoInputs={handleChangeBasicInfoInputs}
            />
        </div>
    );
};

export default BasicInformationForm;
