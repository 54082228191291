import React, { useState, useEffect } from "react";
import {
    Button,
    InputBox,
    Table,
    AutoSuggest,
    Alert,
    Row,
    Space,
    Col,
    Label,
    Select,
    TextArea,
    Stack,
} from "witmeg-ui-system";

import { useSelector } from "react-redux";
import { sectionName as dashboardSectionName } from "../../../../../../redux/slices/dashboard/dashboardConstants";
import { generateLocationListOfAllOrganizations } from "../../../../../../components/dashboardRelatedComps/LocationSelector/helpers/helperFns";
import {
    getStockAdjustmentReasons,
    searchProducts,
    getUnitTypesByOrgId,
} from "../../../../../../utilities/apiRequests/witmegWebNeurolageServerRequests";
import find from "lodash/find";

const CreationForm = (props) => {
    const {
        formData,
        handleSetAdjustments,
        handleChangeInput,
        handleTableData,
        validationMessage,
        tableData,
    } = props;
    const [count, setCount] = useState(0);
    const [updatedData, setUpdatedData] = useState();
    const [inputBarcode, setInputBarcode] = useState();
    const [dataSet, setDataSet] = useState([]);
    const [locations, setLocations] = useState([]);
    const [unitTypes, setUnitTypes] = useState([]);
    const [adjustedReason, setAdjustedReason] = useState([]);
    const suggestionTableColumns = [
        { label: "Barcode", key: "BarCode" },
        { label: "Item Name", key: "ProductName" },
        { label: "Item Code", key: "Itemcode" },
    ];
    const dashboardState = useSelector(
        (state) => state[dashboardSectionName].dashboard
    );

    const decrementAdjustmentCount = (record) => {
        const updatedDataSource = tableData.map((data) => {
            if (data.ID === record.ID) {
                data.adjustmentCount = data.adjustmentCount - 1;
            }
            return data;
        });
        handleTableData(updatedDataSource);
    };

    const incrementAdjustmentCount = (record) => {
        const updatedDataSource = tableData.map((data) => {
            if (data.ID === record.ID) {
                data.adjustmentCount = data.adjustmentCount + 1;
            }
            return data;
        });

        handleTableData(updatedDataSource);
    };

    //fetching the location and reasons
    useEffect(async () => {
        let locationsList = await generateLocationListOfAllOrganizations({
            dashboardSliceState: dashboardState,
        });
        const reasons = await getStockAdjustmentReasons({
            OrgID: dashboardState.currentlySelectedOrgId,
        });

        setAdjustedReason(reasons?.Reasons);
        setLocations(locationsList);
    }, [dashboardState]);

    //get unittypes
    useEffect(() => {
        let isMounted = false;
        const getUnitTypes = async () => {
            let types = await getUnitTypesByOrgId({
                OrgID: dashboardState.currentlySelectedOrgId,
            });

            setUnitTypes(types);
        };

        !isMounted && getUnitTypes();

        return (isMounted = true);
    }, []);

    useEffect(() => {
        let isMounted = false;
        const getSearchProducts = async () => {
            // debugger
            const result = await searchProducts({
                orgId: dashboardState.currentlySelectedOrgId,
                locationId: dashboardState.currentlySelectedLocationId,
                // searchBy: "Barcode",
                searchKey: inputBarcode,
            });
            const data = await getTableDataFromResults(result);
            setDataSet(data);
        };
        !isMounted && getSearchProducts();
        return () => (isMounted = true);
    }, [inputBarcode, dashboardState]);

    const getTableDataFromResults = async (data) => {
        return await data.map((stock, index) => {
            return {
                key: stock.ID,
                BarCode: stock.BarCode,
                ProductName: stock.Name,
                Itemcode: stock.ProductRefNo,
                inHand: find(stock.Inventories[0].QuantityList, {
                    CloudLocationID: dashboardState.currentlySelectedLocationId,
                }).Quantity,
                stockUnit: "EA",
                adjustmentCount: 0,
                ID: stock.ID,
                cloudProductId: stock.CloudProductID,
            };
        });
    };

    const addItems = () => {
        const baseUnit = find(unitTypes, { IsBaseUnit: true });

        const newData = {
            key: tableData.length - 1,
            id: tableData.length - 1,
            BarCode: "",
            ProductName: "",
            inHand: "",
            adjustmentCount: 0,
            Itemcode: "",
            stockUnit: "EA",
            cloudProductId: "",
        };
        handleTableData([...tableData, newData]);
        setCount(tableData.length - 1);
    };

    const getData = (data) => {
        handleTableData(data);
    };

    useEffect(() => {
        let isMounted = false;

        if (updatedData) {
            if (!isMounted) {
                const dataTemp = [...tableData];
                dataTemp[tableData.length - 1] = updatedData;
                getData(dataTemp);
            }
        }
        return () => (isMounted = true);
    }, [updatedData]);

    const setUpdatedTableData = async (row, data) => {
        setUpdatedData(data);
    };

    const inputChangeInEditable = (value) => {
        setInputBarcode(value);
    };

    const deleteItem = (index, item, id) => {
        let copyOfTD = [...tableData];
        copyOfTD.splice(id, 1);
        handleTableData(copyOfTD);
    };

    const addItemsColumns = [
        {
            id: "Barcode - 1",
            title: "Barcode",
            dataIndex: "BarCode",
            key: "BarCode",
            render: (text, record, index) => (
                <div style={{ width: "150px" }}>
                    <AutoSuggest
                        id="Barcode-1"
                        type="text"
                        rowNumber={index}
                        setTableData={setUpdatedTableData}
                        inputValue={record?.BarCode}
                        columnsData={suggestionTableColumns}
                        dataSet={dataSet}
                        searchKey="BarCode"
                        inputChangeInEditable={inputChangeInEditable}
                    />
                </div>
            ),
        },
        {
            title: "Item Name",
            dataIndex: "ProductName",
            key: "ProductName",
            align: "center",
        },
        {
            title: "In Hand",
            dataIndex: "inHand",
            key: "inHand",
            align: "center",
        },
        {
            title: "Unit",
            dataIndex: "stockUnit",
            key: "stockUnit",
            align: "center",
            // render: (_, record) => (
            //     <div>
            //         <Select
            //             options={
            //                 unitTypes &&
            //                 unitTypes.map((unit) => {
            //                     return {
            //                         value: unit.UnitTypeName,
            //                         label: unit.UnitTypeName,
            //                     };
            //                 })
            //             }
            //             onChange={(e) => ({ handleUnit })}
            //             value={record.UnitTypeName}
            //             placeholder="Unit"
            //         ></Select>
            //     </div>
            // ),
        },
        {
            title: "Adjustment",
            dataIndex: "adjustment",
            width: "20%",
            align: "center",
            render: (_, record) => (
                <div>
                    <Stack horizontalAlign="space_evenly">
                        <Stack>
                            <Button
                                size="noSpace"
                                onClick={() => decrementAdjustmentCount(record)}
                            >
                                -
                            </Button>
                        </Stack>
                        <Stack>{record && record.adjustmentCount}</Stack>

                        <Stack>
                            <Button
                                size="noSpace"
                                onClick={() => incrementAdjustmentCount(record)}
                            >
                                +
                            </Button>
                        </Stack>
                    </Stack>
                </div>
            ),
        },
        // {
        //     title: "Adjustment Unit",
        //     dataIndex: "adjustmentUnit",
        //     key: "adjustmentUnit",
        //     align: "center",
        // },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            align: "center",
            render: (_, record, index) => {
                return (
                    <Button
                        category="icon"
                        variant="link"
                        children="delete"
                        onClick={() => deleteItem(_, record, index)}
                        size="small"
                        iconSize={16}
                    />
                );
            },
        },
    ];

    // const missingBasicInfoMessage =
    // 	missingOfferFieldNames.length > 0
    // 		? `${missingOfferFieldNames.join(
    // 				', '
    // 		  )} fields are missing in Basic Information.`
    // 		: '';

    return (
        <div>
            {validationMessage && (
                <Alert message={validationMessage} showIcon type="error" />
            )}
            <div style={{ padding: "20px" }}>
                <Space direction="vertical" style={{ width: "98%" }}>
                    <Row className="form-row">
                        <Col md={7}>
                            <div className="form-lbl-wrapper">
                                <Label>Adjusted Date and Time</Label>
                            </div>
                        </Col>
                        <Col md={16}>
                            <InputBox
                                type="text"
                                placeholder={props.currentDateNTime}
                                disabled
                            />
                        </Col>
                    </Row>
                    <Row className="form-row">
                        <Col md={7}>
                            <div className="form-lbl-wrapper">
                                <Label>Reason</Label>
                            </div>
                        </Col>
                        <Col md={16}>
                            <Select
                                options={
                                    adjustedReason && adjustedReason.length > 0
                                        ? adjustedReason.map((reasons) => {
                                              return {
                                                  value: reasons,
                                                  label: reasons,
                                              };
                                          })
                                        : []
                                }
                                onChange={(e) => handleChangeInput(e, "reason")}
                                value={formData.reason}
                                placeholder="Reason"
                                // hasError={validations.mainCategory}
                            ></Select>
                        </Col>
                    </Row>
                    <Row className="form-row">
                        <Col md={7}>
                            <div className="form-lbl-wrapper">
                                <Label>Comment</Label>
                            </div>
                        </Col>
                        <Col md={16}>
                            <TextArea
                                type="text"
                                placeholder="Comment"
                                value={formData.comment}
                                onChange={(e) =>
                                    handleChangeInput(e.target.value, "comment")
                                }
                                // maxLength='100'
                            />
                        </Col>
                    </Row>
                    <Row className="form-row">
                        <Col md={7}>
                            <div className="form-lbl-wrapper">
                                <Label>Location</Label>
                            </div>
                        </Col>
                        <Col md={16}>
                            <Select
                                options={
                                    locations && locations.length > 0
                                        ? locations.map((ocl) => {
                                              return {
                                                  value: ocl.locationDetails
                                                      .LocationID,
                                                  label: ocl.locationDetails
                                                      .LocationName,
                                              };
                                          })
                                        : []
                                }
                                // isDisabled={true}
                                placeholder="Select Location"
                                value={formData.location}
                                onChange={(e) =>
                                    handleChangeInput(e, "location")
                                }
                            />
                        </Col>
                    </Row>
                    <Row className="form-row">
                        <Col md={7}>
                            <div className="form-lbl-wrapper">
                                <Label>No of items</Label>
                            </div>
                        </Col>
                        <Col md={16}>
                            <InputBox
                                type="number"
                                placeholder="No of items"
                                value={tableData.length}
                                disabled
                            />
                        </Col>
                    </Row>
                </Space>
            </div>
            <Row className="eco-mb-24"></Row>
            <Table
                columns={addItemsColumns}
                dataSource={tableData}
                type="striped"
                className="eco-mb-24"
            />
            <Button variant="link" onClick={() => addItems()}>
                Add Item
            </Button>
        </div>
    );
};

export default CreationForm;
