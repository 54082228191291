import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Space, Search, InputBox, Card, Button, Stack, StackItem, Icon, Message, FileUploader, async } from 'witmeg-ui-system';
import { addEposProductBrand, updateEposProductBrand } from '../../../../../../utilities/apiRequests/witmegWebNeurolageServerRequests';
import { sectionName as dashboardSectionName } from "../../../../../../redux/slices/dashboard/dashboardConstants";


const ProductBrand = (props) => {
    const {
        brands,
        hideAddBrandCard,
        showAddBrandCard,
        showAddCard,



    } = props;
    const [apiValidationError, setApiValidationError] = useState(null);
    const dashboardState = useSelector((state) => state[dashboardSectionName].dashboard);
    const userState = useSelector((state) => state.user.userDetails);
    const [brandInput, setBrandInput] = useState('');
    //  const [addedBrands, setAddedBrands] = useState([{}]);



    const addProductBrand = async (e) => {
        setApiValidationError(null);
        setBrandInput('')

        console.log('added brands ---->', brands)
        const apiReqBody = {
            "BrandName": brandInput,
            "Description": brandInput,
            "CreatedDate": new Date().toISOString(),
            "ModifiedDate": new Date().toISOString(),
            "ModifiedBy": userState.UserId,
            "IsDeleted": userState.UserId,
            "OrgID": dashboardState.currentlySelectedOrgId,
            "CloudCreatedBy": userState.UserId,
            "CloudModifiedBy": userState.UserId,
        }

        let AddRes = await addEposProductBrand(apiReqBody)
        if (AddRes) {
            if (AddRes.hasError) {
                setApiValidationError(AddRes);
                return;
            }
            Message.success(
                `Brand ${brandInput} is added successfully.`

            );
        } else {
            Message.error(
                `Brand ${brandInput} is not added.Please try again`
            )
        }
    }

    const handleDeleteBrand = async (e, brand) => {
        // alert(brand.BrandID)
        // const apiReqBody = {
        //     ...brand,
        //     IsDeleted: true
        // }
        const apiReqBody =
        {
            "BrandID": brand.BrandID,
            "BrandName": brand.BrandName,
            "Description": brand.BrandName,
            "CreatedDate": new Date().toISOString(),
            "CreatedBy": userState.UserId,
            "ModifiedDate": new Date().toISOString(),
            "ModifiedBy": userState.UserId,
            "IsDeleted": true,
            "OrgID": dashboardState.currentlySelectedOrgId,
            "CloudModifiedBy": userState.UserId,

        }

        let AddRes = await updateEposProductBrand(apiReqBody)
        if (AddRes) {
            if (AddRes.hasError) {
                setApiValidationError(AddRes);
                return;
            }
            Message.success(
                `Brand ${brand.BrandName} is deleted successfully.`
            );
        } else {
            Message.error(
                `Brand ${brand.BrandName} is not deleted.Please try again`
            )
        }




    }
    // useEffect(async () => {
    //     const apiReqBody = {
    //         "BrandName": brandInput,
    //         "Description": brandInput,
    //         "CreatedDate": new Date().toISOString(),
    //         "ModifiedDate": new Date().toISOString(),
    //         "ModifiedBy": userState.UserId,
    //         "IsDeleted": userState.UserId,
    //         "OrgID": dashboardState.currentlySelectedOrgId,
    //         "CloudCreatedBy": userState.UserId,
    //         "CloudModifiedBy": userState.UserId,
    //     }

    //     let AddRes = await addEposProductBrand(apiReqBody)

    //     if (AddRes) {
    //         if (AddRes.hasError) {
    //             setApiValidationError(AddRes);
    //             return;
    //         }
    //         Message.success(
    //             `Brand is added successfully.`
    //         );
    //     } else {
    //         Message.error(
    //             `Brand not added. please try again`
    //         )
    //     }
    // }, [apiValidationError, brandInput])





    return (

        <Space direction="vertical" style={{ width: '100%', objectfit: 'contain ' }}>
            <Space direction="vertical" style={{ width: '100%', objectfit: 'contain ' }} >
                <div className="brand__search">
                    <Search
                        style={{
                            width: '100%',
                            border: '1px solid #cecec',
                            objectfit: 'contain '

                        }}
                        type='default'
                        direction='column'
                        placeholder='Search Brand...'
                    >
                    </Search>
                </div>
            </Space >
            <Space direction="vertical" style={{ width: '100%', objectfit: 'contain ' }}>
                {(showAddCard === true) ? (
                    <Card
                        width='100%'
                        shadow="default"
                        disabled={false}
                        type={'complex'}
                        height="70px"
                        // action={showBrandCard}

                        content={
                            <Stack horizontalAlign='space_between'>
                                <Stack direction="row" horizontalAlign="space_evenly" style={{ width: '100%', objectfit: 'contain ' }}>
                                    {/* <FileUploader acceptFiles="image/*" maxSize={25} /> */}
                                </Stack>
                                <Stack >
                                    <InputBox type="text" value={brandInput} onChange={(e) => { setBrandInput(e.target.value) }} ></InputBox>
                                </Stack>
                                <Stack >
                                    <Button
                                        onClick={addProductBrand}
                                        customStyles={{ border: 'none' }}>
                                        <Icon name='save' color='blue' />
                                    </Button>
                                    <Button
                                        onClick={hideAddBrandCard}
                                        customStyles={{ border: 'none' }}>
                                        <Icon
                                            name='cancel' color='red' />
                                    </Button>
                                </Stack>
                            </Stack>
                        }
                    />
                ) : null}

            </Space>
            <Space direction="vertical" style={{ width: '100%', objectfit: 'contain ' }}>
                {brands.map(brand => (
                    <Card
                        key={``}
                        shadow="default"
                        disabled={false}
                        type={'complex'}
                        content={
                            <Stack horizontalAlign='space_between' >
                                <StackItem style={{}}>
                                    <Stack direction="row" horizontalAlign="space_evenly" style={{ width: '100%', objectfit: 'contain ' }}>
                                        <div >
                                            <img src='img' />
                                        </div>
                                        <div >
                                            {brand.BrandName}
                                        </div>
                                    </Stack>
                                </StackItem>
                                <StackItem>
                                    <Button customStyles={{ border: 'none' }}>
                                        <Icon name='pencil' size={16} />
                                    </Button>
                                    <Button
                                        onClick={(e) => handleDeleteBrand(e, brand)}
                                        customStyles={{ border: 'none' }}>
                                        <Icon name='trashcan' size={16} color='red' />
                                    </Button>
                                </StackItem >
                            </Stack>
                        }
                        width="100%"
                        height="fit-content"
                    />))}
            </Space>
        </Space>

    )
}

export default ProductBrand;