import React,{useState, useEffect} from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    registerables
} from 'chart.js';
import { Chart  } from 'react-chartjs-2';
import { Stack } from 'witmeg-ui-system';
import moment from 'moment';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ...registerables
);


export default function SummaryChart(props) {

    // const DATA_COUNT = 7;
    // const NUMBER_CFG = {count: DATA_COUNT, min: -100, max: 100};
    
    // const labels = Utils.months({count: 7});    

    const {summaryData, selectedPeriod} = props
    // const [chartLabels, setChartLabels] = useState([])
    // const [chartDetails, setChartDetails]= useState({
    //     labels: labels,
    //     datasets: [
    //       {
    //         label: 'Dataset 1',
    //         data: Utils.numbers(NUMBER_CFG),
    //         borderColor: Utils.CHART_COLORS.red,
    //         backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red, 0.5),
    //       },
    //       {
    //         label: 'Dataset 2',
    //         data: Utils.numbers(NUMBER_CFG),
    //         borderColor: Utils.CHART_COLORS.blue,
    //         backgroundColor: Utils.transparentize(Utils.CHART_COLORS.blue, 0.5),
    //       }
    //     ],
    //     options: {
    //         responsive: true,
    //         plugins: {
    //             legend: {
    //                 position: 'top',
    //                 labels: {
    //                     boxHeight: 1,
    //                     border: 0
    //                 },
    //                 title: {
    //                     padding: 10
    //                 }
    //             },
    //             title: {
    //                 display: true,
    //                 text: '',
    //             },
    //         },
    //         scales: {
    //             x: {
    //                 grid: {
    //                     display: false
    //                 },
    //                 title: {
    //                     display: true,
    //                     align: "center",
    //                     text: 'Days',
    //                     font: {
    //                         size: 18
    //                     }
    //                 }
    //             },
    //             y: {
    //                 beginAtZero: true,
    //                 title: {
    //                     display: true,
    //                     align: "center",
    //                     text: 'Count',
    //                     font: {
    //                         size: 18
    //                     }
    //                 }                        
    //             }
    //         }
    //     },        
    //   });

    const [chartDetails, setChartDetails] = useState(null);

    useEffect(() => {
        let labels=summaryData? 
        summaryData.SalesActivityData.DailyData.map(dd => dd.DateOfActivity.substring(0,10)) 
        :[...Array(selectedPeriod.value).keys()].map(index => {
            const date = new Date();
            date.setDate(date.getDate() - index);
        
            return moment(date).format('YYYY-MM-DD');
        }).reverse();

        console.log('selected Period', selectedPeriod);

        console.log('Sales', summaryData?summaryData.SalesActivityData?.DailyData.map(dd => dd.TotalQuantity):Array.apply(null, Array(selectedPeriod.value)).map(Number.prototype.valueOf,0));

        console.log('Purchased', summaryData && summaryData.PurchaseActivityData.DailyData? summaryData.PurchaseActivityData.DailyData.map(pd => pd.TotalQuantity) : Array.apply(null, Array(selectedPeriod.value)).map(Number.prototype.valueOf,0));

        setChartDetails({
            chartType:'line',
            data:{
                labels: labels,
                datasets: [                
                    {
                        label: 'Sold',
                        data: summaryData?summaryData.SalesActivityData?.DailyData.map(dd => dd.TotalQuantity):Array.apply(null, Array(selectedPeriod.value)).map(Number.prototype.valueOf,0),
                        borderColor: '#039BE5',
                        backgroundColor: '#039BE5',
                        borderWidth: 1,
                        pointRadius: 0,
                    },
                    {
                        label: 'Damaged',
                        data: summaryData?summaryData.SalesActivityData?.DailyData.map(dd => dd.TotalQuantity):Array.apply(null, Array(selectedPeriod.value)).map(Number.prototype.valueOf,0),
                        borderColor: '#7738C7',
                        backgroundColor: '#7738C7',
                        borderWidth: 1,
                        pointRadius: 0,
                    },                        
                    {
                        label: 'Purchased',
                        data: summaryData && summaryData.PurchaseActivityData.DailyData? summaryData.PurchaseActivityData.DailyData.map(pd => pd.TotalQuantity) : Array.apply(null, Array(selectedPeriod.value)).map(Number.prototype.valueOf,0),
                        borderColor: '#D67C29',
                        backgroundColor: '#D67C29',
                        borderWidth: 1,
                        pointRadius: 0,
                    },
                    {
                        label: 'Expired',
                        data: summaryData && summaryData.PurchaseActivityData.DailyData? summaryData.PurchaseActivityData.DailyData.map(pd => pd.TotalQuantity) : Array.apply(null, Array(selectedPeriod.value)).map(Number.prototype.valueOf,0),
                        borderColor: '#38C7BE',
                        backgroundColor: '#38C7BE',
                        borderWidth: 1,
                        pointRadius: 0,
                    }                    
                ]
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                        labels: {
                            boxHeight: 1,
                            border: 0
                        },
                        title: {
                            padding: 5
                        }
                    },
                    title: {
                        display: true,
                        text: '',
                    },
                },
                scales: {
                    x: {
                        grid: {
                            display: false
                        },
                        title: {
                            display: true,
                            align: "center",
                            text: 'Days',
                            font: {
                                size: 12
                            }
                        }
                    },
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            align: "center",
                            text: 'Count',
                            font: {
                                size: 12
                            }
                        }                        
                    }
                }
            },
            redraw: true
    
        })

    }, [summaryData, selectedPeriod])
    

    return (
        <Stack direction="row">
            {chartDetails?
                <Chart options={chartDetails.options} data={chartDetails.data} type={chartDetails.chartType} />
            :null}
        </Stack>
    )
}
