/* eslint-disable no-shadow */

import {
	SelectOutlined as SelectOutlinedIcon,
	SearchOutlined as SearchOutlinedIcon,
} from '@ant-design/icons';
import { Input } from 'formik-antd';
import React, { Component } from 'react';

import { getEposSuppliersByCompanyName } from '../../../../../../../utilities/apiRequests/witmegWebNeurolageServerRequests';
import FieldErrorMessage from '../../../../../../widgets/formRelated/FieldErrorMessage/FieldErrorMessage';
import CustomInputSearcher from '../../../../../../widgets/formRelated/OmniBar/OmniBar';
import SupplierList from '../../../../../SupplierList/SupplierList';
import { getFormSectionsPartialObjPaths } from '../../../../helpers/parserHelpers';

const { supplierInfoObjPath } = getFormSectionsPartialObjPaths().masterTab;

// NOTE : Helper component to give user inline Supplier Searching Ability.

export default class SupplierInputSearcher extends Component {
	constructor(props) {
		super(props)
		this.state = {
			searchText: props.defaultValue
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.defaultValue !== this.props.defaultValue) {
			this.setState({
				searchText: this.props.defaultValue
			})
		}
	}
	render() {
		const { selectedOrgId, selectedLocationId, helperFns, selectedPurchasOderId } = this.props;
		const { searchText } = this.state
		return (
			<CustomInputSearcher
				//
				// This is what initially displayed to user. Clicking on this invoke popup area and searching.
				displayComponent={
					<div>
						<Input
							name={`${supplierInfoObjPath}supplierName`}
							prefix={<SearchOutlinedIcon />}
							placeholder='Start Typing to Inline Search Suppliers'
							value={searchText}
							placeholder={this.props.defaultValue}
							disabled={selectedPurchasOderId}
						/>
						<FieldErrorMessage
							name={`${supplierInfoObjPath}supplierName`}
							style={{
								position: 'absolute',
							}}
						/>
					</div>
				}
				//
				// Will be invoked when Search Input is changing. Its result will passed to bottom "popupContentGenerator()"
				onSearchInputChange={async (data = {}) => {
					const { searchTerm } = data;
					this.setState({
						searchText: searchTerm
					})
					return await getEposSuppliersByCompanyName({
						orgId: selectedOrgId,
						locationId: selectedLocationId,
						searchKey: searchTerm,
					});

				}}
				//
				// Helper function to determine no items for its internal purposes.
				isEmptyResultChecker={(data = {}) => {
					const { rawSearchResult = {} } = data;
					return rawSearchResult && rawSearchResult.length === 0;
				}}
				//
				// Determine what to show in popup area.
				popupContentGenerator={(data = {}) => {
					console.log("data===>>", data)
					const { rawSearchResult, closePopup, searchTerm } = data;
					const { searchText } = this.state
					console.log("searchTerm===>>", this.state.searchText)
					return (
						<div>
							<SupplierList
								tableCompProps={{
									pagination: {
										pageSize: 100,
									},
								}}
								rawSupplierList={rawSearchResult.length > 0 ? rawSearchResult.filter(rs => (rs.Name && rs.Name.toLowerCase()).includes(searchText.toLocaleLowerCase())) : []}
								handleSelect={(data) => {
									this.props.handleSelect(data);
									closePopup();
								}}
							// customizationProps={{
							// 	customRawColumnListGenerator: (data = {}) => {
							// 		return {
							// 			SupplierId: {
							// 				ColumnName: 'Supplier ID',
							// 				IsFilter: true,
							// 				IsVisible: true,
							// 				ValuePath: 'ID',
							// 			},
							// 			SupplierName: {
							// 				ColumnName: 'Name',
							// 				IsFilter: true,
							// 				IsVisible: true,
							// 				ValuePath: 'Supplier.Name',
							// 			},
							// 			PostCode: {
							// 				ColumnName: 'PostCode',
							// 				IsFilter: true,
							// 				IsVisible: true,
							// 				ValuePath: 'Contact.PostCode',
							// 			},
							// 		};
							// 	},
							// 	customActionColumnButtonGenerator: (data = {}) => {
							// 		const { selectedRowData } = data;

							// 		return (
							// 			<SelectOutlinedIcon
							// 				title='Select'
							// 				onClick={() => {
							// 					helperFns.onSupplierSelected({
							// 						selectedRowData,
							// 						...data,
							// 					});

							// 					closePopup();
							// 				}}
							// 			/>
							// 		);
							// },
							// }}
							/>
						</div>
					);
				}}
			/>
		);
	}
}
