import { SearchOutlined as SearchIcon } from '@ant-design/icons';
import { bindActionCreators } from '@reduxjs/toolkit';
import { Input, Select, Button, Tooltip, Row, Col } from 'antd';
import { LoaderView } from 'ecologital-ui-library';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { reqStatusTypes } from '../../../redux/helpers/constants';
import { actions as customersPageSliceActions } from '../../../redux/slices/dashboard/customersPageSlice/customersPageSlice';
import { sectionName as dashboardSectionName } from '../../../redux/slices/dashboard/dashboardConstants';
import { actions as dashboardSliceActions } from '../../../redux/slices/dashboard/dashboardSlice/dashboardSlice';
import {
	getEposCustomersCountByOrgId,
	getEposCustomersByOrgId,
	getEposCustomersByCompanyName,
	getEposCustomersByPostCode,
	getEposCustomersByAcNumber,
	getEposCustomersByCity,
	getEposCustomersByEmail,
} from '../../../utilities/apiRequests/witmegWebNeurolageServerRequests';
import { errorLogger } from '../../../utilities/errorHandlers';
import CustomerList from '../CustomerList/CustomerList';

import './CustomerSearcher.css';

// Possible Search Types & Their Configs.
const searchTypeList = {
	byAll: {
		label: 'ALL',
		key: 'byAll',
		searchPlaceholderText: '',
	},
	byCustomerName: {
		label: 'CUSTOMER NAME',
		key: 'byCustomerName',
		searchPlaceholderText: 'Search by Customer Name',
	},
	byPostCode: {
		label: 'POST CODE',
		key: 'byPostCode',
		searchPlaceholderText: 'Search by PostCode',
	},
	byAcNumber: {
		label: 'AC NUMBER',
		key: 'byAcNumber',
		searchPlaceholderText: 'Search by Account Number',
	},
	byCity: {
		label: 'CITY',
		key: 'byCity',
		searchPlaceholderText: 'Search by City',
	},
	byEmail: {
		label: 'EMAIL',
		key: 'byEmail',
		searchPlaceholderText: 'Search by Customer Email',
		hidden: true, // Temporally hiding from UI.
	},
};

// NOTE : In here we define the "Search Types" that must have a "searchTerm" to perform the relevant API call.
const searchTypesThatMustHaveSearchTerm = new Set([
	searchTypeList.byCustomerName.key,
	searchTypeList.byPostCode.key,
	searchTypeList.byAcNumber.key,
	searchTypeList.byCity.key,
	searchTypeList.byEmail.key,
]);

// NOTE : In here we define the "Search Types" that currently don't provide a way to use "searchTerm" in relevant API call.
// 				For example in 'byAll' search it return all products of mentioned organization. We can't specify we want 'London' customer on that list.
const searchTypesThatDontSupportSearchTerm = new Set([
	searchTypeList.byAll.key,
]);

const initialComponentState = {
	// Search related state.
	searchType: Object.values(searchTypeList)[0].key, // Determine which kind of search is performed.
	searchTerm: '', // Keyword to search with-in selected ''searchType'.
	rawCustomerSearchResult: {}, // Keep raw result from API, when search is performed.
	searchingReqState: reqStatusTypes.idle,
	searchingReqError: '',
	searchingFormValidationMsg: '', // This is used to show some validation message in Search Inputs, If needed.
	disableSearchTermInput: false,

	// Search result's pagination related state.
	totalCustomerItems: 0, // Track how many customer items totally available in DB for performed search.
	numOfItemsPerPage: 10,
	currentPaginationIndex: 0,
};

class CustomerSearcher extends Component {
	constructor(props) {
		super(props);

		this.state = initialComponentState;
	}

	// Performing suitable search requests, according to selected 'searchType'.
	async handleCustomerSearch(options = {}) {
		const {
			/**
			 * Using this to make some changes to fetching logic when true.
			 * For example when clicked on a pagination number most of the things are same (Like Total Count Of Items, Search Type, Etc... ) and in most cases we only need to fetch next set of data.
			 * 	- So we can avoid re-fetching total count of items, etc..
			 */
			isSearchFromPagination = false,
		} = options;

		try {
			const {
				searchType,
				searchTerm,
				rawCustomerSearchResult,

				numOfItemsPerPage,
				totalCustomerItems,
				currentPaginationIndex,
			} = this.state;

			const { dashboardSliceState } = this.props;
			const { currentlySelectedOrgId, currentlySelectedLocationId } =
				dashboardSliceState;

			// For the "search types" that must have a searchTerm (IE: Currently All Types) to perform the relevant API call,
			// 		- When "Search Term" is NOT provided, in here we are avoid invoking search APIs.
			// 				- Also showing user searchTerm input value is required. (Ex. 'Required' tooltip)
			if (
				searchTypesThatMustHaveSearchTerm.has(searchType) &&
				searchTerm === ''
			) {
				return this.setState({
					searchingFormValidationMsg: 'Required',
				});
			}

			// Disabling "Search Input Box" for Search Types that currently don't support per se 'searchTerm'.
			if (searchTypesThatDontSupportSearchTerm.has(searchType)) {
				this.setState({
					disableSearchTermInput: true,
				});
			} else {
				this.setState({
					disableSearchTermInput: false,
				});
			}

			this.setState({
				searchingReqState: reqStatusTypes.pending,
				searchingReqError: '',
			});

			// NOTE : For some API's functions, some of these key values may not needed. So they will be just ignored on there.
			const commonReqParams = {
				orgId: currentlySelectedOrgId,
				locationId: currentlySelectedLocationId,
				skip: numOfItemsPerPage * currentPaginationIndex,
				limit: numOfItemsPerPage,
				searchKey: searchTerm,
			};

			let rawSearchResult = []; // This value will be updated on relevant searchType section.
			let countOfTotalCustomerItemsForSearch = 0; // Track how many total product available in DB for current search. Mostly used to calculate no of pagination pages.

			// Defining what to do in each specific 'searchType'.
			switch (searchType) {
				case searchTypeList.byAll.key: {
					let totalItemsForByAll = 0;

					if (isSearchFromPagination) {
						// This "if' logic is just to avoid unnecessary fetching.
						totalItemsForByAll = totalCustomerItems;
					} else {
						totalItemsForByAll = await getEposCustomersCountByOrgId({
							...commonReqParams,
						});
					}

					const byAllResult = await getEposCustomersByOrgId({
						...commonReqParams,
						// searchKey: searchTerm, // Currently this API endpoint don't support search within. It return all the customer in provided org.
					});

					rawSearchResult = byAllResult;
					countOfTotalCustomerItemsForSearch = totalItemsForByAll;
					break;
				}

				case searchTypeList.byCustomerName.key: {
					if (isSearchFromPagination) {
						// NOTE : This API endpoint don't support pagination fetch. So it fetch all the relevant data.
						//				So when invoked from 'Pagination' button, We avoid un-necessary re-fetching (Since all data already fetched) by breaking here with existing data.
						rawSearchResult = rawCustomerSearchResult;
						countOfTotalCustomerItemsForSearch = totalCustomerItems;
						break;
					}

					// In here figuratively "byCustomer" mean same as "byCompanyName"
					const byCustomerResult = await getEposCustomersByCompanyName({
						...commonReqParams,
					});

					rawSearchResult = byCustomerResult;
					countOfTotalCustomerItemsForSearch = byCustomerResult.length;
					break;
				}

				case searchTypeList.byPostCode.key: {
					if (isSearchFromPagination) {
						// NOTE : This API endpoint don't support pagination fetch. So it fetch all the relevant data.
						//				So when invoked from 'Pagination' button, We avoid un-necessary re-fetching (Since all data already fetched) by breaking here with existing data.
						rawSearchResult = rawCustomerSearchResult;
						countOfTotalCustomerItemsForSearch = totalCustomerItems;
						break;
					}

					const byPostCodeResult = await getEposCustomersByPostCode({
						...commonReqParams,
					});

					rawSearchResult = byPostCodeResult;
					countOfTotalCustomerItemsForSearch = byPostCodeResult.length;
					break;
				}

				case searchTypeList.byAcNumber.key: {
					if (isSearchFromPagination) {
						// NOTE : This API endpoint don't support pagination fetch. So it fetch all the relevant data.
						//				So when invoked from 'Pagination' button, We avoid un-necessary re-fetching (Since all data already fetched) by breaking here with existing data.
						rawSearchResult = rawCustomerSearchResult;
						countOfTotalCustomerItemsForSearch = totalCustomerItems;
						break;
					}

					const byAcNumberResult = await getEposCustomersByAcNumber({
						...commonReqParams,
					});

					rawSearchResult = byAcNumberResult;
					countOfTotalCustomerItemsForSearch = byAcNumberResult.length;
					break;
				}

				case searchTypeList.byCity.key: {
					if (isSearchFromPagination) {
						// NOTE : This API endpoint don't support pagination fetch. So it fetch all the relevant data.
						//				So when invoked from 'Pagination' button, We avoid un-necessary re-fetching (Since all data already fetched) by breaking here with existing data.
						rawSearchResult = rawCustomerSearchResult;
						countOfTotalCustomerItemsForSearch = totalCustomerItems;
						break;
					}

					const byCityResult = await getEposCustomersByCity({
						...commonReqParams,
					});

					rawSearchResult = byCityResult;
					countOfTotalCustomerItemsForSearch = byCityResult.length;
					break;
				}

				case searchTypeList.byEmail.key: {
					if (isSearchFromPagination) {
						// NOTE : This API endpoint don't support pagination fetch. So it fetch all the relevant data.
						//				So when invoked from 'Pagination' button, We avoid un-necessary re-fetching (Since all data already fetched) by breaking here with existing data.
						rawSearchResult = rawCustomerSearchResult;
						countOfTotalCustomerItemsForSearch = totalCustomerItems;
						break;
					}

					const byEmailResult = await getEposCustomersByEmail({
						...commonReqParams,
					});

					rawSearchResult = byEmailResult;
					countOfTotalCustomerItemsForSearch = byEmailResult.length;
					break;
				}

				default: {
					break;
				}
			}

			return this.setState({
				searchingReqState: reqStatusTypes.succeeded,
				rawCustomerSearchResult: rawSearchResult,

				totalCustomerItems: countOfTotalCustomerItemsForSearch,
			});
		} catch (error) {
			errorLogger(error);

			return this.setState({
				searchingReqState: reqStatusTypes.failed,
				searchingReqError: 'Error occurred while searching.',
			});
		}
	}

	// This is a helper function to be used in SearchType Selector's onChange() handlers. (May be some other relevant places.)
	// This initiate the search process only if currently selected 'searchType' is not need a searchTerm as mandatory field.
	// 		- So in simple sense this automatically perform search when Select Box are changes if applicable.
	searchIfItsAutomaticSearchType() {
		const { searchType } = this.state;

		if (!searchTypesThatMustHaveSearchTerm.has(searchType)) {
			this.handleCustomerSearch();
		} else {
			// Came to here mean it's a searchType that need searchTerm.
			// So we enable searchTerm input box, just in case its disabled on state.
			this.setState({ disableSearchTermInput: false });
		}
	}

	// Changing/Re-setting component's state to make page item are in pristine state.
	resetPage(cb = () => {}) {
		this.setState(initialComponentState, () => {
			// Calling after initial State is resetted.
			cb();
		});
	}

	componentDidMount() {
		// Firing this here to invoke search if page's initially selected searchType is Automatic. So user initially see some results when page is opened.
		this.searchIfItsAutomaticSearchType();
	}

	componentDidUpdate() {
		// NOTE : TEMPORARY SOLUTION. (Need better solution by moving this pages state to Redux.)
		// NOTE : There is some need for remotely reset this page, when some things is being done. (For example when Customer Is Updated, But this page still show old data)
		//        So in here we add temporary way to do that with some redux action invocation.
		const resetPageWhenRemoteCustomersPageResetterFired = () => {
			const shouldInvokeRemotePageReset =
				// eslint-disable-next-line react/destructuring-assignment
				this.props.customersPageSliceState.shouldInvokeCustomerPageResetter;

			if (shouldInvokeRemotePageReset === true) {
				// Immediately disabling further Resetting by disable fire of "invokeCustomersPageResetter()" Redux Action.
				// Otherwise there will be unlimited re-renders.
				// eslint-disable-next-line react/destructuring-assignment
				this.props.customersPageSliceActions.invokeCustomersPageResetter(false);

				// Re-setting The Page.
				this.resetPage(() => {
					this.searchIfItsAutomaticSearchType();
				});
			}
		};

		resetPageWhenRemoteCustomersPageResetterFired();
	}

	render() {
		const {
			searchType,
			searchTerm,
			rawCustomerSearchResult,
			searchingReqState,
			searchingReqError,
			searchingFormValidationMsg,
			disableSearchTermInput,

			totalCustomerItems,
			numOfItemsPerPage,
			currentPaginationIndex,
		} = this.state;

		const {
			dashboardSliceState,
			customerListCustomizationProps = {}, // Optional props that will be used in CustomerList to customize various things if necessary. (Ex. Adding custom action button to Action Column.)
		} = this.props;

		const { currentlySelectedOrgId, currentlySelectedLocationId } =
			dashboardSliceState;

		return (
			<div className='CustomerSearcher'>
				<div className='CustomerSearcher__customerSearcher'>
					<Row>
						<Col xs={24} sm={7} md={6} lg={5} xl={4} xxl={3}>
							<Select
								style={{
									width: '100%',
								}}
								value={searchType}
								options={Object.values(searchTypeList)
									.filter((type) => !type.hidden)
									.map((type) => ({
										label: type.label,
										value: type.key,
									}))}
								onChange={(value) => {
									this.setState(
										{
											searchingReqState: reqStatusTypes.idle,
											searchType: value,
											searchTerm: '', // Resetting search term when search type is changed.
											currentPaginationIndex: 0,
										},
										() => {
											this.searchIfItsAutomaticSearchType();
										},
									);
								}}
							/>
						</Col>

						<Col flex={1}>
							<div className='CustomerSearcher__customerSearcher__searchInputGroup'>
								<Tooltip
									placement='bottomRight'
									title={searchingFormValidationMsg}
									trigger='click'
									visible={Boolean(searchingFormValidationMsg)}
									onVisibleChange={() => {
										// Closing tooltip when user clicked on anywhere on the page.
										setTimeout(() => {
											this.setState({
												searchingFormValidationMsg: false,
											});
										}, 100);
									}}>
									<Input
										style={{
											width: '100%',
										}}
										type='input'
										placeholder={
											searchTypeList[searchType].searchPlaceholderText
										}
										value={searchTerm}
										onChange={(e) => {
											this.setState({
												searchTerm: e.target.value,
											});
										}}
										onPressEnter={() => {
											this.handleCustomerSearch();
										}}
										disabled={disableSearchTermInput}
									/>
								</Tooltip>

								<Button
									icon={<SearchIcon />}
									loading={searchingReqState === reqStatusTypes.pending}
									onClick={() => {
										this.setState(
											{
												currentPaginationIndex: 0,
											},
											() => {
												this.handleCustomerSearch();
											},
										);
									}}
								/>
							</div>
						</Col>
					</Row>
				</div>

				<div className='CustomerSearcher_____contentWrapper'>
					<LoaderView
						isInfo={searchingReqState === reqStatusTypes.idle}
						isLoading={searchingReqState === reqStatusTypes.pending}
						isError={searchingReqState === reqStatusTypes.failed}
						isEmpty={
							searchingReqState === reqStatusTypes.succeeded &&
							rawCustomerSearchResult.Result.length === 0
						}
						typeConfigs={{
							info: {
								subTitle: 'Initiate a Search to See Customers.',
								icon: <SearchIcon />,
							},

							error: {
								subTitle: searchingReqError,
								extra: (
									<>
										<Button
											type='primary'
											onClick={() => {
												this.handleCustomerSearch();
											}}>
											TRY AGAIN
										</Button>
										<Button
											onClick={() => {
												this.setState({
													searchingReqState: reqStatusTypes.idle,
													searchTerm: '',
												});
											}}>
											CANCEL
										</Button>
									</>
								),
							},

							empty: {
								subTitle: 'No Customers Found.',
							},
						}}
					/>

					<div className='CustomerSearcher__customerList'>
						{searchingReqState === reqStatusTypes.succeeded &&
							rawCustomerSearchResult.Result.length > 0 && (
								<CustomerList
									rawCustomerList={rawCustomerSearchResult.Result}
									rawFilterData={rawCustomerSearchResult.Filter}
									selectedOrgId={currentlySelectedOrgId}
									selectedLocationId={currentlySelectedLocationId}
									tableCompProps={{
										pagination: {
											current: currentPaginationIndex + 1,
											total: totalCustomerItems,
											pageSize: numOfItemsPerPage,
											onChange: (pageNo, pageSize) => {
												// NOTE: This 'pageNo' is start by '1' not '0'

												this.setState(
													{
														currentPaginationIndex: pageNo - 1,
													},
													() => {
														this.handleCustomerSearch({
															isSearchFromPagination: true,
														});
													},
												);
											},
										},
									}}
									customizationProps={customerListCustomizationProps}
								/>
							)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	dashboardSliceState: state[dashboardSectionName].dashboard,
	customersPageSliceState: state[dashboardSectionName].customersPage,
});

const mapDispatchToProps = (dispatch) => {
	const boundDashboardSliceActions = bindActionCreators(
		dashboardSliceActions,
		dispatch,
	);

	const boundCustomersPageSliceActions = bindActionCreators(
		customersPageSliceActions,
		dispatch,
	);

	return {
		dashboardSliceActions: boundDashboardSliceActions,
		customersPageSliceActions: boundCustomersPageSliceActions,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSearcher);
