import { SearchOutlined as SearchIcon } from '@ant-design/icons';
import { bindActionCreators } from '@reduxjs/toolkit';
import { Input, Select, Button, Tooltip, Row, Col } from 'antd';
import { LoaderView } from 'ecologital-ui-library';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { reqStatusTypes } from '../../../redux/helpers/constants';
import { sectionName as dashboardSectionName } from '../../../redux/slices/dashboard/dashboardConstants';
import { actions as dashboardSliceActions } from '../../../redux/slices/dashboard/dashboardSlice/dashboardSlice';
import { actions as inventoryProductsPageSliceActions } from '../../../redux/slices/dashboard/inventoryProductsPageSlice/inventoryProductsPageSlice';
import {
	getEposProductCategoriesByOrgId,
	getEposProductBrandsByOrgId,
} from '../../../utilities/apiRequests/witmegWebNeurolageServerRequests';
import { errorLogger } from '../../../utilities/errorHandlers';
import ProductList from '../ProductList/ProductList';

import {
	productFetchingHandlerForGenericType,
	productFetchingHandlerForProformaType,
} from './helpers/searchFetcherHandlers';
import { productSearcherTypes } from './helpers/typeConfigs';

import './ProductSearcher.css';

// Possible Search Types & Their Configs.
const searchTypeList = {
	byProductName: {
		label: 'PRODUCT NAME',
		key: 'byProductName',
		searchPlaceholderText: 'Search by Product Name',
	},

	byCategoryId: {
		label: 'CATEGORY',
		key: 'byCategoryId',
		searchPlaceholderText: '',
	},

	byBrandId: {
		label: 'BRAND',
		key: 'byBrandId',
		searchPlaceholderText: '',
	},
	byStockView: {
		label: 'STOCK VIEW',
		key: 'byStockView',
		searchPlaceholderText: '',
	},
	byBarcode: {
		label: 'BARCODE',
		key: 'byBarcode',
		searchPlaceholderText: 'Search by Barcode',
	},
	byProductRefNo: {
		label: 'ITEM CODE',
		key: 'byProductRefNo',
		searchPlaceholderText: 'Search by Item Code',
	},
};

// NOTE : In here we define the "Search Types" that must have a "searchTerm" to perform the relevant API call.
const searchTypesThatMustHaveSearchTerm = new Set([
	searchTypeList.byProductName.key,
	searchTypeList.byBarcode.key,
	searchTypeList.byProductRefNo.key,
]);

// NOTE : In here we define the "Search Types" that currently don't provide a way to use "searchTerm" in relevant API call.
// 				For example in 'byBrand' search it return all products of mentioned brand. We can't specify we want 'Chocolate 20g' products on that brand.
const searchTypesThatDontSupportSearchTerm = new Set([
	searchTypeList.byCategoryId.key,
	searchTypeList.byBrandId.key,
	searchTypeList.byStockView.key,
]);

const initialComponentState = {
	// Fetching initial page data related state.
	pageInitialDataReqStatus: reqStatusTypes.idle,
	pageInitialDataReqError: '',

	// Search related state.
	searchType: Object.values(searchTypeList)[0].key, // Determine which kind of search is performed.
	searchTerm: '', // Keyword to search with-in selected ''searchType'.
	rawProductSearchResult: {}, // Keep raw result from API, when search is performed.
	searchingReqState: reqStatusTypes.idle,
	searchingReqError: '',
	searchingFormValidationMsg: '', // This is used to show some validation message in Search Inputs, If needed.
	disableSearchTermInput: false,

	// Search result's pagination related state.
	totalProductItems: 0, // Track how many product items totally available in DB for performed search.
	numOfItemsPerPage: 10,
	currentPaginationIndex: 0,

	// Specific 'byCategoryId' type search, related state.
	categoryTypeList: [], // All Category Types.
	categoryTypeId: '', // Track currently selected categoryType.

	// Specific 'byBrandId' type search, related state.
	brandTypeList: [], // All Brand Types.
	brandTypeId: '', // Track currently selected brandType.

	// Specific 'byStockView' type search, related state.
	stockViewTypeList: [], // All StockView Types.
	stockViewTypeId: '', // Track currently selected stockViewType.
};

class ProductSearcher extends Component {
	constructor(props) {
		super(props);

		this.state = initialComponentState;
	}

	async fetchNecessaryInitialPageData() {
		try {
			const { dashboardSliceState } = this.props;
			const { currentlySelectedOrgId } = dashboardSliceState;

			this.setState({
				pageInitialDataReqStatus: reqStatusTypes.pending,
				pageInitialDataReqError: '',
			});

			// Below are pre-fetching data that needed for some specific search types. (Ex. byCategoryId Search --> productCategories)
			// Most of them are to be shown in secondary level select box once relevant primary search type is selected.

			// For "byCategoryId "search.
			const productCategories = await getEposProductCategoriesByOrgId({
				OrgID: currentlySelectedOrgId,
			});

			// For "byBrandId" search.
			const productBrands = await getEposProductBrandsByOrgId({
				OrgID: currentlySelectedOrgId,
			});

			// For "byStock"  search.
			// Manually defining possible stockView types.
			const productStockViews = [
				{ title: 'LOW STOCK ITEMS', ID: 'minOrderReached' },
				{ title: 'EXCESS STOCK ITEMS', ID: 'maxOrderReached' },
			];

			this.setState({
				pageInitialDataReqStatus: reqStatusTypes.succeeded,
				pageInitialDataReqError: '',

				categoryTypeList: productCategories,
				categoryTypeId: productCategories[0] ? productCategories[0].ID : '', // Setting First Category as defaultly selected.

				brandTypeList: productBrands,
				brandTypeId: productBrands[0] ? productBrands[0].ID : '', // Setting First Brand as defaultly selected.

				stockViewTypeList: productStockViews,
				stockViewTypeId: productStockViews[0] ? productStockViews[0].ID : '', // Setting First StockViewType as defaultly selected.
			});
		} catch (error) {
			errorLogger(error);

			this.setState({
				pageInitialDataReqStatus: reqStatusTypes.failed,
				pageInitialDataReqError: 'Error occurred while fetching initial data.',
			});
		}
	}

	// Performing suitable search requests, according to selected 'searchType'.
	async handleProductSearch(options = {}) {
		const {
			/**
			 * Using this to make some changes to fetching logic when true.
			 * For example when clicked on a pagination number most of the things are same (Like Total Count Of Items, Search Type, Etc... ) and in most cases we only need to fetch next set of data.
			 * 	- So we can avoid re-fetching total count of items, etc..
			 */
			isSearchFromPagination = false,
		} = options;

		try {
			const {
				searchType,
				searchTerm,
				categoryTypeId,
				brandTypeId,
				stockViewTypeId,

				numOfItemsPerPage,
				totalProductItems,
				currentPaginationIndex,
			} = this.state;

			const {
				dashboardSliceState,
				productSearcherType = productSearcherTypes.genericType.key,
			} = this.props;
			const { currentlySelectedOrgId, currentlySelectedLocationId } =
				dashboardSliceState;

			// For the "search types" that must have a searchTerm to perform the relevant API call,
			// 		- When "Search Term" is NOT provided, in here we are avoid invoking search APIs.
			// 				- Also showing user searchTerm input value is required. (Ex. 'Required' tooltip)
			if (
				searchTypesThatMustHaveSearchTerm.has(searchType) &&
				searchTerm === ''
			) {
				return this.setState({
					searchingFormValidationMsg: 'Required',
				});
			}

			// Disabling "Search Input Box" for Search Types that currently don't support per se 'searchTerm'.
			if (searchTypesThatDontSupportSearchTerm.has(searchType)) {
				this.setState({
					disableSearchTermInput: true,
				});
			} else {
				this.setState({
					disableSearchTermInput: false,
				});
			}

			this.setState({
				searchingReqState: reqStatusTypes.pending,
				searchingReqError: '',
			});

			// NOTE : For some API's functions, some of these key values may not needed. So they will be just ignored on there.
			const commonReqParams = {
				orgId: currentlySelectedOrgId,
				locationId: currentlySelectedLocationId,
				skip: numOfItemsPerPage * currentPaginationIndex,
				limit: numOfItemsPerPage,
			};

			let rawSearchResult = {}; // This value will be updated on relevant searchType section.
			let countOfTotalProductItemsForSearch = 0; // Track how many total product available in DB for current search. Mostly used to calculate no of pagination pages.
			let productFetchingHandlerData = {};

			const commonFetchingHandlerArgs = {
				searchType,
				searchTypeList,
				isSearchFromPagination,
				commonReqParams,
				totalProductItems,

				searchTerm,
				categoryTypeId,
				brandTypeId,
				stockViewTypeId,
			};

			if (productSearcherType === productSearcherTypes.genericType.key) {
				productFetchingHandlerData = await productFetchingHandlerForGenericType(
					{
						...commonFetchingHandlerArgs,
					},
				);
			} else if (
				productSearcherType === productSearcherTypes.proformaType.key
			) {
				productFetchingHandlerData =
					await productFetchingHandlerForProformaType({
						...commonFetchingHandlerArgs,
					});
			}

			countOfTotalProductItemsForSearch =
				productFetchingHandlerData.totalItemsInCategory;

			rawSearchResult = productFetchingHandlerData.rawSearchResult;

			return this.setState({
				searchingReqState: reqStatusTypes.succeeded,
				rawProductSearchResult: rawSearchResult,

				totalProductItems: countOfTotalProductItemsForSearch,
			});
		} catch (error) {
			errorLogger(error);

			return this.setState({
				searchingReqState: reqStatusTypes.failed,
				searchingReqError: 'Error occurred while searching.',
			});
		}
	}

	// This is a helper function to be used in SearchType Selector's onChange() handlers.
	// This initiate the search process only if currently selected 'searchType' is not need a searchTerm as mandatory field.
	// 		- So in simple sense this automatically perform search when Select Box are changes if applicable.
	searchIfItsAutomaticSearchType() {
		const { searchType } = this.state;

		if (!searchTypesThatMustHaveSearchTerm.has(searchType)) {
			this.handleProductSearch();
		} else {
			// Came to here mean it's a searchType that need searchTerm.
			// So we enable searchTerm input box, just in case its disabled on state.
			this.setState({ disableSearchTermInput: false });
		}
	}

	// This is a helper function, that is used to reset additional Search Type Search Boxes. (Like Category List, Brand List)
	// This is resetting by selecting first one in each item list as selected.
	resetAdditionalFilterSelectBoxes() {
		const { categoryTypeList, brandTypeList, stockViewTypeList } = this.state;

		this.setState({
			categoryTypeId: categoryTypeList[0] ? categoryTypeList[0].ID : '',
			brandTypeId: brandTypeList[0] ? brandTypeList[0].ID : '',
			stockViewTypeId: stockViewTypeList[0] ? stockViewTypeList[0].ID : '',
		});
	}

	// Changing/Re-setting component's state to make page item are in pristine state.
	resetPage(cb = () => {}) {
		this.setState(initialComponentState, () => {
			// Calling after initial State is resetted.
			cb();
		});
	}

	async componentDidMount() {
		await this.fetchNecessaryInitialPageData();
		this.searchIfItsAutomaticSearchType();
	}

	componentDidUpdate(prevProps) {
		const resetPageWhenOrganizationChangedOnRootLevel = () => {
			const previousOrgId =
				// eslint-disable-next-line react/destructuring-assignment
				this.props.dashboardSliceState.currentlySelectedOrgId;
			const newOrgId = prevProps.dashboardSliceState.currentlySelectedOrgId;

			if (newOrgId !== previousOrgId) {
				// When "Organization" changed on some where on root level we make sure page is resetted.
				// Because on this page we are fetching data for one particular organization. When organization changed those data won't matter and user need to refetch things.
				this.resetPage(() => {
					this.fetchNecessaryInitialPageData();
				});
			}
		};

		// NOTE : TEMPORARY SOLUTION. (Need better solution by moving this pages state to Redux.)
		// NOTE : There is some need for remotely reset this page, when some things is being done. (For example when Product Is Updated, But this page still show old data)
		//        So in here we add temporary way to do that with some redux action invocation.
		const resetPageWhenRemoteInventoryProductsPageResetterFired = () => {
			const shouldInvokeRemotePageReset =
				// eslint-disable-next-line react/destructuring-assignment
				this.props.inventoryProductsPageSliceState
					.shouldInvokeInventoryProductsPageResetter;

			if (shouldInvokeRemotePageReset === true) {
				// Immediately disabling further Resetting by disable fire of "invokeInventoryProductsPageResetter()" Redux Action.
				// Otherwise there will be unlimited re-renders.
				// eslint-disable-next-line react/destructuring-assignment
				this.props.inventoryProductsPageSliceActions.invokeInventoryProductsPageResetter(
					false,
				);

				// Re-setting The Page.
				this.resetPage(() => {
					this.fetchNecessaryInitialPageData();
				});
			}
		};

		resetPageWhenOrganizationChangedOnRootLevel();
		resetPageWhenRemoteInventoryProductsPageResetterFired();
	}

	render() {
		const {
			pageInitialDataReqStatus,
			pageInitialDataReqError,

			searchType,
			searchTerm,
			rawProductSearchResult,
			searchingReqState,
			searchingReqError,
			searchingFormValidationMsg,
			disableSearchTermInput,

			totalProductItems,
			numOfItemsPerPage,
			currentPaginationIndex,

			categoryTypeList,
			categoryTypeId,

			brandTypeList,
			brandTypeId,

			stockViewTypeList,
			stockViewTypeId,
		} = this.state;

		const {
			dashboardSliceState,
			productListCustomizationProps = {}, // Optional props that will be used in CustomerList to customize various things if necessary. (Ex. Adding custom action button to Action Column.)
			productSearcherType = productSearcherTypes.genericType.key,
		} = this.props;

		const { currentlySelectedOrgId, currentlySelectedLocationId } =
			dashboardSliceState;

		const isMainPageActionsRunning =
			pageInitialDataReqStatus === reqStatusTypes.pending;
		const isMainPageActionsError =
			pageInitialDataReqStatus !== reqStatusTypes.pending &&
			pageInitialDataReqError;

		if (isMainPageActionsRunning || isMainPageActionsError) {
			return (
				<div className='ProductSearcher'>
					<LoaderView
						isLoading={isMainPageActionsRunning}
						isError={isMainPageActionsError}
						typeConfigs={{
							error: {
								extra: (
									<Button
										type='primary'
										onClick={() => {
											this.fetchNecessaryInitialPageData();
										}}>
										TRY AGAIN
									</Button>
								),
							},
						}}
					/>
				</div>
			);
		}

		return (
			<div className='ProductSearcher'>
				<div className='ProductSearcher__productSearcher'>
					<Row>
						<Col xs={24} sm={7} md={6} lg={5} xl={4} xxl={3}>
							{/* Search Type Selector */}
							<Select
								style={{
									width: '100%',
								}}
								value={searchType}
								options={Object.values(searchTypeList)
									.filter((type) => {
										let shouldRemove = false;

										if (
											productSearcherType ===
											productSearcherTypes.proformaType.key
										) {
											if (type.key === searchTypeList.byStockView.key) {
												shouldRemove = true;
											}
										}

										switch (true) {
											// Removing 'byCategory' entry when theres no categories.
											case type.key === searchTypeList.byCategoryId.key &&
												categoryTypeList.length === 0: {
												shouldRemove = true;
												break;
											}
											// Removing 'byBrand' entry when theres no brands.
											case type.key === searchTypeList.byBrandId.key &&
												brandTypeList.length === 0: {
												shouldRemove = true;
												break;
											}

											default: {
												break;
											}
										}

										return !shouldRemove;
									})
									.map((type) => ({ label: type.label, value: type.key }))}
								onChange={(value) => {
									this.setState(
										{
											searchingReqState: reqStatusTypes.idle,
											searchType: value,
											searchTerm: '', // Resetting search term when search type is changed.
											currentPaginationIndex: 0,
										},
										() => {
											this.resetAdditionalFilterSelectBoxes();
											this.searchIfItsAutomaticSearchType();
										},
									);
								}}
							/>
						</Col>

						{/* When Specific "byCategoryId" Search Type Selected */}
						{searchType === searchTypeList.byCategoryId.key && (
							<Col xs={24} sm={7} md={6} lg={5} xl={4} xxl={3}>
								<Select
									style={{
										width: '100%',
									}}
									value={categoryTypeId}
									options={categoryTypeList.map((cat) => {
										return {
											label: cat.ProductCategory.Category,
											value: cat.ID,
										};
									})}
									onChange={(value) => {
										this.setState(
											{
												categoryTypeId: value,
												searchTerm: '',
												currentPaginationIndex: 0,
											},
											() => {
												this.searchIfItsAutomaticSearchType();
											},
										);
									}}
								/>
							</Col>
						)}

						{/* When Specific "byBrandId" Search Type Selected */}
						{searchType === searchTypeList.byBrandId.key && (
							<Col xs={24} sm={7} md={6} lg={5} xl={4} xxl={3}>
								<Select
									style={{
										width: '100%',
									}}
									value={brandTypeId}
									options={brandTypeList.map((brand) => ({
										label: brand.BrandName,
										value: brand.ID,
									}))}
									onChange={(value) => {
										this.setState(
											{
												brandTypeId: value,
												searchTerm: '',
												currentPaginationIndex: 0,
											},
											() => {
												this.searchIfItsAutomaticSearchType();
											},
										);
									}}
								/>
							</Col>
						)}

						{/* When Specific "byStockView" Search Type Selected */}
						{searchType === searchTypeList.byStockView.key && (
							<Col xs={24} sm={7} md={6} lg={5} xl={4} xxl={3}>
								<Select
									style={{
										width: '100%',
									}}
									value={stockViewTypeId}
									options={stockViewTypeList.map((stockType) => ({
										label: stockType.title,
										value: stockType.ID,
									}))}
									onChange={(value) => {
										this.setState(
											{
												stockViewTypeId: value,
												searchTerm: '',
												currentPaginationIndex: 0,
											},
											() => {
												this.searchIfItsAutomaticSearchType();
											},
										);
									}}
								/>
							</Col>
						)}

						<Col flex={1}>
							<div className='ProductSearcher__productSearcher__searchGroup'>
								<Tooltip
									placement='bottomRight'
									title={searchingFormValidationMsg}
									trigger='click'
									visible={Boolean(searchingFormValidationMsg)}
									onVisibleChange={() => {
										// Closing tooltip when user clicked on anywhere on the page.
										setTimeout(() => {
											this.setState({
												searchingFormValidationMsg: false,
											});
										}, 100);
									}}>
									<Input
										style={{
											width: '100%',
										}}
										type='input'
										placeholder={
											searchTypeList[searchType].searchPlaceholderText
										}
										value={searchTerm}
										onChange={(e) => {
											this.setState({
												searchTerm: e.target.value,
											});
										}}
										onPressEnter={() => {
											this.handleProductSearch();
										}}
										disabled={disableSearchTermInput}
									/>
								</Tooltip>

								<Button
									icon={<SearchIcon />}
									loading={searchingReqState === reqStatusTypes.pending}
									onClick={() => {
										this.setState(
											{
												currentPaginationIndex: 0,
											},
											() => {
												this.handleProductSearch();
											},
										);
									}}
								/>
							</div>
						</Col>
					</Row>
				</div>

				<div className='ProductSearcher_____contentWrapper'>
					<LoaderView
						isInfo={searchingReqState === reqStatusTypes.idle}
						isLoading={searchingReqState === reqStatusTypes.pending}
						isError={searchingReqState === reqStatusTypes.failed}
						isEmpty={
							searchingReqState === reqStatusTypes.succeeded &&
							rawProductSearchResult.Result.length === 0
						}
						typeConfigs={{
							info: {
								subTitle: 'Initiate a Search to See Products.',
								icon: <SearchIcon />,
							},

							error: {
								subTitle: searchingReqError,
								extra: (
									<>
										<Button
											type='primary'
											onClick={() => {
												this.handleProductSearch();
											}}>
											TRY AGAIN
										</Button>
										<Button
											onClick={() => {
												this.setState({
													searchingReqState: reqStatusTypes.idle,
													searchTerm: '',
												});
											}}>
											CANCEL
										</Button>
									</>
								),
							},

							empty: {
								subTitle: 'No Products Found.',
							},
						}}
					/>
					<div className='ProductSearcher__productList'>
						{searchingReqState === reqStatusTypes.succeeded &&
							rawProductSearchResult.Result.length > 0 && (
								<ProductList
									rawProductList={rawProductSearchResult.Result}
									rawFilterData={rawProductSearchResult.ProductFilter}
									selectedOrgId={currentlySelectedOrgId}
									selectedLocationId={currentlySelectedLocationId}
									tableCompProps={{
										pagination: {
											current: currentPaginationIndex + 1,
											total: totalProductItems,
											pageSize: numOfItemsPerPage,
											onChange: (pageNo, pageSize) => {
												// NOTE: This 'pageNo' is start by '1' not '0'

												this.setState(
													{
														currentPaginationIndex: pageNo - 1,
													},
													() => {
														this.handleProductSearch({
															isSearchFromPagination: true,
														});
													},
												);
											},
										},
									}}
									extraCustomProps={{
										onFinishExtraTaskFn: () => this.resetPage(),
									}}
									customizationProps={productListCustomizationProps}
								/>
							)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	dashboardSliceState: state[dashboardSectionName].dashboard,
	inventoryProductsPageSliceState:
		state[dashboardSectionName].inventoryProductsPage,
});

const mapDispatchToProps = (dispatch) => {
	const boundDashboardSliceActions = bindActionCreators(
		dashboardSliceActions,
		dispatch,
	);

	const boundInventoryProductsPageSliceActions = bindActionCreators(
		inventoryProductsPageSliceActions,
		dispatch,
	);

	return {
		dashboardSliceActions: boundDashboardSliceActions,
		inventoryProductsPageSliceActions: boundInventoryProductsPageSliceActions,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductSearcher);
