import { HeaderBar } from 'ecologital-ui-library';
import { Form } from 'formik-antd';
import React, { Component } from 'react';

import { getFormSectionsPartialObjPaths } from '../../helpers/parserHelpers';

import { generateRelevantCustomPropertyInput } from './helpers/helpers';

import './PmvCustomTab.css';

// Default Form.Items Label Size.
const defaultLabelColProps = {
	span: 5,
	style: {
		whiteSpace: 'pre-wrap',
	},
};

const { customInfoObjPath } = getFormSectionsPartialObjPaths().customTab;

class PmvCustomTab extends Component {
	render() {
		const { customPropertyTypesList } = this.props;

		return (
			<div className='PmvCustomTab'>
				<Form
					labelCol={{ ...defaultLabelColProps }}
					colon={false}
					labelAlign='left'>
					<div className='PmvMasterTab__customInfoSection'>
						<HeaderBar subTitle={<b>CUSTOM INFORMATION</b>} />

						<div className='PmvMasterTab__customInfoSection__formControlsWrapper'>
							{customPropertyTypesList.map((customType) => {
								return generateRelevantCustomPropertyInput(
									customType,
									`${customInfoObjPath}${customType.customArrayKeyName}`,
								);
							})}
						</div>
					</div>
				</Form>
			</div>
		);
	}
}

export default PmvCustomTab;
