import React, { useState, useEffect } from 'react';
import { Table } from 'ecologital-ui-library';
import { Input, InputNumber, Form, Space, Button } from 'antd';

import MaterialDesignIcon from '../../../common/MaterialDesignIcon';
import _, { indexOf } from 'lodash';

const EditableCell = ({
	editing,
	dataIndex,
	title,
	inputType,
	record,
	index,
	children,
	...restProps
}) => {
	const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
	// if (editing) {
	// 	console.log("editing====>>", editing)
	// 	console.log("dataIndex====>>", dataIndex)
	// }
	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item
					name={dataIndex}
					style={{
						margin: 0,
						width: 120
					}}
					rules={[
						{
							required: true,
							message: `Please Input ${title}!`,
						},
					]}
				>
					<InputNumber style={{ width: '120px' }} />
				</Form.Item>
			) : (
				children
			)}
		</td>
	);
};

const SearchResults = (props) => {

	const selectionType = 'checkbox';
	const [editingKey, setEditingKey] = useState('');
	const [products, setProducts] = useState([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [form] = Form.useForm();

	// const editableFields = props.viewType === "products" ?
	// 	["ProductCode", "InnerEAN", "Nsp", "NumberUnitsPack", "Rsp", "ShortDescription", "Weight", "VATRate", "Currency"]
	// 	: ["ProductCode", "InnerEAN", "EffectiveDate", "EndDate", "Nsp", "NumberUnitsPack", "Rsp", "ShortDescription", "Currency"];

	const isEditing = (record) => record.key === editingKey;
	const edit = (record) => {
		console.log(`record`, record)
		form.setFieldsValue({
			ProductCode: '',
			...record,
		});
		// setEditingKey
		setEditingKey(record.key);
	};

	const cancel = () => {
		setEditingKey('');
	};

	const save = async (rec) => {
		try {
			const row = await form.validateFields();
			let newData = [...products];
			const index = newData.findIndex((item) => rec.ID === item.ID);
			let selectedKeys = [...new Set([...selectedRowKeys, index])]
			setSelectedRowKeys(selectedKeys)

			if (index > -1) {
				const item = newData[index];
				newData.splice(index, 1, { ...item, ...row });
				setProducts(newData);
				props.handleSetSelectedItems(newData.filter((nd, k) => selectedKeys.find(l => l === k)))

			}
			setEditingKey('');

		} catch (errInfo) {
			console.log('Validate Failed:', errInfo);
		}
	};

	useEffect(() => {
        let selectedList = products.map((p, i) => props.selectedItems.findIndex(si => si.ID===p.ID)>-1?i:null).filter(x => x!==null)
        setSelectedRowKeys(selectedList)
    }, [props.selectedItems])

	useEffect(() => {
		setProducts(props.productList)
	}, [props.productList]);

	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
			props.handleSetSelectedItems(selectedRows)
			setSelectedRowKeys(selectedRowKeys)
		},
		// getCheckboxProps: (record) => ({
		// 	disabled: record.name === 'Disabled User',
		// 	// Column configuration not to be checked
		// 	name: record.name,
		// }),
	};

	const editableCols = props.viewType === "products" ?
		[
			{ title: 'ProductCode', dataIndex: 'ProductCode', key: 0 },
			{ title: 'InnerEAN', dataIndex: 'InnerEAN', key: 1 },
			{ title: 'Nsp', dataIndex: 'Nsp', key: 2 },
			{ title: 'Rsp', dataIndex: 'Rsp', key: 3 },
			{ title: 'Customer Price', dataIndex: 'customerPrice', key: 4, editable: true },
			{ title: 'ShortDescription', dataIndex: 'ShortDescription', key: 5 },
			{ title: 'Currency', dataIndex: 'Currency', key: 6 },
		]
		: [
			{ title: 'ProductCode', dataIndex: 'ProductCode', key: 0 },
			{ title: 'InnerEAN', dataIndex: 'InnerEAN', key: 1 },
			{ title: 'Nsp', dataIndex: 'Nsp', key: 2 },
			{ title: 'Rsp', dataIndex: 'Rsp', key: 3 },
			{ title: 'NumberUnitsPack', dataIndex: 'NumberUnitsPack', key: 4 },
			{ title: 'EndDate', dataIndex: 'EndDate', key: 5 },
			{ title: 'ShortDescription', dataIndex: 'ShortDescription', key: 6 },
		];


	// const columnsBK = [
	// 	..._.uniq([
	// 		...editableCols,
	// 		...Object.keys(props.productList[0]).map((p, i) => {
	// 			return {
	// 				title: p,
	// 				dataIndex: p,
	// 				key: i + 7,
	// 			}
	// 		})
	// 	], 'title'),
	// 	{
	// 		title: '',
	// 		key: 'operation',
	// 		fixed: 'right',
	// 		width: 60,
	// 		render: (_, record) => {
	// 			const editable = isEditing(record);
	// 			return editable ? (
	// 				<Space direction='horizontal'>
	// 					<Button type="text" icon={<MaterialDesignIcon icon='visibility' />} />
	// 					<Button type="link" icon={<MaterialDesignIcon icon='done' />} onClick={() => save(record)} />
	// 					<Button type="link" danger icon={<MaterialDesignIcon icon='close' />} onClick={() => cancel()} />
	// 					{/* <MaterialDesignIcon icon='save' />
	// 				<MaterialDesignIcon icon='cancel' /> */}
	// 				</Space>
	// 			) : (<Space direction='horizontal'>
	// 				<Button type="text" icon={<MaterialDesignIcon icon='visibility' />} />
	// 				<Button type="link" icon={<MaterialDesignIcon icon='edit' />} onClick={() => edit(record)} />
	// 				{/* <MaterialDesignIcon icon='save' />
	// 			<MaterialDesignIcon icon='cancel' /> */}
	// 			</Space>)
	// 		},
	// 	},
	// ]



	let reqCols = props.columnOrder.map((p, i) => {
		return {
			title: p.ColumnHeader,
			dataIndex: p.ColumnName,
			key: p.ColumnName,
		}
	});

	console.log("reqCols", reqCols);

	const displayCols = props.viewType === "products" ?
		[
			...reqCols.slice(0, reqCols.findIndex(r => r.dataIndex === "Rsp") + 1),
			{
				title: "Customer Price",
				dataIndex: "customerPrice",
				key: "customerPrice",
				editable: true
			},
			...reqCols.slice(reqCols.findIndex(r => r.dataIndex === "Rsp") + 2)
		]
		: reqCols;

	const columns = [
		...displayCols,
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 60,
			render: (_, record) => {
				const editable = isEditing(record);
				return editable ? (
					<Space direction='horizontal'>
						<Button type="text" icon={<MaterialDesignIcon icon='visibility' />} />
						<Button type="link" icon={<MaterialDesignIcon icon='done' />} onClick={() => save(record)} />
						<Button type="link" danger icon={<MaterialDesignIcon icon='close' />} onClick={() => cancel()} />
						{/* <MaterialDesignIcon icon='save' />
					<MaterialDesignIcon icon='cancel' /> */}
					</Space>
				) : (<Space direction='horizontal'>
					<Button type="text" icon={<MaterialDesignIcon icon='visibility' />} />
					<Button type="link" icon={<MaterialDesignIcon icon='edit' />} onClick={() => edit(record)} disabled={props.viewType !== "products"} />
					{/* <MaterialDesignIcon icon='save' />
				<MaterialDesignIcon icon='cancel' /> */}
				</Space>)
			}
		}
	]




	const mergedColumns = columns.map((col) => {
		if (!col.editable) {
			return col;
		}

		return {
			...col,
			onCell: (record) => ({
				record,
				inputType: 'text',
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditing(record),
			}),
		};
	});

	return (
		<div>
			{products ? (
				<Form form={form} component={false}>
					<Table
						components={{
							body: {
								cell: EditableCell,
							},
						}}
						rowSelection={{
							selectedRowKeys,
							type: selectionType,
							...rowSelection,
						}}
						columns={mergedColumns}
						dataSource={[
							...(products.map((p, i) => {
								return { key: i, ...p };
							})),
						]}
						// rowClassName="editable-row"
						loading={props.loading}
						size="small"
						{...props.tableCompProps}
					/>
				</Form>
			) : null}
		</div>
	);
}

export default SearchResults;
