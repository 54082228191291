import {
	CopyOutlined as CopyOutlinedIcon,
	CheckCircleOutlined as CheckCircleOutlinedIcon,
	MinusCircleOutlined as MinusCircleOutlinedIcon,
	PauseCircleOutlined as PauseCircleOutlinedIcon,
	ExclamationCircleOutlined as ExclamationCircleOutlinedIcon,
} from '@ant-design/icons';
import { countries as countryListObj } from 'countries-list';

// NOTE : In here we define multiple lists used for various <Select> element's Items, Set up initial Values, Etc...

export const customerTypesList = [
	{
		label: 'Person',
		customerTypeID: 2,
	},
	{
		label: 'Business',
		customerTypeID: 3,
		default: true,
	},
];

// SIDE NOTE : This is also used in <CustomerOverview/> ---> <CusOveGeneralSection/>.
export const customerStatusTypesList = [
	{
		label: 'Draft',
		value: 'Draft',
		icon: <CopyOutlinedIcon style={{ color: 'gray' }} />,
	},
	{
		label: 'Active',
		value: 'Active',
		icon: <CheckCircleOutlinedIcon style={{ color: 'green' }} />,
	},
	{
		label: 'On Hold',
		value: 'OnHold',
		icon: <PauseCircleOutlinedIcon style={{ color: 'orange' }} />,
	},
	{
		label: 'Bad Debt',
		value: 'BadDebt',
		icon: <ExclamationCircleOutlinedIcon style={{ color: 'red' }} />,
	},
	{
		label: 'Closed',
		value: 'Closed',
		icon: <MinusCircleOutlinedIcon style={{ color: 'red' }} />,
	},
];

export const addressTypesList = [
	{
		label: 'Billing',
		value: 'Billing',
	},
	{
		label: 'Delivery',
		value: 'Delivery',
	},
];

export const countryTypesList = Object.values(countryListObj)
	.map((country) => {
		return {
			label: country.name,
			value: country.name,
		};
	})
	.sort((a, b) => {
		// eslint-disable-next-line no-param-reassign
		a = a.label;
		// eslint-disable-next-line no-param-reassign
		b = b.label;

		return a.localeCompare(b);
	});

export const paymentMethodTypesList = [
	{
		label: 'Cash',
		value: 'Cash',
	},
	{
		label: 'Card',
		value: 'Card',
	},
	{
		label: 'Cheque',
		value: 'Cheque',
	},
	{
		label: 'Bank Transfer',
		value: 'Bank Transfer',
	},
];

// SIDE NOTE : This is also used in <CustomerOverview/> ---> <CusOveGeneralSection/>.
export const tierTypesList = [
	{
		label: 'Standard',
		value: 0,
		default: true,
	},
	{
		label: 'Tier 1',
		value: 1,
	},
	{
		label: 'Tier 2',
		value: 2,
	},
	{
		label: 'Tier 3',
		value: 3,
	},
	{
		label: 'Tier 4',
		value: 4,
	},
];
