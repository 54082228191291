import globalValues from '../../configs/globalValues';

const {
	WITMEG_ORGSERVER___BASEURL,
	WITMEG_APPSERVER___BASEURL,
	WITMEG_EMAILSERVER___BASEURL,
	WITMEG_WEBNEUROLAGESERVER___BASEURL,
	WITMEG_ECOSERVICESSERVER___BASEURL,
	WITMEG_REPORTING_BASE_URL,
	REACT_APP__WITMEG__LOOKUP__BASEURL,

} = globalValues.baseURLS;

const apiEndpointPaths = {
	// ******************************************* Org Server (Org Registry) *******************************************
	witmegOrgServer: {
		loginUser: `${WITMEG_ORGSERVER___BASEURL}/user/login`, // ---> POST
		getUserDetailsByUserId: `${WITMEG_ORGSERVER___BASEURL}/user`, // ---> GET /user/:USERID

		getUserOrgDetailsByUserId: `${WITMEG_ORGSERVER___BASEURL}/registereduser/user`, // ---> GET /registereduser/user/:USERID
		getFullUserOrgDetailsByUserId: `${WITMEG_ORGSERVER___BASEURL}/registereduser/userdetail`, // ---> GET /registereduser/userdetail/:USERID.

		getOrganizationByOrgId: `${WITMEG_ORGSERVER___BASEURL}/organisation/id`, // ---> GET /organisation/id/:ORGANIZATIONID
		getLocationsOfOrgByOrganizationId: `${WITMEG_ORGSERVER___BASEURL}/locations`, // ---> GET /locations/:ORGANIZATIONID

		findGlobalCustomerByEmail: `${WITMEG_ORGSERVER___BASEURL}/customer/globalfind`, // ---> POST
		addGlobalCustomer: `${WITMEG_ORGSERVER___BASEURL}/customer/global/addcustomer`, // ---> POST
	},

	// ******************************************* App Server (App Registry) *******************************************
	witmegAppServer: {
		getAllAppTypeDetailsOnPlatform: `${WITMEG_APPSERVER___BASEURL}/v1/myapp`, // ---> POST
	},

	// *******************************************  Email Server (Email Registry) *******************************************
	witmegEmailServer: {
		sendEmail: `${WITMEG_EMAILSERVER___BASEURL}/v1/email/add`, // POST
	},

	// *******************************************  Web Neurolage Server *******************************************
	witmegWebNeurolageServer: {
		// **************** EPOS Product Related APIS ****************
		getEposProductCategoriesByOrgId: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/productcategories`, // ---> GET  /v1/epos/productcategories/{ORGID}
		getEposProductBrandsByOrgId: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/productbrands`, // ---> GET   /v1/epos/productbrands/{ORGID}
		getUnitTypesByOrgId: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/unittypes`, // ---> GET   /v1/epos/unittypes/{ORGID}
		getEposProductCategoryByName: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/productcategory/find/name`, // ----> POST
		getEposProductCategoryByCategoryId: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/productcategory/find/id`, // ----> POST

		getEposProductsInitioalByOrgId: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/products/initialloading/pagination`, // ---> POST
		getEposProductCountByProductName: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/products/count/productname`, // ---> POST
		getEposProductCountByCloudProductCategoryID: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/products/cloudcategory/count`, // ---> GET  /v1/epos/products/cloudcategory/count/{ORGID}/{PRODUCT_CATEGORY_ID}
		getEposProductCountByCloudProductBrandId: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/products/cloudbrand/count`, // ---> GET /v1/epos/products/cloudbrand/count/{ORGID}/{CLOUD_BRAND_ID}
		getEposProductCountThatMinOrderLevelReached: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/products/stocklevel/minimum/pagination/count`, // ---> POST
		getEposProductCountThatMaxOrderLevelReached: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/products/stocklevel/maximum/pagination/count`, // ---> POST

		getEposProductsByCloudProductName: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/products/pagination/productname`, // ---> POST
		getEposProductsByCloudProductCategoryId: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/products/cloudcategory/pagination`, // ---> POST
		getEposProductsByCloudProductBrandId: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/products/cloudbrand/pagination`, // ---> POST
		getEposProductByBarcode: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/product/barcode`, // ---> POST
		getEposProductByProductRefNo: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/product/productrefno`, // ---> POST
		getEposProductByCloudProductId: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/product/cloudproductid`, // ---> POST
		getEposProductThatMinOrderLevelReached: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/products/stocklevel/minimum/pagination`, // ---> POST
		getEposProductThatMaxOrderLevelReached: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/products/stocklevel/maximum/pagination`, // ---> POST
		getEposBrandByBrandName: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/productbrand/search/name`, // ---> POST
		getEposBarcodeByBarcodeId: `https://productlookup.witmeg.com/v1/productlookup/ops/barcode`, // ---> POST

		getSummaryOfStockChangesForTimeline: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/products/timeline/summary`, // ---> POST
		getEposUnitChartByCloudUnitChartId: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/unitchart`, // ---> GET /v1/epos/unitchart/{CloudUnitChartID}

		addEposProduct: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/product/add`, // ---> POST
		addEposProductBrand: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/productbrand/add`, // ---> POST
		updateEposProductBrand: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/productbrand/update`, // ---> POST
		addEposUnitChart: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/unitchart/add`, // ---> POST

		updateEposProduct: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/product/updateproduct`, // ---> POST
		getEposProductOverview: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/products/overview`, // ---> POST

		searchProducts: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/products/search`, // ---> POST

		// **************** EPOS Customers Related APIS ****************
		getSalesRepsListByOrgId: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/salesreps`, // ---> GET /v1/epos/salesreps/{OrgId}

		getEposCustomersCountByOrgId: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/customers/count`, // ---> GET /v1/epos/customers/count/{OrgID}

		getEposCustomersByOrgId: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/customers/pagination`, // ---> POST
		getEposCustomersByCloudCustomerId: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/customers/search/eposcloudcustomer`, // ---> POST
		getEposCustomersByCompanyName: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/customers/search/companyname`, // ---> POST
		getEposCustomersByPostCode: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/customers/search/postcode`, // ---> POST
		getEposCustomersByAcNumber: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/customers/search/accno`, // ---> POST
		getEposCustomersByCity: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/customers/search/city`, // ---> POST
		getEposCustomersByEmail: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/customers/search/email`, // ---> POST

		addEposCustomer: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/customers/add`, // ---> POST
		updateEposCustomer: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/customers/update`, // ---> POST

		// **************** EPOS Proformas Related APIS ****************
		getProductListCountForProformaCreation: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/proforma/productlist/count`, // ---> POST
		getProductListForProformaCreation: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/proforma/productlist`, // ---> POST
		addProformaInvoice: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/proforma/add`, // ---> POST

		// **************** EPOS Supplier Related APIS ****************
		getEposSuppliersAllByOrgId: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/supplier/all`, // ---> POST 
		addEposSupplier: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/supplier/create`, // ---> POST 
		deleteEposSupplier: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/supplier/delete`, // ---> POST 
		overviweEposSupplier: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/supplier/overview`, // ---> POST 
		editEposSupplier: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/supplier/edit`, // ---> POST  
		searchEposSupplier: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/supplier/search`, // ---> POST
		searchCountEposSupplier: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/supplier/search/count`, // ---> POST

		// **************** EPOS Purchase Order Related APIS ****************
		getProductListCountForPurchaseOrderCreation: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/purchase/orders/pagination/count`, // ---> POST
		getProductListForPurchaseOrderCreation: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/proforma/productlist`, // ---> POST
		getPurchaseOrdersList: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/purchase/orders/pagination`, // ---> POST
		getPurchaseOrdersCount: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/purchase/orders/pagination/count`, // ---> POST
		savePurchaseOrder: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/purchase/orderrequest`, // ---> POST
		updatePuchaseOrder: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/purchase/orders/update`, // ---> POST
		updatePuchaseOrderStatus: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/purchase/orders/updatestatus`, // ---> POST
		getPurchaseOrderDetailsById: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/purchase/orders/id`, // ---> POST
		updatePurchaseOrder: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/purchase/orders/update`, // ---> POST
		updatePurchaseOrderStatus: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/purchase/orders/updatestatus`, // ---> POST
		getStockAdjustmentList: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/stock/adjustments/list`,// ---> POST
		getStockAdjustmentListPagination: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/stock/adjustment/search/pagination`,// ---> POST
		getStockAdjustmentDetail: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/stock/adjustment/detail`,// ---> POST
		getStockAdjustmentItemDetail: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/stock/adjustment/item/detail`,// ---> POST
		getStockAdjustmentSummary: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/stock/adjustment/item/summary`,// ---> POST
		getStockAdjustmentReasons: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/stock/list/reasons`,//---> GET
		saveStockAdjustment: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/stock/update`,// ---> POST


		// **************** Settings Related APIS ****************
		getCurrencySettingsByOrgIdAndCloudLocationID: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/org/setting`, // ---> POST

		// **************** NISA App Related APIS ****************
		getNisaProdoctCountByOrdId: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/nisa/products/pagination/count`, // ---> POST
		getNisaProdoctsByOrdId: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/nisa/products/pagination`, // ---> POST
		getNisaProdoctsByBarCode: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/nisa/products/barcode`, // ---> POST
		getNisaProdoctCountByProductName: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/nisa/products/productname/count`, // ---> POST
		getNisaProdoctsByProductName: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/nisa/products/productname`, // ---> POST
		getNisaProdoctCountByModifiedDate: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/nisa/products/modifieddate/count`, // ---> POST
		getNisaProdoctsByModifiedDate: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/nisa/products/modifieddate`, // ---> POST
		addNisaProductsUpdateRequest: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/nisa/productupdaterequest/add`, // ---> POST,
		getNisaProdoctCountPriceModified: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/nisa/products/pricemodified/pagination/count`, // ---> POST
		getNisaProdoctsPriceModified: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/nisa/products/pricemodified/pagination`, // ---> POST


		getNisaPromotionCountByOrdId: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/nisa/promotions/pagination/count`, // ---> POST
		getNisaPromotionByOrdId: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/nisa/promotions/pagination`, // ---> POST
		getNisaPromotionByBarCode: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/nisa/promotions/barcode`, // ---> POST
		getNisaPromotionCountByProductName: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/nisa/promotions/productname/count`, // ---> POST
		getNisaPromotionByProductName: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/nisa/promotions/productname`, // ---> POST
		getNisaPromotionCountByModifiedDate: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/nisa/promotions/modifieddate/count`, // ---> POST
		getNisaPromotionByModifiedDate: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/nisa/promotions/modifieddate`, // ---> POST
		addNisaPromotionsUpdateRequest: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/nisa/promotionupdaterequest/add`, // ---> POST
		getNisaPromotionCountByPeriod: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/nisa/promotions/period/count`, // ---> POST
		getNisaPromotionByPeriod: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/nisa/promotions/period`, // ---> POST

		//Custom Attributes
		getEposCustomAttributesByOrgId: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/attributes`, // ---> GET  /v1/epos/attributes/{OrgID}
		addEposProductCategory: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/productcategory/add`, // ---> POST
		getEposProductCategoryById: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/productcategory/find/id`, // ---> POST
		updateEposProductCategory: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/productcategory/update`, // ---> POST

		//Offers
		getAllEposOffers: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/offers`, // ---> POST
		saveEposOffers: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/offer/add`, // ---> POST
		deleteEposOffers: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/offer/delete`, // ---> POST
		getAllEposOffersByLocation: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/offers/location`, // ---> GET
		editEposOffers: `${WITMEG_WEBNEUROLAGESERVER___BASEURL}/v1/epos/offer/update`, // ---> POST
	},

	// *******************************************  Reporting Server *******************************************
	witmegReportingServer: {
		// **************** Reporting Related APIS ****************
		getReportListByAppIDAndOrgID: `${WITMEG_REPORTING_BASE_URL}/app-dashboards`, // ---> GET
	},

	// *******************************************  Ecologital Services Server *******************************************
	ecoServicesServer: {
		uploadImage: `${WITMEG_ECOSERVICESSERVER___BASEURL}/v1/image/uploadImage`, // ---> POST
	},
};

export default apiEndpointPaths;
