import { serverRequest } from './axiosInstances/serverRequest';
import { APIError, errorLogger } from '../errorHandlers';
import apiEndpoints from './apiEndpointPaths';

/* Start Product Realated APIs */
export async function getNisaProductCountsByOrgId(reqBody = {}, options = {}) {
	try {
		const { OrgID } = reqBody;

		const apiReqBody = {
			OrgID,
		};

		const API_URL = `${apiEndpoints.witmegWebNeurolageServer.getNisaProdoctCountByOrdId}`;
		const resObj = await serverRequest('generateToken__WitmegWebNeurolageServer', API_URL, 'POST', apiReqBody, options);			

		const { Status, Result } = resObj.data;

		if (!Status) {
			// throw new APIError(Result);
			return [];
		}

		return Result;
	} catch (error) {
		// const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		// return Promise.reject(APIError(customErrMsg, error));
		return 0
	}
}

export async function getNisaProductsByOrgId(reqBody = {}, options = {}) {
	try {
		const { OrgID, Skip } = reqBody;

		const apiReqBody = {
			OrgID,
			Skip,
			Limit: 10,
			RequestValue: '',
		};

		const API_URL = apiEndpoints.witmegWebNeurolageServer.getNisaProdoctsByOrdId;
		const resObj = await serverRequest('generateToken__WitmegWebNeurolageServer', API_URL, 'POST', apiReqBody, options);			

		const { Status, Result, ColumnOrder } = resObj.data;

		if (!Status) {
			return {}
			// throw new APIError(Result);
		}

		return { Status, Result, ColumnOrder };
	} catch (error) {
		// const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		// return Promise.reject(APIError(customErrMsg, error));
		return [];
	}
}

export async function getNisaProdoctCountByProductName(reqBody = {}, options = {}) {
	try {
		const { OrgID, RequestValue } = reqBody;

		const apiReqBody = {
			OrgID,
			RequestValue
		};

		const API_URL = apiEndpoints.witmegWebNeurolageServer.getNisaProdoctCountByProductName;
		const resObj = await serverRequest('generateToken__WitmegWebNeurolageServer', API_URL, 'POST', apiReqBody, options);		

		const { Status, Result } = resObj.data;

		if (!Status) {
			// throw new APIError(Result);
			return null;
		}

		return Result;
	} catch (error) {
		// const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		// return Promise.reject(APIError(customErrMsg, error));
		return null;
	}
}

export async function getNisaProdoctsByProductName(reqBody = {}, options = {}) {
	try {
		const { OrgID, Skip, RequestValue } = reqBody;

		const apiReqBody = {
			OrgID,
			Skip,
			Limit: 10,
			RequestValue
		};

		const API_URL = apiEndpoints.witmegWebNeurolageServer.getNisaProdoctsByProductName;
		const resObj = await serverRequest('generateToken__WitmegWebNeurolageServer', API_URL, 'POST', apiReqBody, options);		

		const { Status, Result } = resObj.data;

		if (!Status) {
			// throw new APIError(Result);
			return null;
		}

		return Result;
	} catch (error) {
		// const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		// return Promise.reject(APIError(customErrMsg, error));
		return null;
	}
}

export async function getNisaProdoctCountByModifiedDate(reqBody = {}, options = {}) {
	try {
		const { OrgID, ModifiedDate } = reqBody;

		const apiReqBody = {
			OrgID,
			ModifiedDate
		};

		const API_URL = apiEndpoints.witmegWebNeurolageServer.getNisaProdoctCountByModifiedDate;
		const resObj = await serverRequest('generateToken__WitmegWebNeurolageServer', API_URL, 'POST', apiReqBody, options);		

		const { Status, Result } = resObj.data;

		if (!Status) {
			// throw new APIError(Result);
			return null;
		}

		return Result;
	} catch (error) {
		// const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		// return Promise.reject(APIError(customErrMsg, error));
		return null;
	}
}

export async function getNisaProdoctsByModifiedDate(reqBody = {}, options = {}) {
	try {
		const { OrgID, Skip, ModifiedDate } = reqBody;

		const apiReqBody = {
			OrgID,
			Skip,
			Limit: 10,
			ModifiedDate,
		};

		const API_URL = apiEndpoints.witmegWebNeurolageServer.getNisaProdoctsByModifiedDate;
		const resObj = await serverRequest('generateToken__WitmegWebNeurolageServer', API_URL, 'POST', apiReqBody, options);		

		const { Status, Result } = resObj.data;

		if (!Status) {
			// throw new APIError(Result);
			return null
		}

		return Result;
	} catch (error) {
		// const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		// return Promise.reject(APIError(customErrMsg, error));
		return [];
	}
}

export async function getNisaProdoctsByBarCode(reqBody = {}, options = {}) {
	try {
		const { OrgID, RequestValue } = reqBody;

		const apiReqBody = {
			OrgID,
			RequestValue
		};

		const API_URL = apiEndpoints.witmegWebNeurolageServer.getNisaProdoctsByBarCode;
		const resObj = await serverRequest('generateToken__WitmegWebNeurolageServer', API_URL, 'POST', apiReqBody, options);		

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		// const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		// return Promise.reject(APIError(customErrMsg, error));
		return [];
	}
}

export async function addNisaProductsUpdateRequest(reqBody = {}, options = {}) {
	try {
		const { OrgID, CloudLocationID, Request } = reqBody;

		const apiReqBody = {
			OrgID,
			CloudLocationID,
			Request
		};

		const API_URL = apiEndpoints.witmegWebNeurolageServer.addNisaProductsUpdateRequest;
		const resObj = await serverRequest('generateToken__WitmegWebNeurolageServer', API_URL, 'POST', apiReqBody, options);		

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		// const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		// return Promise.reject(APIError(customErrMsg, error));
		return [];
	}
}

export async function getNisaProdoctCountPriceModified(reqBody = {}, options = {}) {
	try {
		const { OrgID } = reqBody;

		const apiReqBody = {
			OrgID,
		};

		const API_URL = apiEndpoints.witmegWebNeurolageServer.getNisaProdoctCountPriceModified;
		const resObj = await serverRequest('generateToken__WitmegWebNeurolageServer', API_URL, 'POST', apiReqBody, options);		

		const { Status, Result } = resObj.data;

		if (!Status) {
			// throw new APIError(Result);
			return [];
		}

		return Result;
	} catch (error) {
		// const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		// return Promise.reject(APIError(customErrMsg, error));
		return 0
	}
}

export async function getNisaProdoctsPriceModified(reqBody = {}, options = {}) {
	try {
		const { OrgID, Skip } = reqBody;

		const apiReqBody = {
			OrgID,
			Skip,
			Limit: 10
		};

		const API_URL = apiEndpoints.witmegWebNeurolageServer.getNisaProdoctsPriceModified;
		const resObj = await serverRequest('generateToken__WitmegWebNeurolageServer', API_URL, 'POST', apiReqBody, options);		

		const { Status, Result, ColumnOrder } = resObj.data;

		if (!Status) {
			return {}
			// throw new APIError(Result);
		}

		return { Status, Result, ColumnOrder };
	} catch (error) {
		// const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		// return Promise.reject(APIError(customErrMsg, error));
		return [];
	}
}

/* End Product Realated APIs */

/* Start Promotion Realated APIs */

export async function getNisaPromotionCountByOrdId(reqBody = {}, options = {}) {
	try {
		const { OrgID } = reqBody;

		const apiReqBody = {
			OrgID,
		};

		const API_URL = apiEndpoints.witmegWebNeurolageServer.getNisaPromotionCountByOrdId;
		const resObj = await serverRequest('generateToken__WitmegWebNeurolageServer', API_URL, 'POST', apiReqBody, options);		

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		// const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		// return Promise.reject(APIError(customErrMsg, error));
		return [];
	}
}

export async function getNisaPromotionsByOrgId(reqBody = {}, options = {}) {
	try {
		const { OrgID, Skip } = reqBody;

		const apiReqBody = {
			OrgID,
			Skip,
			Limit: 10,
			RequestValue: '',
		};

		const API_URL = apiEndpoints.witmegWebNeurolageServer.getNisaPromotionByOrdId;
		const resObj = await serverRequest('generateToken__WitmegWebNeurolageServer', API_URL, 'POST', apiReqBody, options);		

		const { Status, Result, ColumnOrder } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return { Status, Result, ColumnOrder };
	} catch (error) {
		// const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		// return Promise.reject(APIError(customErrMsg, error));
		return [];
	}
}

export async function getNisaPromotionCountByModifiedDate(reqBody = {}, options = {}) {
	try {
		const { OrgID, ModifiedDate } = reqBody;

		const apiReqBody = {
			OrgID,
			ModifiedDate
		};

		const API_URL = apiEndpoints.witmegWebNeurolageServer.getNisaPromotionCountByModifiedDate;
		const resObj = await serverRequest('generateToken__WitmegWebNeurolageServer', API_URL, 'POST', apiReqBody, options);		

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		// const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		// return Promise.reject(APIError(customErrMsg, error));
		return [];
	}
}

export async function getNisaPromotionByModifiedDate(reqBody = {}, options = {}) {
	try {
		const { OrgID, Skip, ModifiedDate } = reqBody;

		const apiReqBody = {
			OrgID,
			Skip,
			Limit: 10,
			ModifiedDate,
		};

		const API_URL = apiEndpoints.witmegWebNeurolageServer.getNisaPromotionByModifiedDate;
		const resObj = await serverRequest('generateToken__WitmegWebNeurolageServer', API_URL, 'POST', apiReqBody, options);		

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		// const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		// return Promise.reject(APIError(customErrMsg, error));
		return [];
	}
}


export async function getNisaPromotionByBarCode(reqBody = {}, options = {}) {
	try {
		const { OrgID, RequestValue } = reqBody;

		const apiReqBody = {
			OrgID,
			RequestValue
		};

		const API_URL = apiEndpoints.witmegWebNeurolageServer.getNisaPromotionByBarCode;
		const resObj = await serverRequest('generateToken__WitmegWebNeurolageServer', API_URL, 'POST', apiReqBody, options);		

		const { Status, Result, ColumnOrder } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return { Status, Result, ColumnOrder };
	} catch (error) {
		// const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		// return Promise.reject(APIError(customErrMsg, error));
		return [];
	}
}

export async function getNisaPromotionCountByProductName(reqBody = {}, options = {}) {
	try {
		const { OrgID, RequestValue } = reqBody;

		const apiReqBody = {
			OrgID,
			RequestValue
		};

		const API_URL = apiEndpoints.witmegWebNeurolageServer.getNisaPromotionCountByProductName;
		const resObj = await serverRequest('generateToken__WitmegWebNeurolageServer', API_URL, 'POST', apiReqBody, options);		

		const { Status, Result } = resObj.data;

		if (!Status) {
			// throw new APIError(Result);
			return null;
		}

		return Result;
	} catch (error) {
		// const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		// return Promise.reject(APIError(customErrMsg, error));
		return null;
	}
}

export async function getNisaPromotionByProductName(reqBody = {}, options = {}) {
	try {
		const { OrgID, Skip, RequestValue } = reqBody;

		const apiReqBody = {
			OrgID,
			Skip,
			Limit: 10,
			RequestValue,
		};

		const API_URL = apiEndpoints.witmegWebNeurolageServer.getNisaPromotionByProductName;
		const resObj = await serverRequest('generateToken__WitmegWebNeurolageServer', API_URL, 'POST', apiReqBody, options);		

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		// const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		// return Promise.reject(APIError(customErrMsg, error));
		return [];
	}
}

export async function addNisaPromotionsUpdateRequest(reqBody = {}, options = {}) {
	try {
		const { OrgID, CloudLocationID, Request } = reqBody;

		const apiReqBody = {
			OrgID,
			CloudLocationID,
			Request
		};

		const API_URL = apiEndpoints.witmegWebNeurolageServer.addNisaPromotionsUpdateRequest;
		const resObj = await serverRequest('generateToken__WitmegWebNeurolageServer', API_URL, 'POST', apiReqBody, options);		

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		// const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		// return Promise.reject(APIError(customErrMsg, error));
		return [];
	}
}

export async function getNisaPromotionCountByPeriod(reqBody = {}, options = {}) {
	try {
		const { OrgID, StartDate, EndDate } = reqBody;

		const apiReqBody = {
			OrgID,
			StartDate,
			EndDate
		};

		const API_URL = apiEndpoints.witmegWebNeurolageServer.getNisaPromotionCountByPeriod;
		const resObj = await serverRequest('generateToken__WitmegWebNeurolageServer', API_URL, 'POST', apiReqBody, options);		

		const { Status, Result } = resObj.data;

		if (!Status) {
			// throw new APIError(Result);
			return null;
		}

		return Result;
	} catch (error) {
		// const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		// return Promise.reject(APIError(customErrMsg, error));
		return null;
	}
}

export async function getNisaPromotionByPeriod(reqBody = {}, options = {}) {
	try {
		const { OrgID, StartDate, EndDate, Skip } = reqBody;

		const apiReqBody = {
			OrgID,
			StartDate,
			EndDate,
			Limit: 10,
			Skip
		};


		const API_URL = apiEndpoints.witmegWebNeurolageServer.getNisaPromotionByPeriod;
		const resObj = await serverRequest('generateToken__WitmegWebNeurolageServer', API_URL, 'POST', apiReqBody, options);		

		const { Status, Result } = resObj.data;

		if (!Status) {
			// throw new APIError(Result);
			return null;
		}

		return Result;
	} catch (error) {
		// const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		// return Promise.reject(APIError(customErrMsg, error));
		return null;
	}
}

/* End Promotion Realated APIs */
