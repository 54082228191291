import { Divider } from 'antd';
import { LoaderView } from 'ecologital-ui-library';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    getPurchaseOrdersList,
    getPurchaseOrdersCount
} from '../../../utilities/apiRequests/witmegWebNeurolageServerRequests';

import PurchaseOrderSearchForm from './subSections/PurchaseOrderSearchForm';
import SearchResults from './subSections/SearchResults';
// import { Alert, Button, Tabs } from 'antd';
// import { LoaderView } from 'ecologital-ui-library';
// import { Formik } from 'formik';

const dashboardSelector = (state) => () => state.dashboard;

const PurchaseOrderSearcher = () => {
    // const dispatch = useDispatch();
    const dashboardState = useSelector(dashboardSelector)();
    const [purchaseOrdersList, setPurchaseOrdersList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [statusNumber, setstatusNumber] = useState(0);
    const [resultCount, setResultCount] = useState(0)


    useEffect(() => {
        async function fetchCount() {
            setLoading(true);
            setPurchaseOrdersList([]);

            const poData = getPurchaseOrdersCount({
                OrgID: dashboardState.dashboard.currentlySelectedOrgId,
                CloudLocationID: dashboardState.dashboard.currentlySelectedLocationId,
                Status: Number(statusNumber)
            });
            await poData.then((data) => {
                console.log('getPurchaseOrders Count ===>>', data);
                setResultCount(data.Result)
                setLoading(false);
                return true;
            });

        }
        fetchCount();
    }, [dashboardState, statusNumber]);

    useEffect(() => {
        async function fetchCount() {
            setLoading(true);
            setPurchaseOrdersList([]);

            const poData = getPurchaseOrdersList({
                OrgID: dashboardState.dashboard.currentlySelectedOrgId,
                CloudLocationID: dashboardState.dashboard.currentlySelectedLocationId,
                Skip: 0,
                Limit: Number(resultCount),
                Status: Number(statusNumber)
            });
            await poData.then((data) => {
                setPurchaseOrdersList(data.PurchaseOrders);
                console.log('getPurchaseOrders Data ===>>', data);
                setLoading(false);
                return true;
            });

        }
        if (resultCount > 0) {
            fetchCount();
        }
    }, [dashboardState, statusNumber, resultCount]);

    const handleChangeProductType = async (val) => {
        if (statusNumber === val) {
            setLoading(true);
            setPurchaseOrdersList([]);

            const poDataCount = getPurchaseOrdersCount({
                OrgID: dashboardState.dashboard.currentlySelectedOrgId,
                CloudLocationID: dashboardState.dashboard.currentlySelectedLocationId,
                Status: Number(statusNumber)
            });
            await poDataCount.then((data) => {
                console.log('getPurchaseOrders Count ===>>', data);
                setResultCount(data.Result)
                setLoading(false);
                return true;
            });

            const poData = getPurchaseOrdersList({
                OrgID: dashboardState.dashboard.currentlySelectedOrgId,
                CloudLocationID: dashboardState.dashboard.currentlySelectedLocationId,
                Skip: 0,
                Limit: Number(resultCount),
                Status: Number(statusNumber)
            });
            await poData.then((data) => {
                setPurchaseOrdersList(data.PurchaseOrders);
                console.log('getPurchaseOrders Data ===>>', data);
                setLoading(false);
                return true;
            });
        } else {
            setstatusNumber(val);
        }

    };

    return (
        <div>
            <PurchaseOrderSearchForm handleChangeProductType={handleChangeProductType} />

            <Divider />
            {loading ? (
                <LoaderView className='ProductMasterView' isLoading />
            ) : (
                <>
                    {purchaseOrdersList && purchaseOrdersList.length > 0 ? (
                        <SearchResults
                            purchaseOrdersList={purchaseOrdersList}
                            LocationId={dashboardState.dashboard.currentlySelectedLocationId}
                            LocationName={dashboardState.dashboard.currentlySelectedLocationName}
                        />
                    ) : null}
                </>
            )}
        </div>
    );
};

export default PurchaseOrderSearcher;
