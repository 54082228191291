// NOTE : In here we define multiple lists used for various <Select> element's Items, Set up initial Values, Etc...

export const proformaOrderTypesList = [
	{
		label: 'On Site',
		value: 'OnSite',
		default: true,
	},
];

export const proformaDeliveryTypesList = [
	{
		label: 'Delivery',
		value: 'Delivery',
		default: true,
	},
	{
		label: 'Collection',
		value: 'Collection',
	},
];

export const proformaStatusTypesList = [
	{
		label: 'Not Completed',
		value: 'NotCompleted',
		default: true,
	},
	{
		label: 'Completed',
		value: 'Completed',
	},

	{
		label: 'Cancel',
		value: 'Cancel',
	},
];
