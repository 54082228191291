import { ErrorMessage } from 'formik';
import React, { Component } from 'react';

/**
 * NOTE : This component is created to use in special cases where we need to "Manually" render Error Message (Similar "Antd FormItem") from Fromik Context.
 * 	- So given correct "Formik State Path" as "name" prop and this will automatically parse relevant error msg and show it.
 * 	- By the way, Custom AntD Fields we are using from "formik-antd" package automatically do this. So this is only needed when there is some special cases.
 */

class FieldErrorMessage extends Component {
	render() {
		const {
			name = '', // Formik State Path
			customErrMsg = '', // If need to override existing msg, pass this.
			style = {},
		} = this.props;

		return (
			<div style={style}>
				<ErrorMessage name={name}>
					{(
						msg, // Error message which will automatically parsed from Formik Context for given 'name'
					) => {
						return (
							// NOTE : ClassName used here are to match "AntD Form.Items" Error Message look & feel.
							<div className='ant-form-item-explain ant-form-item-explain-error'>
								<div role='alert'>
									<li>{customErrMsg || msg}</li>
								</div>
							</div>
						);
					}}
				</ErrorMessage>
			</div>
		);
	}
}

export default FieldErrorMessage;
