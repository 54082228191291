import React, { useState } from 'react';
// import { Input, InputNumber, Popconfirm, } from 'antd';
import { Button, Form, Typography, Select, Table, InputBox } from 'witmeg-ui-system';
const originData = [];

for (let i = 0; i < 1; i++) {
    originData.push({
        key: i.toString(),
        name: '-',
        itemCode: '-',
        barcode: '-',
        unitType: '-',
        purchase: '-',
        selling: '-',
        margin: '-',
    });
}

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === 'number' ?
        <InputBox type="number" />
        : <Select>
            <Select.Option value="EA">EA</Select.Option>
            <Select.Option value="Box">Box</Select.Option>
            <Select.Option value="Pack">Pack</Select.Option>
        </Select>
        ;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const SecondaryBarcodeForm = () => {
    const [form] = Form.useForm();
    const [data, setData] = useState(originData);
    const [editingKey, setEditingKey] = useState('');

    const isEditing = (record) => record.key === editingKey;

    const edit = (record) => {
        form.setFieldsValue({
            name: '',
            itemCode: '',
            barcode: '',
            unitType: '',
            purchase: '',
            selling: '',
            margin: '',
            ...record,
        });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setData(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const deleteItem = async (key) => {
        let newData = data.filter(item => item.key !== key);
        setData(newData)
    };

    const addNewRow = () => {
        let item = {
            key: data.length.toString(),
            name: '',
            itemCode: '',
            barcode: '',
            unitType: '',
            purchase: '',
            selling: '',
            margin: '',
        }
        setData([...data, item])
        edit(item);
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            editable: true,
        },
        {
            title: 'Item Code',
            dataIndex: 'itemCode',
            key: 'itemCode',
            editable: true,
        },
        {
            title: 'Barcode',
            dataIndex: 'barcode',
            key: 'barcode',
            editable: true,
        },
        {
            title: 'Unit Type',
            dataIndex: 'unitType',
            key: 'unitType',
            editable: true,
        },
        {
            title: 'Purchase',
            dataIndex: 'purchase',
            key: 'purchase',
            editable: true,
        },
        {
            title: 'Selling',
            dataIndex: 'selling',
            key: 'selling',
            editable: true,
        },
        {
            title: 'Margin',
            dataIndex: 'margin',
            key: 'margin',
            editable: true,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>

                        <Button category='icon' variant='subtle' onClick={() => save(record.key)} children='check' size='small' iconSize={16} />
                        <Button category='icon' variant='subtle' children='cancel' size='small' iconSize={16} onClick={cancel} />
                        <Button category='icon' variant='subtle' children='trashcan' size='small' iconSize={16} onClick={() => deleteItem(record.key)} disabled={record.key === '0'} />
                    </span>
                ) : (
                    <>
                        <Button category='icon' variant='subtle' children='create' size='small' iconSize={16} disabled={editingKey !== '' || record.key === '0'} onClick={() => edit(record)} />
                        <Button category='icon' variant='subtle' children='trashcan' size='small' iconSize={16} onClick={() => deleteItem(record.key)} disabled={record.key === '0'} />
                    </>
                );
            },
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'unitType' ? 'select' : 'number',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });
    return (
        <div>
            <Form form={form} component={false}>
                <div></div>
                {/* <Table
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    bordered
                    dataSource={data}
                    columns={mergedColumns}
                    rowClassName="editable-row"
                    pagination={false}
                    scroll={{ x: true }}
                /> */}
            </Form>
            {/* <Button variant="link" onClick={addNewRow}>Add New</Button> */}
        </div>
    );
};


export default SecondaryBarcodeForm;