import { serverRequest } from './axiosInstances/serverRequest';
import { APIError, errorLogger } from '../errorHandlers';

import apiEndpointPaths from './apiEndpointPaths';
import { decodeToken } from '../tokenGenerators';

export async function loginUser(reqBody = {}, options = {}) {
	try {
		const { UserName, Password } = reqBody;

		const apiReqBody = {
			UserName,
			Password,
		};

		const API_URL = apiEndpointPaths.witmegOrgServer.loginUser;
		const resObj = await serverRequest('generateToken__WitmegOrgServer', API_URL, 'POST', apiReqBody, options);

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('mongo: no documents in result'): {
					customErrMsg =
						'No account exist for this username. Please check again.';
					break;
				}

				case errMsg.includes('Password does not match'): {
					customErrMsg = 'Your password is incorrect. Please check again.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function getUserDetailsByUserId(reqBody = {}, options = {}) {
	try {
		const { UserID } = reqBody;

		const apiReqBody = {};

		const API_URL = `${apiEndpointPaths.witmegOrgServer.getUserDetailsByUserId}/${UserID}`;
		const resObj = await serverRequest('generateToken__WitmegOrgServer', API_URL, 'GET', apiReqBody, options);		

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('encoding/hex: invalid byte'):
				case errMsg.includes('the provided hex string is not a valid ObjectID'):
				case errMsg.includes('encoding/hex: odd length hex string'): {
					customErrMsg = 'Invalid UserID.';
					break;
				}

				case errMsg.includes('Incorrect Path / values'): {
					customErrMsg = 'UserID not provided.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function getUserOrgDetailsByUserId(reqBody = {}, options = {}) {
	try {
		const { UserID } = reqBody;

		const apiReqBody = {};

		const API_URL = `${apiEndpointPaths.witmegOrgServer.getUserOrgDetailsByUserId}/${UserID}`;
		const resObj = await serverRequest('generateToken__WitmegOrgServer', API_URL, 'GET', apiReqBody, options);		

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('encoding/hex: invalid byte'):
				case errMsg.includes('the provided hex string is not a valid ObjectID'):
				case errMsg.includes('encoding/hex: odd length hex string'): {
					customErrMsg = 'Invalid UserID.';
					break;
				}

				case errMsg.includes('Incorrect Path / values'): {
					customErrMsg = 'UserID not provided.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function getFullUserOrgDetailsByUserId(
	reqBody = {},
	options = {},
) {
	try {
		const { UserID } = reqBody;

		const apiReqBody = {};

		const API_URL = `${apiEndpointPaths.witmegOrgServer.getFullUserOrgDetailsByUserId}/${UserID}`;
		const resObj = await serverRequest('generateToken__WitmegOrgServer', API_URL, 'GET', apiReqBody, options);		

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('encoding/hex: invalid byte'):
				case errMsg.includes('the provided hex string is not a valid ObjectID'):
				case errMsg.includes('encoding/hex: odd length hex string'): {
					customErrMsg = 'Invalid UserID.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function getOrganizationByOrgId(reqBody = {}, options = {}) {
	try {
		const { OrganizationID } = reqBody;

		const apiReqBody = {};

		const API_URL = `${apiEndpointPaths.witmegOrgServer.getOrganizationByOrgId}/${OrganizationID}`;
		const resObj = await serverRequest('generateToken__WitmegOrgServer', API_URL, 'GET', apiReqBody, options);		

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('encoding/hex: invalid byte'):
				case errMsg.includes('the provided hex string is not a valid ObjectID'):
				case errMsg.includes('encoding/hex: odd length hex string'): {
					customErrMsg = 'Invalid OrganizationID.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function getLocationsOfOrgByOrganizationId(
	reqBody = {},
	options = {},
) {
	try {
		const { OrganizationID } = reqBody;

		const apiReqBody = {};

		const API_URL = `${apiEndpointPaths.witmegOrgServer.getLocationsOfOrgByOrganizationId}/${OrganizationID}`;
		const resObj = await serverRequest('generateToken__WitmegOrgServer', API_URL, 'GET', apiReqBody, options);		

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('encoding/hex: invalid byte'):
				case errMsg.includes('the provided hex string is not a valid ObjectID'):
				case errMsg.includes('encoding/hex: odd length hex string'): {
					customErrMsg = 'Invalid OrganizationID.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function findGlobalCustomerByEmail(reqBody = {}, options = {}) {
	try {
		const { Email, OrganizationID = '' } = reqBody;

		const apiReqBody = {
			Email,
			OrganizationID, // Not Required. Currently this don't matter in finding.
		};

		const API_URL = apiEndpointPaths.witmegOrgServer.findGlobalCustomerByEmail;
		const resObj = await serverRequest('generateToken__WitmegOrgServer', API_URL, 'POST', apiReqBody, options);		

		const { Status, Result } = resObj.data;

		// This will updated later depending on conditions.
		const response = {
			globalCustomerExist: '', // TRUE OR FALSE
			globalCustomerId: '', // If global user exist, This result will be "UserId". Also used as "CloudCustomerId"
		};

		if (!Status) {
			// Handling some cases come with "Status: false" from throwing error.
			if (typeof Result === 'string') {
				switch (true) {
					// When Global User Don't Exist.
					case Result.includes('mongo: no documents in result'): {
						response.globalCustomerExist = false;
						break;
					}

					default: {
						break;
					}
				}
			} else {
				// Any other "false" cases throw error.
				throw new APIError(Result);
			}
		} else {
			// When user exist.
			response.globalCustomerExist = true;
			response.globalCustomerId = Result;
		}

		return response;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function addGlobalCustomer(reqBody = {}, options = {}) {
	try {
		const {
			FirstName,
			LastName,
			UserName,
			Password = '',
			Email,
			OriginatedOrganizationID,
			Addresses,
			Avatar,
			CustomerSettings,
		} = reqBody;

		const apiReqBody = {
			// Required
			FirstName,
			LastName,
			UserName,
			Email,

			// Optional
			Password, // Currently, Automatically generated in Backend.
			OriginatedOrganizationID,
			Addresses,
			Avatar,
			CustomerSettings,
		};

		const API_URL = apiEndpointPaths.witmegOrgServer.addGlobalCustomer;
		const resObj = await serverRequest('generateToken__WitmegOrgServer', API_URL, 'POST', apiReqBody, options);			

		const { Status, Result } = resObj.data;

		// Throwing Errors for errors that will happen even if Status is 200 & True.
		if (Status) {
			if (typeof Result === 'string') {
				switch (true) {
					case Result.includes(
						'Customer Already Exist in Global. Cannot insert again',
					): {
						throw new APIError(Result);
					}

					default: {
						break;
					}
				}
			}
		}

		if (!Status) {
			throw new APIError(Result);
		}

		// From the API we get "AccessToken" & "RefreshToken" as Result.
		const receivedResponse = Result;

		// Extracting  "UserId" (Also used as "CloudCustomerId") from received AccessToken.
		const decodedAccessToken = decodeToken(receivedResponse.AccessToken);
		const userId = decodedAccessToken.userid;

		return {
			...receivedResponse,
			userId,
		};
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes(
					'Customer Already Exist in Global. Cannot insert again',
				): {
					customErrMsg = 'A customer already exist for this email.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}
