import { serverRequest } from "./axiosInstances/serverRequest";
import { APIError, errorLogger } from "../errorHandlers";
import apiEndpoints from "./apiEndpointPaths";
import axiosWitmegWebNeurolageServer from "./axiosInstances/axiosWitmegWebNeurolageServerInstance";
import isString from "lodash/isString";

export async function getEposProductCategoriesByOrgId(
    reqBody = {},
    options = {}
) {
    try {
        const apiReqBody = {};

        const { OrgID } = reqBody;

        const API_URL = `${apiEndpoints.witmegWebNeurolageServer.getEposProductCategoriesByOrgId}/${OrgID}`;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "GET",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return Result;
    } catch (error) {
        // const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getEposProductCategoryByName(reqBody = {}, options = {}) {
    try {
        const { OrgID, categoryName } = reqBody;

        const apiReqBody = {
            OrgID: OrgID,
            Value: categoryName,
        };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.getEposProductCategoryByName;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            return "Error";
        }

        return Result;
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getAllEposProducts(reqBody = {}, options = {}) {
    try {
        const { orgId, locationId, skip = 0, limit = 100 } = reqBody;

        const apiReqBody = {
            OrgID: orgId,
            CloudLocationID: locationId,
            Skip: skip,
            Limit: limit,
        };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer
                .getEposProductsInitioalByOrgId;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return Result;
    } catch (error) {
        // const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function searchProducts(reqBody = {}, options = {}) {
    const {
        orgId,
        locationId,
        start = 0,
        limit = 10,
        searchBy,
        searchKey,
        isAdvancedSearch,
        advacedSearchParams,
    } = reqBody;

    try {
        let apiReqBody = {
            OrgID: orgId,
            CloudLocationID: locationId,
            Barcode: searchKey,
        };

        // console.log('offer item search', data);

        // if (isAdvancedSearch) {
        // 	data = {
        // 		...data,
        // 		"AdvanceSearch": { ...advacedSearchParams }
        // 	}
        // }

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.getEposProductByBarcode;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return Result;
    } catch (error) {
        const errorMessage = error.message;
        errorLogger(error, { errorMessage });
        return Promise.reject(APIError(errorMessage, error));
    }
}

export async function getEposProductBrandsByOrgId(reqBody = {}, options = {}) {
    try {
        const apiReqBody = {};

        const { OrgID } = reqBody;

        const API_URL = `${apiEndpoints.witmegWebNeurolageServer.getEposProductBrandsByOrgId}/${OrgID}`;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "GET",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return Result;
    } catch (error) {
        // const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

//Stock Adjustment List
export async function getStockAdjustmentList(reqBody = {}, options = {}) {
    try {
        const { ...fullReqBodyData } = reqBody;
        const apiReqBody = { ...fullReqBodyData };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.getStockAdjustmentList;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return Result;
    } catch (error) {
        // const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getStockAdjustmentListPagination(
    reqBody = {},
    options = {}
) {
    try {
        const { ...fullReqBodyData } = reqBody;
        const apiReqBody = { ...fullReqBodyData };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer
                .getStockAdjustmentListPagination;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return Result;
    } catch (error) {
        // const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

//getStockAdjustmentReasons
//get Reasons
export async function getStockAdjustmentReasons(reqBody = {}, options = {}) {
    try {
        const apiReqBody = {};

        const { OrgID } = reqBody;

        const API_URL = `${apiEndpoints.witmegWebNeurolageServer.getStockAdjustmentReasons}/${OrgID}`;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "GET",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return Result;
    } catch (error) {
        // const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

//Stock Adjustment Detail
export async function getStockAdjustmentDetail(reqBody = {}, options = {}) {
    try {
        const { ...fullReqBodyData } = reqBody;
        const apiReqBody = { ...fullReqBodyData };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.getStockAdjustmentDetail;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return Result;
    } catch (error) {
        // const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

//getStockAdjustmentItemDetail
//Stock Adjustment Detail Item
export async function getStockAdjustmentItemDetail(reqBody = {}, options = {}) {
    try {
        const { ...fullReqBodyData } = reqBody;
        const apiReqBody = { ...fullReqBodyData };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.getStockAdjustmentItemDetail;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return Result;
    } catch (error) {
        // const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

//getStockAdjustmentSummary
//Stock Adjustment Summary
export async function getStockAdjustmentSummary(reqBody = {}, options = {}) {
    try {
        const { ...fullReqBodyData } = reqBody;
        const apiReqBody = { ...fullReqBodyData };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.getStockAdjustmentSummary;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return Result;
    } catch (error) {
        // const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function saveStockAdjustment(apiReqBody = {}, options = {}) {
    try {
        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.saveStockAdjustment;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return Status;
    } catch (error) {
        // const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getUnitTypesByOrgId(reqBody = {}, options = {}) {
    try {
        const apiReqBody = {};
        const { OrgID } = reqBody;

        if (
            localStorage.getItem("unittypes") == null ||
            localStorage.getItem("unittypes") == ""
        ) {
            const API_URL = `${apiEndpoints.witmegWebNeurolageServer.getUnitTypesByOrgId}/${OrgID}`;
            const resObj = await serverRequest(
                "generateToken__WitmegWebNeurolageServer",
                API_URL,
                "GET",
                apiReqBody,
                options
            );
            const { Status, Result } = resObj.data;

            const unitTypes = Result.map((result) => ({
                ID: result.ID,
                UnitTypeId: result.UnitTypeId,
                UnitTypeName: result.UnitTypeName,
                IsActive: result.IsActive,
                IsBaseUnit: result.IsBaseUnit,
            }));

            localStorage.setItem("units", JSON.stringify(unitTypes));

            if (!Status) {
                throw new APIError(Result);
            }

            return unitTypes;
        }

        return JSON.parse(localStorage.getItem("units"));
    } catch (error) {
        // const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getEposProductCountByProductName(
    reqBody = {},
    options = {}
) {
    try {
        const { orgId, locationId, productName = "" } = reqBody;

        const apiReqBody = {
            OrgID: orgId,
            CloudLocationID: locationId,
            ProductName: productName,
        };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer
                .getEposProductCountByProductName;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return Number(Result);
    } catch (error) {
        // const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getEposBrandByBrandName(reqBody = {}, options = {}) {
    try {
        // const { orgId, BrandName = '' } = reqBody;

        // const apiReqBody = {
        // 	OrgID: orgId,
        // 	BrandName: BrandName,

        // };

        const { ...fullReqBodyData } = reqBody;

        const apiReqBody = { ...fullReqBodyData };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.getEposBrandByBrandName;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return Result;
    } catch (error) {
        // const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getEposBarcodeByBarcodeId(reqBody = {}, options = {}) {
    try {
        const { ...fullReqBodyData } = reqBody;

        const apiReqBody = { ...fullReqBodyData };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.getEposBarcodeByBarcodeIde;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return Result;
    } catch (error) {
        // const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getEposProductCountByCloudProductCategoryID(
    reqBody = {},
    options = {}
) {
    try {
        const { orgId, productCategoryId } = reqBody;

        const apiReqBody = {};

        const API_URL = `${apiEndpoints.witmegWebNeurolageServer.getEposProductCountByCloudProductCategoryID}/${orgId}/${productCategoryId}`;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "GET",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return Number(Result);
    } catch (error) {
        // const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getEposProductCountByCloudProductBrandId(
    reqBody = {},
    options = {}
) {
    try {
        const { orgId, productBrandId } = reqBody;

        const apiReqBody = {};

        const API_URL = `${apiEndpoints.witmegWebNeurolageServer.getEposProductCountByCloudProductBrandId}/${orgId}/${productBrandId}`;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "GET",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return Number(Result);
    } catch (error) {
        // const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getEposProductCountThatMinOrderLevelReached(
    reqBody = {},
    options = {}
) {
    try {
        const { orgId, locationId } = reqBody;

        const apiReqBody = {
            OrgID: orgId,
            CloudLocationID: locationId,
        };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer
                .getEposProductCountThatMinOrderLevelReached;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return Number(Result);
    } catch (error) {
        // const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getEposProductCountThatMaxOrderLevelReached(
    reqBody = {},
    options = {}
) {
    try {
        const { orgId, locationId } = reqBody;

        const apiReqBody = {
            OrgID: orgId,
            CloudLocationID: locationId,
        };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer
                .getEposProductCountThatMaxOrderLevelReached;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return Number(Result);
    } catch (error) {
        // const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getEposProductsByCloudProductName(
    reqBody = {},
    options = {}
) {
    try {
        const {
            orgId,
            locationId,
            productName,
            skip = 0,
            limit = 20,
        } = reqBody;

        const apiReqBody = {
            OrgID: orgId,
            CloudLocationID: locationId,
            ProductName: productName,
            Skip: skip,
            Limit: limit,
        };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer
                .getEposProductsByCloudProductName;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return resObj.data;
    } catch (error) {
        // const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getEposProductsByCloudProductCategoryId(
    reqBody = {},
    options = {}
) {
    try {
        const {
            orgId,
            locationId,
            productCategoryId,
            skip = 0,
            limit = 100,
        } = reqBody;

        const apiReqBody = {
            OrgID: orgId,
            CloudLocationID: locationId,
            CloudProductCategoryID: productCategoryId,
            Skip: skip,
            Limit: limit,
        };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer
                .getEposProductsByCloudProductCategoryId;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return Result;
    } catch (error) {
        // const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getEposProductsByCloudProductBrandId(
    reqBody = {},
    options = {}
) {
    try {
        const {
            orgId,
            locationId,
            productBrandId,
            skip = 0,
            limit = 20,
        } = reqBody;

        const apiReqBody = {
            OrgID: orgId,
            CloudLocationID: locationId,
            CloudProductBrandID: productBrandId,
            Skip: skip,
            Limit: limit,
        };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer
                .getEposProductsByCloudProductBrandId;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return resObj.data;
    } catch (error) {
        // const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getEposProductByBarcode(reqBody = {}, options = {}) {
    try {
        const { OrgID, CloudLocationID, Barcode } = reqBody;

        const apiReqBody = {
            OrgID: OrgID,
            CloudLocationID: CloudLocationID,
            Barcode: Barcode,
        };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.getEposProductByBarcode;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        // Ignoring/Handling some Errors as Non-Errors.
        if (!Status) {
            if (typeof Result === "string") {
                switch (true) {
                    // Instead of throwing error for no items. We simulate empty array instead.
                    case Result.includes("no documents in result"): {
                        return {
                            ...resObj.data,
                            Result: [],
                        };
                    }

                    default: {
                        break;
                    }
                }
            }

            // Came to here mean, Its not a custom NonError case. So just throwing error just like others.
            throw new APIError(Result);
        }

        return resObj.data;
    } catch (error) {
        // const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getEposProductByProductRefNo(reqBody = {}, options = {}) {
    try {
        const { orgId, locationId, productRefNo } = reqBody;

        const apiReqBody = {
            OrgID: orgId,
            CloudLocationID: locationId,
            ProductRefNo: productRefNo,
        };

        const apiReqUrl =
            apiEndpoints.witmegWebNeurolageServer.getEposProductByProductRefNo;

        const resObj = await axiosWitmegWebNeurolageServer({
            url: apiReqUrl,
            method: "POST",
            data: apiReqBody,
            ...options,
        });

        const { Status, Result } = resObj.data;

        // Ignoring/Handling some Errors as Non-Errors.
        if (!Status) {
            if (typeof Result === "string") {
                switch (true) {
                    // Instead of throwing error for no items. We simulate empty array instead.
                    case Result.includes("no documents in result"): {
                        return {
                            ...resObj.data,
                            Result: [],
                        };
                    }

                    default: {
                        break;
                    }
                }
            }

            // Came to here mean, Its not a custom NonError case. So just throwing error just like others.
            throw new APIError(Result);
        }

        return resObj.data;
    } catch (error) {
        // const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getEposProductByCloudProductId(
    reqBody = {},
    options = {}
) {
    try {
        const { orgId, productId } = reqBody;

        const apiReqBody = {
            OrgID: orgId,
            CloudProductID: productId,
        };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer
                .getEposProductByCloudProductId;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        // Ignoring/Handling some Errors as Non-Errors.
        if (!Status) {
            if (typeof Result === "string") {
                switch (true) {
                    // Instead of throwing error for no items. We simulate empty array instead.
                    case Result.includes("no documents in result"): {
                        return {
                            ...resObj.data,
                            Result: [],
                        };
                    }

                    default: {
                        break;
                    }
                }
            }

            // Came to here mean, Its not a custom NonError case. So just throwing error just like others.
            throw new APIError(Result);
        }

        return resObj.data;
    } catch (error) {
        // const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getEposProductThatMinOrderLevelReached(
    reqBody = {},
    options = {}
) {
    try {
        const { orgId, locationId, skip = 0, limit = 20 } = reqBody;

        const apiReqBody = {
            OrgID: orgId,
            CloudLocationID: locationId,
            Skip: skip,
            Limit: limit,
        };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer
                .getEposProductThatMinOrderLevelReached;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return resObj.data;
    } catch (error) {
        // const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getEposProductThatMaxOrderLevelReached(
    reqBody = {},
    options = {}
) {
    try {
        const { orgId, locationId, skip = 0, limit = 20 } = reqBody;

        const apiReqBody = {
            OrgID: orgId,
            CloudLocationID: locationId,
            Skip: skip,
            Limit: limit,
        };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer
                .getEposProductThatMaxOrderLevelReached;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return resObj.data;
    } catch (error) {
        // const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getSummaryOfStockChangesForTimeline(
    reqBody = {},
    options = {}
) {
    try {
        const {
            locationId,
            productId,
            startDate,
            skip = 0,
            limit = 20,
        } = reqBody;

        const apiReqBody = {
            CloudLocationID: locationId,
            CloudProductID: productId,
            /**
             * NOTE: Regarding Params. Currently,
             * 					- "StartDate" --> If this value is not provided, it return all entries. (Within Limit Value)
             * 					- "StartDate" --> Regardless of this value "Product Created" entry is always returned.
             * 					- "Limit"			--> Regardless of this param value, Minimum 5 entry is returned, If available. (For Example Limit=1-->5, Limit=2--->6, ....)
             * 					- "Skip"			--> Doesn't skip items in traditional way. Backend seems to decide which to send. (For example first 2 items and last 8 items. Not first 10 items.)
             */
            StartDate: startDate,
            Skip: skip,
            Limit: limit,
        };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer
                .getSummaryOfStockChangesForTimeline;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return Result;
    } catch (error) {
        // const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getEposUnitChartByCloudUnitChartId(
    reqBody = {},
    options = {}
) {
    try {
        const apiReqBody = {};

        const { CloudUnitChartId } = reqBody;

        const API_URL = `${apiEndpoints.witmegWebNeurolageServer.getEposUnitChartByCloudUnitChartId}/${CloudUnitChartId}`;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "GET",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return Result;
    } catch (error) {
        // const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function addEposProduct(apiReqBody = {}, options = {}) {
    try {
        const API_URL = apiEndpoints.witmegWebNeurolageServer.addEposProduct;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        } else {
            // Handling errors occur as "Status:true"
            // eslint-disable-next-line no-lonely-if
            if (isString(Result) && Result.includes("Product Already Exist")) {
                return { hasError: true, message: "Product Already Exist" };
            }
        }

        // This result is "CloudProductID"
        return Result;
    } catch (error) {
        const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                case errMsg.includes("Product Already Exist"): {
                    customErrMsg = "Product Already Exist.";
                    break;
                }

                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function addEposProductBrand(apiReqBody, options = {}) {
    try {
        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.addEposProductBrand;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            console.log("ERROR");
            // throw new APIError(Result);
        } else {
            if (isString(Result) && Result.includes("Brand Already Exist")) {
                return { hasError: true, message: "Brand Already Exist" };
            }
        }

        return Result;
    } catch (error) {
        const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                case errMsg.includes("Brand Already Exist"): {
                    customErrMsg = "Brand Already Exist.";
                    break;
                }

                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function addEposProductCategory(reqBody = {}, options = {}) {
    try {
        const { ...fullReqBodyData } = reqBody;

        const apiReqBody = { ...fullReqBodyData };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.addEposProductCategory;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        } else {
            // Handling errors occur as "Status:true"
            // eslint-disable-next-line no-lonely-if
            if (isString(Result) && Result.includes("Category Already Exist")) {
                return { hasError: true, message: "Category Already Exist" };
            }
        }

        // This result is "CloudProductID"
        return Result;
    } catch (error) {
        const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                case errMsg.includes("Category Already Exist"): {
                    customErrMsg = "Category Already Exist.";
                    break;
                }

                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getEposProductCategoryById(
    apiReqBody = {},
    options = {}
) {
    try {
        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.getEposProductCategoryById;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return Result;
    } catch (error) {
        const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function addEposUnitChart(reqBody = {}, options = {}) {
    try {
        const { ...fullReqBodyData } = reqBody;

        const apiReqBody = { ...fullReqBodyData };

        const API_URL = apiEndpoints.witmegWebNeurolageServer.addEposUnitChart;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        // This result is "CloudUnitChartId"
        return Result;
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function updateEposProduct(reqBody = {}, options = {}) {
    try {
        const { ...fullReqBodyData } = reqBody;

        const apiReqBody = { ...fullReqBodyData };

        const API_URL = apiEndpoints.witmegWebNeurolageServer.updateEposProduct;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        // This result is "Product Updated"
        return Result;
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function updateEposProductCategory(reqBody = {}, options = {}) {
    try {
        const { ...fullReqBodyData } = reqBody;

        const apiReqBody = { ...fullReqBodyData };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.updateEposProductCategory;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        // This result is "Product Updated"
        return Result;
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function updateEposProductBrand(apiReqBody, options = {}) {
    try {
        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.updateEposProductBrand;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;
        // console.log('resObj-------------------->', resObj)

        if (!Status) {
            throw new APIError(Result);
        }

        // This result is "Product Updated"
        return Result;
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getSalesRepsListByOrgId(reqBody = {}, options = {}) {
    try {
        const { orgId } = reqBody;

        const apiReqBody = {};

        const API_URL = `${apiEndpoints.witmegWebNeurolageServer.getSalesRepsListByOrgId}/${orgId}`;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "GET",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return Result;
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getEposCustomersCountByOrgId(reqBody = {}, options = {}) {
    try {
        const { orgId } = reqBody;

        const apiReqBody = {};

        const API_URL = `${apiEndpoints.witmegWebNeurolageServer.getEposCustomersCountByOrgId}/${orgId}`;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "GET",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return Number(Result);
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getEposCustomersByOrgId(reqBody = {}, options = {}) {
    try {
        const { orgId, locationId, skip = 0, limit = 20 } = reqBody;

        const apiReqBody = {
            OrgID: orgId,
            CloudLocationID: locationId,
            Skip: skip,
            Limit: limit,
        };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.getEposCustomersByOrgId;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return resObj.data;
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getEposCustomersByCloudCustomerId(
    reqBody = {},
    options = {}
) {
    try {
        const { orgId, locationId, searchKey } = reqBody;

        const apiReqBody = {
            OrgID: orgId,
            CloudLocationID: locationId,
            SearchKey: searchKey, // CloudCustomerId
        };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer
                .getEposCustomersByCloudCustomerId;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return resObj.data;
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getEposCustomersByCompanyName(
    reqBody = {},
    options = {}
) {
    try {
        const { orgId, locationId, searchKey } = reqBody;

        const apiReqBody = {
            OrgID: orgId,
            CloudLocationID: locationId,
            SearchKey: searchKey,
        };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.getEposCustomersByCompanyName;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return resObj.data;
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getEposCustomersByPostCode(reqBody = {}, options = {}) {
    try {
        const { orgId, locationId, searchKey } = reqBody;

        const apiReqBody = {
            OrgID: orgId,
            CloudLocationID: locationId,
            SearchKey: searchKey,
        };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.getEposCustomersByPostCode;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return resObj.data;
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getEposCustomersByAcNumber(reqBody = {}, options = {}) {
    try {
        const { orgId, locationId, searchKey } = reqBody;

        const apiReqBody = {
            OrgID: orgId,
            CloudLocationID: locationId,
            SearchKey: searchKey,
        };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.getEposCustomersByAcNumber;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return resObj.data;
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getEposCustomersByCity(reqBody = {}, options = {}) {
    try {
        const { orgId, locationId, searchKey } = reqBody;

        const apiReqBody = {
            OrgID: orgId,
            CloudLocationID: locationId,
            SearchKey: searchKey,
        };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.getEposCustomersByCity;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return resObj.data;
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getEposCustomersByEmail(reqBody = {}, options = {}) {
    try {
        const { orgId, locationId, searchKey } = reqBody;

        const apiReqBody = {
            OrgID: orgId,
            CloudLocationID: locationId,
            SearchKey: searchKey,
        };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.getEposCustomersByEmail;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return resObj.data;
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function addEposCustomer(reqBody = {}, options = {}) {
    try {
        const apiReqBody = {
            ...reqBody,
        };

        const API_URL = apiEndpoints.witmegWebNeurolageServer.addEposCustomer;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return resObj.data;
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function updateEposCustomer(reqBody = {}, options = {}) {
    try {
        const apiReqBody = {
            ...reqBody,
        };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.updateEposCustomer;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return resObj.data;
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getProductListCountForProformaCreation(
    reqBody = {},
    options = {}
) {
    try {
        const { orgId, locationId, searchKeyType, searchKey } = reqBody;

        const apiReqBody = {
            OrgID: orgId,
            CloudLocationID: locationId,
            SearchKeyType: searchKeyType, // Available Types : ItemCode, BarCode, Name, Category, Brand, OR Can Be Empty. (If empty backend return just first available items according to skip & limit)
            SearchKey: searchKey,
        };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer
                .getProductListCountForProformaCreation;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return Number(Result);
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getProductListForProformaCreation(
    reqBody = {},
    options = {}
) {
    try {
        const {
            orgId,
            locationId,
            searchKeyType,
            searchKey,
            skip = 0,
            limit = 20,
        } = reqBody;

        const apiReqBody = {
            OrgID: orgId,
            CloudLocationID: locationId,
            SearchKeyType: searchKeyType, // Available Types : ItemCode, BarCode, Name, Category, Brand, OR Can Be Empty. (If empty backend return just first available items according to skip & limit)
            SearchKey: searchKey,
            Skip: skip,
            Limit: limit,
        };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer
                .getProductListForProformaCreation;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return resObj.data;
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function addProformaInvoice(reqBody = {}, options = {}) {
    try {
        const { ...fullReqBodyData } = reqBody;

        const apiReqBody = { ...fullReqBodyData };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.addProformaInvoice;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return Result;
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getEposSuppliersAllByOrgId(reqBody = {}, options = {}) {
    try {
        const { ...fullReqBodyData } = reqBody;

        const apiReqBody = { ...fullReqBodyData };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.getEposSuppliersAllByOrgId;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return Result;
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

//save supplier
export async function addEposSupplier(reqBody = {}, options = {}) {
    try {
        const { apiReqBody } = reqBody;

        const API_URL = apiEndpoints.witmegWebNeurolageServer.addEposSupplier;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        // if (!Status) {
        // 	throw new APIError(Result);
        // }

        return { isAdded: Status, Message: Result };
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

//delete supplier
export async function deleteEposSupplier(reqBody = {}, options = {}) {
    try {
        const { ...fullReqBodyData } = reqBody;

        const apiReqBody = { ...fullReqBodyData };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.deleteEposSupplier;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return { isDeleted: Status, Message: Result };
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

//overview of supplier
export async function overviweEposSupplier(reqBody = {}, options = {}) {
    try {
        const { ...fullReqBodyData } = reqBody;

        const apiReqBody = { ...fullReqBodyData };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.overviweEposSupplier;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return { isDeleted: Status, Message: Result };
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

//edit supplier
export async function editEposSupplier(reqBody = {}, options = {}) {
    try {
        const { apiReqBody } = reqBody;

        const API_URL = apiEndpoints.witmegWebNeurolageServer.editEposSupplier;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return { isAdded: Status, Message: Result };
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

//search supplier
export async function searchEposSupplier(apiReqBody = {}, options = {}) {
    try {
        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.searchEposSupplier;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return { isAdded: Status, Message: Result };
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

//search supplier count
export async function searchCountEposSupplier(reqBody = {}, options = {}) {
    try {
        const { ...fullReqBodyData } = reqBody;

        const apiReqBody = { ...fullReqBodyData };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.searchCountEposSupplier;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return { isAdded: Status, Message: Result };
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

//get offers
export async function getAllEposOffers(reqBody = {}, options = {}) {
    try {
        const { ...fullReqBodyData } = reqBody;

        const apiReqBody = { ...fullReqBodyData };
        const OrgId = apiReqBody.OrgId;

        const API_URL = `${apiEndpoints.witmegWebNeurolageServer.getAllEposOffers}/${OrgId}`;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "GET",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return { isAdded: Status, Message: Result };
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

//get offers by location
export async function getAllEposOffersByLocation(reqBody = {}, options = {}) {
    try {
        const { ...fullReqBodyData } = reqBody;

        const apiReqBody = { ...fullReqBodyData };
        const SelectedLocation = apiReqBody.SelectedLocation;

        const API_URL = `${apiEndpoints.witmegWebNeurolageServer.getAllEposOffersByLocation}/${SelectedLocation}`;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "GET",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return { isAdded: Status, Message: Result };
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

//save offers
export async function saveEposOffers(reqBody = {}, options = {}) {
    try {
        const { ...fullReqBodyData } = reqBody;

        const apiReqBody = { ...fullReqBodyData };
        const OrgId = apiReqBody.OrgId;

        const API_URL = apiEndpoints.witmegWebNeurolageServer.saveEposOffers;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return { isAdded: Status, Message: Result };
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

//delete offers
export async function deleteEposOffers(reqBody = {}, options = {}) {
    try {
        const { ...fullReqBodyData } = reqBody;

        const apiReqBody = { ...fullReqBodyData };
        // const OrgId = apiReqBody.OrgId;

        const API_URL = apiEndpoints.witmegWebNeurolageServer.deleteEposOffers;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        // if (!Status) {
        // 	throw new APIError(Result);
        // }

        if (apiReqBody.CloudOfferIDs.length > 1) {
            console.log("length");
            const obj = JSON.parse(resObj.data);
            const { status } = resObj.status;
            if (status == 200) {
                return { isDeleted: true, Message: "" };
            }
            console.log("obj", obj);
        } else {
            const { Status, Result } = resObj.data;
            return { isDeleted: Status, Message: Result };
        }
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

//save offers
export async function editEposOffers(reqBody = {}, options = {}) {
    try {
        const { ...fullReqBodyData } = reqBody;

        const apiReqBody = { ...fullReqBodyData };
        const OrgId = apiReqBody.OrgId;

        const API_URL = apiEndpoints.witmegWebNeurolageServer.editEposOffers;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return { isAdded: Status, Message: Result };
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getEposSuppliersByCompanyName(
    reqBody = {},
    options = {}
) {
    try {
        const { ...fullReqBodyData } = reqBody;

        const apiReqBody = { ...fullReqBodyData };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.getEposSuppliersAllByOrgId;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return Result;
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getProductListForPurchaseOrderCreation(
    reqBody = {},
    options = {}
) {
    try {
        const {
            orgId,
            locationId,
            searchKeyType,
            searchKey,
            skip = 0,
            limit = 20,
        } = reqBody;

        const apiReqBody = {
            OrgID: orgId,
            CloudLocationID: locationId,
            SearchKeyType: searchKeyType, // Available Types : ItemCode, BarCode, Name, Category, Brand, OR Can Be Empty. (If empty backend return just first available items according to skip & limit)
            SearchKey: searchKey,
            Skip: skip,
            Limit: limit,
        };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer
                .getProductListForPurchaseOrderCreation;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return resObj.data;
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getPurchaseOrdersCount(reqBody = {}, options = {}) {
    try {
        const { OrgID, CloudLocationID, Status = 0 } = reqBody;

        const apiReqBody = {
            OrgID: OrgID,
            CloudLocationID: CloudLocationID,
            Status: Status,
        };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.getPurchaseOrdersCount;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        // const { Result } = resObj.data;

        // if (!Result) {
        // 	// throw new APIError(Result);
        // 	return [];
        // }

        return resObj.data;
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getPurchaseOrdersList(reqBody = {}, options = {}) {
    try {
        const {
            OrgID,
            CloudLocationID,
            searchKeyType,
            Skip = 0,
            Limit = 10,
            Status = 0,
        } = reqBody;

        const apiReqBody = {
            OrgID: OrgID,
            CloudLocationID: CloudLocationID,
            SearchKeyType: searchKeyType, // Available Types : ItemCode, BarCode, Name, Category, Brand, OR Can Be Empty. (If empty backend return just first available items according to skip & limit)
            Skip: Skip,
            Limit: Limit,
            Status: Status,
        };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.getPurchaseOrdersList;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        // console.log("resObj 2091", resObj);

        // const { Result } = resObj.data;

        // if (!Result) {
        // 	// throw new APIError(Result);
        // 	return [];
        // }

        return resObj.data;
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getPurchaseOrderDetailsById(reqBody = {}, options = {}) {
    try {
        const { OrgID, CloudLocationID, poId } = reqBody;

        const apiReqBody = {
            OrgID: OrgID,
            CloudLocationID: CloudLocationID,
            CloudPurchaseOrderID: poId,
        };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.getPurchaseOrderDetailsById;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        // const { Result } = resObj.data;

        // if (!Result) {
        // 	// throw new APIError(Result);
        // 	return [];
        // }

        return resObj.data;
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function savePurchaseOrder(reqBody = {}, options = {}) {
    try {
        const { ...fullReqBodyData } = reqBody;

        const apiReqBody = { ...fullReqBodyData };

        const API_URL = apiEndpoints.witmegWebNeurolageServer.savePurchaseOrder;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return Result;
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function updatePuchaseOrder(reqBody = {}, options = {}) {
    try {
        const { ...fullReqBodyData } = reqBody;

        const apiReqBody = { ...fullReqBodyData };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.updatePuchaseOrder;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;

        if (!Status) {
            throw new APIError(Result);
        }

        return resObj.data;
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        // errorLogger(error, { customErrMsg });
        // return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function updatePuchaseOrderStatus(reqBody = {}, options = {}) {
    try {
        const { ...fullReqBodyData } = reqBody;

        const apiReqBody = { ...fullReqBodyData };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.updatePuchaseOrderStatus;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        // const { Status, Result } = resObj;

        // if (!Status) {
        // 	throw new APIError(Result);
        // }

        return resObj.data;
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        // errorLogger(error, { customErrMsg });
        // return Promise.reject(APIError(customErrMsg, error));
    }
}

export async function getEposCustomAttributesByOrgId(
    reqBody = {},
    options = {}
) {
    try {
        const apiReqBody = {};

        const { OrgID } = reqBody;

        const API_URL = `${apiEndpoints.witmegWebNeurolageServer.getEposCustomAttributesByOrgId}/${OrgID}`;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "GET",
            apiReqBody,
            options
        );

        const { Status, Result } = resObj.data;
        if (!Status) {
            // throw new APIError(Result);
            return [];
        }

        return Result;
    } catch (error) {
        // const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}

//Product Overview

export async function getEposProductOverviewByProductId(
    reqBody = {},
    options = {}
) {
    try {
        const { locationId, productId, orgId, noOfDays } = reqBody;

        const apiReqBody = {
            CloudLocationID: locationId,
            CloudProductID: productId,
            OrgID: orgId,
            NumberOfDays: noOfDays,
        };

        const API_URL =
            apiEndpoints.witmegWebNeurolageServer.getEposProductOverview;
        const resObj = await serverRequest(
            "generateToken__WitmegWebNeurolageServer",
            API_URL,
            "POST",
            apiReqBody,
            options
        );

        // const { Status, Result } = resObj.data;

        // if (!Status) {
        // 	throw new APIError(Result);
        // }

        return resObj.data;
    } catch (error) {
        //const errMsg = error.message;
        let customErrMsg = error.customErrMsg || "";

        if (error.customErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }

        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }
}
