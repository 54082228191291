export const generateLocationListOfAllOrganizations = (options = {}) => {
	const { dashboardSliceState } = options;
	const { fullUserOrgDetailsList } = dashboardSliceState;

	return fullUserOrgDetailsList.reduce((acc, currentValue, orgIndex) => {
		const org = currentValue;

		org.Companies.forEach((com, comIndex) => {
			com.LocationIDs.forEach((loc, locIndex) => {
				acc.push(
					// Details for Each Location.
					{
						organizationDetails: {
							OrganizationName: org.OrganizationName,
							OrganizationID: org.OrganisationID,
							OrganizationIndex: orgIndex,
						},
						companyDetails: {
							CompanyName: com.CompanyName,
							CompanyID: com.CompanyID,
							CompanyIndex: comIndex,
						},
						locationDetails: {
							LocationName: loc.LocationName,
							LocationID: loc.LocationID,
							LocationIndex: locIndex,
						},
					},
				);
			});
		});

		return acc;
	}, []);
};

export const handleLocationChange = (options = {}) => {
	const { dashboardSliceActions } = options;

	const { setLocationSelectorData: setLocationSelectorDataAction } =
		dashboardSliceActions;

	const { clickedLocationData = {} } = options;

	const { organizationDetails, companyDetails, locationDetails } =
		clickedLocationData;
	// Updating redux store with this selection. So anywhere in the app can use this location selector details.
	setLocationSelectorDataAction({
		orgIndex: organizationDetails.OrganizationIndex,
		orgId: organizationDetails.OrganizationID,

		comIndex: companyDetails.CompanyIndex,
		comId: companyDetails.CompanyID,

		locIndex: locationDetails.LocationIndex,
		locId: locationDetails.LocationID,
	});
};
