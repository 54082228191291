const loginRoot = "/login";
const dashboardRoot = "/dashboard";
const inventoryRoot = "/inventory";
const salesRoot = "/sales";
const appsRoot = "/apps";
const purchaseRoot = "/purchase";

export const routePaths = {
    loginRoot,
    dashboardRoot,

    // ****** Dashboard -> Overview ******
    dashboard__overview: `${dashboardRoot}/overview`,

    // ****** Dashboard -> Inventory -> XXX ******
    dashboard__inventory__products: `${dashboardRoot + inventoryRoot}/products`,
    dashboard__inventory__products__add: `${
        dashboardRoot + inventoryRoot
    }/products/add`,
    dashboard__inventory__category: `${dashboardRoot + inventoryRoot}/category`,

    // dashboard__inventory__promotions: `${dashboardRoot + inventoryRoot
    //   }/promotions`,
    dashboard__inventory__brands: `${dashboardRoot + inventoryRoot}/brand`,
    dashboard__inventory__offers: `${dashboardRoot + inventoryRoot}/offers`,

    // dashboard__inventory__promotions: `${dashboardRoot + inventoryRoot
    //   }/promotions `,
    // dashboard__inventory__offers: `${dashboardRoot + inventoryRoot}/offers`,

    // ****** Dashboard -> Sales -> Customers ******
    dashboard__sales__customers: `${dashboardRoot + salesRoot}/customers`,
    dashboard__sales__customers__add: `${
        dashboardRoot + salesRoot
    }/customers/add`,
    dashboard__sales__customers__edit: `${
        dashboardRoot + salesRoot
    }/customers/edit`,
    dashboard__sales__customers__search: `${
        dashboardRoot + salesRoot
    }/customers/search`,

    // ****** Dashboard -> Sales -> Proformas ******
    dashboard__sales__proformas: `${dashboardRoot + salesRoot}/proformas`,
    dashboard__sales__proformas__add: `${
        dashboardRoot + salesRoot
    }/proformas/add`,
    dashboard__sales__proformas__edit: `${
        dashboardRoot + salesRoot
    }/proformas/edit`,
    dashboard__sales__proformas__search: `${
        dashboardRoot + salesRoot
    }/proformas/search`,

    // ****** Dashboard -> Sales -> Purcrase Orders ******
    dashboard__purchase__purchase_orders: `${
        dashboardRoot + purchaseRoot
    }/purchase-orders`,
    dashboard__purchase__purchase_orders__add: `${
        dashboardRoot + purchaseRoot
    }/purchase-orders/add`,
    dashboard__purchase__purchase_orders__edit: `${
        dashboardRoot + purchaseRoot
    }/purchase-orders/edit/:id`,
    dashboard__purchase__purchase_orders__search: `${
        dashboardRoot + purchaseRoot
    }/purchase-orders/search`,
    dashboard__purchase__supplier: `${dashboardRoot + purchaseRoot}/supplier`,
    dashboard__purchase__msa: `${dashboardRoot + purchaseRoot}/msa`,

    // ****** Dashboard -> Account Settings ******
    dashboard__accountSettings: `${dashboardRoot}/accountSettings`,

    // ****** Dashboard -> Report  View ******
    dashboard__reportView: `${dashboardRoot}/report-view`,

    // ****** Dashboard -> Apps  ******
    dashboard__apps_nisa: `${dashboardRoot + appsRoot}/nisa`,
    dashboard__apps_nisa_promotions: `${
        dashboardRoot + appsRoot
    }/nisa-promotions`,
};
