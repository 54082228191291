import { bindActionCreators } from '@reduxjs/toolkit';
import { Alert, Tabs, Button } from 'antd';
// import { LoaderView } from 'ecologital-ui-library';
import { Formik } from 'formik';
import lodash from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
// import { Avatar } from 'witmeg-ui-system';

import { reqStatusTypes } from '../../../redux/helpers/constants';
import { sectionName as dashboardSectionName } from '../../../redux/slices/dashboard/dashboardConstants';
import { actions as dashboardSliceActions } from '../../../redux/slices/dashboard/dashboardSlice/dashboardSlice';
import { actions as inventoryProductsPageSliceActions } from '../../../redux/slices/dashboard/inventoryProductsPageSlice/inventoryProductsPageSlice';
import {
	addEposProduct,
	getEposProductBrandsByOrgId,
	getEposProductByCloudProductId,
	getEposProductCategoriesByOrgId,
	getUnitTypesByOrgId,
	updateEposProduct,
} from '../../../utilities/apiRequests/witmegWebNeurolageServerRequests';
import { errorLogger } from '../../../utilities/errorHandlers';
import { getLoggedUserId } from '../../../utilities/userAuthentication';

import ConditionalTabRenderer from './helperComponents/ConditionalTabRenderer';
import {
	createCustomTabFormRelatedApiData,
	createImagesTabFormRelatedApiData,
	createMasterTabFormRelatedApiData,
} from './helpers/apiDataCreators';
import {
	fullValidationScheme,
	generateFullBaseInitialState,
	mapApiProductDataToFormikFormStructure,
} from './helpers/formikHelperData';
import { generateFomikFormValuesObjParserFunctions } from './helpers/parserHelpers';
import { customPropertyTypesList } from './helpers/placeholderData';
import './ProductMasterView.css';
import PmvCustomTab from './subSections/PmvCustomTab/PmvCustomTab';
import PmvImagesTab from './subSections/PmvImagesTab/PmvImagesTab';
import PmvMasterTab from './subSections/PmvMasterTab/PmvMasterTab';

const { TabPane } = Tabs;
let productData = {};
class ProductMasterView extends Component {
	constructor(props) {
		super(props);

		const { inventoryProductsPageSliceState } = this.props;

		const { productAddEditModalType, productAddEditModalAdditionalInfo } =
			inventoryProductsPageSliceState;

		const { productId: editingProductId } = productAddEditModalAdditionalInfo;
		const isEditMode = productAddEditModalType === 'EDIT';

		this.state = {
			activeTabKey: '1', // Currently Showing Tab.
			isEditMode,
			editingProductId,

			formikInitialState: {},

			// "Fetching Initial Page Data", related state.
			pageInitialDataReqStatus: reqStatusTypes.idle,
			pageInitialDataReqError: '',
			categoryTypeList: [], // All Category Types.
			brandTypeList: [], // All Brand Types.
			unitTypeList: [], // All Unit Types.
			customPropertyTypesList: [], // All Custom Property Types.
			rawProductData: {}, // Product's Raw API data. (Especially used when opened in "EDIT" mode)
		};
	}

	async fetchNecessaryInitialPageData() {
		try {
			const { isEditMode, editingProductId } = this.state;
			const { dashboardSliceState } = this.props;

			const { currentlySelectedOrgId, currentlySelectedLocationId } =
				dashboardSliceState;

			// These will update below in certain cases. (Especially in "EDIT" mode)
			let rawProductData = {};
			let preFilledFormikInitialValues = {};

			this.setState({
				pageInitialDataReqStatus: reqStatusTypes.pending,
				pageInitialDataReqError: '',
			});

			// *** Below are pre-fetching data that needed for some specific parts in forms.
			const productCategories = await getEposProductCategoriesByOrgId({
				OrgID: currentlySelectedOrgId,
			});

			const productBrands = await getEposProductBrandsByOrgId({
				OrgID: currentlySelectedOrgId,
			});

			const unitTypes = await getUnitTypesByOrgId({
				OrgID: currentlySelectedOrgId,
			});

			// *** When in "EDIT" Mode.
			if (isEditMode) {
				// Getting Editing Products Initial Raw Data.
				const productDataResponse = await getEposProductByCloudProductId({
					orgId: currentlySelectedOrgId,
					productId: editingProductId,
				});
				rawProductData = productDataResponse.Result[0];
				productData = productDataResponse.Result[0];

				// Mapping "Raw Product Values" to "Formik Value Structure", So we can pass them as formik initial values.
				preFilledFormikInitialValues = mapApiProductDataToFormikFormStructure({
					productData: rawProductData,

					unitTypeList: unitTypes,
					categoryTypeList: productCategories,
					brandTypeList: productBrands,
					customPropertyTypesList,
					currentlySelectedLocationId,
				});
			}

			this.setState(
				{
					pageInitialDataReqStatus: reqStatusTypes.succeeded,
					pageInitialDataReqError: '',
					categoryTypeList: productCategories,
					brandTypeList: productBrands,
					unitTypeList: unitTypes,
					customPropertyTypesList,
					rawProductData,
				},
				() => {
					// NOTE : Re-Setting React State here because, "generateFullBaseInitialState()" depending on some values from state we define in above.
					this.setState({
						formikInitialState: generateFullBaseInitialState({
							preFilledInitialValues: preFilledFormikInitialValues,
							dynamicInitialValueGetters: {
								getDefaultBaseUnitType: () => this.getDefaultBaseUnitType(),
							},
						}),
					});
				},
			);
		} catch (error) {
			errorLogger(error);

			this.setState({
				pageInitialDataReqStatus: reqStatusTypes.failed,
				pageInitialDataReqError: 'Error occurred while fetching initial data.',
			});
		}
	}

	getDefaultBaseUnitType() {
		const { unitTypeList: unitTypeListFromState = [] } = this.state;

		const unitTypeList = unitTypeListFromState;

		const index = unitTypeList.findIndex((item) => {
			return item.UnitTypeName === 'EA' || item.IsBaseUnit;
		});

		return index >= 0 ? index : '';
	}

	// This get the all the form values in every form of every tab.
	// So in here we parse all those values and send necessary data to Add/Edit a product.
	async handleProductAddEditFormSubmitting(
		allFormValuesInEveryTab,
		formikProps,
	) {
		try {
			const {
				isEditMode,
				editingProductId,

				categoryTypeList,
				brandTypeList,
				unitTypeList,
				rawProductData,
			} = this.state;

			const { dashboardSliceState, inventoryProductsPageSliceActions } =
				this.props;

			const { currentlySelectedOrgId, currentlySelectedLocationId } =
				dashboardSliceState;

			const { toggleProductAddEditModal, invokeInventoryProductsPageResetter } =
				inventoryProductsPageSliceActions;

			const parserFunctions = generateFomikFormValuesObjParserFunctions(
				allFormValuesInEveryTab,
				{
					categoryTypeList,
					brandTypeList,
					unitTypeList,
					customPropertyTypesList,
				},
			);

			const userId = getLoggedUserId();

			const commonArgumentsForApiDataCreatorFunctions = {
				currentlySelectedOrgId,
				currentlySelectedLocationId,
				userId,

				isEditMode,
				rawProductData,

				parserFunctions,
			};

			const masterTabFormRelatedApiData = createMasterTabFormRelatedApiData(
				commonArgumentsForApiDataCreatorFunctions,
			);

			const customTabFormRelatedApiData = createCustomTabFormRelatedApiData(
				commonArgumentsForApiDataCreatorFunctions,
			);

			const imagesTabFormRelatedApiData = createImagesTabFormRelatedApiData(
				commonArgumentsForApiDataCreatorFunctions,
			);

			const fullAddEposProductApiReqBody = {
				...masterTabFormRelatedApiData,
				...customTabFormRelatedApiData,
				...imagesTabFormRelatedApiData,

				/*
						NOTE : Seems, Below are automatically added by Backend, If Not Provided.

						OnLineConfig: {
							SelleableTypes: null,
						},


						ProductTag: '',

						Discount: '',
						MaxDiscount: 0,
						IsDeleted: 0,
				*/
			};

			if (isEditMode) {
				// let fullUpdateEposProductApiReqBody = lodash.mergeWith(
				// 	rawProductData,
				// 	fullAddEposProductApiReqBody,
				// 	// eslint-disable-next-line consistent-return
				// 	(objValue, srcValue, key) => {
				// 		// Making below mentioned keys are not merged with existing values.
				// 		const ignoreMergeKeys = ['OnLineImages', 'CustomArray', 'CloudSellingPricesArray', 'CloudCostPricesArray'];

				// 		if (ignoreMergeKeys.includes(key)) {
				// 			return srcValue;
				// 		}
				// 	},
				// );

				const postData = Object.assign(fullAddEposProductApiReqBody, {
					ProductID: rawProductData.ProductID,
					CloudCostPricesArray: productData.CloudCostPricesArray.map((ccp) =>
						ccp.CloudLocationID ===
						fullAddEposProductApiReqBody.CloudCostPricesArray.find(
							(p) => p.CloudLocationID,
						).CloudLocationID
							? fullAddEposProductApiReqBody.CloudCostPricesArray.find(
									(p) => p.CloudLocationID,
							  )
							: ccp,
					),
					CloudSellingPricesArray: productData.CloudSellingPricesArray.map(
						(csp) =>
							csp.CloudLocationID ===
							fullAddEposProductApiReqBody.CloudSellingPricesArray.find(
								(p) => p.CloudLocationID,
							).CloudLocationID
								? fullAddEposProductApiReqBody.CloudSellingPricesArray.find(
										(p) => p.CloudLocationID,
								  )
								: csp,
					),
				});

				await updateEposProduct({
					ID: editingProductId,
					...postData,
				});
			} else {
				await addEposProduct(fullAddEposProductApiReqBody);
			}

			toast.success(`Product ${isEditMode ? 'Updated' : 'Added'}.`);

			toggleProductAddEditModal(); // Closing the Modal.
			invokeInventoryProductsPageResetter(true); // Remotely Resetting "Inventory Products Page". (So user don't see out-dated data list in that page.)
			formikProps.setStatus({
				error: '',
			});
			formikProps.setSubmitting(false);
		} catch (error) {
			const errMsg =
				error.customErrMsg ||
				'Error occurred while Creating/Editing the product.';

			errorLogger(error);

			toast.error(errMsg);
			formikProps.setStatus({
				error: errMsg,
			});
			formikProps.setSubmitting(false);
		}
	}

	getEachTabsMetaData(options = {}) {
		const { formikProps } = options;

		const tabMetaData = {
			masterTab: {},
			customTab: {},
			imageTab: {},
		};

		const tabsList = [
			{
				tabMetaKey: 'masterTab',
				formikKey: 'masterSection',
			},
			{
				tabMetaKey: 'customTab',
				formikKey: 'customSection',
			},
			{
				tabMetaKey: 'imageTab',
				formikKey: 'imageSection',
			},
		];

		tabsList.forEach((tab) => {
			const tabErrors = lodash.get(formikProps.errors, tab.formikKey);
			const tabTouched = lodash.get(formikProps.touched, tab.formikKey);

			if (lodash.isEmpty(tabErrors)) {
				tabMetaData[tab.tabMetaKey].isErrors = false;
			} else {
				tabMetaData[tab.tabMetaKey].isErrors = true;
			}

			if (lodash.isEmpty(tabTouched)) {
				tabMetaData[tab.tabMetaKey].isTouched = false;
			} else {
				tabMetaData[tab.tabMetaKey].isTouched = true;
			}

			if (
				tabMetaData[tab.tabMetaKey].isErrors &&
				tabMetaData[tab.tabMetaKey].isTouched
			) {
				tabMetaData[tab.tabMetaKey].showErrors = true;
			} else {
				tabMetaData[tab.tabMetaKey].showErrors = false;
			}
		});

		return tabMetaData;
	}

	componentDidMount() {
		this.fetchNecessaryInitialPageData();
	}

	render() {
		const {
			activeTabKey,
			isEditMode,

			formikInitialState,

			pageInitialDataReqStatus,
			pageInitialDataReqError,
			categoryTypeList,
			brandTypeList,
			unitTypeList,
			customPropertyTypesList,
		} = this.state;

		const { dashboardSliceState, inventoryProductsPageSliceActions } =
			this.props;

		const {
			fullUserOrgDetailsList,
			currentlySelectedOrgIndex,
			defaultCurrencyDetails,
		} = dashboardSliceState;

		const { toggleProductAddEditModal } = inventoryProductsPageSliceActions;

		const currentOrgName =
			fullUserOrgDetailsList[currentlySelectedOrgIndex].OrganizationName;

		const isMainPageActionsRunning =
			pageInitialDataReqStatus === reqStatusTypes.pending;
		const isMainPageActionsError =
			pageInitialDataReqStatus !== reqStatusTypes.pending &&
			pageInitialDataReqError;

		if (isMainPageActionsRunning || isMainPageActionsError) {
			return (
				<div><h3>Loading ...</h3></div>
				// <LoaderView
				// 	className='ProductMasterView'
				// 	isLoading={isMainPageActionsRunning}
				// 	isError={isMainPageActionsError}
				// 	typeConfigs={{
				// 		error: {
				// 			extra: (
				// 				<Button
				// 					type='primary'
				// 					onClick={() => {
				// 						this.fetchNecessaryInitialPageData();
				// 					}}>
				// 					TRY AGAIN
				// 				</Button>
				// 			),
				// 		},
				// 	}}
				// />
			);
		}

		return (
			<div className='ProductMasterView'>
				<Formik
					initialStatus={{}}
					initialValues={formikInitialState}
					enableReinitialize
					validationSchema={fullValidationScheme}
					onSubmit={(allFormValuesInEveryTab, formikProps) => {
						this.handleProductAddEditFormSubmitting(
							allFormValuesInEveryTab,
							formikProps,
						);
					}}
					validateOnChange={false} // As a Performance Improvement (But Note that there could be some "Validation Error Showing" caveats due to this.)
				>
					{(formikProps) => {
						const parserFunctions = generateFomikFormValuesObjParserFunctions(
							formikProps.values,
							{
								categoryTypeList,
								brandTypeList,
								unitTypeList,
								customPropertyTypesList,
							},
						);

						const commonPropsForTabCompParts = {
							activeTabKey,

							formikProps,
							categoryTypeList,
							brandTypeList,
							unitTypeList,
							customPropertyTypesList,

							toggleProductAddEditModal,
							parserFunctions,
							isEditMode,

							currentOrgName,
						};

						const tabsMetaData = this.getEachTabsMetaData({ formikProps });
						const isMasterTabFilled =
							(isEditMode && !tabsMetaData.masterTab.isErrors) ||
							(!tabsMetaData.masterTab.isErrors &&
								tabsMetaData.masterTab.isTouched);

						return (
							<div>
								<div className='ProductMasterView____notesSection'>
									<div>NOTES : </div>
									<ul>
										<li>
											{/* eslint-disable-next-line react/no-unescaped-entities */}
											Other Tabs are disabled when, "Master Tab" required data
											are not fully filled.
										</li>
									</ul>
								</div>

								<Tabs
									defaultActiveKey='1'
									type='card'
									size='small'
									tabBarGutter={2}
									animated={{ inkBar: false, tabPane: false }}
									onChange={(activeTabKey) => {
										this.setState({
											activeTabKey,
										});
									}}>
									<TabPane
										tab={
											<div>
												Master {tabsMetaData.masterTab.showErrors ? '*' : ''}
											</div>
										}
										key='1'>
										<ConditionalTabRenderer
											activeTabKey={activeTabKey}
											thisTabKey='1'>
											<PmvMasterTab
												{...commonPropsForTabCompParts}
												defaultCurrencyDetails={defaultCurrencyDetails}
											/>
										</ConditionalTabRenderer>
									</TabPane>

									<TabPane
										tab={
											<div>
												Custom {tabsMetaData.customTab.showErrors ? '*' : ''}
											</div>
										}
										key='2'
										disabled={!isMasterTabFilled}>
										<PmvCustomTab {...commonPropsForTabCompParts} />
									</TabPane>

									<TabPane tab='Barcode' key='3' disabled={!isMasterTabFilled}>
										<ConditionalTabRenderer
											activeTabKey={activeTabKey}
											thisTabKey='3'>
											Barcode
										</ConditionalTabRenderer>
									</TabPane>

									<TabPane tab='Location' key='4' disabled={!isMasterTabFilled}>
										<ConditionalTabRenderer
											activeTabKey={activeTabKey}
											thisTabKey='4'>
											Location
										</ConditionalTabRenderer>
									</TabPane>

									<TabPane tab='Stock' key='5' disabled={!isMasterTabFilled}>
										<ConditionalTabRenderer
											activeTabKey={activeTabKey}
											thisTabKey='5'>
											Stock
										</ConditionalTabRenderer>
									</TabPane>

									<TabPane
										tab={
											<div>
												Images {tabsMetaData.imageTab.showErrors ? '*' : ''}
											</div>
										}
										key='6'
										disabled={!isMasterTabFilled}>
										<ConditionalTabRenderer
											activeTabKey={activeTabKey}
											thisTabKey='6'>
											<PmvImagesTab {...commonPropsForTabCompParts} />
										</ConditionalTabRenderer>
									</TabPane>

									<TabPane tab='Supplier' key='7' disabled={!isMasterTabFilled}>
										<ConditionalTabRenderer
											activeTabKey={activeTabKey}
											thisTabKey='7'>
											Supplier
										</ConditionalTabRenderer>
									</TabPane>
								</Tabs>

								<div className='ProductMasterView__footer'>
									{formikProps.status.error && (
										<div className='ProductMasterView__footer__errorViewer'>
											<Alert
												message={formikProps.status.error}
												type='error'
												showIcon
											/>
										</div>
									)}

									<div className='ProductMasterView__footer__buttonList'>
										<Button
											type='primary'
											loading={formikProps.isSubmitting}
											onClick={() => {
												formikProps.handleSubmit();
											}}>
											SAVE
										</Button>

										<Button
											disabled={formikProps.isSubmitting}
											onClick={() => {
												toggleProductAddEditModal();
											}}>
											CANCEL
										</Button>
									</div>
								</div>
							</div>
						);
					}}
				</Formik>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	dashboardSliceState: state[dashboardSectionName].dashboard,
	inventoryProductsPageSliceState:
		state[dashboardSectionName].inventoryProductsPage,
	defaultCurrencyDetails: state.settings.currencySettings
		? state.settings.currencySettings.DefaultCurrency
		: null,
});

const mapDispatchToProps = (dispatch) => {
	const boundDashboardSliceActions = bindActionCreators(
		dashboardSliceActions,
		dispatch,
	);

	const boundInventoryProductsPageSliceActions = bindActionCreators(
		inventoryProductsPageSliceActions,
		dispatch,
	);

	return {
		dashboardSliceActions: boundDashboardSliceActions,
		inventoryProductsPageSliceActions: boundInventoryProductsPageSliceActions,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductMasterView);
