import { createAsyncThunk } from "@reduxjs/toolkit";

import { getCurrencySettingsByOrgIdAndCloudLocationID as getCurrencySettingsAPI } from "../../../utilities/apiRequests/settingsServerRequests";
// import { getLoggedUserId } from '../../../utilities/userAuthentication';

import sliceConstants from "./settingsSliceConstants";

export const dummyFn = createAsyncThunk(
    `${sliceConstants.name}/dummyFn`,
    async (options = {}) => {
        try {
            return await Promise.resolve(options.dummyValue);
        } catch (error) {
            const errMsg = error.customErrMsg || error.message;
            return Promise.reject(errMsg);
        }
    }
);

export const getCurrencySettingsByOrgIdAndCloudLocationID = createAsyncThunk(
    `${sliceConstants.name}/getCurrencySettingsByOrgIdAndCloudLocationID`,
    async (payload) => {
        console.log("payload========>>>", payload);
        try {
            // const { UserID = getLoggedUserId() } = options;

            // const fullUserOrgDetailsByUserId = await getFullUserOrgDetailsByUserIdAPI(
            // 	{ payload },
            // );
            const currencyData = await getCurrencySettingsAPI(payload);
            // console.log("getCurrencySettingsAPI", currencyData);
            return await getCurrencySettingsAPI(payload);
        } catch (error) {
            const errMsg = error.customErrMsg || error.message;
            return Promise.reject(errMsg);
        }
    }
);

// export const getCurrentlyLoggedUserDetails = createAsyncThunk(
//     `${sliceConstants.name}/getCurrentlyLoggedUserDetails`,
//     async (options = {}, thunkAPI) => {
//         try {
//             return await getUserDetailsByUserIdAPI({
//                 UserID: getLoggedUserId(),
//             });
//         } catch (error) {
//             const errMsg = error.customErrMsg || error.message;
//             return Promise.reject(errMsg);
//         }
//     },
// );

const extraActions = {
    dummyFn,
    getCurrencySettingsByOrgIdAndCloudLocationID,
};

export default extraActions;
