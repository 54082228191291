import {
	DeleteOutlined as DeleteOutlinedIcon,
	EditOutlined as EditOutlinedIcon,
} from '@ant-design/icons';
import { Empty, Popover, InputNumber, Select, Button, Popconfirm } from 'antd';
import { LoaderView } from 'ecologital-ui-library';
import { at } from 'lodash';
import React, { Component, useRef, useState } from 'react';

import FieldErrorMessage from '../../../../../../widgets/formRelated/FieldErrorMessage/FieldErrorMessage';
import ProductList from '../../../../../ProductList/ProductList';
import { getFormSectionsPartialObjPaths } from '../../../../helpers/parserHelpers';

import './ProductItemList.css';

const { addedItemsInfoObjPath } = getFormSectionsPartialObjPaths().itemsTab;

export default class ProductItemList extends Component {

	constructor(props) {
		super(props);

		this.state = {
			itemList: props.currentItems
		};
	}

	updateItemList = (val, fieldName, item) => {
		// const arr = [...this.state.itemList]
		// arr[arr.findIndex(a => a.CloudProductID === item.CloudProductID)][fieldName] = val
		const arr = this.props.currentItems.map(li => {
			return {
				...li,
				[fieldName]: li.CloudProductID === item.CloudProductID ? val : li[fieldName],
				availableUnitTypes: li.CloudProductID === item.CloudProductID && fieldName === 'UnitTypeID' ? [...new Set([li.UnitTypeID, val])] : [li.availableUnitTypes]
			}
		})
		this.setState({
			itemList: arr
		})
	}

	render() {
		const {
			selectedOrgId,
			selectedLocationId,
			formikProps,
			helperFns,
			parserFunctions,
			unitTypes,
			currentItems,
			handleDeletePOItem,
			statusCode
		} = this.props;

		const updateItemList = this.updateItemList;

		// const itemList = currentItems && currentItems.length > 0 && unitTypes && unitTypes.length > 0 ? currentItems.map(ci => {
		// 	return {
		// 		...ci,
		// 		UnitType: unitTypes.find(u => u.UnitTypeId === ci.UnitTypeID),
		// 		UnitTypeName: unitTypes.find(u => u.UnitTypeID === ci.UnitTypeID).UnitTypeName
		// 	}
		// }) : []

		const { getFormValueByPath } = parserFunctions;

		return (
			<div className='ProductItemList'>
				{currentItems ?
					<ProductList
						selectedOrgId={selectedOrgId}
						selectedLocationId={selectedLocationId}
						// rawProductList={getFormValueByPath(
						// 	`${addedItemsInfoObjPath}addedItemsList`,
						// )}
						rawProductList={currentItems}
						tableCompProps={{
							size: 'small',
							locale: {
								emptyText: (
									<Empty
										image={Empty.PRESENTED_IMAGE_SIMPLE}
										description='No Products Added Yet.'
									/>
								),
							},
							pagination: false,
							onRow: (selectedRowData, rowIndex) => {
								// On Each Row hover invoking handlers to show additional detail in "Items Details" Section.
								return {
									onMouseOver: () => {
										helperFns.onProductItemHoverIn({
											selectedRowData,
											rowIndex,
										});
									},
									onMouseOut: () => {
										helperFns.onProductItemHoverOut({
											selectedRowData,
											rowIndex,
										});
									},
								};
							},
						}}
						customizationProps={{
							customRawColumnListGenerator: (data = {}) => {
								return {
									ItemCode: {
										ColumnName: 'Bar Code',
										ValuePath: 'Barcode',
										IsVisible: true,
										IsFilter: true,
									},
									ProductName: {
										ColumnName: 'Name',
										IsFilter: true,
										IsVisible: true,
										ValuePath: 'ProductName',
									},
									UnitType: {
										ColumnName: 'Unit Type',
										IsFilter: true,
										IsVisible: true,
										ValuePath: 'UnitTypeName',
									},
									Weight: {
										ColumnName: 'Weight',
										IsFilter: true,
										IsVisible: true,
										ValuePath: 'Weight',
									},
									CostPrice: {
										ColumnName: 'Cost Price',
										IsFilter: true,
										IsVisible: true,
										ValuePath: 'PurchasingPrice',
									},
									Qty: {
										ColumnName: 'Qty',
										IsFilter: true,
										IsVisible: true,
										ValuePath: 'OrderQuantity',
									},
									// LineAmount: {
									// 	ColumnName: 'Amount',
									// 	IsFilter: true,
									// 	IsVisible: true,
									// 	ValuePath: '___manuallyAddedData.amount',
									// },
									LineVAT: {
										ColumnName: 'VAT',
										IsFilter: true,
										IsVisible: true,
										ValuePath: 'vatAmount',
									},
									LineTotal: {
										ColumnName: 'Line Total',
										IsFilter: true,
										IsVisible: true,
										ValuePath: 'lineTotal',
									},
								};
							},
							customSpecialRootKeyListGenerator: (data = {}) => {
								return [];
							},
							customColumnSortOrderKeysGenerator: (data = {}) => {
								return [];
							},
							customActionColumnButtonGenerator: (data = {}) => {
								const { selectedRowData, rowIndex } = data;
								const { AvailableStock: availableStock } = selectedRowData;

								const inputRef = useRef(null);
								const [popupVisible, setPopupVisible] = useState(false);

								return (
									<div style={{ display: 'flex', gap: '5px' }}>
										<Popover
											content={() => {
												return (
													<div>
														<div>
															<span>
																<span>QTY : </span>
																<InputNumber
																	min={1}
																	max={availableStock}
																	ref={inputRef}
																	defaultValue={
																		selectedRowData.OrderQuantity
																			? selectedRowData.OrderQuantity
																			: 1
																	}
																	onChange={(e) => { updateItemList(e, 'OrderQuantity', selectedRowData) }}
																/>
															</span>

															<span>
																<span>Unit Type : </span>
																{/* <InputNumber
																min={1}
																max={availableStock}
																ref={inputRef}
																defaultValue={
																	selectedRowData.___manuallyAddedData
																		? selectedRowData.___manuallyAddedData
																			.purchaseQty
																		: 0
																}
																disabled
															/> */}
																<Select defaultValue={selectedRowData.UnitTypeID} style={{ width: '80px' }} onChange={(e) => { updateItemList(e, 'UnitTypeID', selectedRowData) }}>
																	{unitTypes.filter(ut => ut.IsBaseUnit || ut.UnitTypeId === selectedRowData.UnitTypeID || (selectedRowData.availableUnitTypes && selectedRowData.availableUnitTypes.includes(ut.UnitTypeId))).map(up => {
																		return (
																			<Select.Option value={up.UnitTypeId}>{up.UnitTypeName}</Select.Option>
																		)
																	})}

																</Select>

															</span>

															<span>
																<span>Cost Price : </span>
																<InputNumber
																	min={1}
																	max={availableStock}
																	ref={inputRef}
																	defaultValue={
																		selectedRowData.PurchasingPrice
																			? selectedRowData.PurchasingPrice
																			: 1
																	}
																	onChange={(e) => { updateItemList(e, 'PurchasingPrice', selectedRowData) }}
																/>
															</span>

															<Button
																onClick={() => {
																	// const purchaseQty = inputRef.current.value;

																	// if (purchaseQty && purchaseQty >= 0) {
																	// 	helperFns.onProductEditing({
																	// 		rowIndex,
																	// 		selectedRowData: {
																	// 			...selectedRowData,
																	// 			___manuallyAddedData: {
																	// 				purchaseQty,
																	// 			},
																	// 		},
																	// 	});
																	// }
																	this.props.setPOItemList(this.state.itemList);
																	setPopupVisible(false);
																}}>
																UPDATE
															</Button>

															<Button
																onClick={() => {
																	setPopupVisible(false);
																}}>
																CANCEL
															</Button>
														</div>
														{selectedRowData.FurtureDeliveryInfo ?
															<div>
																<hr />

																<p><b>Furture Delivery Info</b></p>
																{selectedRowData.FurtureDeliveryInfo.map(fd => {
																	return (
																		<table>
																			<tr>
																				<td>Order Number </td><td>: {fd.OrderNumber} </td>
																				<td span="2" width="40"></td>
																				<td>Product Code </td><td>: {fd.ProductCode} </td>

																			</tr>
																			<tr>
																				<td>Expected Delivery Date </td><td>: {fd.ExpectedDeliveryDate} </td>
																				<td span="2"></td>
																				<td>Delivery Quantity </td><td>: {fd.DeliveryQuantity} </td>
																			</tr>

																		</table>
																	)
																})}

															</div>
															: null
														}
														<div>

														</div>
													</div>
												);
											}}
											title=''
											trigger='click'
											onVisibleChange={(visibleStateAccordingToTriggerProp) => {
												// NOTE : In "onVisibleChange" prop, what we receive is Popup state, that should be according "trigger" prop. (This doesn't care that we are using our own state to handle popup visibility by passing "visible" prop.)
												// 				Anyway, We are using this to our advantage by using this to close popup when clicked on outside of Popover.

												if (visibleStateAccordingToTriggerProp === false) {
													setPopupVisible(false);
												}
											}}
											visible={popupVisible}
											overlayInnerStyle={{
												border: '1px solid #0c0c0c21',
											}}
											destroyTooltipOnHide>
											<Button
												icon={<EditOutlinedIcon
													title='Edit'
													onClick={() => setPopupVisible(true)}
												/>}
												type="link"
												disabled={statusCode !== 0 || selectedRowData.NotValidProduct}
											/>
										</Popover>

										<Popconfirm
											title="Are you sure to delete this product?"
											onConfirm={() => handleDeletePOItem(selectedRowData)}
											okText="Yes"
											cancelText="No"
											placement="top"
											disabled={statusCode !== 0}
										>
											<Button icon={
												<DeleteOutlinedIcon
													title='Remove'
												/>}
												type="link"
												disabled={statusCode !== 0}
											/>
										</Popconfirm>

									</div>
								);
							},
						}}
						rowClassName={(record) => record.NotValidProduct ?
							'table-row-warn'
							: (record.FurtureDeliveryInfo !== null && record.TotalQuantityinPendingOrders > 0) ? 'table-row-highlight' : 'table-row-default'}
					/>
					: <div><LoaderView className='ProductMasterView'
						isLoading={true} /></div>}

				<FieldErrorMessage
					name={`${addedItemsInfoObjPath}addedItemsList`}
					style={{
						textAlign: 'right',
					}}
				/>
			</div>
		);
	}
}
