import { serverRequest } from './axiosInstances/serverRequest';
import { APIError, errorLogger } from '../errorHandlers';

import apiEndpoints from './apiEndpointPaths';

export async function getCurrencySettingsByOrgIdAndCloudLocationID(
	reqBody = {},
	options = {},
) {
	try {
		const { OrgID, LocationId } = reqBody;
		// const apiReqBody = {
		//     OrgID,
		//     LocationId, // Not Required. Currently this don't matter in finding.
		// };

		const apiReqBody = {
			OrgID,
			CloudLocationID: LocationId,
		};

		const API_URL = apiEndpoints.witmegWebNeurolageServer.getCurrencySettingsByOrgIdAndCloudLocationID;
		const resObj = await serverRequest('generateToken__WitmegWebNeurolageServer', API_URL, 'POST', apiReqBody, options);			

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('encoding/hex: invalid byte'):
				case errMsg.includes('the provided hex string is not a valid ObjectID'):
				case errMsg.includes('encoding/hex: odd length hex string'): {
					customErrMsg = 'Invalid UserID.';
					break;
				}

				case errMsg.includes('Incorrect Path / values'): {
					customErrMsg = 'UserID not provided.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}
