import React, { useEffect, useState } from 'react'
import { getStockAdjustmentItemDetail } from '../../../../../../utilities/apiRequests/witmegWebNeurolageServerRequests'
import { sectionName as dashboardSectionName } from "../../../../../../redux/slices/dashboard/dashboardConstants";
import { useSelector, useDispatch } from "react-redux";
import {
    Typography,
    Stack,
} from "witmeg-ui-system";


function MsaDetail(props) {
    const {
        displayDetail,
        startDate,
        endDate,
        passedProductId,
        selectedMsaObj,
        currentLocation,
        noOfItems
    } = props
    const dashboardState = useSelector((state) => state[dashboardSectionName].dashboard);
    const [itemDetails, setItemDetails] = useState(null)


    //fetching data from api
    const getDetails = (result) => {
        setItemDetails(result)
        console.log('ItemDetails', itemDetails)

    }

    //getting data from api
    useEffect(async () => {
        setItemDetails(null)
        let searchDetail = await getStockAdjustmentItemDetail({
            ProductID: passedProductId,
            OrgID: dashboardState.currentlySelectedOrgId,
            CloudLocID: dashboardState.currentlySelectedLocationId,
            StartDate: startDate,
            EndDate: endDate,
        });

        console.log('hello item detail====>>>>>', searchDetail)
        let data = await getDetails(searchDetail)


    }, [passedProductId])


    return (
        <div style={{ padding: '30px' }}>
            <Stack>
                <Typography style={{ height: '40px', width: '100%', display: 'flex', justifyContent: 'flex-start' }} color="gray_darkest" pattern="bold" className="eco-ml-4">
                    {itemDetails?.ItemName}
                </Typography>
            </Stack>
            <Stack >
                <Typography style={{ height: '40px', width: '100%' }} color="gray_base" >Adjusted Date and Time </Typography>
                <Typography style={{ height: '40px', width: '5%' }} color="gray_base">:</Typography>
                <Typography style={{ height: '40px', width: '100%', display: 'flex', justifyContent: 'flex-start' }} color="gray_darkest" pattern="bold" className="eco-ml-4">
                    {selectedMsaObj?.stockDatenTime}
                </Typography>
            </Stack>
            <Stack >
                <Typography style={{ height: '40px', width: '100%' }} color="gray_base" >Location</Typography>
                <Typography style={{ height: '40px', width: '5%' }} color="gray_base">:</Typography>
                <Typography style={{ height: '40px', width: '100%', display: 'flex', justifyContent: 'flex-start' }} color="gray_darkest" pattern="bold" className="eco-ml-4">
                    {currentLocation}
                </Typography>
            </Stack>
            <Stack >
                <Typography style={{ height: '40px', width: '100%' }} color="gray_base" >Device</Typography>
                <Typography style={{ height: '40px', width: '5%' }} color="gray_base">:</Typography>
                <Typography style={{ height: '40px', width: '100%', display: 'flex', justifyContent: 'flex-start' }} color="gray_darkest" pattern="bold" className="eco-ml-4">
                    {selectedMsaObj?.stockDevice}
                </Typography>
            </Stack>
            <Stack >
                <Typography style={{ height: '40px', width: '100%' }} color="gray_base" >No. of items</Typography>
                <Typography style={{ height: '40px', width: '5%' }} color="gray_base">:</Typography>
                <Typography style={{ height: '40px', width: '100%', display: 'flex', justifyContent: 'flex-start' }} color="gray_darkest" pattern="bold" className="eco-ml-4">
                    {noOfItems}
                </Typography>
            </Stack>
            <Stack >
                <Typography style={{ height: '40px', width: '100%' }} color="gray_base" >Adjusted by</Typography>
                <Typography style={{ height: '40px', width: '5%' }} color="gray_base">:</Typography>
                <Typography style={{ height: '40px', width: '100%', display: 'flex', justifyContent: 'flex-start' }} color="gray_darkest" pattern="bold" className="eco-ml-4">
                    {selectedMsaObj?.stockAdjustedBy}
                </Typography>
            </Stack>


        </div>

    )
}

export default MsaDetail