import React, { useEffect, useState } from 'react';
import { ImageSlider } from 'witmeg-ui-system';



export default function ProductOverviewImageSlider(props) {
    const [imageArr, setImageArr] = useState(null);
    useEffect(()=>{
        let PrimaryImgList=[]
        let SpecialImgList=[]
        let UnitChartImgList=[]
        if(props.data.PrimaryImages && Object.keys(props.data.PrimaryImages).length > 0) {
            PrimaryImgList = Object.keys(props.data.PrimaryImages).map((primg, i) => {
                return {
                    imageURL: props.data.PrimaryImages[primg],
                    key: `primary_img_${i}`
                }
            });
        }
        if(props.data.SpecialImages && Object.keys(props.data.SpecialImages).length > 0) {
            SpecialImgList = Object.keys(props.data.SpecialImages).map((spimg, i) => {
                return {
                    imageURL: props.data.SpecialImages[spimg] && props.data.SpecialImages[spimg][0] &&props.data.SpecialImages[spimg][0].ImgURL+'?V='+Math.random(),
                    key: `special_img_${i}`
                }
            });
        }
        if(props.data.UnitChartImages && Object.keys(props.data.UnitChartImages).length > 0) {
            UnitChartImgList = Object.keys(props.data.UnitChartImages).map((img, i) => {
                return {
                    imageURL: props.data.UnitChartImages[img],
                    key: `unit_chart_img_${i}`
                }
            });
        }
        setImageArr([...PrimaryImgList, ...SpecialImgList, ...UnitChartImgList])
        
      
    }, [props.data])
    
  return (
    <div>
        { imageArr&&imageArr.length>=0?
        <div>
            <ImageSlider sliderImages={imageArr} />
        </div>
        :null
        }
    </div>
  )
}

