import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sectionName as dashboardSectionName } from '../../../../../redux/slices/dashboard/dashboardConstants';
import {
	Stack,
	StackItem,
	InputGroup,
	Button,
	Space,
	Table,
	Popover,
	ActionList,
	Icon,
	RightPanel,
	RightPanelHeader,
	Accordion,
	Form,
	Message,
	ConfirmationPopup,
	SearchableMenu,
	Label,
	DatePicker,
	Alert,
	InlineMessage,
} from 'witmeg-ui-system';

import CategoryOverview from './CategoryOverview';
import SubCategoryInformationForm from './Forms/SubCategoryInformationForm';
import WarningMessagesForm from './Forms/WarningMessagesForm';
import CategoryInformationForm from './Forms/CategoryInformationForm';
import {
	addEposProductCategory,
	getEposProductCategoriesByOrgId,
	updateEposProductCategory,
	getEposProductCategoryByName,
} from '../../../../../utilities/apiRequests/witmegWebNeurolageServerRequests';

import './index.css';
import { set } from 'lodash';

const CategoryPage = (props) => {
	const { RangePicker } = DatePicker;

	const { selectedLocation } = props;
	const [categoryInformationForm] = Form.useForm();
	const [subCategoryInformationForm] = Form.useForm();
	const [ecommerceConfigurationsForm] = Form.useForm();
	const [customAttributesForm] = Form.useForm();
	const categoryInfoPanel = useRef();
	const subCategoryInfoPanel = useRef();
	const dashboardState = useSelector(
		(state) => state[dashboardSectionName].dashboard
	);
	const [selectedOption, setSelectedOption] = useState([]);
	const [searchKey, setSearchKey] = useState('');
	const [displayData, setDisplayData] = useState(null);
	const [loadingProducts, setLoadingCategories] = useState(true);
	const [
		settingsMenuPopoverTriggerEvent,
		setSettingsMenuPopoverTriggerEvent,
	] = useState(null);
	const [
		addMenuPopoverTriggerEvent,
		setAddMenuPopoverTriggerEvent,
	] = useState(false);
	const [enableCategoryAddMode, setEnableCategoryAddMode] =
		useState(false);
	const [showAddOrEditPanel, setShowAddOrEditPanel] =
		useState(false);
	const [enableCategoryEditMode, setEnableCategoryEditMode] =
		useState(false);
	const [showSubCategoryInfoPanel, setShowPriceInfoPanel] =
		useState(false);
	const [missingCategoryFieldNames, setMissingCategoryFieldNames] =
		useState([]);
	const [
		dotsMenuPopoverTriggerEvent,
		setDotsMenuPopoverTriggerEvent,
	] = useState(null);
	const [showDotsMenu, setShowDotsMenu] = useState(false);
	const [showSettingsMenu, setShowSettingsMenu] = useState(false);
	const [showAddMenu, setShowAddMenu] = useState(false);
	const [categoryInformationValues, setCategoryInformationValues] =
		useState({});
	const [subCategoryInfo, setSubCategoryInfo] = useState(null);
	const [warningMessageInfo, setWarningMessageInfo] =
		useState(null);
	const [categoryInfo, setCategoryInfo] = useState(null);
	const [brands, setBrands] = useState([]);
	const [warningMessagesObj, setWarningMessagesObj] = useState({});
	const [apiValidationError, setApiValidationError] =
		useState(null);
	const [selectedCategoryObj, setSelectedCategoryObj] =
		useState(null);
	const [selectedCategoryId, setSelectedCategoryId] =
		useState(null);
	const [showActionsMenu, setShowActionsMenu] = useState(false);
	const [categoryIdToDelete, setCategoryIdToDelete] =
		useState(null);
	const [showCategoryActionMenu, setShowCategoryActionMenu] =
		useState(false);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [
		actionMenuPopoverTriggerEvent,
		setActionMenuPopoverTriggerEvent,
	] = useState(null);
	const [
		hasSubCategoryValidationIssues,
		setHasSubCategoryValidationIssues,
	] = useState(false);
	const [
		categoryInfoValidationErrors,
		setCategoryInfoValidationErrors,
	] = useState({
		productName: false,
		mainCategory: false,
		brand: false,
		itemCode: false,
		barcode: false,
	});
	const [rowData, setRowData] = useState(null);
	const [
		missingCustomAttributesFields,
		setMissingCustomAttributesFields,
	] = useState([]);
	const [
		missingCustomAttributesFieldNames,
		setMissingCustomAttributesFieldNames,
	] = useState([]);
	const [
		subCategoryInformationValues,
		setSubCategoryInformationValues,
	] = useState([]);
	const options = [
		{ value: 'category', label: 'Category', selected: true },
	];
	const [priceInfoValidationErrors, setPriceInfoValidationErrors] =
		useState({
			purchasePrice: false,
			sellingPrice: false,
		});
	const userState = useSelector((state) => state.user.userDetails);
	const [customAttributesInfo, setCustomAttributesInfo] =
		useState(null);
	const [showCategoryOverviewPanel, setShowCategoryOverviewPanel] =
		useState(false);
	const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] =
		useState(false);
	const [
		showDeleteConfirmPopupIndex,
		setShowDeleteConfirmPopupIndex,
	] = useState(false);

	const moment = require('moment');

	const [advancedSearchComponents, setAdvancedSearchComponents] =
		useState([
			{
				key: 1,
				title: 'Created Date',
				type: 'date-range-select',
				isVisible: false,
			},
			{
				key: 2,
				title: 'VAT Rate',
				type: 'range-select',
				units: ['%'],
				isVisible: false,
			},
		]);
	const [columnData, setColumnData] = useState([
		{
			title: 'Category Name',
			dataIndex: 'categoryName',
			key: 'categoryName',
			render: (text) => <a>{text}</a>,
		},
		{
			title: 'Description',
			dataIndex: 'description',
			key: 'description',
		},
		{
			title: 'VAT %',
			dataIndex: 'vat',
			key: 'vat',
		},
		{
			title: 'Discount',
			dataIndex: 'discount',
			key: 'discount',
		},
		{
			title: 'Show Under Shop Sale',
			dataIndex: 'showUnderShopSale',
			key: 'showUnderShopSale',
		},
		{
			title: 'Point Based Discount',
			dataIndex: 'pointBasedDiscount',
			key: 'pointBasedDiscount',
		},
		{
			title: 'Created Date',
			dataIndex: 'createdDate',
			key: 'createdDate',
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (record) => (
				<Space size="middle">
					<Button
						category="icon"
						variant="link"
						children="eye"
						size="small"
						iconSize={16}
						onClick={() => {
							toggleCategoryOverviewPanel(record);
						}}
					/>
					<Button
						category="icon"
						variant="link"
						children="ellipsis-vertical"
						size="small"
						iconSize={16}
						onClick={(e) => {
							handleShowCategoryActions(e, record);
							toggleActiom(true);
						}}
						onMouseLeave={() => {
							toggleActiom(false);
						}}
					/>
				</Space>
			),
		},
	]);

	useEffect(() => {
		categoryInformationForm.resetFields();
	}, [categoryInformationValues]);

	useEffect(() => {
		subCategoryInformationForm.resetFields();
	}, [subCategoryInformationValues]);

	useEffect(async () => {
		let isMounted = false;

		if (rowData && categoryIdToDelete) {
			if (!isMounted) {
				let category = rowData.find(
					(rd) => rd.ID === categoryIdToDelete
				);
				// console.log(
				// 	'category ',
				// 	category,
				// 	'and',
				// 	categoryIdToDelete
				// );
				category.ProductCategory.IsproductCategoryDeleted = true;
				// console.log('category updated', category);
				//setSelectedCategoryObj(category);
				let deleteResult = await updateEposProductCategory(
					category
				);
				// console.log('deleteResult', deleteResult);
				if (
					deleteResult ===
					'Product is updated successfully.'
				) {
					console.log('category========>', category);
					Message.success(
						`Category (${category.ProductCategory.Category}) is deleted successfully.`
					);
					reloadData();
				}
			}
		}

		return () => (isMounted = true);
	}, [rowData, categoryIdToDelete]);

	useEffect(async () => {
		let isMounted = false;

		if (categoryInfo && subCategoryInfo && warningMessageInfo) {
			if (enableCategoryEditMode) {
				if (!isMounted) {
					const apiReqBody = { ...selectedCategoryObj };

					apiReqBody.ProductCategory = {
						...apiReqBody.ProductCategory,
						Category: categoryInfo.categoryName,
						ProductDescription: categoryInfo.description,
						VatRate: categoryInfo.vat / 100,
						WarningMessage:
							warningMessageInfo.warningMessage,
						SoundFile: warningMessageInfo.soundFile,
						IsPercentageDiscount: categoryInfo.discount,
						IsPointsBasedDiscount:
							categoryInfo.pointBasedDiscount,
						IsPointsBasedDiscount:
							categoryInfo.pointBasedDiscount,
						IsTotalShowInShopSales:
							categoryInfo.showUnderShopSale,
						ModifiedDate: new Date().toISOString(),
						ModifiedBy: userState.UserId,
					};
					// console.log(
					// 	'apiReqBodyNew one----------->',
					// 	apiReqBody
					// );
					const subCatInfo = subCategoryInfo.map(
						(subCat) => {
							const existingSubCat =
								apiReqBody.ProductSubCategory.find(
									(existingSubCat) => {
										return (existingSubCat.PrdoctSubcategoryID =
											subCat.subcategoryId);
									}
								);
							return {
								...existingSubCat,
								SubcategoryDescription:
									subCat.description,
								Name: subCat.name,
								ModifiedDate:
									new Date().toISOString(),
								ModifiedBy: userState.UserId,
								CloudModifiedBy: userState.UserId,
							};
						}
					);

					apiReqBody.ProductSubCategory = subCatInfo;

					console.log(
						'Updating Category--------->',
						apiReqBody
					);
					let res = await updateEposProductCategory({
						ID: selectedCategoryId,
						categoryID: 0,
						...apiReqBody,
					});
					if (res) {
						hideAddOrEditCategoryPanel();
						Message.success(
							`Category (${categoryInfo.categoryName}) is updated successfully.`
						);
						setCategoryInfo(null);
						setSubCategoryInfo(null);
						ecommerceConfigurationsForm.resetFields();
						reloadData();
					} else {
						Message.error(
							`Category is not updated. Please try again`
						);
					}
				}
			} else {
				const subCatInfo = subCategoryInfo.map((subCat) => {
					return {
						OrgID: dashboardState.currentlySelectedOrgId,
						PrdoctSubcategoryID: 0,
						CategoryID: 0,
						SubcategoryDescription: subCat.description,
						Name: subCat.name,
						CreatedDate: new Date().toISOString(),
						CreatedBy: userState.UserId,
						ModifiedDate: new Date().toISOString(),
						ModifiedBy: userState.UserId,
						IsSubCategoryDeleted: false,
						CloudCreatedBy: userState.UserId,
						CloudModifiedBy: userState.UserId,
					};
				});

				const apiReqBody = {
					ProductCategory: {
						ProductCategoryID: 0,
						Category: categoryInfo.categoryName.trim(),
						ProductDescription: categoryInfo.description,
						CreatedDate: new Date().toISOString(),
						CreatedBy: userState.UserId,
						ModifiedDate: new Date().toISOString(),
						ModifiedBy: userState.UserId,
						VatRate: categoryInfo.vat / 100,
						WarningMessage:
							warningMessageInfo.warningMessage,
						SoundFile: warningMessageInfo.soundFile,
						IsAlwaysVisibleWarning: false,
						IsPercentageDiscount: categoryInfo.discount,
						IsPointsBasedDiscount:
							categoryInfo.pointBasedDiscount,
						IsTotalShowInShopSales:
							categoryInfo.showUnderShopSale,
						IsproductCategoryDeleted: false,
						OrgID: dashboardState.currentlySelectedOrgId,
						CloudCreatedBy: userState.UserId,
						CloudModifiedBy: userState.UserId,
					},
					ProductSubCategory: subCatInfo,
				};
				let AddRes = await addEposProductCategory(apiReqBody);

				if (AddRes) {
					if (AddRes.hasError) {
						setApiValidationError(AddRes);
						return;
					}
					hideAddOrEditCategoryPanel();
					Message.success(
						`Category (${categoryInfo.categoryName}) is added successfully.`
					);
					ecommerceConfigurationsForm.resetFields();
					reloadData();
				} else {
					Message.error(
						`Category not added. please try again`
					);
				}
			}
		}

		return () => (isMounted = true);
	}, [categoryInfo, subCategoryInfo, warningMessageInfo]);

	useEffect(async () => {
		let isMounted = false;

		if (rowData && selectedCategoryId) {
			if (!isMounted) {
				let category =
					(await rowData) &&
					rowData.find(
						(rd) => rd.ID === selectedCategoryId
					);
				setSelectedCategoryObj(category);
			}
		} else {
			setSelectedCategoryObj(null);
		}
		return () => (isMounted = true);
	}, [rowData, selectedCategoryId]);

	useEffect(() => {
		if (enableCategoryAddMode) {
			setCategoryInformationValues({
				categoryName: '',
				description: '',
				vat: 0,
				discount: false,
				showUnderShopSale: false,
				pointBasedDiscount: false,
			});

			setWarningMessagesObj({
				warningMessage: '',
				age: 18,
				soundFile: '',
			});
		} else {
			setCategoryInformationValues({});
		}

		setShowAddOrEditPanel(enableCategoryAddMode);
	}, [enableCategoryAddMode]);

	useEffect(() => {
		if (enableCategoryEditMode) {
			setCategoryInfoValidationErrors({
				productName: false,
				mainCategory: false,
				brand: false,
				itemCode: false,
				barcode: false,
			});
		}
	}, [enableCategoryEditMode]);

	useEffect(async () => {
		let isMounted = false;

		if (enableCategoryEditMode && selectedCategoryObj) {
			if (!isMounted) {
				const categoryObject =
					selectedCategoryObj.ProductCategory;
				setCategoryInformationValues({
					categoryName: categoryObject.Category,
					description: categoryObject.ProductDescription,
					vat: categoryObject.VatRate * 100,
					discount: categoryObject.IsPercentageDiscount,
					showUnderShopSale:
						categoryObject.IsTotalShowInShopSales,
					pointBasedDiscount:
						categoryObject.IsPointsBasedDiscount,
				});

				const subCatArr =
					selectedCategoryObj.ProductSubCategory.map(
						(subCat, index) => {
							return {
								key: index,
								subcategoryId:
									subCat.PrdoctSubcategoryID,
								name: subCat.Name,
								description:
									subCat.SubcategoryDescription,
							};
						}
					);

				setSubCategoryInformationValues(subCatArr);

				setWarningMessagesObj({
					warningMessage: categoryObject.WarningMessage,
					age: 18,
					soundFile: categoryObject.SoundFile,
				});

				setShowAddOrEditPanel(true);
			}
		} else {
			setCategoryInformationValues({});
			setSubCategoryInformationValues([]);
			setWarningMessagesObj({});
			setShowAddOrEditPanel(false);
		}
		return () => (isMounted = true);
	}, [enableCategoryEditMode, selectedCategoryObj]);

	useEffect(async () => {
		let isMounted = false;

		if (!isMounted) {
			if (
				selectedLocation &&
				selectedLocation.organizationDetails &&
				selectedLocation.organizationDetails.OrganizationID
			) {
				setLoadingCategories(true);
				setDisplayData(null);
				setRowData(null);
				let productCategoryList =
					await getEposProductCategoriesByOrgId({
						OrgID: selectedLocation.organizationDetails
							.OrganizationID,
					});
				// setProductCategories(productCategoryList);

				setRowData(productCategoryList);
				let data = await getTableDataFromResult(
					productCategoryList
				);

				let categoryNameFilter = [];
				data.forEach((item, index) => {
					pushToArrayIfNotExist(categoryNameFilter, {
						text: item.categoryName,
						value: item.categoryName,
					});
				});

				let descriptionFilter = [];
				data.forEach((item, index) => {
					pushToArrayIfNotExist(descriptionFilter, {
						text: item.description,
						value: item.description,
					});
				});

				let vat69Filter = [];
				data.forEach((item, index) => {
					pushToArrayIfNotExist(vat69Filter, {
						text: item.vat,
						value: item.vat,
					});
				});

				let discountFilter = [];
				data.forEach((item, index) => {
					pushToArrayIfNotExist(discountFilter, {
						text: item.discount,
						value: item.discount,
					});
				});

				let showUnderShopSaleFilter = [];
				data.forEach((item, index) => {
					pushToArrayIfNotExist(showUnderShopSaleFilter, {
						text: item.showUnderShopSale,
						value: item.showUnderShopSale,
					});
				});

				let pointBasedDiscountFilter = [];
				data.forEach((item, index) => {
					pushToArrayIfNotExist(pointBasedDiscountFilter, {
						text: item.pointBasedDiscount,
						value: item.pointBasedDiscount,
					});
				});

				let createdDateFilter = [];
				data.forEach((item, index) => {
					pushToArrayIfNotExist(createdDateFilter, {
						text: item.createdDate,
						value: item.createdDate,
					});
				});

				let columnDataWithFilters = columnData.map((data) => {
					if (data.dataIndex === 'categoryName') {
						return {
							...data,
							filters: categoryNameFilter,
							onFilter: (value, record) =>
								record.categoryName.startsWith(value),
							filterSearch: true,
						};
					} else if (data.dataIndex === 'description') {
						return {
							...data,
							filters: descriptionFilter,
							onFilter: (value, record) =>
								record.description.startsWith(value),
							filterSearch: true,
						};
					} else if (
						data.dataIndex === 'pointBasedDiscount'
					) {
						return {
							...data,
							filters: pointBasedDiscountFilter,
							onFilter: (value, record) =>
								record.pointBasedDiscount == value,
							filterSearch: true,
						};
					} else if (data.dataIndex === 'vat') {
						return {
							...data,
							filters: vat69Filter,
							onFilter: (value, record) =>
								record.vat == value,
							filterSearch: true,
							sorter: (a, b) => a.vat - b.vat,
						};
					} else if (data.dataIndex === 'discount') {
						return {
							...data,
							filters: discountFilter,
							onFilter: (value, record) =>
								record.discount == value,
							filterSearch: true,
						};
					} else if (
						data.dataIndex === 'showUnderShopSale'
					) {
						return {
							...data,
							filters: showUnderShopSaleFilter,
							onFilter: (value, record) =>
								record.showUnderShopSale == value,
							filterSearch: true,
						};
					} else if (data.dataIndex === 'createdDate') {
						return {
							...data,
							filters: createdDateFilter,
							onFilter: (value, record) =>
								record.createdDate == value,
							sorter: (a, b) =>
								Date.parse(a.createdDate) -
								Date.parse(b.createdDate),
						};
					} else {
						return data;
					}
				});

				setColumnData(columnDataWithFilters);
				setDisplayData(data);
				setLoadingCategories(false);
			}
		}
		return () => (isMounted = true);
	}, [selectedLocation]);

	useEffect(() => {
		categoryInformationForm.resetFields();
	}, [categoryInformationValues]);

	useEffect(() => {
		subCategoryInformationForm.resetFields();
	}, [subCategoryInformationValues]);

	const onFinishCustomAttributes = (values) => {
		console.log('onFinishCustomAttributes Success:', values);
		setMissingCustomAttributesFields([]);
		setCustomAttributesInfo(values);
	};

	const sortResultsFromCreatedDate = (result) => {
		return result.sort((result1, result2) => {
			return (
				new Date(result2.ProductCategory.CreatedDate) -
				new Date(result1.ProductCategory.CreatedDate)
			);
		});
	};

	const pushToArrayIfNotExist = (array, elementObject) => {
		let existingObject = array.find((obj) => {
			return obj.value === elementObject.value;
		});

		if (!existingObject) {
			array.push(elementObject);
		}
	};

	const onFinishCustomAttributesFailed = (errorInfo) => {
		console.log('onFinishCustomAttributesFailed:', errorInfo);
		const missingCustomAttributesFields =
			errorInfo.errorFields.map(
				(errorField) => errorField.name[0]
			);
		console.log(
			'missingCustomAttributesFields:',
			missingCustomAttributesFields
		);
		setMissingCustomAttributesFields(
			missingCustomAttributesFields
		);
		setCustomAttributesInfo(null);
	};

	const handleSetSearchKey = async (value) => {
		setSearchKey(value);
		setLoadingCategories(true);
	};

	const toggleAddMenu = (showAddMenu) => {
		setShowAddMenu(showAddMenu);
	};

	const toggleSettingsMenu = (showSettingsMenu) => {
		setShowSettingsMenu(showSettingsMenu);
	};

	const toggleDotsMenu = (showDotsMenu) => {
		setShowDotsMenu(showDotsMenu);
	};

	const toggleCategoryOverviewPanel = (record) => {
		setSelectedCategoryId(record.id);
		setShowCategoryOverviewPanel(true);
	};

	const categoryViewPanel = () => {
		setShowAddOrEditPanel(true);
		setEnableCategoryAddMode(true);
		toggleAddMenu(false);
	};

	const reloadData = async () => {
		setLoadingCategories(true);
		let searchResult = await getEposProductCategoriesByOrgId({
			OrgID: dashboardState.currentlySelectedOrgId,
		});
		let data = await getTableDataFromResult(searchResult);
		setDisplayData(data);
		setRowData(searchResult);
		setLoadingCategories(false);
	};

	const handleSubmitAddCategory = async () => {
		validateCategoryInfo();
		setSubCategoryInfo(subCategoryInformationValues);
		setWarningMessageInfo(warningMessagesObj);
		setApiValidationError(null);
	};

	const handleShowCategoryActions = (event, selectedCategory) => {
		console.log('record--------==========', event);
		setActionMenuPopoverTriggerEvent(event);
		setSelectedCategory(selectedCategory.id);
		setShowCategoryActionMenu(true);
		toggleActiom(true);
	};

	const toggleActiomMenu = (showCategoryActionMenu) => {
		setShowCategoryActionMenu(showCategoryActionMenu);
	};

	const handleSubmitUpdateProduct = async () => {
		validateCategoryInfo();
		setSubCategoryInfo(subCategoryInformationValues);
		setWarningMessageInfo(warningMessagesObj);
		setApiValidationError(null);
	};

	const handleSetSubCategories = (subCategoriesUpdated) => {
		setSubCategoryInformationValues(subCategoriesUpdated);
	};

	const hideAddOrEditCategoryPanel = () => {
		setSelectedCategoryId(null);
		setEnableCategoryAddMode(false);
		setEnableCategoryEditMode(false);
		setShowAddOrEditPanel(false);
	};

	const addCategoryPanelHeader = (
		<RightPanelHeader
			title={
				enableCategoryEditMode
					? 'Update Category'
					: 'Add Category'
			}
			rightContent={
				enableCategoryEditMode ? (
					<Button
						onClick={() => handleSubmitUpdateProduct()}
						variant="primary"
					>
						Update
					</Button>
				) : (
					<Button
						onClick={() => handleSubmitAddCategory()}
						variant="primary"
					>
						Save
					</Button>
				)
			}
		/>
	);

	const viewEditCategoryOverviewPanel = (selectedCategoryId) => {
		setShowCategoryOverviewPanel(false);
		setSelectedCategoryId(selectedCategoryId);
		setShowAddOrEditPanel(true);
		setEnableCategoryEditMode(true);
		toggleActiomMenu(false);
	};

	const onFinishBasic = (values) => {
		console.log('onFinishBasic Success:', values);
		setMissingBasicFields([]);
		setCategoryInfo(values);
	};

	const onFinishBasicFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
		const missingBasicFields = errorInfo.errorFields.map(
			(errorField) => errorField.name[0]
		);
		console.log('missingBasicFields:', missingBasicFields);
		setMissingBasicFields(missingBasicFields);
		setCategoryInfo(null);
	};

	const handleChangeCategoryInfoInputs = (values, updatedField) => {
		setCategoryInformationValues(values);
		setCategoryInfoValidationErrors({
			...categoryInfoValidationErrors,
			[updatedField]: values[updatedField] === '',
		});
	};

	const handleChangeWarningMessageInputs = (
		values,
		updatedField
	) => {
		setWarningMessagesObj(values);
	};

	const validateCategoryInfo = () => {
		let categoryInfoValidations = {
			categoryName:
				categoryInformationValues.categoryName === '',
			vat: categoryInformationValues.vat === '',
		};
		setCategoryInfoValidationErrors(categoryInfoValidations);
		setMissingCategoryFieldNames(
			[
				categoryInformationValues.categoryName === ''
					? 'Category Name'
					: null,
			].filter((x) => x)
		);
		if (categoryInformationValues.categoryName !== '') {
			setCategoryInfo(categoryInformationValues);
		}
	};

	const categoryOverviewHeader = (
		<RightPanelHeader
			title="Category Overview"
			rightContent={
				<Space>
					<Button
						onClick={() =>
							setShowDeleteConfirmPopup(true)
						}
					>
						Delete
					</Button>
					<Button
						variant="primary"
						onClick={() =>
							viewEditCategoryOverviewPanel(
								selectedCategoryId
							)
						}
					>
						Edit
					</Button>
				</Space>
			}
		/>
	);

	const onCancelDeleteConfirmPopup = () => {
		setShowCategoryOverviewPanel(false);
		setShowDeleteConfirmPopup(false);
		setShowDeleteConfirmPopupIndex(false);
	};

	const hideOverviewCategoryPanel = () => {
		setShowCategoryOverviewPanel(false);
	};

	let handleDeleteSingleCategory = async (selectedCategoryId) => {
		//console.log("record", record);
		let categoryId = selectedCategoryId;
		console.log('passed one to  ', categoryId);
		setCategoryIdToDelete(categoryId);
		// console.log("passed category", categoryIdToDelete);
	};

	const confirmDeleteCategory = (selectedCategoryId) => {
		handleDeleteSingleCategory(selectedCategoryId);
	};

	const confirmDeleteOverviewedProduct = (selectedCategoryId) => {
		let categoryObj =
			rowData &&
			rowData.find((rd) => rd.ID === selectedCategoryId);
		categoryObj.ProductCategory.IsproductCategoryDeleted = true;
		console.log('categoryObj', categoryObj);
		updateEposProductCategory(categoryObj).then((res) => {
			if (res) {
				setShowDeleteConfirmPopup(false);
				setShowCategoryOverviewPanel(false);
				setShowDeleteConfirmPopupIndex(false);

				Message.success(
					`Category (${categoryObj.ProductCategory.Category}) is deleted successfully.`
				);
				// setCategoryInfo(null);
				// setSubCategoryInfo(null);
				// ecommerceConfigurationsForm.resetFields();
				reloadData();
			}
		});
	};

	const handleClearAll = () => {
		setSelectedOption('');
		handleSetSearchKey('');

		const selectedComponents = advancedSearchComponents.map(
			(searchComponent) => {
				return {
					...searchComponent,
					isVisible: false,
				};
			}
		);
		setAdvancedSearchComponents(selectedComponents);
		reloadData();
	};

	const handleSearchCategory = async (value) => {
		if (value !== '') {
			setLoadingCategories(true);

			let categoryObject = await getEposProductCategoryByName({
				OrgID: dashboardState.currentlySelectedOrgId,
				categoryName: value,
			});
			if (categoryObject !== 'Error') {
				let category = categoryObject.ProductCategory;
				setRowData([categoryObject]);

				let resultArr = [
					{
						key: 0,
						id: categoryObject.ID,
						categoryName: category.Category,
						description: category.ProductDescription,
						vat: category.VatRate,
						discount: category.IsPercentageDiscount
							? 'Applicable'
							: 'Not Applicable',
						showUnderShopSale:
							category.IsTotalShowInShopSales
								? 'Applicable'
								: 'Not Applicable',
						pointBasedDiscount:
							category.IsPointsBasedDiscount
								? 'Applicable'
								: 'Not Applicable',
						createdDate: category.CreatedDate
							? category.CreatedDate.split('T')[0] &&
							  moment(category.CreatedDate).format('L')
							: null,
					},
				];

				setDisplayData(resultArr);
			} else {
				setDisplayData([]);
			}
			setLoadingCategories(false);
		} else {
			reloadData();
		}
	};

	const getTableDataFromResult = async (result) => {
		if (result.length > 2) {
			result = sortResultsFromCreatedDate(result);
		}

		return await result.map((categoryObject, index) => {
			const category = categoryObject.ProductCategory;
			return {
				key: index,
				id: categoryObject.ID,
				categoryName: category.Category,
				description: category.ProductDescription,
				vat: category.VatRate * 100,
				discount: category.IsPercentageDiscount
					? 'Applicable'
					: 'Not Applicable',
				showUnderShopSale: category.IsTotalShowInShopSales
					? 'Applicable'
					: 'Not Applicable',
				pointBasedDiscount: category.IsPointsBasedDiscount
					? 'Applicable'
					: 'Not Applicable',
				createdDate: category.CreatedDate
					? category.CreatedDate.split('T')[0] &&
					  moment(category.CreatedDate).format('L')
					: null,
			};
		});
	};

	const missingCategoryInfoMessage =
		missingCategoryFieldNames.length > 0
			? `${missingCategoryFieldNames.join(
					', '
			  )} field(s) are missing in Category Information.`
			: '';
	return (
		<div
			className="category-page-content"
			style={{ padding: '20px' }}
		>
			<div
				className="category-page-header"
				style={{ paddingBottom: '20px' }}
			>
				<Stack
					horizontalAlign="space_between"
					verticalAlign="v_top"
				>
					<Stack style={{ flexWrap: 'wrap' }}>
						<StackItem
							style={{ width: '280px', margin: 10 }}
						>
							<InputGroup
								leftComponent="select"
								leftOptions={options}
								leftValue={selectedOption}
								onChangeLeftValue={(e) =>
									setSelectedOption(e)
								}
								leftWidth="50%"
								rightComponent={'text'}
								rightValue={searchKey}
								onChangeRightValue={(value) => {
									handleSetSearchKey(value);
									handleSearchCategory(value);
								}}
								rightWidth="50%"
								style={{ width: '160px' }}
							/>
						</StackItem>
						{advancedSearchComponents.map(
							(searchComponent) => {
								if (searchComponent.isVisible) {
									if (
										searchComponent.type ===
										'range-select'
									) {
										return (
											<StackItem
												style={{ margin: 10 }}
											>
												<SearchableMenu
													groupedItems={[
														{
															selectedOption:
																searchComponent.selectedOption,
															items: [
																{
																	text: 'Less than',
																	inputs: [
																		<InputGroup
																			leftComponent="select"
																			leftOptions={searchComponent.units.map(
																				(
																					unit
																				) => {
																					return {
																						label: unit,
																						value: unit,
																					};
																				}
																			)}
																			leftValue={{
																				label: searchComponent
																					.units[0],
																				value: searchComponent
																					.units[0],
																			}}
																			// onChangeLeftValue={setSelectedOption}
																			leftWidth="30%"
																			rightComponent="number"
																			rightWidth="70%"
																			style={{
																				width: '160px',
																			}}
																		/>,
																	],
																},
																{
																	text: 'More than',
																	inputs: [
																		<InputGroup
																			leftComponent="select"
																			leftOptions={searchComponent.units.map(
																				(
																					unit
																				) => {
																					return {
																						label: unit,
																						value: unit,
																					};
																				}
																			)}
																			leftValue={{
																				label: searchComponent
																					.units[0],
																				value: searchComponent
																					.units[0],
																			}}
																			// onChangeLeftValue={setSelectedOption}
																			leftWidth="30%"
																			rightComponent="number"
																			rightWidth="70%"
																			style={{
																				width: '160px',
																			}}
																		/>,
																	],
																},
																{
																	text: 'Between',
																	inputs: [
																		<InputGroup
																			leftComponent="select"
																			leftOptions={searchComponent.units.map(
																				(
																					unit
																				) => {
																					return {
																						label: unit,
																						value: unit,
																					};
																				}
																			)}
																			leftValue={{
																				label: searchComponent
																					.units[0],
																				value: searchComponent
																					.units[0],
																			}}
																			// onChangeLeftValue={setSelectedOption}
																			leftWidth="30%"
																			rightComponent="number"
																			rightWidth="70%"
																			style={{
																				width: '160px',
																			}}
																		/>,
																		<br />,
																		<Label>
																			And
																		</Label>,
																		<br />,
																		<InputGroup
																			leftComponent="select"
																			leftOptions={searchComponent.units.map(
																				(
																					unit
																				) => {
																					return {
																						label: unit,
																						value: unit,
																					};
																				}
																			)}
																			leftValue={{
																				label: searchComponent
																					.units[0],
																				value: searchComponent
																					.units[0],
																			}}
																			// onChangeLeftValue={setSelectedOption}
																			leftWidth="30%"
																			rightComponent="number"
																			rightWidth="70%"
																			style={{
																				width: '160px',
																			}}
																		/>,
																	],
																},
															],
														},
													]}
													placement="topLeft"
													action={(
														text,
														values
													) =>
														handleApplySearchFilters(
															searchComponent.title,
															text,
															values
														)
													}
												>
													<Button
														category="text-icon"
														variant="default"
														text={`Select ${searchComponent.title}`}
														icon="chevron-down"
														size="medium"
														iconSize={20}
														customStyles={{
															borderColor:
																'#ccc',
														}}
													/>
												</SearchableMenu>
											</StackItem>
										);
									} else if (
										searchComponent.type ===
										'date-range-select'
									) {
										return (
											<StackItem
												style={{ margin: 10 }}
											>
												<RangePicker
													onCalendarChange={(
														momentDates,
														dateStrings
													) =>
														handleDateRangeSelect(
															dateStrings
														)
													}
												/>
											</StackItem>
										);
									}
								}
							}
						)}
						<StackItem style={{ marginRight: 20 }}>
							<SearchableMenu
								groupedItems={[
									{
										items: advancedSearchComponents.map(
											(searchComponent) => ({
												checkBox: true,
												text: searchComponent.title,
												checked:
													searchComponent.isVisible,
											})
										),
									},
								]}
								placement="topLeft"
								isSearchable={true}
								action={(selectedItems) => {
									const selectedComponents =
										advancedSearchComponents.map(
											(searchComponent) => {
												return {
													...searchComponent,
													isVisible:
														selectedItems.includes(
															searchComponent.title
														),
												};
											}
										);
									setAdvancedSearchComponents(
										selectedComponents
									);
								}}
							>
								<Button
									category="icon"
									variant="default"
									children="filter"
									size="medium"
									iconSize={20}
									className="eco-button--special"
									color="white"
								/>
							</SearchableMenu>
						</StackItem>
						<StackItem
							className="eco-divider-l"
							style={{ margin: 10 }}
						>
							<Button
								category="text"
								children="Clear All"
								variant="subtle"
								onClick={handleClearAll}
							/>
						</StackItem>
					</Stack>
					<Stack
						horizontalAlign="space_between"
						style={{ alignItems: 'flex-start' }}
					>
						<StackItem
							className="eco-mr-12"
							style={{ margin: 10 }}
						>
							<Button
								category="icon"
								variant="primary"
								children="plus"
								size="small"
								iconSize={20}
								color="white"
								onClick={(e) => {
									setAddMenuPopoverTriggerEvent(e);
									toggleAddMenu(true);
								}}
								onMouseLeave={() => {
									toggleAddMenu(false);
								}}
							/>
						</StackItem>

						<Popover
							position="bottom-right"
							popoverTriggerEvent={
								addMenuPopoverTriggerEvent
							}
							active={showAddMenu}
							showHidePopover={toggleAddMenu}
							type="basic"
							customStyles={{ marginTop: 8 }}
						>
							<ActionList
								width={160}
								items={
									process.env.NODE_ENV !==
									'production'
										? [
												{
													key: 1,
													prefix: (
														<Icon
															name="tag"
															size={16}
														/>
													),
													content:
														'Products',
													onClick: {},
												},
												{
													key: 2,
													prefix: (
														<Icon
															name="tag"
															size={16}
														/>
													),
													content: 'Offers',
													onClick: {},
												},
												{
													key: 3,
													prefix: (
														<Icon
															name="tag"
															size={16}
														/>
													),
													content:
														'Category',
													onClick: () => {
														categoryViewPanel();
													},
												},
												{
													key: 4,
													prefix: (
														<Icon
															name="tag"
															size={16}
														/>
													),
													content: 'Brand',
													onClick: {},
												},
										  ]
										: [
												{
													key: 1,
													prefix: (
														<Icon
															name="tag"
															size={16}
														/>
													),
													content:
														'Products',
													onClick: {},
												},
												{
													key: 3,
													prefix: (
														<Icon
															name="tag"
															size={16}
														/>
													),
													content:
														'Category',
													onClick: () => {
														categoryViewPanel();
													},
												},
												{
													key: 4,
													prefix: (
														<Icon
															name="tag"
															size={16}
														/>
													),
													content: 'Brand',
													onClick: {},
												},
										  ]
								}
								type="action"
							/>
						</Popover>

						<Popover
							position="bottom-right"
							popoverTriggerEvent={
								actionMenuPopoverTriggerEvent
							}
							active={showCategoryActionMenu}
							showHidePopover={toggleActiomMenu}
							type="basic"
							customStyles={{ width: '128px' }}
						>
							<ActionList
								width={160}
								items={[
									{
										key: 4,
										prefix: (
											<Icon
												name="pencil"
												size={16}
											/>
										),
										content: 'Edit',
										onClick: () =>
											viewEditCategoryOverviewPanel(
												selectedCategory
											),
									},
									{
										key: 5,
										prefix: (
											<Icon
												name="trashcan"
												size={16}
											/>
										),
										content: 'Delete',
										onClick: () => {
											setShowDeleteConfirmPopupIndex(
												true
											),
												toggleActiomMenu(
													false
												);
										},
									},
									{
										key: 6,
										prefix: (
											<Icon
												name="user"
												size={16}
											/>
										),
										content: 'Log',
									},
								]}
								type="action"
							/>

							<ConfirmationPopup
								modalVisible={
									showDeleteConfirmPopupIndex
								}
								onSuccess={() =>
									confirmDeleteOverviewedProduct(
										selectedCategory
									)
								}
								onCancel={onCancelDeleteConfirmPopup}
								confirmationMessage={`Are you sure you want to delete this category ?`}
								okText="Yes"
								cancelText="No"
							/>
						</Popover>
						<RightPanel
							active={showAddOrEditPanel}
							size="small"
							outsideClose={true}
							handleOnClose={hideAddOrEditCategoryPanel}
							headerContent={addCategoryPanelHeader}
						>
							<Space
								direction="vertical"
								style={{ width: '100%' }}
							>
								{missingCategoryInfoMessage && (
									<Alert
										message="Required Fields Missing!"
										showIcon
										description={`
                                        ${missingCategoryInfoMessage}
                                    `}
										type="error"
										closable
										action={
											<Button
												category="text"
												variant="link"
												size="small"
											/>
										}
									/>
								)}
								{hasSubCategoryValidationIssues && (
									<Alert
										message="Duplicate Data!"
										showIcon
										description={
											subCategoryValidationError
										}
										type="error"
										closable
										action={
											<Button
												category="text"
												variant="link"
												size="small"
											/>
										}
									/>
								)}
								{apiValidationError ? (
									<InlineMessage
										message={
											apiValidationError.message
										}
										type="alert"
										variant="error"
										close={true}
									/>
								) : null}
								<Accordion
									theme="default"
									type="content_only"
									title="Category Information"
									collapsed={false}
								>
									<div
										ref={categoryInfoPanel}
										style={{ width: '100%' }}
									>
										<CategoryInformationForm
											categoryInformationValues={
												categoryInformationValues
											}
											categoryInformationForm={
												categoryInformationForm
											}
											onFinish={onFinishBasic}
											onFinishFailed={
												onFinishBasicFailed
											}
											handleChangeCategoryInfoInputs={
												handleChangeCategoryInfoInputs
											}
											brands={brands}
											orgDetails={
												dashboardState
											}
											validations={
												categoryInfoValidationErrors
											}
										/>
									</div>
								</Accordion>
								<Accordion
									theme="default"
									type="content_only"
									title="Sub Category Information"
									collapsed={
										!showSubCategoryInfoPanel
									}
								>
									<div
										ref={subCategoryInfoPanel}
										style={{ width: '100%' }}
									>
										<SubCategoryInformationForm
											subCategoryInformationValues={
												subCategoryInformationValues
											}
											handleSetSubCategories={
												handleSetSubCategories
											}
											validations={
												priceInfoValidationErrors
											}
										/>
									</div>
								</Accordion>

								<Accordion
									theme="default"
									type="content_only"
									title="Warning Messages"
									collapsed={true}
								>
									<div style={{ width: '100%' }}>
										<WarningMessagesForm
											warningMessagesInitialValues={{}}
											customAttributesForm={
												customAttributesForm
											}
											onFinish={
												onFinishCustomAttributes
											}
											onFinishFailed={
												onFinishCustomAttributesFailed
											}
											warningMessages={
												warningMessagesObj
											}
											handleChangeWarningMessageInputs={
												handleChangeWarningMessageInputs
											}
										/>
									</div>
								</Accordion>
							</Space>
						</RightPanel>
						<RightPanel
							active={showCategoryOverviewPanel}
							size="small"
							outsideClose={true}
							headerContent={categoryOverviewHeader}
							handleOnClose={hideOverviewCategoryPanel}
						>
							<Space
								direction="vertical"
								style={{ width: '100%' }}
							>
								{showCategoryOverviewPanel ? (
									<CategoryOverview
										category={
											rowData
												? rowData.find(
														(rd) =>
															rd.ID ===
															selectedCategoryId
												  )
												: []
										}
									/>
								) : null}
							</Space>
						</RightPanel>

						<StackItem
							className="eco-mr-12 eco-ml-12"
							style={{ margin: 10 }}
						>
							<Button
								category="icon"
								variant="default"
								children="settings"
								size="small"
								iconSize={20}
								onClick={(e) => {
									setSettingsMenuPopoverTriggerEvent(
										e
									);
									toggleSettingsMenu(true);
								}}
								onMouseLeave={() => {
									toggleSettingsMenu(false);
								}}
							/>
						</StackItem>
						<Popover
							position="bottom-right"
							popoverTriggerEvent={
								settingsMenuPopoverTriggerEvent
							}
							active={showSettingsMenu}
							showHidePopover={toggleSettingsMenu}
							type="basic"
							customStyles={{ marginTop: 8 }}
						>
							<ActionList
								width={160}
								items={[
									{
										key: 7,
										prefix: (
											<Icon
												name="tag"
												size={16}
											/>
										),
										content: 'Label Print',
										onClick: {},
									},
									{
										key: 8,
										prefix: (
											<Icon
												name="trashcan"
												size={16}
											/>
										),
										content: 'Delete',
										onClick: {},
									},
								]}
								type="action"
							/>
						</Popover>
						<ConfirmationPopup
							modalVisible={showDeleteConfirmPopup}
							onSuccess={() =>
								confirmDeleteOverviewedProduct(
									selectedCategoryId
								)
							}
							onCancel={onCancelDeleteConfirmPopup}
							confirmationMessage={`Are you sure you want to delete this category ? (This category may have already existing products.)`}
							okText="Yes"
							cancelText="No"
						/>

						<StackItem style={{ margin: 10 }}>
							<Button
								category="icon"
								variant="default"
								children="ellipsis"
								size="small"
								iconSize={20}
								onClick={(e) => {
									setDotsMenuPopoverTriggerEvent(e);
									toggleDotsMenu(true);
								}}
								onMouseLeave={() => {
									toggleDotsMenu(false);
								}}
							/>
						</StackItem>

						<Popover
							position="bottom-right"
							popoverTriggerEvent={
								dotsMenuPopoverTriggerEvent
							}
							active={showDotsMenu}
							showHidePopover={toggleDotsMenu}
							type="basic"
							customStyles={{ marginTop: 8 }}
						>
							<ActionList
								width={160}
								sections={[
									{
										title: 'Upload',
										items: [
											{
												key: 9,
												prefix: (
													<Icon
														name="file-csv"
														size={16}
													/>
												),
												content: 'CSV File',
												onClick: {},
											},
										],
									},
									{
										title: 'Download',
										items: [
											{
												key: 10,
												prefix: (
													<Icon
														name="file-csv"
														size={16}
													/>
												),
												content: 'CSV File',
												onClick: {},
											},
											{
												key: 11,
												prefix: (
													<Icon
														name="file-xls"
														size={16}
													/>
												),
												content: 'XLS file',
												onClick: {},
											},
										],
									},
								]}
								type="action"
							/>
						</Popover>
					</Stack>
				</Stack>
			</div>
			<div className="category-page-content">
				<Table
					columns={columnData}
					dataSource={displayData}
					loading={loadingProducts}
				></Table>
			</div>
		</div>
	);
};
export default CategoryPage;
