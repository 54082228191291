/* eslint-disable react/no-array-index-key */

import lodash from 'lodash';

/**
 * NOTE : Helper function to return list of extracted values from given compound path.
 * EX :
        compoundValuePath = 'address.Line1 + address.Line2 + address.Country';
        fullValueObj      = { a: 10, b: 20, address : { Line1: 'xx', Line2: 'yy',  Country: 'zz', Zip: 'qq'}}
        Output ----> ['xx', 'yy', 'zz']
 */
export function getCompoundValueListFromCompoundPath(
	compoundValuePath = '',
	fullValueObj = {},
) {
	const compoundValuePathList = compoundValuePath.replace(/ /g, '').split('+');

	return compoundValuePathList.map((objPath) => {
		return lodash.get(fullValueObj, objPath);
	});
}

/**
 * NOTE : Helper function to create & return React Element which is created from list of extracted values from given compound path.
 */
export function defaultCompoundValuePathColumnRenderer(
	compoundValuePath,
	fullRowValues, // Entire value set passed for this Row.
) {
	const compoundValueList = getCompoundValueListFromCompoundPath(
		compoundValuePath,
		fullRowValues,
	);

	return (
		<div>
			{compoundValueList.map((val, index) => {
				return <div key={index}>{val}</div>;
			})}
		</div>
	);
}
