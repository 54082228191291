import React, { useEffect, useState } from 'react';
import { Button, InputBox, Table, Form, Select, Input } from 'witmeg-ui-system';
const { Option } = Select;


const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    unitTypes,
    ...restProps
}) => {
    const [selectedUnit, setSelectedUnit] = useState('Box')
    const [selectedUnitObj, setSelectedUnitObj] = useState('')
    function handleChange(e) {
        console.log("Change unit Type===>>", e)
        setSelectedUnit(e.target.value)
        setSelectedUnitObj(unitTypes.find(u => u.UnitTypeName === e.target.value))
    }

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={dataIndex == 'name' ? [
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ] : [{
                        required: false,

                    }]}
                >
                    <Input />
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const SubCategoryTable = (props) => {
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [editingKey, setEditingKey] = useState('');

    useEffect(() => {
        if (props.subCategoryInformationValues) {
            setData(props.subCategoryInformationValues);
        }
    }, [props.subCategoryInformationValues]);

    const isEditing = (record) => record.key === editingKey;

    const edit = (record) => {
        form.setFieldsValue({
            name: '',
            description: '',
            ...record,
        });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setData(newData);
                setEditingKey('');
                props.handleSetSubCategories(newData)
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const deleteItem = async (key) => {
        let newData = data.filter(item => item.key !== key);
        setData(newData)
        props.handleSetSubCategories(newData)
    };

    const addNewSubCategory = () => {
        let item = {
            key: data.length.toString(),
            name: '',
            description: ''

        }
        setData([...data, item]);
        edit(item);
    }

    const columns = [
        {
            title: <div> Name <span style={{ color: "red" }}>*</span></div>,
            dataIndex: 'name',
            key: 'name',
            editable: true,

        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            editable: true,


        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Button category='icon' variant='subtle' onClick={() => save(record.key)} children='check' size='small' iconSize={16} />
                        <Button category='icon' variant='subtle' children='cancel' size='small' iconSize={16} onClick={cancel} />
                        <Button category='icon' variant='subtle' children='trashcan' size='small' iconSize={16} onClick={() => deleteItem(record.key)} disabled={record.key === '0'} />
                    </span>
                ) : (
                    <>
                        <Button category='icon' variant='subtle' children='create' size='small' iconSize={16} disabled={editingKey !== '' || record.key === '0'} onClick={() => edit(record)} />
                        <Button category='icon' variant='subtle' children='trashcan' size='small' iconSize={16} onClick={() => deleteItem(record.key)} disabled={record.key === '0'} />
                    </>
                );
            },
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: 'number',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),

            }),
        };
    });
    let subCategoryInformationValues = props.subCategoryInformationValues
    return (
        <div>
            <Form form={form} component={false}>
                <Table
                    components={{
                        body: {
                            cell: (props) => <EditableCell {...props} />,
                        },
                    }}
                    bordered
                    dataSource={data}
                    columns={mergedColumns}
                    rowClassName="editable-row"

                />
            </Form>
            <Button variant="link" onClick={addNewSubCategory}>Add New</Button>

        </div>
    );
};


export default SubCategoryTable;