import React from "react";
import {
    GlobalLayout,
    GlobalHeader,
    GlobalFooter,
    SideMenuWithToggle,
    Icon,
} from "witmeg-ui-system";
import { Tooltip } from "antd";

import { bindActionCreators } from "@reduxjs/toolkit";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import {
    generateLocationListOfAllOrganizations,
    handleLocationChange,
} from "../../components/dashboardRelatedComps/LocationSelector/helpers/helperFns";
import { reqStatusTypes } from "../../redux/helpers/constants";
import { actions as customersPageSliceActions } from "../../redux/slices/dashboard/customersPageSlice/customersPageSlice";
import { sectionName as dashboardSectionName } from "../../redux/slices/dashboard/dashboardConstants";
import { actions as dashboardSliceActions } from "../../redux/slices/dashboard/dashboardSlice/dashboardSlice";
import { actions as proformasPageSliceActions } from "../../redux/slices/dashboard/proformasPageSlice/proformasPageSlice";
import { actions as purchaseOrderPageSliceActions } from "../../redux/slices/dashboard/purchaseOrderPageSlice/purchaseOrderPageSlice";
import { actions as settingsSliceActions } from "../../redux/slices/settingsSlice/settingsSlice";
import { actions as userSliceActions } from "../../redux/slices/userSlice/userSlice";
import { actions as reportSliceActions } from "../../redux/slices/reportsSlice/reportsSlice";
import { routePaths } from "../../routes/routeConstants";
import { getReportListByAppIDAndOrgID } from "../../utilities/apiRequests/witmegReportingServerRequests";
import routerHistory from "../../utilities/routerHistory";
import {
    logoutUser,
    getLoggedUserId,
} from "../../utilities/userAuthentication";

import DashboardPageRoutes from "./DashboardPageRoutes";
import "./DashboardPage.css";
// import '../../../node_modules/witmeg-reporting-embed/dist/styles.css'

import logo from "../../assets/img/logo.png";
import logoWithText from "../../assets/img/logo-with-text-blue.png";

// Temporary Logging for Debugging Purposes.

class DashboardPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            preFetchingReqStatus: reqStatusTypes.idle,
            preFetchingReqError: "",
            notificationList: [],
            reportList: null,
            menuState: false,
            selectedLocation: null,
            activeKey: null,
            globalMenuConfig: [
                {
                    key: 0,
                    menuTitle: <Link to={routePaths.dashboardRoot}>Home</Link>,
                    description: "",
                    menuIcon: "home",
                    onClick: () => {
                        routerHistory.push(routePaths.dashboardRoot);
                        setKey("0");
                    },
                },
                {
                    key: 1,
                    menuTitle: "Inventory",
                    description: "",
                    menuIcon: "four-squares",
                    subMenuItems: [
                        {
                            key: "1.1",
                            menuTitle: (
                                <Link
                                    to={
                                        routePaths.dashboard__inventory__products
                                    }
                                >
                                    Products
                                </Link>
                            ),
                            onClick: () => {
                                routerHistory.push(
                                    routePaths.dashboard__inventory__products
                                );
                                setKey("1");
                            },
                        },
                        {
                            key: "1.2",
                            menuTitle: (
                                <Link
                                    to={
                                        routePaths.dashboard__inventory__category
                                    }
                                >
                                    Category
                                </Link>
                            ),
                            // description: 'Description2',
                            menuIcon: "layers",
                            onClick: () => {
                                setKey("1");
                            },
                        },
                        {
                            key: "1.3",
                            menuTitle: (
                                <Link
                                    to={routePaths.dashboard__inventory__brands}
                                >
                                    Brand
                                </Link>
                            ),
                            menuIcon: "bookmark",
                            onClick: () => {
                                setKey("1");
                            },
                        },
                        // {
                        // 	key: '1.4',
                        // 	menuTitle: <Link
                        // 		to={
                        // 			routePaths.dashboard__inventory__promotions
                        // 		}>
                        // 		Promotions
                        // 	</Link>,
                        // 	menuIcon: 'bookmark',
                        // 	onClick: () => { }
                        // },
                        {
                            key: "1.5",
                            menuTitle: (
                                <Link
                                    to={routePaths.dashboard__inventory__offers}
                                >
                                    Offers
                                </Link>
                            ),
                            menuIcon: <Icon name="offer" />,
                            onClick: () => {
                                setKey("1");
                            },
                        },
                    ],
                },
                // {
                // 	key: '3',
                // 	menuTitle: 'Sales',
                // 	menuIcon: 'salesforce',
                // 	subMenuItems: [
                // 		{
                // 			key: '3.1',
                // 			menuTitle: 'Add Customer',
                // 			menuIcon: 'user-add',
                // 			onClick: () => {},
                // 		},
                // 		{
                // 			key: '3.2',
                // 			menuTitle: (
                // 				<Link
                // 					to={
                // 						routePaths.dashboard__sales__customers__search
                // 					}
                // 				>
                // 					Search Customers
                // 				</Link>
                // 			),
                // 			menuIcon: 'user-search',
                // 			onClick: () => {},
                // 		},
                // 		{
                // 			key: '3.3',
                // 			menuTitle: 'Add Performa',
                // 			menuIcon: 'plus',
                // 			onClick: () => {},
                // 		},
                // 		{
                // 			key: '3.4',
                // 			menuTitle: (
                // 				<Link
                // 					to={
                // 						routePaths.dashboard__sales__proformas__search
                // 					}
                // 				>
                // 					Search Proformas
                // 				</Link>
                // 			),
                // 			menuIcon: 'search',
                // 			onClick: () => {},
                // 		},
                // 	],
                // },
                {
                    key: "4",
                    menuTitle: "Purchase",
                    menuIcon: "cash",
                    subMenuItems: [
                        {
                            key: "4.1",
                            menuTitle: "Add Purchase Order",
                            menuIcon: "user-add",
                            onClick: () => {
                                setKey("4");
                            },
                        },
                        {
                            key: "4.2",
                            menuTitle: (
                                <Link
                                    to={
                                        routePaths.dashboard__purchase__supplier
                                    }
                                >
                                    Supplier
                                </Link>
                            ),
                            menuIcon: "user-add",
                            onClick: () => {
                                setKey("4");
                            },
                        },
                        {
                            key: "4.3",
                            menuTitle: (
                                <Link
                                    to={
                                        routePaths.dashboard__purchase__purchase_orders__search
                                    }
                                >
                                    Purchase Orders
                                </Link>
                            ),
                            menuIcon: "search",
                            onClick: () => {
                                setKey("4");
                            },
                        },
                        {
                            key: "4.4",
                            label: (
                                <Link to={routePaths.dashboard__purchase__msa}>
                                    Manual Stock Adjustment
                                </Link>
                            ),
                            icon: <Icon name="note" />,
                            onClick: () => {
                                setKey("4");
                            },
                        },
                        {
                            key: "4.5",
                            menuTitle: "GRNs",
                            menuIcon: "view-list",
                            onClick: () => {
                                setKey("4");
                            },
                        },
                    ],
                },
                {
                    key: "5",
                    menuTitle: "Apps",
                    menuIcon: "four-squares",
                    subMenuItems: [
                        {
                            key: "5.1",
                            menuTitle: (
                                <Link to={`${routePaths.dashboard__apps_nisa}`}>
                                    NISA
                                </Link>
                            ),
                            onClick: () => {
                                setKey("5");
                            },
                        },
                        {
                            key: "5.2",
                            menuTitle: (
                                <Link
                                    to={`${routePaths.dashboard__apps_nisa_promotions}`}
                                >
                                    NISA PROMOTIONS
                                </Link>
                            ),
                            onClick: () => {
                                setKey("5");
                            },
                        },
                    ],
                },
                {
                    key: "6",
                    menuTitle: "Reports",
                    menuIcon: "dashboard",
                    // subMenuItems: [],
                    subMenuItems: [
                        {
                            key: 6.1,
                            menuTitle: (
                                <Link
                                    to={`${routePaths.dashboard__reportView}/749d3000-4536-4b61-a32f-b342e64fd8a0`}
                                >
                                    Report 1
                                </Link>
                            ),
                            onClick: () => {
                                setKey("6");
                            },
                        },
                        {
                            key: 6.2,
                            menuTitle: (
                                <Link
                                    to={`${routePaths.dashboard__reportView}/749d3000-4536-4b61-a32f-b342e64fd8a0`}
                                >
                                    Report 2
                                </Link>
                            ),
                            onClick: () => {
                                setKey("6");
                            },
                        },
                    ],
                },
                {
                    key: "7",
                    menuTitle: (
                        <Link to={routePaths.dashboard__accountSettings}>
                            Settings
                        </Link>
                    ),
                    description: "",
                    menuIcon: "settings",
                    onClick: () => {
                        routerHistory.push(
                            routePaths.dashboard__accountSettings
                        );
                        setKey("7");
                    },
                },
                {
                    key: "5",
                    menuTitle: "Logout",
                    menuIcon: "logout",
                    onClick: () => {
                        logoutUser({
                            redirectTo: routePaths.loginRoot,
                        });
                    },
                },
            ],
            toggleMenuConfig: [
                {
                    key: "0",
                    label: <Link to={routePaths.dashboardRoot}>Home</Link>,
                    icon: <Icon name="home" />,
                    onClick: () => {
                        routerHistory.push(routePaths.dashboardRoot);
                        setKey("0");
                    },
                },
                {
                    key: "1",
                    label: "Inventory",
                    icon: <Icon name="four-squares" />,
                    children: [
                        {
                            key: "1.1",
                            label: (
                                <Link
                                    to={
                                        routePaths.dashboard__inventory__products
                                    }
                                >
                                    Products
                                </Link>
                            ),
                            icon: <Icon name="briefcase" />,
                            onClick: () => {
                                routerHistory.push(
                                    routePaths.dashboard__inventory__products
                                );
                                setKey("1");
                            },
                        },
                        {
                            key: "1.2",
                            label: (
                                <Link
                                    to={
                                        routePaths.dashboard__inventory__category
                                    }
                                >
                                    Category
                                </Link>
                            ),
                            icon: <Icon name="layers" />,
                            onClick: () => {
                                setKey("1");
                            },
                        },
                        {
                            key: "1.3",
                            label: (
                                <Link
                                    to={routePaths.dashboard__inventory__brands}
                                >
                                    Brand
                                </Link>
                            ),
                            icon: <Icon name="bookmark" />,
                            onClick: () => {
                                setKey("1");
                            },
                        },
                        // {
                        // 	key: '1.4',
                        // 	label: <Link
                        // 		to={
                        // 			routePaths.dashboard__inventory__promotions
                        // 		}>
                        // 		Promotions
                        // 	</Link>,
                        // 	icon: <Icon name="bookmark" />,
                        // 	onClick: () => { }
                        // },
                        {
                            key: "1.5",
                            label: (
                                <Link
                                    to={routePaths.dashboard__inventory__offers}
                                >
                                    Offers
                                </Link>
                            ),
                            icon: <Icon name="offer" />,
                            onClick: () => {
                                setKey("1");
                            },
                        },
                    ],
                },

                {
                    key: "4",
                    label: "Purchase",
                    icon: <Icon name="cash" />,
                    children: [
                        {
                            key: "4.1",
                            label: "Add Purchase Order",
                            icon: <Icon name="user-add" />,
                            onClick: () => {
                                setKey("4");
                            },
                        },
                        {
                            key: "4.2",
                            label: (
                                <Link
                                    to={
                                        routePaths.dashboard__purchase__supplier
                                    }
                                >
                                    Supplier
                                </Link>
                            ),
                            icon: <Icon name="view-list" />,
                            onClick: () => {
                                setKey("4");
                            },
                        },
                        {
                            key: "4.3",
                            label: (
                                <Link
                                    to={
                                        routePaths.dashboard__purchase__purchase_orders__search
                                    }
                                >
                                    Purchase Orders
                                </Link>
                            ),
                            icon: "search",
                            onClick: () => {
                                setKey("4");
                            },
                        },
                        {
                            key: "4.4",
                            label: (
                                <Link to={routePaths.dashboard__purchase__msa}>
                                    Manual Stock Adjustment
                                </Link>
                            ),
                            icon: <Icon name="note" />,
                            onClick: () => {
                                setKey("4");
                            },
                        },
                        {
                            key: "4.5",
                            label: "GRNs",
                            icon: <Icon name="view-list" />,
                            onClick: () => {
                                setKey("4");
                            },
                        },
                    ],
                },
                {
                    key: "5",
                    label: "Apps",
                    icon: <Icon name="four-squares" />,
                    children: [
                        {
                            key: "5.1",
                            label: (
                                <Link to={`${routePaths.dashboard__apps_nisa}`}>
                                    NISA
                                </Link>
                            ),
                            onClick: () => {
                                setKey("5");
                            },
                        },
                        {
                            key: "5.2",
                            label: (
                                <Link
                                    to={`${routePaths.dashboard__apps_nisa_promotions}`}
                                >
                                    NISA PROMOTIONS
                                </Link>
                            ),
                            onClick: () => {
                                setKey("5");
                            },
                        },
                    ],
                },
                {
                    key: "6",
                    label: "Reports",
                    icon: <Icon name="dashboard" />,
                    // children: [],
                    subMenuItems: [
                        {
                            key: 6.1,
                            menuTitle: (
                                <Link
                                    to={`${routePaths.dashboard__reportView}/749d3000-4536-4b61-a32f-b342e64fd8a0`}
                                >
                                    Report 1
                                </Link>
                            ),
                            onClick: () => {},
                        },
                        {
                            key: 6.2,
                            menuTitle: (
                                <Link
                                    to={`${routePaths.dashboard__reportView}/749d3000-4536-4b61-a32f-b342e64fd8a0`}
                                >
                                    Report 2
                                </Link>
                            ),
                            onClick: () => {},
                        },
                    ],
                },
                {
                    key: "7",
                    label: (
                        <Link to={routePaths.dashboard__accountSettings}>
                            Settings
                        </Link>
                    ),
                    icon: <Icon name="settings" />,
                    onClick: () => {
                        routerHistory.push(
                            routePaths.dashboard__accountSettings
                        );
                        setKey("7");
                    },
                },
                {
                    key: "5",
                    label: "Logout",
                    icon: <Icon name="logout" />,
                    onClick: () => {
                        logoutUser({
                            redirectTo: routePaths.loginRoot,
                        });
                    },
                },
            ],
            breadcrumbRoutes: [],
        };
    }

    async runPreFetchActions() {
        try {
            this.setState({
                preFetchingReqStatus: reqStatusTypes.pending,
                preFetchingReqError: "",
            });

            const { userSliceActions, dashboardSliceActions } = this.props;

            const {
                getCurrentlyLoggedUserDetails:
                    getCurrentlyLoggedUserDetailsAction,
            } = userSliceActions;

            const {
                getFullUserOrgDetailsByUserId:
                    getFullUserOrgDetailsByUserIdAction,
            } = dashboardSliceActions;

            const preFetchActionFns = [
                getCurrentlyLoggedUserDetailsAction, // Getting Currently Logged User's Basic Details. (Ex. Name, Email, Address, ...)
                () =>
                    getFullUserOrgDetailsByUserIdAction({
                        UserId: getLoggedUserId(),
                    }),
            ];

            // Executing all added actions and getting Promises.
            const preFetchActionFnsPromises = preFetchActionFns.map(
                (action) => {
                    return action();
                }
            );

            // Checking all actions are successfully ran.
            await Promise.all(preFetchActionFnsPromises)
                .then(() => {
                    return this.setState({
                        preFetchingReqStatus: reqStatusTypes.succeeded,
                    });
                })
                .catch(() => {
                    return this.setState({
                        preFetchingReqStatus: reqStatusTypes.failed,
                        preFetchingReqError:
                            "Error Occurred While Loading The Dashboard.",
                    });
                });
        } catch {
            this.setState({
                preFetchingReqStatus: reqStatusTypes.failed,
                preFetchingReqError:
                    "Error Occurred While Loading The Dashboard.",
            });
        }

        // try {
        // 	settingsSliceActions.dummyFn()
        // 	// const {
        // 	// 	getCurrencySettingsByOrgIdAndCloudLocationID: getCurrencySettingsByOrgIdAndCloudLocationIDAction
        // 	// } = settingsSliceActions;

        // 	// const preFetchActionFns = [
        // 	// 	() =>
        // 	// 		getCurrencySettingsByOrgIdAndCloudLocationIDAction({
        // 	// 			UserId: getLoggedUserId(),
        // 	// 		})
        // 	// ];

        // 	// const preFetchActionFnsPromises = preFetchActionFns.map((action) => {
        // 	// 	return action();
        // 	// });

        // 	// await Promise.all(preFetchActionFnsPromises).then(() => {
        // 	// 	console.log("====Currency Data=======")
        // 	// }).catch((e) => {
        // 	// 	console.log(" CurrencyError==============>>>", e)
        // 	// })

        // } catch (e) {
        // 	console.log("Error==============>>>", e)
        // }
    }

    setKey(key) {
        this.setState({
            activeKey: key,
        });
    }

    // Generate what should be displayed on Dashboard Avatar Circle.
    generateAvatarValue() {
        const { userSliceState } = this.props;
        const { userDetails: userAccountDetails } = userSliceState;

        const { FirstName = "", LastName = "" } = userAccountDetails;

        return String(FirstName[0] + LastName[0]).toUpperCase();
    }

    // Generate what should be displayed in <Dashboard.AppBar/> Middle Section
    generatePageTitleDetails() {
        const { dashboardSliceState } = this.props;

        const {
            currentlySelectedOrgIndex,
            currentlySelectedCompanyIndex,
            currentlySelectedLocationIndex,
            fullUserOrgDetailsList,
            getFullUserOrgDetailsByUserIdReqStatus,
        } = dashboardSliceState;

        // If this specific request is not finished mean our needed data is not yet fetched.
        // So to avoid errors occurred when accessing unavailable data we do this check here.
        if (
            getFullUserOrgDetailsByUserIdReqStatus !== reqStatusTypes.succeeded
        ) {
            return {};
        }

        const currentlySelectedOrgData =
            fullUserOrgDetailsList[currentlySelectedOrgIndex] || {};

        const currentlySelectedCompanyData =
            currentlySelectedOrgData.Companies[currentlySelectedCompanyIndex] ||
            {};

        const currentlySelectedLocationData =
            currentlySelectedCompanyData.LocationIDs[
                currentlySelectedLocationIndex
            ] || {};

        return {
            pageTitle: currentlySelectedOrgData.OrganizationName,
            pageSubtitle: currentlySelectedLocationData.LocationName,
        };
    }

    // Generate what should be displayed in <Dashboard.AppBar/> LocationSelector Section
    generateLocationSelectorDetails() {
        const { dashboardSliceState, dashboardSliceActions } = this.props;

        const fullLocationList = generateLocationListOfAllOrganizations({
            dashboardSliceState,
        });

        // Generating UI Library compatible list for Locations List.
        const locationMenuItemList = fullLocationList.map(
            (fullLocRelatedDetails) => {
                return {
                    icon: "marker-place",
                    label: fullLocRelatedDetails.locationDetails.LocationName,
                    value: fullLocRelatedDetails.locationDetails.LocationID,
                    organizationDetails:
                        fullLocRelatedDetails.organizationDetails,
                    companyDetails: fullLocRelatedDetails.companyDetails,
                    locationDetails: fullLocRelatedDetails.locationDetails,
                };
            }
        );

        return [
            {
                label:
                    (fullLocationList &&
                        fullLocationList[0]?.organizationDetails
                            ?.OrganizationName) ||
                    "",
                options: locationMenuItemList,
            },
        ];
    }

    fetchLatestNotifications = () => {
        // Set Dummy Notifications For Testing
        this.setState({
            notificationList: [
                {
                    avatarValue: "AB",
                    title: "Product Added",
                    subtitle: "Noodles Added To Inventory.",
                    id: "001",
                    seen: false,
                    onClick: () => {
                        this.onClickNofificationItem("001", "Product Added");
                    },
                },
                {
                    avatarValue: "AC",
                    title: "Product Added",
                    subtitle: "Noodles Added To Inventory.",
                    id: "002",
                    seen: false,
                    onClick: () => {
                        this.onClickNofificationItem("002", "Product Added");
                    },
                },
                {
                    avatarValue: "DS",
                    title: "Product Added",
                    subtitle: "Noodles Added To Inventory.",
                    id: "003",
                    seen: false,
                    onClick: () => {
                        this.onClickNofificationItem("003", "Product Added");
                    },
                },
                {
                    avatarValue: "SM",
                    title: "Purchase Order Added",
                    subtitle: "Noodles Added To Inventory.",
                    id: "004",
                    seen: false,
                    onClick: () => {
                        this.onClickNofificationItem(
                            "003",
                            "Purchase Order Added"
                        );
                    },
                },
            ],
        });
    };

    onClickNofificationItem = (val, title) => {
        const { notificationList } = this.state;
        this.setState({
            notificationList: notificationList.filter(
                (nl) => nl.title !== title
            ),
        });
        switch (title) {
            case "Product Added":
                routerHistory.push("/dashboard/inventory/products");
                break;
            case "Purchase Order Added":
                routerHistory.push(
                    "/dashboard/purchase/purchase-orders/search"
                );
                break;
            default:
        }
        // if (title === 'Product Added') {
        // 	routerHistory.push('/dashboard/inventory/products');
        // } else if (title === 'Purchase Order Added') {
        // 	routerHistory.push('/dashboard/purchase/purchase-orders/search');
        // }
    };

    componentDidMount() {
        this.runPreFetchActions();

        // Fetch Latest Notifications
        this.fetchLatestNotifications();
    }

    async componentDidUpdate(prevProps, prevState) {
        // console.log("dashboardSliceState ====>>", this.props.dashboardSliceState)
        const {
            dashboardSliceState,
            settingsSliceActions,
            reportSliceActions,
            reportsSliceState,
        } = this.props;
        const { menuState } = this.state;
        if (
            prevProps.dashboardSliceState.currentlySelectedOrgId !==
                dashboardSliceState.currentlySelectedOrgId ||
            prevProps.dashboardSliceState.currentlySelectedLocationId !==
                dashboardSliceState.currentlySelectedLocationId ||
            prevState.menuState !== menuState
        ) {
            console.log(
                "===========ORG ID Change=========",
                dashboardSliceState.currentlySelectedOrgId
            );

            // dummyFnAction,
            settingsSliceActions.getCurrencySettingsByOrgIdAndCloudLocationID({
                OrgID: dashboardSliceState.currentlySelectedOrgId,
                LocationId: dashboardSliceState.currentlySelectedLocationId,
            });

            const { dummyFn: dummyFnAction } = settingsSliceActions;

            const reportListData = await getReportListByAppIDAndOrgID({
                OrgID: dashboardSliceState.currentlySelectedOrgId,
                appId: process.env.REACT_APP_CURRENT_APP_ID,
            });

            reportSliceActions.setReportList(reportListData);

            const renderTooltip = (item) => {
                if (this.state.menuState === true) {
                    return (
                        <Link
                            to={`${routePaths.dashboard__reportView}/${item.id}`}
                            style={{
                                maxWidth: "100px",
                                whiteSpace: "inherit",
                            }}
                        >
                            {item.name}
                        </Link>
                    );
                } else {
                    return (
                        <Tooltip title={item.name}>
                            <Link
                                to={`${routePaths.dashboard__reportView}/${item.id}`}
                                style={{
                                    maxWidth: "100px",
                                    whiteSpace: "inherit",
                                }}
                            >
                                {item.name}
                            </Link>
                        </Tooltip>
                    );
                }
            };

            const reportMenuItems = reportListData
                ? reportListData.map((item, idx) => {
                      return {
                          key: `6.${item.id}`,
                          icon: <Icon name="dashboard" size={16} />,
                          label: renderTooltip(item),
                          onClick: () => {},
                      };
                  })
                : [];

            this.setState({
                reportList: reportListData,
                globalMenuConfig: [
                    {
                        key: "0",
                        menuTitle: (
                            <Link to={routePaths.dashboardRoot}>Home</Link>
                        ),
                        description: "",
                        menuIcon: "home",
                        onClick: () => {
                            routerHistory.push(routePaths.dashboardRoot);
                        },
                    },
                    {
                        key: "1",
                        menuTitle: "Inventory",
                        description: "",
                        menuIcon: "four-squares",
                        subMenuItems: [
                            {
                                key: "1.1",
                                menuTitle: (
                                    <Link
                                        to={
                                            routePaths.dashboard__inventory__products
                                        }
                                    >
                                        Products
                                    </Link>
                                ),
                                menuIcon: "briefcase",
                                onClick: () => {
                                    routerHistory.push(
                                        routePaths.dashboard__inventory__products
                                    );
                                },
                            },
                            {
                                key: "1.2",
                                menuTitle: (
                                    <Link
                                        to={
                                            routePaths.dashboard__inventory__category
                                        }
                                    >
                                        Category
                                    </Link>
                                ),
                                // description: 'Description2',
                                menuIcon: "layers",
                                onClick: () => {},
                            },
                            {
                                key: "1.3",
                                menuTitle: (
                                    <Link
                                        to={
                                            routePaths.dashboard__inventory__brands
                                        }
                                    >
                                        Brand
                                    </Link>
                                ),
                                menuIcon: "bookmark",
                                onClick: () => {},
                            },
                            // {
                            // 	key: '1.4',
                            // 	menuTitle: <Link
                            // 		to={
                            // 			routePaths.dashboard__inventory__promotions
                            // 		}>
                            // 		Promotions
                            // 	</Link>,
                            // 	menuIcon: 'bookmark',
                            // 	onClick: () => { }
                            // },
                            {
                                key: "1.5",
                                menuTitle: (
                                    <Link
                                        to={
                                            routePaths.dashboard__inventory__offers
                                        }
                                    >
                                        Offers
                                    </Link>
                                ),
                                menuIcon: <Icon name="offer" />,
                                onClick: () => {},
                            },
                        ],
                    },
                    // {
                    // 	key: '3',
                    // 	menuTitle: 'Sales',
                    // 	menuIcon: 'salesforce',
                    // 	subMenuItems: [
                    // 		{
                    // 			key: '3.1',
                    // 			menuTitle: 'Add Customer',
                    // 			menuIcon: 'user-add',
                    // 			onClick: () => {},
                    // 		},
                    // 		{
                    // 			key: '3.2',
                    // 			menuTitle: (
                    // 				<Link
                    // 					to={
                    // 						routePaths.dashboard__sales__customers__search
                    // 					}
                    // 				>
                    // 					Search Customers
                    // 				</Link>
                    // 			),
                    // 			menuIcon: 'search',
                    // 			onClick: () => {},
                    // 		},
                    // 		{
                    // 			key: '3.3',
                    // 			menuTitle: 'Add Performa',
                    // 			menuIcon: 'plus',
                    // 			onClick: () => {},
                    // 		},
                    // 		{
                    // 			key: '3.4',
                    // 			menuTitle: (
                    // 				<Link
                    // 					to={
                    // 						routePaths.dashboard__sales__proformas__search
                    // 					}
                    // 				>
                    // 					Search Proformas
                    // 				</Link>
                    // 			),
                    // 			menuIcon: 'search',
                    // 			onClick: () => {},
                    // 		},
                    // 	],
                    // },
                    {
                        key: "4",
                        menuTitle: "Purchase",
                        menuIcon: "cash",
                        subMenuItems: [
                            {
                                key: "4.1",
                                menuTitle: "Add Purchase Order",
                                menuIcon: "user-add",
                                onClick: () => {},
                            },
                            {
                                key: "4.2",
                                menuTitle: (
                                    <Link
                                        to={
                                            routePaths.dashboard__purchase__supplier
                                        }
                                    >
                                        Supplier
                                    </Link>
                                ),
                                menuIcon: "user-add",
                                onClick: () => {},
                            },
                            {
                                key: "4.3",
                                menuTitle: (
                                    <Link
                                        to={
                                            routePaths.dashboard__purchase__purchase_orders__search
                                        }
                                    >
                                        Purchase Orders
                                    </Link>
                                ),
                                menuIcon: "search",
                                onClick: () => {},
                            },
                            {
                                key: "4.4",
                                label: (
                                    <Link
                                        to={routePaths.dashboard__purchase__msa}
                                    >
                                        Manual Stock Adjustment
                                    </Link>
                                ),
                                icon: <Icon name="note" />,
                                onClick: () => {},
                            },
                            {
                                key: "4.5",
                                menuTitle: "GRNs",
                                menuIcon: "view-list",
                                onClick: () => {},
                            },
                        ],
                    },
                    {
                        key: "5",
                        menuTitle: "Apps",
                        menuIcon: "four-squares",
                        subMenuItems: [
                            {
                                key: "5.1",
                                menuTitle: (
                                    <Link
                                        to={`${routePaths.dashboard__apps_nisa}`}
                                    >
                                        NISA
                                    </Link>
                                ),
                                onClick: () => {},
                            },
                            {
                                key: "5.2",
                                menuTitle: (
                                    <Link
                                        to={`${routePaths.dashboard__apps_nisa_promotions}`}
                                    >
                                        NISA PROMOTIONS
                                    </Link>
                                ),
                                onClick: () => {},
                            },
                        ],
                    },
                    {
                        key: "6",
                        menuTitle: "Reports",
                        menuIcon: "dashboard",
                        subMenuItems: this.state.reportList
                            ? this.state.reportList.map((item, idx) => {
                                  return {
                                      key: `6.${item.id}`,
                                      menuTitle: (
                                          <Link
                                              to={`${routePaths.dashboard__reportView}/${item.id}`}
                                              style={{
                                                  maxWidth: "100px",
                                                  whiteSpace: "break-spaces",
                                              }}
                                          >
                                              {item.name}
                                          </Link>
                                      ),
                                      onClick: () => {},
                                  };
                              })
                            : [],
                        // subMenuItems: [
                        // 	{
                        // 		key: 6.1,
                        // 		menuTitle: <Link to={`${routePaths.dashboard__reportView}/749d3000-4536-4b61-a32f-b342e64fd8a0`}>
                        // 			Report 1
                        // 		</Link>,
                        // 		onClick: () => { }
                        // 	},
                        // 	{
                        // 		key: 6.2,
                        // 		menuTitle: <Link
                        // 			to={`${routePaths.dashboard__reportView}/749d3000-4536-4b61-a32f-b342e64fd8a0`}>
                        // 			Report 2
                        // 		</Link>,
                        // 		onClick: () => { }
                        // 	}
                        // ]
                    },
                    {
                        key: "7",
                        menuTitle: (
                            <Link to={routePaths.dashboard__accountSettings}>
                                Settings
                            </Link>
                        ),
                        description: "",
                        menuIcon: "settings",
                        onClick: () => {
                            routerHistory.push(
                                routePaths.dashboard__accountSettings
                            );
                        },
                    },
                    {
                        key: "8",
                        menuTitle: "Logout",
                        menuIcon: "logout",
                        onClick: () => {
                            logoutUser({
                                redirectTo: routePaths.loginRoot,
                            });
                        },
                    },
                ],
                toggleMenuConfig: [
                    {
                        key: "0",
                        label: <Link to={routePaths.dashboardRoot}>Home</Link>,
                        icon: <Icon name="home" />,
                        onClick: () => {
                            routerHistory.push(routePaths.dashboardRoot);
                        },
                    },
                    {
                        key: "1",
                        label: "Inventory",
                        icon: <Icon name="four-squares" />,
                        children: [
                            {
                                key: "1.1",
                                label: (
                                    <Link
                                        to={
                                            routePaths.dashboard__inventory__products
                                        }
                                    >
                                        Products
                                    </Link>
                                ),
                                icon: <Icon name="briefcase" />,
                                onClick: () => {
                                    routerHistory.push(
                                        routePaths.dashboard__inventory__products
                                    );
                                },
                            },
                            {
                                key: "1.2",
                                label: (
                                    <Link
                                        to={
                                            routePaths.dashboard__inventory__category
                                        }
                                    >
                                        Category
                                    </Link>
                                ),
                                icon: <Icon name="layers" />,
                                onClick: () => {},
                            },
                            {
                                key: "1.3",
                                label: (
                                    <Link
                                        to={
                                            routePaths.dashboard__inventory__brands
                                        }
                                    >
                                        Brand
                                    </Link>
                                ),
                                icon: <Icon name="bookmark" />,
                                onClick: () => {},
                            },
                            // {
                            // 	key: '1.4',
                            // 	label: <Link
                            // 		to={
                            // 			routePaths.dashboard__inventory__promotions
                            // 		}>
                            // 		Promotions
                            // 	</Link>,
                            // 	icon: <Icon name="bookmark" />,
                            // 	onClick: () => { }
                            // },
                            {
                                key: "1.5",
                                label: (
                                    <Link
                                        to={
                                            routePaths.dashboard__inventory__offers
                                        }
                                    >
                                        Offers
                                    </Link>
                                ),
                                icon: <Icon name="offer" />,
                                onClick: () => {},
                            },
                        ],
                    },
                    // {
                    // 	key: '3',
                    // 	label: 'Sales',
                    // 	icon: <Icon name="salesforce" />,
                    // 	children: [
                    // 		{
                    // 			key: '3.1',
                    // 			label: 'Add Customer',
                    // 			icon: <icon name="user-add" />,
                    // 			onClick: () => { }
                    // 		},
                    // 		{
                    // 			key: '3.2',
                    // 			label: <Link
                    // 				to={
                    // 					routePaths.dashboard__sales__customers__search
                    // 				}>
                    // 				Search Customers
                    // 			</Link>,
                    // 			icon: <Icon name="user-search" />,
                    // 			onClick: () => { }
                    // 		},
                    // 		{
                    // 			key: '3.3',
                    // 			label: 'Add Performa',
                    // 			icon: <Icon name="plus" />,
                    // 			onClick: () => { }
                    // 		},
                    // 		{
                    // 			key: '3.4',
                    // 			label: <Link
                    // 				to={
                    // 					routePaths.dashboard__sales__proformas__search
                    // 				}>
                    // 				Search Proformas
                    // 			</Link>,
                    // 			icon: <Icon name="search" />,
                    // 			onClick: () => { }
                    // 		},
                    // 	]
                    // },
                    {
                        key: "4",
                        label: "Purchase",
                        icon: <Icon name="cash" />,
                        children: [
                            {
                                key: "4.2",
                                label: (
                                    <Link
                                        to={
                                            routePaths.dashboard__purchase__supplier
                                        }
                                    >
                                        Supplier
                                    </Link>
                                ),
                                icon: <Icon name="view-list" />,
                                onClick: () => {},
                            },
                            {
                                key: "4.3",
                                label: (
                                    <Link
                                        to={
                                            routePaths.dashboard__purchase__purchase_orders__search
                                        }
                                    >
                                        Purchase Orders
                                    </Link>
                                ),
                                icon: <Icon name="search" />,
                                onClick: () => {},
                            },
                            {
                                key: "4.4",
                                label: (
                                    <Link
                                        to={routePaths.dashboard__purchase__msa}
                                    >
                                        Manual Stock Adjustment
                                    </Link>
                                ),
                                icon: <Icon name="note" />,
                                onClick: () => {},
                            },
                            {
                                key: "4.5",
                                label: "GRNs",
                                icon: <Icon name="view-list" />,
                                onClick: () => {},
                            },
                        ],
                    },
                    {
                        key: "5",
                        label: "Apps",
                        icon: <Icon name="four-squares" />,
                        children: [
                            {
                                key: "5.1",
                                label: (
                                    <Link
                                        to={`${routePaths.dashboard__apps_nisa}`}
                                    >
                                        NISA
                                    </Link>
                                ),
                                onClick: () => {},
                            },
                            {
                                key: "5.2",
                                label: (
                                    <Link
                                        to={`${routePaths.dashboard__apps_nisa_promotions}`}
                                    >
                                        NISA PROMOTIONS
                                    </Link>
                                ),
                                onClick: () => {},
                            },
                        ],
                    },
                    {
                        key: "6",
                        label: "Reports",
                        icon: <Icon name="dashboard" />,
                        children: reportMenuItems,
                    },
                    {
                        key: "7",
                        label: "Logout",
                        icon: <Icon name="logout" />,
                        onClick: () => {
                            logoutUser({
                                redirectTo: routePaths.loginRoot,
                            });
                        },
                    },
                ],
            });
        }

        if (
            JSON.stringify(prevProps.reportsSliceState) !==
                JSON.stringify(this.props.reportsSliceState) ||
            prevProps.location.pathname !== this.props.location.pathname
        ) {
            const splittedPathname = this.props.location.pathname
                .split("/")
                .filter((item) => Boolean(item)); // Removing empty item coming from '/' split.

            this.setState({
                breadcrumbRoutes: splittedPathname.map((item, index) => {
                    const isHome = `/${item}` === routePaths.dashboardRoot;
                    const linkPath = `/${splittedPathname
                        .slice(0, index + 1)
                        .join("/")}/`; // Path as ReactRouter URL.

                    return {
                        icon: isHome ? "home" : "",
                        title:
                            index === 2 && splittedPathname[1] === "report-view"
                                ? this.props.reportsSliceState.reports.find(
                                      (r) => r.id === item
                                  ).name
                                : item.toUpperCase(),
                        onClick: () => {
                            routerHistory.push(linkPath);
                        },
                        isActive: index === splittedPathname.length - 1,
                    };
                }),
            });
        }
    }

    toggleMenu = () => {
        this.setState({
            menuState: !this.state.menuState,
        });
    };

    render() {
        const pageTitleDetails = this.generatePageTitleDetails();
        const locationSelectorDetails = this.generateLocationSelectorDetails();

        return (
            <GlobalLayout>
                {/* <GlobalSidePanel menuConfig={this.state.globalMenuConfig} logoUrl={logo} /> */}
                <SideMenuWithToggle
                    collapsed={this.state.menuState}
                    items={this.state.toggleMenuConfig}
                    logoUrl={this.state.menuState ? logo : logoWithText}
                    openKeys={this.state.activeKey}
                />
                <div className="eco-layout__content">
                    <GlobalHeader
                        type="complex"
                        helpMenuContent={[
                            {
                                key: 1,
                                icon: "light",
                                content: "What’s new in the product?",
                                onClick: () => {
                                    alert("Guidline 1");
                                },
                            },
                            {
                                key: 2,
                                icon: "mission",
                                content: "How to set targets?",
                                onClick: () => {
                                    alert("Guidline 2");
                                },
                            },
                            {
                                key: 3,
                                icon: "news",
                                content: "New Releases",
                                onClick: () => {
                                    alert("Guidline 3");
                                },
                            },
                            {
                                key: 4,
                                icon: "settings",
                                content: "More about product setting?",
                                onClick: () => {
                                    alert("Guidline 4");
                                },
                            },
                        ]}
                        profileMenuContent={[
                            {
                                key: 1,
                                icon: "user",
                                content: "Profile",
                                onClick: () => {
                                    alert("Profile");
                                },
                            },
                            {
                                key: 2,
                                icon: "settings",
                                content: "Settings",
                                onClick: () => {
                                    alert("Settings");
                                },
                            },
                            {
                                key: 3,
                                icon: "logout",
                                content: "Logout",
                                onClick: () => {
                                    logoutUser({
                                        redirectTo: routePaths.loginRoot,
                                    });
                                },
                            },
                        ]}
                        pageTitleProps={{
                            pageTitle: pageTitleDetails.pageTitle,
                            pageSubtitle: pageTitleDetails.pageSubtitle,
                        }}
                        breadcrumbProperties={{
                            breadcrumbRoutes: this.state.breadcrumbRoutes,
                        }}
                        // 	this.state.reportList ?
                        // 	splittedPathname.map((item, index) => {
                        // 		console.log("this.state.reportList.find(r => r.id===item).name", this.state?.reportList)
                        // 		const isHome = `/${item}` === routePaths.dashboardRoot;
                        // 		const linkPath = `/${splittedPathname.slice(0, index + 1).join('/')}/`; // Path as ReactRouter URL.

                        // 		return {
                        // 			icon: isHome ? "home" : "",
                        // 			title: index===3 && splittedPathname[1]==="report-view" ?this.state.reportList.find(r => r.id===item).name : item.toUpperCase(),
                        // 			onClick: () => {
                        // 				routerHistory.push(linkPath);
                        // 			},
                        // 			isActive: index === splittedPathname.length - 1
                        // 		}
                        // 	})
                        // 	:splittedPathname.map((item, index) => {
                        // 		console.log("this.state.reportList.find(r => r.id===item).name", this.state?.reportList)
                        // 		const isHome = `/${item}` === routePaths.dashboardRoot;
                        // 		const linkPath = `/${splittedPathname.slice(0, index + 1).join('/')}/`; // Path as ReactRouter URL.

                        // 		return {
                        // 			icon: isHome ? "home" : "",
                        // 			title: item.toUpperCase(),
                        // 			onClick: () => {
                        // 				routerHistory.push(linkPath);
                        // 			},
                        // 			isActive: index === splittedPathname.length - 1
                        // 		}
                        // 	})
                        //   }}
                        locationSelectorProps={{
                            locationAvatarValue: "",
                            groupedItems: locationSelectorDetails,
                            handleLocationChange: (e) => {
                                this.setState({
                                    selectedLocation: e,
                                });
                                // sessionStorage.setItem('selectedLocation', JSON.stringify(e))
                                handleLocationChange({
                                    dashboardSliceActions,
                                    clickedLocationData: e,
                                    storesList:
                                        this.props.dashboardSliceState
                                            .storesList,
                                });
                                // dispatch(dashboardSliceActions.setLocationChangedData({
                                // 	orgIndex: e.organizationDetails.OrganizationIndex,
                                // 	orgId: e.organizationDetails.OrganizationID,

                                // 	comIndex: e.companyDetails.CompanyIndex,
                                // 	comId: e.companyDetails.CompanyID,

                                // 	locIndex: e.locationDetails.LocationIndex,
                                // 	locId: e.locationDetails.LocationID,
                                // 	storesList: storesList
                                // }))
                            },
                            // defaultValue: locationSelectorDetails[0].options[0],
                            defaultValue:
                                this.state.selectedLocation ||
                                locationSelectorDetails[0].options[0],
                        }}
                        onToggle={this.toggleMenu}
                        hasIcon={true}
                    />
                    <div className="eco-app-content">
                        <DashboardPageRoutes
                            selectedLocation={
                                this.state.selectedLocation ||
                                locationSelectorDetails[0].options[0]
                            }
                        />
                    </div>
                    <GlobalFooter
                        productVersion={process.env.REACT_APP_CURRENT_VERSION}
                    />
                </div>
            </GlobalLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userSliceState: state.user,
        dashboardSliceState: state[dashboardSectionName].dashboard,
        reportsSliceState: state.reports,
    };
};

const mapDispatchToProps = (dispatch) => {
    const boundUserSliceActions = bindActionCreators(
        userSliceActions,
        dispatch
    );
    const boundDashboardSliceActions = bindActionCreators(
        dashboardSliceActions,
        dispatch
    );
    const boundCustomersPageSliceActions = bindActionCreators(
        customersPageSliceActions,
        dispatch
    );
    const boundProformasPageSliceActions = bindActionCreators(
        proformasPageSliceActions,
        dispatch
    );
    const boundPurchaseOrderPageSliceActions = bindActionCreators(
        purchaseOrderPageSliceActions,
        dispatch
    );
    const boundSettingsSliceActions = bindActionCreators(
        settingsSliceActions,
        dispatch
    );
    const boundReportSliceActions = bindActionCreators(
        reportSliceActions,
        dispatch
    );

    return {
        userSliceActions: boundUserSliceActions,
        dashboardSliceActions: boundDashboardSliceActions,
        customersPageSliceActions: boundCustomersPageSliceActions,
        proformasPageSliceActions: boundProformasPageSliceActions,
        purchaseOrderPageSliceActions: boundPurchaseOrderPageSliceActions,
        settingsSliceActions: boundSettingsSliceActions,
        reportSliceActions: boundReportSliceActions,
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DashboardPage));
