import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

import { getFullUserOrgDetailsByUserId as getFullUserOrgDetailsByUserIdAPI } from '../../../../utilities/apiRequests/witmegOrgServerRequests';
import { getLoggedUserId } from '../../../../utilities/userAuthentication';

import sliceConstants from './dashboardSliceConstants';

export const getFullUserOrgDetailsByUserId = createAsyncThunk(
	`${sliceConstants.name}/getFullUserOrgDetailsByUserId`,
	async (options = {}, thunkAPI) => {
		try {
			const { UserID = getLoggedUserId() } = options;

			const fullUserOrgDetailsByUserId = await getFullUserOrgDetailsByUserIdAPI(
				{ UserID },
			);

			// Sorting Organizations, Each of it's nested Companies and Locations. (Ascending order - by Name)
			// Also removing organization's that user don't have explicit access for 'Web Neurolage'.
			const sortedFullUserOrgDetailsByUserId = fullUserOrgDetailsByUserId
				.slice()
				.reduce((acc, current, index, sourceArray) => {
					// ************** App License Check **************
					// User should have Web Neurolage App Permission in a Organizations to access that.
					const webNeurolageAppLicenseId = '5';
					const isValidWebNeurolageLicenseAvailable = current.AppLicenses.find(
						(license) => {
							return (
								license.AppLicenseID === webNeurolageAppLicenseId &&
								license.Status === 'Active'
							);
						},
					);
					if (!isValidWebNeurolageLicenseAvailable) {
						return acc;
					}

					// ************** Sorting **************
					// Sorting Each Nested Company. (Org-->Company)
					current.Companies.sort((a, b) =>
						a.CompanyName.localeCompare(b.CompanyName),
					);

					// Sorting Each Nested Location. (Org-->Company-->Location)
					current.Companies.forEach((com) =>
						com.LocationIDs.sort((a, b) =>
							a.LocationName.localeCompare(b.LocationName),
						),
					);

					acc.push(current);

					// Finally sorting Each Organization.
					if (sourceArray.length === index + 1) {
						acc.sort((a, b) =>
							a.OrganizationName.localeCompare(b.OrganizationName),
						);
					}
					return acc;
				}, []);

			// Setting up initial Location Selector Data. So Initially FIRST Organization, Company, Location is selected.
			thunkAPI.dispatch(
				// eslint-disable-next-line no-use-before-define
				setLocationSelectorData({
					orgIndex: 0,
					orgId: sortedFullUserOrgDetailsByUserId[0].OrganisationID,

					comIndex: 0,
					comId: sortedFullUserOrgDetailsByUserId[0].Companies[0].CompanyID,

					locIndex: 0,
					locId:
						sortedFullUserOrgDetailsByUserId[0].Companies[0].LocationIDs[0]
							.LocationID,
					locName:
						sortedFullUserOrgDetailsByUserId[0].Companies[0].LocationIDs[0]
							.LocationName,
					storesList: fullUserOrgDetailsByUserId
				}),
			);

			return sortedFullUserOrgDetailsByUserId;
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;
			return Promise.reject(errMsg);
		}
	},
);

// This action is used to set Organization ID, CompanyID, LocationID, Etc....
export const setLocationSelectorData = createAction(
	`${sliceConstants.name}/setLocationSelectorData`,
);

export const setLocationChangedData = createAsyncThunk(
	`${sliceConstants.name}/setLocationSelectorData`,
	async (payload) => {
		console.log('payload========>>>', payload);
		try {
			// // const { UserID = getLoggedUserId() } = options;

			// // const fullUserOrgDetailsByUserId = await getFullUserOrgDetailsByUserIdAPI(
			// // 	{ payload },
			// // );
			// return await getCurrencySettingsAPI(payload);
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;
			return Promise.reject(errMsg);
		}
	}
)

const extraActions = {
	getFullUserOrgDetailsByUserId,
	setLocationSelectorData,
	setLocationChangedData
};

export default extraActions;
