import { LoadingOutlined as LoadingIcon } from "@ant-design/icons";
import { Form, Input, Alert, Spin } from "antd";
import React, { Component } from "react";
import { Stack, StackItem, InputBox, Button } from "witmeg-ui-system";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";

import { routePaths } from "../../../../routes/routeConstants";
import { loginUser } from "../../../../utilities/apiRequests/witmegOrgServerRequests";
import { saveLoggedUser } from "../../../../utilities/userAuthentication";

class LoginForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFormSubmitting: false,
            formSubmitError: "",
            username: "",
            password: "",
        };
    }

    handleChangeInput = (field, e) => {
        this.setState({
            [field]: e.target.value,
        });
    };

    handleFormSubmitting = async () => {
        const { history: routerHistory, reactRouterState } = this.props;
        // const { shouldRedirect, redirectPath } = reactRouterState;

        const apiReqBody = {
            UserName: this.state.username,
            Password: this.state.password,
        };

        this.setState({
            isFormSubmitting: true,
        });

        const resData = await loginUser(apiReqBody);
        const { AccessToken, RefreshToken } = resData;

        saveLoggedUser({
            AccessToken,
            RefreshToken,
        });

        toast.success("You have logged in successfully.");

        setTimeout(() => {
            let redirectTo = routePaths.dashboardRoot;

            // if (shouldRedirect) {
            // 	redirectTo = '/dashboard';
            // }
            location.href = redirectTo;
            history.push(redirectTo);
        });
    };

    render() {
        return (
            <div>
                <StackItem className="login-form-elements-mt">
                    <InputBox
                        id="email_address"
                        iconName="email"
                        type="text"
                        placeholder="Your email"
                        onChange={(e) => {
                            this.handleChangeInput("username", e);
                        }}
                        // error={loginInfoValidationErrors.username}
                    />
                </StackItem>
                <StackItem>
                    <InputBox
                        id="password"
                        iconName="locked"
                        type="password"
                        placeholder="Password"
                        onChange={(e) => {
                            this.handleChangeInput("password", e);
                        }}
                        // error={loginInfoValidationErrors.password}
                    />
                </StackItem>
                <StackItem className="eco-mt-8">
                    <Button
                        category="text"
                        color="blue"
                        variant="primary"
                        fullWidth={true}
                        onClick={this.handleFormSubmitting}
                        // onClick={login}
                    >
                        Log in
                    </Button>
                </StackItem>
                {/* <Form.Item
					label='Username'
					name='UserName'
					rules={[{ required: true, message: 'Required.' }]}>
					<Input />
				</Form.Item> */}

                {/* <Form.Item
					label='Password'
					name='Password'
					rules={[{ required: true, message: 'Required.' }]}>
					<Input.Password />
				</Form.Item>

				<Form.Item>
					<Button type='primary' htmlType='submit'>
						LOGIN
					</Button>
					{isFormSubmitting && <Spin indicator={<LoadingIcon />} />}
				</Form.Item> */}

                {/* {formSubmitError && <Alert message={formSubmitError} type='error' />} */}
            </div>
        );
    }
}

export default withRouter(LoginForm);
