import { reqStatusTypes } from '../../../helpers/constants';
import { sectionName } from '../dashboardConstants';

const sliceName = 'dashboard';
const currentStatePath = `${sectionName}.${sliceName}`;

const sliceConstants = {
	name: sliceName,
	parentSectionName: sectionName,
	currentStatePath,
	initialState: {
		// ******************************* getFullUserOrgDetailsByUserId() *******************************
		getFullUserOrgDetailsByUserIdReqStatus: reqStatusTypes.idle,
		getFullUserOrgDetailsByUserIdReqError: '',
		fullUserOrgDetailsList: [],

		// ******************************* getFullUserOrgDetailsByUserId()  && setLocationSelectorData() *******************************
		currentlySelectedOrgIndex: 0, // This track which index of above 'state.fullUserOrgDetailsList' array is currently selected.
		currentlySelectedCompanyIndex: 0,
		currentlySelectedLocationIndex: 0,

		currentlySelectedOrgId: '',
		currentlySelectedCompanyId: '',
		currentlySelectedLocationId: '',
	},
};

export default sliceConstants;
