import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { sectionName as dashboardSectionName } from "../../../../../redux/slices/dashboard/dashboardConstants";
import {
    Stack,
    StackItem,
    Button,
    Popover,
    RightPanel,
    RightPanelHeader,
    ActionList,
    Icon,
    Form,
    Space,
    Alert,
    ConfirmationPopup,
    Accordion,
    SearchableMenu,
    Label,
    InputGroup,
    InlineMessage,
    Table,
    Message,
    DatePicker,
} from "witmeg-ui-system";
import BasicInformationForm from "./Forms/BasicInformationForm";

const { RangePicker } = DatePicker;

import "./index.css";

import {
    addEposProduct,
    getAllEposProducts,
    getEposProductsByCloudProductCategoryId,
    getEposProductBrandsByOrgId,
    getEposProductByBarcode,
    getEposProductByProductRefNo,
    getEposProductCategoriesByOrgId,
    getUnitTypesByOrgId,
    updateEposProduct,
    getEposCustomAttributesByOrgId,
    getEposProductsByCloudProductName,
} from "../../../../../utilities/apiRequests/witmegWebNeurolageServerRequests";
import ProductOverview from "./ProductOverview/";
import ProductImagesForm from "./Forms/ProductImagesForm";
import PriceInformationForm from "./Forms/PriceInformationForm";
import EcommerceConfigurations from "./Forms/EcommerceConfigurations";
import CustomAttributesForm from "./Forms/CustomAttributesForm";
import SecondaryBarcodeForm from "./Forms/SecondaryBarcodeForm";
import ProductBrand from "./Forms/ProductBrand";
import moment from "moment-timezone";

const ProductPage = (props) => {
    const [basicInformationForm] = Form.useForm();
    const [priceInformationForm] = Form.useForm();
    const [ecommerceConfigurationsForm] = Form.useForm();
    const [customAttributesForm] = Form.useForm();

    const basicInfoPanel = useRef();
    const priceInfoPanel = useRef();

    const dashboardState = useSelector(
        (state) => state[dashboardSectionName].dashboard
    );
    const userState = useSelector((state) => state.user.userDetails);
    const currencySettingsState = useSelector(
        (state) => state.settings.currencySettings
    );
    const [loadingProducts, setLoadingProducts] = useState(true);
    const [rowData, setRowData] = useState(null);
    const [displayData, setDisplayData] = useState(null);
    const [selectedOption, setSelectedOption] = useState({
        value: "product",
        label: "Product",
    });
    const [enableProductAddMode, setEnableProductAddMode] = useState(false);
    const [printMenuPopoverTriggerEvent, setPrintMenuPopoverTriggerEvent] =
        useState(null);
    const [showPrintMenuPopover, setShowPrintMenuPopover] = useState(false);
    const [
        downloadMenuPopoverTriggerEvent,
        setDownloadMenuPopoverTriggerEvent,
    ] = useState(null);
    const [showDownloadMenuPopover, setShowDownloadMenuPopover] =
        useState(false);
    const [searchKey, setSearchKey] = useState("");
    const [showProductOverviewPanel, setShowProductOverviewPanel] =
        useState(false);
    const [showProductBrandPanel, setShowProductBrandPanel] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [selectedProductObj, setSelectedProductObj] = useState(null);
    const [enableProductEditMode, setEnableProductEditMode] = useState(false);
    const [productIdToDelete, setProductIdToDelete] = useState(null);
    const [productCategories, setProductCategories] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [brands, setBrands] = useState([]);
    const [showBrandCard, setShowBrandCard] = useState([]);
    const [addMenuPopoverTriggerEvent, setAddMenuPopoverTriggerEvent] =
        useState(false);
    const [unitTypes, setUnitTypes] = useState([]);
    const [showAddMenu, setShowAddMenu] = useState(false);
    const [customAttributes, setCustomAttributes] = useState([]);
    const [customAttributesObj, setCustomAttributesObj] = useState(null);
    const [missingBasicFields, setMissingBasicFields] = useState([]);
    const [missingBasicFieldNames, setMissingBasicFieldNames] = useState([]);
    const [basicInfo, setBasicInfo] = useState(null);
    const [missingPriceFields, setMissingPriceFields] = useState([]);
    const [missingPriceFieldNames, setMissingPriceFieldNames] = useState([]);
    const [priceInfo, setPriceInfo] = useState(null);
    const [unitChartInfo, setUnitChartInfo] = useState(null);
    const [onlineImagesObj, setOnlineImagesObj] = useState({});
    const [missingEcommerceFields, setMissingEcommerceFields] = useState([]);
    const [missingEcommerceFieldNames, setMissingEcommerceFieldNames] =
        useState([]);
    // const [ecommerceInfo, setEcommerceInfo] = useState(null)
    const [missingCustomAttributesFields, setMissingCustomAttributesFields] =
        useState([]);
    const [
        missingCustomAttributesFieldNames,
        setMissingCustomAttributesFieldNames,
    ] = useState([]);
    const [customAttributesInfo, setCustomAttributesInfo] = useState(null);
    const [basicInformationIValues, setBasicInformationIValues] = useState({});
    const [basicInfoValidationErrors, setbasicInfoValidationErrors] = useState({
        productName: false,
        mainCategory: false,
        brand: false,
        itemCode: false,
        barcode: false,
    });

    const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [priceInformationValues, setPriceInformationValues] = useState({});
    const [priceInfoValidationErrors, setPriceInfoValidationErrors] = useState({
        purchasePrice: false,
        sellingPrice: false,
        recommendedPrice: false,
    });
    const [showProductsActionMenu, setShowProductsActionMenu] = useState(false);
    const [actionMenuPopoverTriggerEvent, setActionMenuPopoverTriggerEvent] =
        useState(null);
    const [
        ecommerceConfigurationsItitialValues,
        setEcommerceConfigurationsItitialValues,
    ] = useState(null);
    const [showAddOrEditPanel, setShowAddOrEditPanel] = useState(false);
    const [apiValidationError, setApiValidationError] = useState(null);
    const [showBasicInfoPanel, setShowBasicInfoPanel] = useState(true);
    const [showPriceInfoPanel, setShowPriceInfoPanel] = useState(false);
    const [forceResetImages, setForceResetImages] = useState(false);
    const [showDeleteConfirmPopupIndex, setShowDeleteConfirmPopupIndex] =
        useState(false);
    const [showAddCard, setShowAddCard] = useState(true);

    //product tags
    // const [productTagsInputValue, setProductTagsInputValue] = React.useState('');
    const [productTagsValue, setProductTagsValue] = React.useState([]);

    // const wrapperProductParentState = useCallback(val => {
    // 	setProductTagsValue(val);
    // 	// console.log('product tags from the parent', val);
    //   }, [productTagsValue]);

    const createOption = (label) => ({
        label,
        value: label,
    });

    const currentDate = moment.tz();
    const today = currentDate.clone().tz("Europe/London");

    setPriceInfo;
    useEffect(() => {
        if (enableProductAddMode && unitTypes.length > 0) {
            setBasicInformationIValues({
                productName: "",
                mainCategory: "",
                subCategory: "",
                brand: "",
                location: [],
                itemCode: "",
                barcode: "",
                stock: "",
                weightable: "no",
                packSize: "",
                showOnline: false,
                priceNotification: false,
                availableStock: "",
                openingStock: "",
            });
            setPriceInformationValues({
                purchasePrice: "",
                purchaseUnits: {
                    value: unitTypes.find((ut) => ut.IsBaseUnit).ID,
                    label: unitTypes.find((ut) => ut.IsBaseUnit).UnitTypeName,
                },
                sellingPrice: "",
                sellingPriceUnits: {
                    value: unitTypes.find((ut) => ut.IsBaseUnit).ID,
                    label: unitTypes.find((ut) => ut.IsBaseUnit).UnitTypeName,
                },
                recommendedPrice: "",
                showOnline: true,
                updatedPrices: false,
                maxDiscount: "",
            });
            setEcommerceConfigurationsItitialValues({
                productTag: [],
                orderQty: 1,
                onlineConfig: {},
                outOfStock: false,
                featuredProduct: false,
            });
            // productTagsData([]);
        } else {
            setBasicInformationIValues({});
        }

        setShowAddOrEditPanel(enableProductAddMode);
    }, [enableProductAddMode, unitTypes]);

    useEffect(async () => {
        if (rowData && selectedProductId) {
            let product =
                (await rowData) &&
                rowData.find((rd) => rd.ID === selectedProductId);
            setSelectedProductObj(product);
        } else {
            setSelectedProductObj(null);
        }
    }, [rowData, selectedProductId]);

    useEffect(async () => {
        if (enableProductEditMode && selectedProductObj && unitTypes) {
            let locationId = props.selectedLocation.locationDetails.LocationID;
            setBasicInformationIValues({
                productName: selectedProductObj.Name,
                mainCategory: selectedProductObj.CloudProductCategory
                    ? {
                          value: selectedProductObj.CloudProductCategory
                              .ProductCategory.ID,
                          label: selectedProductObj.CloudProductCategory
                              .ProductCategory.Category,
                      }
                    : {},
                subCategory:
                    selectedProductObj.CloudProductCategory &&
                    selectedProductObj.CloudProductCategory.ProductSubCategory
                        ? {
                              value: selectedProductObj.CloudProductCategory
                                  .ProductSubCategory.ID,
                              label: selectedProductObj.CloudProductCategory
                                  .ProductSubCategory.Name,
                          }
                        : {},
                brand: selectedProductObj.CloudProductBrand
                    ? {
                          value: selectedProductObj.CloudProductBrand.ID,
                          label: selectedProductObj.CloudProductBrand.BrandName,
                      }
                    : {},
                location: "",
                itemCode: selectedProductObj.ProductRefNo,
                barcode: selectedProductObj.BarCode,
                stock: selectedProductObj.OpeningStock,
                weightable: selectedProductObj.HasWeight ? "yes" : "no",
                packSize: "",
                showOnline: selectedProductObj.IsOnline,
                priceNotification: selectedProductObj.IsPriceNotification,
                weight: selectedProductObj.Weight,
                availableStock:
                    selectedProductObj?.Inventories?.filter(
                        (b) => b.QuantityList
                    )
                        .find((i) =>
                            i?.QuantityList.find(
                                (ql) => ql?.CloudLocationID === locationId
                            )
                        )
                        ?.QuantityList.find(
                            (qls) => qls?.CloudLocationID === locationId
                        ).Stock || "-",
            });

            setPriceInformationValues({
                purchasePrice:
                    selectedProductObj.CloudCostPricesArray[0].CloudCostprice,
                purchaseUnits: {
                    value: selectedProductObj.UnitChart.CloudUnitTypeID, //unitTypes.find(ut => ut.ID===selectedProductObj.UnitChart).ID,
                    label: selectedProductObj.UnitChart.UnitTypeName,
                },
                sellingPrice:
                    selectedProductObj.CloudSellingPricesArray[0]
                        .CloudSellingprice,
                sellingPriceUnits: {
                    value: selectedProductObj.UnitChart.CloudUnitTypeID, //unitTypes.find(ut => ut.ID===selectedProductObj.UnitChart).ID,
                    label: selectedProductObj.UnitChart.UnitTypeName,
                },
                recommendedPrice: selectedProductObj.RecommendedPrice,
                showOnline: selectedProductObj.IsOnline,
                updatedPrices: false,
                maxDiscount: selectedProductObj.MaxDiscount,
            });

            let productTagArray =
                selectedProductObj.ProductTag !== ""
                    ? selectedProductObj.ProductTag.split(",")
                    : [];
            // const productTagArrayData =
            //     productTagArray.length == 0 ? "" : productTagArray;
            setEcommerceConfigurationsItitialValues({
                productTag: productTagArray,
                orderQty: selectedProductObj.MaxOnlineOrderQty,
                onlineConfig: selectedProductObj.OnLineConfig,
                outOfStock: selectedProductObj.IsOutOfStock,
                featuredProduct: false,
                showOnline: true,
            });
            productTagsData(productTagArray);
            setOnlineImagesObj(selectedProductObj.OnLineImages);
            setUnitChartInfo(selectedProductObj.UnitChart);
            setShowAddOrEditPanel(true);
        } else {
            setBasicInformationIValues({});
            setShowAddOrEditPanel(false);
            // productTagsData([]);
        }
    }, [enableProductEditMode, selectedProductObj, unitTypes]);

    useEffect(() => {
        basicInformationForm.resetFields();
    }, [basicInformationIValues]);

    useEffect(() => {
        priceInformationForm.resetFields();
    }, [priceInformationValues]);

    const productTagsData = (tagValues) => {
        const tags = tagValues.map((t) => createOption(t));
        // setProductTagsInputValue(tagInputValues);
        // console.log('product tags', tags);
        setProductTagsValue(tags);
        // console.log('product tags state', productTagsValue);
    };

    const [advancedSearchComponents, setAdvancedSearchComponents] = useState([
        {
            key: 1,
            title: "Created Date",
            type: "date-range-select",
            isVisible: false,
        },
        {
            key: 2,
            title: "Selling Price",
            type: "range-select",
            units: ["£", "$"],
            isVisible: false,
        },
        {
            key: 3,
            title: "Opening Stock",
            type: "range-select",
            units: ["Boxes"],
            isVisible: false,
        },
        {
            key: 4,
            title: "Weight",
            type: "range-select",
            units: ["Kg", "g"],
            isVisible: false,
        },
    ]);

    // const tokenInfo = JSON.parse(localStorage.getItem('user'))
    const [columnData, setColumnData] = useState([
        {
            title: "Product Name",
            dataIndex: "productName",
            key: "productName",
            width: "300px",
            render: (text) => <a>{text}</a>,
        },
        {
            title: "Category",
            dataIndex: "category",
            key: "category",
        },
        {
            title: "Item Code",
            dataIndex: "itemCode",
            key: "itemCode",
            width: "200px",
        },
        {
            title: "Barcode",
            dataIndex: "barcode",
            key: "barcode",
            width: "200px",
        },
        {
            title: "Purchase Price",
            dataIndex: "purchasePrice",
            key: "purchasePrice",
        },
        {
            title: "Selling Price",
            dataIndex: "sellingPrice",
            key: "sellingPrice",
        },
        {
            title: "Stock",
            dataIndex: "stock",
            key: "stock",
        },
        {
            title: "Weight",
            dataIndex: "weight",
            key: "weight",
            textAlign: "right",
            render: (text) => <p>{text}</p>,
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) => (
                <Space size="middle">
                    {/* <a>Invite {record.name}</a> */}
                    <Button
                        category="icon"
                        variant="subtle"
                        children="eye"
                        size="small"
                        iconSize={16}
                        onClick={() => {
                            viewProductOverviewPanel(record);
                        }}
                    />

                    <Button
                        category="icon"
                        variant="link"
                        children="ellipsis-vertical"
                        size="small"
                        iconSize={16}
                        onClick={(e) => {
                            handleShowProductsActions(e, record);
                            toggleActions(true);
                        }}
                        onMouseLeave={() => {
                            toggleActions(false);
                        }}
                    />
                </Space>
            ),
        },
    ]);

    const [columnDefs] = useState([
        { field: "Product Name" },
        { field: "Category" },
        { field: "Item Code" },
        { field: "Barcode" },
        { field: "Purchase Price" },
        { field: "Selling Price" },
        { field: "Opening Stock" },
        { field: "Weight" },
        { field: "Actions" },
    ]);

    const options = [
        { value: "product", label: "Product" },
        { value: "category", label: "Category" },
        { value: "itemCode", label: "Item Code" },
        { value: "barCode", label: "Barcode" },
    ];

    const handleOrgID = () => {
        setOrgID(props.selectedLocation.organizationDetails.OrganizationID);
    };
    const getTableDataFromResult = async (result, locationId) => {
        result = sortResultsFromCreatedDate(result);
        console.log("test results---", result);
        return await result.map((product, index) => {
            return {
                key: index,
                id: product.ID,
                productName: product.Name,
                category: product.CloudProductCategory.ProductCategory.Category,
                itemCode: product.ProductRefNo,
                barcode: product.BarCode,
                // "purchasePrice": product.CloudCostPricesArray && product.CloudCostPricesArray[0].CloudCostprice,
                purchasePrice:
                    product.CloudCostPricesArray &&
                    product.CloudCostPricesArray[0] &&
                    product.CloudCostPricesArray.find(
                        (cc) =>
                            cc.CloudLocationID ===
                            props.selectedLocation.locationDetails.LocationID
                    )
                        ? product.CloudCostPricesArray.find(
                              (cc) =>
                                  cc.CloudLocationID ===
                                  props.selectedLocation.locationDetails
                                      .LocationID
                          ).CloudCostprice
                        : "",
                // "sellingPrice": product.CloudSellingPricesArray && product.CloudSellingPricesArray[0] ? product.CloudSellingPricesArray[0].CloudSellingprice:'',
                sellingPrice:
                    dashboardState &&
                    product.CloudSellingPricesArray &&
                    product.CloudSellingPricesArray[0] &&
                    product.CloudSellingPricesArray.find(
                        (cs) =>
                            cs.CloudLocationID ===
                            props.selectedLocation.locationDetails.LocationID
                    )
                        ? product.CloudSellingPricesArray[0].CloudSellingprice
                        : "",
                // "stock": product.Inventories && product.Inventories[0] && product.Inventories[0].QuantityList && product.Inventories[0].QuantityList[0] && product.Inventories.find(i => i.QuantityList.find(ql=>ql.CloudLocationID==="622ee86816fb94c36b722b51")).QuantityList.find(qls=>qls.CloudLocationID==="622ee86816fb94c36b722b51").Stock? product.Inventories.find(i => i.QuantityList.find(ql=>ql.CloudLocationID==="622ee86816fb94c36b722b51")).QuantityList.find(qls=>qls.CloudLocationID==="622ee86816fb94c36b722b51").Stock : '-',
                stock:
                    product?.Inventories?.filter((b) => b.QuantityList)
                        .find((i) =>
                            i?.QuantityList.find(
                                (ql) => ql?.CloudLocationID === locationId
                            )
                        )
                        ?.QuantityList.find(
                            (qls) => qls?.CloudLocationID === locationId
                        ).Stock || "-",
                // "stock": product.OpeningStock,
                weight: product.Weight,
                // "actions": <Button key={index} category='icon' variant='default' children='eye' size='small' iconSize={10} />
            };
        });
    };

    const sortResultsFromCreatedDate = (result) => {
        return result.sort((result1, result2) => {
            return (
                new Date(result2.CreatedDate) - new Date(result1.CreatedDate)
            );
        });
    };

    const hideAddBrandCard = () => {
        setShowAddCard(false);
    };

    const showAddBrandCard = () => {
        setShowAddCard(true);
    };

    const pushToArrayIfNotExist = (array, elementObject) => {
        let existingObject = array.find((obj) => {
            return obj.value === elementObject.value;
        });

        if (!existingObject) {
            array.push(elementObject);
        }
    };

    useEffect(async () => {
        if (props.selectedLocation.organizationDetails.OrganizationID) {
            let productCategoryList = await getEposProductCategoriesByOrgId({
                OrgID: props.selectedLocation.organizationDetails
                    .OrganizationID,
            });
            setProductCategories(productCategoryList);

            let productBrandList = await getEposProductBrandsByOrgId({
                OrgID: props.selectedLocation.organizationDetails
                    .OrganizationID,
            });
            setBrands(productBrandList);

            let customAttributeList = await getEposCustomAttributesByOrgId({
                OrgID: props.selectedLocation.organizationDetails
                    .OrganizationID,
            });
            let unitTypesArr = await getUnitTypesByOrgId({
                OrgID: props.selectedLocation.organizationDetails
                    .OrganizationID,
            });
            setUnitTypes(unitTypesArr);
            console.log("unitTypesArr", unitTypesArr);

            setCustomAttributes(customAttributeList);
            let customAttributeObj = {};
            if (customAttributeList.Attributes) {
                customAttributeList.Attributes.forEach((ca) => {
                    customAttributeObj[ca] = "";
                });
            }
            setCustomAttributesObj(customAttributeObj);

            let baseUnit = unitTypesArr.find((uts) => uts.IsBaseUnit);
            let unitChartInfo = {
                UnitChartID: 0,
                UnitTypeID: baseUnit.UnitTypeId,
                UnitTypeName: baseUnit.UnitTypeName,
                BaseUnitTypeID: baseUnit.UnitTypeId,
                BaseunitTypeName: baseUnit.UnitTypeName,
                CloudBaseUnitTypeID: baseUnit.ID,
                CloudCreatedBy: baseUnit.CloudCreatedBy
                    ? baseUnit.CloudCreatedBy
                    : "",
                CloudModifiedBy: baseUnit.CloudModifiedBy,
                CreatedDate: baseUnit.CreatedDate
                    ? baseUnit.CreatedDate
                    : today.toISOString(),
                IsDeleted: false,
                ModifiedDate: baseUnit.ModifiedDate,
                OrgID: baseUnit.OrgID,
                CloudUnitTypeID: baseUnit.ID,
                Quantity: 1,
            };
            setUnitChartInfo(unitChartInfo);
        }
    }, [dashboardState]);

    useEffect(async () => {
        if (productCategories) {
            setLoadingProducts(true);
            let locationId = props.selectedLocation.locationDetails.LocationID;
            let x = selectedCategory
                ? await getEposProductsByCloudProductCategoryId({
                      locationId:
                          props.selectedLocation.locationDetails.LocationID,
                      productCategoryId: selectedCategory.value,
                      orgId: props.selectedLocation.organizationDetails
                          .OrganizationID,
                  })
                : await getAllEposProducts({
                      locationId:
                          props.selectedLocation.locationDetails.LocationID,
                      orgId: props.selectedLocation.organizationDetails
                          .OrganizationID,
                  });

            console.log("x===>>", x);
            setRowData(x);
            let data = await getTableDataFromResult(x, locationId);

            let productNameFilter = [];
            data.forEach((item, index) => {
                pushToArrayIfNotExist(productNameFilter, {
                    text: item.productName,
                    value: item.productName,
                });
            });

            let categoryFilter = [];
            data.forEach((item, index) => {
                pushToArrayIfNotExist(categoryFilter, {
                    text: item.category,
                    value: item.category,
                });
            });

            let itemCodeFilter = [];
            data.forEach((item, index) => {
                pushToArrayIfNotExist(itemCodeFilter, {
                    text: item.itemCode,
                    value: item.itemCode,
                });
            });

            let barcodesFilter = [];
            data.forEach((item, index) => {
                pushToArrayIfNotExist(barcodesFilter, {
                    text: item.barcode,
                    value: item.barcode,
                });
            });

            let purchasePriceFilter = [];
            data.forEach((item, index) => {
                pushToArrayIfNotExist(purchasePriceFilter, {
                    text: item.purchasePrice,
                    value: item.purchasePrice,
                });
            });

            let sellingPriceFilter = [];
            data.forEach((item, index) => {
                pushToArrayIfNotExist(sellingPriceFilter, {
                    text: item.sellingPrice,
                    value: item.sellingPrice,
                });
            });

            let stockFilter = [];
            data.forEach((item, index) => {
                pushToArrayIfNotExist(stockFilter, {
                    text: item.stock,
                    value: item.stock,
                });
            });

            // console.log('barcodes', barcodes);
            let columnDataWithFilters = columnData.map((data) => {
                if (data.dataIndex === "productName") {
                    return {
                        ...data,
                        filters: productNameFilter,
                        onFilter: (value, record) =>
                            record.productName.startsWith(value),
                        filterSearch: true,
                    };
                } else if (data.dataIndex === "category") {
                    return {
                        ...data,
                        filters: categoryFilter,
                        onFilter: (value, record) =>
                            record.category.startsWith(value),
                        filterSearch: true,
                    };
                } else if (data.dataIndex === "itemCode") {
                    return {
                        ...data,
                        filters: itemCodeFilter,
                        onFilter: (value, record) =>
                            record.itemCode.startsWith(value),
                        filterSearch: true,
                    };
                } else if (data.dataIndex === "barcode") {
                    return {
                        ...data,
                        filters: barcodesFilter,
                        onFilter: (value, record) =>
                            record.barcode.startsWith(value),
                        filterSearch: true,
                    };
                } else if (data.dataIndex === "purchasePrice") {
                    return {
                        ...data,
                        // filters: purchasePriceFilter,
                        // onFilter: (value, record) => record.purchasePrice.startsWith(value),
                        // filterSearch: true,
                        sorter: (a, b) => a.purchasePrice - b.purchasePrice,
                        render: (text) => (
                            <p style={{ textAlign: "right" }}>
                                {currencySettingsState?.DefaultCurrency
                                    ?.CurrencySymbol || "£"}{" "}
                                {Number(text)
                                    .toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </p>
                        ),
                    };
                } else if (data.dataIndex === "sellingPrice") {
                    return {
                        ...data,
                        // filters: sellingPriceFilter,
                        // onFilter: (value, record) => record.sellingPrice.startsWith(value),
                        // filterSearch: true,
                        sorter: (a, b) => a.sellingPrice - b.sellingPrice,
                        render: (text) => (
                            <p style={{ textAlign: "right" }}>
                                {currencySettingsState?.DefaultCurrency
                                    ?.CurrencySymbol || "£"}{" "}
                                {Number(text)
                                    .toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </p>
                        ),
                    };
                } else if (data.dataIndex === "stock") {
                    return {
                        ...data,
                        // filters: stockFilter,
                        // onFilter: (value, record) => record.stock.startsWith(value),
                        // filterSearch: true,
                        render: (text) => (
                            <p style={{ textAlign: "right" }}>{text}</p>
                        ),
                    };
                }
                // else if (data.dataIndex === 'weight') {
                //   return {
                //     ...data,
                //     // filters: stockFilter,
                //     // onFilter: (value, record) => record.stock.startsWith(value),
                //     filterSearch: true,
                //     render: (text) => <p style={{ textAlign: 'right' }}>{text}</p>
                //   }
                // }
                else {
                    return data;
                }
            });
            setColumnData(columnDataWithFilters);
            setDisplayData(data);
            setLoadingProducts(false);
        }
    }, [
        productCategories,
        selectedCategory,
        currencySettingsState,
        props.selectedLocation,
    ]);

    const reloadData = async () => {
        let locationId = props.selectedLocation.locationDetails.LocationID;
        if (searchKey !== "" && selectedOption.value === "barCode") {
            setLoadingProducts(true);
            let searchResult = await getEposProductByBarcode({
                locationId: props.selectedLocation.locationDetails.LocationID,
                orgId: props.selectedLocation.organizationDetails
                    .OrganizationID,
                barcode: searchKey,
            });
            let data = await getTableDataFromResult(
                searchResult.Result,
                locationId
            );
            setDisplayData(data);
            setRowData(searchResult.Result);
            setLoadingProducts(false);
        } else if (searchKey !== "" && selectedOption.value === "itemCode") {
            setLoadingProducts(true);
            let searchResult = await getEposProductByProductRefNo({
                locationId: props.selectedLocation.locationDetails.LocationID,
                orgId: props.selectedLocation.organizationDetails
                    .OrganizationID,
                productRefNo: searchKey,
            });
            let data = await getTableDataFromResult(
                searchResult.Result,
                locationId
            );
            setDisplayData(data);
            setRowData(searchResult.Result);
            setLoadingProducts(false);
        } else if (searchKey !== "" && selectedOption.value === "product") {
            setLoadingProducts(true);
            let searchResult = await getEposProductsByCloudProductName({
                orgId: props.selectedLocation.organizationDetails
                    .OrganizationID,
                locationId: props.selectedLocation.locationDetails.LocationID,
                productName: searchKey,
            });
            let data = await getTableDataFromResult(
                searchResult.Result,
                locationId
            );
            setDisplayData(data);
            setRowData(searchResult.Result);
            setLoadingProducts(false);
        } else {
            setForceResetImages(true);
            setOnlineImagesObj({});
            ecommerceConfigurationsForm.resetFields();
            setLoadingProducts(true);
            let locationId = props.selectedLocation.locationDetails.LocationID;
            let searchResult = await getAllEposProducts({
                locationId: props.selectedLocation.locationDetails.LocationID,
                orgId: props.selectedLocation.organizationDetails
                    .OrganizationID,
            });
            let data = await getTableDataFromResult(searchResult, locationId);
            setDisplayData(data);
            setRowData(searchResult);
            setLoadingProducts(false);
            setTimeout(() => {
                setForceResetImages(false);
            }, 500);
        }
    };

    useEffect(async () => {
        if (rowData && productIdToDelete) {
            let product =
                (await rowData) &&
                rowData.find((rd) => rd.ProductRefNo === productIdToDelete);
            product.IsActive = false;
            product.IsDeleted = 1;
            let deleteResult = await updateEposProduct(product);
            if (deleteResult === "Product Updated") {
                Message.success(
                    `Product(${product.Name}) is deleted successfully.`
                );
                reloadData();
            }
        }
    }, [rowData, productIdToDelete]);

    useEffect(() => {
        // if (selectedOption && selectedOption.value === 'all') {
        if (
            (selectedOption && selectedOption.value === "all") ||
            selectedOption === ""
        ) {
            reloadData();
            setSelectedCategory(null);
        }
    }, [selectedOption]);

    // useEffect(() => {
    //   addEposProductBrand(apiReqBody).then(res => {
    //     if (res) {
    //       if (res.hasError) {
    //         setApiValidationError(res)
    //         return;
    //       }
    //       Message.success(`Brand () added successfully.`)
    //       reloadData();
    //     }
    //   })
    // }, [])

    useEffect(() => {
        if (basicInfo && priceInfo && ecommerceConfigurationsItitialValues) {
            let selectedProductCategory = productCategories.find(
                (pc) => pc.ID === basicInfo.mainCategory.value
            ).ProductCategory;
            let selectedProductSubCategory =
                basicInfo.subCategory && basicInfo.subCategory !== ""
                    ? productCategories
                          .find((pc) => pc.ID === basicInfo.mainCategory.value)
                          .ProductSubCategory.find(
                              (sc) => sc.ID === basicInfo.subCategory.value
                          )
                    : {};
            let unitChartInfoObj = unitChartInfo;
            let ecommerceInfo = ecommerceConfigurationsItitialValues;

            let selectedBrandID =
                brands.length > 0 &&
                basicInfo.brand &&
                brands.find((br) => br.ID === basicInfo.brand.value)
                    ? brands.find((br) => br.ID === basicInfo.brand.value)
                          .BrandID
                    : null;

            const apiReqBody = {
                OrgID: props.selectedLocation.organizationDetails
                    .OrganizationID,
                CloudSelectedLocation:
                    props.selectedLocation.locationDetails.LocationID,
                CloudCreatedBy: userState.UserId,
                CloudModifiedBy: userState.UserId,
                Name: basicInfo.productName.trim(),
                BarCode: basicInfo.barcode,
                ProductRefNo: basicInfo.itemCode,
                IsActive: true,
                IsOnline: ecommerceInfo?.showOnline || false,
                CloudProductCategoryID: basicInfo.mainCategory.value,
                CloudProductBrandID: basicInfo.brand.value,
                ProductCategoryID: selectedProductCategory.ProductCategoryID,
                BrandID: selectedBrandID,
                MaxDiscount: Number(priceInformationValues.maxDiscount),
                CloudProductCategory: {
                    ProductCategory: selectedProductCategory,
                    ProductSubCategory: selectedProductSubCategory,
                },
                CloudProductBrand: brands.find(
                    (bn) => bn.ID === basicInfo.brand.value
                ),
                CloudCostPricesArray:
                    basicInfo.location.length > 0
                        ? basicInfo.location.map((loc) => {
                              return {
                                  CloudUnitTypeID:
                                      priceInformationValues.purchaseUnits
                                          .valuee,
                                  CloudBaseUnitTypeID:
                                      priceInfo.purchaseUnits.value,
                                  CloudLocationID: loc.value,
                                  CloudCostprice: Number(
                                      priceInfo.purchasePrice
                                  ),
                              };
                          })
                        : [
                              {
                                  CloudUnitTypeID:
                                      priceInformationValues.purchaseUnits
                                          .value,
                                  CloudBaseUnitTypeID:
                                      priceInfo.purchaseUnits.value,
                                  CloudLocationID:
                                      props.selectedLocation.locationDetails
                                          .LocationID,
                                  CloudCostprice: Number(
                                      priceInfo.purchasePrice
                                  ),
                              },
                          ],
                CloudSellingPricesArray:
                    basicInfo.location.length > 0
                        ? basicInfo.location.map((loc) => {
                              return {
                                  CloudSalesUnitTypeID:
                                      priceInformationValues.sellingPriceUnits
                                          .value, //priceInfo.sellingPriceUnits.value,
                                  CloudBaseUnitTypeID:
                                      priceInfo.sellingPriceUnits.value,
                                  CloudLocationID: loc.value,
                                  CloudSellingprice: Number(
                                      priceInfo.sellingPrice
                                  ),
                              };
                          })
                        : [
                              {
                                  CloudSalesUnitTypeID:
                                      priceInformationValues.sellingPriceUnits
                                          .value, //priceInfo.sellingPriceUnits.value,
                                  CloudBaseUnitTypeID:
                                      priceInfo.sellingPriceUnits.value,
                                  CloudLocationID:
                                      props.selectedLocation.locationDetails
                                          .LocationID,
                                  CloudSellingprice: Number(
                                      priceInfo.sellingPrice
                                  ),
                              },
                          ],
                UnitChart: unitChartInfoObj,
                OpeningStock:
                    unitChartInfoObj.UnitTypeName === "EA"
                        ? Number(basicInfo.openingStock)
                        : Number(basicInfo.openingStock) *
                          unitChartInfoObj.Quantity,
                HasWeight: false,
                Weight: basicInfo.weightable === "yes" ? basicInfo.weight : "",
                CustomArray: customAttributesObj ? customAttributesObj : {},
                OnLineImages: onlineImagesObj,
                OnLineConfig: ecommerceInfo ? ecommerceInfo.onlineConfig : {},
                ProductTag:
                    ecommerceInfo?.productTag.length > 0
                        ? ecommerceInfo.productTag
                        : "",
                IsOutOfStock: ecommerceInfo ? ecommerceInfo.outOfStock : false,
                MaxOnlineOrderQty: ecommerceInfo
                    ? Number(ecommerceInfo.orderQty)
                    : 1,
                RecommendedPrice: Number(
                    priceInformationValues.recommendedPrice
                ),
                IsPriceNotification: basicInfo.priceNotification,
                HasWeight: basicInfo.weightable === "yes",
            };
            console.log("apiRequestBodyproduct ------>", apiReqBody);
            if (enableProductEditMode) {
                updateEposProduct({
                    ID: selectedProductId,
                    ProductID: 0,
                    IsPriceChanged: priceInformationValues.updatedPrices,
                    CostPricesArray: selectedProductObj.CostPricesArray,
                    SellingPricesArray: selectedProductObj.SellingPricesArray,
                    CustomArray: selectedProductObj.CustomArray,
                    ...apiReqBody,
                }).then((res) => {
                    if (res) {
                        hideAddOrEditProductPanel();
                        Message.success(
                            `Product(${basicInfo.productName}) updated successfully.`
                        );
                        setBasicInfo(null);
                        setPriceInfo(null);
                        setOnlineImagesObj({});
                        setEcommerceConfigurationsItitialValues(null);
                        // productTagsData([]);
                        ecommerceConfigurationsForm.resetFields();
                        reloadData();
                    }
                });
            } else {
                addEposProduct(apiReqBody).then((res) => {
                    if (res) {
                        if (res.hasError) {
                            setApiValidationError(res);
                            return;
                        }
                        hideAddOrEditProductPanel();
                        Message.success(
                            `Product(${basicInfo.productName}) added successfully.`
                        );
                        setOnlineImagesObj({});
                        ecommerceConfigurationsForm.resetFields();
                        setEcommerceConfigurationsItitialValues(null);
                        // productTagsData([])
                        setBasicInfo(null);
                        setPriceInfo(null);
                        setbasicInfoValidationErrors({
                            productName: false,
                            mainCategory: false,
                            brand: false,
                            itemCode: false,
                            barcode: false,
                        });
                        setPriceInfoValidationErrors({
                            purchasePrice: false,
                            sellingPrice: false,
                        });
                        reloadData();
                    }
                });
            }
            //
        }
    }, [basicInfo, priceInfo, ecommerceConfigurationsItitialValues]);

    const togglePrintMenu = (showPrintMenu) => {
        setShowPrintMenuPopover(showPrintMenu);
    };

    const toggleDownloadMenu = (showDownloadMenu) => {
        setShowDownloadMenuPopover(showDownloadMenu);
    };

    const hideAddOrEditProductPanel = () => {
        setSelectedProductId(null);
        setEnableProductAddMode(false);
        // setEnableProductEditMode(false);
        toggleAddMenu(false);
        setOnlineImagesObj({});
    };

    const hideProductOverviewPanel = () => {
        setSelectedProductId(null);
        setShowProductOverviewPanel(false);
    };

    const hideProductBrandPanel = () => {
        setShowProductBrandPanel(false);
    };

    const viewProductOverviewPanel = (record) => {
        setSelectedProductId(record.id);
        setShowProductOverviewPanel(true);
    };

    const handleSetSearckKey = async (value) => {
        setSearchKey(value);
        let locationId = props.selectedLocation.locationDetails.LocationID;

        if (value !== "" && selectedOption.value === "barCode") {
            setLoadingProducts(true);
            let searchResult = await getEposProductByBarcode({
                locationId: props.selectedLocation.locationDetails.LocationID,
                orgId: props.selectedLocation.organizationDetails
                    .OrganizationID,
                barcode: value,
            });
            let data = await getTableDataFromResult(
                searchResult.Result,
                locationId
            );
            setDisplayData(data);
            setRowData(searchResult.Result);
            setLoadingProducts(false);
        } else if (value !== "" && selectedOption.value === "itemCode") {
            setLoadingProducts(true);
            let searchResult = await getEposProductByProductRefNo({
                locationId: props.selectedLocation.locationDetails.LocationID,
                orgId: props.selectedLocation.organizationDetails
                    .OrganizationID,
                productRefNo: value,
            });
            let data = await getTableDataFromResult(
                searchResult.Result,
                locationId
            );
            setDisplayData(data);
            setRowData(searchResult.Result);
            setLoadingProducts(false);
        } else if (value !== "" && selectedOption.value === "product") {
            setLoadingProducts(true);
            let searchResult = await getEposProductsByCloudProductName({
                orgId: props.selectedLocation.organizationDetails
                    .OrganizationID,
                locationId: props.selectedLocation.locationDetails.LocationID,
                productName: value,
            });
            let data = await getTableDataFromResult(
                searchResult.Result,
                locationId
            );
            setDisplayData(data);
            setRowData(searchResult.Result);
            setLoadingProducts(false);
        } else {
            setLoadingProducts(true);
            reloadData();
        }
    };

    // const handleKeyPressSearckKeyInput = (e) => {
    //  console.log("handleKeyPressSearckKeyInput===>>", e)
    // }

    let handleDeleteSingleProduct = async (record) => {
        let productId = record.itemCode;
        setProductIdToDelete(productId);
    };

    const confirmDeleteProduct = (e, record) => {
        handleDeleteSingleProduct(record);
        setShowDeleteConfirmPopupIndex(false);
        toggleActionsMenu(false);
    };

    let handleDeleteOverviewedProduct = async () => {
        setConfirmPopupVisible(true);
    };

    const confirmDeleteProductOverviewed = (selectedProductId) => {
        console.log("rowData++++>", rowData);
        let productRef =
            rowData &&
            rowData.find((rd) => rd.ID === selectedProductId).ProductRefNo;
        setProductIdToDelete(productRef);
        setConfirmPopupVisible(false);
        setShowProductOverviewPanel(false);
    };

    const validateBasicInfo = () => {
        let basicInfoValidations = {
            productName: basicInformationIValues.productName === "",
            mainCategory: basicInformationIValues.mainCategory === "",
            itemCode: basicInformationIValues.itemCode === "",
            barcode: basicInformationIValues.barcode === "",
        };
        setbasicInfoValidationErrors(basicInfoValidations);
        missingBasicFieldNames;
        setMissingBasicFieldNames(
            [
                basicInformationIValues.productName === ""
                    ? "Product Name"
                    : null,
                basicInformationIValues.mainCategory === ""
                    ? "Main Category"
                    : null,
                basicInformationIValues.itemCode === "" ? "Item Code" : null,
                basicInformationIValues.barcode === "" ? "Barcode" : null,
            ].filter((x) => x)
        );
        if (
            basicInformationIValues.productName !== "" &&
            basicInformationIValues.mainCategory !== "" &&
            basicInformationIValues.itemCode !== "" &&
            basicInformationIValues.barcode !== ""
        ) {
            setBasicInfo(basicInformationIValues);
        }
    };

    const validatePriceInfo = () => {
        let priceInfoValidations = {
            purchasePrice: priceInformationValues.purchasePrice === "",
            sellingPrice: priceInformationValues.sellingPrice === "",
        };
        setMissingPriceFieldNames(
            [
                priceInformationValues.purchasePrice === ""
                    ? "Purchace Price"
                    : null,
                priceInformationValues.sellingPrice === ""
                    ? "Selling Price"
                    : null,
            ].filter((x) => x)
        );

        setPriceInfoValidationErrors(priceInfoValidations);
        if (
            priceInformationValues.purchasePrice !== "" &&
            priceInformationValues.sellingPrice !== ""
        ) {
            setPriceInfo(priceInformationValues);
        }
        console.log("MissingPriceFieldNames===>", priceInfoValidations);
    };

    const handleSubmitAddProduct = async () => {
        // await basicInformationForm.submit();
        // await priceInformationForm.submit();
        await ecommerceConfigurationsForm.submit();
        // await customAttributesForm.submit();
        validateBasicInfo();
        validatePriceInfo();
        setApiValidationError(null);
    };

    const handleSubmitUpdateProduct = async () => {
        // await basicInformationForm.submit();
        // await priceInformationForm.submit();
        await ecommerceConfigurationsForm.submit();
        // await customAttributesForm.submit();
        validateBasicInfo();
        validatePriceInfo();
    };

    const handleShowProductsActions = (e, record) => {
        console.log("record--------==========", e);
        setActionMenuPopoverTriggerEvent(e);
        setSelectedProducts(record);
        console.log("record---------------------->>>>>>", selectedProducts);
        setShowProductsActionMenu(true);
        toggleActions(true);
    };

    const viewEditProductPanel = (record) => {
        setSelectedProductId(record.id);
        setEnableProductEditMode(true);
        toggleActions(false);
    };

    const handleLoadEditView = () => {
        setShowProductOverviewPanel(false);
        // setShowAddOrEditPanel(false)
        setEnableProductEditMode(true);
    };

    const handleLoadBrandView = () => {
        setShowProductBrandPanel(true);
        // setEnableProductEditMode(true);
    };

    const addProductPanelHeader = (
        <RightPanelHeader
            title={enableProductEditMode ? "Update Product" : "Add Product"}
            rightContent={
                enableProductEditMode ? (
                    <Button
                        onClick={() => handleSubmitUpdateProduct()}
                        type="primary"
                        variant="primary"
                    >
                        Update
                    </Button>
                ) : (
                    <Button
                        onClick={() => handleSubmitAddProduct()}
                        type="primary"
                        variant="primary"
                    >
                        Save
                    </Button>
                )
            }
        />
    );
    const onCancel = () => {
        console.log("cancel");
        setConfirmPopupVisible(false);
    };
    const onSuccess = () => {
        console.log("success");
        setConfirmPopupVisible(false);
    };
    const [confirmPopupVisible, setConfirmPopupVisible] = useState(false);

    const addNewBrand = () => {};

    const productBrandPanelHeader = (
        <RightPanelHeader
            title="Brand"
            rightContent={
                <Space>
                    <Button
                        variant="primary"
                        // onClick={addNewBrand}
                        onClick={showAddBrandCard}
                        // onClick={alert(brand)}
                    >
                        Add
                    </Button>
                </Space>
            }
        />
    );
    const productOverviewPanelHeader = (
        <RightPanelHeader
            title="Product Overview"
            rightContent={
                <Space>
                    <Button
                        key={selectedProductId}
                        onClick={() => handleDeleteOverviewedProduct()}
                    >
                        Delete
                    </Button>
                    <ConfirmationPopup
                        modalVisible={confirmPopupVisible}
                        onSuccess={(e) =>
                            confirmDeleteProductOverviewed(selectedProductId)
                        }
                        onCancel={onCancel}
                        confirmationMessage={`Are you sure you want to delete this product?`}
                        okText="Ok"
                        cancelText="Cancel"
                    />

                    <Button
                        variant="primary"
                        onClick={() => handleLoadEditView()}
                    >
                        Edit
                    </Button>
                </Space>
            }
        />
    );

    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };

    const fieldsWithValidation = [
        { name: "productName", label: "Product Name" },
        { name: "mainCategory", label: "Main Category" },
        { name: "itemCode", label: "Item Code" },
        { name: "barcode", label: "Barcode" },
        { name: "purchasePrice", label: "Purchase Price" },
        { name: "sellingPrice", label: "Selling Price" },
    ];

    useEffect(() => {
        let missingBasicFieldNames = fieldsWithValidation
            .filter((field) => missingBasicFields.includes(field.name))
            .map((field) => field.label);
        setMissingBasicFieldNames(missingBasicFieldNames);
    }, [missingBasicFields]);

    useEffect(() => {
        let missingPriceFieldNames = fieldsWithValidation
            .filter((field) => missingPriceFields.includes(field.name))
            .map((field) => field.label);
        setMissingPriceFieldNames(missingPriceFieldNames);
    }, [missingPriceFields]);

    const onFinishBasic = (values) => {
        setMissingBasicFields([]);
        setBasicInfo(values);
    };

    const onFinishBasicFailed = (errorInfo) => {
        const missingBasicFields = errorInfo.errorFields.map(
            (errorField) => errorField.name[0]
        );
        setMissingBasicFields(missingBasicFields);
        setBasicInfo(null);
    };

    const handleChangeBasicInfoInputs = (values, updatedField) => {
        setBasicInformationIValues(values);
        setbasicInfoValidationErrors({
            ...basicInfoValidationErrors,
            [updatedField]: values[updatedField] === "",
        });
    };

    const handleChangePriceInfoInputs = (values, updatedField) => {
        setPriceInformationValues(values);
        setPriceInfoValidationErrors({
            ...priceInfoValidationErrors,
            [updatedField]: values[updatedField] === "",
        });
    };

    const handleChangeCustomAttributesInputs = (values, updatedField) => {
        setCustomAttributesObj(values);
    };

    const handleChangeEcomConfigInputs = (values) => {
        console.log("Changed values", values);
        setEcommerceConfigurationsItitialValues(values);
    };

    const onFinishPrice = (values) => {
        setMissingPriceFields([]);
        setPriceInfo(values);
    };

    const onFinishPriceFailed = (errorInfo) => {
        const missingPriceFields = errorInfo.errorFields.map(
            (errorField) => errorField.name[0]
        );
        setMissingPriceFields(missingPriceFields);
        setPriceInfo(null);
    };

    const onFinishCustomAttributes = (values) => {
        setMissingCustomAttributesFields([]);
        setCustomAttributesInfo(values);
    };

    const onFinishCustomAttributesFailed = (errorInfo) => {
        const missingCustomAttributesFields = errorInfo.errorFields.map(
            (errorField) => errorField.name[0]
        );
        setMissingCustomAttributesFields(missingCustomAttributesFields);
        setCustomAttributesInfo(null);
    };

    const toggleActions = (showProductsActionMenu) => {
        setShowProductsActionMenu(showProductsActionMenu);
    };

    const reloadDataInMainCategory = async () => {
        let searchResult = await getEposProductCategoriesByOrgId({
            OrgID: dashboardState.currentlySelectedOrgId,
        });

        setProductCategories(searchResult);
        console.log("searchResult---reload", searchResult);
    };

    const handleSetUnitChart = (unitChartUpdated) => {
        let baseUnit = unitTypes.find((uts) => uts.IsBaseUnit);
        let currentUnitChart = unitTypes.find(
            (ut) =>
                ut.UnitTypeName ===
                unitChartUpdated[unitChartUpdated.length - 1].UnitTypeName
        );

        let unitChartInfo = {
            UnitChartID: 0,
            UnitTypeID: currentUnitChart.UnitTypeId,
            UnitTypeName: currentUnitChart.UnitTypeName,
            BaseUnitTypeID: baseUnit.UnitTypeId,
            BaseunitTypeName: baseUnit.UnitTypeName,
            CloudBaseUnitTypeID: baseUnit.ID,
            CloudCreatedBy: currentUnitChart.CloudCreatedBy
                ? currentUnitChart.CloudCreatedBy
                : "",
            CloudModifiedBy: currentUnitChart.CloudModifiedBy,
            CreatedDate: currentUnitChart.CreatedDate
                ? currentUnitChart.CreatedDate
                : today.toISOString(),
            IsDeleted: false,
            ModifiedDate: currentUnitChart.ModifiedDate,
            OrgID: currentUnitChart.OrgID,
            CloudUnitTypeID: currentUnitChart.ID,
            Quantity: Number(unitChartUpdated[unitChartUpdated.length - 1].qty),
        };
        setUnitChartInfo(unitChartInfo);
    };

    const handleOnlineImageList = (images) => {
        let onlineImagesObj = {};
        let PrimaryImages = {};
        let UnitChartImages = {};
        let SpecialImages = {};

        images
            .filter((ig) => ig.imageType === "PrimaryImages")
            .forEach((img, index) => {
                PrimaryImages[index === 0 ? "Main" : `Image${index + 1}`] =
                    img.imageUrl;
            });

        images
            .filter((ig) => ig.imageType === "UnitChartImages")
            .forEach((img, index) => {
                UnitChartImages[img.key] = img.imageUrl;
            });

        images
            .filter((ig) => ig.imageType === "SpecialImages")
            .forEach((img, index) => {
                SpecialImages[img.attrName] = [
                    {
                        Value: img.attrName,
                        ImgURL: img.imageUrl,
                    },
                ];
            });
        onlineImagesObj = {
            PrimaryImages: PrimaryImages,
            UnitChartImages: UnitChartImages,
            SpecialImages: SpecialImages,
        };
        setOnlineImagesObj(onlineImagesObj);
        // console.log("onlineImagesObj===>>", onlineImagesObj)
    };

    const toggleAddMenu = (showAddMenu) => {
        setShowAddMenu(showAddMenu);
    };

    const onCancelDeleteConfirmPopup = () => {
        // setShowCategoryOverviewPanel(false);
        setShowDeleteConfirmPopup(false);
        setShowDeleteConfirmPopupIndex(false);
        toggleActions(false);
    };

    const handleEdit = () => {};

    const missingBasicInfoMessage =
        missingBasicFieldNames.length > 0
            ? `${missingBasicFieldNames.join(
                  ", "
              )} fields are missing in Basic Information.`
            : "";
    const missingPriceInfoMessage =
        missingPriceFieldNames.length > 0
            ? `${missingPriceFieldNames.join(
                  ", "
              )} fields are missing in Price Information.`
            : "";

    const locateError = () => {
        setShowBasicInfoPanel(false);
        setShowPriceInfoPanel(false);
        if (missingBasicFieldNames.length > 0) {
            setShowBasicInfoPanel(true);
        } else if (missingPriceFieldNames.length > 0) {
            setShowPriceInfoPanel(true);
        }
    };

    return (
        <div className="product-page-content" style={{ padding: "20px" }}>
            <div
                className="product-page-header"
                style={{ paddingBottom: "20px" }}
            >
                <Stack horizontalAlign="space_between">
                    <Stack>
                        {/* <StackItem style={{minWidth:'120px'}}>
              <Select
                value={selectedOption}
                onChange={setSelectedOption}
                options={options}
              />
            </StackItem>
            <StackItem style={{ marginRight: 20,minWidth:'120px' }}>
              {selectedOption && selectedOption.value === "category" ?
                <Select
                  options={ productCategories ? productCategories.map(pc => {
                    return {
                      value: pc.ID, label: pc.ProductCategory.Category
                    }
                  }) : []}
                  onChange={(e)=>setSelectedCategory(e)}
                  value={selectedCategory}
                />
                : <Search className="eco-gray-border" onChange={handleSetSearckKey} value={searchKey} />
              }
            </StackItem> */}
                        <StackItem style={{ width: "280px", marginRight: 20 }}>
                            <InputGroup
                                leftComponent="select"
                                leftOptions={options}
                                leftValue={selectedOption}
                                onChangeLeftValue={setSelectedOption}
                                leftWidth="50%"
                                rightComponent={
                                    selectedOption &&
                                    selectedOption.value === "category"
                                        ? "select"
                                        : "search"
                                }
                                rightOptions={
                                    productCategories
                                        ? productCategories.map((pc) => {
                                              return {
                                                  value: pc.ID,
                                                  label: pc.ProductCategory
                                                      .Category,
                                              };
                                          })
                                        : []
                                }
                                rightValue={
                                    selectedOption &&
                                    selectedOption.value === "category"
                                        ? selectedCategory
                                        : searchKey
                                }
                                onChangeRightValue={(value) => {
                                    if (
                                        selectedOption &&
                                        selectedOption.value === "category"
                                    ) {
                                        setSelectedCategory(value);
                                    } else {
                                        handleSetSearckKey(value);
                                    }
                                }}
                                rightWidth="50%"
                                style={{ width: "160px" }}
                            />
                        </StackItem>

                        {advancedSearchComponents.map((searchComponent) => {
                            if (searchComponent.isVisible) {
                                if (searchComponent.type === "range-select") {
                                    return (
                                        <StackItem style={{ marginRight: 20 }}>
                                            <SearchableMenu
                                                groupedItems={[
                                                    {
                                                        items: [
                                                            {
                                                                text: "Less than",
                                                                inputs: [
                                                                    <InputGroup
                                                                        leftComponent="select"
                                                                        leftOptions={searchComponent.units.map(
                                                                            (
                                                                                unit
                                                                            ) => {
                                                                                return {
                                                                                    label: unit,
                                                                                    value: unit,
                                                                                };
                                                                            }
                                                                        )}
                                                                        leftValue={{
                                                                            label: searchComponent
                                                                                .units[0],
                                                                            value: searchComponent
                                                                                .units[0],
                                                                        }}
                                                                        // onChangeLeftValue={setSelectedOption}
                                                                        leftWidth="30%"
                                                                        rightComponent="text"
                                                                        // rightOptions={ productCategories ? productCategories.map(pc => {
                                                                        //   return {
                                                                        //     value: pc.ID, label: pc.ProductCategory.Category
                                                                        //   }
                                                                        // }) : []}
                                                                        // rightValue={searchKey}
                                                                        // onChangeRightValue={(value) => {
                                                                        //   if(selectedOption&&selectedOption.value==="category") {
                                                                        //     setSelectedCategory(value)
                                                                        //   } else {
                                                                        //     handleSetSearckKey(value)
                                                                        //   }
                                                                        // }}
                                                                        rightWidth="70%"
                                                                        style={{
                                                                            width: "160px",
                                                                        }}
                                                                    />,
                                                                ],
                                                            },
                                                            {
                                                                text: "More than",
                                                                inputs: [
                                                                    <InputGroup
                                                                        leftComponent="select"
                                                                        leftOptions={searchComponent.units.map(
                                                                            (
                                                                                unit
                                                                            ) => {
                                                                                return {
                                                                                    label: unit,
                                                                                    value: unit,
                                                                                };
                                                                            }
                                                                        )}
                                                                        leftValue={{
                                                                            label: searchComponent
                                                                                .units[0],
                                                                            value: searchComponent
                                                                                .units[0],
                                                                        }}
                                                                        // onChangeLeftValue={setSelectedOption}
                                                                        leftWidth="30%"
                                                                        rightComponent="text"
                                                                        // rightOptions={ productCategories ? productCategories.map(pc => {
                                                                        //   return {
                                                                        //     value: pc.ID, label: pc.ProductCategory.Category
                                                                        //   }
                                                                        // }) : []}
                                                                        // rightValue={searchKey}
                                                                        // onChangeRightValue={(value) => {
                                                                        //   if(selectedOption&&selectedOption.value==="category") {
                                                                        //     setSelectedCategory(value)
                                                                        //   } else {
                                                                        //     handleSetSearckKey(value)
                                                                        //   }
                                                                        // }}
                                                                        rightWidth="70%"
                                                                        style={{
                                                                            width: "160px",
                                                                        }}
                                                                    />,
                                                                ],
                                                            },
                                                            {
                                                                text: "Between",
                                                                inputs: [
                                                                    <InputGroup
                                                                        leftComponent="select"
                                                                        leftOptions={searchComponent.units.map(
                                                                            (
                                                                                unit
                                                                            ) => {
                                                                                return {
                                                                                    label: unit,
                                                                                    value: unit,
                                                                                };
                                                                            }
                                                                        )}
                                                                        leftValue={{
                                                                            label: searchComponent
                                                                                .units[0],
                                                                            value: searchComponent
                                                                                .units[0],
                                                                        }}
                                                                        // onChangeLeftValue={setSelectedOption}
                                                                        leftWidth="30%"
                                                                        rightComponent="text"
                                                                        // rightOptions={ productCategories ? productCategories.map(pc => {
                                                                        //   return {
                                                                        //     value: pc.ID, label: pc.ProductCategory.Category
                                                                        //   }
                                                                        // }) : []}
                                                                        // rightValue={searchKey}
                                                                        // onChangeRightValue={(value) => {
                                                                        //   if(selectedOption&&selectedOption.value==="category") {
                                                                        //     setSelectedCategory(value)
                                                                        //   } else {
                                                                        //     handleSetSearckKey(value)
                                                                        //   }
                                                                        // }}
                                                                        rightWidth="70%"
                                                                        style={{
                                                                            width: "160px",
                                                                        }}
                                                                    />,
                                                                    <br />,
                                                                    <Label>
                                                                        And
                                                                    </Label>,
                                                                    <br />,
                                                                    <InputGroup
                                                                        leftComponent="select"
                                                                        leftOptions={searchComponent.units.map(
                                                                            (
                                                                                unit
                                                                            ) => {
                                                                                return {
                                                                                    label: unit,
                                                                                    value: unit,
                                                                                };
                                                                            }
                                                                        )}
                                                                        leftValue={{
                                                                            label: searchComponent
                                                                                .units[0],
                                                                            value: searchComponent
                                                                                .units[0],
                                                                        }}
                                                                        // onChangeLeftValue={setSelectedOption}
                                                                        leftWidth="30%"
                                                                        rightComponent="text"
                                                                        // rightOptions={ productCategories ? productCategories.map(pc => {
                                                                        //   return {
                                                                        //     value: pc.ID, label: pc.ProductCategory.Category
                                                                        //   }
                                                                        // }) : []}
                                                                        // rightValue={searchKey}
                                                                        // onChangeRightValue={(value) => {
                                                                        //   if(selectedOption&&selectedOption.value==="category") {
                                                                        //     setSelectedCategory(value)
                                                                        //   } else {
                                                                        //     handleSetSearckKey(value)
                                                                        //   }
                                                                        // }}
                                                                        rightWidth="70%"
                                                                        style={{
                                                                            width: "160px",
                                                                        }}
                                                                    />,
                                                                ],
                                                            },
                                                        ],
                                                    },
                                                ]}
                                                placement="topLeft"
                                                action={(text) => alert(text)}
                                            >
                                                <Button
                                                    category="text-icon"
                                                    variant="default"
                                                    text="Select..."
                                                    icon="chevron-down"
                                                    size="medium"
                                                    iconSize={20}
                                                    customStyles={{
                                                        borderColor: "#ccc",
                                                    }}
                                                />
                                            </SearchableMenu>
                                        </StackItem>
                                    );
                                } else if (
                                    searchComponent.type === "date-range-select"
                                ) {
                                    return (
                                        <StackItem style={{ marginRight: 20 }}>
                                            <RangePicker />
                                        </StackItem>
                                    );
                                }
                            }
                        })}

                        <StackItem style={{ marginRight: 20 }}>
                            <SearchableMenu
                                groupedItems={[
                                    {
                                        items: advancedSearchComponents.map(
                                            (searchComponent) => ({
                                                checkBox: true,
                                                text: searchComponent.title,
                                                checked:
                                                    searchComponent.isVisible,
                                            })
                                        ),
                                    },
                                ]}
                                placement="topLeft"
                                isSearchable={true}
                                action={(selectedItems) => {
                                    const selectedComponents =
                                        advancedSearchComponents.map(
                                            (searchComponent) => {
                                                return {
                                                    ...searchComponent,
                                                    isVisible:
                                                        selectedItems.includes(
                                                            searchComponent.title
                                                        ),
                                                };
                                            }
                                        );
                                    setAdvancedSearchComponents(
                                        selectedComponents
                                    );
                                }}
                            >
                                <Button
                                    category="icon"
                                    variant="default"
                                    children="filter"
                                    size="medium"
                                    iconSize={20}
                                    className="eco-button--special"
                                    color="white"
                                    disabled={true}
                                />
                            </SearchableMenu>
                        </StackItem>
                        <StackItem className="eco-divider-l">
                            <Button
                                category="text"
                                children="Clear All"
                                variant="subtle"
                                onClick={() => {
                                    setSelectedOption("");
                                    handleSetSearckKey("");

                                    const selectedComponents =
                                        advancedSearchComponents.map(
                                            (searchComponent) => {
                                                return {
                                                    ...searchComponent,
                                                    isVisible: false,
                                                };
                                            }
                                        );
                                    setAdvancedSearchComponents(
                                        selectedComponents
                                    );
                                }}
                            />
                        </StackItem>
                    </Stack>
                    <Stack>
                        <StackItem className="mr-12">
                            <Button
                                category="icon"
                                variant="primary"
                                children="plus"
                                size="small"
                                iconSize={20}
                                color="white"
                                onClick={(e) => {
                                    setAddMenuPopoverTriggerEvent(e);
                                    toggleAddMenu(true);
                                }}
                                onMouseLeave={() => {
                                    toggleAddMenu(false);
                                }}
                            />
                            {/* <Dropdown overlay={menu} type="primary">
                <Button variant="primary">
                  Add <DownOutlined />
                </Button>
              </Dropdown> */}
                        </StackItem>
                        <Popover
                            position="bottom-right"
                            popoverTriggerEvent={actionMenuPopoverTriggerEvent}
                            active={showProductsActionMenu}
                            showHidePopover={toggleActions}
                            type="basic"
                            customStyles={{ width: "128px" }}
                        >
                            <ActionList
                                width={160}
                                items={[
                                    {
                                        key: 4,
                                        prefix: (
                                            <Icon name="pencil" size={16} />
                                        ),
                                        content: "Edit",
                                        onClick: () => {
                                            viewEditProductPanel(
                                                selectedProducts
                                            );
                                        },
                                    },
                                    {
                                        key: 5,
                                        prefix: (
                                            <Icon name="trashcan" size={16} />
                                        ),
                                        content: "Delete",
                                        // onClick: () => { setShowDeleteConfirmPopupIndex(true), toggleActiomMenu(false); }
                                        // onClick: (e) => confirmDeleteProduct(e, selectedProducts)
                                        onClick: () => {
                                            setShowDeleteConfirmPopupIndex(
                                                true
                                            ),
                                                toggleActions(false);
                                        },
                                    },
                                    {
                                        key: 6,
                                        prefix: <Icon name="user" size={16} />,
                                        content: "Log",
                                    },
                                ]}
                                type="action"
                            />

                            <ConfirmationPopup
                                modalVisible={showDeleteConfirmPopupIndex}
                                onSuccess={(e) =>
                                    confirmDeleteProduct(e, selectedProducts)
                                }
                                onCancel={onCancelDeleteConfirmPopup}
                                confirmationMessage={`Are you sure you want to delete this product? (This product may have already existing stock quantity.)`}
                                okText="Yes"
                                cancelText="No"
                            />
                        </Popover>

                        <StackItem className="mr-12">
                            <Button
                                category="icon"
                                variant="default"
                                children="settings"
                                size="small"
                                iconSize={20}
                                onClick={(e) => {
                                    setPrintMenuPopoverTriggerEvent(e);
                                    togglePrintMenu(true);
                                }}
                                onMouseLeave={() => {
                                    togglePrintMenu(false);
                                }}
                            />
                        </StackItem>
                        <StackItem>
                            <Button
                                category="icon"
                                variant="default"
                                children="ellipsis"
                                size="small"
                                iconSize={20}
                                onClick={(e) => {
                                    setDownloadMenuPopoverTriggerEvent(e);
                                    toggleDownloadMenu(true);
                                }}
                                onMouseLeave={() => {
                                    toggleDownloadMenu(false);
                                }}
                            />
                        </StackItem>
                        <Popover
                            position="bottom-right"
                            popoverTriggerEvent={addMenuPopoverTriggerEvent}
                            active={showAddMenu}
                            showHidePopover={toggleAddMenu}
                            type="basic"
                            customStyles={{ marginTop: 8 }}
                        >
                            <ActionList
                                width={160}
                                items={
                                    process.env.NODE_ENV !== "production"
                                        ? [
                                              {
                                                  key: 1,
                                                  prefix: (
                                                      <Icon
                                                          name="tag"
                                                          size={16}
                                                      />
                                                  ),
                                                  content: "Products",
                                                  onClick: () => {
                                                      setEnableProductAddMode(
                                                          true
                                                      ),
                                                          toggleAddMenu(false);
                                                  },
                                              },
                                              {
                                                  key: 2,
                                                  prefix: (
                                                      <Icon
                                                          name="tag"
                                                          size={16}
                                                      />
                                                  ),
                                                  content: "Offers",
                                                  onClick: {},
                                              },
                                              {
                                                  key: 3,
                                                  prefix: (
                                                      <Icon
                                                          name="tag"
                                                          size={16}
                                                      />
                                                  ),
                                                  content: "Category",
                                                  onClick: () => {},
                                              },
                                              {
                                                  key: 4,
                                                  prefix: (
                                                      <Icon
                                                          name="tag"
                                                          size={16}
                                                      />
                                                  ),
                                                  content: "Brand",
                                                  // onClick: () => { handleLoadBrandView() }
                                              },
                                          ]
                                        : [
                                              {
                                                  key: 1,
                                                  prefix: (
                                                      <Icon
                                                          name="tag"
                                                          size={16}
                                                      />
                                                  ),
                                                  content: "Products",
                                                  onClick: () => {
                                                      setEnableProductAddMode(
                                                          true
                                                      ),
                                                          toggleAddMenu(false);
                                                  },
                                              },
                                              {
                                                  key: 3,
                                                  prefix: (
                                                      <Icon
                                                          name="tag"
                                                          size={16}
                                                      />
                                                  ),
                                                  content: "Category",
                                                  onClick: () => {},
                                              },
                                              {
                                                  key: 4,
                                                  prefix: (
                                                      <Icon
                                                          name="tag"
                                                          size={16}
                                                      />
                                                  ),
                                                  content: "Brand",
                                                  // onClick: () => { handleLoadBrandView() }
                                              },
                                          ]
                                }
                                type="action"
                            />
                        </Popover>
                    </Stack>
                </Stack>
            </div>

            <Table
                columns={columnData}
                dataSource={displayData}
                loading={loadingProducts}
                // pagination={{ pageSize: 10 }}
            />
            <Popover
                position="bottom-right"
                popoverTriggerEvent={printMenuPopoverTriggerEvent}
                active={showPrintMenuPopover}
                type="basic"
                customStyles={{ marginTop: 8 }}
            >
                <ActionList
                    width={160}
                    items={[
                        {
                            key: 1,
                            prefix: <Icon name="tag" size={16} />,
                            content: "Label Print",
                            onClick: {},
                        },
                        {
                            key: 2,
                            prefix: <Icon name="trashcan" size={16} />,
                            content: "Delete",
                            onClick: {},
                        },
                    ]}
                    type="action"
                />
            </Popover>
            <Popover
                position="bottom-right"
                popoverTriggerEvent={downloadMenuPopoverTriggerEvent}
                active={showDownloadMenuPopover}
                type="basic"
                customStyles={{ marginTop: 8 }}
            >
                <ActionList
                    width={160}
                    sections={[
                        {
                            title: "Upload",
                            items: [
                                {
                                    key: 1,
                                    prefix: <Icon name="file-csv" size={16} />,
                                    content: "CSV File",
                                    onClick: {},
                                },
                            ],
                        },
                        {
                            title: "Download",
                            items: [
                                {
                                    key: 1,
                                    prefix: <Icon name="file-csv" size={16} />,
                                    content: "CSV File",
                                    onClick: {},
                                },
                                {
                                    key: 2,
                                    prefix: <Icon name="file-xls" size={16} />,
                                    content: "XLS file",
                                    onClick: {},
                                },
                            ],
                        },
                    ]}
                    type="action"
                />
            </Popover>
            <RightPanel
                id={"BasicInfoForm"}
                active={showAddOrEditPanel}
                size="small"
                outsideClose={false}
                handleOnClose={hideAddOrEditProductPanel}
                headerContent={addProductPanelHeader}
            >
                <Space direction="vertical" style={{ width: "100%" }}>
                    {(missingBasicInfoMessage || missingPriceInfoMessage) && (
                        <Alert
                            message="Required Fields Missing!"
                            showIcon
                            description={`
            ${missingBasicInfoMessage}
            ${missingPriceInfoMessage}
          `}
                            type="error"
                            closable
                            action={
                                <Button
                                    category="text"
                                    variant="link"
                                    children="Locate me..."
                                    size="small"
                                    // color="red"
                                    onClick={locateError}
                                />
                            }
                        />
                    )}

                    {apiValidationError ? (
                        <InlineMessage
                            message={apiValidationError.message}
                            type="alert"
                            variant="error"
                            close={true}
                        />
                    ) : null}
                    <Accordion
                        theme="default"
                        type="content_only"
                        title="Basic Information"
                        collapsed={!showBasicInfoPanel}
                    >
                        <div ref={basicInfoPanel} style={{ width: "100%" }}>
                            <BasicInformationForm
                                basicInformationIValues={
                                    basicInformationIValues
                                }
                                basicInformationForm={basicInformationForm}
                                onFinish={onFinishBasic}
                                onFinishFailed={onFinishBasicFailed}
                                handleChangeBasicInfoInputs={
                                    handleChangeBasicInfoInputs
                                }
                                productCategories={productCategories}
                                brands={brands}
                                orgDetails={dashboardState}
                                validations={basicInfoValidationErrors}
                                locationDetails={
                                    props?.selectedLocation?.locationDetails ||
                                    null
                                }
                                enableProductEditMode={enableProductEditMode}
                                reloadDataInMainCategory={
                                    reloadDataInMainCategory
                                }
                                sortResultsFromCreatedDate={
                                    sortResultsFromCreatedDate
                                }
                                allproductData={rowData}
                            />
                        </div>
                    </Accordion>

                    <Accordion
                        theme="default"
                        type="content_only"
                        title="Price Information"
                        collapsed={!showPriceInfoPanel}
                    >
                        <div ref={priceInfoPanel} style={{ width: "100%" }}>
                            <PriceInformationForm
                                priceInformationValues={priceInformationValues}
                                priceInformationForm={priceInformationForm}
                                onFinish={onFinishPrice}
                                onFinishFailed={onFinishPriceFailed}
                                unitTypes={unitTypes}
                                handleSetUnitChart={handleSetUnitChart}
                                handleChangePriceInfoInputs={
                                    handleChangePriceInfoInputs
                                }
                                validations={priceInfoValidationErrors}
                                unitChartInfo={unitChartInfo}
                                currencySettings={currencySettingsState}
                                locationDetails={
                                    props?.selectedLocation?.locationDetails ||
                                    null
                                }
                                enableProductEditMode={enableProductEditMode}
                            />
                        </div>
                    </Accordion>

                    <Accordion
                        theme="default"
                        type="content_only"
                        title="Images"
                        collapsed={true}
                    >
                        <div style={{ width: "100%" }}>
                            <ProductImagesForm
                                unitTypes={unitTypes}
                                handleOnlineImageList={handleOnlineImageList}
                                currentOrgName={
                                    dashboardState.currentlySelectedLocationName
                                }
                                basicInfo={
                                    basicInformationIValues
                                        ? basicInformationIValues
                                        : {}
                                }
                                customAttributes={
                                    customAttributesObj
                                        ? customAttributesObj
                                        : {}
                                }
                                onlineImages={
                                    onlineImagesObj ? onlineImagesObj : {}
                                }
                                locationDetails={
                                    props?.selectedLocation || null
                                }
                                forceResetImages={forceResetImages}
                            />
                        </div>
                    </Accordion>

                    <Accordion
                        theme="default"
                        type="content_only"
                        title="Ecommerce Configurations"
                        collapsed={true}
                    >
                        <div style={{ width: "100%" }}>
                            <EcommerceConfigurations
                                ecommerceConfigurationsItitialValues={
                                    ecommerceConfigurationsItitialValues
                                }
                                handleChangeEcomConfigInputs={
                                    handleChangeEcomConfigInputs
                                }
                                customAttributes={customAttributesObj}
                                productTagsValue={productTagsValue}
                            />
                        </div>
                    </Accordion>

                    <Accordion
                        theme="default"
                        type="content_only"
                        title="Secondary Barcode"
                        collapsed={true}
                    >
                        <div style={{ width: "100%" }}>
                            <SecondaryBarcodeForm />
                        </div>
                    </Accordion>

                    <Accordion
                        theme="default"
                        type="content_only"
                        title="Custom Attributes"
                        collapsed={true}
                    >
                        <div style={{ width: "100%" }}>
                            <CustomAttributesForm
                                customAttributesInitialValues={{}}
                                customAttributesForm={customAttributesForm}
                                onFinish={onFinishCustomAttributes}
                                onFinishFailed={onFinishCustomAttributesFailed}
                                customAttributes={customAttributesObj}
                                handleChangeCustomAttributesInputs={
                                    handleChangeCustomAttributesInputs
                                }
                            />
                        </div>
                    </Accordion>
                </Space>
            </RightPanel>
            <RightPanel
                active={showProductOverviewPanel}
                size="small"
                outsideClose={false}
                handleOnClose={hideProductOverviewPanel}
                headerContent={productOverviewPanelHeader}
            >
                {showProductOverviewPanel ? (
                    <ProductOverview
                        product={
                            rowData
                                ? rowData.find(
                                      (rd) => rd.ID === selectedProductId
                                  )
                                : []
                        }
                        locationId={
                            props.selectedLocation.locationDetails.LocationID
                        }
                        OrgID={
                            props.selectedLocation.organizationDetails
                                .OrganizationID
                        }
                        // categoryInfo={productCategories && rowData && productCategories.find(pc => pc.ID === rowData.find(rd => rd.ProductRefNo===selectedProductId).CloudProductCategoryID)}
                    />
                ) : null}
            </RightPanel>

            <RightPanel
                active={showProductBrandPanel}
                size="small"
                outsideClose={false}
                handleOnClose={hideProductBrandPanel}
                headerContent={productBrandPanelHeader}
                className="brand"
                style={{ width: "100%", objectfit: "contain " }}
            >
                <ProductBrand
                    brands={brands}
                    addNewBrand={addNewBrand}
                    hideAddBrandCard={hideAddBrandCard}
                    showAddBrandCard={showAddBrandCard}
                    showAddCard={showAddCard}
                />
            </RightPanel>
        </div>
    );
};

export default ProductPage;
