import React from 'react';
import SubCategoryTable from './SubCategoryTable';

export default function SubCategoryInformationForm(props) {
	const { subCategoryInformationValues, handleSetSubCategories } =
		props;

	return (
		<div>
			<SubCategoryTable
				subCategoryInformationValues={
					subCategoryInformationValues
				}
				handleSetSubCategories={handleSetSubCategories}
			/>
		</div>
	);
}
