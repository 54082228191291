import { Button } from 'antd';
import React from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';

import './ImageViewer.css';

// Setting up Add-On functionality that used in "Swiper" NPM Module Component.
SwiperCore.use([Navigation, Pagination]);

class ImageViewer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isFullSizeMode: false,
			fullSizeImageIndex: 0,
		};
	}

	toggleFullSizeMode() {
		const { isFullSizeMode } = this.state;

		this.setState({
			isFullSizeMode: !isFullSizeMode,
		});
	}

	setFullSizeImageIndex(index) {
		this.setState({
			fullSizeImageIndex: index,
		});
	}

	render() {
		const {
			imageList = [],
			viewerMode = 'SWIPER', // Available Options "SWIPER", "GRID"

			// This props is used to give ability to wrap thumbnail image with custom component, So caller can add additional functionality around it.
			ThumbnailImgWrapperComp = (props) => {
				return props.children;
			},
		} = this.props;
		const { isFullSizeMode, fullSizeImageIndex } = this.state;

		return (
			<div className='ImageViewer'>
				<div className='ImageViewer__generalInfo'>
					{/* When No Images Available */}
					{imageList.length === 0 ? (
						<div className='ImageViewer__generalInfo__noImages'>
							No Image Available.
						</div>
					) : (
						''
					)}
				</div>

				{/* When in Swiper Mode */}
				{viewerMode === 'SWIPER' && (
					<div className='ImageViewer__swiperMode'>
						{imageList.length > 0 && (
							<Swiper
								spaceBetween={50}
								slidesPerView={isFullSizeMode ? 1 : 3}
								navigation
								roundLengths
								pagination={{ clickable: true }}
								breakpoints={{
									// when window width is 200-320px
									200: {
										slidesPerView: 1,
									},
									// when window width is 320-600px
									320: {
										slidesPerView: 2,
									},
									// when window width is >= 600px
									600: {
										slidesPerView: 3,
									},
								}}>
								{imageList.map((img, imgIndex) => {
									return (
										<React.Fragment key={`${img.imgUrl}--swiperSlide`}>
											<SwiperSlide key={`${img.imgUrl}--swiperSlide`}>
												<ThumbnailImgWrapperComp clickedImageDetails={img}>
													<div
														className='ImageViewer____slideContainer'
														onClick={() => {
															this.setFullSizeImageIndex(imgIndex);
															this.toggleFullSizeMode();
														}}>
														<div>
															<div className='ImageViewer____slideContainer____imageContainer'>
																<img
																	src={img.imgUrl}
																	alt={img.imgTitle}
																	title={img.imgTitle}
																/>
															</div>
														</div>
													</div>
												</ThumbnailImgWrapperComp>
											</SwiperSlide>
										</React.Fragment>
									);
								})}
							</Swiper>
						)}
					</div>
				)}

				{/* When in Grid Mode */}
				{viewerMode === 'GRID' && (
					<div className='ImageViewer__gridMode'>
						{imageList.map((img, imgIndex) => {
							return (
								<ThumbnailImgWrapperComp
									clickedImageDetails={img}
									key={`${img.imgUrl}--gridMode`}>
									<div
										className='ImageViewer____slideContainer'
										onClick={() => {
											if (!isFullSizeMode) {
												this.setFullSizeImageIndex(imgIndex);
												this.toggleFullSizeMode();
											}
										}}>
										<img
											src={img.imgUrl}
											alt={img.imgTitle}
											title={img.imgTitle}
										/>
									</div>
								</ThumbnailImgWrapperComp>
							);
						})}
					</div>
				)}

				{/* When Full Screen Mode Invoked - Showing Image In Full Size */}
				{isFullSizeMode && (
					<div className='ImageViewer__fullSizeMode'>
						<Button
							className='ImageViewer__fullSizeMode__closeButton'
							onClick={() => this.toggleFullSizeMode()}>
							X
						</Button>

						<Swiper
							spaceBetween={500}
							slidesPerView={1}
							navigation
							roundLengths
							pagination={{ clickable: true }}
							initialSlide={fullSizeImageIndex}>
							{imageList.map((img) => {
								return (
									<React.Fragment key={`${img.imgUrl}--fullSizeMode`}>
										<SwiperSlide key={`${img.imgUrl}--fullSizeMode`}>
											<div
												className='ImageViewer____slideContainer'
												onClick={() => {
													if (!isFullSizeMode) {
														this.toggleFullSizeMode();
													}
												}}>
												<div>
													<div className='ImageViewer____slideContainer____imageContainer'>
														<img
															src={img.imgUrl}
															alt={img.imgTitle}
															title={img.imgTitle}
														/>
													</div>
													<div className='ImageViewer____slideContainer___infoSection'>
														{isFullSizeMode && <div>{img.imgTitle}</div>}
													</div>
												</div>
											</div>
										</SwiperSlide>
									</React.Fragment>
								);
							})}
						</Swiper>
					</div>
				)}
			</div>
		);
	}
}

export default ImageViewer;
