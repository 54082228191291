import React, { useState, useEffect, useRef } from 'react'
import { Progress } from 'antd';

import axios from "axios";
import lodash from 'lodash';
import { Checkbox, Select, Space, Typography, Card, Stack, StackItem, Icon, Button, Row, Col, InputBox, Label, FileUploader, Popup, ProgressBar } from 'witmeg-ui-system';
import Resizer from "react-image-file-resizer";
import { toBlob } from 'html-to-image';
import globalValues from '../../../../../../configs/globalValues';
import { generateToken__WitmegEcoServicesServer } from '../../../../../../utilities/tokenGenerators';
import defaultImage from '../../../../../../assets/img/default.jpg'
import 'react-image-crop/dist/ReactCrop.css'


export default function ProductImagesForm(props) {
	const [selectedOption, setSelectedOption] = useState('PrimaryImages');
	const [defaultFileList, setDefaultFileList] = useState([]);
	const [uploadedImageUrls, setUploadedImageUrls] = useState([]);
	const [selectedImage, setSelectedImage] = useState(null);
	const [processedImage, setProcessedImage] = useState(null)
	const [showImageCropPopup, setShowImageCropPopup] = useState(false)
	const [progress, setProgress] = useState(0);
	const [crop, setCrop] = useState(null)
	const [imageQuality, setImageQuality] = useState(1)

	// const canvas = useRef();

	// useEffect(() => {
	//     const context = canvas.getContext('2d'); 
	//     // draw(context);
	//     context.drawImage(processedImage.preview, 0, 0, this.canvasA.width, this.canvasA.height);

	// }, [processedImage])


	// useEffect(() => {
	//     if(uploadedImageUrls.length>0) {
	//         props.handleOnlineImageList(uploadedImageUrls)
	//     }
	// }, [uploadedImageUrls])

	useEffect(() => {
		if (props.forceResetImages) {
			setDefaultFileList([])
			setUploadedImageUrls([])
			setProgress(0)
		}
	}, [props.forceResetImages])


	useEffect(() => {
		if (props.onlineImages && Object.keys(props.onlineImages).length > 0) {
			// setDefaultFileList(Object.keys(props.onlineImages.PrimaryImages).map(pim => {
			//     return {
			//         thumbUrl: props.onlineImages.PrimaryImages[pim]
			//     }
			// }))

			setUploadedImageUrls([
				...props.onlineImages.PrimaryImages ? Object.keys(props.onlineImages.PrimaryImages).map(pim => {
					return {
						id: Math.floor(100000 + Math.random() * 900000),
						imageUrl: props.onlineImages.PrimaryImages[pim],
						imageType: 'PrimaryImages',
						key: 'EA',
						attrName: null
					}
				}) : [],
				...props.onlineImages.UnitChartImages ? Object.keys(props.onlineImages.UnitChartImages).map(pim => {
					return {
						id: Math.floor(100000 + Math.random() * 900000),
						imageUrl: props.onlineImages.UnitChartImages[pim],
						imageType: 'UnitChartImages',
						key: pim,
						attrName: null
					}
				}) : [],
				...props.onlineImages.SpecialImages ? Object.keys(props.onlineImages.SpecialImages).map(pim => {
					return {
						id: Math.floor(100000 + Math.random() * 900000),
						imageUrl: props.onlineImages.SpecialImages[pim][0].ImgURL,
						imageType: 'SpecialImages',
						key: 'EA',
						attrName: {
							value: props.onlineImages.SpecialImages[pim][0].Value,
							label: props.onlineImages.SpecialImages[pim][0].Value
						}
					}
				}) : []
			]);
		} else if (lodash.isEmpty(props.onlineImages)) {
			setUploadedImageUrls([])
		}
	}, [props.onlineImages])



	const options = [
		{ value: 'PrimaryImages', label: 'Primary Images' },
		{ value: 'UnitChartImages', label: 'Unit Chart Images' },
		{ value: 'SpecialImages', label: 'Special Images' },
	];

	const onImageUploaderSuccessFn = (data) => {
		console.log("onImageUploaderSuccessFn====>>", data)
	}

	const handleDeleteImage = (index) => {
		let imageList = uploadedImageUrls.filter((df, i) => i !== index)
		setDefaultFileList(imageList)
		setUploadedImageUrls(imageList)
		props.handleOnlineImageList(imageList);
	}

	const handleProcessImage = async (file) => {
		console.log("file=====>>>", file)
		setSelectedImage(file)
		setShowImageCropPopup(true)
		let uploadingImage = file.selectedFile;
		const image = await resizeImage(uploadingImage, 500, 500, "JPEG")
		console.log("image=====>>>", image)
		setProcessedImage(image)
	}

	const uploadImage = async (file) => {
		console.log("file=====>>>", file)
		const fmData = new FormData();
		const timeStamp = Math.round(new Date().getTime() / 1000);
		const environmentFolderName =
			globalValues.environment.CURRENT_ENVIRONMENT === 'production'
				? 'production'
				: 'dev';
		const fileName = `P_${props.basicInfo.barcode}_${timeStamp}`;
		const filePath = `/${environmentFolderName}/${props.locationDetails.organizationDetails.OrganizationID}/erp/`; //

		fmData.append("file", file);
		fmData.append('fileName', fileName);
		fmData.append('filePath', filePath);
		try {
			const config = {
				headers: {
					'Access-Control-Allow-Origin': '*',
					'Authorization': `Bearer ${generateToken__WitmegEcoServicesServer()}`,
				},
			};
			const res = await axios.post(
				// "http://35.228.251.106:14501/v1/image/uploadImage",
				`${globalValues.baseURLS.REACT_APP__WITMEG_IMAGEUPLOADSERVER___BASEURL}/v1/image/uploadImage`,
				fmData,
				config
			);

			console.log("server res: ", res);
			setUploadedImageUrls([
				...uploadedImageUrls,
				{
					id: Math.floor(100000 + Math.random() * 900000),
					imageUrl: res.data.Result,
					imageType: 'PrimaryImages',
					key: 'EA',
					attrName: null
				}
			]);
			props.handleOnlineImageList([
				...uploadedImageUrls,
				{
					id: Math.floor(100000 + Math.random() * 900000),
					imageUrl: res.data.Result,
					imageType: 'PrimaryImages',
					key: 'EA',
					attrName: null
				}
			])



		} catch (err) {
			console.log("Eroor: ", err);
			const error = new Error("Some error");
			// onError({ err });
		}
	};

	const handleSetImageType = (e, i) => {
		let data = uploadedImageUrls.map((url, j) => {
			return {
				id: url.id,
				imageUrl: url.imageUrl,
				imageType: i === j ? e.value : url.imageType,
				key: url.key,
				attrName: url.attrName
			}
		});
		setUploadedImageUrls(data)
		props.handleOnlineImageList(data)
	}

	const handleSetUnitType = (e, i) => {
		let data = uploadedImageUrls.map((url, j) => {
			return {
				id: url.id,
				imageUrl: url.imageUrl,
				imageType: url.imageType,
				key: i === j ? e.label : url.key,
				attrName: url.attrName
			}
		})
		setUploadedImageUrls(data);
		props.handleOnlineImageList(data);
	}

	const handleSpecialImage = (e, i) => {
		setUploadedImageUrls(uploadedImageUrls.map((url, j) => {
			return {
				id: url.id,
				imageUrl: url.imageUrl,
				imageType: url.imageType,
				key: url.key,
				attrName: i === j ? e.label : url.attrName,
			}
		}))
		props.handleOnlineImageList(data);
	}


	const handleOnChange = ({ file, fileList, event }) => {
		// console.log(file, fileList, event);
		//Using Hooks to update the state to the current filelist
		setDefaultFileList(fileList);
		//filelist - [{uid: "-1",url:'Some url to image'}]
	};

	const resizeImage = async (uploadingImage, width, height, fileFormat) => {
		return new Promise((resolve) => {
			Resizer.imageFileResizer(
				uploadingImage,
				width,
				height,
				fileFormat,
				100,
				0,
				(uri) => {
					resolve(uri);
				},
				"file"
			);
		});
	}

	const handleGenerareImage = async () => {
		const element = document.getElementById('capture')
		toBlob(element, { quality: imageQuality, backgroundColor: '#fff', width: 500, height: 500 })
			.then(async function (blob) {
				await uploadImage(blob)
				setShowImageCropPopup(false)
			})
			.catch(function (error) {
				console.error('oops, something went wrong!', error);
			});
	}


	return (
		<>
			<div id="image_upload_container">
				{/* <Dragger
                accept="image/*"
                customRequest={uploadImage}
                onChange={handleOnChange}
                listType="picture-card"
                defaultFileList={defaultFileList}
                style={{ width: '100%' }}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                    band files
                </p>
            </Dragger> */}
				<FileUploader multiple={false} onUpload={handleProcessImage} acceptFiles="image/*" maxSize={25} />
				<br />
				<div>
					<Typography type="title" level={4}>Manage Uploads</Typography>
					<br />
					{uploadedImageUrls && uploadedImageUrls.map((fl, i) => {
						return (
							<Card
								shadow="default"
								disabled={false}
								type={'complex'}
								content={
									<Stack className="eco-pa-4" direction='row' horizontalAlign='space_around' verticalAlign='v_center'>
										<StackItem style={{ width: '100px', height: '100px', padding: '8px' }}>
											<img src={fl.imageUrl ? fl.imageUrl : defaultImage} alt="Product Images" style={{ width: '100%' }} />
										</StackItem>
										<StackItem style={{ flexGrow: '1', height: '55px', padding: '10px' }}>
											<div>
												<div>
													<Select
														value={fl ?
															{ value: fl.imageType, label: fl.imageType }
															: { value: 'PrimaryImages', label: 'PrimaryImages' }
														}
														onChange={(e) => handleSetImageType(e, i)}
														options={options}
													/>

												</div>
												{
													uploadedImageUrls && uploadedImageUrls[i] && uploadedImageUrls[i].imageType === "UnitChartImages" ?
														<div style={{ marginTop: '8px' }}>
															<Select
																// value={selectedOption}
																value={uploadedImageUrls[i].key ? { value: uploadedImageUrls[i].key, label: uploadedImageUrls[i].key } : null}
																onChange={(e) => handleSetUnitType(e, i)}
																options={
																	props.unitTypes.map((utm) => {
																		return {
																			value: utm.ID,
																			label: utm.UnitTypeName,
																		}
																	})
																}
															/>
														</div>
														: uploadedImageUrls && uploadedImageUrls[i] && uploadedImageUrls[i].imageType === "SpecialImages" ?
															<div style={{ marginTop: '8px' }}>
																<Select
																	value={uploadedImageUrls[i].attrName ? { value: uploadedImageUrls[i].attrName } : null}
																	onChange={(e) => handleSpecialImage(e, i)}
																	options={
																		props.customAttributes && Object.keys(props.customAttributes) && Object.keys(props.customAttributes).map((utm) => {
																			return {
																				value: utm,
																				label: utm,
																			}
																		})
																	}
																/>
															</div>
															: null
												}
											</div>
										</StackItem>
										<Stack width='300' horizontalAlign='space_evenly'>
											<StackItem style={{ flexGrow: '5', padding: '8px', justifyContent: 'center', display: 'flex', alignItems: 'center', width: '100px' }}>
												<ProgressBar type="circle" value={i === defaultFileList.length - 1 ? progress : 100} style={{ marginRight: '10%', width: '20px' }} width={80} />
											</StackItem>
											<StackItem style={{ height: '100px', padding: '8px', justifyContent: 'flex-end', display: 'flex', alignItems: 'center' }}>
												<Button category='link' onClick={() => handleDeleteImage(i)} customStyles={{ border: 'none' }}>
													<Icon name='trashcan' color='red' />
												</Button>
											</StackItem>
										</Stack>

									</Stack>}
								width="100%"
								height="fit-content"
							/>
						)
					})}


					{/* <div style={{marginTop:'12px'}}>
                    <Row>
                        <Col span={3}>
                            <div style={{width:'100%', height:'80px', backgroundColor:'#bbb', padding:'8px'}}>
                                Preview
                            </div>
                        </Col>
                        <Col span={15}>
                        
                            <div style={{paddingLeft: '8px'}}>
                            <div>
                                <Checkbox checked={true}>Use As Primary Image</Checkbox>
                            </div>
                            <br />
                            <div>
                                <Select
                                    value={selectedOption}
                                    onChange={setSelectedOption}
                                    options={options}
                                />
                            </div>
                            </div>

                        </Col>
                        <Col span={6}>

                        </Col>
                    </Row>
                </div> */}

				</div>
			</div>
			<Popup
				modalTitle={<h3>Image Upload</h3>}
				visible={showImageCropPopup}
				onCancel={() => setShowImageCropPopup(false)}
				footer={<Space>
					<Button onClick={handleGenerareImage} variant="primary" size="small">Done</Button>
					<Button onClick={() => setShowImageCropPopup(false)} variant="secondary" className="eco-mr-8" size="small">Cancel</Button>
				</Space>
				}
				// footer={[
				//   <Button onClick={onRedoStep} variant="secondary" className="eco-mr-8" size="small">Back</Button>,
				//   <Button onClick={onCompleteStep} variant="primary" size="small">Next</Button>
				// ]}
				style={{ height: '800px', width: '800px' }}
				width={550}
			>
				<div>
					<div style={{ width: '504px', height: '504px', border: '2px solid #666' }}>
						{processedImage ?
							<div id="capture" style={{ width: '500px', height: '500px', display: 'flex', background: "#fff" }}>

								<div style={{ display: 'flex', verticalAlign: 'middle', justifyContent: 'center', alignItems: 'center', margin: 'auto' }} >
									<div>
										<img src={URL.createObjectURL(processedImage)} />
									</div>
								</div>
							</div>
							: null
						}
					</div>
					<hr />
					<div>
						<Row className="form-row">
							<Col md={8}><div className="form-lbl-wrapper"><Label>Image Quality</Label></div></Col>
							<Col md={8}><InputBox type="number" placeholder="Ouality" minValue={0.1} maxValue={1} value={imageQuality} onChange={(e) => setImageQuality(e.target.value)} /></Col>
						</Row>
					</div>
				</div>
			</Popup>
		</>
	)
}
