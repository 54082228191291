import React from 'react';
import { Card, Stack, StackItem, Typography,Carousel } from 'witmeg-ui-system';

export default function OfferOverviewImageSlider({OfferProducts}) {
    console.log('OfferProducts', OfferProducts);
  return (
    <div>
        <Carousel slidesToShow={OfferProducts?.length < 3 ? OfferProducts?.length : 3} slidesToScroll={1}>
            {
                OfferProducts?.map((offer) => {
                    return <Card
                            key={OfferProducts.ID}
                            width={OfferProducts.length === 1 ? "35%" : OfferProducts.length === 2 ? "45" : "90%"}
                            height="300px"
                            className={`centered-card-content ${OfferProducts.length === 1 ? 'centered-card' : ''}`}
                            content={            
                                <Stack direction="column">
                                    <StackItem className="eco-mb-24">
                                        <img src={offer.ImageURL} style={{ width:'100px',height:'100px'}}/>
                                    </StackItem>
                                    <StackItem alignment="start">
                                        <Typography className="eco-typography--center" type="title" level={4}>{offer.name}</Typography>
                                    </StackItem>
                                    <StackItem alignment="start" className="eco-mb-24">
                                        <Typography className="eco-typography--center" size="small" color="gray_base">{`BARCODE: ${offer.BarCode}`}</Typography>
                                    </StackItem>
                                    <StackItem alignment="start">
                                        <Typography className="eco-typography--center" type="title" level={3} pattern="bold">{offer.Price}</Typography>
                                    </StackItem>
                                </Stack>
                            }
                        />
                })
            }
        </Carousel>
      </div>
  )
}