import { serverRequest } from './axiosInstances/serverRequest';
import { APIError, errorLogger } from '../errorHandlers';
import apiEndpoints from './apiEndpointPaths';

export async function uploadImage(reqBody = {}, options = {}) {
	try {
		/**
		 * NOTE : ReqBody must be a "multipart/form-data" data set. It should contain,
		 * 					- file : BINARY FILE
		 * 					- filePath : Folder path. Folders will be created if not exist. (Ex. /a/b/c/d). Note that folder path names should not contain whitespace.
		 * 					- fileName : Name of the file. (Extension can be excluded.)
		 */

		const { file, fileName, filePath } = reqBody;

		const formData = new FormData();
		formData.append('file', file);
		formData.append('fileName', fileName);
		formData.append('filePath', filePath);

		const apiReqBody = formData;

		const API_URL = apiEndpoints.ecoServicesServer.uploadImage;
		const resObj = await serverRequest('generateToken__WitmegEcoServicesServer', API_URL, 'POST', apiReqBody, options);		

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		// Uploaded Image as CDN Link.
		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}
