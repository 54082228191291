import React from "react";
import { Switch, Route, useRouteMatch, useParams } from "react-router-dom";

import { routePaths } from "../../routes/routeConstants";

import AppsPage from "./subPages/AppsPage/AppsPage";
import CustomersPage from "./subPages/CustomersPage/CustomersPage";
import DashboardHomePage from "./subPages/DashboardHomePage/DashboardHomePage";
import InventoryProductsPage from "./subPages/InventoryProductsPage/InventoryProductsPage";
import ProductPage from "./subPages/Inventory/Product";
import ProductAddPage from "./subPages/InventoryProductsPage/subPages/ProductAddPage/ProductAddPage";
import ProformasPage from "./subPages/ProformasPage/ProformasPage";
import PurchaseOrderPage from "./subPages/PurchaseOrderPage/PurchaseOrderPage";
import ReportsPage from "./subPages/ReportsPage/ReportsPage";
import CategoryPage from "./subPages/Inventory/Category";
import SupplierPage from "./subPages/Purchasing/Supplier";
import Brand from "./subPages/Inventory/Brand";
import OfferPage from "./subPages/Inventory/Offers";
import Msa from "./subPages/Purchasing/Msa";

const TempComp = (props = {}) => {
    const { title } = props;

    return (
        <div>
            <h1>{title}</h1>
        </div>
    );
};

export default function DashboardPageRoutes(props) {
    let match = useRouteMatch();
    let params = useParams();

    console.log("useRouteMatch", match);
    console.log("useParams", params);

    return (
        <Switch>
            {/* ***** Dashboard -> Overview ***** */}
            <Route path={routePaths.dashboard__overview}>
                <TempComp title="Overview" />
            </Route>

            {/* ***** Dashboard -> Inventory -> XXX ***** */}
            <Route path={routePaths.dashboard__inventory__products}>
                <ProductPage selectedLocation={props.selectedLocation} />
            </Route>

            <Route path={routePaths.dashboard__inventory__products__add}>
                <ProductAddPage />
            </Route>

            <Route path={routePaths.dashboard__inventory__category}>
                <CategoryPage selectedLocation={props.selectedLocation} />
            </Route>
            <Route path={routePaths.dashboard__inventory__brands}>
                <Brand selectedLocation={props.selectedLocation} />
            </Route>

            <Route path={routePaths.dashboard__inventory__offers}>
                <OfferPage selectedLocation={props.selectedLocation} />
            </Route>

            {/* <Route path={routePaths.dashboard__inventory__promotions} >
						<TempComp title='Inventory Promotions Page' />
					</Route> */}

            {/* ***** Dashboard -> Sales -> XXX ***** */}
            {/* <Route
						path={routePaths.dashboard__sales__customers}
					>
						<CustomersPage />
					</Route> */}

            {/* <Route
						path={routePaths.dashboard__sales__proformas}
					>
						<ProformasPage />
					</Route> */}

            {/* ***** Dashboard -> Purchase -> XXX ***** */}
            {/* <Route
						path={
							routePaths.dashboard__purchase__purchase_orders
						}
					>
						<PurchaseOrderPage />
					</Route> */}
            <Route path={routePaths.dashboard__purchase__msa}>
                <Msa selectedLocation={props.selectedLocation} />
            </Route>

            {/* ***** Dashboard -> Purchase -> Supplier ***** */}
            <Route path={routePaths.dashboard__purchase__supplier}>
                <SupplierPage selectedLocation={props.selectedLocation} />
            </Route>

            {/* ***** Dashboard -> Account Settings ***** */}
            {/* <Route
						path={routePaths.dashboard__accountSettings}
						
					>
						<TempComp title="Account Settings Page" />
					</Route> */}

            {/* ***** Dashboard -> Apps ***** */}
            <Route path={`${routePaths.dashboard__apps_nisa}`}>
                <AppsPage app="nisa" />
            </Route>

            <Route path={`${routePaths.dashboard__apps_nisa_promotions}`}>
                <AppsPage app="nisa-promotions" />
            </Route>

            {/* ***** Dashboard -> Reports ***** */}
            <Route path={`${routePaths.dashboard__reportView}/:boardId`}>
                <ReportsPage />
            </Route>

            {/* ***** Dashboard -> Root ***** */}
            <Route path={routePaths.dashboardRoot} exact>
                <DashboardHomePage />
            </Route>

            {/* When Given Path is Not Available */}
            {/* <Route path={routePaths.dashboardRoot}>
						<Redirect to={routePaths.dashboardRoot} />
					</Route> */}
        </Switch>
    );
}
