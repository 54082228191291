import React from 'react'
import {
    Row,
    Col,
    Stack,
    Typography,
    Icon,

} from "witmeg-ui-system";

function BrandOverview(props) {

    const {
        selectedBrandId,
        brand,
    } = props;


    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col span={10}>
                    <Stack direction="column">
                        <div style={{ width: '100%', position: 'relative' }}>
                            <Stack direction="column" style={{ height: '200px', justifyContent: 'center' }} verticalAlign="v_center">
                                <Icon name="information" />
                                <Typography>No images to preview</Typography></Stack>
                        </div>
                    </Stack>
                </Col>

                <Col span={14}>
                    <Stack horizontalAlign="space_between" direction="row" className="eco-mb-4">
                        <Typography
                            type="title"
                            level={4}
                            pattern="bold"
                        >
                            {brand ? brand.BrandName : ''}
                        </Typography>

                    </Stack>

                    <Stack >
                        <Typography style={{ height: '100px', width: '22%' }} color="gray_base" >DESCRIPTION:</Typography>
                        <Typography style={{ height: '100px', width: '70%', display: 'flex', justifyContent: 'flex-start' }} color="gray_darkest" pattern="bold" className="eco-ml-4">
                            {brand.Description}
                        </Typography>
                    </Stack>
                </Col>


            </Row>
        </div>
    )
}

export default BrandOverview