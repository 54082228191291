import React, { useState } from "react";
import {
    Stack,
    StackItem,
    Typography,
    Card,
    Icon,
    Tag,
    Row,
    Col,
    Select,
    Carousel,
} from "witmeg-ui-system";
import ProfilePicture from "../../../../../../assets/img/blank-profile-picture.png";
import Energy from "../../../../../../assets/img/energy.png";

const SupplierOverview = ({ selectedRecord, overviewOfSupplier }) => {
    const [selectedPeriod, setSelectedPeriod] = useState({
        value: 30,
        label: "Last 30 days",
        icon: "calender",
    });

    const options2 = [
        {
            options: [
                { value: 30, label: "Last 30 days", icon: "calender" },
                { value: 365, label: "Last Year", icon: "calender" },
                { value: 90, label: "Last 90 days", icon: "calender" },
                { value: 14, label: "Last 2 weeks", icon: "calender" },
                { value: 7, label: "Last week", icon: "calender" },
            ],
        },
    ];

    return (
        <div>
            <Stack direction="row" className="eco-mb-24 bordered-section">
                <StackItem>
                    <Stack direction="column" className="eco-mr-32">
                        {/* <div>image contianer</div>
                        <Typography type="text" pattern="bold">Supplier Code</Typography> */}
                        <div>
                            <img
                                src={ProfilePicture}
                                alt="Profile Picture"
                                style={{ width: "200px", height: "200px" }}
                            />
                        </div>
                    </Stack>
                </StackItem>
                <StackItem alignment="middle">
                    <Typography
                        type="title"
                        level={3}
                        pattern="bold"
                        className="eco-mb-20"
                    >
                        Supplier Name: {overviewOfSupplier.Name || "-"}
                    </Typography>

                    {overviewOfSupplier.isExternalIntegration && (
                        <Stack direction="row" className="eco-mb-8">
                            <Typography
                                type="text"
                                className="eco-mr-8"
                                color="gray_base"
                            >
                                This Supplier is Integrated With Nisa
                            </Typography>
                            <div>
                                <img
                                    src={Energy}
                                    alt="Profile Picture"
                                    style={{ width: "20px", height: "20px" }}
                                />
                            </div>
                        </Stack>
                    )}

                    <Stack direction="row" className="eco-mb-8">
                        <Typography
                            type="text"
                            className="eco-mr-8"
                            color="gray_base"
                        >
                            ADDRESS :{" "}
                        </Typography>
                        <Typography type="text" pattern="bold">
                            {overviewOfSupplier.Address || "-"}
                        </Typography>
                    </Stack>
                    <Stack direction="row" className="eco-mb-8">
                        <Typography
                            type="text"
                            className="eco-mr-8"
                            color="gray_base"
                        >
                            EMAIL :{" "}
                        </Typography>
                        <Typography type="text" pattern="bold">
                            {overviewOfSupplier.Email || "-"}{" "}
                        </Typography>
                    </Stack>
                    <Stack direction="row" className="eco-mb-8">
                        <Typography
                            type="text"
                            className="eco-mr-8"
                            color="gray_base"
                        >
                            LANDLINE :{" "}
                        </Typography>
                        <Typography type="text" pattern="bold">
                            {overviewOfSupplier.Telephone || "-"}{" "}
                        </Typography>
                    </Stack>
                    <Stack direction="row" className="eco-mb-8">
                        <Typography
                            type="text"
                            className="eco-mr-8"
                            color="gray_base"
                        >
                            MOBILE :{" "}
                        </Typography>
                        <Typography type="text" pattern="bold">
                            {overviewOfSupplier.MobileNo || "-"}{" "}
                        </Typography>
                    </Stack>
                </StackItem>
            </Stack>

            <Stack>
                <Row>
                    <Col span={24}>
                        <Carousel slidesToShow={1} slidesToScroll={1}>
                            {overviewOfSupplier.ContactPerson?.map(
                                (cp, index) => (
                                    <Card
                                        width={"90%"}
                                        content={
                                            <>
                                                <Stack
                                                    direction="row"
                                                    horizontalAlign="h_center"
                                                    className="eco-mb-8"
                                                >
                                                    <Typography
                                                        type="text"
                                                        className="eco-mr-8"
                                                        color="gray_base"
                                                        pattern="bold"
                                                    >
                                                        CONTACT PERSON{" "}
                                                        {`${index + 1}`}
                                                    </Typography>
                                                </Stack>
                                                <Stack className="eco-mb-8">
                                                    <Typography
                                                        type="text"
                                                        className="eco-mr-8"
                                                        color="gray_base"
                                                    >
                                                        Name :
                                                    </Typography>
                                                    <Typography
                                                        type="text"
                                                        pattern="bold"
                                                    >
                                                        {cp.Name}
                                                    </Typography>
                                                </Stack>

                                                <Stack className="eco-mb-8">
                                                    <Typography
                                                        type="text"
                                                        className="eco-mr-8"
                                                        color="gray_base"
                                                    >
                                                        Designation :
                                                    </Typography>
                                                    <Typography
                                                        type="text"
                                                        pattern="bold"
                                                    >
                                                        {cp.Staff}
                                                    </Typography>
                                                </Stack>

                                                <Stack className="eco-mb-8">
                                                    <Typography
                                                        type="text"
                                                        className="eco-mr-8"
                                                        color="gray_base"
                                                    >
                                                        E-Mail :
                                                    </Typography>
                                                    <Typography
                                                        type="text"
                                                        pattern="bold"
                                                    >
                                                        {cp.Email}
                                                    </Typography>
                                                </Stack>

                                                <Stack className="eco-mb-8">
                                                    <Typography
                                                        type="text"
                                                        className="eco-mr-8"
                                                        color="gray_base"
                                                    >
                                                        Mobile :
                                                    </Typography>
                                                    <Typography
                                                        type="text"
                                                        pattern="bold"
                                                    >
                                                        {cp.Mobile}
                                                    </Typography>
                                                </Stack>
                                            </>
                                        }
                                    ></Card>
                                )
                            )}
                        </Carousel>
                    </Col>
                </Row>
            </Stack>

            <Stack horizontalAlign="h_end" className="eco-mb-24">
                <div className="eco-mr-0">
                    <Select
                        className="duration-select"
                        defaultValue="last-30-days"
                        placeholder="Last 30 days"
                        options={options2}
                        value={selectedPeriod}
                        onChange={setSelectedPeriod}
                    ></Select>
                </div>
            </Stack>

            <Row gutter={[24, 24]} justify="space-between">
                <Col span={12}>
                    <Card
                        width="100%"
                        height="250px"
                        title="Sales"
                        content={
                            <div>
                                <div
                                    style={{
                                        margin: "auto",
                                        width: "100px",
                                        height: "",
                                    }}
                                >
                                    <Stack
                                        horizontalAlign="h-center"
                                        className="eco-mb-12"
                                    >
                                        <Icon
                                            name="tag"
                                            type="filled"
                                            size={20}
                                            color="orange"
                                        />
                                        <span>&nbsp;</span>
                                        <Tag
                                            label="Products"
                                            variant="orange"
                                        />
                                    </Stack>
                                </div>
                                <div className="eco-pa-12">
                                    <Row>
                                        <Col span={24}>
                                            <Stack horizontalAlign="space_between">
                                                <Typography color="gray_base">
                                                    Active Products
                                                </Typography>
                                                <Typography
                                                    type="title"
                                                    level={3}
                                                >
                                                    0
                                                </Typography>
                                            </Stack>
                                        </Col>
                                        <Col span={24}>
                                            <Stack horizontalAlign="space_between">
                                                <Typography color="gray_base">
                                                    Reached Reorder Level
                                                </Typography>
                                                <Typography
                                                    type="title"
                                                    level={3}
                                                >
                                                    0
                                                </Typography>
                                            </Stack>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        }
                    ></Card>
                </Col>
                <Col span={12}>
                    <Card
                        width="100%"
                        height="250px"
                        title="Sales"
                        content={
                            <div>
                                <div style={{ margin: "auto", width: "100px" }}>
                                    <Stack
                                        horizontalAlign="h-center"
                                        className="eco-mb-12"
                                    >
                                        <Icon
                                            name="tag"
                                            type="filled"
                                            size={20}
                                            color="orange"
                                        />
                                        <span>&nbsp;</span>
                                        <Tag
                                            label="Purchase"
                                            variant="orange"
                                        />
                                    </Stack>
                                </div>
                                <div className="eco-pa-12">
                                    <Row>
                                        <Col span={24}>
                                            <Stack horizontalAlign="space_between">
                                                <Typography color="gray_base">
                                                    Confirmed POs (7 days) :{" "}
                                                </Typography>
                                                <Typography
                                                    type="title"
                                                    level={3}
                                                >
                                                    0
                                                </Typography>
                                            </Stack>
                                        </Col>
                                        <Col span={24}>
                                            <Stack horizontalAlign="space_between">
                                                <Typography color="gray_base">
                                                    Completed POs (7 days) :
                                                </Typography>
                                                <Typography
                                                    type="title"
                                                    level={3}
                                                >
                                                    0
                                                </Typography>
                                            </Stack>
                                        </Col>
                                        <Col span={24}>
                                            <Stack horizontalAlign="space_between">
                                                <Typography color="gray_base">
                                                    GRN Created (7 days) :
                                                </Typography>
                                                <Typography
                                                    type="title"
                                                    level={3}
                                                >
                                                    0
                                                </Typography>
                                            </Stack>
                                        </Col>
                                        <Col span={24}>
                                            <Stack horizontalAlign="space_between">
                                                <Typography color="gray_base">
                                                    Last Payment Date :
                                                </Typography>
                                                <Typography
                                                    type="title"
                                                    level={3}
                                                >
                                                    0
                                                </Typography>
                                            </Stack>
                                        </Col>
                                        <Col span={24}>
                                            <Stack horizontalAlign="space_between">
                                                <Typography color="gray_base">
                                                    Total Due :
                                                </Typography>
                                                <Typography
                                                    type="title"
                                                    level={3}
                                                ></Typography>
                                            </Stack>
                                        </Col>
                                        <Col span={24}>
                                            <Stack horizontalAlign="space_between">
                                                <Typography color="gray_base">
                                                    Last Invoice Date :
                                                </Typography>
                                                <Typography
                                                    type="title"
                                                    level={3}
                                                ></Typography>
                                            </Stack>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        }
                    ></Card>
                </Col>
            </Row>
        </div>
    );
};

export default SupplierOverview;
