import React from 'react'
import { InputBox, Label, Row, Col, Button, Stack, InlineMessage, Alert } from 'witmeg-ui-system';

function OnlineLookupForm(props) {

    const {
        barCodeValue,
        barCodeData,
        productCategories,
        brands,
        allproductData,
        handleCloseLookUp,
        handleOpenLookUp
    } = props
    console.log('barCodeData---', barCodeData)
    console.log('allproductData---', allproductData)
    console.log('productCategories---', productCategories)
    console.log('brands---', brands)
    console.log('barCodeValue---', barCodeValue)


    const handleData = () => {
        console.log('Barcode', barCodeValue)
    }

    const productsByBarcode = allproductData.find(({ BarCode }) => BarCode === barCodeValue)
    console.log("productsByBarcode", productsByBarcode);
    const mainCategory = productsByBarcode?.CloudProductCategory?.ProductCategory.Category;
    const name = productsByBarcode?.Name;
    const brand = productsByBarcode?.CloudProductBrand.BrandName;
    const weight = productsByBarcode?.Weight;
    const price = productsByBarcode?.CloudSellingPricesArray[0].CloudSellingprice



    return (
        <div>
            <Stack horizontalAlign='h_start'>
                <Row className="form-row">
                    <Alert description='This service provides indicative pricing. We recommend that to check supplier invoices to be certain.' type="info" showIcon />
                </Row>
            </Stack>

            <Row className="form-row">
                <Col md={8}><div className="form-lbl-wrapper"><Label required={true} >Barcode</Label></div></Col>
                <Col md={16}>
                    <InputBox
                        type="text"
                        placeholder="Barcode"
                        value={barCodeValue}
                    />
                </Col>
            </Row><Row className="form-row">
                <Col md={8}><div className="form-lbl-wrapper"><Label >Main Category</Label></div></Col>
                <Col md={16}>
                    <InputBox
                        type="text"
                        placeholder="Main Category"
                        value={mainCategory}
                    />
                </Col>
            </Row><Row className="form-row">
                <Col md={8}><div className="form-lbl-wrapper"><Label >Name</Label></div></Col>
                <Col md={16}>
                    <InputBox
                        type="text"
                        placeholder="Name"
                        value={name}
                    />
                </Col>
            </Row><Row className="form-row">
                <Col md={8}><div className="form-lbl-wrapper"><Label >Brand</Label></div></Col>
                <Col md={16}>
                    <InputBox
                        type="text"
                        placeholder="Brand"
                        value={brand}
                    />
                </Col>
            </Row><Row className="form-row">
                <Col md={8}><div className="form-lbl-wrapper"><Label >Weight</Label></div></Col>
                <Col md={16}>
                    <InputBox
                        type="text"
                        placeholder="Weight"
                        value={weight}
                    />
                </Col>
            </Row><Row className="form-row">
                <Col md={8}><div className="form-lbl-wrapper"><Label>Price</Label></div></Col>
                <Col md={16}>
                    <InputBox
                        type="text"
                        placeholder="Price"
                        value={price}
                    />
                </Col>
            </Row>
            {/* <Stack horizontalAlign='h_end'>
                <Button variant='primary' onClick={handleData}>Get Data</Button>
            </Stack> */}


        </div>
    )
}

export default OnlineLookupForm