import lodash from 'lodash';

// This generate string obj paths, which can used to access values from Formik Value Obj.
export function getFormSectionsPartialObjPaths() {
	return {
		masterTab: {
			itemInfoObjPath: 'masterSection.itemInformation.',
			unitConfigurationObjPath: 'masterSection.unitConfiguration.',
			sellingInfoObjPath: 'masterSection.sellingInformation.',
			purchaseInfoObjPath: 'masterSection.purchaseInformation.',
			stockInfoObjPath: 'masterSection.stockInformation.',
		},

		customTab: {
			customInfoObjPath: 'customSection.customInformation.',
		},

		imagesTab: {
			currentImagesInfoObjPath: 'imageSection.currentImageInformation.',
			imageUploaderInfoObjPath: 'imageSection.imageUploaderInformation.',
		},
	};
}
// This function  generate multiple functions that can easily extract data from Formik Values Object.
export function generateFomikFormValuesObjParserFunctions(
	formikAllFormValuesInEveryTabObj = {},
	otherData = {},
) {
	const getFormValueByPath = (path) =>
		lodash.get(formikAllFormValuesInEveryTabObj, path);

	const setFormValueByPath = (path, value) => {
		/**
		 * NOTE :
		 * 	- This function should be used very carefully and only use in specific edge cases.
		 * 	- This is similar to "formikProps.setFieldValue". But there is very definitive difference. That this MUTATE "formikProps.values" directly and Re-Rendering don't occur with changes.
		 * 	- Also note that this function update only update provided path value if that path already exist. This condition is must to avoid some errors that very hard to debug.
		 */

		if (lodash.has(formikAllFormValuesInEveryTabObj, path)) {
			lodash.set(formikAllFormValuesInEveryTabObj, path, value);
		}
	};

	const { categoryTypeList, brandTypeList, unitTypeList } = otherData;

	function masterTabRelatedFunctions() {
		// Object Paths for Sub Sections of Master Tab's Form.
		const {
			itemInfoObjPath,
			unitConfigurationObjPath,
			sellingInfoObjPath,
			purchaseInfoObjPath,
			stockInfoObjPath,
		} = getFormSectionsPartialObjPaths().masterTab;

		// ********* Category, SubCategory, Brand Fields Related Functions *********
		const getSelectedCategoryData = () => {
			const selectedCategoryIndex = getFormValueByPath(
				`${itemInfoObjPath}category`,
			);
			const isCategorySelected = Number.isInteger(selectedCategoryIndex);
			const selectedCategoryRoot = isCategorySelected
				? categoryTypeList[selectedCategoryIndex]
				: {};
			const selectedCategory = selectedCategoryRoot.ProductCategory || {};
			const selectedCategorySubCategoryList =
				selectedCategoryRoot.ProductSubCategory || [];

			return {
				isSelected: isCategorySelected,
				selectedIndex: selectedCategoryIndex,
				selectedCategorySubCategoryObjList: selectedCategorySubCategoryList,
				selectedCategoryObj: selectedCategory,
			};
		};

		const getSelectedSubCategoryData = () => {
			const selectedCategoryData = getSelectedCategoryData();

			const selectedSubCategoryIndex = getFormValueByPath(
				`${itemInfoObjPath}subCategory`,
			);
			const isSubCategorySelected =
				selectedCategoryData.isSelected &&
				Number.isInteger(selectedSubCategoryIndex);
			const selectedSubCategory = isSubCategorySelected
				? selectedCategoryData.selectedCategorySubCategoryObjList[
						selectedSubCategoryIndex
				  ]
				: {};

			return {
				isSelected: isSubCategorySelected,
				selectedIndex: selectedSubCategoryIndex,
				selectedSubCategoryObj: selectedSubCategory,
			};
		};

		const getSelectedBrandData = () => {
			const selectedBrandIndex = getFormValueByPath(`${itemInfoObjPath}brand`);
			const isBrandSelected = Number.isInteger(selectedBrandIndex);
			const selectedBrand = isBrandSelected
				? brandTypeList[selectedBrandIndex]
				: {};

			return {
				isSelected: isBrandSelected,
				selectedIndex: selectedBrandIndex,
				selectedBrandObj: selectedBrand,
			};
		};

		// ********* Unit Type Fields Related Functions *********
		const getSelectedBaseUnitTypeData = () => {
			const selectedBaseUnitTypeIndex = getFormValueByPath(
				`${unitConfigurationObjPath}baseUnitType`,
			);
			const isBaseUnitTypeSelected = Number.isInteger(
				selectedBaseUnitTypeIndex,
			);
			const baseUnitType = isBaseUnitTypeSelected
				? unitTypeList[selectedBaseUnitTypeIndex]
				: {};

			return {
				isSelected: isBaseUnitTypeSelected,
				selectedIndex: selectedBaseUnitTypeIndex,
				selectedBaseUnitTypeObj: baseUnitType,
			};
		};

		const getSelectedAdditionalUnitTypeData = () => {
			const selectedAdditionalUnitTypeIndex = getFormValueByPath(
				`${unitConfigurationObjPath}additionalUnitType`,
			);
			const isAdditionalUnitTypeSelected = Number.isInteger(
				selectedAdditionalUnitTypeIndex,
			);
			const additionalUnitType = isAdditionalUnitTypeSelected
				? unitTypeList[selectedAdditionalUnitTypeIndex]
				: {};
			const additionalUnitTypeQuantity = getFormValueByPath(
				`${unitConfigurationObjPath}additionalUnitTypeQuantity`,
			);

			return {
				isSelected: isAdditionalUnitTypeSelected,
				selectedIndex: selectedAdditionalUnitTypeIndex,
				selectedAdditionalUnitTypeObj: additionalUnitType,
				selectedAdditionalUnitTypeQuantity: additionalUnitTypeQuantity,
			};
		};

		const getSelectedSellingUnitTypeData = () => {
			const selectedSellingUnitTypeIndex = getFormValueByPath(
				`${sellingInfoObjPath}unitType`,
			);
			const isSellingUnitTypeSelected = Number.isInteger(
				selectedSellingUnitTypeIndex,
			);
			const sellingUnitType = isSellingUnitTypeSelected
				? unitTypeList[selectedSellingUnitTypeIndex]
				: {};

			return {
				isSelected: isSellingUnitTypeSelected,
				selectedIndex: selectedSellingUnitTypeIndex,
				selectedSellingUnitTypeObj: sellingUnitType,
			};
		};

		const getSelectedPurchaseUnitTypeData = () => {
			const selectedPurchaseUnitTypeIndex = getFormValueByPath(
				`${purchaseInfoObjPath}unitType`,
			);
			const isPurchaseUnitTypeSelected = Number.isInteger(
				selectedPurchaseUnitTypeIndex,
			);
			const purchaseUnitType = isPurchaseUnitTypeSelected
				? unitTypeList[selectedPurchaseUnitTypeIndex]
				: {};

			return {
				isSelected: isPurchaseUnitTypeSelected,
				selectedIndex: selectedPurchaseUnitTypeIndex,
				selectedPurchaseUnitTypeObj: purchaseUnitType,
			};
		};

		const getSelectedInitialStockUnitTypeData = () => {
			const selectedInitialStockUnitTypeIndex = getFormValueByPath(
				`${stockInfoObjPath}initialStockUnitType`,
			);
			const isInitialStockUnitTypeSelected = Number.isInteger(
				selectedInitialStockUnitTypeIndex,
			);
			const initialStockUnitType = isInitialStockUnitTypeSelected
				? unitTypeList[selectedInitialStockUnitTypeIndex]
				: {};

			return {
				isSelected: isInitialStockUnitTypeSelected,
				selectedIndex: selectedInitialStockUnitTypeIndex,
				selectedInitialStockUnitTypeObj: initialStockUnitType,
			};
		};

		const getSelectedMinOrderLevelUnitTypeData = () => {
			const selectedMinOrderLevelUnitTypeIndex = getFormValueByPath(
				`${stockInfoObjPath}minOrderLevelUnitType`,
			);
			const isMinOrderLevelUnitTypeSelected = Number.isInteger(
				selectedMinOrderLevelUnitTypeIndex,
			);
			const minOrderLevelUnitType = isMinOrderLevelUnitTypeSelected
				? unitTypeList[selectedMinOrderLevelUnitTypeIndex]
				: {};

			return {
				isSelected: isMinOrderLevelUnitTypeSelected,
				selectedIndex: selectedMinOrderLevelUnitTypeIndex,
				selectedMinOrderLevelUnitTypeObj: minOrderLevelUnitType,
			};
		};

		const getSelectedMaxOrderLevelUnitTypeData = () => {
			const selectedMaxOrderLevelUnitTypeIndex = getFormValueByPath(
				`${stockInfoObjPath}maxOrderLevelUnitType`,
			);
			const isMaxOrderLevelUnitTypeSelected = Number.isInteger(
				selectedMaxOrderLevelUnitTypeIndex,
			);
			const maxOrderLevelUnitType = isMaxOrderLevelUnitTypeSelected
				? unitTypeList[selectedMaxOrderLevelUnitTypeIndex]
				: {};

			return {
				isSelected: isMaxOrderLevelUnitTypeSelected,
				selectedIndex: selectedMaxOrderLevelUnitTypeIndex,
				selectedMaxOrderLevelUnitTypeObj: maxOrderLevelUnitType,
			};
		};

		return {
			getSelectedCategoryData,
			getSelectedSubCategoryData,
			getSelectedBrandData,

			getSelectedBaseUnitTypeData,
			getSelectedAdditionalUnitTypeData,
			getSelectedSellingUnitTypeData,
			getSelectedPurchaseUnitTypeData,
			getSelectedInitialStockUnitTypeData,
			getSelectedMinOrderLevelUnitTypeData,
			getSelectedMaxOrderLevelUnitTypeData,
		};
	}

	function imagesTabRelatedFunctions() {
		// Object Paths for Sub Sections of Images Tab's Form.
		const { currentImagesInfoObjPath } =
			getFormSectionsPartialObjPaths().imagesTab;

		function getAllImagesInEveryCategory() {
			const primaryImageList =
				getFormValueByPath(`${currentImagesInfoObjPath}primaryImageList`) || [];

			const unitChartImageList =
				getFormValueByPath(`${currentImagesInfoObjPath}unitChartImageList`) ||
				[];

			const specialImageList =
				getFormValueByPath(`${currentImagesInfoObjPath}specialImageList`) || [];

			// SIDE NOTE : These formatting should be SOMEWHAT match with formatting's and vice versa in "ProductOverview.js ----> getProductImagesInEveryCategory()"

			const formattedPrimaryImageList = primaryImageList
				.map((img, index) => {
					return {
						...img,
						formikFieldPath: `${currentImagesInfoObjPath}primaryImageList`,
						formikFieldValueIndex: index,
						imgCategory: 'Primary Images',
						isMainImage: img.imgKeyTitle === 'Main',
					};
				})
				.sort((img) => {
					return img.imgKeyTitle === 'Main' ? -1 : 0;
				});

			const formattedUnitChartImageList = unitChartImageList.map(
				(img, index) => {
					return {
						...img,
						formikFieldPath: `${currentImagesInfoObjPath}unitChartImageList`,
						formikFieldValueIndex: index,
						imgCategory: 'UnitChart Images',
						isMainImage: false,
					};
				},
			);

			const formattedSpecialImageList = specialImageList.map((img, index) => {
				return {
					...img,
					formikFieldPath: `${currentImagesInfoObjPath}specialImageList`,
					formikFieldValueIndex: index,
					imgCategory: 'Special Images',
					isMainImage: false,
				};
			});

			return [].concat(
				formattedPrimaryImageList,
				formattedUnitChartImageList,
				formattedSpecialImageList,
			);
		}

		return {
			getAllImagesInEveryCategory,
		};
	}

	return {
		getFormValueByPath,
		setFormValueByPath,
		masterTabParserFns: masterTabRelatedFunctions(),
		imagesTabParserFns: imagesTabRelatedFunctions(),
	};
}
