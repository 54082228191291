import { reqStatusTypes } from '../../helpers/constants';

const sliceConstants = {
	name: 'settings',
	initialState: {
		// ******************************* dummyFn() *******************************
		dummyFnReqStatus: reqStatusTypes.idle,
		dummyFnReqError: '',
		dummyValue: '',

		// ******************************* Currency Settings *******************************
		currencySettingsStatus: reqStatusTypes.idle,
		currencySettingsError: {},
		currencySettings: null,
	},
};

export default sliceConstants;
