import React, { Component } from 'react';

import ProductMasterView from '../../../../../../components/dashboardRelatedComps/ProductMasterView/ProductMasterView';

export default class ProductAddPage extends Component {
	render() {
		return (
			<div className='ProductAddPage'>
				<ProductMasterView />
			</div>
		);
	}
}
