import React from 'react';

// This helper component is used to avoid re-renders when given 'Tab' is not the currently activated tab. This
// 		- This is mostly used as some performance improvement for Formik Input's Lags.
class ConditionalTabRenderer extends React.Component {
	shouldComponentUpdate(nextProps) {
		const {
			activeTabKey, // Currently Activated Tab.
			thisTabKey, // "Tab Key" of Tab that currently trying to render.
		} = nextProps;

		return activeTabKey === thisTabKey;
	}

	render() {
		// eslint-disable-next-line react/destructuring-assignment
		return this.props.children;
	}
}

export default ConditionalTabRenderer;
