import React, { useEffect, useState } from "react";
import {
    getStockAdjustmentDetail,
    saveStockAdjustment,
    getStockAdjustmentListPagination,
} from "../../../../../utilities/apiRequests/witmegWebNeurolageServerRequests";
import { useSelector, useDispatch } from "react-redux";
import { sectionName as dashboardSectionName } from "../../../../../redux/slices/dashboard/dashboardConstants";
import MsaInformationForm from "./Forms/MsaInformationForm";
import TableInfo from "./Forms/TableInfo";
import CreationForm from "./Forms/CreationForm";
import moment from "moment-timezone";
import {
    InputGroup,
    StackItem,
    Stack,
    Button,
    Table,
    Space,
    RightPanel,
    RightPanelHeader,
    Card,
    Message,
} from "witmeg-ui-system";
// import StockAdjestmentCreateForm from "./Forms/StockAdjestmentCreateForm";

function Msa(props) {
    const dashboardState = useSelector(
        (state) => state[dashboardSectionName].dashboard
    );
    const [selectedLeftValue, setSelectedLeftValue] = useState({
        value: "All",
        label: "All",
    });
    const [searchKey, setSearchKey] = useState("");
    const [displayData, setDisplayData] = useState(null);
    const [showAddOrEditPanel, setShowAddOrEditPanel] = useState(false);
    const [showMsaOverviewPanel, setShowMsaOverviewPanel] = useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [selectedMsaObj, setSelectedMsaObj] = useState(null);
    const [displayDetail, setDisplayDetail] = useState(null);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [userComment, setUserComment] = useState(null);
    const [noOfItems, setNoOfItems] = useState(null);
    const [columnData, setColumnData] = useState([
        {
            title: "Date & Time",
            dataIndex: "stockDatenTime",
            key: "stockDatenTime",
            render: (text) => <a>{text}</a>,
        },
        {
            title: "Reason",
            dataIndex: "stockReason",
            key: "stockReason",
            render: (text) => <a>{text}</a>,
        },
        {
            title: "Comment",
            dataIndex: "stockComment",
            key: "stockComment",
            render: (text) => <a>{text}</a>,
        },
        {
            title: "Adjusted by",
            dataIndex: "stockAdjustedBy",
            key: "stockAdjustedBy",
            render: (text) => <a>{text}</a>,
        },
        {
            title: "Device",
            dataIndex: "stockDevice",
            key: "stockDevice",
            render: (text) => <a>{text}</a>,
        },
        {
            title: "Actions",
            key: "actions",
            render: (record) => (
                <Space size="middle">
                    <Button
                        category="icon"
                        variant="link"
                        children="eye"
                        size="small"
                        iconSize={16}
                        onClick={() => {
                            toggleMsaOverviewPanel(record);
                            // handleShowMsaActions(e, record);
                        }}
                    />
                </Space>
            ),
        },
    ]);
    const [loadingData, setLoadingData] = useState(false);
    const [trigger, setTrigger] = useState(false);
    const [formData, setFormData] = useState({
        comment: null,
        location: null,
        reason: null,
    });
    const [tableData, setTableData] = useState([]);
    const [formValues, setFormValues] = useState([]);
    const leftOptions = [
        { value: "All", label: "All" },
        // { value: "Date", label: "Date" },
        { value: "Reason", label: "Reason" },
        { value: "Device", label: "Device" },
        // { value: "Location", label: "Location" },
    ];

    //setting the end date
    const currentDate = moment.tz();
    const today = currentDate.clone().tz("Europe/London");
    const endDate = today.format("yyyy-MM-DD[T]HH:mm:ss.ssssSSSZ");
    const currentDateNTime = today.format("DD-MM-YYYY, h:mm a");
    //setting the start date
    const prevDate = moment.tz("2014-06-01 12:00", "America/New_York");
    const prevDatetaken = prevDate.clone().tz("Europe/London");
    const startDate = prevDatetaken.format("yyyy-MM-DD[T]HH:mm:ss.ssssSSSZ");

    const handleChangeInput = (val, fieldName) => {
        setFormData((form) => ({ ...form, [fieldName]: val }));
        let fields = ["reason", "location"];
        fields.includes(fieldName)
            ? emitFormValues(fieldName, val.value)
            : emitFormValues(fieldName, val);
    };

    const emitFormValues = (fieldName = "", val = "") => {
        fieldName !== "" &&
            val !== "" &&
            setFormValues((form) => ({ ...form, [fieldName]: val }));
    };

    const handleTableData = (data) => {
        setTableData(data);
    };

    //mapping the data list
    const getTableDataFromResult = async (result) => {
        // console.log("result", result);
        return await result.map((stock, index) => {
            return {
                key: index,
                stockDatenTime: moment(stock.CreatedDate)
                    .utc()
                    .format("YYYY-MM-DD, h:mm A"),
                stockReason: stock.Reason,
                stockComment: stock.Comment,
                stockAdjustedBy: stock.AdjustedBy,
                stockDevice: stock.Device,
                stockLocation: stock.LocationName,
                stockgroupID: stock.GroupID,
            };
        });
    };

    //fetching data from api
    useEffect(() => {
        let isMounted = false;
        !isMounted && fetchDataBasedOnLocation();
        return () => (isMounted = true);
    }, [trigger]);

    //mapping the data
    const getTableDataFromDetail = async (result) => {
        let datanew = result[0]?.Items;
        setCurrentLocation(result[0]?.LocationName);
        setNoOfItems(result[0]?.NoOfItems);

        return await datanew?.map((product, index) => {
            let temp = product.Adjustment + product.UnitType;
            // console.log("result---", temp);
            return {
                key: index,
                itemName: {
                    name: product?.ItemName,
                    itemCode: product?.ItemCode,
                    unitType: product?.UnitType,
                },
                closingStock: product?.ClosingStock,
                adjustment: product.Adjustment + " " + product.UnitType,
                unitType: product.UnitType,
                productID: product.ProductID,
            };
        });
    };

    const fetchDataBasedOnLocation = async () => {
        setLoadingData(true);

        const general = searchKey ? true : false;
        const advance = !searchKey ? true : false;

        let result = await getStockAdjustmentListPagination({
            OrgID: dashboardState.currentlySelectedOrgId,
            CloudLocationID: dashboardState.currentlySelectedLocationId,
            Pagination: {
                Start: 0,
                Limit: 100,
            },
            Search: {
                IsGeneralSearchApplicable: general,
                GeneralSearch: {
                    Key: selectedLeftValue.value,
                    Value: searchKey,
                },
                IsAdvanceSearchApplicable: advance,
                AdvanceSearch: {
                    IsSearchByDate: true,
                    CreatedDate: {
                        Start: startDate,
                        End: endDate,
                    },
                },
            },
        });

        const searchResult = await getTableDataFromResult(result);
        setDisplayData(searchResult);
        setLoadingData(false);
    };

    // getStockAdjustmentDetail
    //fetching detail from api
    useEffect(async () => {
        let searchDetail = await getStockAdjustmentDetail({
            GroupID: selectedGroupId,
        });
        let datanew = await getTableDataFromDetail(searchDetail);
        // console.log("hello detail", searchDetail);
        setDisplayDetail(datanew);
        // console.log("DisplayData----", displayDetail);
        setUserComment(searchDetail[0]?.Comments);
    }, [selectedGroupId]);

    const handleSubmitCreateMsa = async () => {
        let stockData = {
            ...formValues,
            noOfItems: tableData.length,
            itemsAdd: tableData,
        };

        let reqBody = stockData.itemsAdd.map((stock, index) => {
            return {
                StockList: [
                    {
                        Adjustment: stock.adjustmentCount,
                        Time: today.toISOString(),
                        OrgID: dashboardState.currentlySelectedOrgId,
                        CloudProductID: stock.cloudProductId,
                        CloudLocationID: stockData.location,
                        UpdateReason: stockData.reason,
                        Comment: stockData.comment,
                        UnitType: "EA",
                        Quantity: stock.adjustmentCount,
                        DeviceID: "Web Neurolage",
                        CloudUserID:
                            dashboardState.fullUserOrgDetailsList[0].UserID,
                    },
                ],
            };
        });

        const stockAdujusted = await saveStockAdjustment(reqBody[0]);

        if (stockAdujusted) {
            Message.success(`Stock adjustment note created`);
            clearForm();
            setTrigger((trigger) => !trigger);
            hideAddorEditMsaPanel();
        }
    };

    const clearForm = () => {
        setFormData({
            comment: "",
            location: null,
            reason: null,
        });
        setTableData([]);
        setFormValues([]);
    };

    //adding the header in edit
    const addMsaPanelHeader = (
        <RightPanelHeader
            title={"Manual Stock Adjustment Creation"}
            rightContent={
                <Button
                    onClick={() => handleSubmitCreateMsa()}
                    variant="primary"
                >
                    Save
                </Button>
            }
        ></RightPanelHeader>
    );

    //adding the header in overview
    const msaOverviewHeader = (
        <RightPanelHeader
            title={"Manual Stock Adjustment Overview"}
            rightContent={
                <Space>
                    {/* <Button
                    //onClick={() => setShowDeleteConfirmPopup(true)}
                    >Delete</Button>
                    <Button variant="primary"
                    //onClick={() => viewEditBrandOverviewPanel(selectedBrandId)}
                    >Edit</Button> */}
                </Space>
            }
        ></RightPanelHeader>
    );

    //showing the Msa creation Panel
    const MsaViewPanel = () => {
        setShowAddOrEditPanel(true);
    };

    //hiding the Msa creation Panel
    const hideAddorEditMsaPanel = () => {
        setShowAddOrEditPanel(false);
        clearForm();
    };

    //hiding the Overview Panel
    const hideOverviewMsaPanel = () => {
        setShowMsaOverviewPanel(false);
    };

    const toggleMsaOverviewPanel = (record) => {
        // console.log("record----", record);
        setSelectedGroupId(record?.stockgroupID);
        // console.log("record----", record?.stockgroupID);
        setShowMsaOverviewPanel(true);
        setSelectedMsaObj(record);
    };

    return (
        <div className="msapage" style={{ padding: "20px" }}>
            <div className="msapage__header" style={{ paddingBottom: "20px" }}>
                <Stack horizontalAlign="space_between" verticalAlign="v_top">
                    <Stack>
                        <StackItem style={{ width: "210px", margin: 10 }}>
                            <InputGroup
                                leftComponent="select"
                                leftOptions={leftOptions}
                                leftValue={selectedLeftValue}
                                leftWidth="80%"
                                onChangeLeftValue={(value) => {
                                    setSelectedLeftValue(value);
                                    setTrigger((trigger) => !trigger);
                                }}
                                rightComponent="search"
                                rightWidth="50%"
                                rightValue={searchKey}
                                onChangeRightValue={(value) => {
                                    setSearchKey(value);
                                    setTrigger((trigger) => !trigger);
                                }}
                            ></InputGroup>
                        </StackItem>
                    </Stack>
                    <Stack
                        horizontalAlign="space_between"
                        style={{ alignItems: "flex-start" }}
                    >
                        <StackItem className="eco-mr-12" style={{ margin: 10 }}>
                            <Button
                                category="icon"
                                variant="primary"
                                children="plus"
                                size="small"
                                iconSize={20}
                                color="white"
                                onClick={() => {
                                    MsaViewPanel();
                                }}
                            />
                        </StackItem>
                        <StackItem className="eco-mr-12" style={{ margin: 10 }}>
                            <Button
                                category="icon"
                                variant="default"
                                children="settings"
                                size="small"
                                iconSize={20}
                            />
                        </StackItem>

                        <RightPanel
                            active={showAddOrEditPanel}
                            size="medium"
                            outsideClose={true}
                            handleOnClose={hideAddorEditMsaPanel}
                            headerContent={addMsaPanelHeader}
                        >
                            <Space
                                direction="vertical"
                                style={{ width: "98%" }}
                            >
                                <Card
                                    height="425px"
                                    width="100%"
                                    rounded="small"
                                    shadow="no_shadow"
                                    className="menuItemCard"
                                    content={
                                        <div>
                                            <CreationForm
                                                currentDateNTime={
                                                    currentDateNTime
                                                }
                                                locationDetails={
                                                    props.selectedLocation
                                                }
                                                formData={formData}
                                                handleTableData={
                                                    handleTableData
                                                }
                                                handleChangeInput={
                                                    handleChangeInput
                                                }
                                                tableData={tableData}
                                            />
                                        </div>
                                    }
                                ></Card>
                            </Space>
                        </RightPanel>
                        <RightPanel
                            active={showMsaOverviewPanel}
                            size="small"
                            outsideClose={true}
                            headerContent={msaOverviewHeader}
                            handleOnClose={hideOverviewMsaPanel}
                        >
                            <Space
                                direction="vertical"
                                style={{ width: "98%" }}
                            >
                                <Card
                                    height="320px"
                                    width="100%"
                                    rounded="small"
                                    shadow="no_shadow"
                                    className="menuItemCard"
                                    content={
                                        <div>
                                            <MsaInformationForm
                                                selectedGroupID={
                                                    selectedGroupId
                                                }
                                                selectedMsaObj={selectedMsaObj}
                                                noOfItems={noOfItems}
                                                currentLocation={
                                                    currentLocation
                                                }
                                                displayDetail={displayDetail}
                                                userComment={userComment}
                                            />
                                        </div>
                                    }
                                ></Card>
                                <Card
                                    height="320px"
                                    width="100%"
                                    rounded="small"
                                    shadow="no_shadow"
                                    className="menuItemCard"
                                    content={
                                        <div>
                                            <TableInfo
                                                selectedGroupId={
                                                    selectedGroupId
                                                }
                                                startDate={startDate}
                                                endDate={endDate}
                                                selectedMsaObj={selectedMsaObj}
                                                getTableDataFromDetail={
                                                    getTableDataFromDetail
                                                }
                                                currentLocation={
                                                    currentLocation
                                                }
                                                noOfItems={noOfItems}
                                                displayDetail={displayDetail}
                                            />
                                        </div>
                                    }
                                ></Card>
                            </Space>
                        </RightPanel>
                    </Stack>
                </Stack>
            </div>
            {/* inde page data table */}
            <div className="stockpage__table">
                <Table
                    columns={columnData}
                    dataSource={displayData}
                    loading={loadingData}
                ></Table>
            </div>
        </div>
    );
}
export default Msa;
