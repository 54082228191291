import { reqStatusTypes } from '../../../helpers/constants';
import { sectionName } from '../dashboardConstants';

const sliceName = 'purchaseOrderPage';
const currentStatePath = `${sectionName}.${sliceName}`;

const sliceConstants = {
    name: sliceName,
    parentSectionName: sectionName,
    currentStatePath,
    initialState: {
        // ******************************* invokepurchaseOrderPageResetter() *******************************
        shouldInvokepurchaseOrderPageResetter: false,

        // ******************************* setProformaCrudRelatedData() *******************************
        purchaseOrderCrudRelatedData: {
            currentCrudType: 'ADD',
            selectedItemData: {}, // Currently, Only applicable on EDIT mode. For example to pass editing Customer's ID.
            additionalInfo: {},
        },
    },
};

export default sliceConstants;
