import React, { useState, useEffect } from "react";
import {
    Table,
    Stack,
    StackItem,
    Search,
    Button,
    Icon,
    Space,
    RightPanel,
    RightPanelHeader,
    Form,
    Accordion,
    ConfirmationPopup,
    Message,
    Popover,
    ActionList,
    Alert,
    InlineMessage,
    async,
} from "witmeg-ui-system";
import { useSelector, useDispatch } from "react-redux";
import "./index.css";
import {
    addEposProductBrand,
    updateEposProductBrand,
    getEposProductBrandsByOrgId,
    getEposBrandByBrandName,
} from "../../../../../utilities/apiRequests/witmegWebNeurolageServerRequests";
import BrandInformationForm from "./Forms/BrandInformationForm";
import BrandOverview from "./BrandOverview";
import { sectionName as dashboardSectionName } from "../../../../../redux/slices/dashboard/dashboardConstants";

function Brand(props) {
    const dashboardState = useSelector(
        (state) => state[dashboardSectionName].dashboard
    );
    const [brandInformationForm] = Form.useForm();
    const { selectedLocation } = props;
    const [displayData, setDisplayData] = useState(null);
    const [loadingBrands, setLoadingBrands] = useState(false);
    const [rowData, setRowData] = useState(null);
    const userState = useSelector((state) => state.user.userDetails);
    const [showAddOrEditPanel, setShowAddOrEditPanel] = useState(false);
    const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState(false);
    const [enableBrandAddMode, setEnableBrandAddMode] = useState(false);
    const [enableBrandEditMode, setEnableBrandEditMode] = useState(false);
    const [showBrandOverviewPanel, setShowBrandOverviewPanel] = useState(false);
    const [selectedBrandId, setSelectedBrandId] = useState(false);
    const [selectedBrandObj, setSelectedBrandObj] = useState(false);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [showBrandActionMenu, setShowBrandActionMenu] = useState(false);
    const [apiValidationError, setApiValidationError] = useState(null);
    const [actionMenuPopoverTriggerEvent, setActionMenuPopoverTriggerEvent] =
        useState(null);
    const [brandInformationValues, setBrandInformationValues] = useState({});
    const [showDeleteConfirmPopupIndex, setShowDeleteConfirmPopupIndex] =
        useState(false);
    const [missingBrandFieldNames, setMissingBrandFieldNames] = useState([]);
    const [errorMessage, setErrorMessage] = useState(false);
    const [searchData, setSearchData] = useState(null);
    const [brandInfoValidationErrors, setBrandInfoValidationErrors] = useState({
        brandName: false,
    });
    const [columnData, setColumnData] = useState([
        {
            title: "Brand Name",
            dataIndex: "BrandName",
            key: "BrandName",
            render: (text) => <a>{text}</a>,
        },
        {
            title: "Brand Description",
            dataIndex: "Description",
            key: "Description",
            render: (text) => <a>{text}</a>,
        },
        {
            title: "Actions",
            key: "actions",
            render: (record) => (
                <Space size="middle">
                    <Button
                        category="icon"
                        variant="link"
                        children="eye"
                        size="small"
                        iconSize={16}
                        onClick={(e) => {
                            toggleBrandOverviewPanel(record);
                            handleShowBrandActions(e, record);
                        }}
                    />
                    <Button
                        category="icon"
                        variant="link"
                        children="ellipsis-vertical"
                        size="small"
                        iconSize={16}
                        onClick={(e) => {
                            handleShowBrandActions(e, record);
                            toggleActiom(true);
                        }}
                        onMouseLeave={() => {
                            toggleActiom(false);
                        }}
                    />
                </Space>
            ),
        },
        // {
        //     title: "Brand Image",
        //     dataIndex: "BrandImage",
        //     key: "BrandImage",
        //     render: (image) => <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkUxm0Ig3QDrzXliFv2rNg0oLr0KH0RssgWtJ8kwvL8A&s" style={{ width: '100px', height: '100px' }} />,
        //     // render: (image) => <img src={image} style={{width:'100px',height:'100px'}}/> /* uncomment this line when images are fetched fom api*/,
        // },
    ]);

    useEffect(() => {
        let isMounted = false;
        //setLoadingBrands(true);
        if (!isMounted) {
            const setDisplayAndRowData = async () => {
                let searchResult = await getEposProductBrandsByOrgId({
                    OrgID: selectedLocation.organizationDetails.OrganizationID,
                });
                let data = await getTableDataFromResult(searchResult);
                setDisplayData(data);
                setRowData(searchResult);
            };

            setDisplayAndRowData();
        }
        // setLoadingBrands(false);
        return () => (isMounted = true);
    }, []);

    useEffect(async () => {
        let isMounted = false;
        if (rowData && selectedBrandId) {
            if (!isMounted) {
                let brand =
                    (await rowData) &&
                    rowData.find((rd) => rd.ID === selectedBrandId);
                setSelectedBrandObj(brand);
            }
        } else {
            setSelectedBrandObj(null);
        }

        return () => (isMounted = true);
    }, [rowData, selectedBrandId]);

    const reloadData = async () => {
        setLoadingBrands(true);
        let searchResult = await getEposProductBrandsByOrgId({
            OrgID: selectedLocation.organizationDetails.OrganizationID,
        });
        let data = await getTableDataFromResult(searchResult);
        setDisplayData(data);
        setRowData(searchResult);
        setLoadingBrands(false);
    };

    useEffect(async () => {
        let isMounted = false;
        if (selectedLocation) {
            // alert("changed location")
            !isMounted && fetchData();
        }

        return () => (isMounted = true);
    }, [selectedLocation]);

    const fetchData = async () => {
        setLoadingBrands(true);
        let brandList = await getEposProductBrandsByOrgId({
            OrgID: selectedLocation.organizationDetails.OrganizationID,
        });
        let data = await getTableDataFromResult(brandList);
        // console.log('location changed brand list', data);
        setRowData(brandList);
        setDisplayData(data);
        setLoadingBrands(false);
    };

    const validateBrandInfo = () => {
        let brandInfoValidations = {
            brandName: brandInformationValues.brandName === "",
        };
        setBrandInfoValidationErrors(brandInfoValidations);
        setMissingBrandFieldNames(
            [
                brandInformationValues.brandName === "" ? "Brand Name" : null,
            ].filter((x) => x)
        );
        if (brandInformationValues.brandName !== "") {
            setBrandInformationValues(brandInformationValues);
        }
    };

    // useEffect(async () => {
    //     if (rowData && categoryIdToDelete) {
    //         let category = rowData.find((rd) => rd.ID === categoryIdToDelete);
    //         console.log(
    //             "category ",
    //             category,
    //             "and",
    //             categoryIdToDelete
    //         );
    //         category.ProductCategory.IsproductCategoryDeleted = true;
    //         console.log("category updated", category);
    //         //setSelectedCategoryObj(category);
    //         let deleteResult = await updateEposProductCategory(category);
    //         console.log("deleteResult", deleteResult);
    //         if (deleteResult === "Product is updated successfully.") {
    //             console.log("category========>", category);
    //             Message.success(`Category (${category.ProductCategory.Category}) is deleted successfully.`);
    //             reloadData();
    //         }
    //     }
    // }, [rowData, categoryIdToDelete]);

    const handleSubmitAddBrand = async (e) => {
        validateBrandInfo();
        if (brandInfoValidationErrors.brandName === false) {
            console.log("added brands ---->", displayData);
            console.log("rowData--------------", rowData);

            let apiReqBody = { ...selectedBrandObj };
            // let brand =
            //     rowData && rowData.find((rd) => rd.ID === selectedBrandId);
            // console.log('brand--------------', brand)
            console.log("setBrandInformationValues", brandInformationValues);
            apiReqBody = {
                ...apiReqBody,
                BrandName: brandInformationValues.brandName.trim(),
                Description: brandInformationValues.description.trim(),
                CreatedDate: new Date().toISOString(),
                ModifiedDate: new Date().toISOString(),
                ModifiedBy: userState.UserId,
                IsDeleted: userState.UserId,
                OrgID: dashboardState.currentlySelectedOrgId,
                CloudCreatedBy: userState.UserId,
                CloudModifiedBy: userState.UserId,
            };
            let AddRes = await addEposProductBrand(apiReqBody);
            if (AddRes) {
                if (AddRes.hasError) {
                    setApiValidationError(AddRes);
                    return;
                }
                Message.success(
                    `Brand ${brandInformationValues.brandName} is added successfully.`
                );
                setShowAddOrEditPanel(false);
                reloadData();
            }
        } else {
            Message.error(
                `Brand ${brandInformationValues.brandName} is not added. Please try again`
            );
            reloadData();
            setShowAddOrEditPanel(false);
        }
    };

    const getTableDataFromResult = async (result) => {
        // console.log("result------", result);

        result = sortResultsFromCreatedDate(result);
        if (result.length > 2) {
            result = sortResultsFromCreatedDate(result);
        }

        return await result.map((brandObject, index) => {
            return {
                key: index,
                BrandID: 1,
                BrandID: brandObject.ID,
                BrandName: brandObject.BrandName,
                CloudCreatedBy: userState.UserId,
                CloudModifiedBy: userState.UserId,
                CreatedBy: userState.UserId,
                CreatedDate: new Date().toISOString(),
                Description: brandObject.Description,
                ID: brandObject.ID,
                IsDeleted: false,
                ModifiedBy: userState.UserId,
                ModifiedDate: new Date().toISOString(),
                OrgID: brandObject.OrgID,
            };
        });
    };
    const toggleActiom = (showBrandActionMenu) => {
        setShowBrandActionMenu(showBrandActionMenu);
    };

    useEffect(async () => {
        let isMounted = false;
        if (enableBrandEditMode && selectedBrandObj) {
            const brandObject = selectedBrandObj;
            // console.log('brandObj============>', brandObject);
            !isMounted &&
                setBrandInformationValues({
                    brandName: brandObject.BrandName,
                    description: brandObject.Description,
                });
        }

        return () => (isMounted = true);
    }, [enableBrandEditMode, selectedBrandObj]);

    useEffect(() => {
        // console.log('enableBrandAddMode', enableBrandAddMode);
        let isMounted = false;
        if (enableBrandAddMode) {
            !isMounted &&
                setBrandInformationValues({
                    brandName: "",
                    description: "",
                });
        }
        setShowAddOrEditPanel(enableBrandAddMode);

        return () => (isMounted = true);
    }, [enableBrandAddMode]);

    useEffect(() => {
        let isMounted = false;
        if (enableBrandEditMode) {
            !isMounted &&
                setBrandInfoValidationErrors({
                    brandName: false,
                });
        }
        return () => (isMounted = true);
    }, [enableBrandEditMode]);

    const confirmDeleteOverviewedBrand = async (selectedBrandId) => {
        console.log("selectedBrand====>", selectedBrand);
        let brandObj =
            rowData && rowData.find((rd) => rd.ID === selectedBrandId);
        console.log("brandObj", brandObj);
        const apiReqBody = {
            ...brandObj,
            IsDeleted: true,
        };
        let res = await updateEposProductBrand(apiReqBody);
        console.log("brandObj------------------------------", rowData);
        console.log("brandObj------------------------------", selectedBrandId);

        if (res) {
            if (res.hasError) {
                setApiValidationError(res);
                return;
            }
            Message.success(
                `Brand ${brandObj.BrandName} is deleted successfully.`
            );
            setShowBrandOverviewPanel(false);
            setShowDeleteConfirmPopup(false);
            setShowDeleteConfirmPopupIndex(false);
            reloadData();
        } else {
            Message.error(
                `Brand ${brandObj.BrandName} is not deleted.Please try again`
            );
        }
    };

    const handleSearchBrand = async (value) => {
        console.log("ongoing   value ---", value.target.value);
        setSearchData(value.target.value);
        console.log("searchData--", searchData);
        if (value.target.value !== "") {
            setLoadingBrands(true);

            let brandObject = await getEposBrandByBrandName({
                OrgID: selectedLocation.organizationDetails.OrganizationID,
                BrandName: value.target.value,
            });
            console.log("brandOBj-------->", brandObject);
            if (brandObject !== "Error") {
                let brand = brandObject;
                setRowData(brandObject);
                console.log("setRowData-----", rowData);

                setDisplayData(brandObject);
                console.log("heyyy", displayData);
            } else {
                setDisplayData([]);
            }
            setLoadingBrands(false);
        } else {
            reloadData();
        }
    };

    const confirmUpdateOverviewedBrand = async (selectedBrandId) => {
        validateBrandInfo();

        if (brandInfoValidationErrors.brandName === false) {
            let apiReqBody = { ...selectedBrandObj };

            let brandObj =
                rowData && rowData.find((rd) => rd.ID === selectedBrandId);
            apiReqBody = {
                ...apiReqBody,
                BrandName: brandInformationValues.brandName,
                Description: brandInformationValues.description,
                CreatedDate: new Date().toISOString(),
                ModifiedDate: new Date().toISOString(),
                ModifiedBy: userState.UserId,
                IsDeleted: false,
                OrgID: dashboardState.currentlySelectedOrgId,
                CloudCreatedBy: userState.UserId,
                CloudModifiedBy: userState.UserId,
            };

            let res = await updateEposProductBrand({
                ID: selectedBrandId,
                ...apiReqBody,
            });
            console.log("brandObj------------------------------", rowData);
            console.log(
                "brandObj------------------------------",
                selectedBrandId
            );

            if (res) {
                if (res.hasError) {
                    setApiValidationError(res);
                }
                Message.success(
                    `Brand ${brandObj.BrandName} is updated successfully.`
                );
                setShowAddOrEditPanel(false);
                setEnableBrandEditMode(false);
                reloadData();
            }
        } else {
            Message.error(`Brand is not updated. Please try again`);
            reloadData();
            setShowAddOrEditPanel(false);
        }
    };

    const sortResultsFromCreatedDate = (result) => {
        return result.sort((result1, result2) => {
            return (
                new Date(result2.CreatedDate) - new Date(result1.CreatedDate)
            );
        });
    };

    const handleSubmitUpdateBrand = () => {
        validateBrandInfo();
        setApiValidationError(null);
        confirmUpdateOverviewedBrand(selectedBrandId);
    };

    const toggleActiomMenu = (showBrandActionMenu) => {
        setShowBrandActionMenu(showBrandActionMenu);
    };

    // const getTableDataFromResult = async (data) => {
    //     console.log('result-----', data)

    //     return await data.map(results => {
    //         return {
    //             "BrandID": results.BrandID,
    //             "BrandName": results.BrandName,
    //             "CloudCreatedBy": results.UserId,
    //             "CloudModifiedBy": results.UserId,
    //             "CreatedBy": results.UserId,
    //             "CreatedDate": new Date().toISOString(),
    //             "Description": results.Description,
    //             "ID": results.ID,
    //             "IsDeleted": false,
    //             "ModifiedBy": userState.UserId,
    //             "ModifiedDate": new Date().toISOString(),
    //             "OrgID": results.OrgID,
    //         }
    //     }
    //     );
    // }

    const addBrandPanelHeader = (
        <RightPanelHeader
            title={enableBrandEditMode ? "Update Brand" : "Add Brand"}
            rightContent={
                enableBrandEditMode ? (
                    <Button
                        // onClick={() => handleSubmitUpdateBrand()}
                        onClick={() =>
                            confirmUpdateOverviewedBrand(selectedBrandId)
                        }
                        variant="primary"
                    >
                        Update
                    </Button>
                ) : (
                    <Button
                        onClick={(e) => handleSubmitAddBrand(e)}
                        variant="primary"
                    >
                        Save
                    </Button>
                )
            }
        />
    );

    const BrandViewPanel = () => {
        setShowAddOrEditPanel(true);
        setEnableBrandAddMode(true);
        setBrandInformationValues({
            brandName: "",
            description: "",
        });
    };

    const hideAddorEditBrandPanel = () => {
        setShowAddOrEditPanel(false);
        setSelectedBrandId(null);
        setEnableBrandAddMode(false);
        setEnableBrandEditMode(false);
        setShowAddOrEditPanel(false);
    };

    const handleChangeBrandInfoInputs = (values, updatedField) => {
        setBrandInformationValues(values);
        setBrandInfoValidationErrors({
            ...brandInfoValidationErrors,
            [updatedField]: values[updatedField] === "",
        });
    };
    const toggleBrandOverviewPanel = (record) => {
        console.log("record----", record);
        setSelectedBrandId(record.ID);
        setShowBrandOverviewPanel(true);
    };

    const hideOverviewBrandPanel = () => {
        setShowBrandOverviewPanel(false);
    };

    const onCancelDeleteConfirmPopup = () => {
        setShowBrandOverviewPanel(false);
        setShowDeleteConfirmPopup(false);
        setShowDeleteConfirmPopupIndex(false);
    };
    const viewEditBrandOverviewPanel = (selectedBrandId) => {
        setShowBrandOverviewPanel(false);
        setSelectedBrandId(selectedBrandId);
        setShowAddOrEditPanel(true);
        setEnableBrandEditMode(true);
        toggleActiomMenu(false);
    };
    const handleShowBrandActions = (e, record) => {
        console.log("record--------==========", e);
        setActionMenuPopoverTriggerEvent(e);
        setSelectedBrand(record.ID);
        console.log("record---------------------->>>>>>", selectedBrand);
        setShowBrandActionMenu(true);
        toggleActiom(true);
    };

    const handleClear = () => {
        setSearchData("");
        reloadData();
        console.log("SearchData---------", searchData);
    };

    const brandOverviewHeader = (
        <RightPanelHeader
            title="Brand Overview"
            rightContent={
                <Space>
                    <Button onClick={() => setShowDeleteConfirmPopup(true)}>
                        Delete
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() =>
                            viewEditBrandOverviewPanel(selectedBrandId)
                        }
                    >
                        Edit
                    </Button>
                </Space>
            }
        />
    );

    const missingBrandInfoMessage =
        missingBrandFieldNames.length > 0
            ? `${missingBrandFieldNames.join(
                  ", "
              )} field(s) are missing in Brand Information.`
            : "";
    return (
        <div className="brandpage" style={{ padding: "20px" }}>
            <div
                className="brandpage__header"
                style={{ paddingBottom: "20px" }}
            >
                <Stack horizontalAlign="space_between" verticalAlign="v_top">
                    <Stack>
                        <StackItem style={{ width: "210px", margin: 10 }}>
                            <Search
                                className="brandpage__search"
                                type="default"
                                direction="column"
                                placeholder="Search Brand..."
                                onChange={(value) => handleSearchBrand(value)}
                                value={searchData}
                            ></Search>
                        </StackItem>

                        <StackItem
                            style={{ width: "200px", margin: 10 }}
                            className="eco-divider-l"
                        >
                            <Button
                                category="text"
                                children="Clear All"
                                variant="subtle"
                                onClick={handleClear}
                                // onClick={() => setSearchData('')}
                            >
                                Clear All
                            </Button>
                        </StackItem>

                        <Popover
                            position="bottom-right"
                            popoverTriggerEvent={actionMenuPopoverTriggerEvent}
                            active={showBrandActionMenu}
                            showHidePopover={toggleActiomMenu}
                            type="basic"
                            customStyles={{ width: "128px" }}
                        >
                            <ActionList
                                width={160}
                                items={[
                                    {
                                        key: 4,
                                        prefix: (
                                            <Icon name="pencil" size={16} />
                                        ),
                                        content: "Edit",
                                        onClick: () =>
                                            viewEditBrandOverviewPanel(
                                                selectedBrand
                                            ),
                                    },
                                    {
                                        key: 5,
                                        prefix: (
                                            <Icon name="trashcan" size={16} />
                                        ),
                                        content: "Delete",
                                        onClick: () => {
                                            setShowDeleteConfirmPopupIndex(
                                                true
                                            ),
                                                toggleActiomMenu(false);
                                        },
                                    },
                                    {
                                        key: 6,
                                        prefix: <Icon name="user" size={16} />,
                                        content: "Log",
                                    },
                                ]}
                                type="action"
                            />

                            <ConfirmationPopup
                                modalVisible={showDeleteConfirmPopupIndex}
                                onSuccess={() =>
                                    confirmDeleteOverviewedBrand(selectedBrand)
                                }
                                onCancel={onCancelDeleteConfirmPopup}
                                confirmationMessage={`Are you sure you want to delete this brand ?`}
                                okText="Yes"
                                cancelText="No"
                            />
                        </Popover>
                        <RightPanel
                            active={showAddOrEditPanel}
                            size="small"
                            outsideClose={true}
                            handleOnClose={hideAddorEditBrandPanel}
                            headerContent={addBrandPanelHeader}
                        >
                            <Space
                                direction="vertical"
                                style={{ width: "100%" }}
                            >
                                {apiValidationError ? (
                                    <InlineMessage
                                        message={apiValidationError.message}
                                        type="alert"
                                        variant="error"
                                        close={false}
                                    />
                                ) : null}

                                {missingBrandInfoMessage && (
                                    <Alert
                                        message="Required Fields Missing!"
                                        showIcon
                                        description={`
                                        ${missingBrandInfoMessage}
                                    `}
                                        type="error"
                                        closable
                                    />
                                )}

                                <Accordion
                                    theme="default"
                                    type="content_only"
                                    title="Basic Information"
                                    // collapsed={!showBasicInfoPanel}
                                >
                                    <div //ref={basicInfoPanel}
                                        style={{ width: "100%" }}
                                    >
                                        <BrandInformationForm
                                            brandInformationValues={
                                                brandInformationValues
                                            }
                                            handleChangeBrandInfoInputs={
                                                handleChangeBrandInfoInputs
                                            }
                                            validations={
                                                brandInfoValidationErrors
                                            }
                                        />
                                    </div>
                                </Accordion>
                                {/* <Accordion
                                    theme='default'
                                    type='content_only'
                                    title='Image Upload'
                                // collapsed={!showBasicInfoPanel}
                                >
                                    <div //ref={basicInfoPanel} 
                                        style={{ width: '100%' }}>
                                        <BrandImageUpload />
                                    </div>
                                </Accordion> */}
                            </Space>
                        </RightPanel>
                        <RightPanel
                            active={showBrandOverviewPanel}
                            size="small"
                            outsideClose={true}
                            headerContent={brandOverviewHeader}
                            handleOnClose={hideOverviewBrandPanel}
                        >
                            <Space
                                direction="vertical"
                                style={{ width: "100%" }}
                            >
                                {showBrandOverviewPanel ? (
                                    <BrandOverview
                                        brand={
                                            rowData
                                                ? rowData.find(
                                                      (rd) =>
                                                          rd.ID ===
                                                          selectedBrandId
                                                  )
                                                : []
                                        }
                                        selectedBrandId={selectedBrandId}
                                    />
                                ) : null}
                            </Space>
                        </RightPanel>

                        <ConfirmationPopup
                            modalVisible={showDeleteConfirmPopup}
                            onSuccess={() =>
                                confirmDeleteOverviewedBrand(selectedBrandId)
                            }
                            onCancel={onCancelDeleteConfirmPopup}
                            confirmationMessage={`Are you sure you want to delete this brand ?`}
                            okText="Yes"
                            cancelText="No"
                        />
                    </Stack>
                    <Stack
                        horizontalAlign="space_between"
                        style={{ alignItems: "flex-start" }}
                    >
                        <StackItem className="eco-mr-12" style={{ margin: 10 }}>
                            <Button
                                category="icon"
                                variant="primary"
                                children="plus"
                                size="small"
                                iconSize={20}
                                color="white"
                                onClick={() => {
                                    BrandViewPanel();
                                }}

                                // onClick={(e) => {
                                //   setAddMenuPopoverTriggerEvent(e);
                                //   toggleAddMenu(true);
                                // }}
                                // onMouseLeave={() => {
                                //   toggleAddMenu(false);
                                // }}
                            />
                        </StackItem>
                        <StackItem className="eco-mr-12" style={{ margin: 10 }}>
                            <Button
                                category="icon"
                                variant="default"
                                children="settings"
                                size="small"
                                iconSize={20}
                                // onClick={(e) => {
                                //   setPrintMenuPopoverTriggerEvent(e);
                                //   togglePrintMenu(true);
                                // }}
                                // onMouseLeave={() => {
                                //   togglePrintMenu(false)
                                // }}
                            />
                        </StackItem>
                    </Stack>
                </Stack>
            </div>
            <div className="brandpage__table">
                <Table
                    columns={columnData}
                    dataSource={displayData}
                    loading={loadingBrands}
                ></Table>
            </div>
        </div>
    );
}

export default Brand;
