import React, { KeyboardEventHandler, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import { Form, InlineMessage, InputBox, Label, Switch, Checkbox, TextArea, Row, Col } from 'witmeg-ui-system';

const layout = {
	labelCol: {
		span: 8,
	},
	wrapperCol: {
		span: 16,
	},
};

const EcommerceConfigurations = (props) => {
	const {
		ecommerceConfigurationsItitialValues,
		handleChangeEcomConfigInputs,
		customAttributes,
		productTagsValue
	} = props;

	const createOption = (label) => ({
		label,
		value: label,
	});	

	const [inputValue, setInputValue] = React.useState('');
	const [value, setValue] = React.useState([]);

	useEffect(() => {
		// console.log('productTagsValue', productTagsValue);
		setValue(productTagsValue);
		// console.log('productTagsValueState', value);
	}, [productTagsValue]);

	const components = {
		DropdownIndicator: null,
	};

	const handleKeyDown = (event) => {
		if (!inputValue) return;
		switch (event.key) {
			case 'Enter':
			case 'Tab':
				setValue((prev) => [...prev, createOption(inputValue)]);
				setInputValue('');
				event.preventDefault();
		}
	};

	useEffect(() => {
		let tags = value.map(v => v.value).join(',');
		handleChangeInput(tags, 'productTag');
	}, [value]);

	const handleChangeInput = (e, fieldName) => {

		let fieldValue = fieldName === 'productTag' ? e : e.target.value;

		// let updatedEcomConfigValues = Object.assign(ecommerceConfigurationsItitialValues, { [fieldName]: fieldValue })

		let updatedEcomConfigValues = {
		  ...ecommerceConfigurationsItitialValues,
		  [fieldName]: fieldValue
		};

		// console.log('fieldValue', updatedEcomConfigValues);
		handleChangeEcomConfigInputs(updatedEcomConfigValues, fieldName)

	}

	const handleChangeSwitch = (e, fieldName) => {
		let updatedBasicInformationIValues = {
			...ecommerceConfigurationsItitialValues,
			[fieldName]: e
		}

		handleChangeEcomConfigInputs(updatedBasicInformationIValues)
	}

	const handleChangeOnlineConfig = (val, keyName) => {
		let updatedBasicInformationIValues = {
			...ecommerceConfigurationsItitialValues,
			onlineConfig: {
				...ecommerceConfigurationsItitialValues.onlineConfig,
				[keyName]: val
			}
		}

		handleChangeEcomConfigInputs(updatedBasicInformationIValues)
	}

	return (
		<div>
			<Row className="form-row">
				<Col md={8}><div className="form-lbl-wrapper"><Label>Product Tag</Label></div></Col>
				<Col md={16}>
					<CreatableSelect
						components={components}
						inputValue={inputValue}
						isClearable
						isMulti
						menuIsOpen={false}
						onChange={(newValue) => setValue(newValue)}
						onInputChange={(newValue) => setInputValue(newValue)}
						onKeyDown={handleKeyDown}
						placeholder="Type something and press enter..."
						value={value}
					/>
					{/* <InputBox
						type="text"
						placeholder="Product Tag"
						value={ecommerceConfigurationsItitialValues?.productTag || ''}
						onChange={(e) => handleChangeInput(e, 'productTag')}
					/> */}
				</Col>
			</Row>

			<Row className="form-row">
				<Col md={8}><div className="form-lbl-wrapper"><Label>Order Qty</Label></div></Col>
				<Col md={16}>
					<InputBox
						type="number"
						placeholder="Order Qty"
						value={ecommerceConfigurationsItitialValues?.orderQty || ''}
						onChange={(e) => handleChangeInput(e, 'orderQty')}
						minValue={0}
						minErrorMessage={'The minimum value cannot be negative'}
					/>
				</Col>
			</Row>

			<Row className="form-row">
				<Col md={8}><div className="form-lbl-wrapper"><Label>Online Config</Label></div></Col>
				<Col md={16}>
					<div>
						{customAttributes && Object.keys(customAttributes).map(attr => {
							return (
								<div><Checkbox checked={ecommerceConfigurationsItitialValues?.onlineConfig?.[attr]} onChange={(e) => handleChangeOnlineConfig(e.target.checked, attr)} key={`oc_key_${attr}`}>{attr}</Checkbox></div>
							)
						})}
					</div>
				</Col>
			</Row>

			<Row className="form-row">
				<Col md={8}><div className="form-lbl-wrapper"><Label>Out Of Stock</Label></div></Col>
				<Col md={16}>
					<div className="form-lbl-wrapper">
						<Checkbox checked={ecommerceConfigurationsItitialValues?.outOfStock} onChange={(e) => handleChangeSwitch(e.target.checked, 'outOfStock')} />
					</div>
				</Col>
			</Row>

			<Row className="form-row">
				<Col md={8}><div className="form-lbl-wrapper"><Label>Show Online</Label></div></Col>
				<Col md={16}>
					<div className="form-lbl-wrapper">
						<Switch size="small" name="showOnline" onChange={(e) => handleChangeSwitch(e, "showOnline")} defaultChecked={ecommerceConfigurationsItitialValues?.showOnline} />
					</div>
				</Col>
			</Row>

		</div>
	)
}

export default EcommerceConfigurations;