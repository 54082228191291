import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import DashboardPage from "../pages/DashboardPage/DashboardPage";
import HomePage from "../pages/HomePage/HomePage";
import LoginPage from "../pages/LoginPage/LoginPage";
import routerHistory from "../utilities/routerHistory";
import {
    handleFederatedLogin,
    removeUserIfExpired,
} from "../utilities/userAuthentication";

import PrivateRoute from "./helpers/PrivateRoute/PrivateRoute";
import { routePaths } from "./routeConstants";

// For each route change,
// 		1. Handling Federated Login Logics. (If Applicable)
// 		2. Check if User AccessToken expired (Through LocalStorage Value) and remove user from localStorage. No redirection happen here, Because public pages should be accessible even when user is not logged/valid/expired.
routerHistory.listen(() => {
    handleFederatedLogin();
    removeUserIfExpired();
});

export default class MainRoutes extends Component {
    constructor(props) {
        super(props);

        // Initial Check/Update.
        handleFederatedLogin();
        removeUserIfExpired();
    }

    render() {
        return (
            <Router>
                <Switch>
                    <Route path={routePaths.loginRoot}>
                        <LoginPage />
                    </Route>

                    <PrivateRoute path={routePaths.dashboardRoot}>
                        <DashboardPage />
                    </PrivateRoute>

                    <Route path="/">
                        <HomePage />
                    </Route>
                </Switch>
            </Router>
        );
    }
}
