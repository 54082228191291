import { Descriptions } from 'antd';
import React from 'react';

import ProductOverviewItem from '../../helperComponents/ProductOverviewItem/ProductOverviewItem';
import ProductOverviewSection from '../../helperComponents/ProductOverviewSection/ProductOverviewSection';

import './ProOveSalesAndStockSection.css';

const ProOveSalesAndStockSection = (props) => {
	const { overviewData = {} } = props;

	return (
		<ProductOverviewSection
			title='SALES AND STOCK'
			className='ProOveSalesAndStockSection'>
			<ProductOverviewItem title='TYPE'>
				<Descriptions
					bordered
					size='small'
					column={1}
					layout='horizontal'
					labelStyle={{
						width: '200px',
					}}
					contentStyle={{
						whiteSpace: 'nowrap',
					}}>
					<Descriptions.Item
						label={
							<div>
								<div>SOLD</div>
								<div className='ProOveSalesAndStockSection____labelSubtitle'>
									(Last 30 Days)
								</div>
							</div>
						}>
						114/EA
					</Descriptions.Item>

					<Descriptions.Item
						label={
							<div>
								<div>PURCHASED</div>
								<div className='ProOveSalesAndStockSection____labelSubtitle'>
									(Last 30 Days)
								</div>
							</div>
						}>
						100/EA
					</Descriptions.Item>

					<Descriptions.Item
						label={
							<div>
								<div>DAMAGED</div>
								<div className='ProOveSalesAndStockSection____labelSubtitle'>
									(Last 30 Days)
								</div>
							</div>
						}>
						2/EA
					</Descriptions.Item>

					<Descriptions.Item
						label={
							<div>
								<div>EXPIRED</div>
								<div className='ProOveSalesAndStockSection____labelSubtitle'>
									(Last 30 Days)
								</div>
							</div>
						}>
						5/EA
					</Descriptions.Item>
				</Descriptions>
			</ProductOverviewItem>
		</ProductOverviewSection>
	);
};

export default ProOveSalesAndStockSection;
