import { createAction } from '@reduxjs/toolkit';

import sliceConstants from './reportsSliceConstants';


export const setReportList = createAction(
	`${sliceConstants.name}/setReportList`,
);

// export const getCurrentlyLoggedUserDetails = createAsyncThunk(
//     `${sliceConstants.name}/getCurrentlyLoggedUserDetails`,
//     async (options = {}, thunkAPI) => {
//         try {
//             return await getUserDetailsByUserIdAPI({
//                 UserID: getLoggedUserId(),
//             });
//         } catch (error) {
//             const errMsg = error.customErrMsg || error.message;
//             return Promise.reject(errMsg);
//         }
//     },
// );

const extraActions = {
	setReportList,
};

export default extraActions;
