
import { HeaderBar } from 'ecologital-ui-library';
import { Form } from 'formik-antd';
import lodash from 'lodash';
import React, { Component } from 'react';

import { getFormSectionsPartialObjPaths } from '../../helpers/parserHelpers';
import { imageUploaderTypes } from '../../helpers/placeholderData';

import PmvImagesTabImageViewer from './subComponents/PmvImagesTabImageViewer/PmvImagesTabImageViewer';
import PmvImageTabImageUploader from './subComponents/PmvImageTabImageUploader/PmvImageTabImageUploader';

import './PmvImagesTab.css';

// Default Form.Items Label Size.
const defaultLabelColProps = {
	span: 4,
	style: {
		whiteSpace: 'pre-wrap',
	},
};

const { currentImagesInfoObjPath, imageUploaderInfoObjPath } =
	getFormSectionsPartialObjPaths().imagesTab;

const { itemInfoObjPath } = getFormSectionsPartialObjPaths().masterTab;

const imageUploaderTypesList = Object.values(imageUploaderTypes);

class PmvImagesTab extends Component {
	generateHelperFunctionsAndDataToGetNecessaryInfo() {
		const {
			customPropertyTypesList,

			parserFunctions,
			formikProps,
		} = this.props;

		const { getFormValueByPath, imagesTabParserFns } = parserFunctions;

		const { getAllImagesInEveryCategory: getAllImagesInEveryCategoryFn } =
			imagesTabParserFns;

		// **** Setters

		// Set selected image in "Primary Images --> Main" by updating Fromik State.
		function setImageAsMainImage(
			// Selected image's formik path & index.
			currentFormikFieldPath,
			currentFormikValueIndex,
		) {
			const isAlreadyPrimaryCategoryImage =
				currentFormikFieldPath ===
				`${currentImagesInfoObjPath}primaryImageList`;

			const primaryImageCategoryImgList = getFormValueByPath(
				`${currentImagesInfoObjPath}primaryImageList`,
			).slice(); // Notice Slicing.

			// Image List that currently selected image is belong to.
			const currentImageCategoryImgList = getFormValueByPath(
				currentFormikFieldPath,
			); // Notice NOT Slicing.
			const selectedImage =
				currentImageCategoryImgList[currentFormikValueIndex];
			currentImageCategoryImgList.splice(currentFormikValueIndex, 1); // Removing Image from It's current category. (Notice its direct removal from original array. Its okay since we are updating formikState below later.)

			// Checking "Primary Category" already have "Main" Image. If so making its key temporally.
			const existingMainImage = primaryImageCategoryImgList.find((img) => {
				return img.imgKeyTitle === 'Main';
			});
			if (existingMainImage) {
				existingMainImage.imgKeyTitle = 'TEMP';
			}

			if (isAlreadyPrimaryCategoryImage) {
				primaryImageCategoryImgList[currentFormikValueIndex].imgKeyTitle =
					'Main';
			} else {
				primaryImageCategoryImgList.push({
					imgKeyTitle: 'Main',
					imgUrl: selectedImage.imgUrl,
				});
			}

			// Sorting, Ordering, Naming "Primary Category" Image List.
			const formattedImageList = primaryImageCategoryImgList.reduce(
				(acc, currentValue, index) => {
					if (currentValue.imgKeyTitle === 'Main') {
						acc.unshift(currentValue);
					} else {
						acc.push({ ...currentValue, imgKeyTitle: `Image${index}` });
					}

					return acc;
				},
				[],
			);

			formikProps.setFieldValue(
				`${currentImagesInfoObjPath}primaryImageList`,
				formattedImageList,
			);
		}

		// **** Getters
		function getAllImagesInEveryCategory(...args) {
			return getAllImagesInEveryCategoryFn(...args);
		}

		function getSelectedImageMainType() {
			return getFormValueByPath(`${imageUploaderInfoObjPath}imageMainType`);
		}

		function getSelectedImageUnitType() {
			return getFormValueByPath(`${imageUploaderInfoObjPath}imageUnitType`);
		}

		function getSelectedImageCustomTypeData() {
			const selectedCustomTypeIndex =
				getFormValueByPath(`${imageUploaderInfoObjPath}imageCustomType`) || 0;

			return customPropertyTypesList[selectedCustomTypeIndex];
		}

		function getSelectedImageCustomType() {
			return getFormValueByPath(
				`${imageUploaderInfoObjPath}imageCustomTypeValue`,
			);
		}

		function getBarCodeValue() {
			// From Masters Tab.
			return getFormValueByPath(`${itemInfoObjPath}barcode`);
		}

		return {
			helperFns: {
				setImageAsMainImage,

				getAllImagesInEveryCategory,
				getSelectedImageMainType,
				getSelectedImageUnitType,
				getSelectedImageCustomTypeData,
				getSelectedImageCustomType,
				getBarCodeValue,
			},
			helperData: {},
		};
	}

	// When image is successfully uploaded adding it to "Formik State's Image List" so, Image showed in ImageViewer.
	onImageUploaderSuccess(cdnLink) {
		const { formikProps, parserFunctions } = this.props;
		const { getFormValueByPath } = parserFunctions;

		const { helperFns } =
			this.generateHelperFunctionsAndDataToGetNecessaryInfo();

		const mainImageType = helperFns.getSelectedImageMainType();
		const selectedImageUnitTypeData = helperFns.getSelectedImageUnitType();
		const selectedCustomTypeData = helperFns.getSelectedImageCustomTypeData();
		const selectedCustomTypeDataValue = helperFns.getSelectedImageCustomType();

		if (mainImageType === imageUploaderTypes.primary.type) {
			const currentImageList = getFormValueByPath(
				`${currentImagesInfoObjPath}primaryImageList`,
			);

			const isMainImageSelected = currentImageList.find((img) => {
				return img.imgKeyTitle === 'Main';
			});

			const newImageList = [
				...currentImageList,
				{
					imgKeyTitle: isMainImageSelected ? 'TEMP' : 'Main',
					imgUrl: cdnLink,
				},
			];

			const formattedImageList = newImageList.reduce(
				(acc, currentValue, index) => {
					if (currentValue.imgKeyTitle === 'Main') {
						acc.unshift(currentValue);
					} else {
						acc.push({ ...currentValue, imgKeyTitle: `Image${index}` });
					}

					return acc;
				},
				[],
			);

			formikProps.setFieldValue(
				`${currentImagesInfoObjPath}primaryImageList`,
				formattedImageList,
			);
		} else if (mainImageType === imageUploaderTypes.unitChart.type) {
			const currentImageList = getFormValueByPath(
				`${currentImagesInfoObjPath}unitChartImageList`,
			);

			const filteredImageList = currentImageList.filter((img) => {
				return img.imgKeyTitle !== selectedImageUnitTypeData;
			});

			const newImageList = [
				...filteredImageList,
				{
					imgKeyTitle: selectedImageUnitTypeData,
					imgUrl: cdnLink,
				},
			];

			formikProps.setFieldValue(
				`${currentImagesInfoObjPath}unitChartImageList`,
				newImageList,
			);
		} else if (mainImageType === imageUploaderTypes.special.type) {
			const currentImageList = getFormValueByPath(
				`${currentImagesInfoObjPath}specialImageList`,
			);

			const filteredImageList = currentImageList.filter((img) => {
				return img.imgKeyTitle !== selectedCustomTypeData.customArrayKeyName;
			});

			const newImageList = [
				...filteredImageList,
				{
					imgKeyTitle: selectedCustomTypeData.customArrayKeyName,
					imgUrl: cdnLink,
					customValue: selectedCustomTypeDataValue,
				},
			];

			formikProps.setFieldValue(
				`${currentImagesInfoObjPath}specialImageList`,
				newImageList,
			);
		}
	}

	render() {
		const {
			formikProps,

			unitTypeList,
			customPropertyTypesList,
			parserFunctions,

			currentOrgName,
		} = this.props;

		const { helperFns } =
			this.generateHelperFunctionsAndDataToGetNecessaryInfo();

		const imageList = helperFns.getAllImagesInEveryCategory();
		const selectedCustomTypeData = helperFns.getSelectedImageCustomTypeData();
		const barCode = helperFns.getBarCodeValue();

		if (lodash.isEmpty(formikProps.values)) {
			// To avoid runtime errors occurred due to formik initial values are not yet initialized.
			return null;
		}

		return (
			<div className='PmvImagesTab'>
				<Form
					labelCol={{ ...defaultLabelColProps }}
					colon={false}
					labelAlign='left'>
					<div className='PmvMasterTab__imagesViewSection'>
						<HeaderBar subTitle={<b>IMAGES</b>} />
						<PmvImagesTabImageViewer
							imageList={imageList}
							setImageAsMainImageFn={helperFns.setImageAsMainImage}
						/>
					</div>

					<div className='PmvMasterTab__imagesUploadSection'>
						<HeaderBar subTitle={<b>IMAGE UPLOADER</b>} />
						<PmvImageTabImageUploader
							formikProps={formikProps}
							parserFunctions={parserFunctions}
							imageTypes={imageUploaderTypesList}
							unitTypeList={unitTypeList}
							customPropertyTypesList={customPropertyTypesList}
							selectedCustomTypeData={selectedCustomTypeData}
							// ........... Other Props .........
							barCode={barCode}
							currentOrgName={currentOrgName}
							onImageUploaderSuccessFn={(...args) =>
								this.onImageUploaderSuccess(...args)
							}
							defaultLabelColProps={defaultLabelColProps}
						/>
					</div>
				</Form>
			</div>
		);
	}
}

export default PmvImagesTab;
