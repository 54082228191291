import { bindActionCreators } from '@reduxjs/toolkit';
import { Alert, Button, Tabs } from 'antd';
import { LoaderView } from 'ecologital-ui-library';
import { Formik } from 'formik';
import lodash from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

import { reqStatusTypes } from '../../../redux/helpers/constants';
import { actions as customersPageSliceActions } from '../../../redux/slices/dashboard/customersPageSlice/customersPageSlice';
import { sectionName as dashboardSectionName } from '../../../redux/slices/dashboard/dashboardConstants';
import { actions as dashboardSliceActions } from '../../../redux/slices/dashboard/dashboardSlice/dashboardSlice';
import { routePaths } from '../../../routes/routeConstants';
import {
	findGlobalCustomerByEmail,
	addGlobalCustomer,
} from '../../../utilities/apiRequests/witmegOrgServerRequests';
import {
	getSalesRepsListByOrgId,
	getEposCustomersByCloudCustomerId,
	addEposCustomer,
	updateEposCustomer,
} from '../../../utilities/apiRequests/witmegWebNeurolageServerRequests';
import { errorLogger } from '../../../utilities/errorHandlers';
import routerHistory from '../../../utilities/routerHistory';
import { getLoggedUserId } from '../../../utilities/userAuthentication';
import ConditionalTabRenderer from '../ProductMasterView/helperComponents/ConditionalTabRenderer';

import { createMasterTabFormRelatedApiData } from './helpers/apiDataCreators';
import {
	generateFullBaseInitialState,
	generateDynamicInitialValueGetterFunctions,
	fullValidationScheme,
	mapApiCustomerDataToFormikFormStructure,
} from './helpers/formikHelperData';
import { generateFomikFormValuesObjParserFunctions } from './helpers/parserHelpers';
import CmvMasterTab from './subSections/CmvMasterTab/CmvMasterTab';

import './CustomerMasterView.css';

const { TabPane } = Tabs;

class CustomerMasterView extends Component {
	constructor(props) {
		super(props);

		const { customersPageSliceState } = this.props;
		const { customerCrudRelatedData } = customersPageSliceState;

		const { customerId: editingCustomerId } =
			customerCrudRelatedData.selectedItemData;
		const isEditMode = customerCrudRelatedData.currentCrudType === 'EDIT';

		this.state = {
			activeTabKey: '1', // Currently Showing Tab.
			isEditMode,
			editingCustomerId,

			formikInitialState: {},

			// "Fetching Initial Page Data", related state.
			pageInitialDataReqStatus: reqStatusTypes.idle,
			pageInitialDataReqError: '',
			salesRepsList: [],

			rawCustomerData: {}, // Customer's Raw API data. (Especially used when opened in "EDIT" mode)
		};
	}

	async fetchNecessaryInitialPageData() {
		try {
			const { isEditMode, editingCustomerId } = this.state;
			const { dashboardSliceState } = this.props;

			const { currentlySelectedOrgId, currentlySelectedLocationId } =
				dashboardSliceState;

			// These will update below in certain cases. (Especially in "EDIT" mode)
			let rawCustomerData = {};
			let preFilledFormikInitialValues = {};
			let formikInitialValueGetterFunctions = {};

			this.setState({
				pageInitialDataReqStatus: reqStatusTypes.pending,
				pageInitialDataReqError: '',
			});

			// *** Below are pre-fetching data that needed for some specific parts in forms.
			const salesRepsList = await getSalesRepsListByOrgId({
				orgId: currentlySelectedOrgId,
			});

			// *** When in "EDIT" Mode.
			if (isEditMode) {
				// Getting Editing Customer's Initial Raw Data.
				const productDataResponse = await getEposCustomersByCloudCustomerId({
					orgId: currentlySelectedOrgId,
					locationId: currentlySelectedLocationId,
					searchKey: editingCustomerId,
				});

				rawCustomerData = productDataResponse.Result[0];

				// Mapping "Raw Customer Values" to "Formik Value Structure", So we can pass them as formik initial values.
				preFilledFormikInitialValues = mapApiCustomerDataToFormikFormStructure({
					customerData: rawCustomerData,
					salesRepsList,
				});
			}

			// Generating functions set, to be used as dynamic/customized initial value getters for needed keys.
			formikInitialValueGetterFunctions =
				generateDynamicInitialValueGetterFunctions({
					dashboardSliceState,
				});

			this.setState(
				{
					pageInitialDataReqStatus: reqStatusTypes.succeeded,
					pageInitialDataReqError: '',

					salesRepsList,
					rawCustomerData,
				},
				() => {
					// NOTE : Re-Setting React State here because, "generateFullBaseInitialState()" may depend on some values from state we define in above.
					this.setState({
						formikInitialState: generateFullBaseInitialState({
							preFilledInitialValues: preFilledFormikInitialValues,
							dynamicInitialValueGetters: formikInitialValueGetterFunctions,
						}),
					});
				},
			);
		} catch (error) {
			errorLogger(error);
			this.setState({
				pageInitialDataReqStatus: reqStatusTypes.failed,
				pageInitialDataReqError: 'Error occurred while fetching initial data.',
			});
		}
	}

	// Helper function to get "globalCustomerId".
	async addGlobalCustomerIfNotExist(email = '', otherData = {}) {
		const { detailsForNewGlobalCustomer } = otherData;

		// This will be updated according to the conditions.
		let globalCustomerId = ''; // NOTE : Also used as "CloudCustomerId"

		// Checking Global User Exist.
		const globalCustomerResponse = await findGlobalCustomerByEmail({
			Email: email,
		});

		const { globalCustomerExist, globalCustomerId: existingGlobalCustomerId } =
			globalCustomerResponse;

		if (globalCustomerExist) {
			globalCustomerId = existingGlobalCustomerId;
		} else {
			// Adding Global User.
			const addGlobalCustomerResp = await addGlobalCustomer({
				...detailsForNewGlobalCustomer,
			});

			globalCustomerId = addGlobalCustomerResp.userId;
		}

		return globalCustomerId;
	}

	// This get the all the form values in every form of every tab.
	// So in here we parse all those values and send necessary data to Add/Edit a customer.
	async handleCustomerAddEditFormSubmitting(
		allFormValuesInEveryTab,
		formikProps,
	) {
		try {
			const { isEditMode, editingCustomerId, salesRepsList, rawCustomerData } =
				this.state;

			const { dashboardSliceState, customersPageSliceActions } = this.props;

			const { currentlySelectedOrgId } = dashboardSliceState;
			const { resetCustomerCrudRelatedData } = customersPageSliceActions;

			const parserFunctions = generateFomikFormValuesObjParserFunctions(
				allFormValuesInEveryTab,
				{ salesRepsList },
			);

			const userId = getLoggedUserId();

			const commonArgumentsForApiDataCreatorFunctions = {
				userId,
				isEditMode,
				parserFunctions,
			};

			const masterTabFormRelatedApiData = createMasterTabFormRelatedApiData(
				commonArgumentsForApiDataCreatorFunctions,
			);

			// Getting CloudCustomerID.
			const cloudCustomerId = isEditMode
				? rawCustomerData.CloudCustomerID
				: await this.addGlobalCustomerIfNotExist(
						masterTabFormRelatedApiData.Email,
						{
							detailsForNewGlobalCustomer: {
								FirstName: masterTabFormRelatedApiData.FirstName,
								LastName: masterTabFormRelatedApiData.LastName,
								UserName: masterTabFormRelatedApiData.Email,
								Email: masterTabFormRelatedApiData.Email,
								OriginatedOrganizationID: currentlySelectedOrgId,
								Addresses: masterTabFormRelatedApiData.Addresses,
							},
						},
				  );

			const fullAddEposCustomerApiReqBody = {
				CloudCustomerID: cloudCustomerId,
				OrgID: currentlySelectedOrgId,
				Avatar: '',
				...masterTabFormRelatedApiData,
			};

			if (isEditMode) {
				const fullUpdateEposProductApiReqBody = lodash.mergeWith(
					rawCustomerData, // Existing Customer Data.
					fullAddEposCustomerApiReqBody,
					// eslint-disable-next-line consistent-return
					(objValue, srcValue, key) => {
						// Making below mentioned keys are not merged with existing values.
						const ignoreMergeKeys = ['Addresses'];
						if (ignoreMergeKeys.includes(key)) {
							return srcValue;
						}
					},
				);

				await updateEposCustomer({
					ID: editingCustomerId,
					...fullUpdateEposProductApiReqBody,
				});
			} else {
				await addEposCustomer(fullAddEposCustomerApiReqBody);
			}

			toast.success(`Customer ${isEditMode ? 'Updated' : 'Added'}.`);
			formikProps.setStatus({
				error: '',
			});
			formikProps.setSubmitting(false);

			// Resetting related redux's existing data and redirecting.
			resetCustomerCrudRelatedData();
			routerHistory.push(routePaths.dashboard__sales__customers__search);
		} catch (error) {
			const errMsg =
				error.customErrMsg ||
				'Error occurred while Creating/Editing the customer.';
			errorLogger(error);
			toast.error(errMsg);
			formikProps.setStatus({
				error: errMsg,
			});
			formikProps.setSubmitting(false);
		}
	}

	getEachTabsMetaData(options = {}) {
		const { formikProps } = options;

		const tabMetaData = {
			masterTab: {},
		};

		const tabsList = [
			{
				tabMetaKey: 'masterTab',
				formikKey: 'masterSection',
			},
		];

		tabsList.forEach((tab) => {
			const tabErrors = lodash.get(formikProps.errors, tab.formikKey);
			const tabTouched = lodash.get(formikProps.touched, tab.formikKey);

			if (lodash.isEmpty(tabErrors)) {
				tabMetaData[tab.tabMetaKey].isErrors = false;
			} else {
				tabMetaData[tab.tabMetaKey].isErrors = true;
			}

			if (lodash.isEmpty(tabTouched)) {
				tabMetaData[tab.tabMetaKey].isTouched = false;
			} else {
				tabMetaData[tab.tabMetaKey].isTouched = true;
			}

			if (
				tabMetaData[tab.tabMetaKey].isErrors &&
				tabMetaData[tab.tabMetaKey].isTouched
			) {
				tabMetaData[tab.tabMetaKey].showErrors = true;
			} else {
				tabMetaData[tab.tabMetaKey].showErrors = false;
			}
		});

		return tabMetaData;
	}

	componentDidMount() {
		this.fetchNecessaryInitialPageData();
	}

	componentWillUnmount() {
		const { customersPageSliceActions } = this.props;
		const { resetCustomerCrudRelatedData } = customersPageSliceActions;

		// Making related redux data is resetted when un-mounting. This is done to make sure when this component mounted again it doesn't show previous data.
		resetCustomerCrudRelatedData();
	}

	render() {
		const {
			activeTabKey,

			formikInitialState,

			pageInitialDataReqStatus,
			pageInitialDataReqError,
			salesRepsList,
		} = this.state;

		const { customersPageSliceState } = this.props;
		const { customerCrudRelatedData } = customersPageSliceState;

		const isMainPageActionsRunning =
			pageInitialDataReqStatus === reqStatusTypes.pending;
		const isMainPageActionsError =
			pageInitialDataReqStatus !== reqStatusTypes.pending &&
			pageInitialDataReqError;

		// NOTE : This component handle both ADD  & EDIT. So in here we check in "EDIT" mode existing item data is available. If not we are redirecting.
		// 				Example Scenario - User Directly going to edit URL "/sales/customers/edit"
		if (
			customerCrudRelatedData.currentCrudType === 'EDIT' &&
			lodash.isEmpty(customerCrudRelatedData.selectedItemData)
		) {
			return <Redirect to={routePaths.dashboard__sales__customers__search} />;
		}

		if (isMainPageActionsRunning || isMainPageActionsError) {
			return (
				<LoaderView
					className='CustomerMasterView'
					isLoading={isMainPageActionsRunning}
					isError={isMainPageActionsError}
					typeConfigs={{
						error: {
							extra: (
								<Button
									type='primary'
									onClick={() => {
										this.fetchNecessaryInitialPageData();
									}}>
									TRY AGAIN
								</Button>
							),
						},
					}}
				/>
			);
		}

		return (
			<div className='CustomerMasterView'>
				<Formik
					initialStatus={{}}
					initialValues={formikInitialState}
					enableReinitialize
					validationSchema={fullValidationScheme}
					onSubmit={(allFormValuesInEveryTab, formikProps) => {
						this.handleCustomerAddEditFormSubmitting(
							allFormValuesInEveryTab,
							formikProps,
						);
					}}
					validateOnChange={false} // As a Performance Improvement (But Note that there could be some "Validation Error Showing" caveats due to this.)
				>
					{(formikProps) => {
						const parserFunctions = generateFomikFormValuesObjParserFunctions(
							formikProps.values,
							{},
						);

						const commonPropsForTabCompParts = {
							salesRepsList,
							parserFunctions,
						};

						const tabsMetaData = this.getEachTabsMetaData({ formikProps });

						return (
							<div>
								<Tabs
									defaultActiveKey='1'
									type='card'
									size='small'
									tabBarGutter={2}
									animated={{ inkBar: false, tabPane: false }}
									onChange={(activeTabKey) => {
										this.setState({
											activeTabKey,
										});
									}}>
									<TabPane
										tab={
											<div>
												Master
												{tabsMetaData.masterTab.showErrors ? ' *' : ''}
											</div>
										}
										key='1'>
										<ConditionalTabRenderer
											activeTabKey={activeTabKey}
											thisTabKey='1'>
											<CmvMasterTab {...commonPropsForTabCompParts} />
										</ConditionalTabRenderer>
									</TabPane>
								</Tabs>

								<div className='CustomerMasterView__footer'>
									{formikProps.status.error && (
										<div className='CustomerMasterView__footer__errorViewer'>
											<Alert
												message={formikProps.status.error}
												type='error'
												showIcon
											/>
										</div>
									)}

									<div className='CustomerMasterView__footer__buttonList'>
										<Button
											type='primary'
											loading={formikProps.isSubmitting}
											onClick={() => {
												formikProps.handleSubmit();
											}}>
											SAVE
										</Button>

										<Button
											disabled={formikProps.isSubmitting}
											onClick={() => {
												routerHistory.push(
													routePaths.dashboard__sales__customers__search,
												);
											}}>
											CANCEL
										</Button>
									</div>
								</div>
							</div>
						);
					}}
				</Formik>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	dashboardSliceState: state[dashboardSectionName].dashboard,
	customersPageSliceState: state[dashboardSectionName].customersPage,
});

const mapDispatchToProps = (dispatch) => {
	const boundDashboardSliceActions = bindActionCreators(
		dashboardSliceActions,
		dispatch,
	);

	const boundDashboardPageSliceActions = bindActionCreators(
		customersPageSliceActions,
		dispatch,
	);

	return {
		dashboardSliceActions: boundDashboardSliceActions,
		customersPageSliceActions: boundDashboardPageSliceActions,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerMasterView);
