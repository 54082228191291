// NOTE : This contains Full Formik Form's Initial Data, Validations, Etc...
// 				Currently, We have multiple forms across multiple tabs, But all those forms data are handled in single formik instance.

import lodash from 'lodash';
import * as Yup from 'yup';

import {
	proformaOrderTypesList,
	proformaDeliveryTypesList,
	proformaStatusTypesList,
} from './placeholderData';

// Common "Error Texts" to be used in Yup Validations.
export const errorTexts = {
	required: 'Required.',
	tooShort: 'Too Short.',
	tooLong: 'Too Long.',
	positiveNumber: 'Must be a Positive Number',
	numberRequired: 'Required.',
};

// Generate Full "Base Initial State" for all the forms in different tabs.
// NOTE : This is declared as function because,
// 					- For some mode (Especially "EDIT" mode) we have to pass and set already existing values as form initial values.
// 					- For some keys we have to calculate dynamic initial state on run. So for those keys we can pass dynamic function that return necessary value.
export const generateFullBaseInitialState = (data = {}) => {
	const {
		preFilledInitialValues = {}, // Values passed in here will be merged with Default Initial State values.
		dynamicInitialValueGetters = {}, // These are mostly come from "generateDynamicInitialValueGetterFunctions" at the bottom of this file.
	} = data;

	const defaultInitialState = {
		// Form in Master Tab.
		masterSection: {
			customerInformation: {
				isCustomerSelected: false,
				customerId: '',
				customerName: '',
				rawCustomerData: {}, // Full Raw API Data of Selected Customer, Which will come from <CustomerList/>
			},

			baseProformaInformation: {
				proformaDate: new Date().toISOString(),
				proformaDeliveryMethod:
					dynamicInitialValueGetters.masterTab.getDefaultProformaDeliveryType(),
				proformaOrderType:
					dynamicInitialValueGetters.masterTab.getDefaultProformaOrderType(),
				proformaStatus:
					dynamicInitialValueGetters.masterTab.getDefaultProformaStatusType(),
			},
		},

		// Form in Items Tab.
		itemsSection: {
			addedItemsInformation: {
				addedItemsList: [],
				selectedItemIndexForDetails: '', // Index of "addedItemsList" which is selected.
			},
		},
	};

	return lodash.merge(defaultInitialState, preFilledInitialValues);
};

// Generate Full "Validation Scheme" for all the forms in different tabs.
// This structure is/should similar to above "fullBaseInitialState" Object.
export const fullValidationScheme = Yup.object().shape({
	// Form in Master Tab.
	masterSection: Yup.object().shape({
		// customerInformation: Yup.object().shape({
		// 	isCustomerSelected: Yup.bool().required(errorTexts.required),
		// 	customerId: Yup.string().required(errorTexts.required),
		// 	customerName: Yup.string().required(errorTexts.required),
		// 	rawCustomerData: Yup.object().required(errorTexts.required),
		// }),

		baseProformaInformation: Yup.object().shape({
			proformaDate: Yup.date().required(errorTexts.required),
			proformaDeliveryMethod: Yup.string().required(errorTexts.required),
			proformaOrderType: Yup.string().required(errorTexts.required),
			proformaStatus: Yup.string().required(errorTexts.required),
		}),
	}),

	// Form in Items Tab.
	itemsSection: Yup.object().shape({
		addedItemsInformation: Yup.object().shape({
			addedItemsList: Yup.array()
				.required(errorTexts.required)
				.min(1, 'Should add at least one Product.'),
			selectedItemIndexForDetails: Yup.string().notRequired(),
		}),
	}),
});

// Map "Raw API Proforma Data" into "Formik Initial State" Structure. (Especially used in "EDIT" mode) 


export function mapApiPurchaseOrderDataToFormikFormStructure(data = {}) {
	const { proformaData = {} } = data;

	// ******** Below is multiple helper functions to extract necessary data for each tab, which will be used in bottom of this main function.

	function getMasterTabRelatedMapperHelperFunctions() {
		return {};
	}

	const mapperHelperFns = {
		masterTab: getMasterTabRelatedMapperHelperFunctions(),
	};

	// *** Mapping Data

	return {
		// Form in Master Tab.
		masterSection: {
			customerInformation: {
				isCustomerSelected: false,
				customerId: '',
				customerName: '',
				rawCustomerData: {},
			},

			baseProformaInformation: {
				proformaDate: '',
				proformaDeliveryMethod: '',
				proformaOrderType: '',
				proformaStatus: '',
			},
		},

		// Form in Items Tab.
		itemsSection: {
			addedItemsInformation: {
				addedItemsList: [],
				selectedItemIndexForDetails: '',
			},
		},
	};
}

// Generate set of helper functions, to be used as dynamic/customized initial value getters for formik initial value keys.
// Directly used by "generateFullBaseInitialState()".
export function generateDynamicInitialValueGetterFunctions(data = {}) {
	function getMasterTabRelatedGetterFunctions() {
		const getDefaultProformaOrderType = () => {
			return proformaOrderTypesList.find((i) => i.default).value;
		};

		const getDefaultProformaDeliveryType = () => {
			return proformaDeliveryTypesList.find((i) => i.default).value;
		};

		const getDefaultProformaStatusType = () => {
			return proformaStatusTypesList.find((i) => i.default).value;
		};

		return {
			getDefaultProformaOrderType,
			getDefaultProformaDeliveryType,
			getDefaultProformaStatusType,
		};
	}

	return {
		masterTab: getMasterTabRelatedGetterFunctions(),
	};
}
