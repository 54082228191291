import {
	EditOutlined as EditIcon,
	MoreOutlined as MoreOutlinedIcon,
} from '@ant-design/icons';
import { bindActionCreators } from '@reduxjs/toolkit';
import { Button, Dropdown, Menu } from 'antd';
import { withViewportData } from 'ecologital-ui-library';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actions as customersPageSliceActions } from '../../../redux/slices/dashboard/customersPageSlice/customersPageSlice';
import { sectionName as dashboardSectionName } from '../../../redux/slices/dashboard/dashboardConstants';
import { routePaths } from '../../../routes/routeConstants';
import routerHistory from '../../../utilities/routerHistory';

import { generateCustomerDataObjParserFunctions } from './helpers/parserHelpers';
import CusOveChartSection from './subComponents/CusOveChartSection/CusOveChartSection';
import CusOveGeneralSection from './subComponents/CusOveGeneralSection/CusOveGeneralSection';

import './CustomerOverview.css';

class CustomerOverview extends Component {
	render() {
		const {
			rawCustomerData = {},
			toggleOverviewModal,

			currentViewportData, // From 'withViewportData' HOC.

			customersPageSliceActions,
		} = this.props;

		const { setCustomerCrudRelatedData } = customersPageSliceActions;

		const parserFunctions = generateCustomerDataObjParserFunctions(
			rawCustomerData,
			{},
		);

		const commonPropsForSubSectionComponents = {
			rawCustomerData,
			parserFunctions,

			currentViewportData,
		};

		return (
			<div className='CustomerOverview'>
				<div className='CustomerOverview____headerWrapper'>
					<div className='CustomerOverview__title'>
						<h2>{parserFunctions.getCompanyName() || 'N/A'}</h2>
					</div>

					<div className='CustomerOverview__buttons'>
						<Button
							title='Edit Customer'
							icon={<EditIcon />}
							size='medium'
							onClick={() => {
								toggleOverviewModal(); // Closing Overview Modal.

								// Setting Customer Edit Details & Redirecting to Edit Page.
								setCustomerCrudRelatedData({
									currentCrudType: 'EDIT',
									selectedItemData: {
										customerId: rawCustomerData.ID,
									},
								});

								routerHistory.push(
									routePaths.dashboard__sales__customers__edit,
								);
							}}
						/>

						<Dropdown
							trigger='click'
							placement='bottomRight'
							overlay={
								<Menu>
									<Menu.Item
										key='1'
										onClick={() => {
											toggleOverviewModal();
										}}>
										Close
									</Menu.Item>
								</Menu>
							}>
							<Button
								title='More Actions'
								icon={<MoreOutlinedIcon />}
								size='medium'
							/>
						</Dropdown>
					</div>
				</div>

				<div className='CustomerOverview_____bodyWrapper'>
					<CusOveGeneralSection {...commonPropsForSubSectionComponents} />
					{/* <CusOveChartSection {...commonPropsForSubSectionComponents} /> */}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	customersPageSliceState: state[dashboardSectionName].customersPage,
});

const mapDispatchToProps = (dispatch) => {
	const boundCustomersPageSliceActions = bindActionCreators(
		customersPageSliceActions,
		dispatch,
	);

	return {
		customersPageSliceActions: boundCustomersPageSliceActions,
	};
};

const compWithViewportDataHoc = withViewportData(CustomerOverview);

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(compWithViewportDataHoc);
