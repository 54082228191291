export function generateGenericRawProductImagesKeyDataParserFunctions() {
	// SIDE NOTE : 'imgKeyTitle' value is used as 'Object Key' in Raw Product Data's 'OnLineImages' relevant section.

	function getObjListAsArray(imageList = {}) {
		if (imageList === null) {
			return [];
		}

		return Object.entries(imageList).map((entry) => {
			const [key, value] = entry;

			return {
				imgKeyTitle: key,
				imgUrl: value,
			};
		});
	}

	function getObjListAsArrayForSpecialImageList(imageList = {}) {
		if (imageList === null) {
			return [];
		}

		return Object.entries(imageList).map((entry) => {
			const [key, value] = entry;

			let imgUrl = '';
			let customValue = '';

			if (Array.isArray(value)) {
				imgUrl = value[0].ImgURL;
				customValue = value[0].Value;
			} else {
				imgUrl = value;
			}

			return {
				imgKeyTitle: key,
				imgUrl,
				customValue,
			};
		});
	}

	function getPrimaryImageListAsArray(imageList = {}) {
		return getObjListAsArray(imageList);
	}

	function getUnitChartImageListAsArray(imageList = {}) {
		return getObjListAsArray(imageList);
	}

	function getSpecialImageListAsArray(imageList = {}) {
		return getObjListAsArrayForSpecialImageList(imageList);
	}

	return {
		getPrimaryImageListAsArray,
		getSpecialImageListAsArray,
		getUnitChartImageListAsArray,
	};
}
