import React from 'react';
import './ProductOverviewItem.css';

// Helper component to Grouping/Styling special sub info items of a section.
const ProductOverviewItem = (props) => {
	const { title, children, className = '' } = props;

	return (
		<div className={`ProductOverviewItem ${className}`}>
			<div className='ProductOverviewItem__header'>{title}</div>
			<div className='ProductOverviewItem__body'>{children}</div>
		</div>
	);
};

export default ProductOverviewItem;
