/* eslint-disable no-shadow */

import { bindActionCreators } from '@reduxjs/toolkit';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import ProformaMasterView from '../../../../components/dashboardRelatedComps/ProformaMasterView/ProformaMasterView';
import RouteContentWrapper from '../../../../components/widgets/RouteContentWrapper/RouteContentWrapper';
import { sectionName as dashboardSectionName } from '../../../../redux/slices/dashboard/dashboardConstants';
import { actions as proformasPageSliceActions } from '../../../../redux/slices/dashboard/proformasPageSlice/proformasPageSlice';
import { routePaths } from '../../../../routes/routeConstants';

import './ProformasPage.css';

class ProformasPage extends Component {
	render() {
		return (
			<div className='ProformasPage'>
				<Switch>
					<Route path={routePaths.dashboard__sales__proformas__add} exact>
						{/* SIDE NOTE : Notice we are adding different "key" for Add & Edit Section Wrapper. This is done to make sure "<ProformaMasterView/>" component unmount on route change. */}

						<RouteContentWrapper title='Add Proforma' key={1}>
							<ProformaMasterView />
						</RouteContentWrapper>
					</Route>

					<Route path={routePaths.dashboard__sales__proformas__edit} exact>
						<RouteContentWrapper title='Edit Proforma' key={2}>
							<ProformaMasterView />
						</RouteContentWrapper>
					</Route>

					<Route path={routePaths.dashboard__sales__proformas__search} exact>
						<RouteContentWrapper title='Search Proformas'>
							<h3>Searching Will Goes Here.</h3>
						</RouteContentWrapper>
					</Route>

					{/* Default */}
					<Route path={routePaths.dashboard__sales__proformas}>
						<Redirect to={routePaths.dashboard__sales__proformas__search} />
					</Route>
				</Switch>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	proformasPageSliceState: state[dashboardSectionName].proformasPage,
});

const mapDispatchToProps = (dispatch) => {
	const boundProformasPageSliceActions = bindActionCreators(
		proformasPageSliceActions,
		dispatch,
	);

	return {
		proformasPageSliceActions: boundProformasPageSliceActions,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProformasPage);
