import React from 'react'
import {
    Row,
    Col,
    Stack,
    Typography,

} from "witmeg-ui-system";

function MsaInformationForm(props) {
    const {
        selectedGroupID,
        selectedMsaObj,
        noOfItems,
        currentLocation,
        userComment

    } = props;

    return (
        <div style={{ padding: '30px' }}>

            <Stack >
                <Typography style={{ height: '40px', width: '100%' }} color="gray_base" >Reason </Typography>
                <Typography style={{ height: '40px', width: '5%' }} color="gray_base">:</Typography>
                <Typography style={{ height: '40px', width: '100%', display: 'flex', justifyContent: 'flex-start' }} color="gray_darkest" pattern="bold" className="eco-ml-4">
                    {selectedMsaObj?.stockReason}
                </Typography>
            </Stack>
            <Stack >
                <Typography style={{ height: '40px', width: '100%' }} color="gray_base" >Comment</Typography>
                <Typography style={{ height: '40px', width: '5%' }} color="gray_base">:</Typography>
                <Typography style={{ height: '40px', width: '100%', display: 'flex', justifyContent: 'flex-start' }} color="gray_darkest" pattern="bold" className="eco-ml-4">
                    {userComment}
                </Typography>
            </Stack>
            <Stack >
                <Typography style={{ height: '40px', width: '100%' }} color="gray_base" >Adjusted Date and Time</Typography>
                <Typography style={{ height: '40px', width: '5%' }} color="gray_base">:</Typography>
                <Typography style={{ height: '40px', width: '100%', display: 'flex', justifyContent: 'flex-start' }} color="gray_darkest" pattern="bold" className="eco-ml-4">
                    {selectedMsaObj?.stockDatenTime}
                </Typography>
            </Stack>
            <Stack >
                <Typography style={{ height: '40px', width: '100%' }} color="gray_base" >Location</Typography>
                <Typography style={{ height: '40px', width: '5%' }} color="gray_base">:</Typography>
                <Typography style={{ height: '40px', width: '100%', display: 'flex', justifyContent: 'flex-start' }} color="gray_darkest" pattern="bold" className="eco-ml-4">
                    {currentLocation}
                </Typography>
            </Stack>
            <Stack >
                <Typography style={{ height: '40px', width: '100%' }} color="gray_base" >Device</Typography>
                <Typography style={{ height: '40px', width: '5%' }} color="gray_base">:</Typography>
                <Typography style={{ height: '40px', width: '100%', display: 'flex', justifyContent: 'flex-start' }} color="gray_darkest" pattern="bold" className="eco-ml-4">
                    {selectedMsaObj?.stockDevice}
                </Typography>
            </Stack>
            <Stack >
                <Typography style={{ height: '40px', width: '100%' }} color="gray_base" >No. of Items</Typography>
                <Typography style={{ height: '40px', width: '5%' }} color="gray_base">:</Typography>
                <Typography style={{ height: '40px', width: '100%', display: 'flex', justifyContent: 'flex-start' }} color="gray_darkest" pattern="bold" className="eco-ml-4">
                    {noOfItems}
                </Typography>
            </Stack>
            <Stack >
                <Typography style={{ height: '40px', width: '100%' }} color="gray_base" >Adjusted by </Typography>
                <Typography style={{ height: '40px', width: '5%' }} color="gray_base">:</Typography>
                <Typography style={{ height: '40px', width: '100%', display: 'flex', justifyContent: 'flex-start' }} color="gray_darkest" pattern="bold" className="eco-ml-4">
                    {selectedMsaObj?.stockAdjustedBy}
                </Typography>
            </Stack>


        </div>

    )
}

export default MsaInformationForm