import { Button, Divider, Empty, Space, message } from 'antd';
import { LoaderView } from 'ecologital-ui-library';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './AppsViewStyles.css'

import {
    getNisaPromotionByBarCode,
    addNisaPromotionsUpdateRequest,
} from '../../../utilities/apiRequests/witmegNisaServerRequests';

import SearchResults from './subSections/SearchResults';
import PromotionSearchByBarCode from './subSections/PromotionSearchByBarCode';
import BarCodeScanResults from './subSections/BarCodeScanResults';
// import { Alert, Button, Tabs } from 'antd';
// import { LoaderView } from 'ecologital-ui-library';
// import { Formik } from 'formik';

const dashboardSelector = (state) => () => state.dashboard;

const NisaPromotionsDownload = () => {
    const dashboardState = useSelector(dashboardSelector)();
    const [productList, setProductList] = useState([]);
    const [columnOrder, setColumnOrder] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fetchingStatus, setFetchingStatus] = useState('default');
    const [selectedItems, setSelectedItems] = useState([]);


    const handleSearchByKey = async (searchOptionVal, searchKeyVal) => {

        if (searchOptionVal === "barcode") {
            const prodData = getNisaPromotionByBarCode({
                OrgID: dashboardState.dashboard.currentlySelectedOrgId,
                RequestValue: searchKeyVal
            });
            await prodData.then((data) => {
                // setProductList();

                let productListArr = [...productList, ...data.Result];
                let selectedListArr = [...selectedItems, ...data.Result];
                let uniqProductList = _.uniqBy(productListArr, 'ID');
                let uniqSelectedList= _.uniqBy(selectedListArr, 'ID');
                setProductList(uniqProductList);
                setSelectedItems(uniqSelectedList)
                setColumnOrder(data.ColumnOrder)
                setFetchingStatus('success');
                setLoading(false);
                return true;
            });

        } else {
            setLoading(false);
            return true;
        }
    }

    const handleSetSelectedItems = (itemList) => {
        setSelectedItems(itemList)
    }

    const sendUpdateProductsRequest = async () => {
        const requetArray = selectedItems.map(item => {
            return {
                ProductCode: item.ProductCode,
                InnerEAN: item.InnerEAN,
                EffectiveDate: item.EffectiveDate,
                EndDate: item.EndDate,
                Nsp: item.Nsp,
                NumberUnitsPack:  parseInt(item.NumberUnitsPack),
                Rsp: item.Rsp,
                ShortDescription: item.ShortDescription,
                Currency: item.Currency
            }
        })
        const productsUpdateData = addNisaPromotionsUpdateRequest({
            OrgID: dashboardState.dashboard.currentlySelectedOrgId,
            CloudLocationID: dashboardState.dashboard.currentlySelectedLocationId,
            Request: requetArray
        });
        await productsUpdateData.then((data) => {
            console.log('Product Update Data ===>>', data);
            message.success('Product Update Request Submitted');
            setSelectedItems([])
        });

    }

    return (
        <div>
            <PromotionSearchByBarCode handleSearchByKey={handleSearchByKey} />

            <Divider />
            <>
                {selectedItems.length > 0 ?
                    <div style={{ textAlign: 'right' }}>
                        <Space align='end'>
                            <Button type="primary" onClick={sendUpdateProductsRequest}>Download</Button>
                        </Space>
                    </div>
                    : null}
            </>


            {(() => {
                switch (fetchingStatus) {
                    case 'pending':
                        return <LoaderView className='ProductMasterView' isLoading />;
                    case 'error':
                        return <Empty />;
                    default:
                        return (
                            <>
                                {productList && productList.length > 0 ? (
                                    <BarCodeScanResults
                                        productList={productList}
                                        selectedItems={selectedItems}
                                        handleSetSelectedItems={handleSetSelectedItems}
                                        viewType='promotions'
                                        loading={loading}
                                        columnOrder={columnOrder}
                                    />
                                ) : null}
                            </>
                        );
                }
            })()}
        </div>
    );
};


export default NisaPromotionsDownload
