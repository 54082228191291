


/* eslint-disable no-shadow */

import { bindActionCreators } from '@reduxjs/toolkit';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import PurchaseOrderMasterView from '../../../../components/dashboardRelatedComps/PurchaseOrderMasterView/PurchaseOrderMasterView';
import PurchaseOrderSearcher from '../../../../components/dashboardRelatedComps/PurchaseOrderSearcher/PurchaseOrderSearcher';
import RouteContentWrapper from '../../../../components/widgets/RouteContentWrapper/RouteContentWrapper';
import { sectionName as dashboardSectionName } from '../../../../redux/slices/dashboard/dashboardConstants';
import { actions as PurchaseOrderPageSliceActions } from '../../../../redux/slices/dashboard/purchaseOrderPageSlice/purchaseOrderPageSlice';
import { routePaths } from '../../../../routes/routeConstants';
import './PurchaseOrderPage.css';

class PurchaseOrderPage extends Component {
	render() {
		return (
			<div className='PurchaseOrderPage'>
				<Switch>
					<Route path={routePaths.dashboard__purchase__purchase_orders__add} exact>
						{/* SIDE NOTE : Notice we are adding different "key" for Add & Edit Section Wrapper. This is done to make sure "<PurchaseOrderMasterView/>" component unmount on route change. */}

						<RouteContentWrapper title='Add Purchase Order' key={1}>
							<PurchaseOrderMasterView />
						</RouteContentWrapper>
					</Route>

					<Route path={routePaths.dashboard__purchase__purchase_orders__edit} exact>
						<RouteContentWrapper title='Edit Purchase Order' key={2}>
							<PurchaseOrderMasterView />
						</RouteContentWrapper>
					</Route>

					<Route path={routePaths.dashboard__purchase__purchase_orders__search} exact>
						<RouteContentWrapper title='Search Purchase Order'>
							<PurchaseOrderSearcher />
						</RouteContentWrapper>
					</Route>

					{/* Default */}
					<Route path={routePaths.dashboard__purchase__purchase_orders}>
						<Redirect to={routePaths.dashboard__purchase__purchase_orders__search} />
					</Route>
				</Switch>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	PurchaseOrderPageSliceState: state[dashboardSectionName].PurchaseOrderPage,
});

const mapDispatchToProps = (dispatch) => {
	const boundPurchaseOrderPageSliceActions = bindActionCreators(
		PurchaseOrderPageSliceActions,
		dispatch,
	);

	return {
		PurchaseOrderPageSliceActions: boundPurchaseOrderPageSliceActions,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderPage);
