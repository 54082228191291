// NOTE : Depending on used type (In "productSearcherType" prop), Some customizations are made on the <ProductSearcher/> Component.

// SIDE NOTE : <ProductSearcher/> component originally created to show products list in only Products Page.
// 							But later there was other places (Ex. On Add Proforma Page) we need to show product list with some modifications.
// 							So we have done some changes, given ways to achieve that. So while exploring the relevant code , keep in mind that.

export const productSearcherTypes = {
	genericType: {
		key: 'genericType',
	},
	proformaType: {
		key: 'proformaType',
	},
};
