import React, { Component } from 'react';

import NisaApp from '../../../../components/dashboardRelatedComps/AppsMasterView/NisaApp';
import NisaPromotionsDownload from '../../../../components/dashboardRelatedComps/AppsMasterView/NisaPromotionsDownload';
import RouteContentWrapper from '../../../../components/widgets/RouteContentWrapper/RouteContentWrapper';
import '../DashboardHomePage/DashboardHomePage.css';

export default function AppsPage(props) {
	console.log("props.....", props)
	return (
		<div>
			<RouteContentWrapper>
				{(() => {
					switch (props.app) {
						case 'nisa':
							return <NisaApp />
						case 'nisa-promotions':
							return <NisaPromotionsDownload />
						default:
							return null
					}
				})()}
			</RouteContentWrapper>
		</div>
	);

}



