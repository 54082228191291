import {
	SearchOutlined as SearchOutlinedIcon,
	SelectOutlined as SelectOutlinedIcon,
} from '@ant-design/icons';
import { Button, Descriptions } from 'antd';
import { HeaderBar } from 'ecologital-ui-library';
import { Form, DatePicker, Select } from 'formik-antd';
import lodash from 'lodash';
import React, { Component } from 'react';

import CustomModal from '../../../../widgets/CustomModal/CustomModal';
import CustomerSearcher from '../../../CustomerSearcher/CustomerSearcher';
import commonProps from '../../helpers/commonProps';
import { getFormSectionsPartialObjPaths } from '../../helpers/parserHelpers';
import {
	proformaOrderTypesList,
	proformaDeliveryTypesList,
	proformaStatusTypesList,
} from '../../helpers/placeholderData';

import CustomerInputSearcher from './helperComponents/CustomerInputSearcher/CustomerInputSearcher';

import './PromvMasterTab.css';

// Default Form.Items Label Size.
const defaultLabelColProps = {
	span: 4,
	style: {
		whiteSpace: 'pre-wrap',
	},
};

// Form.Items Label Size used when Form is divided to two sections.
const dividedSectionLabelColProps = {
	...defaultLabelColProps,
	span: 6,
};

const { customerInfoObjPath, baseProformaInfoObjPath } =
	getFormSectionsPartialObjPaths().masterTab;

class PromvMasterTab extends Component {
	generateHelperFunctionsAndDataToGetNecessaryInfo() {
		const { formikProps, parserFunctions } = this.props;

		const { getFormValueByPath } = parserFunctions;

		// Function to handle customer selecting from CustomerList.
		function onCustomerSelected(data = {}) {
			const {
				selectedRowData, // From CustomList OR CustomInputSearcher Component.
				modalProps = {}, // From Modal Component.
			} = data;

			const mergedFormikState = lodash.merge({}, formikProps.values, {
				masterSection: {
					customerInformation: {
						isCustomerSelected: true,
						customerId: selectedRowData.ID,
						customerName: selectedRowData.CompanyName,
						rawCustomerData: selectedRowData,
					},
				},
			});

			formikProps.setValues(mergedFormikState, true);

			if (lodash.isFunction(modalProps.toggleModal)) {
				modalProps.toggleModal();
			}
		}

		// Extracting multiple data values, which need to shown in UI.
		function getDisplayInfoOfCustomerAdditionalDataSet() {
			const rawCustomerData =
				getFormValueByPath(`${customerInfoObjPath}rawCustomerData`) || {};

			const contactKeysData = rawCustomerData.Contact || {};
			const customerKeysData = rawCustomerData.Customer || {};

			return {
				customerName: rawCustomerData.CompanyName,
				accountNumber: customerKeysData.AccountNumber,
				postCode: contactKeysData.PostCode,

				balanceOutstanding: customerKeysData.BalanceOutstanding,
				creditLimit: customerKeysData.CreditLimit,
				creditPeriod: customerKeysData.CreditPeriod
					? `${customerKeysData.CreditPeriod} Days`
					: '',
			};
		}

		return {
			helperFns: {
				onCustomerSelected,
				getDisplayInfoOfCustomerAdditionalDataSet,
			},
			helperData: {},
		};
	}

	render() {
		const { selectedOrgId, selectedLocationId, formikProps, parserFunctions } =
			this.props;

		const { helperFns } =
			this.generateHelperFunctionsAndDataToGetNecessaryInfo();

		const customerAdditionalDisplayDataSet =
			helperFns.getDisplayInfoOfCustomerAdditionalDataSet();

		return (
			<div className='PromvMasterTab'>
				<Form
					labelCol={{ ...defaultLabelColProps }}
					colon={false}
					labelAlign='left'>
					<div>
						<div className='ProformaMasterView-UTL--gridWrapper'>
							<div className='ProformaMasterView-UTL--gridWrapper___leftColumn'>
								<div className='PromvMasterTab__customerInfoSection ProformaMasterView-UTL--formSubSection'>
									<HeaderBar subTitle={<b>CUSTOMER SELECTION</b>} />

									{/* Customer Select Through Modal */}
									<CustomModal
										toggler={
											<Button icon={<SearchOutlinedIcon />}>
												Select Customer
											</Button>
										}
										togglerWrapperStyles={{
											display: 'flex',
											justifyContent: 'flex-end',
											marginBottom: '15px',
										}}
										modalProps={{
											title: 'SELECT CUSTOMER',
											style: { paddingLeft: '20px', paddingTop: '25px' }, // Making this modal look like nested on parent one.
										}}>
										{(modalProps) => {
											return (
												<CustomerSearcher
													// Adding custom action/button to "Actions Column" of CustomerList.
													// Simply this button is used to get clicked customers data, back to here.
													customerListCustomizationProps={{
														customActionColumnButtonGenerator: (data = {}) => {
															const { selectedRowData } = data;

															return (
																<SelectOutlinedIcon
																	title='Select'
																	onClick={() =>
																		helperFns.onCustomerSelected({
																			selectedRowData,
																			modalProps,
																		})
																	}
																/>
															);
														},
													}}
												/>
											);
										}}
									</CustomModal>

									{/* Customer Select Through Inline Searching */}
									<div>
										<Form.Item
											name='TEMP'
											label='Customer'
											labelCol={{
												...defaultLabelColProps,
												sm: { span: 4 },
												xl: { span: 2 },
											}}>
											<CustomerInputSearcher
												selectedOrgId={selectedOrgId}
												selectedLocationId={selectedLocationId}
												helperFns={helperFns}
												parserFunctions={parserFunctions}
											/>
										</Form.Item>
									</div>
								</div>

								<div className='PromvMasterTab__baseProformaInfoSection ProformaMasterView-UTL--formSubSection'>
									<HeaderBar subTitle={<b>BASE INFORMATION</b>} />

									<div className='PromvMasterTab__baseProformaInfoSection__inputsWrapper'>
										<Form.Item
											name={`${baseProformaInfoObjPath}proformaDate`}
											label='Proforma Date'
											labelCol={{ ...dividedSectionLabelColProps }}>
											<DatePicker
												name={`${baseProformaInfoObjPath}proformaDate`}
												style={{ width: '100%' }}
												allowClear={false}
											/>
										</Form.Item>

										<Form.Item
											name={`${baseProformaInfoObjPath}proformaDeliveryMethod`}
											label='Method'
											labelCol={{ ...dividedSectionLabelColProps }}>
											<Select
												name={`${baseProformaInfoObjPath}proformaDeliveryMethod`}
												options={proformaDeliveryTypesList.map((item) => {
													return {
														label: item.label,
														value: item.value,
													};
												})}
											/>
										</Form.Item>

										<Form.Item
											name={`${baseProformaInfoObjPath}proformaOrderType`}
											label='Order Type'
											labelCol={{ ...dividedSectionLabelColProps }}>
											<Select
												name={`${baseProformaInfoObjPath}proformaOrderType`}
												options={proformaOrderTypesList.map((item) => {
													return {
														label: item.label,
														value: item.value,
													};
												})}
											/>
										</Form.Item>

										<Form.Item
											name={`${baseProformaInfoObjPath}proformaStatus`}
											label='Status'
											labelCol={{ ...dividedSectionLabelColProps }}>
											<Select
												name={`${baseProformaInfoObjPath}proformaStatus`}
												options={proformaStatusTypesList.map((item) => {
													return {
														label: item.label,
														value: item.value,
													};
												})}
											/>
										</Form.Item>
									</div>
								</div>
							</div>

							<div className='ProformaMasterView-UTL--gridWrapper___rightColumn'>
								{/* Additional Data about Selected Customer */}
								<div>
									<div className='PromvMasterTab__customerAdditionalInfoSection'>
										<HeaderBar subTitle={<b>CUSTOMER DETAILS</b>} />

										<Descriptions
											bordered
											size='small'
											column={1}
											layout='horizontal'
											{...commonProps.additionalDetails__descriptionsCompCommonProps}>
											<Descriptions.Item label='Pending Proforma'>
												-
											</Descriptions.Item>
											<Descriptions.Item label='Partially Picked'>
												-
											</Descriptions.Item>
											<Descriptions.Item label='Pick Completed'>
												-
											</Descriptions.Item>
											<Descriptions.Item label='Pending Invoices'>
												-
											</Descriptions.Item>
											<Descriptions.Item label='Credit Notes'>
												-
											</Descriptions.Item>

											<div style={{ height: '10px' }} />

											<Descriptions.Item label='Customer Name'>
												{customerAdditionalDisplayDataSet.customerName || '-'}
											</Descriptions.Item>
											<Descriptions.Item label='Account Number'>
												{customerAdditionalDisplayDataSet.accountNumber || '-'}
											</Descriptions.Item>
											<Descriptions.Item label='Available Credit'>
												-
											</Descriptions.Item>
											<Descriptions.Item label='Balance Outstanding'>
												{customerAdditionalDisplayDataSet.balanceOutstanding ||
													'-'}
											</Descriptions.Item>
											<Descriptions.Item label='Over Due Amount'>
												-
											</Descriptions.Item>
											<Descriptions.Item label='Credit Limit'>
												{customerAdditionalDisplayDataSet.creditLimit || '-'}
											</Descriptions.Item>
											<Descriptions.Item label='Credit Period'>
												{customerAdditionalDisplayDataSet.creditPeriod || '-'}
											</Descriptions.Item>
											<Descriptions.Item label='Currency'>-</Descriptions.Item>
										</Descriptions>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Form>
			</div>
		);
	}
}

export default PromvMasterTab;
