import { HeaderBar } from 'ecologital-ui-library';
import React from 'react';

import './ProductOverviewSection.css';

// Helper component to Group main items of a Overview Section.
const ProductOverviewSection = (props = {}) => {
	const { title, children, className = '' } = props;

	return (
		<div className={`ProductOverviewSection ${className}`}>
			<div className='ProductOverviewSection__header'>
				<HeaderBar title={title} />
			</div>

			<div className='ProductOverviewSection__body'>{children}</div>
		</div>
	);
};

export default ProductOverviewSection;
