import { Button } from 'antd';
import React from 'react';
import { Link, Redirect } from 'react-router-dom';

import { routePaths } from '../../routes/routeConstants';
import { checkIsUserLoggedIn } from '../../utilities/userAuthentication';

import './HomePage.css';

import logo from "../../assets/img/logo.png"

function HomePage() {
	const userLogged = checkIsUserLoggedIn();

	// If user already logged redirecting to 'dashboard'.
	if (userLogged) {
		return <Redirect to={routePaths.dashboardRoot} />;
	}

	return (
		<div className='HomePage'>
			<div>
				<h1>Web Neurolage</h1>
				<div>
					<img
						src={logo}
						alt='Neurolage Logo'
					/>
				</div>

				<Button type='primary'>
					<Link to={routePaths.loginRoot}>LOGIN</Link>
				</Button>
			</div>
		</div>
	);
}

export default HomePage;
