/* eslint-disable react/no-array-index-key */
/* eslint-disable react/sort-comp */

import {
	PlusCircleOutlined as PlusCircleOutlinedIcon,
	OneToOneOutlined as OneToOneOutlinedIcon,
	TabletFilled as TabletFilledIcon,
	ShoppingCartOutlined as ShoppingCartOutlinedIcon,
	ReconciliationOutlined as ReconciliationOutlinedIcon,
	ImportOutlined as ImportOutlinedIcon,
	GiftOutlined as GiftOutlinedIcon,
} from '@ant-design/icons';
import { Button, Select } from 'antd';
import { LoaderView, Timeline } from 'ecologital-ui-library';
import { DateTime } from 'luxon';
import React, { Component } from 'react';

import { reqStatusTypes } from '../../../../../redux/helpers/constants';
import { getSummaryOfStockChangesForTimeline } from '../../../../../utilities/apiRequests/witmegWebNeurolageServerRequests';
import { errorLogger } from '../../../../../utilities/errorHandlers';
import ProductOverviewSection from '../../helperComponents/ProductOverviewSection/ProductOverviewSection';

import './ProOveTimelineSection.css';

const timelineDurationTypes = [
	/**
	 * NOTE : Regarding some keys. Currently,
	 * 					- "value" ---> Just an Index Value.
	 * 					- "durationAsDateFnObj" --> This is what mostly used for date calculations.
	 */
	{ label: 'Today', value: 0, durationAsDateFnObj: { day: 0 } },
	{ label: 'Yesterday', value: 1, durationAsDateFnObj: { day: 1 } },
	{ label: 'Last Week', value: 2, durationAsDateFnObj: { week: 1 } },
	{ label: 'Last Month', value: 3, durationAsDateFnObj: { month: 1 } },
	{ label: 'Last Year', value: 4, durationAsDateFnObj: { year: 1 } },
	{ label: 'All', value: 5, durationAsDateFnObj: 'ALL' },
].map((item, index) => {
	return { ...item, index };
});

export default class ProOveTimelineSection extends Component {
	constructor(props) {
		super(props);

		this.state = {
			rawTimelineDataReqState: reqStatusTypes.idle,
			rawTimelineDataReqError: '',
			rawTimelineData: {},

			selectedTimelineDurationTypeIndex: 3, // Default selecting "Last Month"
		};
	}

	parseRawTimelineData() {
		const { rawTimelineData = {} } = this.state;

		// Helper function to handle and return necessary data depended on "Timeline Entry Type"
		function handleTimelineEntryType(type = '', timelineEntry = {}) {
			// These will update on appropriate switch case, depending on "Timeline Entry Type" of given entry.
			let dataKeysToBeShowOnTimelineCard = []; // Track what data should be displayed on a Timeline Card. (So in this list we have, "On what object keys those data will be")
			let icon = <div />; // Track what data should be displayed on the Icon beside Timeline Card.
			let iconBackColor = ''; // Track what color shown in background of icon.

			switch (type) {
				case 'Product Creation': {
					dataKeysToBeShowOnTimelineCard = [
						'User',
						'PurchaseUnit',
						'SellingPrice',
					];

					icon = <PlusCircleOutlinedIcon title={timelineEntry.Type} />;
					iconBackColor = '#f58634';
					break;
				}
				case 'Opening Stock': {
					dataKeysToBeShowOnTimelineCard = [
						'User',
						'UnitChart',
						'ClosingStock',
					];

					icon = <OneToOneOutlinedIcon title={timelineEntry.Type} />;
					iconBackColor = '#ED5E93';
					break;
				}

				case 'Sales': {
					dataKeysToBeShowOnTimelineCard = ['ClosingStock'];

					icon = <ShoppingCartOutlinedIcon title={timelineEntry.Type} />;
					iconBackColor = '#5B63B7';
					break;
				}

				case 'Manual Stock Update': {
					dataKeysToBeShowOnTimelineCard = ['User', 'ClosingStock'];

					icon = <ReconciliationOutlinedIcon title={timelineEntry.Type} />;
					iconBackColor = '#2f5d62';
					break;
				}

				case 'GRN': {
					dataKeysToBeShowOnTimelineCard = [
						'User',
						'UnitChart',
						'GRNRef',
						'Supplier',
						'ClosingStock',
					];

					icon = <ImportOutlinedIcon title={timelineEntry.Type} />;
					iconBackColor = '#289672';
					break;
				}

				case 'Repacking': {
					dataKeysToBeShowOnTimelineCard = [
						'User',
						'UnitChart',
						'GRNRef',
						'Supplier',
						'ClosingStock',
					];

					icon = <GiftOutlinedIcon title={timelineEntry.Type} />;
					iconBackColor = '#810000';
					break;
				}

				default: {
					dataKeysToBeShowOnTimelineCard = ['Type'];

					icon = <TabletFilledIcon title={timelineEntry.Type} />;
					iconBackColor = 'gray';
					break;
				}
			}

			return {
				rawData: timelineEntry, // All the raw data received for this entry.
				dataKeysToBeShowOnTimelineCard,

				formattedDate: DateTime.fromISO(timelineEntry.Date).toLocaleString(
					DateTime.DATETIME_SHORT,
				), // Mostly will be used as Entry Label.

				icon,
				iconBackColor,
			};
		}

		// Parsing/Generating, All the data need to displayed for each of Timeline Entry.
		return Object.entries(rawTimelineData).map((entry) => {
			// eslint-disable-next-line no-unused-vars
			const [timeStamp, timelineEntry] = entry;

			return handleTimelineEntryType(timelineEntry.Type, timelineEntry);
		});
	}

	async fetchTimelineData() {
		const { overviewData = {}, selectedLocationId = '' } = this.props;
		const { selectedTimelineDurationTypeIndex } = this.state;

		// Helper function to determine from which day "Timeline Entry" is needed.
		// NOTE : Note that currently, Regardless of the specified date "Product Created" entry is always returned by the API.
		function getStartDate() {
			const durationAsObj =
				timelineDurationTypes[selectedTimelineDurationTypeIndex]
					.durationAsDateFnObj;

			const todayDate = DateTime.now().startOf('day');

			let startDate = '';
			if (durationAsObj === 'ALL') {
				startDate = undefined; // Because API return all entries when "StartDate" param is not provided.
			} else {
				startDate = todayDate.minus(durationAsObj).toISO();
			}

			return startDate;
		}

		try {
			this.setState({
				rawTimelineDataReqState: reqStatusTypes.pending,
				rawTimelineDataReqError: '',
			});

			const rawTimelineData = await getSummaryOfStockChangesForTimeline({
				locationId: selectedLocationId,
				productId: overviewData.productId,
				limit: 20,
				startDate: getStartDate(),
			});

			this.setState({
				rawTimelineDataReqState: reqStatusTypes.succeeded,
				rawTimelineData,
			});
		} catch (error) {
			errorLogger(error);

			this.setState({
				rawTimelineDataReqState: reqStatusTypes.failed,
				rawTimelineDataReqError: 'Error Occurred While Getting Timeline Data.',
			});
		}
	}

	async fetchNecessaryInitialData() {
		this.fetchTimelineData();
	}

	componentDidMount() {
		this.fetchNecessaryInitialData();
	}

	render() {
		const {
			rawTimelineDataReqState,
			rawTimelineDataReqError,

			selectedTimelineDurationTypeIndex,
		} = this.state;

		const timelineData = this.parseRawTimelineData();

		const isAnyMainActionsRunning =
			rawTimelineDataReqState === reqStatusTypes.pending;
		const isAnyMainActionsError =
			rawTimelineDataReqState !== reqStatusTypes.pending &&
			rawTimelineDataReqError;

		return (
			<ProductOverviewSection
				title='TIMELINE'
				className='ProOveTimelineSection'>
				{/* When Loading OR Error Occurred */}
				{(isAnyMainActionsRunning || isAnyMainActionsError) && (
					<LoaderView
						isLoading={isAnyMainActionsRunning}
						isError={isAnyMainActionsError}
						typeConfigs={{
							error: {
								extra: (
									<Button
										type='primary'
										onClick={() => {
											this.fetchNecessaryInitialData();
										}}>
										TRY AGAIN
									</Button>
								),
							},
						}}
					/>
				)}

				{/* When Everything Is OK */}
				{!isAnyMainActionsRunning && !isAnyMainActionsError && (
					<div className='ProOveTimelineSection__mainWrapper'>
						<div>
							<Select
								style={{
									width: '300px',
								}}
								options={timelineDurationTypes}
								value={
									timelineDurationTypes[selectedTimelineDurationTypeIndex].value
								}
								onChange={(selectedValue, selectedOption) => {
									this.setState(
										{
											selectedTimelineDurationTypeIndex: selectedOption.index,
										},
										() => {
											this.fetchTimelineData();
										},
									);
								}}
							/>
						</div>

						<div className='ProOveTimelineSection____timelineWrapper'>
							<Timeline mode='alternate'>
								{/* Showing Timeline Entry For Each */}
								{timelineData.map((timelineEntry, index) => {
									return (
										<Timeline.Item
											key={index}
											className='ProOveTimelineSection__timelineEntry'
											dot={timelineEntry.icon}
											color={timelineEntry.iconBackColor}
											label={timelineEntry.formattedDate}
											mobileLabel={timelineEntry.formattedDate}>
											<Timeline.Card innerTitle={timelineEntry.rawData.Title}>
												{timelineEntry.dataKeysToBeShowOnTimelineCard.map(
													(item, index2) => {
														return (
															<div key={index2}>
																{timelineEntry.rawData[item]}
															</div>
														);
													},
												)}
											</Timeline.Card>
										</Timeline.Item>
									);
								})}

								{/* NOTE : This is added to fix UI ugliness when there is only one entry.  */}
								{timelineData.length === 1 && (
									<Timeline.Item
										className='ProOveTimelineSection__timelineEntry'
										dot={<TabletFilledIcon />}
										label='END'
										mobileLabel='END'
									/>
								)}
							</Timeline>
						</div>
					</div>
				)}
			</ProductOverviewSection>
		);
	}
}
