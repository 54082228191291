import lodash from 'lodash';
import * as Yup from 'yup';

import { customerTypesList, tierTypesList } from './placeholderData';

// Common "Error Texts" to be used in Yup Validations.
export const errorTexts = {
	required: 'Required.',
	tooShort: 'Too Short.',
	tooLong: 'Too Long.',
	positiveNumber: 'Must be a Positive Number',
	numberRequired: 'Required.',
};

// Generate Full "Base Initial State" for all the forms in different tabs.
// NOTE : This is declared as function because,
// 					- For some mode (Especially "EDIT" mode) we have to pass and set already existing values as form initial values.
// 					- For some keys we have to calculate dynamic initial state on run. So for those keys we can pass dynamic function that return necessary value.
export const generateFullBaseInitialState = (data = {}) => {
	const {
		preFilledInitialValues = {}, // Values passed in here will be merged with Default Initial State values.
		dynamicInitialValueGetters = {}, // These are mostly come from "generateDynamicInitialValueGetterFunctions" at the bottom of this file.
	} = data;

	const defaultInitialState = {
		// Form in Master Tab.
		masterSection: {
			baseInformation: {
				customerType:
					dynamicInitialValueGetters.masterTab.getDefaultCustomerType(),
				customerStatus: '',
				companyName: '',
				firstName: '',
				lastName: '',
			},

			addressInformation: [
				// Any number of below structure objects.
				{
					addressType: '',
					addressLine1: '',
					addressLine2: '',
					city: '',
					stateProvinceRegion: '',
					postCode: '',
					country: '',
					contactNumber: '',
					faxNumber: '',
					email: '',
				},
			],

			settingsInformation: {
				creditLimit: '',
				creditPeriodAsDays: '',
				memberNumber: '',
				paymentMethod: '',
				customerVatNumber: '',
				openingBalance: '',
				bulkDiscount: '',
				tier: dynamicInitialValueGetters.masterTab.getDefaultTierType(),
				accountNumber:
					dynamicInitialValueGetters.masterTab.generateDefaultAccountNumber(),
				salesRep: '',
				isVatExemption: false,
				isEmailProforma: false,
				isEmailInvoice: false,
			},
		},
	};

	return lodash.merge(defaultInitialState, preFilledInitialValues);
};

// Generate Full "Validation Scheme" for all the forms in different tabs.
// This structure is/should similar to above "fullBaseInitialState" Object.
export const fullValidationScheme = Yup.object().shape({
	// Form in Master Tab.
	masterSection: Yup.object().shape({
		baseInformation: Yup.object().shape({
			customerType: Yup.string().required(errorTexts.required),
			customerStatus: Yup.string().required(errorTexts.required),
			companyName: Yup.string()
				.min(2, errorTexts.tooShort)
				.max(50, errorTexts.tooLong)
				.required(errorTexts.required),
			firstName: Yup.string()
				.min(2, errorTexts.tooShort)
				.max(20, errorTexts.tooLong)
				.required(errorTexts.required),
			lastName: Yup.string()
				.min(2, errorTexts.tooShort)
				.max(40, errorTexts.tooLong)
				.required(errorTexts.required),
		}),

		addressInformation: Yup.array()
			.of(
				Yup.object().shape({
					addressType: Yup.string().required(errorTexts.required),
					addressLine1: Yup.string().required(errorTexts.required),
					addressLine2: Yup.string().notRequired(),
					city: Yup.string().required(errorTexts.required),
					stateProvinceRegion: Yup.string().notRequired(),
					postCode: Yup.string().notRequired(),
					country: Yup.string().required(errorTexts.required),
					contactNumber: Yup.string().required(errorTexts.required),
					faxNumber: Yup.string().notRequired(),
					email: Yup.string()
						.email('Invalid Email.')
						.required(errorTexts.required),
				}),
			)
			.min(1),

		settingsInformation: Yup.object().shape({
			creditLimit: Yup.number()
				.typeError('You Must Specify a Credit Limit.')
				.required(errorTexts.required),
			creditPeriodAsDays: Yup.number()
				.typeError('You Must Specify a Credit Period.')
				.required(errorTexts.required),
			memberNumber: Yup.string().notRequired(),
			paymentMethod: Yup.string().notRequired(),
			customerVatNumber: Yup.string().notRequired(),
			openingBalance: Yup.number().typeError('Not a Number.').notRequired(),
			bulkDiscount: Yup.number().typeError('Not a Number.').notRequired(),
			tier: Yup.string().notRequired(),
			accountNumber: Yup.string().notRequired(),
			salesRep: Yup.string().notRequired(),
			isVatExemption: Yup.string().notRequired(),
			isEmailProforma: Yup.string().notRequired(),
			isEmailInvoice: Yup.string().notRequired(),
		}),
	}),
});

// Map "Raw API Customer Data" into "Formik Initial State" Structure. (Especially used in "EDIT" mode)
export function mapApiCustomerDataToFormikFormStructure(data = {}) {
	const { customerData = {}, salesRepsList = [] } = data;

	// ******** Below is multiple helper functions to extract necessary data for each tab, which will be used in bottom of this main function.

	function getMasterTabRelatedMapperHelperFunctions() {
		function getAddressListArray() {
			const rawAddressList = customerData.Addresses;

			return rawAddressList.map((address) => {
				return {
					addressType: address.AddressType,
					addressLine1: address.AddressLine1,
					addressLine2: address.AddressLine2,
					city: address.City,
					stateProvinceRegion: address.StateProvinceRegion,
					postCode: address.PostZipCode,
					country: address.Country,
					contactNumber: address.Phone,
					faxNumber: address.Fax,
					email: address.Email,
				};
			});
		}

		function getSalesRepIndex() {
			const index = salesRepsList.findIndex((item) => {
				return item.CloudStaffID === customerData.Customer.CloudSalesRepID;
			});

			if (index >= 0) {
				return index;
			} else {
				return '';
			}
		}

		return { getAddressListArray, getSalesRepIndex };
	}

	const mapperHelperFns = {
		masterTab: getMasterTabRelatedMapperHelperFunctions(),
	};

	// *** Mapping Data

	return {
		// Form in Master Tab.
		masterSection: {
			baseInformation: {
				customerType: customerData.Customer.CustomerTypeID,
				customerStatus: customerData.Customer.CustomerStatus,
				companyName: customerData.CompanyName,
				firstName: customerData.FirstName,
				lastName: customerData.LastName,
			},

			addressInformation: mapperHelperFns.masterTab.getAddressListArray(),

			settingsInformation: {
				creditLimit: customerData.Customer.CreditLimit,
				creditPeriodAsDays: Number(customerData.Customer.CreditPeriod),
				memberNumber: customerData.Customer.MemberNumber,
				paymentMethod: customerData.DefaultPaymentType,
				customerVatNumber: customerData.CustomerVatNo,
				openingBalance: customerData.Customer.OpeningBalance,
				bulkDiscount: customerData.Customer.BulkDiscount,
				tier: customerData.Customer.Tier,
				accountNumber: customerData.Customer.AccountNumber,
				salesRep: mapperHelperFns.masterTab.getSalesRepIndex(),
				isVatExemption: customerData.Customer.Isvatexemption,
				isEmailProforma: customerData.Customer.IsEmailProforma,
				isEmailInvoice: customerData.Customer.IsEmailInvoice,
			},
		},
	};
}

// Generate set of helper functions, to be used as dynamic/customized initial value getters for formik initial value keys.
// Directly used by "generateFullBaseInitialState()".
export function generateDynamicInitialValueGetterFunctions(data = {}) {
	const { dashboardSliceState } = data;

	function getMasterTabRelatedGetterFunctions() {
		const getDefaultCustomerType = () => {
			return customerTypesList.find((i) => i.default).customerTypeID || '';
		};

		const getDefaultTierType = () => {
			const defaultTierValue = tierTypesList.find((i) => i.default).value;

			return defaultTierValue === undefined ? '' : defaultTierValue;
		};

		const generateDefaultAccountNumber = () => {
			const { currentlySelectedOrgIndex, fullUserOrgDetailsList } =
				dashboardSliceState;

			const selectedOrgData = fullUserOrgDetailsList[currentlySelectedOrgIndex];
			const currentlySelectedOrgName =
				selectedOrgData.OrganizationName || 'ABC';

			const prefix = currentlySelectedOrgName
				.replace(/ /g, '')
				.slice(0, 3)
				.toUpperCase();
			const suffix = Number.parseInt(Math.random() * 10000, 10);

			return prefix + suffix;
		};

		return {
			getDefaultCustomerType,
			getDefaultTierType,
			generateDefaultAccountNumber,
		};
	}

	return {
		masterTab: getMasterTabRelatedGetterFunctions(),
	};
}
