// Function to generate "Set of Helper Functions" that can easily extract data from "Customer List" related API response's object. (For Ex. Response from "/v1/epos/customers/search/email Endpoint.)
export const generateCustomerDataObjParserFunctions = (
	rawCustomerData = {},
	otherData = {},
) => {
	const getCompanyName = () => {
		/// SIDE NOTE : "CompanyName" AKA "CustomerName"
		return rawCustomerData.CompanyName || '';
	};

	const getCreditPeriodWithDuration = () => {
		const creditPeriodNumericValue = rawCustomerData.Customer.CreditPeriod;
		const creditPeriodDurationValue = 'DAYS';

		// Ex. Output : "30 Days"
		return creditPeriodNumericValue
			? `${creditPeriodNumericValue} ${creditPeriodDurationValue}`
			: '';
	};

	return { getCompanyName, getCreditPeriodWithDuration };
};
