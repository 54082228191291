// Helper function to open given link in JS Way.
export function openLink(href = '') {
	if (!href) {
		return;
	}

	const linkElem = document.createElement('a');
	linkElem.target = '_blank';
	linkElem.href = href;
	linkElem.click();
}
