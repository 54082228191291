import {
	PlusCircleOutlined as PlusCircleOutlinedIcon,
	CloudUploadOutlined as CloudUploadOutlinedIcon,
} from '@ant-design/icons';
import { Button } from 'antd';
import { LoaderView } from 'ecologital-ui-library';
import { Form, Input, InputNumber, Select } from 'formik-antd';
import Jimp from 'jimp';
import lodash from 'lodash';
import React, { Component } from 'react';
import { toast } from 'react-toastify';

import globalValues from '../../../../../../../configs/globalValues';
import { uploadImage } from '../../../../../../../utilities/apiRequests/witmegEcoServicesServerRequests';
import { getFormSectionsPartialObjPaths } from '../../../../helpers/parserHelpers';
import { imageUploaderTypes } from '../../../../helpers/placeholderData';
import { generateRelevantCustomPropertyInput } from '../../../PmvCustomTab/helpers/helpers';

import './PmvImageTabImageUploader.css';

const { imageUploaderInfoObjPath } = getFormSectionsPartialObjPaths().imagesTab;

export default class PmvImageTabImageUploader extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isUploading: false,
			previewImageBlob: '', // Uploading Image Blob.
		};
	}

	generateHelperFunctionsAndDataToGetNecessaryInfo() {
		const { parserFunctions } = this.props;

		const { getFormValueByPath } = parserFunctions;

		function getImageUploaderRelatedFieldsConfig(
			fieldStatusVal,
			existingWholeValueSet = {}, // Relevant Full formik section. (Ex. formikProps.touched)
		) {
			const imageUploaderRelatedFiledNames = [
				'imageMainType',
				'imageFile',
				'imageUnitType',
				'imageCustomType',
				'imageCustomTypeValue',

				'customSizeWidth',
				'customSizeHeight',
				'customSizeUnit',
				'imageQuality',
				'alphaValue',
				'waterMark',
				'fontSize',
			];

			const existingData = lodash.cloneDeep(existingWholeValueSet);

			const fieldsConfig = lodash.merge(
				{
					imageSection: {
						imageUploaderInformation: {
							// Fields will be added here.
						},
					},
				},
				existingData,
			);

			// If object passed just add that config object to uploaderSection key. Otherwise make every related filed has same passed value.
			if (lodash.isPlainObject(fieldStatusVal)) {
				fieldsConfig.imageSection.imageUploaderInformation = lodash.merge(
					fieldsConfig.imageSection.imageUploaderInformation,
					fieldStatusVal,
				);
			} else {
				imageUploaderRelatedFiledNames.forEach((fieldName) => {
					fieldsConfig.imageSection.imageUploaderInformation[fieldName] =
						fieldStatusVal;
				});
			}

			return fieldsConfig;
		}

		function getIsUploaderOpen() {
			return getFormValueByPath(`${imageUploaderInfoObjPath}uploaderOpened`);
		}

		function getSelectedImageMainType() {
			return getFormValueByPath(`${imageUploaderInfoObjPath}imageMainType`);
		}

		function getSelectedFile() {
			return getFormValueByPath(`${imageUploaderInfoObjPath}imageFile`);
		}

		function getImageCustomizationRelatedValues() {
			const imgUploaderRelatedData =
				getFormValueByPath('imageSection.imageUploaderInformation') || {};

			const {
				customSizeWidth,
				customSizeHeight,
				imageQuality,
				alphaValue,
				waterMark,
				fontSize,
			} = imgUploaderRelatedData;

			return {
				imageWidth: customSizeWidth,
				imageHeight: customSizeHeight,
				imageQuality,
				imageOpacity: alphaValue,
				watermarkText: waterMark,
				watermarkFontSize: fontSize,
			};
		}

		return {
			helperFns: {
				getImageUploaderRelatedFieldsConfig,
				getImageCustomizationRelatedValues,
			},
			helperData: {
				isUploaderOpen: getIsUploaderOpen(),
				selectedImageMainType: getSelectedImageMainType(),
				selectedFile: getSelectedFile(),
			},
		};
	}

	async manipulateImage(rawFile, options = {}) {
		const {
			imageWidth = 500,
			imageHeight = 500,
			imageQuality = 100,
			imageOpacity = 100,
			watermarkText = '',
			watermarkFontSize = 12,
		} = options;

		// Helper function to Read File (Received From <Input type='file'/>) into Buffer.
		function readFile(file) {
			return new Promise((resolve, reject) => {
				const fr = new FileReader();

				fr.addEventListener('load', () => {
					resolve(fr.result);
				});
				fr.addEventListener('error', reject);
				fr.readAsArrayBuffer(file);
			});
		}

		// Helper function to add Text to Given "Blob Type" Image File.
		function addTextToImage(file, options = {}) {
			const { text = '', fontSize = 12 } = options;

			return new Promise((resolve, reject) => {
				// Create a hidden canvas object, So we can use do some img manipulation on it.
				const canvas = document.createElement('canvas');
				const context = canvas.getContext('2d');
				canvas.width = imageWidth;
				canvas.height = imageHeight;
				canvas.id = 'PmvImageTabImageUploader--Canvas';
				canvas.style.visibility = 'hidden';
				document.body.append(canvas);

				// Creating New Image Object and adding it to Canvas. Then we do further editing (Ex. Adding Text)
				const imageObj = new Image();
				imageObj.src = URL.createObjectURL(file);
				imageObj.addEventListener('load', async () => {
					context.drawImage(imageObj, 0, 0);

					// Adding Text
					context.lineWidth = 1;
					context.fillStyle = '#1010103d';
					context.font = `${fontSize}px sans-serif`;
					context.fillText(text, 20, imageHeight / 2, imageWidth);

					// Converting Current Canvas into Blob and Returning it.
					canvas.toBlob((blob) => {
						// Removing canvas from DOM.
						canvas.remove();

						resolve(blob);
					});
				});

				imageObj.addEventListener('error', async (error) => {
					reject(error);
				});
			});
		}

		const rawImageFile = await readFile(rawFile);

		// Doing multiple image manipulation using 'Jimp'.
		const manipulatedImageBlob = await Jimp.read(rawImageFile).then(
			async (image) => {
				image.background(0xffffffff); // Specifying empty background color;
				image.contain(imageWidth, imageHeight);
				image.quality(imageQuality);
				image.opacity(imageOpacity / 100);

				const imageAsBuffer = await image.getBufferAsync(image.getMIME());
				return new Blob([imageAsBuffer]);
			},
		);

		const finalBlob = await addTextToImage(manipulatedImageBlob, {
			text: watermarkText,
			fontSize: watermarkFontSize,
		});

		// Retuning Manipulated Image File as File. (This File Type is similar to What we receive from <Input type='file'/>)
		return new File([finalBlob], rawFile.name, {
			type: rawFile.type,
		});
	}

	toggleUploader() {
		const { formikProps } = this.props;

		const { helperFns, helperData } =
			this.generateHelperFunctionsAndDataToGetNecessaryInfo();

		const { getImageUploaderRelatedFieldsConfig } = helperFns;

		const { isUploaderOpen } = helperData;

		// Resetting Preview Blob.
		this.setState({
			previewImageBlob: '',
		});

		// Making all upload related fields not touched. So validation errors are not applied when just opened.
		formikProps.setTouched(
			{
				...getImageUploaderRelatedFieldsConfig(false, formikProps.touched),
			},
			false,
		);

		formikProps.setValues(
			{
				...getImageUploaderRelatedFieldsConfig(
					{
						imageFile: '', // Resetting Image Value.
						uploaderOpened: !isUploaderOpen, // Updating Open State.
					},
					formikProps.values,
				),
			},
			false,
		);
	}

	async uploadImage() {
		const {
			formikProps,

			// Passed from Parent Comp.
			onImageUploaderSuccessFn, // Custom function to be passed defining what to do after upload success. (Ex. Add Uploaded image to ImageViewer List)
			barCode,
			currentOrgName,
		} = this.props;

		const { helperData, helperFns } =
			this.generateHelperFunctionsAndDataToGetNecessaryInfo();

		const {
			getImageUploaderRelatedFieldsConfig,
			getImageCustomizationRelatedValues,
		} = helperFns;
		const { selectedFile } = helperData;

		try {
			this.setState({
				isUploading: true,
			});

			// Validating ImageUploader related fields.
			await formikProps
				.setTouched({
					...getImageUploaderRelatedFieldsConfig(true, formikProps.touched),
				})
				.then((validationErrors) => {
					if (
						validationErrors.imageSection &&
						validationErrors.imageSection.imageUploaderInformation
					) {
						// Throwing here to avoid, rest  of below code being executed.
						throw new Error('IGNORE');
					}
				});

			const timeStamp = Math.round(new Date().getTime() / 1000);
			const environmentFolderName =
				globalValues.environment.CURRENT_ENVIRONMENT === 'production'
					? 'production'
					: 'dev';
			const orgFolderName = currentOrgName.replace(/ /g, ''); // Removing Spaces.
			const imgCustomizationData = getImageCustomizationRelatedValues();

			const fileName = `P_${barCode}_${timeStamp}`;
			const filePath = `/${environmentFolderName}/${orgFolderName}/erp/`; // Side Note: In here "erp" is synonym for "Web Neurolage"
			const manipulatedFile = await this.manipulateImage(
				selectedFile,
				imgCustomizationData,
			);

			await uploadImage({
				file: manipulatedFile,
				filePath,
				fileName,
			})
				.then((imgCdnLink) => {
					onImageUploaderSuccessFn(imgCdnLink);
					this.toggleUploader();
				})
				.catch((error) => {
					throw error;
				});

			this.setState({
				isUploading: false,
			});
		} catch (error) {
			if (error.message !== 'IGNORE') {
				toast.error('Error Occurred While Uploading Data.');
			}

			this.setState({
				isUploading: false,
			});
		}
	}

	render() {
		const { isUploading, previewImageBlob } = this.state;

		const {
			formikProps,
			defaultLabelColProps,

			imageTypes,
			unitTypeList,
			customPropertyTypesList,
			selectedCustomTypeData,
		} = this.props;

		const { helperData } =
			this.generateHelperFunctionsAndDataToGetNecessaryInfo();

		const { isUploaderOpen, selectedImageMainType } = helperData;

		const dividedSectionLabelColProps = {
			...defaultLabelColProps,
			span: 6,
		};

		return (
			<div className='PmvImageTabImageUploader'>
				{isUploading && (
					<div className='PmvImageTabImageUploader__uploadingProgressView'>
						<LoaderView
							className='ProductMasterView'
							isLoading
							isError={false}
						/>
					</div>
				)}

				{/* When Uploader Not Opened */}
				{isUploaderOpen === false && (
					<div className='PmvImageTabImageUploader__formOpenerButton'>
						<Button
							icon={<CloudUploadOutlinedIcon />}
							onClick={() => {
								this.toggleUploader();
							}}>
							ADD IMAGE
						</Button>
					</div>
				)}

				{/* When Uploader is Opened */}
				{isUploaderOpen && (
					<div className='PmvImageTabImageUploader__formItemsWrapper'>
						<div className='PmvImageTabImageUploader__formItemsWrapper__sectionDivider'>
							<div>
								<div className='PmvImageTabImageUploader__formItemsWrapper____uploadSectionHeader'>
									Main Upload Info :
								</div>

								<Form.Item
									name={`${imageUploaderInfoObjPath}imageMainType`}
									label='Image Type'
									labelCol={{ ...dividedSectionLabelColProps }}>
									<Select
										name={`${imageUploaderInfoObjPath}imageMainType`}
										options={imageTypes.map((i) => {
											return { value: i.type, label: i.label };
										})}
									/>
								</Form.Item>

								{/* When MainImageType is UnitType */}
								{selectedImageMainType ===
									imageUploaderTypes.unitChart.type && (
									<Form.Item
										name={`${imageUploaderInfoObjPath}imageUnitType`}
										label='Sub Type' // Image Unit Type
										labelCol={{ ...dividedSectionLabelColProps }}>
										<Select
											name={`${imageUploaderInfoObjPath}imageUnitType`}
											options={unitTypeList.map((i) => {
												return {
													value: i.UnitTypeName,
													label: i.UnitTypeName,
												};
											})}
										/>
									</Form.Item>
								)}

								{selectedImageMainType === imageUploaderTypes.special.type && (
									<Form.Item
										name={`${imageUploaderInfoObjPath}imageCustomType`}
										label='Sub Type' // Image Special Type
										labelCol={{ ...dividedSectionLabelColProps }}>
										<Select
											name={`${imageUploaderInfoObjPath}imageCustomType`}
											options={customPropertyTypesList.map((i, index) => {
												return {
													value: index,
													label: i.label,
												};
											})}
										/>
									</Form.Item>
								)}

								{selectedImageMainType === imageUploaderTypes.special.type &&
									generateRelevantCustomPropertyInput(
										selectedCustomTypeData,
										`${imageUploaderInfoObjPath}imageCustomTypeValue`,
										{
											formItemProps: {
												label: 'Sub Type Value',
												labelCol: { ...dividedSectionLabelColProps },
											},
										},
									)}

								<Form.Item
									name={`${imageUploaderInfoObjPath}imageFile`}
									label='Image'
									labelCol={{ ...dividedSectionLabelColProps }}>
									<div className='PmvImageTabImageUploader____imageSelector'>
										<input
											type='file'
											accept='image/*'
											id='PmvImageTabImageUploader--FileInput'
											style={{ display: 'none' }}
											name={`${imageUploaderInfoObjPath}imageFile`}
											onChange={(event) => {
												const selectedRawFile = event.currentTarget.files[0];

												// Extra Layer to Avoid Non Image Files.
												if (
													!selectedRawFile ||
													!selectedRawFile.type.includes('image')
												) {
													return;
												}

												formikProps.setFieldValue(
													`${imageUploaderInfoObjPath}imageFile`,
													selectedRawFile,
													true,
												);

												this.setState({
													previewImageBlob:
														URL.createObjectURL(selectedRawFile),
												});
											}}
										/>

										<Button
											icon={<PlusCircleOutlinedIcon />}
											onClick={() => {
												// NOTE : Toggling File Input.
												// 					- Doing this way because styling "FileInput" is hard.
												document
													.querySelector('#PmvImageTabImageUploader--FileInput')
													.click();
											}}>
											SELECT IMAGE
										</Button>

										{previewImageBlob && (
											<div className='PmvImageTabImageUploader____imageSelector__imagePreview'>
												<img
													src={previewImageBlob}
													id='PmvImageTabImageUploader--FileInputPreview'
													alt='Preview'
												/>
											</div>
										)}
									</div>
								</Form.Item>
							</div>

							<div>
								<div className='PmvImageTabImageUploader__formItemsWrapper____uploadSectionHeader'>
									Image Customization Info :
								</div>

								<Form.Item
									name={`${imageUploaderInfoObjPath}customSizeWidth`}
									label='Custom Size'
									labelCol={{ ...dividedSectionLabelColProps }}>
									<div style={{ display: 'flex', alignItems: 'flex-end' }}>
										<InputNumber
											name={`${imageUploaderInfoObjPath}customSizeWidth`}
											precision={0}
											min={100}
											max={500}
										/>
										<div>&nbsp;&nbsp;X&nbsp;&nbsp; </div>
										<InputNumber
											name={`${imageUploaderInfoObjPath}customSizeHeight`}
											precision={0}
											min={100}
											max={500}
										/>
										<div>&nbsp;&nbsp; </div>
										<Select
											name={`${imageUploaderInfoObjPath}customSizeUnit`}
											options={['PX'].map((i) => {
												return { value: i, label: i };
											})}
										/>
									</div>
								</Form.Item>

								<Form.Item
									name={`${imageUploaderInfoObjPath}imageQuality`}
									label='Image Quality'
									labelCol={{ ...dividedSectionLabelColProps }}>
									<InputNumber
										name={`${imageUploaderInfoObjPath}imageQuality`}
										precision={0}
										min={1}
										max={100}
									/>
								</Form.Item>

								<Form.Item
									name={`${imageUploaderInfoObjPath}alphaValue`}
									label='Alpha'
									labelCol={{ ...dividedSectionLabelColProps }}>
									<InputNumber
										name={`${imageUploaderInfoObjPath}alphaValue`}
										precision={0}
										min={1}
										max={100}
									/>
								</Form.Item>

								<Form.Item
									name={`${imageUploaderInfoObjPath}waterMark`}
									label='Water Mark'
									labelCol={{ ...dividedSectionLabelColProps }}>
									<Input name={`${imageUploaderInfoObjPath}waterMark`} />
								</Form.Item>

								<Form.Item
									name={`${imageUploaderInfoObjPath}fontSize`}
									label='Font Size'
									labelCol={{ ...dividedSectionLabelColProps }}>
									<InputNumber
										name={`${imageUploaderInfoObjPath}fontSize`}
										precision={0}
										min={8}
										max={72}
									/>
								</Form.Item>
							</div>
						</div>

						<Button
							icon={<CloudUploadOutlinedIcon />}
							style={{ background: '#90e4cd', color: 'rgba(0, 0, 0, 0.85)' }}
							onClick={() => this.uploadImage()}>
							Upload
						</Button>
						<Button
							onClick={() => {
								this.toggleUploader();
							}}>
							Close
						</Button>
					</div>
				)}
			</div>
		);
	}
}
