import lodash from 'lodash';
import React from 'react';

import ImageViewer from '../../../../widgets/ImageViewer/ImageViewer';
import ProductOverviewSection from '../../helperComponents/ProductOverviewSection/ProductOverviewSection';

import './ProOveImagesSection.css';

const ProOveImagesSection = (props) => {
	const { overviewData = {} } = props;

	return (
		<ProductOverviewSection title='IMAGES' className='ProOveImagesSection'>
			<div className='ProOveImagesSection___imageViewerWrapper'>
				<ImageViewer
					imageList={overviewData.productImageList.map((img) => {
						return {
							// Data Strictly Needed By <ImageViewer/>
							imgTitle: `${img.imgCategory} - ${lodash.startCase(
								img.imgKeyTitle,
							)}`,
							imgUrl: img.imgUrl,

							// Additional Data, For further decision making.
							additionalData: {
								...img,
								isMainImage: Boolean(img.isMainImage),
							},
						};
					})}
				/>
			</div>
		</ProductOverviewSection>
	);
};

export default ProOveImagesSection;
