/* eslint-disable no-shadow */

import {
	SelectOutlined as SelectOutlinedIcon,
	SearchOutlined as SearchOutlinedIcon,
} from '@ant-design/icons';
import { Input } from 'formik-antd';
import React, { Component } from 'react';

import { getEposCustomersByCompanyName } from '../../../../../../../utilities/apiRequests/witmegWebNeurolageServerRequests';
import FieldErrorMessage from '../../../../../../widgets/formRelated/FieldErrorMessage/FieldErrorMessage';
import CustomInputSearcher from '../../../../../../widgets/formRelated/OmniBar/OmniBar';
import CustomerList from '../../../../../CustomerList/CustomerList';
import { getFormSectionsPartialObjPaths } from '../../../../helpers/parserHelpers';

const { customerInfoObjPath } = getFormSectionsPartialObjPaths().masterTab;

// NOTE : Helper component to give user inline Customer Searching Ability.

export default class CustomerInputSearcher extends Component {
	render() {
		const { selectedOrgId, selectedLocationId, helperFns } = this.props;

		return (
			<CustomInputSearcher
				//
				// This is what initially displayed to user. Clicking on this invoke popup area and searching.
				displayComponent={
					<div>
						<Input
							name={`${customerInfoObjPath}customerName`}
							prefix={<SearchOutlinedIcon />}
							placeholder='Start Typing to Inline Search Customers'
						/>
						<FieldErrorMessage
							name={`${customerInfoObjPath}customerName`}
							style={{
								position: 'absolute',
							}}
						/>
					</div>
				}
				//
				// Will be invoked when Search Input is changing. Its result will passed to bottom "popupContentGenerator()"
				onSearchInputChange={async (data = {}) => {
					const { searchTerm } = data;

					return await getEposCustomersByCompanyName({
						orgId: selectedOrgId,
						locationId: selectedLocationId,
						searchKey: searchTerm,
					});
				}}
				//
				// Helper function to determine no items for its internal purposes.
				isEmptyResultChecker={(data = {}) => {
					const { rawSearchResult = {} } = data;
					return rawSearchResult.Result && rawSearchResult.Result.length === 0;
				}}
				//
				// Determine what to show in popup area.
				popupContentGenerator={(data = {}) => {
					const { rawSearchResult, closePopup } = data;

					return (
						<div>
							<CustomerList
								tableCompProps={{
									pagination: {
										pageSize: 100,
									},
								}}
								rawCustomerList={rawSearchResult.Result}
								customizationProps={{
									customRawColumnListGenerator: (data = {}) => {
										return {
											CustomerId: {
												ColumnName: 'Customer ID',
												IsFilter: true,
												IsVisible: true,
												ValuePath: 'ID',
											},
											CustomerName: {
												ColumnName: 'Name',
												IsFilter: true,
												IsVisible: true,
												ValuePath: 'Customer.Name',
											},
											PostCode: {
												ColumnName: 'PostCode',
												IsFilter: true,
												IsVisible: true,
												ValuePath: 'Contact.PostCode',
											},
										};
									},
									customActionColumnButtonGenerator: (data = {}) => {
										const { selectedRowData } = data;

										return (
											<SelectOutlinedIcon
												title='Select'
												onClick={() => {
													helperFns.onCustomerSelected({
														selectedRowData,
														...data,
													});

													closePopup();
												}}
											/>
										);
									},
								}}
							/>
						</div>
					);
				}}
			/>
		);
	}
}
