import React from 'react'
import {
    Row,
    Col,
    InputBox,
    Label,
    TextArea

} from "witmeg-ui-system";
import './formStyles.css'

function BrandInformationForm(props) {
    const {
        brandInformationValues,
        handleChangeBrandInfoInputs,
        validations
    } = props;

    const handleChangeInput = (e, fieldName) => {

        console.log('event handler result', e.target.value.trim(), 'and', fieldName)

        let updatedBrandInformationIValues = {
            ...brandInformationValues,
            [fieldName]: e.target.value.trim()
        }

        handleChangeBrandInfoInputs(updatedBrandInformationIValues, fieldName)
    }

    return (
        <div >

            <Row className="form-row" >
                <Col md={7}><div className="form-lbl-wrapper"><Label required={true}>Brand Name</Label></div></Col>
                <Col md={16}>
                    <InputBox
                        type="text"
                        placeholder="Brand Name"
                        value={brandInformationValues.brandName}
                        onChange={(e) => handleChangeInput(e, 'brandName')}
                        error={validations.brandName}
                    />
                </Col>
            </Row>
            <Row className="form-row">
                <Col md={7}><div className="form-lbl-wrapper"><Label>Brand Description</Label></div></Col>
                <Col md={16}>
                    <TextArea
                        type="text"
                        placeholder="Brand Description"
                        rows='5'
                        value={brandInformationValues.description}
                        onChange={(e) => handleChangeInput(e, 'description')}
                        maxLength='100'
                    />
                </Col>
            </Row>
        </div>
    )
}

export default BrandInformationForm