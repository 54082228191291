import globalValues from '../configs/globalValues';

const { IS_DEVELOPMENT } = globalValues.environment;

export const genericErrorHandler = (
	error,
	defaultErrMsg = 'Error Occurred. Please Try Again.',
) => {
	if (IS_DEVELOPMENT) {
		console.log(error);
	}

	let errMsg = '';

	switch (true) {
		case error.message.includes('Network Error'):
			errMsg = 'Network Error. Please check your connection.';
			break;

		default:
			errMsg = defaultErrMsg;
			break;
	}

	return { errMsg };
};

export function APIError(
	customErrMsg = 'Error Occurred. Please try again.',
	orgError = '',
	otherDetails = {},
) {
	const error = new Error(orgError || customErrMsg);

	error.customErrType = 'APIERROR';
	error.customErrMsg = customErrMsg;

	return error;
}

// Helper utility to log errors to console. (To make it easy to track errors.)
// Logs only shown on developer mode.
export function errorLogger(error, options = {}) {
	console.customLog('DEV', new Error(error), options);
}
