import { PlusCircleOutlined as PlusCircleOutlinedIcon } from '@ant-design/icons';
import { Button, Descriptions } from 'antd';
import { HeaderBar } from 'ecologital-ui-library';
import { Form } from 'formik-antd';
import React, { Component } from 'react';

import CustomModal from '../../../../widgets/CustomModal/CustomModal';
import commonProps from '../../helpers/commonProps';
import { getFormSectionsPartialObjPaths } from '../../helpers/parserHelpers';

import ProductFullSearcher from './subComponents/ProductFullSearcher/ProductFullSearcher';
import ProductInlineSearcher from './subComponents/ProductInlineSearcher/ProductInlineSearcher';
import ProductItemList from './subComponents/ProductItemList/ProductItemList';
import PurchaseOrderTotals from './subComponents/PurchaseOrderTotals/PurchaseOrderTotals';
import './PromvItemsTab.css';
import { getUnitTypesByOrgId } from '../../../../../utilities/apiRequests/witmegWebNeurolageServerRequests';

// Default Form.Items Label Size.
const defaultLabelColProps = {
	span: 4,
	style: {
		whiteSpace: 'pre-wrap',
	},
};

const { addedItemsInfoObjPath } = getFormSectionsPartialObjPaths().itemsTab;

class PromvItemsTab extends Component {
	constructor(props) {
		super(props);

		this.state = {
			unitTypes: []
		};
	}

	generateHelperFunctionsAndDataToGetNecessaryInfo() {
		const { formikProps, parserFunctions } = this.props;

		const { getFormValueByPath } = parserFunctions;

		// Function to handle a product adding to the list.
		function onProductAdding(data = {}) {
			const {
				selectedRowData, // From ProductList Component.
				additionalData, // From ProductList Component ---> Adding PopOver.
			} = data;

			const { purchaseQty } = additionalData;

			const currentItemsList = getFormValueByPath(
				`${addedItemsInfoObjPath}addedItemsList`,
			).slice();

			const selectedRowDataWithAdditionalData = {
				...selectedRowData,
				___manuallyAddedData: {
					purchaseQty,
				},
			};

			const newItemsList = currentItemsList.concat(
				selectedRowDataWithAdditionalData,
			);

			formikProps.setFieldValue(
				`${addedItemsInfoObjPath}addedItemsList`,
				newItemsList,
			);
		}

		// Function to handle product removing from the list.
		function onProductRemoving(data = {}) {
			const {
				// From ProductList Component.
				selectedRowData,
				rowIndex,
			} = data;

			const currentItemsList = getFormValueByPath(
				`${addedItemsInfoObjPath}addedItemsList`,
			).slice();

			currentItemsList.splice(rowIndex, 1);
			const modifiedItemsList = currentItemsList;

			formikProps.setFieldValue(
				`${addedItemsInfoObjPath}addedItemsList`,
				modifiedItemsList,
			);
		}

		// Function to handle a product editing from the list.
		function onProductEditing(data = {}) {
			const {
				// From ProductList Component.
				selectedRowData,
				rowIndex,
			} = data;

			const currentItemsList = getFormValueByPath(
				`${addedItemsInfoObjPath}addedItemsList`,
			).slice();

			currentItemsList[rowIndex] = selectedRowData;
			const modifiedItemsList = currentItemsList;

			formikProps.setFieldValue(
				`${addedItemsInfoObjPath}addedItemsList`,
				modifiedItemsList,
			);
		}

		// Function to handle a product hoovering on a specific item in the list.
		function onProductItemHoverIn(data = {}) {
			const {
				// From ProductList Component.
				selectedRowData,
				rowIndex,
			} = data;

			formikProps.setFieldValue(
				`${addedItemsInfoObjPath}selectedItemIndexForMoreInfo`,
				rowIndex,
			);
		}

		function onProductItemHoverOut(data = {}) {
			// Resetting Selected Product Item.
			formikProps.setFieldValue(
				`${addedItemsInfoObjPath}selectedItemIndexForMoreInfo`,
				'',
			);
		}

		// Extracting multiple data values, which need to shown in UI.
		function getDisplayInfoOfSelectedItemAdditionalDataSet() {
			const selectedItemIndex = getFormValueByPath(
				`${addedItemsInfoObjPath}selectedItemIndexForMoreInfo`,
			);

			const isItemSelected = Number.isInteger(selectedItemIndex);

			let selectedItemData = {};
			if (isItemSelected) {
				const fullItemsList = getFormValueByPath(
					`${addedItemsInfoObjPath}addedItemsList`,
				);

				selectedItemData = fullItemsList[selectedItemIndex] || {};
			}

			return {
				itemNo: selectedItemData.ItemCode,
				availableQuantity: selectedItemData.AvailableStock,
				costPrice: '-',
				sellingPrice: selectedItemData.SellingPrice,
				maxDiscount: '-',
				qtyInBox: selectedItemData.PackSize,
				category: selectedItemData.CloudProductCategoryID,
				subCategory: '-',
				images: '-',
			};
		}

		return {
			helperFns: {
				onProductAdding,
				onProductEditing,
				onProductRemoving,
				onProductItemHoverIn,
				onProductItemHoverOut,
				getDisplayInfoOfSelectedItemAdditionalDataSet,
			},
			helperData: {},
		};
	}

	componentDidMount = async () => {
		const { selectedOrgId } = this.props
		const unitTypes = await getUnitTypesByOrgId({
			OrgID: selectedOrgId,
		});
		// console.log("unitTypes===>>", unitTypes)
		this.setState({
			unitTypes: unitTypes
		})
	}

	render() {
		const { selectedOrgId, selectedLocationId, formikProps, parserFunctions } =
			this.props;

		const { helperFns } =
			this.generateHelperFunctionsAndDataToGetNecessaryInfo();

		const selectedItemAdditionalDisplayDataSet =
			helperFns.getDisplayInfoOfSelectedItemAdditionalDataSet();

		return (
			<div className='PromvItemsTab'>
				<Form
					labelCol={{ ...defaultLabelColProps }}
					colon={false}
					labelAlign='left'>
					<div className='ProformaMasterView-UTL--gridWrapper'>
						<div className='ProformaMasterView-UTL--gridWrapper___leftColumn'>
							<div className='PromvItemsTab__itemsInfoSection ProformaMasterView-UTL--formSubSection'>
								<HeaderBar subTitle={<b>ITEMS LIST</b>} />

								{/* Adding Products Through Modal */}
								{/* <CustomModal
									toggler={
										<Button icon={<PlusCircleOutlinedIcon />}>
											Add Product
										</Button>
									}
									togglerWrapperStyles={{
										display: 'flex',
										justifyContent: 'flex-end',
										marginBottom: '15px',
									}}
									modalProps={{
										title: 'SELECT PRODUCTS TO ADD',
										style: { paddingLeft: '20px', paddingTop: '25px' }, // Making this modal look like nested on parent one.
									}}>
									{(modalProps) => {
										return <ProductFullSearcher helperFns={helperFns} />;
									}}
								</CustomModal> */}

								{/* Adding Products Through Inline Searching */}
								{/* <div style={{ marginBottom: '12px' }}>
									<ProductInlineSearcher
										selectedOrgId={selectedOrgId}
										selectedLocationId={selectedLocationId}
										helperFns={helperFns}
										parserFunctions={parserFunctions}
									/>
								</div> */}

								<ProductItemList
									selectedOrgId={selectedOrgId}
									selectedLocationId={selectedLocationId}
									helperFns={helperFns}
									parserFunctions={parserFunctions}
									currentItems={this.state.unitTypes && this.state.unitTypes.length > 0 && this.props.purchaseOrderDetails && this.props.purchaseOrderDetails.PurchaseOrderDetail ?
										this.props.purchaseOrderDetails.PurchaseOrderDetail.map(ci => {
											return {
												...ci,
												UnitType: this.state.unitTypes.find(u => u.UnitTypeId === ci.UnitTypeID),
												UnitTypeName: this.state.unitTypes.find(u => u.UnitTypeId === ci.UnitTypeID).UnitTypeName,
												lineTotal: this.state.unitTypes.find(u => u.UnitTypeId === ci.UnitTypeID).UnitTypeName === "EA"
													? Number((ci.PurchasingPrice * ci.OrderQuantity) / ci.PackSize).toFixed(2)
													: Number(ci.PurchasingPrice * ci.OrderQuantity).toFixed(2),
												vatAmount: this.state.unitTypes.find(u => u.UnitTypeId === ci.UnitTypeID).UnitTypeName === "EA"
													? Number(ci.LineVat * ci.vatRate / ci.PackSize).toFixed(2)
													: Number(ci.LineVat * ci.vatRate).toFixed(2)
											}
										}) : null}
									handleSetQty={this.props.handleSetQty}
									unitTypes={this.state.unitTypes}
									handleDeletePOItem={this.props.handleDeletePOItem}
									statusCode={this.props.purchaseOrderDetails && this.props.purchaseOrderDetails.Status}
									setPOItemList={this.props.setPOItemList}
								/>


							</div>
							<div className='PromvItemsTab__itemsInfoSection ProformaMasterView-UTL--formSubSection'>
								<HeaderBar subTitle={<b>Totals</b>} />
								<div>
									<PurchaseOrderTotals currentItems={this.state.unitTypes && this.state.unitTypes.length > 0 && this.props.purchaseOrderDetails && this.props.purchaseOrderDetails.PurchaseOrderDetail ?
										this.props.purchaseOrderDetails.PurchaseOrderDetail.map(ci => {
											return {
												...ci,
												UnitType: this.state.unitTypes.find(u => u.UnitTypeId === ci.UnitTypeID),
												UnitTypeName: this.state.unitTypes.find(u => u.UnitTypeId === ci.UnitTypeID).UnitTypeName,
												lineTotal: this.state.unitTypes.find(u => u.UnitTypeId === ci.UnitTypeID).UnitTypeName === "EA"
													? Number((ci.PurchasingPrice * ci.OrderQuantity) / ci.PackSize).toFixed(2)
													: Number(ci.PurchasingPrice * ci.OrderQuantity).toFixed(2)
											}
										}) : null} />
								</div>
							</div>
						</div>

						<div className='ProformaMasterView-UTL--gridWrapper___rightColumn'>
							{/* Additional Data about Selected Item */}
							<div>
								<div className='PromvMasterTab__supplierAdditionalInfoSection'>
									<HeaderBar subTitle={<b>ITEM DETAILS</b>} />

									<Descriptions
										bordered
										size='small'
										column={1}
										layout='horizontal'
										{...commonProps.additionalDetails__descriptionsCompCommonProps}>
										<Descriptions.Item label='Item No'>
											{selectedItemAdditionalDisplayDataSet.itemNo || '-'}
										</Descriptions.Item>

										<Descriptions.Item label='Available Quantity'>
											{selectedItemAdditionalDisplayDataSet.availableQuantity ||
												'-'}
										</Descriptions.Item>

										<Descriptions.Item label='Cost Price'>
											{selectedItemAdditionalDisplayDataSet.costPrice || '-'}
										</Descriptions.Item>

										<Descriptions.Item label='Selling Price'>
											{selectedItemAdditionalDisplayDataSet.sellingPrice || '-'}
										</Descriptions.Item>

										<Descriptions.Item label='Category'>
											{selectedItemAdditionalDisplayDataSet.category || '-'}
										</Descriptions.Item>

										<Descriptions.Item label='Sub Category'>
											{selectedItemAdditionalDisplayDataSet.subCategory || '-'}
										</Descriptions.Item>

										<Descriptions.Item label='Images'>
											{selectedItemAdditionalDisplayDataSet.images || '-'}
										</Descriptions.Item>
									</Descriptions>
								</div>
							</div>
						</div>
					</div>
				</Form>
			</div>
		);
	}
}

export default PromvItemsTab;
