// NOTE : Common Props for <Descriptions> components used in each tabs additional details section.
const additionalDetails__descriptionsCompCommonProps = {
	labelStyle: {
		width: '175px',
		padding: '1px 10px',
		fontSize: '0.90em',
		background: '#EBFAC7',
		// whiteSpace: 'nowrap',
	},
	contentStyle: {
		padding: '1px 10px',
		fontSize: '0.90em',
		background: '#FBFFF0',
		// whiteSpace: 'nowrap',
	},
};

const commonProps = {
	additionalDetails__descriptionsCompCommonProps,
};

export default commonProps;
