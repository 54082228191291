import { bindActionCreators } from '@reduxjs/toolkit';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import CustomerMasterView from '../../../../components/dashboardRelatedComps/CustomerMasterView/CustomerMasterView';
import CustomerSearcher from '../../../../components/dashboardRelatedComps/CustomerSearcher/CustomerSearcher';
import RouteContentWrapper from '../../../../components/widgets/RouteContentWrapper/RouteContentWrapper';
import { actions as customersPageSliceActions } from '../../../../redux/slices/dashboard/customersPageSlice/customersPageSlice';
import { sectionName as dashboardSectionName } from '../../../../redux/slices/dashboard/dashboardConstants';
import { routePaths } from '../../../../routes/routeConstants';

import './CustomersPage.css';

class CustomersPage extends Component {
	render() {
		return (
			<div className='CustomersPage'>
				<Switch>
					<Route path={routePaths.dashboard__sales__customers__add} exact>
						{/* SIDE NOTE : Notice we are adding different "key" for Add & Edit Section Wrapper. This is done to make sure "CustomerMasterView" unmount on route change. */}

						<RouteContentWrapper title='Add Customer' key={1}>
							<CustomerMasterView />
						</RouteContentWrapper>
					</Route>

					<Route path={routePaths.dashboard__sales__customers__edit} exact>
						<RouteContentWrapper title='Edit Customer' key={2}>
							<CustomerMasterView />
						</RouteContentWrapper>
					</Route>

					<Route path={routePaths.dashboard__sales__customers__search} exact>
						<RouteContentWrapper title='Search Customers'>
							<CustomerSearcher />
						</RouteContentWrapper>
					</Route>

					{/* Default */}
					<Route path={routePaths.dashboard__sales__customers}>
						<Redirect to={routePaths.dashboard__sales__customers__search} />
					</Route>
				</Switch>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	customersPageSliceState: state[dashboardSectionName].customersPage,
});

const mapDispatchToProps = (dispatch) => {
	const boundCustomersPageSliceActions = bindActionCreators(
		customersPageSliceActions,
		dispatch,
	);

	return {
		customersPageSliceActions: boundCustomersPageSliceActions,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomersPage);
