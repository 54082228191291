import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Form,
    Switch,
    Select,
    InputBox,
    Label,
    Row,
    Col,
    Radio,
    Button,
    RightPanel,
    RightPanelHeader,
    Space,
    Alert,
    Accordion,
    Message,
    InlineMessage,
    Stack,
    Popup,
} from "witmeg-ui-system";
import { generateLocationListOfAllOrganizations } from "../../../../../../components/dashboardRelatedComps/LocationSelector/helpers/helperFns";
import { sectionName as dashboardSectionName } from "../../../../../../redux/slices/dashboard/dashboardConstants";
import CategoryInformationForm from "../../Category/Forms/CategoryInformationForm";
import {
    addEposProductCategory,
    getEposBarcodeByBarcodeId,
    getEposProductCategoryById,
} from "../../../../../../utilities/apiRequests/witmegWebNeurolageServerRequests";
import SubCategoryInformationForm from "../../Category/Forms/SubCategoryInformationForm";
import WarningMessagesForm from "../../Category/Forms/WarningMessagesForm";
import OnlineLookupForm from "./OnlineLookupForm";
import "./formStyles.css";
import "./BasicInformationForm.css";
import find from "lodash/find";

const BasicInformationForm = (props) => {
    // console.log("props", props);
    const {
        basicInformationIValues,
        basicInformationForm,
        onFinish,
        onFinishFailed,
        productCategories,
        brands,
        orgDetails,
        handleChangeBasicInfoInputs,
        validations,
        locationDetails,
        enableProductEditMode,
        reloadDataInMainCategory,
        sortResultsFromCreatedDate,
        allproductData,
    } = props;

    // const [selectedCategory, setSelectedCategory] = useState(null)
    const [categoryInformationForm] = Form.useForm();
    const subCategoryInfoPanel = useRef();
    const [subCategories, setSubCategories] = useState([]);
    const [locations, setLocations] = useState([]);
    const [showCategoryAddPanel, setShowCategoryAddPanel] = useState(false);
    const [productName, setProductName] = useState("");
    const [subCategoryInfo, setSubCategoryInfo] = useState(null);
    const [selectedCategoryObj, setSelectedCategoryObj] = useState(null);
    const [subCategoryInformationValues, setSubCategoryInformationValues] =
        useState([]);
    const [warningMessageInfo, setWarningMessageInfo] = useState(null);
    const [categoryInformationValues, setCategoryInformationValues] = useState(
        {}
    );
    const dashboardState = useSelector(
        (state) => state[dashboardSectionName].dashboard
    );
    const userState = useSelector((state) => state.user.userDetails);
    const [warningMessagesObj, setWarningMessagesObj] = useState({});
    const [getData, setGetData] = useState(null);
    const [rowData, setRowData] = useState(null);
    const [barCodeValue, setBarCodeValue] = useState(null);
    const [categoryInfo, setCategoryInfo] = useState(null);
    const [missingCategoryFieldNames, setMissingCategoryFieldNames] = useState(
        []
    );
    const [hasSubCategoryValidationIssues, setHasSubCategoryValidationIssues] =
        useState(false);
    const [apiValidationError, setApiValidationError] = useState(null);
    const [lookUpVisible, setLookUpVisible] = useState(false);
    const [barCodeData, setBarCodeData] = useState(null);
    const [subCategoryValue, setSubCategoryValue] = useState({});
    const [categoryInfoValidationErrors, setCategoryInfoValidationErrors] =
        useState({
            productName: false,
            mainCategory: false,
            brand: false,
            itemCode: false,
            barcode: false,
        });

    useEffect(() => {
        if (orgDetails && orgDetails.fullUserOrgDetailsList.length > 0) {
            let locationsList = generateLocationListOfAllOrganizations({
                dashboardSliceState: orgDetails,
            });
            setLocations(locationsList);
        }
    }, [orgDetails]);

    useEffect(() => {
        if (showCategoryAddPanel) {
            setCategoryInformationValues({
                categoryName: "",
                description: "",
                vat: 0,
                discount: false,
                showUnderShopSale: false,
                pointBasedDiscount: false,
            });

            setWarningMessagesObj({
                warningMessage: "",
                age: 18,
                soundFile: "",
            });
        } else {
            setCategoryInformationValues({});
        }
    }, [showCategoryAddPanel]);

    useEffect(() => {
        setProductName(basicInformationIValues.productName);
        // if(basicInformationIValues.productCategories && basicInformationIValues.)
        console.log("categoryInformationValues", categoryInformationValues);
    }, [basicInformationIValues]);

    const validateCategoryInfo = () => {
        let categoryInfoValidations = {
            categoryName: categoryInformationValues.categoryName === "",
            vat: categoryInformationValues.vat === "",
        };
        setCategoryInfoValidationErrors(categoryInfoValidations);
        setMissingCategoryFieldNames(
            [
                categoryInformationValues.categoryName === ""
                    ? "Category Name"
                    : null,
            ].filter((x) => x)
        );
        if (categoryInformationValues.categoryName !== "") {
            setCategoryInfo(categoryInformationValues);
        }
    };
    const handleSubmitAddCategory = async () => {
        validateCategoryInfo();
        setSubCategoryInfo(subCategoryInformationValues);
        setWarningMessageInfo(warningMessagesObj);
        setApiValidationError(null);
    };

    useEffect(async () => {
        if (categoryInfo && subCategoryInfo && warningMessageInfo) {
            const subCatInfo = subCategoryInfo.map((subCat) => {
                return {
                    OrgID: dashboardState.currentlySelectedOrgId,
                    PrdoctSubcategoryID: 0,
                    CategoryID: 0,
                    SubcategoryDescription: subCat.description,
                    Name: subCat.name,
                    CreatedDate: new Date().toISOString(),
                    CreatedBy: userState.UserId,
                    ModifiedDate: new Date().toISOString(),
                    ModifiedBy: userState.UserId,
                    IsSubCategoryDeleted: false,
                    CloudCreatedBy: userState.UserId,
                    CloudModifiedBy: userState.UserId,
                };
            });

            // console.log("added category ---->", rowData);
            const apiReqBody = {
                ProductCategory: {
                    ProductCategoryID: 0,
                    Category: categoryInformationValues.categoryName,
                    ProductDescription: categoryInformationValues.description,
                    CreatedDate: new Date().toISOString(),
                    CreatedBy: userState.UserId,
                    ModifiedDate: new Date().toISOString(),
                    ModifiedBy: userState.UserId,
                    VatRate: categoryInformationValues.vat / 100,
                    WarningMessage: warningMessageInfo.warningMessage,
                    SoundFile: warningMessageInfo.soundFile,
                    IsAlwaysVisibleWarning: false,
                    IsPercentageDiscount: categoryInformationValues.discount,
                    IsPointsBasedDiscount:
                        categoryInformationValues.pointBasedDiscount,
                    IsTotalShowInShopSales:
                        categoryInformationValues.showUnderShopSale,
                    IsproductCategoryDeleted: false,
                    OrgID: dashboardState.currentlySelectedOrgId,
                    CloudCreatedBy: userState.UserId,
                    CloudModifiedBy: userState.UserId,
                },
                ProductSubCategory: subCatInfo,
            };

            apiReqBody.ProductSubCategory = subCatInfo;

            let AddRes = await addEposProductCategory(apiReqBody);

            if (AddRes) {
                if (AddRes.hasError) {
                    setApiValidationError(AddRes);
                    return;
                }

                Message.success(
                    `Category (${categoryInformationValues.categoryName}) is added successfully.`
                );
                reloadDataInMainCategory();
                setShowCategoryAddPanel(false);
            } else {
                Message.error(`Category is not added. Please try again`);
            }
        }
    }, [categoryInfo, subCategoryInfo, warningMessageInfo]);

    const handleChangeMainCategory = (e) => {
        handleChangeSelect(e, "mainCategory");
        setSubCategories(
            productCategories.find((sc) => sc.ID === e.value)
                ?.ProductSubCategory
        );
        // setSubCategoryValue({});
    };

    const loadSubCategories = async () => {
        if (basicInformationIValues && basicInformationIValues.mainCategory) {
            const subs = await getProductCategoryDetails();
            setSubCategories(subs.ProductSubCategory);
            const exists = find(subs.ProductSubCategory, {
                ID: basicInformationIValues.subCategory.value,
            });

            exists
                ? setSubCategoryValue(basicInformationIValues.subCategory)
                : setSubCategoryValue({});
        }
    };

    useEffect(() => {
        let isMounted = false;
        !isMounted && loadSubCategories();

        return () => (isMounted = true);
    }, [basicInformationIValues?.mainCategory]);

    const handleChangeWarningMessageInputs = (values, updatedField) => {
        setWarningMessagesObj(values);
    };

    const Options = productCategories
        ? productCategories.map((pc) => {
              return {
                  value: pc.ID,
                  label: pc.ProductCategory.Category,
              };
          })
        : [];

    const productCategoryOptions = [
        ...Options,
        {
            custom: true,
            onClick: () => {
                handleCategoryViewPanel();
            },
            text: "Add Category",
        },
    ];

    const handleChangeCategoryInfoInputs = (values, updatedField) => {
        setCategoryInformationValues(values);
        setCategoryInfoValidationErrors({
            ...categoryInfoValidationErrors,
            [updatedField]: values[updatedField] === "",
        });
    };

    // const mainCategory = basicInformationIValues.mainCategory
    //     ? // {
    //       //   value: basicInformationIValues.mainCategory.ID,
    //       //   label: basicInformationIValues.mainCategory.Category
    //       // }
    //       productCategoryOptions[2]
    //     : {};

    const handleChangeInput = (e, fieldName) => {
        let updatedBasicInformationIValues = {
            ...basicInformationIValues,
            [fieldName]: e.target.value,
        };
        handleChangeBasicInfoInputs(updatedBasicInformationIValues, fieldName);
        // console.log(
        //     "updatedBasicInformationIValues------->",
        //     updatedBasicInformationIValues.barcode
        // );
        setBarCodeValue(updatedBasicInformationIValues.barcode);
        // console.log("setBarCodeValue------->", barCodeValue);
    };

    const handleChangeSelect = (e, fieldName) => {
        let updatedBasicInformationIValues = {
            ...basicInformationIValues,
            [fieldName]: e,
        };

        if (fieldName !== "subCategory") {
            updatedBasicInformationIValues = {
                ...basicInformationIValues,
                [fieldName]: e,
                subCategory: "",
            };
            setSubCategoryValue({});
        }

        fieldName === "subCategory" && setSubCategoryValue(e);
        handleChangeBasicInfoInputs(updatedBasicInformationIValues);
    };

    const handleSetSubCategories = (subCategoriesUpdated) => {
        let updatedBasicInformationIValues = {
            ...basicInformationIValues,
            [fieldName]: e,
        };

        handleChangeBasicInfoInputs(updatedBasicInformationIValues);
        // console.log(updatedBasicInformationIValues);
        setSubCategoryInformationValues(subCategoriesUpdated);
    };

    const onChangeWeightableRadio = (e) => {
        let updatedBasicInformationIValues = {
            ...basicInformationIValues,
            weightable: e.target.value,
        };
        handleChangeBasicInfoInputs(updatedBasicInformationIValues);
    };

    useEffect(() => {
        categoryInformationForm.resetFields();
    }, [categoryInformationValues]);

    const handleChangeSwitch = (e, fieldName) => {
        let updatedBasicInformationIValues = {
            ...basicInformationIValues,
            [fieldName]: e,
        };
        handleChangeBasicInfoInputs(updatedBasicInformationIValues);
    };
    const handleCategoryViewPanel = () => {
        setShowCategoryAddPanel(true);
        categoryInformationForm.resetFields();
    };

    const backButton = () => {
        setShowCategoryAddPanel(false);
    };

    const categoryAddHeader = (
        <RightPanelHeader
            title="Add Category"
            backButtonText="Back to Product Edit"
            backButton={true}
            onClick={backButton}
            rightContent={
                <Space>
                    <Button
                        variant="primary"
                        onClick={(e) => handleSubmitAddCategory(e)}
                    >
                        Save
                    </Button>
                </Space>
            }
        />
    );

    const getProductCategoryDetails = async () => {
        const categories = await getEposProductCategoryById({
            OrgID: dashboardState.currentlySelectedOrgId,
            Value: basicInformationIValues.mainCategory.value,
        });

        return categories;
    };

    const handleLookUp = async () => {
        setBarCodeData(null);
        setLookUpVisible(true);
        // console.log("brandOBj-------->", basicInformationIValues);

        var barcodeObject = {};
        if (basicInformationIValues.barcode) {
            barcodeObject = await getEposBarcodeByBarcodeId({
                OrgID: dashboardState.currentlySelectedOrgId,
                BarCode: basicInformationIValues.barcode,
                OrganizationName:
                    orgDetails.fullUserOrgDetailsList[0].OrganizationName,
                LocationName: locationDetails.LocationName,
                CloudLocationID: locationDetails.LocationID,
            });
            if (barcodeObject.length > 0) {
                setBarCodeData(barcodeObject);
            } else {
                // setLookUpVisible(false);
                Message.error({
                    content: "Barcode does not exist",
                });
            }
        } else {
            Message.error(`Barcode does not exist`);
        }

        // if (barcodeObject.length > 0) {
        //   debugger;
        //   if (barCodeValue !== '' || barCodeValue.trim() !== '') {
        //     let data = await getOnlineLookUpData(barcodeObject);
        //     // if(data.)
        //     console.log("data", data);
        //   } else {
        //     Message.error(
        //       ` Barcode not entered`
        //     )
        //   }

        //   Message.success(
        //     ` added successfully.`
        //   );
        // } else {
        //   Message.error(
        //     ` Please try again`
        //   )
        // }
    };

    const handleCloseLookUp = () => {
        setLookUpVisible(false);
    };

    const handleOpenLookUp = () => {
        setLookUpVisible(true);
    };

    const hideCategoryAddPanel = () => {
        setShowCategoryAddPanel(false);
    };

    const missingCategoryInfoMessage =
        missingCategoryFieldNames.length > 0
            ? `${missingCategoryFieldNames.join(
                  ", "
              )} field(s) are missing in Category Information.`
            : "";
    return (
        <div id="BasicInfoForm">
            {/* <Stack width='800' horizontalAlign='space_between' verticalAlign='v_center' style={{ height: '60px' }}>

        <Stack direction='row' style={{ flexGrow: '5' }}>
          <Icon name="information" color='blue' />
          <Typography style={{ color: '#039BE5' }}>How Online Lookup works!!</Typography>
        </Stack>

        <Stack horizontalAlign='space_evenly' direction='row' style={{ flexGrow: '5' }}>
          <Button variant="secondary" style={{ display: 'flex' }} onClick={handleLookUp}>
            <Icon name='globe'></Icon>
            Online Lookup
          </Button>
        </Stack>

        <Popup
          visible={lookUpVisible}
          title='Online Lookup'
          onCancel={handleCloseLookUp}

        >
          <OnlineLookupForm />
        </Popup>

      </Stack> */}

            <Row className="form-row">
                <Col md={8}>
                    <div className="form-lbl-wrapper">
                        <Label required={true}>Product Name</Label>
                    </div>
                </Col>
                <Col md={16}>
                    <InputBox
                        type="text"
                        placeholder="Product Name"
                        value={basicInformationIValues.productName}
                        onChange={(e) => handleChangeInput(e, "productName")}
                        error={validations.productName}
                    />
                </Col>
            </Row>
            <Row className="form-row">
                <Col md={8}>
                    <div className="form-lbl-wrapper">
                        <Label required={true}>Main Category</Label>
                    </div>
                </Col>
                <RightPanel
                    active={showCategoryAddPanel}
                    size="big"
                    outsideClose={true}
                    headerContent={categoryAddHeader}
                    handleOnClose={hideCategoryAddPanel}
                >
                    <Space direction="vertical" style={{ width: "100%" }}>
                        {missingCategoryInfoMessage && (
                            <Alert
                                message="Required Fields Missing!"
                                showIcon
                                description={`
                                        ${missingCategoryInfoMessage}
                                    `}
                                type="error"
                                closable
                                action={
                                    <Button
                                        category="text"
                                        variant="link"
                                        size="small"
                                    />
                                }
                            />
                        )}

                        {hasSubCategoryValidationIssues && (
                            <Alert
                                message="Duplicate Data!"
                                showIcon
                                description={subCategoryValidationError}
                                type="error"
                                closable
                                action={
                                    <Button
                                        category="text"
                                        variant="link"
                                        size="small"
                                    />
                                }
                            />
                        )}
                        {apiValidationError ? (
                            <InlineMessage
                                message={apiValidationError.message}
                                type="alert"
                                variant="error"
                                close={true}
                            />
                        ) : null}

                        <Accordion
                            theme="default"
                            type="content_only"
                            title="Category Information"
                            collapsed={false}
                        >
                            <div
                                // ref={categoryInfoPanel}
                                style={{ width: "100%" }}
                            >
                                <CategoryInformationForm
                                    categoryInformationValues={
                                        categoryInformationValues
                                    }
                                    categoryInformationForm={
                                        categoryInformationForm
                                    }
                                    // onFinish={onFinishBasic}
                                    // onFinishFailed={onFinishBasicFailed}
                                    handleChangeCategoryInfoInputs={
                                        handleChangeCategoryInfoInputs
                                    }
                                    // brands={brands}
                                    orgDetails={dashboardState}
                                    validations={categoryInfoValidationErrors}
                                />
                            </div>
                        </Accordion>
                        <Accordion
                            theme="default"
                            type="content_only"
                            title="Sub Category Information"
                            // collapsed={!showSubCategoryInfoPanel}
                        >
                            <div
                                ref={subCategoryInfoPanel}
                                style={{ width: "100%" }}
                            >
                                <SubCategoryInformationForm
                                    subCategoryInformationValues={
                                        subCategoryInformationValues
                                    }
                                    handleSetSubCategories={
                                        handleSetSubCategories
                                    }
                                    // validations={priceInfoValidationErrors}
                                />
                            </div>
                        </Accordion>

                        <Accordion
                            theme="default"
                            type="content_only"
                            title="Warning Messages"
                            collapsed={true}
                        >
                            <div style={{ width: "100%" }}>
                                <WarningMessagesForm
                                    // warningMessagesInitialValues={{}}
                                    // customAttributesForm={customAttributesForm}
                                    // onFinish={onFinishCustomAttributes}
                                    // onFinishFailed={onFinishCustomAttributesFailed}
                                    warningMessages={warningMessagesObj}
                                    handleChangeWarningMessageInputs={
                                        handleChangeWarningMessageInputs
                                    }
                                />
                            </div>
                        </Accordion>
                    </Space>
                </RightPanel>
                <Col md={16}>
                    <Select
                        type="options-with-link"
                        options={productCategoryOptions}
                        onChange={handleChangeMainCategory}
                        value={basicInformationIValues.mainCategory}
                        placeholder="Select Main Category"
                        hasError={validations.mainCategory}
                    >
                        <Col md={8}>
                            <div className="form-lbl-wrapper">
                                <Label required={true}>Main Category</Label>
                            </div>
                        </Col>
                    </Select>
                </Col>
            </Row>
            <Row className="form-row">
                <Col md={8}>
                    <div className="form-lbl-wrapper">
                        <Label>Sub Category</Label>
                    </div>
                </Col>
                <Col md={16}>
                    <Select
                        options={
                            subCategories && subCategories.length > 0
                                ? subCategories.map((sc) => {
                                      return {
                                          value: sc.ID,
                                          label: sc.Name,
                                      };
                                  })
                                : []
                        }
                        // value={basicInformationIValues.subCategory}
                        value={subCategoryValue}
                        placeholder="Select Sub Category"
                        onChange={(e) => handleChangeSelect(e, "subCategory")}
                    />
                </Col>
            </Row>
            <Row className="form-row">
                <Col md={8}>
                    <div className="form-lbl-wrapper">
                        <Label>Brand</Label>
                    </div>
                </Col>
                <Col md={16}>
                    <Select
                        options={
                            brands
                                ? brands.map((br) => {
                                      return {
                                          value: br.ID,
                                          label: br.BrandName,
                                      };
                                  })
                                : []
                        }
                        value={basicInformationIValues.brand}
                        placeholder="Select Brand"
                        onChange={(e) => handleChangeSelect(e, "brand")}
                    />
                </Col>
            </Row>
            <Row className="form-row">
                <Col md={8}>
                    <div className="form-lbl-wrapper">
                        <Label>Location</Label>
                    </div>
                </Col>
                <Col md={16}>
                    <Select
                        options={
                            locations && locations.length > 0
                                ? locations.map((ocl) => {
                                      return {
                                          value: ocl.locationDetails.LocationID,
                                          label: ocl.locationDetails
                                              .LocationName,
                                      };
                                  })
                                : []
                        }
                        // isDisabled={true}
                        isMulti={true}
                        placeholder="Select Location"
                        // value={basicInformationIValues.location}
                        value={[
                            {
                                value: locationDetails?.LocationID || null,
                                label: locationDetails?.LocationName || null,
                            },
                        ]}
                        onChange={(e) => handleChangeSelect(e, "location")}
                    />
                </Col>
            </Row>
            <Row className="form-row">
                <Col md={8}>
                    <div className="form-lbl-wrapper">
                        <Label required={true}>Item Code</Label>
                    </div>
                </Col>
                <Col md={8}>
                    <InputBox
                        type="text"
                        placeholder="Item Code"
                        value={basicInformationIValues.itemCode}
                        error={validations.itemCode}
                        onChange={(e) => handleChangeInput(e, "itemCode")}
                    />
                </Col>
            </Row>
            <Row className="form-row" align="middle">
                <Col md={8}>
                    <div className="form-lbl-wrapper">
                        <Label required={true}>Barcode</Label>
                    </div>
                </Col>
                <Col md={8}>
                    <InputBox
                        type="text"
                        placeholder="Barcode"
                        value={basicInformationIValues.barcode}
                        error={validations.barcode}
                        onChange={(e) => handleChangeInput(e, "barcode")}
                    />
                </Col>
                <Col span={8}>
                    <Stack horizontalAlign="space_evenly">
                        <Button
                            variant="secondary"
                            category="icon-text"
                            icon="globe"
                            text="Online Lookup"
                            onClick={handleLookUp}
                            disabled={!barCodeValue}
                        ></Button>
                        {barCodeData && (
                            <Popup
                                visible={lookUpVisible}
                                title="Online Lookup"
                                onCancel={handleCloseLookUp}
                            >
                                {barCodeData ? (
                                    <OnlineLookupForm
                                        barCodeValue={
                                            barCodeValue ? barCodeValue : ""
                                        }
                                        barCodeData={
                                            barCodeData ? barCodeData : ""
                                        }
                                        productCategories={productCategories}
                                        brands={brands}
                                        allproductData={allproductData}
                                        handleCloseLookUp={handleCloseLookUp}
                                        handleOpenLookUp={handleOpenLookUp}
                                    />
                                ) : (
                                    <></>
                                )}
                            </Popup>
                        )}
                    </Stack>
                </Col>
            </Row>
            <Row className="form-row">
                <Col md={8}>
                    <div className="form-lbl-wrapper">
                        <Label>Available stock</Label>
                    </div>
                </Col>
                <Col md={8}>
                    {enableProductEditMode ? (
                        <InputBox
                            type="text"
                            placeholder="Available stock"
                            minValue={0}
                            value={basicInformationIValues.availableStock}
                            disabled={enableProductEditMode}
                        />
                    ) : (
                        <InputBox
                            type="number"
                            placeholder="Available stock"
                            minValue={0}
                            value={basicInformationIValues.openingStock}
                            onChange={(e) =>
                                handleChangeInput(e, "openingStock")
                            }
                            disabled={enableProductEditMode}
                            minErrorMessage={
                                "The minimum value cannot be negative"
                            }
                        />
                    )}
                </Col>
            </Row>
            <Row className="form-row">
                <Col md={8}>
                    <div className="form-lbl-wrapper">
                        <Label>Weighable</Label>
                    </div>
                </Col>
                <Col md={16}>
                    <div style={{ width: "100%", textAlign: "left" }}>
                        <Radio.Group
                            options={[
                                { label: "Yes", value: "yes" },
                                { label: "No", value: "no" },
                            ]}
                            optionType="button"
                            buttonStyle="solid"
                            value={basicInformationIValues.weightable}
                            onChange={onChangeWeightableRadio}
                            // value={basicInformationIValues.weightable}
                        />
                    </div>
                </Col>
            </Row>
            {basicInformationIValues.weightable === "yes" ? (
                <Row className="form-row">
                    <Col md={8}>
                        <div className="form-lbl-wrapper">
                            <Label>Weight</Label>
                        </div>
                    </Col>
                    <Col md={8}>
                        <InputBox
                            type="number"
                            placeholder="Weight"
                            value={basicInformationIValues.weight}
                            minValue={0}
                            minErrorMessage={
                                "The minimum value cannot be negative"
                            }
                            onChange={(e) => handleChangeInput(e, "weight")}
                        />
                    </Col>
                </Row>
            ) : null}
            {/* <Row className="form-row">
        <Col md={8}><div className="form-lbl-wrapper"><Label>Pack Size</Label></div></Col>
        <Col md={8}><InputBox type="number" placeholder="Pack Size" value={basicInformationIValues.packSize}  minValue= {0} onChange={(e)=>handleChangeInput(e, 'packSize')}/></Col>
      </Row> */}

            <Row className="form-row">
                <Col md={8}>
                    <div className="form-lbl-wrapper">
                        <Label>Price Notification</Label>
                    </div>
                </Col>
                <Col md={16}>
                    <div className="form-lbl-wrapper">
                        <Switch
                            checked={basicInformationIValues.priceNotification}
                            ame="priceNotification"
                            onChange={(e) =>
                                handleChangeSwitch(e, "priceNotification")
                            }
                            size="small"
                        />
                    </div>
                </Col>
            </Row>
        </div>

        // <Form initialValues={basicInformationIValues} {...layout} form={basicInformationForm} onFinish={onFinish} onFinishFailed={onFinishFailed} name="nest-messages" style={{ width: '100%' }}>
        //   <Form.Item
        //     name="productName"
        //     label="Product Name"
        //     rules={[
        //       {
        //         required: true,
        //       },
        //     ]}
        //     labelAlign="left"
        //     help=""
        //   >
        //     <InputBox type="text" value="10%"/>
        //   </Form.Item>
        //   <Form.Item
        //     name="mainCategory"
        //     label="Main Category"
        //     rules={[
        //       {
        //         required: true,
        //       },
        //     ]}
        //     labelAlign="left"
        //     help=""
        //   >
        //     <Select
        //       options={productCategoryOptions}
        //       onChange={handleChangeMainCategory}
        //     />
        //   </Form.Item>
        //   <Form.Item name="subCategory" label="Sub Category" labelAlign="left">
        //     <Select options={
        //       subCategories && subCategories.length > 0 ?
        //         subCategories.map(sc => {
        //           return {
        //             value: sc.ID, label: sc.Name
        //           }
        //         }) : []
        //     }
        //     />
        //   </Form.Item>
        //   <Form.Item name="brand" label="Brand" labelAlign="left">
        //     <Select options={brands ? brands.map(br => { return { value: br.ID, label: br.BrandName } }) : []} />
        //   </Form.Item>
        //   {/* <Form.Item name="location" label="Location" labelAlign="left">
        //     <Select
        //       options={locations && locations.length > 0 ?
        //         locations.map(ocl => {
        //           return {
        //             value: ocl.locationDetails.LocationID,
        //             label: ocl.locationDetails.LocationName
        //           }
        //         })
        //         : []
        //       }
        //     />
        //   </Form.Item> */}
        //   <Form.Item
        //     name="itemCode"
        //     label="Item Code"
        //     rules={[
        //       {
        //         required: true,
        //       },
        //     ]}
        //     labelAlign="left"
        //     help=""
        //   >
        //     <AntInput />
        //   </Form.Item>
        //   <Form.Item
        //     name="barcode"
        //     label="Barcode"
        //     rules={[
        //       {
        //         required: true,
        //       },
        //     ]}
        //     labelAlign="left"
        //     help=""
        //   >
        //     <AntInput />
        //   </Form.Item>
        //   <Form.Item name="openingStock" label="Opening Stock" labelAlign="left">
        //     <AntInput />
        //   </Form.Item>
        //   <Form.Item name="weightable" label="Weightable" labelAlign="left">
        //     <Radio.Group
        //       options={[
        //         { label: 'Yes', value: 'yes' },
        //         { label: 'No', value: 'no' },
        //       ]}
        //       optionType="button"
        //       buttonStyle="solid"
        //       // value={basicInformationIValues.weightable}
        //     />
        //   </Form.Item>
        //   <Form.Item name="packSize" label="Pack Size" labelAlign="left">
        //     <AntInput />
        //   </Form.Item>
        //   <Form.Item name="showOnline" label="Show Online" labelAlign="left">
        //     <Switch checked={basicInformationIValues.showOnline} />
        //   </Form.Item>
        //   <Form.Item name="priceNotification" label="Price Notification" labelAlign="left">
        //     <Switch checked={basicInformationIValues.priceNotification} />
        //   </Form.Item>
        // </Form>
    );
};

export default BasicInformationForm;
